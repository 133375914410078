<template>
    <div class="cancel_subscription_modal">
        <div v-if="isLoading" class="loader-wrapper">
            <loader />
        </div>

        <div class="main">
            <h4 v-if="current.title" v-html="current.title" />

            <p v-if="current.desc" v-html="current.desc" class="desc" />

            <div v-if="current.type === 'option'" class="options">
                <modal-option v-for="item in current.subQuestions" :key="item.label" :label="item.label" :icon="item.icon"
                    @onSelect="onSelect(item)" />
            </div>
            
            <a v-if="current.hasSupport" class="link" href="mailto:support@keyword.com" >{{$t('contact-support-team')}}</a>
        </div>

        <template v-if="current.type === 'textarea'">
            <div class="textarea-block">
                <textarea v-model.trim="submitData.details" placeholder="Your answer..." @input="clearError" />
                <p v-if="detailsError" class="error">{{ detailsError }}</p>
                <p class="info" v-html="$t('most-accurate-rank-tracker-info')" />
            </div>


            <div class="submit-block">
                <kw-button 
                    :button-text="$t('continue')"
                    button-type="primary"
                    @click="saveDetails"
                />
            </div>
        </template>

        <template v-if="current.type === 'final'">
            <div v-if="!this.isNegotiatedUser" class="submit-block">
                <kw-button 
                    :button-text="$t('accept-offer')"
                    button-type="primary"
                    @click="onAccept"
                />
                <span @click="confirm" class="cancel-link link">{{ $t('cancel-subscription') }}</span>
            </div>
            <div v-else class="submit-block">
                <kw-button 
                    :button-text="$t('speak_to_us')"
                    button-type="primary"
                    @click="onSpeakToUs"
                />
                <kw-button 
                    :button-text="$t('contact-email')"
                    button-type="primary"
                    @click="onSendEmail"
                />
                <span @click="confirm" class="cancel-link link">{{ $t('cancel-subscription') }}</span>
            </div>

        </template>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { options } from './configs/cancelSubscriptionOptions'
import ModalOption from './components/ModalOption.vue';
import { CANCELATION_COUPON } from '@/constants';

export default {
    name: 'CancelSubscriptionModal',
    components: {
        'modal-option': ModalOption
    },
    data() {
        return {
            current: {
                title: "Tell us why you are leaving us",
                desc: "Why are you cancelling your subscription?",
                type: 'option',
                subQuestions: options,
            },
            submitData: {
                main_question_answer: '',
                sub_question_answer: null,
                details: '',
            },
            isLoading: false,
            detailsError: ''
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentPlan',
        ]),
        isNegotiatedUser(){
            return this.getCurrentPlan?.subscription?.price_type === 'negotiated';
        }
    },
    methods: {
        ...mapActions([
                'cancelSubscription',
                'sendEmailToSupport'
        ]),
        confirm() {
            this.isLoading = true;

            this.cancelSubscription({
                data: {
                    form_data: this.submitData,
                }
            }).finally(()=>{
                this.isLoading = false;
                this.$emit('close');
            })
        
        },
        onAccept(){
            sessionStorage.setItem('offer_coupon', CANCELATION_COUPON);
            
            this.$emit('close');
            this.$router.push({ 
                name: 'AccountDetails',
                query: { offer: 1 },
            });
        },
        onSelect(option) {
            if(!this.submitData.main_question_answer){
                this.submitData.main_question_answer = option.label;
            } else {
                this.submitData.sub_question_answer  = option.label;
            }
            if(!!option.type){
                this.current = option;
            }else{
                this.showOffer();
            }
        },
        onSpeakToUs(){
            window.open('https://meetings.hubspot.com/bogdan-soldatenkov2', '_blank');
            this.$emit('close');
        },
        onSendEmail(){
            this.isLoading = true;
            this.sendEmailToSupport().then(()=>{
                this.$emit('close');
            }).finally(()=>{
                this.isLoading = false;
            });
        },
        showOffer(){
            const amount = this.getCurrentPlan?.subscription?.stripe_package_amount;
            const price = amount ? Math.round(parseFloat(amount.replace(/,/g, "")) * 0.8) : null;
            const circle = this.getCurrentPlan?.subscription?.billing_cycle === "monthly" ? 'month' : 'year';

            this.current = {
                title: 'We’re sorry to see you go...',
                desc: this.isNegotiatedUser ? 'Before you cancel, would you like to speak us about receiving an additional discount?'
                    : `Before you cancel, consider staying with us and enjoy a <b>20% discount</b> on your current package!
                    ${price ? `<br /><br /> Your new total will be: ${price}/${circle}` : ''}`,
                type: 'final',
            }
        },
        saveDetails(){
            if(!this.submitData.details){
                this.detailsError = this.$t('field-empty-error');
            } else {
                this.showOffer();
            }
        },
        clearError(){
            if(this.detailsError) {
                this.detailsError = '';
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.cancel_subscription_modal {
    position: relative;
    width: 100%;
    max-height: calc(100vh - 100px);
    overflow-y: auto;

    @media screen and (max-width: 460px) {
        width: 100vw;
    }

    /deep/ .link {
        color: kw-color(kw-light-blue, 10);
        &:hover {
            text-decoration: none;
            color: kw-color(kw-blue, 7);
        }
    }

    .main {
        width: 100%;
        position: relative;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
            margin: 0;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 500;
            line-height: 28px;
            text-align: center;
        }

        .desc {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            text-align: center;
            margin: 8px 0 0;
        }

        .options {
            margin-top: 42px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        .link {
            cursor: pointer;
            display: inline-block;
            margin: 10px auto 0;
            font-family: Aeonik;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
        }
    }

    .textarea-block {
        padding: 0 24px 24px 24px;

        textarea {
            width: 100%;
            height: 80px;
            background: kw-color(kw-white, 1);
            border: 1px solid kw-color(kw-white, 6);
            resize: none;
            padding: 8px;
            border-radius: 4px;
        }

        .error {
            font-size: 14px;
            color: kw-color(kw-red, 1);
        }

        .info {
            padding: 0 24px;
            margin: 14px 0 0;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 18.75px;
            text-align: center;
        }
    }
   
    .submit-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        border-top: 1px solid kw-color(kw-white, 6);
        
        button {
            width: 100%;
            padding: 8px;
            height: 40px;
            margin-bottom: 16px;
        }

        .cancel-link{
            cursor: pointer;
            font-weight: 500;
        }
    }

    .loader-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255,0.5);
    }
}
</style>
