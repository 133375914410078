<template>
    <div
        class="bp-dropdown"
        data-cy="actions-button"
        :class="{ className, 'bp-dropdown--sub': role }"
    >
        <span
            :class="dropdownButtonClasses"
            @click="_onToggle"
            @mouseenter="_onBtnEnter"
            @mouseleave="_onBtnLeave"
        >
            <slot name="btn" />
        </span>
        <div
            v-if="!isHidden"
            :id="id"
            class="bp-dropdown__body"
            :style="{ minWidth: `${width}px`, top: `${top}px`, left: `${left}px` }"
            :class="{ [`${className}-bp__body`]: className }"
            @click="_onBodyClick"
            @mouseenter="_onBodyEnter"
            @mouseleave="_onBodyLeave"
        >
            <slot name="body">
                <ul
                    class="v-context"
                    data-cy="overview-actions-menu"
                >
                    <li
                        v-for="item in items"
                        :key="item.text"
                    >
                        <hr v-if="item.type === 'divider'">
                        <a
                            v-else
                            @click.prevent="itemClickHandler(item.type)"
                        >
                            <span
                                v-if="item.icon"
                                :class="item.icon"
                            />
                            {{ item.text }}
                        </a>
                    </li>
                </ul>
            </slot>
        </div>
    </div>
</template>

<script>
    import Dropdown from 'bp-vuejs-dropdown';
    export default {
        extends: Dropdown,
        props: {
            itemClickHandler: {
                type: Function,
                required: false,
                default: () => {},
            },
            items: {
                type: Array,
                required: false,
                default: () => [],
            },
        },

        computed: {
            dropdownButtonClasses() {
                return {
                    [`bp-dropdown__${(this.role) ? 'sub' : 'btn'}`]: true,
                    [`bp-dropdown__${(this.role) ? 'sub' : 'btn'}--active`]: !this.isHidden,
                    [`${this.className}-bp__btn`]: this.className,
                    [`${this.className}-bp__btn--active`]: !this.isHidden
                }
            }
        },

        mounted() {
            window.addEventListener('resize', this.onResize);
        },

        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },

        methods: {
            onResize() {
                if (this.isHidden) return;

                // this method and property is in the package "bp-vuejs-dropdown", since this component is extends, we can use the methods of the parent component
                this.isHidden = !this.isHidden;
                this.prepare();
            }
        }
    }
</script>
