<template>
    <div class="display-settings__table" data-cy="project-table-settings">
        <span class="table_icon table_setting_icon" />
        <div>
            <h2
                class="settings__subtitle"
                v-text="$t('project-table-settings')"
            />
            <p
                class="settings__description"
                v-text="$t('project-table-settings-description')"
            />
        </div>
        <!-- table width setting -->
        <label
            for="table-width"
            class="settings__label"
            v-text="$t('table-width')"
        />
        <button-group
            id="table-width"
            :value="getOtherSetting('widetable')"
            @input="wideTablehandler($event)"
        >
            <radio selected-value="hide" data-value="hide">
                {{ $t('standard-table-width') }}
            </radio>
            <radio selected-value="show" data-value="show">
                {{ $t('extra-wide-table-width') }}
            </radio>
        </button-group>
        <p class="settings__description" v-text="$t('extra-wide-note')" />
        <!-- projects sort setting -->
        <label
            for="list-projects"
            class="settings__label"
            v-text="$t('list-projects')"
        />
        <v-select
            id="list-projects"
            options-label="label"
            options-value="value"
            :options="projectsSort"
            :value="getDisplaySetting('category_sort')"
            @change="e => save({ setting: 'category_sort', value: e })"
        />
        <!-- CPC currency setting -->
        <label for="reporting-currency" class="settings__label">
            <span v-text="$t('reporting-currency')" />
            <span
                class="kw kw-information"
                @mouseover="
                    tooltip({
                        text: $t('help-reporting-currency'),
                        event: $event,
                    })
                "
            />
        </label>
        <custom-v-select
            id="reporting-currency"
            data-cy="user-reporting-currency"
            options-label="label"
            options-value="value"
            :options="getCurrencies"
            search
            search-key="label"
            :value="initialCurrency"
            @change="e => save({ setting: 'currency_code', value: e })"
        />
        <!-- project metrics setting -->
        <label
            for="project-metrics"
            class="settings__label"
            v-text="$t('project-metrics')"
        />
        <v-select
            id="project-metrics"
            options-label="label"
            options-value="value"
            :options="periodConfig"
            :value="getDisplaySetting('metrics')"
            @change="e => save({ setting: 'metrics', value: e })"
        />
        <!-- metric ranges setting -->
        <label for="metric-ranges" class="settings__label">
            <span v-text="$t('metric-ranges')" />
            <a
                href="https://support.keyword.com/en/articles/3383465-project-metrics"
                target="_blank"
            >
                <span
                    class="kw kw-information"
                    @mouseover="
                        tooltip({
                            text: $t('help-link-tooltip'),
                            event: $event,
                        })
                    "
                />
            </a>
        </label>
        <div id="metric-ranges" class="display-settings__ranges">
            <kw-input-field
                v-for="(range, i) in metrics_range"
                :key="i"
                v-model="metrics_range[i]"
                class="text-input"
                maxlength="3"
                :placeholder="$t('metrics-range-placeholder')"
                type="text"
                name="metricsRanges"
                :error-data="rangeInputError(i)"
                @blur="debouncedHandler(setRange)"
                @keyup.enter.stop.prevent="debouncedHandler(setRange)"
            />
        </div>
        <!-- table sort setting -->
        <label
            for="table-sort"
            class="settings__label"
            v-text="$t('sort-table-by')"
        />
        <div id="table-sort" class="display-settings__table-sort">
            <v-select
                options-label="label"
                options-value="value"
                :options="sortByConfig"
                :value="getDisplaySetting('sort_by')"
                @change="e => save({ setting: 'sort_by', value: e })"
            />
            <v-select
                options-label="label"
                options-value="value"
                :options="sortByOrderConfig"
                :value="getDisplaySetting('sort_by_order')"
                @change="e => save({ setting: 'sort_by_order', value: e })"
            />
        </div>
        <!-- date format setting -->
        <label for="date-format" class="settings__label">
            <span v-text="$t('date-format')" />
        </label>
        <v-select
            id="date-format"
            options-label="label"
            options-value="value"
            :options="dateFormatConfig"
            :value="getDisplaySetting('dateformat')"
            @change="e => save({ setting: 'dateformat', value: e })"
        />
        <!-- keywords per page setting -->
        <label
            for="keywords-per-page"
            class="settings__label"
            v-text="$t('amount-keywords-per-page')"
        />
        <v-select
            id="keywords-per-page"
            ref="perPageSelector"
            options-label="label"
            options-value="value"
            :options="perPageConfig"
            :value="getOtherSetting('display_length')"
            data-cy="display-keywords-per-page"
            @change="setPerPage"
        />
        <label
            for="chart-height"
            class="settings__label settings__label_chart-height"
        >
            {{ $t('chart-height') }}
            <span v-text="`${getOtherSetting('chartheight')}px`" />
        </label>
        <vue-slider
            id="chart-height"
            :dot-size="16"
            :height="4"
            :interval="25"
            :lazy="true"
            :max="500"
            :min="150"
            :value="getOtherSetting('chartheight')"
            @change="
                e =>
                    updateUserTableSettings({
                        setting: 'chartheight',
                        value: e,
                    })
            "
        />
        <div class="display-settings__limits">
            <span v-text="'150px'" />
            <span v-text="'500px'" />
        </div>
    </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css';
import { mapGetters, mapActions } from 'vuex';
import { MOBILE_WIDTH_RESOLUTION_PHONE } from '@/constants';
import { TYPING_DEBOUNCE } from '@/constants';
import buttonGroup from 'vue-strap/src/buttonGroup';
import Radio from 'vue-strap/src/Radio';
import SimpleConfirmWithCustomHandler from 'components/modals/SimpleConfirmWithCustomHandler';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import ValidationMixin from '@/mixins/ValidationMixin';
import vSelect from 'vue-strap/src/Select';
import VueSlider from 'vue-slider-component';
import {
    dateFormatConfig,
    periodConfig,
    perPageConfig,
    projectsSort,
    sortByConfig,
    sortByOrderConfig,
} from '@/components/settings/configs';

export default {
    name: 'ProjectTabelSettings',
    components: {
        'button-group': buttonGroup,
        radio: Radio,
        'v-select': vSelect,
        'vue-slider': VueSlider,
    },
    mixins: [ValidationMixin, ToolTipRenderMixin],
    data() {
        return {
            dateFormatConfig,
            debouncedHandler: _.debounce(fn => fn(), TYPING_DEBOUNCE),
            err: { metrics_range: {} },
            metrics_range: ['', '', '', '', ''],
            periodConfig,
            perPageConfig,
            projectsSort,
            sortByConfig,
            sortByOrderConfig,
            inited: false,
        };
    },
    computed: {
        ...mapGetters([
            'getDisplaySetting',
            'getOtherSetting',
            'getCurrencies',
        ]),
        initialCurrency() {
            return this.getCurrencies.length
                ? this.getDisplaySetting('currency_code')
                : null;
        },
    },
    mounted() {
        this.metrics_range = this.getDisplaySetting('metrics_range').map(el =>
            el.toString(),
        );
        this.$nextTick(() => (this.inited = true));
    },
    methods: {
        ...mapActions([
            'updateUserDisplaySettings',
            'updateUserTableSettings',
        ]),

        wideTablehandler(value) {
            this.updateUserTableSettings({ setting: 'widetable', value });
        },
        save(setting) {
            if (!this.inited) return;
            this.updateUserDisplaySettings(setting);
        },
        setRange() {
            this.err.metrics_range = this.metricsRangeValidator(
                this.metrics_range,
            );

            if (!_.isEmpty(this.err.metrics_range)) {
                this.$toastr.e(this.err.metrics_range.text);
                if (this.err.metrics_range.type !== 'duplicates-error') {
                    this.err.metrics_range.indexes.forEach(
                        i => (this.metrics_range[i] = ''),
                    );
                }

                return;
            }

            this.save({ setting: 'metrics_range', value: this.metrics_range });
        },
        setPerPage(newValue) {
            const oldValue = this.getOtherSetting('display_length');

            if (!this.inited || newValue === oldValue) return;

            if (newValue <= 100) {
                this.updateUserTableSettings({
                    setting: 'display_length',
                    value: newValue,
                });
                return;
            }

            // if per page more than 100 kw we should as confirmation from user
            this.$modal.show(
                SimpleConfirmWithCustomHandler,
                {
                    handler: confirmed => {
                        confirmed
                            ? this.updateUserTableSettings({
                                  setting: 'display_length',
                                  value: newValue,
                              })
                            : (this.$refs.perPageSelector.val = oldValue);
                    },
                    header: this.$t('warning-100-rows-exceed'),
                    positiveButton: this.$t('ok'),
                },
                {
                    classes: 'v--modal center-modal-popup',
                    clickToClose:
                        window.screen.width > MOBILE_WIDTH_RESOLUTION_PHONE,
                    height: 'auto',
                    width: 282,
                },
            );
        },
        rangeInputError(i) {
            const { indexes } = this.err.metrics_range;
            return {
                error: indexes && indexes.includes(i),
            };
        },
    },
};
</script>

<style lang="scss">
@import '~sass/partials/dropdowns';

.display-settings__table {
    display: flex;
    flex-flow: column nowrap;
    padding: 43px 24px 22px;

    .button-closer {
        align-self: flex-end;
    }

    .form-control.dropdown-toggle {
        @extend .custom-dropdown;
        color: kw-color(kw-black, 1);
    }

    .btn-group {
        margin-bottom: 16px;

        .btn {
            border-color: #dee5ec;
            box-shadow: none;
            color: kw-color(kw-black, 1);
            font-size: 13px;
            line-height: 15px;
            outline: transparent !important;
            width: 50%;

            &:hover {
                background-color: transparent;
            }

            &.active {
                background-color: kw-color(kw-blue, 1);
                border-color: kw-color(kw-blue, 1);
                color: kw-color(kw-white, 1);
            }
        }
    }

    .open .form-control.dropdown-toggle {
        @extend .custom-dropdown_active;
    }

    .dropdown-menu {
        @extend .custom-scroll;
        @extend .custom-dropdown-menu;
        width: 100%;

        .glyphicon.glyphicon-ok.check-mark:before {
            content: '';
        }
    }

    .vue-slider {
        padding: 10px 0 8px 0 !important;

        .vue-slider-rail {
            background-color: #dee5ec;
            box-shadow: none;

            .vue-slider-process {
                box-shadow: none;
                background-color: kw-color(kw-blue, 1);
            }

            .vue-slider-dot-handle {
                background-color: kw-color(kw-blue, 1);
                border: none;
            }
        }
    }

    .radio-buttons {
        display: flex;

        .radio-button {
            &:first-child {
                margin-right: 12px;
            }
        }
    }

    .display-settings__item {
        min-width: max-content;
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding: 10px 0;
        position: relative;

        .checkbox {
            position: relative !important;
            top: unset;
        }

        &_parent {
            position: relative;

            &:before {
                content: '';
                width: 1px;
                height: calc(100% - 42px);
                background-color: #c4c4c4;
                position: absolute;
                top: 32px;
                left: 8px;
            }
        }

        &:last-child {
            margin-bottom: 6px;
        }
    }

    .display-settings__subitem {
        align-items: center;
        display: flex;
        flex-grow: 1;
        padding: 5px 0 5px 24px;
        position: relative;

        &:before {
            border-bottom: 1px solid #c4c4c4;
            content: '';
            left: 8px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 11px;
        }

        &:last-child {
            &:after {
                background-color: kw-color(kw-white, 1);
                content: '';
                height: 28px;
                left: 6px;
                position: absolute;
                top: calc(50% + 1px);
                width: 4px;
            }
        }

        @media screen and (max-width: 800px) {
            flex-basis: 100%;
        }
    }

    .settings__label_other {
        cursor: pointer;
        flex-grow: 1;
        font-weight: normal !important;
        margin-bottom: 0;
        padding-left: 8px;
    }

    .settings__label_other {
        justify-content: flex-start;
        text-transform: unset;

        span {
            color: kw-color(kw-green, 1);
        }

        @media screen and (max-width: 480px) {
            max-width: calc(100% - 16px);
        }
    }
}

#table-width {
    margin-bottom: 8px;
}

.display-settings__limits {
    color: kw-color(kw-grey, 1);
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    line-height: 15px;
    padding-bottom: 20px;
}

.display-settings__ranges {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    input {
        text-align: center;
        width: 54px !important;

        @media screen and (max-width: 480px) {
            padding: 10px 10px;
            width: 44px;
        }
    }
}

.display-settings__table-sort {
    display: flex;
    flex-flow: row nowrap;

    .btn-group {
        width: 50%;

        &:first-child {
            margin-right: 8px;
        }
    }
}

.settings__label {
    justify-content: initial !important;
}

.settings__label_chart-height {
    justify-content: space-between !important;
}

.kw-information {
    color: #828f9c;
    font-size: 12px;
    font-weight: 700;
    margin: 0 4px;
}
</style>
