import {mapGetters} from 'vuex';
import {KW_TABLE_COLUMNS} from '@/constants';
import {isSafari} from '@/helpers/device-detection';

export default {
    computed: {
        ...mapGetters([
            'getUserDataProp',
            'getCurrentProject',
            'getOtherSetting',
            'getProjectByName',
            'getShowKeywordAction',
            'getShownChart',
            'getViewkeyView',
            'getViewkeyHasChildren',
            'getGlobalSearchQuery',
            'keyKeywordsById',
            'getIsAllCheckboxesSelected',
            'withoutIdsGetItem'
        ]),
        getBodyCheckboxChecked () {
            const checkedItems = this.keyKeywordsById;
            const isAllSelected = this.getIsAllCheckboxesSelected;
            const withoutIdsFunc = this.withoutIdsGetItem;
            return (id) => {
                const inWithoutIds = withoutIdsFunc(id);
                const isItemFounded = checkedItems.find((item) => {
                    return item.id === id;
                })
                const checkedStatus = ((isAllSelected && !inWithoutIds) || isItemFounded);
                return checkedStatus;
            }
        },
    },
    methods: {
        title (keyword, index) {
            const previous = this.items[index - 1] || null
            let title = null;

            const showViewKeyTitle = this.getViewkeyView && this.getViewkeyHasChildren;
            let showGroupTitle = false;

            if (this.getGlobalSearchQuery) {
                showGroupTitle = true;
            } else if (
                !this.getViewkeyView &&
                this.getOtherSetting('subcat') === 'show' &&
                (this.getCurrentProject && this.getProjectByName(this.getCurrentProject)?.project.children)
            ) {
                showGroupTitle = true;
            }

            if (showViewKeyTitle || showGroupTitle) {
                title = !previous || previous.category !== keyword.category
                    ? keyword.category.replace('[sub]', '<span class="kw kw-corner-down-right"></span>')
                    : null;
            }

            return title;
        },
        getColumnStyle (field) {
            try {
                return field.partOfTable
                    ? { 'flex': `${field.partOfTable} 0 0` } : {
                        'width': `${field.width}px`,
                        'min-width': `${field.width}px`,
                        'max-width': `${field.width}px`
                    };
            } catch (e) {
                console.warn('Field size error', e);
                return field.partOfTable
                    ? { 'flex': `${field.partOfTable} 0 0` }
                    : { 'width': `${field.width}px`, 'min-width': `${field.width}px`, 'max-width': `${field.width}px` };
            }
        },
        tableRowClasses (isFav) {
            return {
                'table-row': true,
                'table-row_fav': isFav,
            };
        },
        isGSCColumns (field) {
            return KW_TABLE_COLUMNS.GSC.includes(field);
        },
        hideGSCColumn(field) {
            return !this.getUserDataProp('gscConnected') && this.isGSCColumns(field)
        },
        handleMouseover(field, $event, title = false) {
            if (this.hideGSCColumn(field.name)) {
                return this.tooltip({text: this.$t('gsc-needed'), event: $event});
            } else if (field.name === 'serpFeatures' && title) {
                return this.tooltip({text: this.$t('beta'), event: $event});
            }
        },
        fieldDataClasses (field, isPdf = false) {
            const isPeriod = KW_TABLE_COLUMNS.PERIODS.includes(field.name);
            return {
                [field.dataClass]: true,
                'column-data_period': isPeriod,
                'column-data_safari': isSafari(),
               ['table_body_'+ field.title]: isPdf,
            };
        },
        periodIconClasses (field, keyword) {
            const trend = keyword.trends[field.name];
            const isPeriod = KW_TABLE_COLUMNS.PERIODS.includes(field.name);

            // if (trend + keyword.grank > 99 || (keyword.grank === 0 && trend < 0)) {
            //     return {};
            // }

            return {
                'icon-arrow_4': isPeriod && keyword.trends && trend > 0,
                'icon-arrow_4 icon-rotate-down': isPeriod && keyword.trends && trend < 0,
            };
        },
        tableRowInnerStyles (id, title) {
            //const keywordActionsOffset = this.getShowKeywordAction === id && window.MOBILE_WIDTH_RESOLUTION_PHONE > window.screen.width ? 220: 0;
            let countToPlus = 0;
            if(window.screen.width > 943) {
                countToPlus = 95;
            } else {
                countToPlus = 40
            }
            const showChartOffset = this.getShownChart.keyword === id ? this.getOtherSetting('chartheight') + countToPlus : 0;
            return {
                'margin-bottom': `${showChartOffset }px`,
                'margin-top': title ? '46px' : 0,
            };
        },
    },
};
