<template>
    <div class="header">
        <div class="title-block">
            <p class="title">
                {{ title }}
                <span
                    v-if="!!toolTip"
                    class="kw kw-info"
                    @mouseover="tooltip({ text: toolTip, event: $event })"
                />
            </p>
            <span v-if="amount" class="secondary-text">{{ amount }}</span>
            <span v-if="status" :class="directionArrowClasses" />
            <span v-if="percentage" class="percentage">{{ percentage }}%</span>
        </div>

       <div class="timeframe">
            <template v-if="showTimeframe">
                <p class="timeframe-label">{{ $t(`${exactDate ? 'comparison-timeframe' : 'timeframe'}`) }}</p>
                <!-- <date-ranges-dropdown :allTime="includeAllTime" type="trending" :value="date" @onSelect="date=>$emit('onDateSelect', date)" />
                <p>{{ $t('timeframe') }}</p> -->
                <date-ranges-dropdown v-if="timeframeList" showDatePickerList type="trending" :value="date" @onSelect="date=>$emit('onDateSelect', date)" />
                <date-range-picker v-else :exact="exactDate" @onApply="onChange" :value="date" :defaultKey="defaultKey" />
            </template>
            <kw-button
                v-if="isHidable"
                :disabled="isHideDisabled"
                class="hide-button"
                prepend-icon-class="kw kw-eye-off"
                button-type="secondary"
                @click="$emit('onHide')"
            />
       </div>
    </div>
</template>

<script>
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    import DateRangesDropdown from '@/components/common/DateRangesDropdown.vue';
    import DateRangePicker from '@/components/date-range-picker/DateRangePicker.vue';

    export default {
        name: 'ChartBlockHeader',
        mixins: [ToolTipRenderMixin],
        components: {
            'date-ranges-dropdown': DateRangesDropdown,
            'date-range-picker': DateRangePicker
        },
        props: {
            title: {
                type: String,
            },
            toolTip: {
                type: String,
            },
            showTimeframe: {
                type: Boolean,
                default: false,
            },
            isHidable: {
                type: Boolean,
                default: false,
            },
            amount: {
                type: String,
            },
            status: {
                type: String,
            },
            percentage: {
                type: Number,
            },
            date: {
                required: false,
            },
            isHideDisabled: {
                type: Boolean,
                default: false
            },
            includeAllTime: {
                type: Boolean,
                default: true
            },
            exactDate: {
                type: Boolean,
                deafult: false
            },
            timeframeList: {
                type: Boolean,
                deafult: false 
            },
            defaultKey: {
                type: String,
                required: false
            }
        },
        computed: {
            directionArrowClasses () {
                return {
                    'direction-arrow': true,
                    'icon-arrow_4 icon-rotate-down': this.status === 'down',
                    'icon-arrow_4': this.status === 'up',
                };
            },
        },
        methods: {
            onChange(date, key){
                this.$emit('onDateSelect', date, key)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 24px;

        .title-block {
            display: flex;
            align-items: center;
            gap: 8px;
            color: kw-color(kw-grey, 1);
            font-weight: 500;
            font-family: Roboto;

            .title {
                font-size: 14px;
                line-height: 16px;
                text-align: left;
                display: flex;
                align-items: center;
                gap: 8px;
                margin: 0;
                color: kw-color(kw-black, 1);
            }
           
            .kw-info {
                font-weight: 900;
                color: kw-color(kw-grey, 1);
            }

            /deep/ .direction-arrow {
                font-size: 11px;
                color: kw-color(kw-green, 1);

                &.icon-rotate-down {
                    color: kw-color(kw-red, 1);
                }
            }

            .percentage {
                font-size: 14px;
                font-weight: 700;
                line-height: 10px;
                color: kw-color(kw-black, 1);
            }
        }

        @media screen and (max-width: 500px) {
            flex-direction: column;
            gap: 10px;

            & > div{
                width: 100%;
            }

            .timeframe {
                /deep/ .date-ranges-dropdown{
                    button {
                        width: 82px;
                    }
                }
            }
        }
    }
    .timeframe {
        flex: 1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        gap: 8px;

        p {
            white-space: nowrap;
            margin: 0;
            color: kw-color(kw-light-grey, 8);
        }

        .hide-button{
            width: 24px;
            height: 24px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            /deep/.kw {
                font-weight: 900;
                font-size: 16px;
                color: #757C90;
            }
        }
    }
</style>
