<template>
    <div 
        class="custom-modal add-tag"
        data-cy="custom-modal"
    >
        <div class="custom-modal__header">
            {{ $t('add-new-tag-or-group') }}
            <closer 
                data-cy="button-closer"
                @click="close"
            />
        </div>
        <div class="custom-modal__body">
            <div
                class="option-item"
                data-cy="option-add-tag"
                @click="handleAddTagClick"
            >
                <span class="kw kw-tag" />
                <h4 class="title">{{ $t('add-new-tag') }}</h4>
                <p>{{ $t('add-new-tag-text') }}</p>
            </div>
            <div
                class="option-item"
                data-cy="option-add-sub-project"
                @click="handleAddSubProjectClick"
            >
                <span class="kw kw-file" />
                <h4 class="title">{{ $t('add-a-group') }}</h4>
                <p>{{ $t('add-group-text') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AddTagOrSubProject',
        props: {
            handleAddTag: {
                type: Function,
                required: true,
            },
            handleAddSubProject: {
                type: Function,
                required: true,
            },
        },
        methods: {
            close () {
                this.$emit('close');
            },
            handleAddTagClick() {
                this.close();
                this.handleAddTag();
            },
            handleAddSubProjectClick() {
                this.close();
                this.handleAddSubProject();
            }
        },
    };
</script>

<style lang="scss">
  @import '~sass/modal';

  .custom-modal {
    position: relative;
    padding: 32px 40px 42px;

    &.custom-modal_delete-tag,
    &.custom-modal_delete-keywords {
      padding: 0;

      .custom-modal__header {
        padding: 16px;
      }
    }

    .button-closer {
      position: absolute;
      right: 8px;
      top: 8px;
    }

    .custom-modal__header {
      padding: 0;
    }

    .custom-modal__body {
      display: flex;
      margin: 16px 0 0 0;
      padding: 0;

      .option-item {
        flex: 1;
        padding: 20px 8px;
        border: 1px solid #AFBAC7;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 20px;
        }

        span {
          &.kw-file,
          &.kw-tag {
            font-size: 21px;
            font-weight: 600;
            color: kw-color(kw-red, 1);
          }

          &.kw-tag {
            transform: rotate(0deg) !important;
          }
        }

        .title {
          margin-top: 8px;
          margin-bottom: 0;
          line-height: 24px;
        }

        p {
          margin: 0;
        }
      }
    }
  }
</style>
