export default (i18n, toastr) => {
    return error => {
        const { config, response } = error;

        if (!config || !response) {
            return Promise.reject(error);
        }

        if (config.method === 'delete' && config.url.includes('/keywords')) {
            toastr.e(i18n.t('keyword-delete-error'));
        }

        if (config.method === 'patch' && config.url.includes('/keywords')) {
            if (response.data?.errors?.[0]?.detail.includes('non unique')) {
                toastr.w(i18n.t('duplicate-detected'))
            }
        }

        if (config.method === 'post' &&
            (config.url.includes('attach-tags') ||
                config.url.includes('duplicate') ||
                config.url.includes('/refresh')
            )
        ) {
            response.data?.errors?.forEach(error => {
                if(!error.detail.includes('<a href=\"/packages\">View Larger Packages</a>')){
                    toastr.e(error.detail);
                }
            });
        }
        return Promise.reject(error);
    };
};