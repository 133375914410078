import { Events, EventBus } from '@/events';
import { version } from '@/helpers/service';
import store from '@/store';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

function attachUser(email) {
    Sentry.configureScope((scope) => {
        scope.setUser(email ? { email } : null);
    });
}

export const replay = new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: true,
});

/**
 * Initialize Sentry module
 *
 * @param {Vue} app Vue instance
 */
export function init(app) {
    if (process.env.NODE_ENV === 'production') {
        Sentry.init({
            dsn: process.env.MIX_SENTRY_DSN,
            node: process.env.NODE_ENV,
            environment: process.env.APP_ENVIRONMENT,
            // https://stackoverflow.com/a/50387233
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications.',
                // Email link Microsoft Outlook crawler compatibility error
                // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
                "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
            ],
            denyUrls: [
                // Browser extensions
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
                /^moz-extension:\/\//i,
                /^safari-extension:\/\//i
            ],
            replaysSessionSampleRate: 0.01,
            replaysOnErrorSampleRate: 1,
            integrations: [
                new Integrations.Vue({
                    attachProps: true,
                    app,
                }),
                new Integrations.Dedupe(),
                replay,
            ],
            release: version.packageVersion,
        });

        attachUser(store.state.settings.userData.email);

        // Re-attach user data when the user has been changed/updated in the app
        EventBus.on(Events.USER_UPDATE, data => attachUser(data.username));
    }
}
