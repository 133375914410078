<template>
    <div
        :class="mainBlockClasses"
        @scroll.stop
        @mouseover="mouseoverHandler"
    >
        <div :class="contentClasses">
            <top-bar showActionButtons />

            <div class="content-and-filter-wrap">
                <project />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import { Events, EventBus } from '@/events';
    import Project from './components/Project';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    import TopBar from './components/TopBar';
    import {scrollToSideBarItem} from '@/helpers/scroll-to-side-bar-item';
    export default {
        name: 'MainBlock',
        components: {
            'project': Project,
            'top-bar': TopBar,
        },
        mixins: [ToolTipRenderMixin],
        computed: {
            ...mapGetters([
                'getKeywordsIsFetching',
                'getSelectedAuth',
                'getCurrentProject',
                'getActiveTag',
                'getProjectById',
                'getUserDataProp',
            ]),
            contentClasses () {
                return {
                    'content': true,
                    'prevent-overflow': this.getKeywordsIsFetching,
                }
            },
            mainBlockClasses () {
                return {
                    'main-block': true,
                };
            },
        },
        watch: {
            $route (to) {
                if (this.$route.query && this.$route.query.query) {
                    this.setGlobalSearchQuery(this.$route.query.query);
                    this.fetchKeywordsFromSearch();
                }
                if (to.name === 'archived') {
                    this.setArchivedProjectsOpened(true);
                }
            },
            getActiveTag() {
                this.emitProjectViewEvent();
            },
            getCurrentProject(newProject, oldProject) {
                if(!oldProject && newProject) {
                    scrollToSideBarItem(newProject.project_id, this.getActiveTag.id);
                }
            }
        },
        created () {
            this.setIsViewkeyView(false);
        },
        mounted () {
            this.emitProjectViewEvent();
            if (this.$route.query && this.$route.query.query) {
                this.setGlobalSearchQuery(this.$route.query.query)
                this.fetchKeywordsFromSearch();
            }
            if (this.$route.name === 'archived') {
                this.setArchivedProjectsOpened(true);
            }
        },
        methods: {
            ...mapActions([
                'fetchKeywordsFromSearch',
                'getOtherSetting',
                'setGlobalSearchQuery',
                'setIsViewkeyView',
                'setArchivedProjectsOpened',
            ]),
            emitProjectViewEvent() {
                if (!this.getCurrentProject) {
                    setTimeout(this.emitProjectViewEvent, 1000);
                } else {
                    let tagView = !!Object.keys(this.getActiveTag).length;
                    EventBus.emit(Events.PROJECT_VIEW_EVENT, {
                        num_keywords_in_project:
                            tagView
                            ? this.getActiveTag.keywords_count ?? 0
                            : this.getCurrentProject.keywords_count?.ACTIVE ?? 0,
                        tag_view: tagView,
                    });
                }
            },
        }
    }
</script>

<style lang="scss">
    .dashboard-metric-wrap {
        padding-left: 34px;
        padding-right: 0px;
    }
    .main-block {
        @extend .custom-scroll;
        padding-bottom: 44px;
        position: relative;
        transition: all .3s ease;
        width: 100%;
        @media (max-width: 959px) {
            padding-bottom: 28px;
        }
        .content {
            @extend .custom-scroll;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            min-width: 650px;
            @media (max-width: 959px) {
                max-width: unset;
                min-width: unset;
                margin-left: 0;
                margin-right: 0;
            }
            &.prevent-overflow {
                overflow: hidden;
                .top-bar__wrapper {
                    top: 0;
                }
            }
        }
        .content-and-filter-wrap {
            display: flex;
            flex: 0 1 auto;
            width: 100%;
        }
    }
</style>
