import {BRAND_NAME} from '@/constants';
import i18n from '@/i18n';

const updateProjectPageTitle = (routeName, project) => {
    const postfix = ` - ${BRAND_NAME}`;

    if (routeName !== 'keywordList') {
        return;
    }

    if (project) {
        document.title = `${project.name}${postfix}`;
    }
};

const updateAlertsPageTitle = (routeName, unreadCount) => {
    const postfix = ` - ${BRAND_NAME}`;

    if (routeName !== 'alerts') {
        return;
    }

    document.title = `
        ${_.upperFirst(routeName)}
        ${unreadCount !== null && unreadCount > 0 ? `(${unreadCount})` : ''}
        ${postfix}
    `;
};

export default (route, {getCurrentProject, getUnreadAlertsCount}) => {
    const postfix = ` - ${BRAND_NAME}`;
    const viewkeyTitle = `${BRAND_NAME} ${i18n.t('ranking-report')}`;

    if (!route || !route.name || !getCurrentProject) {
        return false;
    }

    switch (route.name) {
        case 'keywordList':
            return updateProjectPageTitle(route.name, getCurrentProject);
        case 'shareViewkey':
        case 'shareViewkeyWithHash':
        case 'shareOfVoice':
        case 'shareOfVoiceWithHash':
        case 'shareKeywordsTable':
        case 'shareKeywordsTableWithHash':
            return document.title = getCurrentProject.client
                ? getCurrentProject.company_name
                    ? getCurrentProject.company_name
                    : viewkeyTitle
                : viewkeyTitle;
        case 'reports':
            return document.title = `${_.upperFirst(route.name)}${postfix}`;
        case 'alerts':
            return updateAlertsPageTitle(route.name, getUnreadAlertsCount);
        case 'billingPlan':
            return document.title = `Account Upgrade${postfix}`;
        case 'archived':
            return document.title = `Restore & Recover Categories / Keywords${postfix}`;
        case 'googleAnalytics':
            return document.title = `Google Analytics${postfix}`;
        case 'contactUs':
            return document.title = `Contact Form${postfix}`;
        case 'pdfView':
            return document.title = i18n.t('pdf-page-title');
        default:
            return document.title = `${_.startCase(route.name)}${postfix}`;
    }
};

export {updateAlertsPageTitle, updateProjectPageTitle};