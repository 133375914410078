import { render, staticRenderFns } from "./DistributionBlock.vue?vue&type=template&id=709dc6ac&scoped=true&"
import script from "./DistributionBlock.vue?vue&type=script&lang=js&"
export * from "./DistributionBlock.vue?vue&type=script&lang=js&"
import style0 from "./DistributionBlock.vue?vue&type=style&index=0&id=709dc6ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "709dc6ac",
  null
  
)

export default component.exports