import client from '../client';
import interceptors from '../interceptors';
const countriesInterceptor = interceptors.get('countries');

client.interceptors.response.use(
    countriesInterceptor.s,
    countriesInterceptor.e,
);

export default {
    fetchCountries: async () => await client.get('/countries'),
    fetchStates: async code => await client.get(`/country/${code}/states`),
};