import i18n from '@/i18n';
import { KW_TABLE_COLUMNS } from '@/constants';

const INTEGRATING_GSC_ACCOUNT_URL = 'http://support.keyword.com/en/articles/7182318-integrating-google-search-console-account';

export const fieldsConfig = [
    {
        name: 'checkbox',
        titleClass: 'column-header column-header_checkbox cursor-pointer',
        dataClass: 'column-data column-data_checkbox',
        width: 32,
        required: true,
        enabled: false,
        disabledInReports: false,
    },
    {
        name: 'kw',
        title: 'Keyword',
        optionName: 'Keyword',
        titleClass: 'column-header column-header_keyword cursor-pointer',
        dataClass: 'column-data column-data_keyword',
        sortField: 'kw',
        partOfTable: 1,
        required: true,
        enabled: false,
        apiAlias: 'kw',
        disabledInReports: false,
        dataCy: 'column-data',
    }, {
        name: 'searchIntents',
        optionName: i18n.t('search_intents'),
        title: i18n.t('search_intents'),
        titleClass: 'column-header cursor-pointer',
        enabled: true,
        dataClass: 'column-data column-data_search_intents',
        width: 110,
        required: false,
        sortField: false,
        apiAlias: 'search_intents',
        apiAliasReports: 'hide-search_intents',
        disabledInReports: true,
        disabledInPDF: true,
        hiddenForFree: true,
    }, {
        name: 'start',
        optionName: i18n.t('starting-rank'),
        title: 'Start',
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_start',
        sortField: 'start',
        width: 70,
        required: false,
        enabled: true,
        apiAlias: 'start',
        apiAliasReports: 'hide-start',
        disabledInReports: false,
    }, {
        name: 'gpage',
        optionName: i18n.t('google-ranking-page'),
        title: 'Page',
        titleClass: 'column-header column-header_gpage cursor-pointer',
        dataClass: 'column-data column-data_gpage',
        sortField: 'gpage',
        width: 71,
        required: false,
        enabled: true,
        apiAlias: 'gpage',
        apiAliasReports: 'hide-gpage',
        disabledInReports: false,
    }, {
        name: 'best',
        optionName: i18n.t('best-rank'),
        title: 'Best',
        titleClass: 'column-header',
        dataClass: 'column-data column-best_grank',
        sortField: 'best',
        width: 70,
        required: false,
        enabled: false,
        apiAlias: 'best',
        apiAliasReports: 'hide-best',
        disabledInReports: false,
    }, {
        name: 'grank',
        optionName: i18n.t('google-rank'),
        title: 'Rank',
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_grank',
        sortField: 'g',
        width: 75,
        required: false,
        enabled: true,
        apiAlias: 'g',
        apiAliasReports: 'hide-g',
        disabledInReports: false,
    }, {
        name: 'serpFeatures',
        optionName: i18n.t('serp-features'),
        title: i18n.t('serp-features'),
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_serp_features',
        sortField: 'serp_features',
        width: 140,
        required: false,
        enabled: true,
        apiAlias: 'serp_features',
        apiAliasReports: 'hide-serp_features',
        disabledInReports: false,
        disabledInPDF: true,
    }, {
        name: 'day',
        optionName: i18n.t('one-day-change'),
        title: '1d',
        titleClass: 'column-header column-header_borderless cursor-pointer',
        dataClass: 'column-data column-data_borderless',
        sortField: 'day',
        width: 59,
        required: false,
        enabled: true,
        apiAlias: 'day',
        apiAliasReports: 'hide-day',
        disabledInReports: false,
    }, {
        name: 'week',
        optionName: i18n.t('seven-day-change'),
        title: '7d',
        titleClass: 'column-header column-header_borderless cursor-pointer',
        dataClass: 'column-data column-data_borderless',
        sortField: 'week',
        width: 59,
        required: false,
        enabled: true,
        apiAlias: 'week',
        apiAliasReports: 'hide-week',
        disabledInReports: false,
    }, {
        name: 'month',
        optionName: i18n.t('thirty-day-change'),
        title: '30d',
        titleClass: 'column-header column-header_borderless cursor-pointer',
        dataClass: 'column-data column-data_borderless',
        sortField: 'month',
        width: 59,
        required: false,
        enabled: true,
        apiAlias: 'month',
        apiAliasReports: 'hide-month',
        disabledInReports: false,
    }, {
        name: 'life',
        optionName: i18n.t('life-change'),
        title: 'Life',
        titleClass: 'column-header column-header_borderless cursor-pointer',
        dataClass: 'column-data column-data_borderless',
        sortField: 'life',
        width: 59,
        required: false,
        enabled: true,
        apiAlias: 'life',
        apiAliasReports: 'hide-life',
        disabledInReports: false,
    }, {
        name: 'clicks',
        optionName: i18n.t('gsc-clicks'),
        title: 'Clicks',
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_clicks',
        sortField: 'clicks',
        width: 120,
        required: false,
        enabled: true,
        apiAlias: 'clicks',
        apiAliasReports: 'hide-clicks',
        disabledInReports: false,
        helperUrl: INTEGRATING_GSC_ACCOUNT_URL,
    }, {
        name: 'impressions',
        optionName: i18n.t('gsc-impressions'),
        title: 'Impressions',
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_impressions',
        sortField: 'impressions',
        width: 120,
        required: false,
        enabled: true,
        apiAlias: 'impressions',
        apiAliasReports: 'hide-impressions',
        disabledInReports: false,
        helperUrl: INTEGRATING_GSC_ACCOUNT_URL,
    }, {
        name: 'ctr',
        optionName: i18n.t('gsc-ctr'),
        title: 'CTR',
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_ctr',
        sortField: 'ctr',
        width: 120,
        required: false,
        enabled: true,
        apiAlias: 'ctr',
        apiAliasReports: 'hide-ctr',
        disabledInReports: false,
        helperUrl: INTEGRATING_GSC_ACCOUNT_URL,
    }, {
        name: 'searchvolume',
        optionName: i18n.t('ms'),
        title: 'Search Vol.',
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_searchvolume',
        sortField: 'ms',
        width: 120,
        required: false,
        enabled: true,
        apiAlias: 'ms',
        apiAliasReports: 'hide-ms',
        disabledInReports: false,
    }, {
        name: 'cpc',
        optionName: i18n.t('cpc'),
        title: i18n.t('cpc-short'),
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_cpc',
        sortField: 'cpc',
        width: 120,
        required: false,
        enabled: true,
        apiAlias: 'cpc',
        apiAliasReports: 'hide-cpc',
        disabledInReports: false,
    }, {
        name: 'competition',
        optionName: i18n.t('competition'),
        title: 'Competition',
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_competition',
        sortField: 'competition',
        width: 120,
        required: false,
        enabled: true,
        apiAlias: 'competition',
        apiAliasReports: 'hide-competition',
        disabledInReports: false,
    }, {
        name: 'seocomp',
        optionName: i18n.t('seo-comp'),
        title: 'Search Results',
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_seocomp',
        sortField: 'comp',
        width: 124,
        required: false,
        enabled: true,
        apiAlias: 'comp',
        apiAliasReports: 'hide-comp',
        disabledInReports: false,
    }, {
        name: 'tagsSplited',
        optionName: i18n.t('tags-uppercased'),
        title: 'Tags',
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_tags',
        width: 140,
        sortField: 'tags',
        required: false,
        enabled: false,
        apiAlias: 'tags',
        apiAliasReports: 'hide-tags',
        disabledInReports: false,
    }, {
        name: 'timestamp',
        optionName: i18n.t('date-added'),
        title: 'Date Added',
        titleClass: 'column-header cursor-pointer',
        dataClass: 'column-data column-data_timestamp',
        sortField: 'timestamp',
        width: 105,
        required: false,
        enabled: true,
        apiAlias: 'timestamp',
        apiAliasReports: 'hide-timestamp',
        disabledInReports: false,
    }, {
        name: 'near',
        optionName: i18n.t('near-geolocation'),
        title: 'Search Location',
        titleClass: 'column-header column-header_near cursor-pointer',
        dataClass: 'column-data column-data_left column-data_near',
        sortField: 'near',
        width: 172,
        required: false,
        enabled: true,
        apiAlias: 'near',
        apiAliasReports: 'hide-near',
        disabledInReports: false,
    }, {
        name: 'gmb',
        optionName: 'GMB',
        title: 'GMB',
        titleClass: 'column-header column-header_gmb cursor-pointer',
        dataClass: 'column-data column-data_left column-data_gmb',
        sortField: 'gmb',
        width: 242,
        required: false,
        enabled: true,
        apiAlias: 'gmb',
        apiAliasReports: 'hide-gmb',
        disabledInReports: false,
    }, {
        name: 'source',
        title: 'Source',
        optionName: i18n.t('source'),
        sortField: 'source',
        titleClass: 'column-header column-header_url cursor-pointer',
        dataClass: 'column-data column-data_left column-data_url',
        width: 102,
        required: false,
        enabled: true,
        apiAlias: 'source',
        apiAliasReports: 'hide-source',
        disabledInReports: false,
    }, {
        name: 'keyword_last_updated',
        title: 'keyword_last_updated',
        optionName: i18n.t('keyword_last_updated'),
        sortField: false,
        titleClass: 'column-header',
        dataClass: 'column-data column-data_left',
        width: 190,
        required: false,
        enabled: true,
        apiAlias: 'keyword_last_updated',
        apiAliasReports: 'hide-keyword_last_updated',
        disabledInReports: false,
        disabledInPDF: true,
    }, {
        name: 'url',
        title: 'URL',
        optionName: 'Url',
        apiAlias: 'url',
        sortField: 'domain',
        titleClass: 'column-header column-header_url cursor-pointer',
        dataClass: 'column-data column-data_left column-data_url',
        partOfTable: 1,
        required: true,
        enabled: true,
        disabledInReports: false,
    },
];

export default function (columnsSelection, gscConnected, isViewkey = false, columnSettings = null, isFreePlan = false) {
    const columns = columnsSelection.filter(el =>
        isFreePlan && el.hiddenForFree
            ? false
            : !gscConnected && KW_TABLE_COLUMNS.GSC.includes(el.name)
                ? false
                : isViewkey
                    ? !el.disabledInReports && (el.required || el.enabled)
                    : el.required || el.enabled,
    );
    const result = columns.slice();

    if (columnSettings?.order) {
        result.sort((a, b) => {
            const indexA = columnSettings.order.indexOf(a.apiAlias);
            const indexB = columnSettings.order.indexOf(b.apiAlias);

            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
            }

            if (indexA !== -1) return -1;
            if (indexB !== -1) return 1;

            return columns.indexOf(a.apiAlias) - columns.indexOf(b.apiAlias);
        });
    }

    if (columnSettings?.widths) {
        result.forEach(column => {
            column.width = columnSettings?.widths[column.apiAlias] ?? column.width;
        });
    }

    return result;
}

export const initColumnsConfig = hideColumns => {
    const fields = [];

    fieldsConfig.forEach(el => {
        el.enabled = el.apiAlias === 'comp'
            ? hideColumns.includes('comp')
            : !hideColumns.includes(el.apiAlias) || typeof el.apiAlias === 'undefined';

        el.disabledInReports = typeof el.apiAliasReports !== 'undefined'
            && hideColumns.includes(`hide-${el.apiAlias}`);

        fields.push(el);
    });

    return fields;
};
