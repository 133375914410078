const enNotes = {
    'delete-note-question': 'Do you want to delete this Note?',
    'edit-note': 'Edit note',
    'leave-note': 'Leave Note',
    'new-note-placeholder': 'Start typing your note...',
    'note-empty-err': 'Note cannot be empty',
    'note-limit-err': 'The limit is 500 characters. Please, shorten the note.',
    'note-text': 'Note text',
    'notes': 'Notes',
    'save-note': 'Save Note',
    'you-can-leave-note': 'You can leave a note for the project {project}',
};

export default enNotes;