<template functional>
    <div :class="`last-refreshed ${props.classes}`">
        <div v-if="props.isEmpty">Last updated: -</div>
        <template v-else>
            Last updated:
            <template v-if="props.isRefreshGathering">
                Gathering data...
                <img :src="require('img/icon-gathering.gif')" />
            </template>

            <template v-else>
                {{ props.lastUpdate.relative }}
            </template>
            
            {{ `(${props.lastUpdate.date})` }}
        </template>
    </div>
</template>

<script>
export default {
    name: 'LastRefreshed',
    props: {
        isRefreshGathering: {
            type: Boolean,
            required: false,
            default: false,
        },
        lastUpdate: {
            type: Object,
            required: true,
        },
        classes: {
            type: String,
            required: false,
            default: '',
        },
        isEmpty: {
            type: Boolean,
            required: false,
        },
    },
};
</script>

<style lang="scss">
.last-refreshed {
    color: #909399;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
    white-space: nowrap;
    min-height: 16px;
}
</style>
