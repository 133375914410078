<template>
    <li>
        <div class="viewkey-item" data-cy="viewkey-item-link">
            <div class="viewkey-item__input-label">
                <span class="kw kw-link" />
                <label class="text-label" :for="`viewkey-${project.id}`">
                    {{ $t('viewkey-link') }}
                </label>
            </div>
            <div class="viewkey-item__input">
                <input
                    :id="`viewkey-${project.id}`"
                    ref="viewkeyLink"
                    class="text-input"
                    :placeholder="$t('viewkey')"
                    readonly
                    type="text"
                    :value="viewkeyLink(project)"
                    data-cy="view-key-input"
                    @click="$event.target.select()"
                />
                <kw-button
                    :button-text="$t('copy')"
                    button-type="primary"
                    data-cy="viewkey-copy-button"
                    @click="
                        copyViewkey(
                            `viewkey-${project.id}`,
                            viewkeyLink(project),
                            project,
                        )
                    "
                />
            </div>
        </div>
        <div
            class="viewkey-item viewkey-item_embedded-code"
            data-cy="viewkey-item-embed"
            v-if="!hideEmbedded"
        >
            <div class="viewkey-item__input-label">
                <span class="kw kw-table" />
                <label class="text-label" :for="`embedded-code-${project.id}`">
                    {{ $t('embedded-code') }}
                </label>
            </div>
            <div class="viewkey-item__input">
                <input
                    :id="`embedded-code-${project.id}`"
                    :value="embeddedCode(project)"
                    class="text-input"
                    :placeholder="$t('viewkey')"
                    type="text"
                    readonly
                    @click="$event.target.select()"
                />
                <kw-button
                    :button-text="$t('copy')"
                    button-type="primary"
                    data-cy="embedded-copy-button"
                    @click="
                        copyViewkey(
                            `embedded-code-${project.id}`,
                            embeddedCode(project),
                            project,
                            true,
                        )
                    "
                />
            </div>
        </div>
    </li>
</template>

<script>
import ViewkeyLinks from '@/mixins/ViewkeyLinks';
export default {
    name: 'DashboardShareViewkey',
    mixins: [ViewkeyLinks],
    props: {
        project: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        hideEmbedded: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.$refs.viewkeyLink.select();
    },
};
</script>

<style lang="scss" scoped>
li {
    padding-bottom: 24px;
}
.viewkey-item {
    padding: 12px 18px 0;
}
.viewkey-item_embedded-code {
    display: none;
}
.viewkey-item__input-label {
    align-items: center;
    display: flex;
    padding-bottom: 10px;
}
.viewkey-item__input {
    display: flex;
    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        flex: 1 1 auto;
        font-size: 12px;
        height: 24px;
        padding: 0 6px;
        width: calc(100% - 50px);
    }
    button {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        height: 24px !important;
        /deep/ span {
            font-size: 11px;
        }
    }
}
.text-label {
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 0;
}
.kw-link,
.kw-table {
    font-size: 12px;
    font-weight: 700;
    margin-right: 8px;
}
@media (min-width: 960px) {
    .viewkey-item_embedded-code {
        display: block;
    }
}
</style>
