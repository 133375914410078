import apiFactory from '@/api';
import { DEFAULT_CURRENCY, IGNORE_CURRENCY } from '@/constants';
import { SET_CURRENCIES, SET_CURRENT_CURRENCY } from '../mutations';

const currenciesApi = apiFactory.get('currencies');

const currenciesStates = {
    state: {
        currencies: [],
        currentCurrency: DEFAULT_CURRENCY,
    },
    mutations: {
        [SET_CURRENCIES]: (state, payload) => (state.currencies = payload),
        [SET_CURRENT_CURRENCY]: (state, payload) => (state.currentCurrency = payload),
    },
    getters: {
        getCurrencies: state => state.currencies,
        getCurrentCurrency: state => state.currentCurrency,
        getCurrencyByCode: state => code => state.currencies.find(el => el.value === code),
    },
    actions: {
        setCurrentCurrency({ commit }, payload = null) {
            commit(SET_CURRENT_CURRENCY, payload ?? DEFAULT_CURRENCY);
        },
        async fetchCurrencies({ getters, commit }) {
            if (getters.getCurrencies.length) {
                return;
            }

            try {
                const res = await currenciesApi.fetchCurrencies();
                commit(SET_CURRENCIES, res.data.data
                    .filter(el => !IGNORE_CURRENCY.includes(el.code))
                    .sort((a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    }).map(el => (
                        {
                            label: `${el.name} (${el.code}/${el.symbol})`,
                            value: el.code,
                            symbol: el.symbol
                        })));
            } catch (error) {
                commit(SET_CURRENCIES, []);
            }
        },
    },
};

export default currenciesStates;
