var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-overview__inner app-table__wrapper"},[_c('div',{staticClass:"app-container"},[(
                _vm.getCheckedProjects.length &&
                !_vm.isEditProjectOpen &&
                _vm.$route.name === 'Overview'
            )?_c('overview-action-bar',{attrs:{"action-bar-type":_vm.$route.name,"manage-projects":_vm.manageProjects,"open-get-viewkey":_vm.openGetViewkey,"selected":_vm.getCheckedProjects,"selected-original-projects":_vm.getOriginalCheckedProjects}}):_vm._e(),_vm._v(" "),_c('custom-table',{attrs:{"items":_vm.projects,"headers":_vm.headers,"table-name":_vm.tableName,"cy-attribute":"overview","field-formatter":_vm.fieldFormatter,"sorting-options":_vm.getOverviewSort,"checkbox-options":_vm.checkboxOptions,"pagination-options":_vm.paginationOptions,"is-items-fetching":_vm.getOverviewDataLoading},on:{"page-change":_vm.handlePageChange,"updated":function () { return _vm.setCheckboxesOnUpdate(_vm.tableName, 'getCheckedProjects'); },"dropdown-item-before-open":function (project, resolve) { return _vm.showActions(project, resolve); },"handle-item-click":function (args) { return _vm.dropdownItemClick(args); },"header-checkbox-click":function($event){return _vm.toggleAllDashboardCheckboxes(_vm.tableName)},"header-column-click":_vm.changeSorter,"body-column-click-shift":function (ref) {
                                var e = ref[0];
                                var project = ref[1];
                                var index = ref[2];
                                var header = ref[3];

                                return _vm.handleColumnClick(header, project, index, e);
},"body-column-click-ctrl":function (ref) {
                                var e = ref[0];
                                var project = ref[1];
                                var index = ref[2];

                                return _vm.handleDashboardCheckboxClick(
                        project.id,
                        _vm.tableName,
                        index,
                        e,
                        _vm.projects
                    );
},"body-column-click-meta":function (ref) {
                                var e = ref[0];
                                var project = ref[1];
                                var index = ref[2];

                                return _vm.handleDashboardCheckboxClick(
                        project.id,
                        _vm.tableName,
                        index,
                        e,
                        _vm.projects
                    );
},"body-checkbox-click":function (ref) {
                                var e = ref[0];
                                var project = ref[1];
                                var index = ref[2];

                                return _vm.handleDashboardCheckboxClick(
                        project.id,
                        _vm.tableName,
                        index,
                        e,
                        _vm.projects
                    );
},"body-checkbox-click-stop":function (ref) {
                                var e = ref[0];
                                var project = ref[1];
                                var index = ref[2];

                                return _vm.handleDashboardCheckboxClick(
                        project.id,
                        _vm.tableName,
                        index,
                        e,
                        _vm.projects
                    );
}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }