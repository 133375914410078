
window._ = require('lodash');
window.axios = require('axios');

// We'll load the axios HTTP library which allows us to easily issue requests
// to our Laravel back-end. This library automatically handles sending the
// CSRF token as a header based on the value of the "XSRF" token cookie.
if (process.env.NODE_ENV === 'development') {
    if (typeof process.env.API_BASE_URL !== 'undefined' || typeof process.env.API_V2_BASE_URL !== 'undefined') {
        console.table({
            ['Axios base url']: process.env.API_BASE_URL,
            ['Axios APIv2 base url']: process.env.API_V2_BASE_URL,
        });
    } else {
        alert('Create .env file!');
    }
}

window.axios.defaults.baseURL = process.env.API_BASE_URL;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.params = {};