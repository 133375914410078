<template>
    <div
        class="dashboard-block"
        data-cy="metrics-box"
    >
        <div
            class="dash-total"
            data-cy="metrics-box-body"
        >
            <template v-if="!loading">
                <div :class="dashTotalClasses">
                    <span
                        class="keyword-change"
                        data-cy="keyword-change"
                    >
                        {{ block.count != '0' ? block.count : '-' }}
                    </span>
                    <template v-if="showKeywordsTotal">
                        <span
                            v-if="block.count != '0'"
                            class="divider"
                            v-text="'/'"
                        />
                        <span
                            v-if="block.count != '0'"
                            class="total-keyword-count"
                            data-cy="total-keyword-count"
                            v-text="totalCount"
                        />
                    </template>
                </div>
            </template>
            <template v-else>
                <placeholder-cell
                    :width="80"
                    :height="14"
                />
            </template>
            <div class="dash-title">
                <template v-if="!loading">
                    <span>
                        In {{ block.title }}
                    </span>
                </template>
                <placeholder-cell
                    v-else
                    :width="45"
                />
            </div>
        </div>
        <div
            :class="directionClasses"
            data-cy="metrics-box-direction"
        >
            <template v-if="!loading">
                <span :class="directionArrowClasses" />
                <span class="total-up">
                  
                    <span class="amount-keywords-changed">
                      <span v-if="showPercentage" class="percentage">{{ getPercentage(activeData.change) }}</span>
                      {{ addPlus(activeData.change) }}
                    </span> {{ periodTitle }}
                </span>
            </template>
            <placeholder-cell
                v-else
                :width="50"
                :height="8"
            />
        </div>
    </div>
</template>

<script>
    import {DEFAULTS_METRICS} from '@/constants';
    import {mapGetters} from 'vuex';
    export default {
        name: 'MetricsBlock',
        props: {
            block: {
                type: Object,
                required: true
            },
            loading: {
                type: Boolean,
                required: false,
                default: false,
            },
            totalCount: {
                type: Number,
                required: true
            },
            showPercentage: {
              type: Boolean,
              required: false,
              default: false,
            }
        },
        computed: {
            ...mapGetters([
                'getGrankRange',
                'showKeywordsTotal',
                'getDisplaySetting',
            ]),
            activeData () {
                switch (this.getDisplaySetting('metrics')) {
                    case 'daily':
                        return this.block.daily;
                    case 'life':
                    case 'lifetime':
                        return this.block.life;
                    case 'monthly':
                        return this.block.monthly;
                    case 'weekly':
                        return this.block.weekly;
                    default:
                        return this.block[DEFAULTS_METRICS.PROP];
                }
            },
            periodTitle () {
                switch (this.getDisplaySetting('metrics')) {
                    case 'daily':
                        return 'for 1 day';
                    case 'life':
                    case 'lifetime':
                        return 'since start';
                    case 'yearly':
                        return 'for 1 year';
                    case 'semester':
                        return 'for 6 months';
                    case 'monthly':
                        return 'for 30 days';
                    case 'weekly':
                        return 'for 7 days';
                    default:
                        return DEFAULTS_METRICS.TITLE;
                }
            },
            dashTotalClasses () {
                return {
                    count: true,
                    increase: this.activeData.status === 'up',
                    decrease: this.activeData.status === 'down',
                };
            },
            directionArrowClasses () {
                return {
                    'direction-arrow': true,
                    'icon-arrow_4 icon-rotate-down': this.activeData.status === 'down',
                    'icon-arrow_4': this.activeData.status === 'up',
                };
            },
            directionClasses () {
                return {
                    'change-direction': true,
                    'down': !this.loading && this.activeData.status === 'down',
                    'up': !this.loading && this.activeData.status === 'up',
              };
            }
        },
        methods: {
            addPlus (value) {
                return value > 0 ? `+${value}` : value;
            },
            getPercentage (value) {
              const original = this.block.count - value;
              if (value === 0 || original === 0 || isNaN(value)) return '';
              
              return ((value / original) * 100).toFixed(1) + '%';
            } 
        },
    }
</script>

<style lang="scss" scoped>
  .dashboard-block {
    background-color: kw-color(kw-white, 1);
    border: 1px solid kw-color(kw-light-grey, 1);
    border-radius: 4px;
    overflow: hidden;

    .placeholder_overview-table-cell {
      margin: 4px auto;
    }

    .dash-total {
      align-items: center;
      color: kw-color(kw-black, 1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 70px;
      padding: 3px 0 5px 0;

      .count {
        display: flex;
        align-items: baseline;
        justify-content: center;
      }

      .keyword-change {
        font-size: 36px;
        font-weight: 500;
        letter-spacing: .1;
        line-height: 42px;
      }

      .total-keyword-count,
      .divider {
        font-size: 16px;
        color: kw-color(kw-grey, 1);
      }

      @media (max-width: 1279px) {
        .total-keyword-count, .divider {
          font-size: 13px;
        }
      }
      @media (max-width: 959px) {
        .keyword-change {
          font-size: 24px;
          line-height: 28px;
        }
        .total-keyword-count, .divider {
          font-size: 10px;
        }
      }
    }

    .increase {
      color: kw-color(kw-green, 1);
    }

    .decrease {
      color: rgb(193, 55, 55);
    }

    .dash-title {
      align-items: center;
      color: kw-color(kw-grey, 1);
      display: flex;
      font-size: 14px;
      justify-content: center;
      line-height: 20px;
      text-align: center;
    }

    .change-direction {
      align-items: center;
      border-top: 1px solid kw-color(kw-light-grey, 1);
      color: kw-color(kw-black, 1);
      display: flex;
      font-size: 14px;
      height: 34px;
      justify-content: center;
      line-height: 15px;
      position: relative;

      &.up {
        background-color: #F2FBF4;
      }

      &.down {
        background-color: #FBF3F2;
      }

      .total-up {
        justify-self: center;
        @media (min-width: 960px) and (max-width: 1279px) {
          font-size: 12px;
        }
      }

      .direction-arrow {
        font-size: 10px;
        margin-right: 3px;
        color: kw-color(kw-green, 1);

        &.icon-rotate-down {
          color: kw-color(kw-red, 1);
        }
      }
    }

    .amount-keywords-changed {
      font-weight: bold;
      .percentage {
        margin-right: 3px;
      }
    }

    //responsive design
    @media screen and (max-width: 959px) {
      .dash-total {
        min-height: unset;
        padding: 1px 0 4px 0;

        .count {
          margin-top: unset;
        }
      }
      .dash-title {
        font-size: 12px;
        line-height: 14px;
      }
      .change-direction {
        font-size: 12px;
        height: 21px;
        position: relative;

        .direction-arrow {
          display: none;
        }
      }
    }
  }
</style>
