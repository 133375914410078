<template>
    <div class="custom-modal import-finished" data-cy="import-finished">
        <div class="custom-modal__header">
            {{ $t('importing-process-finished') }}
            <closer @click="close" />
        </div>

        <div class="custom-modal__body">
            <div class="importing-process-msg">
                {{ $t('importing-process-finished-msg') }}
            </div>
        </div>

        <div class="custom-modal__footer">
            <kw-button
                button-type="text"
                :button-text="$t('refresh-later')"
                @click="close"
            />
            <kw-button
                button-type="primary"
                :button-text="$t('refresh-now')"
                @click="refresh"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImportFinishedModal',
    methods: {
        refresh() {
            location.reload();
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/modal';
.import-finished {
    padding-bottom: 0;
}
</style>
