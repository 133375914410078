export const getPercentage = (count, total) => {
    return !total ? '0%' : `${(count * 100 / total).toFixed(2)}%`
};

export const detectIsViewKey = () => {
    const path = window.location.pathname;
   
    const shareRegex = /^\/(project-share|tag-share|share-view)/;

    if(shareRegex.test(path)){
        return true;
    }

    const regex = /^\/projects\/([^\/]+)\/([^\/]+)(?:\/([^\/]+))?$/;
    const match = path.match(regex);

    if (match && match.length >= 3 && match[3] !== 'add') {
        return true;
    }

    return null;
};

export const detectIsSurferSEO = () => {
    return window.location.host === "ranktracker.surferseo.app" || window.location.host === "ranktracker.surferseo.com" || window.location.search.includes('whitelabel=surferseo');
}

export const isEmptyObject = (value) => {
    if(!value) return true;
    if(typeof value === 'object' && !Array.isArray(value)){
        return !Object.values(value).some(item => item !== null);
    } else {
        return true;
    }
}
