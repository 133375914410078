<template>
    <div class="dashboard-overview__inner">
        <chart-and-metrics
            v-for="(project, i) in getProjectsWithOverviewChartsData"
            :key="`${i}_${getOverviewChartsDataParams.page}`"
            :project="project"
            :loading="getOverviewChartsDataLoading"
        />

        <table-pagination
            v-if="getOverviewChartsTotalPages > 1"
            :current-page="getOverviewChartsDataParams.page"
            :on-change-page="handlePageChange"
            :per-page="getOverviewChartsDataParams.per_page"
            :total-pages="getOverviewChartsTotalPages"
            :total-records="getOverviewChartsTotalItems"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChartAndMetrics from 'components/common/ChartAndMetrics';
export default {
    name: 'OverviewChartView',
    components: {
        'chart-and-metrics': ChartAndMetrics,
    },
    computed: {
        ...mapGetters([
            'getProjectsWithOverviewChartsData',
            'getOverviewChartsDataLoading',
            'getOverviewChartsDataParams',
            'getOverviewChartsTotalItems',
            'getOverviewChartsTotalPages',
        ]),
    },
    mounted() {
        // overview data is fetching after page change
        this.expandedOverviewPageChanged(1);
    },
    methods: {
        ...mapActions(['expandedOverviewPageChanged']),
        handlePageChange(value) {
            if (this.getOverviewChartsDataParams.page !== value) {
                this.expandedOverviewPageChanged(value);
                window.scrollTo(0, 0);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.dashboard-overview__inner {
    margin: 11px auto 0 auto;
}
</style>
