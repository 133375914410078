import Vue from 'vue';
import VueI18n from 'vue-i18n';
import En from './localization/en';

Vue.use(VueI18n);

const i18n = new VueI18n({
    messages: {
        'en-US': En
    }
});
export default i18n;