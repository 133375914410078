<template>
    <div
        v-click-outside="handleClickOutSide"
        class="export-dropdown dropdown_container"
    >
        <kw-button
            :button-text="$t('export')"
            button-type="secondary"
            prepend-icon-class="kw kw-download"
            data-cy="dashboard-export-button"
            @click="handleButtonClick"
        />
        <custom-dropdown-body ref="exportDropdown">
            <ul
                class="v-context"
                data-cy="dashboard-export-menu"
            >
                <li
                    v-for="(item, i) in exportsConfigs"
                    :id="item.type"
                    :key="i"
                    @click.stop="exportClick(item.type, item.event)"
                >
                    <template v-if="!item.hide">
                        <div
                            v-if="item.type === 'subtitle'"
                            :data-cy="item.dataCy"
                            class="v-context__subtitle"
                            v-text="item.text"
                        />
                        <hr
                            v-else-if="item.type === 'divider'"
                            class="v-context__divider"
                        >
                        <a
                            v-else
                            :data-cy="item.dataCy"
                        >
                            <span
                                v-if="item.class"
                                :class="item.class"
                            />
                            {{ item.text }}
                        </a>
                    </template>
                </li>
            </ul>
        </custom-dropdown-body>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { Events, EventBus } from '@/events';
    import { getFetchFilters } from '@/store/utils';
    import { pdfExportParams, REPORT_TYPE } from '@/constants';
    import ProjectsActionsMixin from '@/mixins/ProjectsActionsMixin';

    export default {
        name: 'ExportDropdown',
        mixins: [ProjectsActionsMixin],
        props: {
            project: {
                type: String,
                required: false,
                default: '',
            },
            subproject: {
                type: String,
                required: false,
                default: '',
            },
        },
        computed: {
            ...mapGetters([
                'getViewkeyView',
                'getSearchKeyword',
                'getDataForViewkey',
                'getCurrentProject',
            ]),
            exportsConfigs () {
                return [
                    { type: 'subtitle', text: this.$t('export') },
                    { type: 'export-project-all', class: 'kw kw-download', text: 'TXT', dataCy: 'export-project-all', event: Events.EXPORT_PROJECT_CSV },
                    { type: 'export-project-pdf', class: 'kw kw-download', text: 'PDF', dataCy: 'export-project-pdf', event: Events.EXPORT_PROJECT_TEXT },
                    { type: 'export-project-csv', class: 'kw kw-download', text: 'CSV', dataCy: 'export-project-csv', event: Events.EXPORT_PROJECT_PDF },
                ];
            },
        },
        methods: {
            exportClick (type, event) {
                if (!this.getCurrentProject) {
                    return;
                }

                EventBus.emit(event, {type, project: this.getCurrentProject, shared: this.getViewkeyView});

                let txtParams = {};
                switch (type) {
                    case 'export-project-all':
                        if(!this.getViewkeyView) {
                            txtParams = this.getCurrentProject;
                        }else {
                            txtParams = {
                                auth: this.$route.params.auth,
                                shortkey: this.$route.params.viewkey,
                                password: this.getDataForViewkey.password,
                                id:  this.subproject || this.project
                            }
                        }
                        this.exportProjectAsTXT(txtParams, getFetchFilters(this.$store.getters), this.getViewkeyView);
                        break;

                    case 'export-project-csv':
                        this.exportProjectAsCSV(this.getCurrentProject);
                        break;

                    case 'export-project-pdf':
                        this.exportReport(this.getCurrentProject, {
                            report_type: REPORT_TYPE.PDF,
                            searchQuery: this.getSearchKeyword,
                            ...pdfExportParams,
                            ...getFetchFilters(this.$store.getters),
                        });
                        break;
                }

                this.$refs.exportDropdown.toggleDropdownOpen();
            },
            handleClickOutSide () {
                if(this.$refs.exportDropdown?.getIsDropdownOpened) {
                    this.$refs.exportDropdown.closeDropdown();
                }
            },
            handleButtonClick () {
                this.$refs.exportDropdown.toggleDropdownOpen();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .export-dropdown {
        display: flex;
        flex-flow: row nowrap;
        /deep/ .top-bar__button-export {
            padding: 8px 10px;
        }
    }
    .v-context {
        width: 250px;
        position: relative;
    }
    .custom_dropdown_body {
        box-shadow: none !important;
        margin-top: 5px !important;
    }
    @media screen and (max-width: 959px) {
        .export-dropdown {
            flex-grow: 1;
            justify-content: space-between;
        }
        /deep/ .top-bar__button-export {
            padding: 8px 10px;
            span {
                font-size: 14px;
            }
        }
    }
</style>
