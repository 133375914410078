import {getRangeTitle, calculateCompetitorsChange} from '@/helpers/process-metrics';
import {metricsEmptyData, prepareMetricsData, getFetchFilters} from '@/store/utils';
import apiFactory from '@/api';
import router from '@/routes';

const metricsApi = apiFactory.get('metrics');

const colors = [
    '#B1B7BD',
    '#B6C6E4',
    '#EDC399',
    '#F1BE83',
    '#569B3D',
    '#ADDA95',
    '#BE4135',
    '#ED9F9B',
    '#866DB5',
    '#DEDCDF',
];

import {
    SET_ALL_GROUPS_METRICS_DATA,
    SET_METRICS_AND_CHART_IS_FETCHING,
    SET_METRICS_AND_CHARTS_DATA,
    SET_METRICS_DATA,
    SET_METRICS_IS_FETCHING,
    SET_METRICS_FETCHING_COUNT,
    SET_MINDSHARE_LATEST_DATA,
    SET_MINDSHARE_HISTORY_DATA,
    SET_MINDSHARE_HISTORY_DATA_LOADING,
    SET_MINDSHARE_DATA_CALCULATION_STATE,
    RESET_MINDSHARE_DATA,
    SET_MINDSHARE_DATA_LIMIT_EXCEED,
    SET_MINDSHARE_HISTORY_DAYS_RANGE,
    SET_MINDSHARE_RANKING_DAYS_RANGE,
    SET_AVERAGE_RANKS_DATA,
    SET_AVERAGE_RANKS_DATA_LOADING,
    SET_MINDSHARE_DATA_CALCULATION,
    SET_VIEWKEY_METRICS_IS_FETCHING,
    SET_VIEWKEY_CHART_IS_FETCHING,
    SET_VIEWKEY_KEYWORDS_IN_TOP_POSITIONS_DATA,
} from '../mutations';
import { datePickerList } from '@/components/date-range-picker/datePickerList';

export default {
    state: {
        allProjectsMetricsData: {},
        averageRanksData: {},
        averageRanksDataLoading: false,
        metricsAndChartData: {},
        metricsAndChartIsFetching: true,
        metricsData: metricsEmptyData(),
        metricsIsFetching: false,
        metricsFetchingCount: 0,
        mindshareLatestData: [],
        mindshareHistoryData: [],
        mindshareHistoryDataLoading: false,
        isMindshareDataCalculationInProgress: true,
        mindshareDataLimitExceed: false,
        mindshareHistoryDaysRange: { value: 30, label: '30 Days' },
        mindshareRankingDaysRange: { value: 30, label: '30 Days' },
        mindshareDataCalculation: {},
        viewkeyMetricsIsFetching: false,
        viewkeyChartIsFetching: false,
        viewkeyKeywordsInTopPositionsData: {
            data: null,
            categories: []
        }
    },
    mutations: {
        [SET_ALL_GROUPS_METRICS_DATA]: (state, payload) => (state.allProjectsMetricsData = payload),
        [SET_AVERAGE_RANKS_DATA]: (state, payload) => (state.averageRanksData = payload),
        [SET_AVERAGE_RANKS_DATA_LOADING]: (state, payload) => (state.averageRanksDataLoading = payload),
        [SET_METRICS_AND_CHART_IS_FETCHING]: (state, payload) =>
            (state.metricsAndChartIsFetching = payload),
        [SET_METRICS_AND_CHARTS_DATA]: (state, payload) => (state.metricsAndChartData = payload),
        [SET_METRICS_DATA]: (state, payload) => (state.metricsData = payload),
        [SET_METRICS_IS_FETCHING]: (state, payload) => (state.metricsIsFetching = payload),
        [SET_METRICS_FETCHING_COUNT]: (state, payload) => (state.metricsFetchingCount = payload),
        [SET_MINDSHARE_LATEST_DATA]: (state, payload) => (state.mindshareLatestData = payload),
        [SET_MINDSHARE_HISTORY_DATA]: (state, payload) => (state.mindshareHistoryData = payload),
        [SET_MINDSHARE_DATA_CALCULATION_STATE]: (state, calculation) => (state.isMindshareDataCalculationInProgress = calculation.is_in_progress),
        [SET_MINDSHARE_DATA_CALCULATION]: (state, calculation) => (state.mindshareDataCalculation = calculation),
        [SET_MINDSHARE_HISTORY_DATA_LOADING]: (state, payload) =>
            (state.mindshareHistoryDataLoading = payload),
        [RESET_MINDSHARE_DATA]: state => (
            (state.mindshareLatestData = []), (state.mindshareHistoryData = [])
        ),
        [SET_MINDSHARE_DATA_LIMIT_EXCEED]: (state, payload) =>
            (state.mindshareDataLimitExceed = payload),
        [SET_MINDSHARE_HISTORY_DAYS_RANGE]: (state, payload) =>
            (state.mindshareHistoryDaysRange = payload),
        [SET_MINDSHARE_RANKING_DAYS_RANGE]: (state, payload) =>
            (state.mindshareRankingDaysRange = payload),
        [SET_VIEWKEY_METRICS_IS_FETCHING]: (state, payload) => (state.viewkeyMetricsIsFetching = payload),
        [SET_VIEWKEY_CHART_IS_FETCHING]: (state, payload) => (state.viewkeyChartIsFetching = payload),
        [SET_VIEWKEY_KEYWORDS_IN_TOP_POSITIONS_DATA]: (state, payload) => (state.viewkeyKeywordsInTopPositionsData = payload),
    },
    getters: {
        getMetricsFetchingCount: state => state.metricsFetchingCount,
        getMetricsData: state => state.metricsData,
        getViewkeyChartData: state => state.viewkeyChartData,
        getAverageRanksData: state => state.averageRanksData,
        getAverageRanksDataLoading: state => state.averageRanksDataLoading,
        getAllProjectsMetricsData: (state, getters) => {
            const { allProjectsMetricsData } = state;
            const { getDisplaySetting } = getters;

            // in case of data is empty or fetching - show gap
            if (_.isEmpty(allProjectsMetricsData)) {
                return { loading: true, ...metricsEmptyData() };
            }

            return prepareMetricsData(
                allProjectsMetricsData,
                i => getDisplaySetting('metrics_range')[i],
            );
        },
        getMetricsIsFetching: state => state.metricsIsFetching,
        getMetricsAndChartIsFetching: state => state.metricsAndChartIsFetching,
        getMindshareHistoryDataLoading: state => state.mindshareHistoryDataLoading,
        getProjectWithOverviewChartsData: (state, getters) => {
            const { metricsAndChartData } = state;
            const { metricsData } = state;
            const { name: routeName} = router.history.current;

            if (routeName === 'keywordList' && metricsAndChartData.attributes && metricsData) {
                metricsAndChartData.attributes = metricsData;
            }

            const { getDisplaySetting } = getters;

            // in case of data is empty or fetching - show gap
            if (_.isEmpty(metricsAndChartData)) {
                return { loading: true, ...metricsEmptyData() };
            }

            return prepareMetricsData(
                metricsAndChartData,
                i => getDisplaySetting('metrics_range')[i],
            );
        },
        getMindshareLatestData: state => {
            const { mindshareLatestData } = state;
            if (!mindshareLatestData) {
                return;
            }
            const res = mindshareLatestData.map((value, index) => {
                return {
                    rank: index + 1,
                    domain: value.url.replace(/(^\w+:|^)\/\//, '').replace('www.', ''),
                    share: `${value.percentage.toFixed(2)}%`,
                    color: colors[index],
                    change: calculateCompetitorsChange(value),
                    domain_exist_in_project: value.domain_exist_in_project,
                };
            });
            return res;
        },
        getMindshareHistoryDates: state => {
            const { mindshareHistoryData } = state;
            if (Object.keys(mindshareHistoryData).length === 1) {
                return [];
            }
            return Object.keys(mindshareHistoryData).reverse();
        },
        getMindshsareHistoryData: state => {
            const { mindshareHistoryData } = state;

            // if only the latest key exists
            if (Object.keys(mindshareHistoryData).length === 1) {
                return [];
            }
            const data = [];

            // Simplify this data
            // Top ten data including the history and organized by domain;
            Object.keys(mindshareHistoryData).map(date => {
                return mindshareHistoryData[date].slice(0, 10).map((value, index) => {
                    data.push({
                        rank: index + 1,
                        domain: value.url.replace(/(^\w+:|^)\/\//, '').replace('www.', ''),
                        share: `${value.percentage.toFixed(2)}%`,
                        date: date,
                    });
                });
            });

            // Now group by domain and include the data in a simple array
            const response = data.reverse().reduce((result, item) => {
                let domain = (result[item.domain] = result[item.domain] || {});
                let data = (domain['data'] = domain['data'] || []);
                domain.domain = item.domain;
                domain.rank = item.rank;
                data.push(item.share);
                return result;
            }, {});

            return response;
        },
        isMindshareDataLimitExceed: state => state.mindshareDataLimitExceed,
        getMindshareteHistoryDaysRange: state => state.mindshareHistoryDaysRange,
        getMindshareRankingDaysRange: state => state.mindshareRankingDaysRange,
        getIsMindshareDataCalculationInProgress: state => state.isMindshareDataCalculationInProgress,
        getMindshareDataCalculation: state => state.mindshareDataCalculation,
        getViewkeyChartIsFetching: state => state.viewkeyChartIsFetching, 
        getViewkeyKeywordsInTopPositionsData: state => state.viewkeyKeywordsInTopPositionsData, 
    },
    actions: {
        async fetchViewkeyMetricsAndChart({ commit, getters }) {
            commit(SET_METRICS_FETCHING_COUNT, getters.getMetricsFetchingCount+1);
            commit(SET_METRICS_AND_CHART_IS_FETCHING, true);
            const shortkey = getters.getProjectProperty('shortkey');
            const auth = getters.getProjectProperty('auth');
            const password = getters.getProjectProperty('password');
            
            const timeframe = getters.getDisplaySetting('metrics');

            const queryString = {
                params: { auth, password, timeframe, ...getFetchFilters(getters) },
            };
            try {
                let res;
                const { name: routeName, params: routeParams } = router.history.current;
                if(routeName === 'shareViewkeyWithHash' || routeName === 'shareKeywordsTableWithHash') {
                    // view-key api with hash
                    res = await metricsApi.fetchViewkeyChartsMetricsWithHash(routeParams.hash, queryString);
                } else {
                    res = await metricsApi.fetchViewkeyChartsMetrics(shortkey, queryString);
                }

                if (getters.getCurrentProject.id == res.data.data.id) {
                    commit(SET_METRICS_AND_CHARTS_DATA, res.data.data);
                }
            } catch (error) {
                //
            } finally {
                commit(SET_METRICS_FETCHING_COUNT, getters.getMetricsFetchingCount-1);

                if (getters.getMetricsFetchingCount === 0) {
                    commit(SET_METRICS_AND_CHART_IS_FETCHING, false);
                    commit(SET_METRICS_IS_FETCHING, false);
                }
            }
        },
        async fetchViewkeyMetrics({ commit, getters }) {
            try {
                const { getCurrentProject, getDisplaySetting } = getters;

                if (!getCurrentProject) {
                    return;
                }
                // commit(SET_METRICS_FETCHING_COUNT, getters.getMetricsFetchingCount+1);
                commit(SET_METRICS_IS_FETCHING, true);

                let res;
                if(getters.getViewkeyView) {
                    const viewkey = getters.getProjectProperty('shortkey');
                    const { name: routeName, params: routeParams } = router.history.current;
                    const auth = getters.getProjectProperty('auth');
                    const password = getters.getProjectProperty('password');
                    if(
                        routeName === 'shareViewkeyWithHash' ||
                        routeName === 'shareOfVoiceWithHash' ||
                        routeName === 'shareKeywordsTableWithHash'
                    ) {
                        // view-key api with hash
                        res = await metricsApi.fetchViewkeyChartsMetricsWithHash(routeParams.hash, {
                            params: { auth, password, include: 'chart', ...getFetchFilters(getters) }
                        });
                    } else {
                        res = await metricsApi.fetchViewkeyChartsMetrics(viewkey, {
                            params: { include: 'chart', viewkey, auth },
                        });
                    }
                } else {
                    res = await metricsApi.fetchMetrics(getCurrentProject.id, {
                        params: { include: 'chart', ...getFetchFilters(getters) },
                    });
                }

                if (getters.getCurrentProject.id == res.data.data.id || getters.getCurrentProject.parent == res.data.data.id) {
                    commit(SET_METRICS_DATA, {
                        ...res.data.data.attributes,
                        ranges: res.data.data.attributes.ranges.map((r, i) => ({
                            ...r,
                            range: getDisplaySetting('metrics_range')[i],
                            title: getRangeTitle(getDisplaySetting('metrics_range')[i]),
                        })),
                    });
                }

                commit(SET_MINDSHARE_DATA_CALCULATION_STATE, false);
            } catch (error) {
                //
            } finally {
                commit(SET_METRICS_AND_CHART_IS_FETCHING, false);
                commit(SET_METRICS_IS_FETCHING, false);
            }
        },
        // added includeChart parameter to fetch metrics without charts, since the request is very heavy
        async fetchMetrics({ commit, getters }, payload = { includeChart: true }) {
            const { getCurrentProject, getDisplaySetting } = getters;

            if (!getCurrentProject) {
                return;
            }
            commit(SET_METRICS_FETCHING_COUNT, getters.getMetricsFetchingCount+1);
            commit(SET_METRICS_IS_FETCHING, true);

            const params = { ...getFetchFilters(getters) };
            const { name: routeName } = router.history.current;

            if (payload.includeChart && routeName !== 'keywordList') {
                params.include = 'chart';
            }
            params.timeframe = getDisplaySetting('metrics');
            
            try {
                const res = await metricsApi.fetchMetrics(getCurrentProject.id, { params });

                if (getCurrentProject?.id == res.data.data.id || getCurrentProject?.parent == res.data.data.id) {
                    commit(SET_METRICS_DATA, {
                        ...res.data.data.attributes,
                        id: res.data.data.id,
                        name: res.data.data.id,
                        charts: res.data.data.attributes.chart &&
                            res.data.data.attributes.chart.length &&
                            res.data.data.attributes.chart.map(chart => ({
                                data: chart[1],
                                title: chart[0],
                            })),
                        ranges: res.data.data.attributes.ranges.map((r, i) => ({
                            ...r,
                            range: getDisplaySetting('metrics_range')[i],
                            title: getRangeTitle(getDisplaySetting('metrics_range')[i]),
                        })),
                    });
                }
            } catch (e) {
                console.error(e);
            } finally {
                commit(SET_METRICS_FETCHING_COUNT, getters.getMetricsFetchingCount-1);

                if (getters.getMetricsFetchingCount === 0) {
                    commit(SET_METRICS_AND_CHART_IS_FETCHING, false);
                    commit(SET_METRICS_IS_FETCHING, false);
                }
            }
        },
        async fetchMetricsForPdf({ commit, getters }, parameters) {
            const { getCurrentProject } = getters;

            if (!getCurrentProject) {
                return;
            }

            commit(SET_METRICS_FETCHING_COUNT, getters.getMetricsFetchingCount+1);
            commit(SET_METRICS_AND_CHART_IS_FETCHING, true);
            try {
                const res = await metricsApi.fetchViewkeyChartsMetrics(parameters.viewkey, {
                    params: { ...parameters },
                });

                if (getters.getCurrentProject.id == res.data.data.id) {
                    commit(SET_METRICS_AND_CHARTS_DATA, res.data.data);
                }
            } catch (e) {
                console.error(e);
            } finally {
                commit(SET_METRICS_FETCHING_COUNT, getters.getMetricsFetchingCount-1);

                if (getters.getMetricsFetchingCount === 0) {
                    commit(SET_METRICS_IS_FETCHING, false);
                    commit(SET_METRICS_AND_CHART_IS_FETCHING, false);
                }
            }
        },
        resetMetricsData({ commit }) {
            commit(SET_METRICS_DATA, metricsEmptyData());
        },
        async fetchAllProjectsMetricsData({ commit, getters }) {
            commit(SET_METRICS_FETCHING_COUNT, getters.getMetricsFetchingCount+1);
            commit(SET_METRICS_IS_FETCHING, true);

            try {
                const res = await metricsApi.fetchBulkMetrics();
                commit(SET_ALL_GROUPS_METRICS_DATA, res.data.data);
            } catch (e) {
                console.error(e);
            } finally {
                commit(SET_METRICS_FETCHING_COUNT, getters.getMetricsFetchingCount-1);

                if (getters.getMetricsFetchingCount === 0) {
                    commit(SET_METRICS_IS_FETCHING, false);
                    commit(SET_METRICS_AND_CHART_IS_FETCHING, false);
                }
            }
        },
        async fetchMindshareHistory({ commit, getters, dispatch }, data) {
            let interval = data?.interval;
            const { getCurrentProject, getMindshareteHistoryDaysRange, getProjectProperty } = getters;
            const shortkey = getProjectProperty('shortkey');
            //TODO: change after porject overview launch
            if(!interval) {
                Object.values(datePickerList).forEach((range)=>{
                    if(range.count === getMindshareteHistoryDaysRange.value){
                        interval = {
                            start_date: range.value[0],
                            end_date: range.value[1],
                        }
                    }
                });
            }

            if (!getCurrentProject) {
                return;
            }

            commit(SET_MINDSHARE_DATA_LIMIT_EXCEED, false);
            commit(SET_MINDSHARE_HISTORY_DATA_LOADING, true);
            try {
                let res;
                const isFetchFromHistory = process.env.FETCH_SOV_FROM_HISTORY === 'true';

                if (getters.getViewkeyView) {
                    const { name: routeName, params: routeParams } = router.history.current;
                    const auth = getters.getProjectProperty('auth');

                    if (
                        routeName === 'shareViewkeyWithHash' ||
                        routeName === 'shareOfVoiceWithHash' ||
                        routeName === 'shareKeywordsTableWithHash'
                    ) {
                        // view-key api with hash
                        res = await metricsApi.fetchViewkeyMindshareHistoryWithHash(routeParams.hash, {
                            params: {
                                ...getFetchFilters(getters),
                                per_page: 1000,
                                type: ['se', 'sem'],
                                ...(interval ? {...interval} : {}),
                            },
                        }, isFetchFromHistory);
                    } else {
                        res = await metricsApi.fetchViewkeyMindshareHistory(shortkey, {
                            params: {
                                ...getFetchFilters(getters),
                                per_page: 1000,
                                type: ['se', 'sem'],
                                ...(interval ? {...interval} : {}),
                                auth
                            },
                        }, isFetchFromHistory);
                    }
                } else {
                    const id = getCurrentProject.project_id;

                    res = await metricsApi.fetchMindshareHistory(id, {
                        params: {
                            ...getFetchFilters(getters),
                            per_page: 1000,
                            type: ['se', 'sem'],
                            ...(interval ? {...interval} : {})
                        },
                    }, isFetchFromHistory);
                }

                commit(SET_MINDSHARE_HISTORY_DATA, res.data.data);
                if (res.data.sov_calculation) {
                    commit(SET_MINDSHARE_DATA_CALCULATION_STATE, res.data.sov_calculation)
                    commit(SET_MINDSHARE_DATA_CALCULATION, res.data.sov_calculation)
                    if (!res.data.sov_calculation.is_in_progress) {
                        await dispatch('fetchLastAddedKeyword');
                    }
                }
                //@TODO/ fast solution for MindShareLatest Data must be changed
                //>>>
                    const objKeysToArr = Object.keys(res.data.data);
                    const firstItem = res.data.data[objKeysToArr[0]];
                    const lastItem = res.data.data[objKeysToArr[objKeysToArr.length - 1]];
                    const latestDataToStore = firstItem.map((item) => {
                        const dataForReturn = {
                            ...item,
                            percentage: +item.percentage.toFixed(2),
                        };
                        lastItem.forEach((item1) => {
                            if(item1.url === item.url) {
                                dataForReturn.changes = {
                                    month: +item1.percentage.toFixed(2),
                                }
                            }
                        });
                        return dataForReturn;

                    });
                    commit(SET_MINDSHARE_LATEST_DATA, latestDataToStore);
                //<<<
            } catch (error) {
                console.log(error,'error')
                if (error.type === 'limit_exceed') {
                    commit(SET_MINDSHARE_DATA_LIMIT_EXCEED, true);
                }
            } finally {
                commit(SET_MINDSHARE_HISTORY_DATA_LOADING, false);
            }
        },
        async fetchAverageRanks({ commit, getters }) {
            const { getCurrentProject, getMindshareRankingDaysRange, getProjectProperty } = getters;
            const queryParams = { daysrange: getMindshareRankingDaysRange.value, ...getFetchFilters(getters) };

            if (!getCurrentProject) {
                return;
            }

            commit(SET_AVERAGE_RANKS_DATA_LOADING, true);

            try {
                let res;

                if (getters.getViewkeyView) {
                    const { name: routeName, params: routeParams } = router.history.current;
                    if (
                        routeName === 'shareViewkeyWithHash' ||
                        routeName === 'shareOfVoiceWithHash' ||
                        routeName === 'shareKeywordsTableWithHash'
                    ) {
                        // view-key api with hash
                        res = await metricsApi.fetchViewkeyMetricsRankingsWithHash(routeParams.hash, {
                            params: queryParams
                        });
                    } else {
                        const shortkey = getProjectProperty('shortkey');
                        const auth = getters.getProjectProperty('auth');
                        res = await metricsApi.fetchViewkeyMetricsRankings(shortkey, {
                            params: { ...queryParams, auth },
                        });
                    }
                } else {
                    res = await metricsApi.fetchMetricsRankings(getCurrentProject.id, {
                        params: queryParams,
                    });
                }

                commit(SET_AVERAGE_RANKS_DATA, res.data.data);
            } catch (error) {
                commit(SET_AVERAGE_RANKS_DATA, []);
            } finally {
                commit(SET_AVERAGE_RANKS_DATA_LOADING, false);
            }
        },
        setMindshareHistoryDaysRange({commit}, payload){
          commit(SET_MINDSHARE_HISTORY_DAYS_RANGE, payload);
        },
        setMindshareRankingDaysRange({commit}, payload){
            commit(SET_MINDSHARE_RANKING_DAYS_RANGE, payload);
        },
        resetMindShareData({commit}) {
          commit(RESET_MINDSHARE_DATA);
        },
        async fetchViewkeyKeywordsInTopPositions({ commit, getters }) {
            const viewkey = getters.getProjectProperty('shortkey');
            const auth = getters.getProjectProperty('auth');

            try {
                commit(SET_VIEWKEY_CHART_IS_FETCHING, true);
                const { name: routeName, params: routeParams } = router.history.current;

                let res;
                if (
                    routeName === 'shareViewkeyWithHash' ||
                    routeName === 'shareOfVoiceWithHash' ||
                    routeName === 'shareKeywordsTableWithHash'
                ) {
                    res = await metricsApi.fetchViewkeyKeywordsInTopPositionsByTag(routeParams.hash, { auth });
                } else{
                    res = await metricsApi.fetchViewkeyKeywordsInTopPositions(viewkey, { auth });
                }
                
                const result = {};
                const resData = res.data.data;
                const categories = [];
                for(let i in resData){
                    categories.push(i);
                    let topPosData = resData[i].top_positions;
                    if(topPosData){
                        for(let j in topPosData){
                            if(result[j]?.length){
                                result[j].push(topPosData[j]);
                            } else {
                                result[j] = [topPosData[j]];
                            }
                        }
                    }
                }
                const keywordsInTopPositions = Object.entries(result).map(([key, value]) => ({
                    title: key,
                    data: value
                }));

                const data = {
                    data: keywordsInTopPositions,
                    categories: categories
                };
                commit(SET_VIEWKEY_KEYWORDS_IN_TOP_POSITIONS_DATA, data)
            } catch (error) {
                console.log('error', error)
            } finally {
                commit(SET_VIEWKEY_CHART_IS_FETCHING, false)
            }
        },
    },
};
