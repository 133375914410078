<template>
    <div class="reports-settings">
        <closer @click="close" />
        <form class="reports-settings__reports">
            <h2
                class="settings__subtitle settings__subtitle_reports custom-modal_draggable"
                v-text="$t('email-reports-settings')"
            />
            <div class="reports__fields__block">
                <kw-input-field
                    id="send-from"
                    ref="mailfrom"
                    v-model.trim="reportsSettings.mailfrom"
                    :label="$t('send-from')"
                    class="text-input"
                    name="MailFrom"
                    :placeholder="$t('send-form-placeholder')"
                    :error-data="inputReportsError('mailfrom')"
                    @input="reportsInputHandler('mailfrom')"
                />
                <div
                    class="settings__description_reports send_form_notification"
                >
                    <span class="kw kw-alert-triangle"></span>
                    <p>
                        {{ $t('sender-name-description') }}
                    </p>
                </div>
                <kw-input-field
                    id="sender-name"
                    ref="mailsendername"
                    v-model.trim="reportsSettings.mailsendername"
                    :label="$t('sender-name')"
                    class="text-input"
                    name="SenderName"
                    :placeholder="$t('serpbook-reports')"
                    :error-data="inputReportsError('mailsendername')"
                    @input="reportsInputHandler('mailsendername')"
                />
                <kw-input-field
                    id="subject"
                    ref="emailsubject"
                    v-model.trim="reportsSettings.emailsubject"
                    :label="$t('subject')"
                    class="text-input"
                    name="Subject"
                    :placeholder="$t('subject-placeholder')"
                    :error-data="inputReportsError('emailsubject')"
                    @input="reportsInputHandler('emailsubject')"
                />
                <label
                    for="message"
                    class="settings__label"
                    v-text="$t('message')"
                />
                <textarea
                    id="message"
                    ref="emailbody"
                    v-model.trim="reportsSettings.emailbody"
                    :class="inputReportsClasses('emailbody')"
                    name="Message"
                    :placeholder="$t('attached-emails-placeholder')"
                    type="text"
                    @input="reportsInputHandler('emailbody')"
                />
                <div>
                    <p
                        class="settings__description_reports"
                        v-text="$t('message-description')"
                    />
                </div>
                <label
                    for="attachment-format"
                    class="settings__label"
                    v-text="$t('attachment-format')"
                />
                <v-select
                    id="attachment-format"
                    options-label="label"
                    options-value="value"
                    :options="attachmentFormatOptions"
                    :value.sync="reportsSettings.reportname"
                    @change="reportNameHandler"
                />
                <div class="button__fields__block">
                    <kw-button
                        :button-disabled="buttonDisabled('reports')"
                        :button-text="$t('save')"
                        button-type="primary"
                        :loading="reportsSettingsLoading"
                        @click="saveSettings('reports')"
                    />
                    <kw-button
                        v-if="getPermissionData('viewkey_reports')"
                        class="whitelabel-domain-verify"
                        button-type="outline-primary"
                        :disabled="!getIsPackageOver5000"
                        @click="goToWhitelabelDomains"
                    >
                        <span
                            class="whitelabel-domain-text"
                            @mouseover="handleMousesOver"
                            slot="contentSlot"
                        >
                            {{ $t('white-label-your-domain') }}
                        </span>
                    </kw-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { attachmentFormatOptions } from '../../views/settings/configs';
import { mapGetters, mapActions } from 'vuex';
import { SET_COMPANY_SETTINGS } from '@/store/mutations';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import ValidationMixin from '@/mixins/ValidationMixin';

const vSelect = () => import('vue-strap/src/Select');
export default {
    name: 'EmailSettings',
    components: {
        'v-select': vSelect,
    },
    mixins: [ToolTipRenderMixin, ValidationMixin],
    props: {
        close: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            inited: false,
            reportsSettings: {
                mailfrom: '',
                mailsendername: '',
                emailsubject: '',
                emailbody: '',
                reportname: '',
            },
            reportsSettingsLoading: false,
            attachmentFormatOptions,
            reportsErr: {},
        };
    },
    computed: {
        ...mapGetters([
            'getIsPackageOver5000',
            'getPermissionData',
            'getReportsSettings',
            'getUserDataProp',
            'getCompanySettings',
        ]),
    },
    created() {
        // we add this subscription for reload case - if self api-request ends after this component appears
        this.unSubscribe = this.$store.subscribe(({ type }) => {
            // only SET_COMPANY_SETTINGS because in saveSettings action this mutation goes after SET_REPORTS_SETTINGS
            if (type === SET_COMPANY_SETTINGS && !this.inited) {
                this.initSettings();
                // we set this flag to avoid loop
                this.inited = true;
            }
        });

        if (!this.inited) {
            this.initSettings();
        }
    },
    beforeDestroy() {
        this.unSubscribe();
    },
    methods: {
        ...mapActions([
            'updateReportsSettings',
            //@TODO remove the old legacy actions and getters after testing
            'updateEmailSettings',
        ]),
        initSettings() {
            let reportsSettings = _.cloneDeep(this.getReportsSettings);
            reportsSettings.mailsendername =
                reportsSettings.mailsendername ?? '';
            this.reportsSettings = reportsSettings;

            if (!this.reportsSettings.mailfrom) {
                this.reportsSettings.mailfrom = this.getUserDataProp('email');
            }
        },
        reportNameHandler(value) {
            this.reportsSettings.reportname = value;
        },
        reportsInputHandler(type) {
            switch (type) {
                case 'mailfrom':
                    this.reportsErr.mailfrom = this.singleEmailValidator(
                        this.reportsSettings.mailfrom,
                    );
                    break;
                case 'mailsendername':
                    this.reportsErr.mailsendername = this.reportsSettingsInputValidator(
                        this.reportsSettings.mailsendername,
                        this.$t('sender-name'),
                        50,
                        'mailsendername',
                    );
                    break;
                case 'emailsubject':
                    this.reportsErr.emailsubject = this.reportsSettingsInputValidator(
                        this.reportsSettings.emailsubject,
                        this.$t('subject'),
                        50,
                        'emailsubject',
                    );
                    break;
                case 'emailbody':
                    this.reportsErr.emailbody = this.reportsSettingsInputValidator(
                        this.reportsSettings.emailbody,
                        this.$t('message'),
                    );
                    break;
                default:
                    this.reportsErr[type] = {};
                    break;
            }
        },
        async saveSettings(type) {
            if (this.settingsValidator(type)) {
                const errors = this[`${type}Err`];
                Object.keys(errors).forEach(el => {
                    if (errors[el] && errors[el].text) {
                        this.$toastr.e(errors[el].text);
                        // touch input
                        this.$refs[el].focus();
                        this.$refs[el].blur();
                    }
                });

                return;
            }

            this.reportsSettingsLoading = true;
            await this.updateEmailSettings(this.reportsSettings);
            this.reportsSettingsLoading = false;
        },
        settingsValidator(type) {
            const settings = this[`${type}Settings`];
            const errors = this[`${type}Err`];
            Object.keys(settings).forEach(el =>
                this[`${type}InputHandler`](el),
            );
            return Object.keys(errors).some(
                el => errors[el] && errors[el].text,
            );
        },
        buttonDisabled(type) {
            return _.isEqual(
                this[`${type}Settings`],
                this[`get${_.upperFirst(type)}Settings`],
            );
        },
        inputReportsClasses(type) {
            return {
                'text-input': true,
                'text-input_invalid':
                    this.reportsErr[type] && this.reportsErr[type].text,
            };
        },
        inputReportsError(type) {
            return {
                error: this.reportsErr[type] && this.reportsErr[type].text,
            };
        },
        goToWhitelabelDomains() {
            this.$modal.hide('SettingsModal');
            this.$router.push({ name: 'WhitelabelDomains' });
        },
        handleMousesOver(event) {
            return (
                !this.getIsPackageOver5000 &&
                this.tooltip({
                    text: this.$t('i-whitelabel-domain-verification'),
                    event,
                    maxWidth: 163,
                })
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/partials/dropdowns';

.reports-settings {
    color: kw-color(kw-black, 1);
    display: flex;
    flex-flow: row wrap;
    padding: 43px 24px;

    /deep/ .button-closer {
        position: absolute;
        right: 24px;
        top: 15px;
        z-index: 1;
    }

    /deep/ .form-control.dropdown-toggle {
        @extend .custom-dropdown;
        color: kw-color(kw-black, 1);
        width: 100%;
    }

    /deep/ .open .form-control.dropdown-toggle {
        @extend .custom-dropdown_active;
    }

    /deep/ .dropdown-menu {
        @extend .custom-scroll;
        @extend .custom-dropdown-menu;
        width: 100%;

        .glyphicon.glyphicon-ok.check-mark:before {
            content: '';
        }
    }

    &__reports {
        background-color: kw-color(kw-white, 1);
        border-radius: 4px;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        width: 100%;

        .reports__fields__block {
            max-height: 80vh;
            @extend .custom-scroll;
            overflow: auto;

            .text-input {
                width: 100%;
                margin-top: 20px;
            }

            .send_form_notification {
                margin-top: 16px;
                display: flex;
                gap: 9px;

                p {
                    margin-bottom: 0 !important;
                }
            }

            .kw-alert-triangle {
                color: red;
                font-weight: bold;
            }

            @media (max-width: 1500px) {
                height: 400px;
                padding-right: 5px;
            }

            #attachment-format {
                width: 100%;
            }
        }
    }

    .settings__label {
        margin-top: 20px;
    }

    email-report-save-btn textarea {
        @extend .custom-scroll;
        min-height: 114px;
        min-width: 100%;
        max-width: 100%;
    }

    .kw-btn-primary {
        margin-top: 20px;
        width: 190px;
        height: 40px;
    }
}

.settings__subtitle_reports {
    margin-bottom: 0;
}

.settings__description_reports {
    margin-bottom: 0;
    margin-top: 4px;
}

.button__fields__block {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 8px;

    .whitelabel-domain-verify {
        width: 190px;
        height: 40px;
        min-height: 40px;
        height: fit-content !important;
        padding: 8px 16px !important;
        border-radius: 4px;
        border: 1px solid #edeef0;
        box-shadow: 0px 1px 1px rgba(50, 73, 98, 0.1),
            0px 0px 1px rgba(50, 73, 98, 0.15) !important;
    }

    .whitelabel-domain-text {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        font-family: Roboto;
        font-style: normal;
        order: 0;
        flex-grow: 0;
    }

    @media (max-width: 461px) {
        .button__fields__block {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
        }
    }
}
</style>
