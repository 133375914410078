import {switchDateFormat, getDateByPeriod} from '@/helpers/format-date-service';
import { getIntervalStep } from '../components/main-block/components/Charts/config/lineChartConfig';

const colors = ['#edfeff', '#a2edf3', '#00d3e4', '#00bfd7', '#009aa9', '#006265'];

const overviewChartOptions = (series, isClient, endDate = null, showLatest, categories) => {
    const step = getIntervalStep(categories?.length, 8);
    const showCategories = categories?.length;
    const dataSize = 30;
    return {
        chart: {
            type: 'area',
            style: {
                overflow: 'visible',
            }
        },
        credits: {
            text: '',
            href: 'https://app.keyword.com/',
        },
        exporting: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            area: {
                lineWidth: 1,
                marker: {
                    enabled: false,
                },
                stacking: 'percent',
            },
            series: {
                states: {
                    hover: {
                        enabled: true,
                        halo: {
                            size: 5,
                        },
                    },
                },
            },
        },
        series: series?.map((s, i) => ({
            color: colors[i],
            data: showCategories ? s.data : s.data.slice(s.data.length - dataSize, s.data.length),
            marker: {
                fillColor: 'white',
                radius: 2,
                symbol: 'circle',
            },
            name: s.title,
        })) ?? [],
        title: {
            text: null,
        },
        tooltip: {
            backgroundColor: '#fff',
            borderColor: '#EAEDF2',
            borderRadius: 4,
            crosshairs: true,
            formatter: function() {
                return this.points.reduce(
                    (acc, cur) => {
                        const row = `
                        ${acc}
                        <div class="overview-chart__label-item">
                            <div>
                                <i style="background-color: ${cur.series.color}"></i>
                                ${cur.series.name}
                            </div>
                            <div>${cur.y}</div>
                            <div>${_.round(cur.percentage, 1)}%</div>
                        </div>
                    `;

                        return row;
                    },
                    `
                    <div class="overview-chart__label-header">
                        <div>
                        ${
                            showCategories ? `${
                                (categories[this.points[0].x] === 'latest') ? 'latest' : switchDateFormat(new Date(categories[this.points[0].key]))
                            }`
                            : `${
                                (this.points[0].series.data.length - this.x - 1 === 0 && showLatest)
                                ? 'latest' :
                                categories?.length ? switchDateFormat(new Date(categories[this.points[0].key]))
                                : switchDateFormat(
                                    getDateByPeriod(this.points[0].series.data.length - this.x - 1, endDate),
                                    'new-note',
                                )
                            }`
                        }
                            
                        </div>
                        <div>kws</div>
                        <div>Percent</div>
                    </div>
                `,
                );
            },
            padding: 0,
            shared: true,
            style: {
                opacity: '.9',
            },
            useHTML: true,
        },
        xAxis: {
            crosshair: {
                color: 'white',
                width: 1,
                zIndex: 3,
            },
            gridLineWidth: 0,
            ...(showCategories ? {tickInterval: 1} : {}),
            labels: {
                ...(showCategories ? {
                    step: 1,
                    formatter: function() {
                        if(this.isLast && showLatest){
                            return 'latest'
                        }
                        if(this.pos % step !== 0){
                            return ''
                        }

                        return switchDateFormat(new Date(categories[this.value]), 'chart-date');
                    },
                } : {
                    formatter: (d) =>{
                        if(d.isLast && showLatest){
                            return 'latest'
                        }
                        if(categories?.length){
                            return switchDateFormat(new Date(categories[d.value]), 'chart-date');
                        }
                        if(series[0].data.length < 3){
                            if(d.isFirst || d.isLast) {
                                return switchDateFormat(
                                     getDateByPeriod(Math.min(series[0].data.length, dataSize) - d.value - 1, endDate),
                                     'chart-date',
                                )
                            } else {
                                 return ''
                            }
                        } else {
                            return switchDateFormat(
                                getDateByPeriod(Math.min(series[0].data.length, dataSize) - d.value - 1, endDate),
                                'chart-date',
                            )
                        }
                    },
                }),
                style: {
                    color: '#909399',
                    fontSize: '11px',
                    letterSpacing: '.01em',
                    lineHeight: '14px',
                },
            },
            maxPadding: 0,
            minPadding: 0,
            tickLength: 0,
            title: {
                enabled: false,
            },
        },
        yAxis: {
            gridLineWidth: 0,
            labels: {
                format: '{value}%',
                style: {
                    color: '#909399',
                    fontSize: '12px',
                    fontWeight: 500,
                    letterSpacing: '.01em',
                    lineHeight: '14px',
                },
            },
            opposite: true,
            title: {
                enabled: false,
            },
        },
    };
};

export {overviewChartOptions};