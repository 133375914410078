<template>
    <span>{{ switchDateFormat(new Date(params.data.updated_at), 'Y-m-d') }}</span>
</template>

<script>
import {switchDateFormat} from '@/helpers/format-date-service';
export default {
    name: 'DateRenderer',
    methods:{
        switchDateFormat,
    },
};
</script>

<style lang="scss" scoped></style>
