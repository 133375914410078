<template>
    <div
        v-if="dynamicHeaders && items"
        ref="tableDynamic"
        class="app-table app-table_dynamic"
        :data-cy="`${cyAttribute}-table-dynamic`"
    >
        <vue-custom-scrollbar
            wrapper-query-selector=".app-table_dynamic"
            header-query-selector=".app-table__header"
        >
            <div
                class="app-table__header"
                :data-cy="`${cyAttribute}-dynamic-table-header`"
            >
                <div
                    id="dynamicTableHeaderRow"
                    class="header-row"
                    :data-cy="`${cyAttribute}-dynamic-table-header-row`"
                >
                    <div
                        v-for="header in dynamicHeaders"

                        :key="header.name"
                        :class="getClasses(tableParts.HEADER, header)"
                        :data-cy="`${cyAttribute}-dynamic-table-header-column`"
                        :style="getStyles(tableParts.DYNAMIC_BODY_COLUMN, header)"
                        @click="emitEvent('header-column-click', header)"
                    >
                        <div
                            class="column-header__title"
                            v-html="header.title"
                        />

                        <div
                            v-if="header.sorter"
                            class="column-header__sort-container"
                            :class="sortingOptions.direction"
                        >
                            <span
                                v-if="header.sorter === sortingOptions.field"
                                class="kw kw-sort"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="app-table__body">
                <virtual-list
                    id="virtual-dynamic"
                    :debounce="50"
                    :remain="50"
                    :pagemode="true"
                    :size="VIRTUAL_LIST_ITEM_HEIGHT"
                    :data-cy="`${cyAttribute}-dynamic-table-body`"
                    :wclass="getVirtualListWClass('right')"
                >
                    <div
                        v-for="(item, index) in items"
                        :key="item.id || index"
                        :class="getClasses(tableParts.BODY_ROW, item)"
                        :data-cy="`${cyAttribute}-dynamic-table-row`"
                    >
                        <div
                            :id="`row-dynamic-${item.id}`"
                            :class="getTableRowInnerClasses(index)"
                            @mouseover="setHoverState(item.id)"
                            @mouseleave="removeHoverState(item.id)"
                        >
                            <div
                                v-for="header in dynamicHeaders"
                                :key="header.name"
                                :data-cy="`${cyAttribute}-dynamic-table-body-column`"
                                :class="getClasses(tableParts.BODY_COLUMN, header)"
                                :style="getStyles(tableParts.DYNAMIC_BODY_COLUMN, header)"
                                @click.ctrl="(e) => emitEvent('body-column-click', 'ctrl', e, item, index, header)"
                                @click.exact="(e) => emitEvent('body-column-click', 'exact', e, item, index, header)"
                                @click.meta="(e) => emitEvent('body-column-click', 'meta', e, item, index, header)"
                                @click.shift="(e) => emitEvent('body-column-click', 'shift', e, item, index, header)"
                            >
                                <div
                                    class="row-wrap"
                                >
                                    <component
                                        :is="header.component"
                                        v-if="header.component"
                                        :key="`row-item-${header.name}`"
                                        v-bind="header.props(item, header, (...args) => $parent.$emit(...args))"
                                    />
                                    <div
                                        v-else
                                        v-html="processField(item, header)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </virtual-list>
            </div>
        </vue-custom-scrollbar>
    </div>
</template>

<script>
    import CustomTableMixin from '@/mixins/customTableMixin';
    import VueCustomScrollbar from '@/components/extended/VueCustomScrollbar';


    export default {
        name: 'DynamicTable',

        components: {
            'vue-custom-scrollbar': VueCustomScrollbar
        },


        mixins: [CustomTableMixin],

        props: {
            tableName: {
                type: String,
                required: true,
            },
            dynamicHeaders: {
                type: Array,
                required: true
            },
            isActiveHover: {
                type: Boolean,
                required: false,
                default: false,
            },
            items: {
                type: Array,
                required: true,
            },
            sortingOptions: {
                type: Object,
                required: true
            },
            fieldFormatter: {
                type: Function,
                required: true,
            },
            cyAttribute: {
                type: String,
                required: true
            }
        },

        methods: {
            // this method calculates the width of all elements in the dynamic table in pixels and percentages and returns the width of all the dynamic table
            getDynamicTableWidth() {
                return this.dynamicHeaders.reduce((acc, header) => {
                    acc.widthInPixels += +header.width ? header.width : 0;
                    acc.widthInPercentage += +header.percentWidth ? header.percentWidth : 0;
                    return acc;
                }, { widthInPixels: 0, widthInPercentage: 0 });
            },
            //this method counts the bristles of all columns whose width is given only in pixels,
            getWidthOfItemsWithFixedWidth() {
                return this.dynamicHeaders.reduce((acc, header) => {
                    acc += !header.percentWidth ? header.width : 0;
                    return acc
                }, 0);
            },

        }
    };
</script>

<style lang="scss">
// important class for overflow shadows of dynamic table
.column-header_with-shadow {
    &:before {
        background: linear-gradient(to left, rgba(31, 63, 97, 0.07) 0%, rgba(31, 63, 97, 0) 97.37%);
        content: '';
        height: 100%;
        position: absolute;
        right: 100%;
        width: 8px;
    }
}

.app-table_dynamic {
    @extend .custom-scroll;
    border-top-right-radius: 4px;
    flex: 1 1 auto;
    overflow: auto;
    // header
    border-right: 1px solid #E1E8EF;

    .column-header {

        &.column-header__actions{
            display: flex;
            justify-content: center;
        }

        &:first-child {
            border-left: none !important;
            border-radius: 0;
        }

        &:last-child {
            border-right: none;
        }
    }

    .column-header_borderless {
        border-right: transparent !important;

        & + .column-header:not(.column-header_borderless) {
            border-left: 1px solid #E1E8EF;
        }
    }

    .column-header_gpage {
        .column-header__title {
            display: flex;
            flex-flow: row nowrap;
            margin-left: 0;
        }

        .column-header__icon {
            font-style: normal;
            outline: transparent;
            padding-left: 16px;
            width: 100%;
        }
    }

    .table-cell__icon {
        position: relative;

        i {
            display: inline-block;
            font-size: 14px;
            margin-top: 6px;
        }

        span.kw-flag {
            color: #4DB645;
            font-size: 10px;
            font-weight: 700;
            left: -10px;
            margin-top: 0px !important;
            position: absolute;
            top: -4px;
        }
    }

    // body
    .table-row__title {
        color: transparent;
        user-select: none;
        width: 0;
    }

    .column-data {
        &:first-child {
            border-left: none !important;
        }

        &:last-child {
            border-right: none;
        }
    }

    .column-header_url,
    .column-data_url {
        min-width: 250px;

        &.column-header_safari,
        &.column-data_safari {
            padding-right: 47px !important;
        }
    }

    .column-data_borderless {
        border-right: transparent !important;

        & + .column-data:not(.column-data_borderless) {
            border-left: 1px solid #E1E8EF;
        }
    }

    .column-data_cpc,
    .column-data_competition,
    .column-data_searchvolume,
    .column-data_seocomp {
        span {
            letter-spacing: .2px;
            text-align: right;
        }
    }

    .column-data_timestamp {
        letter-spacing: 0.1px;
    }

    .column-data_near {
        min-width: 100px;
    }

    .column-data_gmb {
        min-width: 67px;
    }

    .column-data_period {
        .row-wrap {
            display: flex;
            width: 100%;
            position: relative;

            .period-value {
                width: 100%;
                white-space: nowrap;
                text-align: right;

                span.dot-negative,
                span.dot-positive {
                    margin-left: auto;
                    margin-right: 3px;
                }
            }

            &:before {
                position: absolute;
                left: 15px;
            }
        }

        span.icon-arrow_4 {
            font-size: 10px;
            line-height: 16px;
            margin-right: 5px;
            width: 9px;
        }

        span.icon-arrow_4.icon-rotate-down {
            color: kw-color(kw-red, 1);
        }

        span.icon-arrow_4:not(.icon-rotate-down) {
            color: kw-color(kw-green, 1);
        }
    }

    .column-data_tags {
        justify-content: flex-start !important;
        position: relative;

        .table-cell {
            max-width: 109px;
        }
    }

    .column-data_grank {
        .table-cell__icon {
            margin-right: 2px;

            i {
                background-repeat: no-repeat;
                background-size: contain;
                height: 14px;
                margin-top: 8px;
                width: 10px;
            }
        }
    }
}

.app-table_grownup {
    border-left: 1px solid #E1E8EF;
    border-top-left-radius: 4px;

    .column-header:first-child {
        border-top-left-radius: 4px;
    }
}

.empty-value {
    color: #d3d3d3;
}

.tag-field-disabled {
    pointer-events: none;
}

@media (max-width: 959px) {
    .app-table_dynamic {
        border-top-right-radius: 0;

        .column-header {
            &:last-child {
                border-right: none;
                border-top-right-radius: 0;
            }

            &:not(.column-header_checkbox) {
                padding: 0 10px;
            }
        }

        .table-row {
            .column-data {
                &:last-child {
                    border-right: none;
                }

                &:not(.column-data_checkbox) {
                    padding: 0 10px;
                }

                &.column-data_url {
                    padding: 0 10px 0 25px;
                }
            }
        }

    }
}
</style>
