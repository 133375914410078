import {SET_CURRENT_GROUP_OBJECT, UPDATE_CURRENT_PROJECT_TAGS} from '../mutations';
import router from '@/routes';

export default {
    state: {
        currentProject: null,
    },
    mutations: {
        [SET_CURRENT_GROUP_OBJECT]: (state, project) => state.currentProject = project,
        [UPDATE_CURRENT_PROJECT_TAGS]: (state, tags) => state.currentProject.tags = tags,
    },
    getters: {
        getCurrentProject: state => state.currentProject,
    },
    actions: {
        // forced - should be updated even if previous project has the same id (in this case passedId is important)
        async setCurrentProject ({getters, commit, dispatch}, payload = {}) {
            if (getters.getViewkeyView) {
                return;
            }

            const id = payload.passedId
                ? payload.passedId
                : router.history.current.params.id
                    ? decodeURIComponent(router.history.current.params.id)
                    : null;

            if (!id) {
                commit(SET_CURRENT_GROUP_OBJECT, null);
                return;
            }

            // if user comes back from add keyword page we do not need refetch keywords
            if (!payload.forced && getters.getCurrentProject && id === getters.getCurrentProject.id) {
                return;
            }

            const currentProject = getters.getProjectById(id);
            commit(SET_CURRENT_GROUP_OBJECT, currentProject);
        },

        updateCurrentProjectTags ({commit}, payload = []) {
            commit(UPDATE_CURRENT_PROJECT_TAGS, payload);
        }
    },
};