<template>
    <div class="set-new-password">
        <div class="title">{{ $t('set-new-password.title') }}</div>
        <form :novalidate="true" @submit.prevent="validateForm">
            <div class="form-group password-wrapper">
                <kw-input-field
                    id="password"
                    name="password"
                    data-cy="password"
                    class="text-input"
                    :type="fieldType"
                    :label="$t('login-form.password')"
                    placeholder="••••••••"
                    v-model.trim="password"
                    v-validate="'required|min:8|max:32|include_special_character'"
                    :error-data="passwordFieldError"
                />
                <div
                    @click="togglePasswordVisibility"
                    class="password-visibility"
                >
                    <div>
                        <span :class="`kw ${eyeIcon}`" />
                    </div>
                </div>
            </div>
            <div class="password-hint">
                <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8 0.5C12.4062 0.5 16 4.09375 16 8.5C16 12.9375 12.4062 16.5 8 16.5C3.5625 16.5 0 12.9375 0 8.5C0 4.09375 3.5625 0.5 8 0.5ZM11.5938 7.125C11.9375 6.78125 11.9375 6.25 11.5938 5.90625C11.25 5.5625 10.7188 5.5625 10.375 5.90625L7 9.28125L5.59375 7.90625C5.25 7.5625 4.71875 7.5625 4.375 7.90625C4.03125 8.25 4.03125 8.78125 4.375 9.125L6.375 11.125C6.53125 11.3125 6.75 11.375 7 11.375C7.21875 11.375 7.4375 11.3125 7.59375 11.125L11.5938 7.125Z"
                        fill="#D0D5DD"
                    />
                </svg>
                <span>
                    {{ $t('signup-form.password-hint') }}
                </span>
            </div>
            <div class="errors">
                <span v-if="firstError">{{ firstError }}</span>
            </div>
            <kw-button
                type="submit"
                class="set-new-password-submit"
                data-cy="set-new-password-submit"
                button-type="action"
                :loading="loading"
                :button-disabled="isDisabled"
                :button-text="$t('submit')"
            />
            <router-link :to="{ name: 'login' }" class="login">
                {{ $t('set-new-password.bak-to-login') }}
            </router-link>
        </form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'SetNewPassword',
    data() {
        return {
            password: '',
            errorMsg: '',
            loading: false,
            validated: false,
            showPassword: false,
            id: this.$route.query.id,
            token: this.$route.query.token,
        };
    },
    computed: {
        passwordFieldError() {
            return {
                error: this.validated && this.errors.collect('password').length,
            };
        },
        isDisabled() {
            return !!(
                (this.validated && this.errors.all().length) ||
                !this.password
            );
        },
        firstError() {
            return this.validated && this.errors.all().length
                ? this.errors.items[0].msg
                : this.errorMsg || false;
        },
        eyeIcon() {
            return this.showPassword ? 'kw-eye' : 'kw-eye-off';
        },
        fieldType() {
            return this.showPassword ? 'text' : 'password';
        },
    },
    mounted() {
        if (!this.$route.query.id || !this.$route.query.token) {
            this.$router.push({ name: 'login' });
        }
    },
    methods: {
        ...mapActions(['sendToken']),
        async refreshPassword() {
            if (!this.errors.all().length) {
                this.loading = true;
                let data = {
                    id: this.id,
                    token: this.token,
                    password: this.password,
                };
                await this.sendToken(data);
                this.loading = false;
            }
        },
        validateForm(e) {
            if (this.errors.all().length) {
                this.errors.all().forEach(el => this.$toastr.e(el));
            }

            this.refreshPassword();
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
    },
};
</script>

<style lang="scss" scoped>
.set-new-password {
    display: inline-flex;
    flex-direction: column;
    gap: 39px;

    .title {
        color: var(--black, #15394f);
        font-family: Aeonik;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    form {
        display: flex;
        justify-content: center;
        flex-direction: column;

        /deep/ .form-group {
            margin-bottom: 6px;
        }

        /deep/ .kw__input {
            height: 50px;
        }

        /deep/ .field__label {
            color: var(--black, #15394f);
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 8px;
        }

        .password-wrapper {
            position: relative;

            .password-visibility {
                cursor: pointer;
                width: 30px;
                height: 30px;
                position: absolute;
                top: 42px;
                right: 0;
            }

            .password-visibility span {
                width: 25px;
                height: 25px;
                font-size: 20px;
                color: #0074d9;
            }
        }

        .password-hint {
            color: #7e8693;
            font-family: Aeonik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            gap: 6px;
        }

        .errors {
            height: 14px;
            color: #f04438;
            font-family: Aeonik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .set-new-password-submit {
            margin-top: 24px;
            margin-bottom: 24px;
        }

        /deep/ .kw-button {
            height: 50px;
            width: 100%;
            color: #fff;
            text-align: center;
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 50px;
        }

        .login {
            align-self: stretch;
            text-decoration: none;
            color: var(--black, #15394f);
            text-align: center;
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    @media (max-width: 980px) {
        padding-bottom: 48px;
    }
}
</style>
