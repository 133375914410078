import guards from '../guards';

import AppHeader from 'components/app-header/AppHeader';
import settingsAccount from 'pages/settings/Account';
import settingsSharedAccess from 'pages/settings/SharedAccess';
import SettingsViewKey from 'pages/settings/SettingsViewKey';
import SettingsTable from 'pages/settings/SettingsTable';
import SettingsPlan from 'pages/settings/SettingsPlan';
import SettingsNotifications from 'pages/settings/SettingsNotifications';
const settingsMain = () => import('pages/settings/SettingsMain');

export default [
    {
        components: { settingsMain, AppHeader },
        name: 'settings',
        path: '/settings',
        children: [
            {
                component: settingsSharedAccess,
                name: 'settingsSharedAccess',
                path: 'team',
                meta: {requiresSubscription: true},
                beforeEnter: guards('isAdmin'),
            },
            {
                component: settingsAccount,
                name: 'settingsAccount',
                path: 'account',
                meta: {requiresSubscription: true},
                // account settings should be only visible for master
                beforeEnter: guards('isAdmin'),
            },
            {
                component: SettingsViewKey,
                name: 'settingsAccountViewKey',
                path: 'view-key',
                meta: {requiresSubscription: true},
                beforeEnter: guards('isAdmin'),
            },
            {
                component: SettingsTable,
                name: 'settingsAccountTable',
                path: 'table',
                meta: {requiresSubscription: true},
                beforeEnter: guards('isAdmin'),
            },
            {
                component: SettingsPlan,
                name: 'settingsPlan',
                path: 'plan',
                meta: {requiresSubscription: false},
                beforeEnter: guards('isAdmin'),
            },
            {
                component: SettingsNotifications,
                name: 'settingsNotifications',
                path: 'notifications',
                meta: {requiresSubscription: false},
                beforeEnter: guards('notViewOnly'),
            },
        ],
        // settings should be visible only for master and admin
        // beforeEnter: guards('isAdmin'),
    },
];
