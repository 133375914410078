var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"table-cell"},[_c('div',{staticClass:"table-cell__icon table-cell__icon_flag"},[_c('flag',{attrs:{"country":_vm.props.keyword.flag.image,"tooltip":_vm.props.keyword.flag.tooltip,"tooltip-callback":_vm.parent.tooltip}})],1),_vm._v(" "),_c('div',{class:_vm.$options.iconClasses(_vm.props),on:{"mouseover":function($event){_vm.parent.tooltip({
                text: _vm.parent.$t(((_vm.props.keyword.type) + "-ranking")),
                event: $event,
            })}}}),_vm._v(" "),(_vm.props.keyword.near)?_c('div',{staticClass:"kw kw-map-pin",attrs:{"data-cy":"keyword-location-tag"},on:{"mouseover":function($event){_vm.parent.tooltip({
                text: _vm.parent.$t('location', { near: _vm.props.keyword.near }),
                event: $event,
            })}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"table-cell__content-wrapper",attrs:{"data-cy":"keyword-table-cell-name"}},[_c('div',{staticClass:"table-cell__content table-cell__content_cut table-cell__content_url",on:{"mouseover":function($event){return _vm.parent.tooltip({ text: _vm.props.keyword.url, event: $event })}}},[_c('div',{staticClass:"table-cell__text table-cell__text_url",domProps:{"innerHTML":_vm._s(_vm.props.keyword.url)}})]),_vm._v(" "),_c('div',{staticClass:"table-cell__content table-cell__content_cut"},[_c('div',{staticClass:"table-cell__text",on:{"mouseover":function($event){return _vm.parent.tooltip({
                        text: _vm.props.keyword.fullKw,
                        event: $event,
                        allowHtml: false
                    })}}},[_vm._v(_vm._s(_vm.props.keyword.kw))])])]),_vm._v(" "),_c('div',{staticClass:"table-cell__actions"},[_c('div',{staticClass:"actions-class__action-container"},[(_vm.props.isViewKey != false)?_c('kw-button',{attrs:{"button-disabled":_vm.props.isSearch,"append-icon-class":_vm.props.keyword.isfav
                        ? 'kw kw-favorite'
                        : 'kw kw-iconsstar',"data-cy":"favorite-iconsstar-in-table","button-type":"text"},on:{"mouseover":function($event){_vm.parent.tooltip({
                        text: _vm.props.keyword.isfav
                            ? _vm.parent.$t('unfav-kw')
                            : _vm.parent.$t('fav-kw'),
                        event: $event,
                    })},"click":function($event){$event.stopPropagation();return _vm.props.toggleFavorite({
                        keywords: [_vm.props.keyword.id],
                        action: !_vm.props.keyword.isfav,
                    })}}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"actions-class__action-container"},[_c('kw-button',{attrs:{"append-icon-class":"kw kw-charts","button-active":_vm.parent.$store.getters.getShownChart.keyword ===
                    _vm.props.keyword.auth,"data-cy":"show-historical-chart","button-type":"text","data-important-tooltip":_vm.parent.$t('view-charts')},on:{"click":function($event){$event.stopPropagation();return _vm.parent.toggleShowSingleChart(_vm.props.keyword)}}})],1),_vm._v(" "),_c('div',{staticClass:"actions-class__action-container"},[_c('a',{attrs:{"data-important-tooltip":_vm.parent.$t('see-in-search-results'),"href":_vm.$options.spyglassLink(_vm.props, _vm.parent),"target":"_blank","test":_vm.props.keyword.grank},on:{"click":function($event){$event.stopPropagation();return _vm.parent.emitOpenSpyglass({project_id: _vm.props.keyword.project_id, keyword_id: _vm.props.keyword.id})}}},[_c('span',{staticClass:"kw kw-search"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }