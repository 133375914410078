<template functional>
    <div class="table-cell">
        <span
            v-if="props.keyword.tagsSplited.length"
            :class="$options.tagsClasses(props.keyword.tagsSplited[0], parent.$store.getters, props.classes)"
            @click.stop="listeners['set-active-tags'](props.keyword.tagsSplited[0])"
            @mouseover="parent.getTooltipForText(props.keyword.tagsSplited[0].name, $event)"
            v-text="props.keyword.tagsSplited[0].name"
        />
        <span
            v-if="props.keyword.tagsSplited.length > 1"
            class="more-tags-button"
            @click.stop="parent.setAllTags(props.keyword.id)"
        >
            {{ `+${props.keyword.tagsSplited.length - 1}` }}
        </span>
        <div
            v-if="props.keyword.tagsSplited.length > 1 && parent.showAllTags === props.keyword.id"
            class="all-tags-class"
        >
            <span
                v-for="(tag, key) in $options.longTagList(props)"
                :key="key"
                v-click-outside="hideAllTags"
                :class="$options.tagsClasses(tag, parent.$store.getters, props.classes)"
                @click.stop="listeners['set-active-tags'](tag)"
                @mouseover="parent.getTooltipForText(tag.name, $event)"
                v-text="tag.name"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TagsField',
        longTagList: ({keyword}) => [...keyword.tagsSplited].splice(1),
        tagsClasses (tag, {getActiveTag}, classes = {}) {
            return {
                'tag-name': true,
                'active-tag': getActiveTag.id === tag.id,
                ...classes,
            };
        },
    };
</script>

<style lang="scss" scoped>
  .tag-name {
    @import '~sass/cutting-name';
    background: #F5F6F7;
    border-radius: 4px;
    border: 1px solid #CBD6E9;
    color: #265596;
    cursor: pointer;
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    max-height: 16px;
    max-width: 100%;
    padding: 0 5px;
    position: relative;
    text-transform: lowercase;
    transition: .25s all;
    white-space: nowrap;

    &:hover {
      background: #CBD6E8;
      color: #265596;
    }

    &.active-tag {
      background: kw-color(kw-blue, 1);
      color: kw-color(kw-white, 1);
    }
  }

  .more-tags-button {
    color: kw-color(kw-grey, 1);
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    margin-left: 5px;
    max-height: 16px;

    & ~ .tag-name {
      max-width: calc(100% - 18.5px);
    }
  }

  .all-tags-class {
    background-color: kw-color(kw-white, 1);
    border-radius: 4px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1), 0 0 3px rgba(0, 0, 0, 0.05);
    left: 85%;
    max-width: 100px;
    max-height: 140px;
    min-width: fit-content;
    padding: 4px 8px 1px 8px;
    position: absolute;
    display: flex;
    flex-flow: row wrap;
    z-index: 3;

    .tag-name {
      margin-right: 3px;
      margin-bottom: 3px;
      padding: 0 5.2px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
