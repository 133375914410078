// add-keywords
export const RESET_KEYWORD_DRAFT = 'RESET_KEYWORD_DRAFT';
export const RESET_QUANTITY_DRAFT = 'RESET_QUANTITY_DRAFT';
export const SET_CURRENT_PAGE_FOR_SUGGESTIONS = 'SET_CURRENT_PAGE_FOR_SUGGESTIONS';
export const SET_DRAFTS_QUANTITY = 'SET_DRAFTS_QUANTITY';
export const SET_KEYWORD_ADDING = 'SET_KEYWORD_ADDING';
export const SET_KEYWORD_DRAFT = 'SET_KEYWORD_DRAFT';
export const SET_SUGGESTIONS = 'SET_SUGGESTIONS';

// alerts
export const SET_ALERTS = 'SET_ALERTS';
export const SET_ALERTS_LOADING = 'SET_ALERTS_LOADING';
export const SET_ALERTS_PAGE = 'SET_ALERTS_PAGE';
export const SET_ALERTS_PER_PAGE = 'SET_ALERTS_PER_PAGE';
export const SET_ALERTS_SORT = 'SET_ALERTS_SORT';
export const SET_ALERTS_TOTAL = 'SET_ALERTS_TOTAL';
export const SET_ALERTS_VIEW = 'SET_ALERTS_VIEW';
export const SET_IS_LATEST = 'SET_IS_LATEST';
export const SET_IS_PIVOT = 'SET_IS_PIVOT';
export const SET_KEYWORD_FOR_ALERTS_FILTER = 'SET_KEYWORD_FOR_ALERTS_FILTER';
export const SET_PROJECTS_FOR_ALERTS_FILTER = 'SET_PROJECTS_FOR_ALERTS_FILTER';
export const SET_TOTAL_ALERTS_PAGES = 'SET_TOTAL_ALERTS_PAGES';
export const SET_UNREADS_ALERTS_COUNT = 'SET_UNREADS_ALERTS_COUNT';

// archivedProjects
export const SET_DELETED_PROJECTS = 'SET_DELETED_PROJECTS';
export const SET_DELETED_PROJECTS_LOADED = 'SET_DELETED_PROJECTS_LOADED';
export const SET_ARCHIVED_PROJECTS = 'SET_ARCHIVED_PROJECTS';
export const SET_ARCHIVED_PROJECTS_LOADED = 'SET_ARCHIVED_PROJECTS_LOADED';
export const SET_ARCHIVED_PROJECTS_FOR_UNDO = 'SET_ARCHIVED_PROJECTS_FOR_UNDO';
export const SET_DELETED_PROJECTS_FOR_UNDO = 'SET_DELETED_PROJECTS_FOR_UNDO';
export const CLEAR_UNDO_DELETED_PROJECTS = 'CLEAR_UNDO_DELETED_PROJECTS';
export const SET_ARCHIVED_PROJECTS_SORTER = 'SET_ARCHIVED_PROJECTS_SORTER';
export const SET_ARCHIVED_PROJECTS_SEARCH_QUERY = 'SET_ARCHIVED_PROJECTS_SEARCH_QUERY';
export const SET_CHECKED_ARCHIVED_PROJECTS = 'SET_CHECKED_ARCHIVED_PROJECTS';

// billing
export const SET_COUPON = 'SET_COUPON';
export const SET_BILLING_CYCLE = 'SET_BILLING_CYCLE';
export const SET_COUPON_CHECKING = 'SET_COUPON_CHECKING';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_DEFAULT_CARD = 'SET_DEFAULT_CARD';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_PACKAGES = 'SET_PACKAGES';
export const SET_PACKAGES_LOADING = 'SET_PACKAGES_LOADING';
export const SET_CURRENT_PLAN = 'SET_CURRENT_PLAN';
export const SET_IS_NOT_STRIPE_USER = 'SET_IS_NOT_STRIPE_USER';
export const SET_PAYMENT_DETAILS_LOADED = 'SET_PAYMENT_DETAILS_LOADED';
export const SET_PLAN_IS_UPGRADED = 'SET_PLAN_IS_UPGRADED';

// charts
export const SET_COMPETITORS_DATA = 'SET_COMPETITORS_DATA';
export const SET_COMPETITORS_SUGGESTIONS = 'SET_COMPETITORS_SUGGESTIONS';
export const SET_SERIES = 'SET_SERIES';
export const SET_SHOWN_CHART = 'SET_SHOWN_CHART';
export const SET_COMMON_KEYWORDS_IS_SHOW = 'SET_COMMON_KEYWORDS_IS_SHOW';
export const SET_SERP_HISTORY_RESULTS = 'SET_SERP_HISTORY_RESULTS';
export const SET_PDF_CHARTS_DATA = 'SET_PDF_CHARTS_DATA';
export const SET_PDF_CHARTS_DATA_IS_FETCHING = 'SET_PDF_CHARTS_DATA_IS_FETCHING';
export const SET_GOOGLE_INCIDENTS = 'SET_GOOGLE_INCIDENTS';

// countries-states
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_STATES = 'SET_STATES';
export const SET_BILLING_STATES = 'SET_BILLING_STATES';

// current-project
export const SET_CURRENT_GROUP_OBJECT = 'SET_CURRENT_GROUP_OBJECT';
export const UPDATE_CURRENT_PROJECT_TAGS = 'UPDATE_CURRENT_PROJECT_TAGS';

// expanded-overview
export const SET_OVERVIEW_CHARTS_DATA = 'SET_OVERVIEW_CHARTS_DATA';
export const SET_OVERVIEW_CHARTS_DATA_LOADING = 'SET_OVERVIEW_CHARTS_DATA_LOADING';
export const SET_OVERVIEW_CHARTS_DATA_PAGE = 'SET_OVERVIEW_CHARTS_DATA_PAGE';
export const SET_OVERVIEW_CHARTS_TOTAL_ITEMS = 'SET_OVERVIEW_CHARTS_TOTAL_ITEMS';
export const SET_OVERVIEW_CHARTS_TOTAL_PAGES = 'SET_OVERVIEW_CHARTS_TOTAL_PAGES';
export const SET_OVERVIEW_CHARTS_DATA_PARAMS = 'SET_OVERVIEW_CHARTS_DATA_PARAMS';

// google-analytics
export const SET_GA_AUTHORIZED = 'SET_GA_AUTHORIZED';
export const SET_GA_PROFILES = 'SET_GA_PROFILES';
export const SET_GA_PROFILES_DATA = 'SET_GA_PROFILES_DATA';
export const SET_GA_USERNAME = 'SET_GA_USERNAME';

// google-search-console
export const SET_GSC_USERNAME = 'SET_GSC_USERNAME';
export const SET_GSC_USERNAME_IS_FETCHING = 'SET_GSC_USERNAME_IS_FETCHING';
export const SET_GSC_PROFILES = 'SET_GSC_PROFILES';
export const SET_GSC_PROFILES_IS_FETCHING = 'SET_GSC_PROFILES_IS_FETCHING';
export const SET_GSC_SUGGESTIONS = 'SET_GSC_SUGGESTIONS';
export const SET_GSC_SUGGESTIONS_IS_FETCHING = 'SET_GSC_SUGGESTIONS_IS_FETCHING';
export const SET_GSC_SUGGESTIONS_SORT = 'SET_GSC_SUGGESTIONS_SORT';
export const SET_GSC_SUGGESTIONS_DELETING = 'SET_GSC_SUGGESTIONS_DELETING';
export const SET_GSC_DELETED_SUGGESTIONS = 'SET_GSC_DELETED_SUGGESTIONS';
export const SET_GSC_DELETED_SUGGESTIONS_IS_FETCHING = 'SET_GSC_DELETED_SUGGESTIONS_IS_FETCHING';
export const SET_GSC_SUGGESTIONS_RESTORING = 'SET_GSC_SUGGESTIONS_RESTORING';
export const SET_GSC_KEYWORDS_FROM_SUGGESTIONS_CREATING = 'SET_GSC_KEYWORDS_FROM_SUGGESTIONS_CREATING';
export const SET_GSC_PROFILE_CONNECTION = 'SET_GSC_PROFILE_CONNECTION';
export const SET_GSC_PROFILE_CONNECTIONS = 'SET_GSC_PROFILE_CONNECTIONS';
export const SET_GSC_PROFILE_CONNECTIONS_CREATING = 'SET_GSC_PROFILE_CONNECTIONS_CREATING';
export const SET_GSC_PROFILE_CONNECTIONS_UPDATING = 'SET_GSC_PROFILE_CONNECTIONS_UPDATING';
export const SET_GSC_PROFILE_CONNECTIONS_DELETING = 'SET_GSC_PROFILE_CONNECTIONS_DELETING';
export const SET_GSC_PROFILE_CONNECTIONS_IS_FETCHING = 'SET_GSC_PROFILE_CONNECTIONS_IS_FETCHING';

// keywords
export const RESET_GLOBAL_SEARCH_QUERY = 'RESET_GLOBAL_SEARCH_QUERY';
export const SET_AVAILABLE_TAGS = 'SET_AVAILABLE_TAGS';
export const SET_GATHERING_TIMER = 'SET_GATHERING_TIMER';
export const SET_GLOBAL_SEARCH_QUERY = 'SET_GLOBAL_SEARCH_QUERY';
export const SET_KEYWORDS = 'SET_KEYWORDS';
export const SET_KEYWORDS_FOR_UNDO = 'SET_KEYWORDS_FOR_UNDO';
export const SET_KEYWORDS_IS_FETCHING = 'SET_KEYWORDS_IS_FETCHING';
export const SET_LIST_OF_KEYWORDS_DOMAINS = 'SET_LIST_OF_KEYWORDS_DOMAINS';
export const SET_LIST_OF_KEYWORDS_TYPES = 'SET_LIST_OF_KEYWORDS_TYPES';
export const SET_PROJECT_FOR_KEYWORDS_UNDO = 'SET_PROJECT_FOR_KEYWORDS_UNDO';
export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';
export const SET_SELECTED_KEYWORDS = 'SET_SELECTED_KEYWORDS';
export const SET_SELECTED_SUGGESTIONS = 'SET_SELECTED_SUGGESTIONS';
export const SET_SHOW_KEYWORD_ACTION = 'SET_SHOW_KEYWORD_ACTION';
export const SET_TOTAL_KEYWORDS = 'SET_TOTAL_KEYWORDS';
export const SET_TOTAL_KEYWORDS_PAGES = 'SET_TOTAL_KEYWORDS_PAGES';
export const UPDATE_KEYWORD_FILTERS = 'UPDATE_KEYWORD_FILTERS';
export const RESET_KEYWORD_FILTERS = 'RESET_KEYWORD_FILTERS';
export const SET_ALL_CHECKBOXES_SELECTED = 'SET_ALL_CHECKBOXES_SELECTED';
export const SET_FAVORITE_DATA = 'SET_FAVORITE_DATA';
export const SET_ALL_WITHOUT_IDS = 'SET_ALL_WITHOUT_IDS';
export const SET_NEW_ADDED_KEYWORDS = 'SET_NEW_ADDED_KEYWORDS';
export const SET_NEW_ADDED_KEYWORDS_IS_FETCHING = 'SET_NEW_ADDED_KEYWORDS_IS_FETCHING';
export const SET_KEYWORD_SETTINGS = 'SET_KEYWORD_SETTINGS';
export const SET_LAZY_LOAD_FETCHING = 'SET_LAZY_LOAD_FETCHING';
export const SET_DAILY_ADDED = 'SET_DAILY_ADDED';
export const SET_FILTER_BY_RANKING_URL = 'SET_FILTER_BY_RANKING_URL';
export const SET_IS_EDIT_POPUP_VISIBLE = 'SET_IS_EDIT_POPUP_VISIBLE';
export const SET_LAST_FETCH_REQUEST = 'SET_LAST_FETCH_REQUEST';
export const SET_CURRENT_REFRESHING_KEYWORDS = 'SET_CURRENT_REFRESHING_KEYWORDS';
export const SET_VIEWKEY_DATA = 'SET_VIEWKEY_DATA';

// pdf-settings
export const SET_PDF_SHOW_COLUMNS = 'SET_PDF_SHOW_COLUMNS';
export const SET_PDF_HISTORICAL_CHART = 'SET_PDF_HISTORICAL_CHART';
export const SET_PDF_COMPANY_INFO = 'SET_PDF_COMPANY_INFO';
export const SET_PDF_VERIFIED_BY = 'SET_PDF_VERIFIED_BY';
export const SET_PDF_DATE = 'SET_PDF_DATE';

// keywords-table-settings
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_GRANK_RANGE = 'SET_GRANK_RANGE';
export const SET_KEYWORDS_TABLE_SETTINGS = 'SET_KEYWORDS_TABLE_SETTINGS';
export const SET_HIDE_URL_KEYWORDS = 'SET_HIDE_URL_KEYWORDS';
export const SET_CURRENT_TOOLTIP = 'SET_CURRENT_TOOLTIP';

// manage-projects
export const SET_ACTIVE_GROUPS = 'SET_ACTIVE_GROUPS';
export const SET_ACTIVE_GROUPS_LOADED = 'SET_ACTIVE_GROUPS_LOADED';
export const SET_CHECKED_GROUPS = 'SET_CHECKED_GROUPS';
export const SET_EDITABLE_GROUP = 'SET_EDITABLE_GROUP';
export const SET_IS_GROUP_ADDING = 'SET_IS_GROUP_ADDING';
export const SET_OPENED_GROUPS_KEYS = 'SET_OPENED_GROUPS_KEYS';
export const SET_OPENED_GROUPS_TAGS = 'SET_OPENED_GROUPS_TAGS';
export const SET_SUGGESTED_REGIONS = 'SET_SUGGESTED_REGIONS';
export const SET_SUGGESTED_URLS = 'SET_SUGGESTED_URLS';

// metrics
export const SET_ALL_GROUPS_METRICS_DATA = 'SET_ALL_GROUPS_METRICS_DATA';
export const SET_METRICS_AND_CHART_IS_FETCHING = 'SET_METRICS_AND_CHART_IS_FETCHING';
export const SET_METRICS_AND_CHARTS_DATA = 'SET_METRICS_AND_CHARTS_DATA';
export const SET_METRICS_DATA = 'SET_METRICS_DATA';
export const SET_METRICS_IS_FETCHING = 'SET_METRICS_IS_FETCHING';
export const SET_METRICS_FETCHING_COUNT = 'SET_METRICS_FETCHING_COUNT';
export const SET_MINDSHARE_LATEST_DATA = 'SET_MINDSHARE_LATEST_DATA';
export const RESET_MINDSHARE_DATA = 'RESET_TOP_TEN_SOV_DATA';
export const SET_MINDSHARE_DATA_LIMIT_EXCEED = 'SET_MINDSHARE_DATA_LIMIT_EXCEED';
export const SET_MINDSHARE_HISTORY_DATA = 'SET_MINDSHARE_HISTORY_DATA';
export const SET_MINDSHARE_DATA_CALCULATION_STATE = 'SET_MINDSHARE_DATA_CALCULATION_STATE';
export const SET_MINDSHARE_DATA_CALCULATION = 'SET_MINDSHARE_DATA_CALCULATION';
export const SET_AVERAGE_RANKS_DATA = 'SET_AVERAGE_RANKS_DATA';
export const SET_AVERAGE_RANKS_DATA_LOADING = 'SET_AVERAGE_RANKS_DATA_LOADING';
export const SET_MINDSHARE_HISTORY_DATA_LOADING = 'SET_MINDSHARE_HISTORY_DATA_LOADING';
export const SET_MINDSHARE_HISTORY_DAYS_RANGE = 'SET_MINDSHARE_HISTORY_DAYS_RANGE';
export const SET_MINDSHARE_RANKING_DAYS_RANGE = 'SET_MINDSHARE_RANKING_DAYS_RANGE';
export const SET_VIEWKEY_METRICS_IS_FETCHING = 'SET_VIEWKEY_METRICS_IS_FETCHING';
export const SET_VIEWKEY_CHART_IS_FETCHING = 'SET_VIEWKEY_CHART_IS_FETCHING';
export const SET_VIEWKEY_KEYWORDS_IN_TOP_POSITIONS_DATA = 'SET_VIEWKEY_KEYWORDS_IN_TOP_POSITIONS_DATA';

// notes
export const SET_PROJECT_NOTES = 'SET_PROJECT_NOTES';

// currencies
export const SET_CURRENCIES = 'SET_CURRENCIES';
export const SET_CURRENT_CURRENCY = 'SET_CURRENT_CURRENCY';

// overview
export const SET_CURRENT_OVERVIEW_PAGE = 'SET_CURRENT_OVERVIEW_PAGE';
export const SET_OVERVIEW_DATA = 'SET_OVERVIEW_DATA';
export const SET_OVERVIEW_DATA_LOADING = 'SET_OVERVIEW_DATA_LOADING';
export const SET_OVERVIEW_ITEMS_PER_PAGE = 'SET_OVERVIEW_ITEMS_PER_PAGE';
export const SET_OVERVIEW_SEARCH = 'SET_OVERVIEW_SEARCH';
export const SET_OVERVIEW_SORT = 'SET_OVERVIEW_SORT';
export const SET_OVERVIEW_VIEW = 'SET_OVERVIEW_VIEW';
export const SET_SORT_BY_CHANGES = 'SET_SORT_BY_CHANGES';
export const SET_OVERVIEW_DATA_PARAMS = 'SET_OVERVIEW_DATA_PARAMS';
export const SET_OVERVIEW_PAGINATE_WITH_API = 'SET_OVERVIEW_PAGINATE_WITH_API';

// refreshes
export const CLEAR_REFRESH_PROGRESS = 'CLEAR_REFRESH_PROGRESS';
export const SET_IS_REFRESH_RUNNING = 'SET_IS_REFRESH_RUNNING';
export const SET_PROJECT_LAST_UPDATE = 'SET_PROJECT_LAST_UPDATE';
export const SET_REFRESH_PROGRESS = 'SET_REFRESH_PROGRESS';
export const SET_ALL_PROJECTS_LAST_UPDATE = 'SET_ALL_PROJECTS_LAST_UPDATE';

// reports
export const SET_REPORTS = 'SET_REPORTS';
export const SET_REPORTS_HISTORY = 'SET_REPORTS_HISTORY';
export const SET_REPORTS_HISTORY_LOADING = 'SET_REPORTS_HISTORY_LOADING';
export const SET_REPORTS_LOADING = 'SET_REPORTS_LOADING';
export const SET_REPORTS_SORT = 'SET_REPORTS_SORT';
export const SET_REPORTS_HISTORY_SORT = 'SET_REPORTS_HISTORY_SORT';
export const SET_REPORTS_SEARCH = 'SET_REPORTS_SEARCH';
export const SET_ACTIVITY_LOGS = 'SET_ACTIVITY_LOGS';

// import
export const SET_IMPORT_ACTIVITY_LOG_ID = 'SET_IMPORT_ACTIVITY_LOG_ID';
export const SET_IMPORT_ACTIVITY_LOG_DATA = 'SET_IMPORT_ACTIVITY__LOG_DATA';

// whitelabel domains
export const SET_WHITELABEL_DOMAINS = 'SET_WHITELABEL_DOMAINS';
export const SET_WHITELABEL_DOMAINS_LOADING = 'SET_WHITELABEL_DOMAINS_LOADING';
export const SET_WHITELABEL_DOMAIN_IS_ADDING = 'SET_WHITELABEL_DOMAIN_IS_ADDING';
export const SET_WHITELABEL_DOMAIN_IS_DELETING = 'SET_WHITELABEL_DOMAIN_IS_DELETING';
export const SET_WHITELABEL_DOMAIN_IS_VERIFYING = 'SET_WHITELABEL_DOMAIN_IS_VERIFYING';

// settings
export const SET_SETTINGS_IS_FETCHING = 'SET_SETTINGS_IS_FETCHING';

// sidebars
export const SET_CONTENT_LEFT_MARGIN = 'SET_CONTENT_LEFT_MARGIN';
export const SET_IS_SIDEBAR_RESIZING = 'SET_IS_SIDEBAR_RESIZING';
export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH';
export const SET_ARCHIVED_PROJECTS_OPENED = 'SET_ARCHIVED_PROJECTS_OPENED';
export const TOGGLE_EDIT_PROJECT = 'TOGGLE_EDIT_PROJECT';
export const SET_SIDEBAR_OPENED = 'SET_SIDEBAR_OPENED';
export const TOGGLE_PROJECT_ADD_TAGS = 'TOGGLE_PROJECT_ADD_TAGS';
export const TOGGLE_ADD_SUB_PROJECT = 'TOGGLE_ADD_SUB_PROJECT';
export const TOGGLE_PROJECT_EDIT_TAG = 'TOGGLE_PROJECT_EDIT_TAG';
export const SET_ADD_PROJECT_SCREEN_OPENED = 'SET_ADD_PROJECT_SCREEN_OPENED';

// tags
export const SET_ACTIVE_TAG = 'SET_ACTIVE_TAG';
export const SET_SHOW_ALL_TAGS = 'SET_SHOW_ALL_TAGS';
export const SET_MANAGE_TAGS_DATA = 'SET_MANAGE_TAGS_DATA';

// tutorial
export const SET_ARCHIVED_PROJECTS_AVAILABLE = 'SET_ARCHIVED_PROJECTS_AVAILABLE';
export const SET_TUTORIAL_MODE = 'SET_TUTORIAL_MODE';

// viewkey
export const SET_DATA_FOR_VIEWKEY = 'SET_DATA_FOR_VIEWKEY';
export const SET_VIEWKEY_ACCESS_STATUS = 'SET_VIEWKEY_ACCESS_STATUS';
export const SET_VIEWKEY_HAS_CHILDREN = 'SET_VIEWKEY_HAS_CHILDREN';
export const SET_VIEWKEY_VIEW = 'SET_VIEWKEY_VIEW';
export const SET_HASH_FOR_SHARE_LOADING = 'SET_HASH_FOR_SHARE_LOADING';
export const SET_HASH_FOR_SHARE = 'SET_HASH_FOR_SHARE';
export const SET_SHARE_VIEW_HASH_DATA = 'SET_SHARE_VIEW_HASH_DATA';

// account settings
export const SET_ACCOUNT_SETTINGS = 'SET_ACCOUNT_SETTINGS';
export const SET_API_KEY_RESET_IN_PROGRESS = 'SET_API_KEY_RESET_IN_PROGRESS';

// billing details
export const SET_BILLING_DETAILS = 'SET_BILLING_DETAILS';
export const SET_BILLING_DETAILS_LOADED = 'SET_BILLING_DETAILS_LOADED';

// addons settings
export const SET_ADDON_USERS = 'SET_ADDON_USERS';

// alerts settings
export const SET_ALERT_SETTINGS = 'SET_ALERT_SETTINGS';

// display settings
export const SET_DISPLAY_SETTINGS = 'SET_DISPLAY_SETTINGS';
export const SET_OTHER_SETTINGS = 'SET_OTHER_SETTINGS';
export const TOGGLE_KEYWORDS_TABLE_SORTER = 'TOGGLE_KEYWORDS_TABLE_SORTER';

// reports settings
export const SET_REPORTS_SETTINGS = 'SET_REPORTS_SETTINGS';
export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS';

// settings
export const SET_FILTER_BY_TAG = 'SET_FILTER_BY_TAG';
export const SET_PROJECTS_SEARCH = 'SET_PROJECTS_SEARCH';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_SUBSCRIPTION_DATA = 'SET_SUBSCRIPTION_DATA';
export const SET_PERMISSIONS_DATA = 'SET_PERMISSIONS_DATA';
export const SET_PERMISSIONS_DATA_LOADED = 'SET_PERMISSIONS_DATA_LOADED';
export const SET_USED = 'SET_USED';
export const SET_USED_IS_FETCHING = 'SET_USED_IS_FETCHING';
export const SET_USED_REFRESHES = 'SET_USED_REFRESHES';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_IS_FREE_PLAN = 'SET_IS_FREE_PLAN';
export const SET_USER_DATA_PROP = 'SET_USER_DATA_PROP';
export const SET_IS_AUTH_USER = 'SET_IS_AUTH_USER';
export const SET_EMAIL_NOTIFICATION_SETTINGS_DATA = 'SET_EMAIL_NOTIFICATION_SETTINGS_DATA';
export const SET_EMAIL_NOTIFICATION_SETTINGS_PROP = 'SET_EMAIL_NOTIFICATION_SETTINGS_PROP';
export const SET_IS_RESTRICTED_ACCESS_USER = 'SET_IS_RESTRICTED_ACCESS_USER';

// subscription
export const SET_SUBSCRIPTION_INACTIVE = 'SET_SUBSCRIPTION_INACTIVE';

//AUTH RESET
export const RESET = 'RESET';

//PROJECT OVERVIEW
export const SET_PROJECT_OVERVIEW_OPTION = 'SET_PROJECT_OVERVIEW_OPTION';
export const SET_PROJECT_OVERVIEW_OPTION_IS_VISIBLE = 'SET_PROJECT_OVERVIEW_OPTION_IS_VISIBLE';
export const SET_PROJECT_OVERVIEW_OPTIONS = 'SET_PROJECT_OVERVIEW_OPTIONS';
export const SET_PROJECT_VISIBILITY = 'SET_PROJECT_VISIBILITY';
export const SET_PROJECT_ESTIMATED_TRAFFIC = 'SET_PROJECT_ESTIMATED_TRAFFIC';
export const SET_PROJECT_OVERVIEW_DATA = 'SET_PROJECT_OVERVIEW_DATA';
export const SET_PROJECT_KEYWORDS_BY_INTENT = 'SET_PROJECT_KEYWORDS_BY_INTENT';
export const SET_PROJECT_SERP_FEATURE_PERFOMANCE = 'SET_PROJECT_SERP_FEATURE_PERFOMANCE';
export const SET_PROJECT_TOP_PAGES = 'SET_PROJECT_TOP_PAGES';
export const SET_PROJECT_AVERAGE_RANK = 'SET_PROJECT_AVERAGE_RANK';
export const SET_PROJECT_KEYWORDS_IN_TOP_POSITIONS = 'SET_PROJECT_KEYWORDS_IN_TOP_POSITIONS';
