import { render, staticRenderFns } from "./ShareKeywordsTable.vue?vue&type=template&id=033ba985&scoped=true&"
import script from "./ShareKeywordsTable.vue?vue&type=script&lang=js&"
export * from "./ShareKeywordsTable.vue?vue&type=script&lang=js&"
import style0 from "./ShareKeywordsTable.vue?vue&type=style&index=0&id=033ba985&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "033ba985",
  null
  
)

export default component.exports