<template functional>
    <div class="valid-plan">
        <div class="plan-content-message">
            <div class="message">{{ parent.$t('billing-subscription-will-renew') }}</div>
        </div>
        <div class="plan-content-date">
            <div class="date">{{ props.date }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PlanValid',
    }
</script>

<style lang="scss" scoped>
  .valid-plan {
    border: 1px solid kw-color(kw-green, 5);
    background-color: #DFF5DE;
    padding: 20px 20px;
    height: auto;
    width: calc(100% + 2px);
    bottom: -1px;
    left: -1px;
    position: relative;
    border-radius: 0 0 4px 4px;

    .plan-content-message {
      height: auto;
      width: 200px;
      margin: 0 auto;

      .message {
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        color: kw-color(kw-green, 5);
        text-align: center;
        margin-bottom: 5px;
      }
    }

    .plan-content-date {
      height: auto;
      width: 167px;
      margin: 0 auto;

      .date {
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        color: kw-color(kw-black, 1);
        text-align: center;
        margin-bottom: 5px;
      }
    }
  }
</style>