<template>
    <div class="custom-modal custom-modal_confirm-action">
        <div class="custom-modal__header">
            <div class="confirm-action-title">
                <span class="confirm-action-title" v-if="title">{{
                    title
                }}</span>
                <br />
                <span class="confirm-action-description" v-if="description">{{
                    description
                }}</span>
            </div>
            <span class="kw kw-x" @click="close" />
        </div>
        <div class="custom-modal__footer">
            <kw-button
                :button-text="confirmButtonText"
                button-type="primary"
                :loading="loading"
                @click="confirm"
            />
            <kw-button
                v-if="cancelButtonText"
                :button-text="cancelButtonText"
                button-type="outline-primary"
                @click="cancel"
            />
        </div>

        <global-events @keyup.esc="close" />
        <global-events @keyup.enter="confirm" />
    </div>
</template>

<script>
export default {
    name: 'ConfirmModal',
    props: {
        title: {
            type: String | Boolean,
            required: false,
            default: () => {
                return false;
            },
        },
        description: {
            type: String | Boolean,
            required: false,
            default: () => {
                return false;
            },
        },
        confirmButtonText: {
            type: String,
            required: false,
            default: () => {
                return 'Confirm';
            },
        },
        cancelButtonText: {
            type: String,
            required: false,
            default: () => {
                return 'Cancel';
            },
        },
        confirmCallback: {
            type: Function,
            required: true,
        },
        cancelCallback: {
            type: Function | Boolean,
            required: false,
            default: () => {
                return false;
            },
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        async confirm() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            try {
                await this.confirmCallback();

                this.close();
            } catch (e) {
                console.log(e, 'e');
            }

            this.loading = false;
        },
        async cancel() {
            if (this.cancelCallback) {
                await this.cancelCallback();
            }

            this.close();
        },
        async close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/modal';
.custom-modal_confirm-action {
    &.custom-modal {
        padding: 10px;
    }

    .custom-modal__header {
        display: flex;
        align-items: flex-start;

        .confirm-action-title {
            display: block;
            font-size: 18px;
            font-weight: 500;
            color: kw-color(kw-black, 7);
            flex: 1;
            padding: 5px 10px 0;
        }
        .confirm-action-description {
            display: block;
            font-size: 18px;
            font-weight: 400;
            color: kw-color(kw-black, 7);
            flex: 1;
            padding: 0 10px;
        }

        /deep/ .icon-close-cross {
            font-size: 11px;
            color: rgba($color: #000000, $alpha: 0.3);
            cursor: pointer;
            transition: color 0.7s;
            &:hover {
                color: rgba($color: #000000, $alpha: 0.7);
            }
        }
    }
    .custom-modal__footer {
        justify-content: flex-end;

        /deep/ button {
            width: 120px;
            padding: 10px;
            transition: opacity 0.7s;
            opacity: 1;

            &:hover {
                opacity: 0.7;
            }

            &:last-child {
                margin-left: 5px;
            }
        }
    }
}
</style>
