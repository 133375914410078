import client from '../client';
import interceptors from '../interceptors';
const gaProfileInterceptor = interceptors.get('gaProfiles');

client.interceptors.response.use(
    gaProfileInterceptor.s,
    gaProfileInterceptor.e,
);


const resource = '/analytics';

const encodedId = id => encodeURIComponent(id);

export default {
    fetchGaProfiles: async () => await client.get(`${resource}/profiles`),
    createGaProfile: async (payload) => await client.post(`${resource}/profiles`, payload),
    updateGaProfile: async (profileId, payload) => await client.patch(`${resource}/profiles/${profileId}`, payload),
    removeGaProfile: async (profileId) => await client.delete(`${resource}/profiles/${encodedId(profileId)}`),
    fetchGaAccountProfiles: async () => await client.get(`${resource}/fetch-ga-account-profiles`)
};
