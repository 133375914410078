<template>
    <div class="export-project-modal" data-cy="export-csv-modal">
        <closer @click="close" />
        <div
            class="custom-modal__header custom-modal_draggable"
            data-cy="export-csv-modal-header"
        >
            {{ $t("export-csv") }}
        </div>
        <div
            class="custom-modal__body custom-modal__body_export"
            data-cy="export-csv-modal-body"
        >
            <radio-buttons
                :options="exportPeriodConfig"
                :value="period"
                @radio_click="setPeriod"
            />
            <date-range
                v-if="period === 'range'"
                :end-label="$t('end-date')"
                :end-value="endValue"
                :set-date="setRange"
                :start-label="$t('start-date')"
                :start-value.sync="startValue"
            />
            <kw-button
                button-type="primary"
                :button-text="$t('download-csv-file')"
                data-cy="export-csv-modal-button"
                @click="exportFile"
            />
        </div>

        <global-events @keyup.enter="exportFile" />
    </div>
</template>

<script>
import { exportPeriodConfig } from "./configs";
import { mapGetters } from "vuex";
import { switchDateFormat } from "@/helpers/format-date-service";
import DateRange from "components/ui-elements/DateRange";
import { getFetchFilters } from "@/store/utils";
import i18n from "@/i18n";
import toastr from "@/helpers/init-toastr";
import { REPORT_TYPE } from '../../constants';

const today = new Date();
export default {
    name: "ExportProjectModal",
    components: {
        "date-range": DateRange,
    },
    props: {
        exportReport: {
            type: Function,
            required: true,
        },
        project: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            endValue: switchDateFormat(today, "m/d/YY"),
            exportPeriodConfig,
            period: "live",
            startValue: switchDateFormat(today, "m/d/YY"),
        };
    },
    computed: {
        ...mapGetters([
            "getKeywordFilters",
            "getViewkeyView",
            "getTotalKeywords",
        ]),
    },
    created() {
        this.getKeywordFilters.keywordsDate
            ? this.setRange(
                  "start",
                  switchDateFormat(
                      new Date(this.getKeywordFilters.keywordsDate),
                      "m/d/YY"
                  )
              )
            : this.setRange(
                  "start",
                  switchDateFormat(
                      new Date(
                          _.cloneDeep(today).setDate(today.getDate() - 30)
                      ),
                      "m/d/YY"
                  )
              );
    },
    methods: {
        setPeriod(value) {
            this.period = value;
        },
        setRange(type, date) {
            this[`${type}Value`] = date;
        },
        exportFile() {
            if (
                this.getTotalKeywords <= process.env.MAX_EXPORT_ITEMS_COUNT ||
                process.env.MAX_EXPORT_ITEMS_COUNT === undefined
            ) {
                let data = {
                    report_type: REPORT_TYPE.CSV,
                    ...getFetchFilters(this.$store.getters),
                };

                if (this.period !== "live") {
                    data.end_date = switchDateFormat(
                        new Date(this.endValue),
                        "Y-m-d"
                    );
                    data.start_date = switchDateFormat(
                        new Date(this.startValue),
                        "Y-m-d"
                    );
                }

                this.exportReport(this.project, data);
            } else {
                toastr.e(i18n.t("export-more-keywords-error"));
            }

            this.close();
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~sass/modal";

.custom-modal__body_export {
    border-top: 1px solid kw-color(kw-light-grey, 1);
    margin-right: 0;
    overflow: unset !important;
    padding: 22px 12px 12px 34px;
}

/deep/ .button-closer {
    position: absolute;
    right: 12px;
    top: 15px;
}

.radio-buttons {
    padding-bottom: 22px;
}

.kw-btn-primary {
    margin-left: auto;
    width: 160px;
}

/deep/ .date-range {
    margin-bottom: 30px;
}
</style>
