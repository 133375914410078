<template>
    <div
        data-cy="modal-note-delete"
        class="confirm-deleting-modal"
    >
        <div :class="headerClasses">
            <p>{{ headerText }}</p>
            <p
                @mouseover="getTooltipForText(type, $event)"
                v-text="type"
            />
        </div>
        <div :class="footerClasses">
            <kw-button
                button-type="text"
                :button-text="$t('cancel')"
                @click="close"
            />
            <kw-button
                button-type="primary"
                :button-text="$t('delete')"
                :loading="loading"
                data-cy="modal-note-delete-confirm"
                @click="confirm"
            />
        </div>

        <global-events @keyup.enter="confirm" />
    </div>
</template>

<script>
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    export default {
        name: 'SimpleDeleteWithCustomHandler',
        mixins: [ToolTipRenderMixin],
        props: {
            deleteHandler: {
                type: Function,
                required: true,
            },
            header: {
                type: String,
                required: false,
                default: null,
            },
            item: {
                type: [String, Number, Object, Array],
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
            unicClasses: {
                type: Object,
                required: false,
                default: () => {}
            },
        },
        data () {
            return {
                loading: false,
            };
        },
        computed: {
            headerText() {
                return this.header ?? this.$t('delete-modal-header')
            },
            headerClasses() {
                return {
                    'header': true,
                    ...this.unicClasses
                }
            },
            footerClasses () {
                return {
                    'footer': true,
                    ...this.unicClasses
                }
            }
        },
        methods: {
            async confirm () {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                await this.deleteHandler(this.item);
                this.loading = false;
                this.$emit('close');
            },
            close () {
                this.$emit('close');
            },
        },
    };

</script>

<style lang="scss">
  @import '~sass/modal';

  .confirm-deleting-modal {
    padding: 10px;

    .header {
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.01em;
      line-height: 24px;
      padding: 10px;

      &.delete-addon {
        text-align: center;
      }

      p {
        margin: 0;
        text-align: left;

        &:last-child {
          @import '~sass/cutting-name';
          margin-bottom: 10px;
          outline: transparent;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      button {
        width: 102px;

        &:first-child {
          color: kw-color(kw-blue, 1) !important;
        }

        &:last-child {
          background-color: kw-color(kw-red, 1) !important;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
</style>
