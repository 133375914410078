const getRange = function(dateString) {
    const oldDate = new Date(dateString);
    const now = new Date();
    const durationInSeconds = (now.getTime() - oldDate.getTime()) / 1000;
    if (durationInSeconds > 60*60*24*365) {
        const years = now.getFullYear() - oldDate.getFullYear();
        return years + ' year'  + (years > 1 ? 's' : '');
    }
    if (durationInSeconds > 60*60*24*31) {
        const nowMonth = now.getMonth();
        const oldMonth = oldDate.getMonth();
        const months = nowMonth > oldMonth ? nowMonth - oldMonth : nowMonth + 12 - oldMonth;
        return months + ' month' + (months > 1 ? 's' : '');
    }
    if (durationInSeconds > 60*60*24) {
        const nowDays = now.getDate();
        const oldDays = oldDate.getDate();
        const daysInMonth = new Date(oldDate.getFullYear(), oldDate.getMonth(), 0).getDate();
        const days = nowDays > oldDays ? nowDays - oldDays : nowDays - oldDays + daysInMonth;
        return days + ' day' + (days > 1 ? 's' : '');
    }
    if (durationInSeconds > 60*60) {
        const nowHour = now.getHours();
        const oldHour = oldDate.getHours();
        const hours = nowHour > oldHour ? nowHour - oldHour : nowHour - oldHour + 24;
        return hours + ' hour' + (hours > 1 ? 's' : '');
    }
    if (durationInSeconds > 60) {
        const nowMinutes = now.getMinutes();
        const oldMinutes = oldDate.getMinutes();
        const minutes = nowMinutes > oldMinutes ? nowMinutes - oldMinutes : nowMinutes - oldMinutes + 60;
        return minutes + ' minute' + minutes > 1 ? 's' : '';
    }
    if (durationInSeconds > 0) {
        const nowSeconds = now.getSeconds();
        const oldSeconds = oldDate.getSeconds();
        const seconds = nowSeconds > oldSeconds ? nowSeconds - oldSeconds : nowSeconds - oldSeconds + 60;
        return seconds + ' second' + seconds > 1 ? 's' : '';
    }
    return null;
}

const timeAgo = function (dateString) {
    const range = getRange(dateString);
    return range ? range + ' ago' : 'just now';
};

export { timeAgo };
