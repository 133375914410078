<template>
    <div class="invoices">
        <div class="account_invoice block_icon">
            <span class="kw kw-credit-card" />
        </div>
        <div class="settings_plan_title">
            {{ $t('invoices') }}
        </div>
        <div class="invoice_billing_detail_block">
        </div>
        <div class="invoices_table">
            <div class="invoices_table_container">
                <div class="invoice_table_header">
                    <div
                        v-for="(column, index) in getInvoicesColumns"
                        :key="index"
                        class="invoice_table_header_item"
                    >
                        <span>
                            {{ column.title }}
                        </span>
                    </div>
                </div>
                <transition
                    appear
                    mode="out-in"
                    name="fade"
                >
                    <loader
                        v-if="loading"
                        class="loader_item"
                    />
                    <template v-else>
                        <div
                            v-if="getAllInvoices.length"
                            key="list"
                            class="invoice_table_body"
                        >
                            <div
                                v-for="(item, index) in getAllInvoices"
                                :key="index"
                                class="invoice_table_row"
                            >
                                <div
                                    class="table_data"
                                >
                                    <span class="date">{{ getFormattedDate(item.date) }}</span>
                                </div>
                                <div
                                    class="table_data"
                                >
                                    <span class="amount">${{ item.total | amount }}</span>
                                </div>
                                <div
                                    class="table_data"
                                >
                                    <span class="pdf-link">
                                        <a
                                            :href="item.hosted_invoice_url"
                                            target="_blank"
                                        >{{ $t('pdf') }}</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            class="no-invoice-data"
                        >
                            <span
                                class="no-invoice-data-text"
                            >
                                No invoices to display
                            </span>
                        </div>
                    </template>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { getInvoicesColumns } from './configs';
    import { switchDateFormat } from '@/helpers/format-date-service';

    export default {
        name: 'Invoices',
        filters: {
            amount(value) {
                const val = parseInt(value) / 100;
                return val.toFixed(2);
            }
        },

        data () {
            return {
                loading: true,
                textareaData: '',
                getInvoicesColumns,
                detailSaveLoading: false,
            };
        },

        computed: {
            ...mapGetters([
                'getAllInvoices',
            ]),
        },

        async created () {
            this.loading = true;
            await this.fetchInvoices();
            this.loading = false;
        },

        methods: {
            ...mapActions([
                'fetchInvoices',
            ]),
            getFormattedDate(date) {
                return switchDateFormat(new Date(date), 'note');
            },

        }
    }
</script>

<style lang="scss">
  @import '~sass/table';

  .invoices {
    @extend .custom-scroll;
    padding: 0 19px 25px 19px;

    .invoices_table {
      @extend .table;
      flex: 1 1 auto;
      margin: {
        top: 17px;
      };

      .invoice_table_header {
        position: relative;
        display: flex;
        border-bottom: 1px solid kw-color(kw-light-grey, 4);

        &_item {
          width: 94px;

          &:first-child {
            width: 126px;
          }

          &:last-child {
            width: 38px;
          }

          span {
            font-size: 13px;
            font-weight: 500;
            line-height: 15px;
            text-transform: capitalize;
            color: kw-color(kw-black, 1);
            display: inline-block;
            margin-bottom: 5px;
          }
        }
      }

      .invoice_table_body {
        .invoice_table_row {
          display: flex;
          margin-bottom: 8px;

          .table_data {
            width: 94px;

            &:first-child {
              width: 128px;
            }

            &:last-child {
              width: 38px;
              text-align: right;
              padding-right: 2px;
            }

            .date {
              font-size: 13px;
              line-height: 15px;
              font-weight: 500;
            }

            .amount {
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 15px;
              color: #909399;
            }

            .pdf-link {
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 15px;
              color: kw-color(kw-blue, 1);
              display: flex;
              justify-content: flex-end;

              a {
                border-bottom: 1px solid kw-color(kw-blue, 1);
                text-decoration: none;
              }
            }

            span {
              @import '~sass/cutting-name';
            }
          }

          &:first-child {
            margin-top: 8px;
          }

          &:last-child {
            margin-bottom: 0;

            .table_data {
              padding-bottom: 0;
            }
          }
        }
      }

      .no-invoice-data {
        margin-top: 8px;
        .no-invoice-data-text {
          font-size: 13px;
          line-height: 15px;
          color: #63697B;
        }
      }
    }

    .invoice_table_body {
      @extend .custom-scroll;
      max-height: 350px;
      overflow-y: auto;
    }

    .button-loader {
      margin: 15px auto !important;
    }
  }
</style>