<template>
    <div class="confirm-handler-modal">
        <div class="header">
            {{ $t('manual-gmb-header') }}
            <closer @click="cancel" />
        </div>
        <div class="body">
            {{ $t('manual-gmb') }}
        </div>
        <kw-button
            :button-text="$t('ok')"
            button-type="primary"
            class="confirm-button"
            data-cy="simple-confirm-accept"
            @click="confirm"
        />

        <global-events @keyup.enter="confirm()" />
    </div>
</template>

<script>
export default {
    name: 'ConfirmManualGMBModal',
    props: {
        handler: {
            type: Function,
            required: true,
        },
    },
    methods: {
        confirm() {
            this.handler(true);
            this.close();
        },
        cancel() {
            this.handler(false);
            this.close();
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/modal';

.confirm-handler-modal {
    padding: 14px 16px 16px;

    .header {
        color: #000;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .body {
        color: #24242c;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 16px;
    }

    .button-closer {
        float: right;
    }

    .confirm-button {
        width: 145px;
        margin-top: 24px;
    }
}
</style>
