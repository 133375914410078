<template>
    <div class="top-competitors">
        <div :class="competitorsListClasses">
            <custom-table
                v-if="
                    !getMindshareHistoryDataLoading &&
                    !getNewAddedKeywordsIsFetching
                "
                :config="config"
                :is-selected="isSelected"
                :copy-callback="copyTable"
                :select-handler="selectDomain"
                :items="getMindshareLatestData"
                :is-all-selected="isAllSelected"
                :download-callback="downloadTable"
                :select-all-handler="selectAllDomains"
                :downloadable="downloadable"
                :showChart="showChart"
                :copyable="copyable"
                withBorder
            />
            
            <loader v-else />
        </div>
        <div :class="competitorsChartClasses">
            <top-competitors-chart
                :list="filteredList"
                :categories="getMindshareHistoryDates"
                :chartStyles="chartStyles"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import html2canvas from 'html2canvas';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import { copyAsPNG, exportAsPNG } from '@/helpers/downloads-service';
import TopCompetitorsChart from './TopCompetitorsChart';
import CustomTable from 'components/ui-elements/CustomTable';
import { competitorsTableConfig, viewsOptions } from './configs';

export default {
    name: 'ShareOfVoiceChart',
    components: {
        'custom-table': CustomTable,
        'top-competitors-chart': TopCompetitorsChart,
    },
    props: {
        mindshareView: {
            type: String,
            default: 'table'
        },
        mobileHide: {
            type: Boolean,
            default: true
        },
        downloadable: {
            type: Boolean,
            default: true
        },
        showChart: {
            type: Boolean,
            default: false
        },
        chartStyles: {
            type: Object,
            required: false
        },
        copyable: {
            type: Boolean,
            default: true
        },
    },
    mixins: [ToolTipRenderMixin],
    data() {
        return {
            viewsOptions,
            selected: [],
            view: 'percentage',
            config: competitorsTableConfig,
        };
    },
    computed: {
        ...mapGetters([
            'getUserData',
            'getMindshsareHistoryData',
            'getMindshareLatestData',
            'getMindshareHistoryDates',
            'getNewAddedKeywordsIsFetching',
            'getMindshareHistoryDataLoading',
            'getCurrentProject'
        ]),
        filteredList() {
            // Get the top ten share of voice data with colors and chart points
            return this.getMindshareHistoryDataLoading
                ? []
                : this.selected.reduce((acc, cur) => {
                      const selectedData = Object.values(
                          this.getMindshsareHistoryData,
                      ).find(m => m.domain === cur);
                      if (!selectedData) return acc;
                      acc.push(selectedData);
                      return acc;
                  }, []);
        },
        competitorsListClasses() {
            return {
                'top-competitors__list': true,
                'mobile-hide': this.mobileHide && this.mindshareView !== 'table',
            };
        },
        competitorsChartClasses() {
            return {
                'top-competitors__chart': true,
                'mobile-hide': this.mobileHide && this.mindshareView !== 'chart',
            };
        },
        isAllSelected() {
            return !!(
                this.getMindshareLatestData &&
                this.getMindshareLatestData.length &&
                this.selected.length === this.getMindshareLatestData.length
            );
        },
    },
    watch: {
        getCurrentProject(){
            this.selected = [];
        },
        getMindshareLatestData(data) {
            if (data && data.length && this.selected.length === 0) {
                this.selectAllDomains();
            }
        },
    },
    mounted(){
        if(this.selected.length === 0){
            this.selectAllDomains();
        }
    },
    methods: {
        selectDomain({ domain }) {
            this.selected = this.selected.includes(domain)
                ? this.selected.filter(s => s !== domain)
                : [...this.selected, domain];
        },
        selectAllDomains() {
            this.selected =
                this.selected.length === this.getMindshareLatestData.length
                    ? []
                    : this.getMindshareLatestData.map(t => t.domain);
        },
        isSelected({ domain }) {
            return this.selected.includes(domain);
        },
        async convertTableToCanvas(table) {
            const tableTitle = document
                .querySelector('#tableTitle')
                .cloneNode(true);
            tableTitle.classList.remove('browser-view');
            tableTitle.style.width = '60%';
            tableTitle.style.marginBottom = '3px';
            table.style.width = '700px';
            return await html2canvas(table, {
                scale: 2,
                width: 700,
                height: table.clientHeight + 70,
                onclone: d =>
                    d.querySelector('#customTable').prepend(tableTitle),
            });
        },
        async copyTable(table) {
            const canvas = await this.convertTableToCanvas(table);
            table.style.width = '100%';
            copyAsPNG(canvas);
            this.$toastr.s(this.$t('mindshare-copied-msg'));
        },
        async downloadTable(table) {
            const canvas = await this.convertTableToCanvas(table);
            table.style.width = '100%';
            exportAsPNG(
                canvas,
                `${this.$t('mindshare')} for ${this.getCurrentProject.name}`,
            );
        },
    },
};
</script>

<style lang="scss" scoped>

.top-competitors {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    padding: 0;
    width: 100%;
    min-height: 300px;

    &__loading {
        min-height: 400px;
    }

    .mobile-hide {
        @media (max-width: 959px) {
            display: none;
        }
    }

    .top-competitors__list {
        margin-bottom: 0;
        flex: 1;
    }

    .top-competitors__chart {
        flex: 1;
        border: 1px solid kw-color(kw-light-grey, 1);
        border-radius: 4px;
        @media screen and (max-width: 500px) {
            padding-top: 24px;
        }
    }

    .top-competitors-chart__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /deep/ .custom-table {
        .custom-table__row {
            .custom-table__cell:nth-child(3) {
                max-width: 180px;
            }

            .custom-table__cell:nth-child(4) {
                flex: 1;
            }

            .custom-table__cell:nth-child(5) {
                max-width: 130px;
            }
        }
    }
}

    
.empty-top-competitors {
    display: flex;
    flex: 1 1 auto;
    min-height: 400px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(~img/cactus.svg);
}

.project__btn {
    .btn-group {
        label {
            .kw {
                font-weight: 700;
            }
        }
        .btn.active {
            .kw {
                color: #0074d9;
            }
        }
    }
    .kw-list,
    .kw-competitor {
        display: block !important;
    }
}

.top-competitors__chart {
    display: flex;
    width: 100%;
}

.top-competitors__list {
    margin-bottom: 16px;
    position: relative;
    width: 100%;

    /deep/ .button-loader {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /deep/ span.icon-arrow_4 {
        font-size: 10px;
        line-height: 16px;
        width: 9px;
    }

    /deep/ span.icon-arrow_4.icon-rotate-down {
        color: kw-color(kw-red, 1);
    }

    /deep/ span.icon-arrow_4:not(.icon-rotate-down) {
        color: kw-color(kw-green, 1);
    }
}

.competitors-panel__header {
    align-items: center;
    display: flex;
    flex-flow: row wrap;

    &.two-columns {
        flex-flow: row wrap;
        justify-content: space-between;

        .btn-group {
            margin-left: auto;
            padding: 0;
        }

        @media (max-width: 959px) {
            .mobile-hide {
                display: none;
            }
            /deep/ .btn-group {
                margin-left: auto;
                padding: 0;

                label {
                    & > span {
                        display: none;
                    }
                }
            }
        }
    }

    &:not(.two-columns) {
        .dropdown-wrapper {
            span {
                white-space: nowrap;
            }
        }
    }

    .title_distribution {
        @media (max-width: 959px) {
            width: 100%;
        }
    }

    h4 {
        font-size: 17px;
        font-weight: 500;
    }

    span {
        color: #c4c4c4;
        font-size: 12px;
        font-weight: 500;
        margin-left: 4px;

        &.kw-help-circle {
            font-size: 14px;
            font-weight: 700;
            margin-right: 4px;
        }
    }
}

.browser-view {
    .project {
        display: none;
    }

    .kw-help-circle {
        display: block;
    }
}

.title {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;

    &:not(.browser-view) {
        .btn-group,
        .dropdown-wrapper {
            display: none;
        }
    }
}

.project {
    color: #919399;
    display: flex;
    flex: 1 1 100%;
    font-size: 12px;
    font-weight: 500;
    max-width: 400px;
}

.project__title,
.project__tags {
    align-self: center;
    margin-right: 8px;
}

.project__title {
    max-width: 160px;
}

.project__tags {
    max-width: 120px;
}

.project__keywords {
    align-items: center;
    display: flex;

    & > div {
        align-items: center;
        background-color: #919399;
        border-radius: 50%;
        color: kw-color(kw-white, 1);
        display: flex;
        font-size: 10px;
        height: 28px;
        justify-content: center;
        margin-right: 4px;
        padding: 4px;
        width: auto;
        min-width: 28px;
    }
}

.kw-help-circle {
    cursor: pointer;
    display: none;
    text-align: center;
}

@media (min-width: 960px) {
    .competitors-panel {
        padding: 0;
    }
    .competitors-panel__header {
        flex-flow: row nowrap;
    }
    .top-competitors {
        flex-flow: row nowrap;
    }
}
</style>

<style lang="scss">
.mouseover-mindshare {
    .title {
        font-weight: bold;
        display: block;
        margin-bottom: 7px;
    }
}
</style>
