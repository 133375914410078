import { isSafari } from '@/helpers/device-detection';
import { VIRTUAL_LIST_ITEM_HEIGHT, TABLE_PARTS } from '@/constants';

export default {
  data() {
    return {
      tableParts: TABLE_PARTS,
      VIRTUAL_LIST_ITEM_HEIGHT,
    }
  },

  methods: {

    // this method is used in the template to send events
    emitEvent(eventName, modifier, ...args) {
      if (typeof modifier === 'string') {
        eventName += `-${modifier}`;
      } else {
        args = [modifier, ...args];
      }
      this.$parent.$emit(eventName,  args);
    },

    getTableRowInnerClasses (i) {
      return {
        'table-row__inner': true,
        'table-row__inner_even': i % 2,
      };
    },

    // this method adds custom html if passed a suitable function "fieldFormatter"
    processField(item, header) {
      const formattedValue = this.fieldFormatter(item, header);
      return formattedValue || item[header.name];
    },

    //this method defines all classes for the table,
    // the argument can be one of TABLE_PARTS
    getClasses(part, item) {
      const classes = {};
      switch (part) {
        // Reminder change titleClass to headerClass in kwTableOptions
        case TABLE_PARTS.HEADER:
          if (item.headerClass) {
            classes[item.headerClass] = true;
          }
          classes['column-header_safari'] = isSafari();
          return classes;

        case TABLE_PARTS.HEADER_COLUMN:
          classes['column-header'] = true;
          return classes;

        case TABLE_PARTS.BODY_ROW:
          classes['table-row'] = true;
          return classes;

        case TABLE_PARTS.BODY_COLUMN:
          if (item.bodyClass) {
            classes[item.bodyClass] = true;
          }
          return classes;

      }
      return classes;
    },

    // did not fully understand this method,
    // this method was copied from the keywords table
    getDynamicShadow() {
      const dynTable = document.querySelector('.scroll-area');
      const dynHeaderRow = document.querySelector('.app-table_dynamic .header-row');

      if (!dynTable || !dynHeaderRow) {
        return 'both';
      }

      if (dynHeaderRow.scrollWidth === dynTable.clientWidth) {
        return 'none';
      }

      if (dynTable.scrollLeft < 1) {
        return 'right';
      }

      if (Math.round(dynTable.clientWidth + dynTable.scrollLeft) === dynHeaderRow.scrollWidth) {
        return 'left';
      }

      return 'both';
    },

    //the argument side can be either "left" or "right"
    getVirtualListWClass(side) {
      return ['both', side].includes(this.getDynamicShadow()) ? 'virtual-list_shadow' : ''
    },

    // this method return a style object to set custom width to columns
    generateColumnWidthStyles(widthInPixels, widthInPercentage = widthInPixels) {
      return {
        'minWidth': widthInPixels,
        width: widthInPercentage,
        'maxWidth': widthInPercentage,
      };
    },

    // this method defines all styles for the table,
    // the argument can be one of TABLE_PARTS
    getStyles(part, item) {
      const { percentWidth, width } = item;
      switch (part) {
        // Reminder change titleClass to headerClass in kwTableOptions
        case this.tableParts.COLUMN:
        case this.tableParts.DYNAMIC_BODY_COLUMN:
          if (item.width) {
            const { widthInPercentage } = this.getDynamicTableWidth();
            const widthOfItemsWithFixedWidth = this.getWidthOfItemsWithFixedWidth();
            const itemsWidthPercentWidthCount = this.dynamicHeaders.filter(header => +header.percentWidth).length
            const formattedWidthInPixel = `${width}px`;
            const formattedWidthInPercentage =
              percentWidth ?
                `calc(${percentWidth * 100 / widthInPercentage}% - ${widthOfItemsWithFixedWidth / itemsWidthPercentWidthCount}px)`
                : formattedWidthInPixel;
            return this.generateColumnWidthStyles(formattedWidthInPixel, formattedWidthInPercentage);
          }
          break;

        case this.tableParts.STATIC_BODY_COLUMN:
          if (item.width && item.percentWidth) {
            const { widthInPercentage } = this.getStaticTableWidth();
            const formattedWidthInPixel = `${item.width}px`;
            const formattedWidthInPercentage = `${item.percentWidth * 100 / widthInPercentage}%`;
            return this.generateColumnWidthStyles(formattedWidthInPixel, formattedWidthInPercentage);
          }
          break;

        case this.tableParts.ROW:
          if (item.width && item.percentWidth) {
            const formattedWidthInPixel = `${item.width}px`;
            const formattedWidthInPercentage = `${item.percentWidth}%`;
            return this.generateColumnWidthStyles(formattedWidthInPixel, formattedWidthInPercentage);
          }
          break;

      }
    },

    // do refactor
    setHoverState (id) {
      if (!this.activeHover) return;
      let hoverTimeout;

      hoverTimeout = setTimeout(() => {
        clearTimeout(hoverTimeout);

        try {
          document
            .querySelectorAll('.table-row__inner')
            .forEach(el => el.classList.remove('table-row__inner_hovered'));

          document
            .getElementById(`row-static-${id}`)
            .classList.add('table-row__inner_hovered');

          document
            .getElementById(`row-dynamic-${id}`)
            .classList.add('table-row__inner_hovered');
        } catch (e) {
          //
        }
      }, 25);
    },

    // do refactor
    removeHoverState (id) {
      if (!this.activeHover) return;
      setTimeout(() => {
        try {
          document
            .getElementById(`row-static-${id}`)
            .classList.remove('table-row__inner_hovered');

          document
            .getElementById(`row-dynamic-${id}`)
            .classList.remove('table-row__inner_hovered');
        } catch (e) {
          //
        }
      }, 25);
    },
  },

}