<template>
    <div class="share-data">
        <div
            v-if="isCompanyDataDisplayed" 
            class="share-data__info"
        >
            <h3
                class="share-data__company-name"
                data-cy="branding-preview-company_name"
                v-text="companyName"
            />
        </div>

        <div class="share-data__link">
            <refresh
                :title="projectDashboardTitle"
                :show-refresh-label="false"
                :sub-title="subTitle"
            />
            <a
                v-if="isCompanyDataDisplayed && project.company_logo"
                class="share-data__logo-link"
                data-cy="branding-preview-logo_link"
                :href="companyLink"
                target="_blank"
            >
                <img
                    alt="logo"
                    class="share-data__logo-image"
                    :src="project.company_logo"
                >
            </a>
        </div>
        <div
            v-if="isCompanyDataDisplayed"
            class="share-data__info"
        >
            <div
                class="share-data__description"
                data-cy="branding-preview-company_description"
            >
                {{ companyDescription }}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters} from 'vuex';
    import Refresh from 'components/refresh/Refresh';
    import {switchDateFormat} from '@/helpers/format-date-service';
    export default {
        name: 'ShareDataPdf',
        components: {
            'refresh': Refresh,
        },
        props: {
            project: {
                type: Object,
                required: false,
                default: () => {},
            },
            isCompanyDataDisplayed: {
                type: Boolean,
                required: true,
            }
        },
        computed: {
            ...mapGetters([
                'getListOfKeywordsDomains',
                'getProjectProperty',
            ]),
            projectDashboardTitle () {
                return this.getListOfKeywordsDomains.length > 1 || !this.getListOfKeywordsDomains.length
                    ? this.getProjectProperty('name')
                    : this.getListOfKeywordsDomains[0];
            },
            companyLink () {
                return this.project.company_link || '';
            },
            companyName () {
                return this.project.company_name || '';
            },
            companyDescription () {
                return this.project.company_desc || '';
            },
            subTitle () {
                return this.$t('downloaded-on') + ': ' + switchDateFormat(new Date(), 'pdf-date');
            },
        },

    }
</script>