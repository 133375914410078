<template>
    <div class="top-pages" data-cy="top-pages-tab">
        <top-pages-table />
    </div>
</template>

<script>
import TopPagesTable from './Table';
import { Events, EventBus } from '@/events';
export default {
    name: 'TopPagesTab',
    components: {
        'top-pages-table': TopPagesTable,
    },
    mounted() {
        EventBus.emit(Events.OPEN_TOP_PAGES_TAB);
    },
};
</script>
