<template>
    <div class="dashboard-metrics-wrap">
        <div class="dashbord-metrics">
            <div class="metrics-btn">
              <button-group
                v-if="type === 'table'"
                v-model="metricsValue"
                data-cy="metric-periods"
              >
                <radio
                  v-for="option in dashboardOverviewMetricsOptions"
                  :key="option.value"
                  :selected-value="option.value"
                  >
                    {{ option.label }}
                  </radio>
              </button-group>
            </div>
            <div class="dashboard">
            
                <metrics-block-keywords-change
                    :metrics="data"
                    :loading="loading"
                />
                
                <metrics-block
                    v-for="(block, key) in data.ranges"
                    :key="key"
                    :block="block"
                    :loading="loading"
                    :total-count="data.total"
                    :showPercentage="showPercentage"
                />
            
            </div>
        </div>
    </div>
</template>

<script>
    import {dashboardOverviewMetricsOptions} from './configs';
    import {mapGetters, mapActions} from 'vuex';
    import {SET_DISPLAY_SETTINGS} from '@/store/mutations';
    import buttonGroup from 'vue-strap/src/buttonGroup';
    import MetricsBlock from './components/MetricsBlock';
    import MetricsBlockKeywordsChange from './components/MetricsBlockKeywordsChange';
    import Radio from 'vue-strap/src/Radio';
    export default {
        name: 'Metrics',
        components: {
            'button-group': buttonGroup,
            'metrics-block-keywords-change': MetricsBlockKeywordsChange,
            'metrics-block': MetricsBlock,
            'radio': Radio,
        },
        props: {
            data: {
                type: Object,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
            type: {
                type: String,
                required: false,
                default: 'table',
            },
            showPercentage: {
              type: Boolean,
              required: false,
            }
        },
        data () {
            return {
                dashboardOverviewMetricsOptions,
                metricsValue: 'monthly',
            };
        },
        computed: {
            ...mapGetters([
                'getDisplaySetting',
                'getDisplaySettings',
            ]),
        },
        watch: {
            metricsValue (val) {
                if (val === this.getDisplaySetting('metrics')) {
                    return;
                }
                this.$emit('onDateChange', val)
                // unsubscribe after the first change to escape loop
                this.unsubscribeDisplaySettings();
                this.updateOnlyDisplaySettings({...this.getDisplaySettings, metrics: val});
            },
        },
        created () {
            // if store is empty we subscribe on display settings changes (on first time app opened)
            this.unsubscribeDisplaySettings = this.$store.subscribe(({type, payload}) => {
                if (type === SET_DISPLAY_SETTINGS) {
                    this.metricsValue = payload.metrics;
                }
            });

            // if dashboard overview was opened after routing from another page
            this.metricsValue = this.getDisplaySetting('metrics');
        },
        beforeDestroy () {
            this.unsubscribeDisplaySettings();
        },
        methods: {
            ...mapActions(['updateOnlyDisplaySettings']),
        },
    }
</script>

<style lang="scss">
  @import '~sass/partials/button-group';

  .dashboard-metrics-wrap {
    margin: 0;
    padding: 0;
  }

  .dashbord-metrics {
    align-items: center;
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
    max-width: 100%;
    overflow-x: auto;
  }

  .dashboard {
    display: flex;
    flex: 1 1 100%;
    justify-content: space-between;

    .dashboard-block {
      border-radius: 4px;
      flex: 1 7 140px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .dashboard-block:first-child {
      margin-left: 0;
    }

    .dashboard-block:last-child {
      margin-right: 0;
    }

    .dash-title {
      font-style: normal;
      font-weight: normal;
      line-height: 20px;
      font-size: 14px;
      color: #909399;

      span {
        white-space: nowrap;
      }
    }

    .dash-total {
      font-style: normal;
      font-size: 22px;
      letter-spacing: 0.01em;
      justify-content: center;
      color: kw-color(kw-black, 1);

      .total-up {
        color: kw-color(kw-green, 1);
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
        font-size: 14px;
      }
    }
  }

  //responsove design
  @media screen and (max-width: 959px) {
    .dashboard {
      flex-wrap: wrap;

      .dashboard-block {
        margin-left: 6px;
        margin-right: 6px;
        flex: 1 1 30%;
      }

      .dashboard-block:first-child {
        margin-left: unset;
      }

      .dashboard-block:last-child {
        margin-right: unset;
      }

      .dashboard-block:nth-child(3n) {
        margin-right: unset;
      }

      .dashboard-block:nth-child(4n) {
        margin-left: unset;
      }

      .dashboard-block:nth-child(n + 4) {
        margin-top: 12px;
      }
    }
  }

  .dashbord-metrics {
    .metrics-btn {
      display: flex;
    }
    .btn-group {
      @extend .button-group_outline;
      flex-flow: column nowrap;
    }
  }

  @media screen and (max-width: 959px) {
    .dashbord-metrics {
      .metrics-btn {
        display: none;
      }
    }
  }
</style>
