import {
    SET_TUTORIAL_MODE,
    SET_ARCHIVED_PROJECTS_AVAILABLE,
} from '@/store/mutations';
import router from '@/routes';
import apiFactory from '@/api';
const api = apiFactory.get('projects');

const tutorial = {
    state: {
        archivedProjectsAvailable: false,
        tutorialMode: null,
    },
    mutations: {
        [SET_ARCHIVED_PROJECTS_AVAILABLE]: (state, payload) => state.archivedProjectsAvailable = payload,
        [SET_TUTORIAL_MODE]: (state, payload) => state.tutorialMode = payload,
    },
    getters: {
        getArchivedProjectsAvailable: state => state.archivedProjectsAvailable,
        getTutorialMode: state => state.tutorialMode,
    },
    actions: {
        async checkAvailabilityOfArchivedProjects ({commit}) {
            const res = await Promise.all([
                api.fetchDeletedProjects({params: {limit: 1}}),
                api.fetchArchivedProjects({params: {limit: 1}}),
            ]);

            commit(SET_ARCHIVED_PROJECTS_AVAILABLE, !!res.reduce((acc, cur) => acc += cur.data.data.length, 0));
        },
        setTutorialMode({commit}, payload = false) {
            commit(SET_TUTORIAL_MODE, payload);

            if (payload) {
                router.push({ name: 'Tutorial' });
            }
        }
    },
};

export default tutorial;
