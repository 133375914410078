<template>
    <div class="top-competitors-chart__wrapper">
        <loader v-if="getMindshareHistoryDataLoading || getNewAddedKeywordsIsFetching" />
        <highcharts
            v-else
            ref="chartComponent"
            :callback="chartCallback"
            :class="topCompetitorsChartClasses"
            :options="config(list, categories, chartStyles)"
        />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Highcharts from 'highcharts';
    import { Chart } from 'highcharts-vue';

    import NoData from 'highcharts/modules/no-data-to-display';
    import { config } from './configs/TopCompetitorsChartConfig';

    NoData(Highcharts);

    export default {
        name: 'TopCompetitorsChart',
        components: {
            'highcharts': Chart,
        },
        props: {
            list: {
                type: Array,
                required: true,
            },
            categories: {
                type: Array,
                required: true,
            },
            chartStyles: {
                type: Object,
                required: false
            }
        },
        data () {
            return {
                config,
                debouncedHandler: _.debounce(fn => fn(), 200),
            };
        },
        computed: {
            ...mapGetters([
                'getSidebarWidth',
                'isLeftSidebarOpen',
                'getNewAddedKeywordsIsFetching',
                'getMindshareHistoryDataLoading',
            ]),
            topCompetitorsChartClasses () {
                return {
                    'top-competitors-chart-classes': true,
                    'top-competitors-chart-classes_loading': this.getMindshareHistoryDataLoading,
                };
            },
        },
        watch: {
            isLeftSidebarOpen () {
                this.debouncedHandler(this.resize);
            },
            getSidebarWidth () {
                this.debouncedHandler(this.resize);
            },
        },
        methods: {
            chartCallback () {
                const chartBG = document.querySelector('.top-competitors-chart-classes image');

                if (chartBG) {
                    chartBG.setAttribute('preserveAspectRatio', 'xMidYMid slice');
                }
            },
            resize () {
                if (this.$refs.chartComponent) {
                    const v = document.getElementsByClassName('top-competitors')[0].clientWidth / 2 - 16;
                    this.$refs.chartComponent.chart.setSize(v);
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .top-competitors-chart__wrapper {
        position: relative;
        width: 100%;
        /deep/ .button-loader {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        /deep/ .highcharts-tooltip {
            span {
                display: flex;
                flex-flow: column nowrap;
                min-width: 233px;
            }
            .top-competitors-chart__label-header {
                font-weight: 500;
            }
            .top-competitors-chart__label-item {
                display: flex;
                font-size: 12px;
                justify-content: space-between;
                padding: 4px 0;
                i {
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 3px;
                    height: 6px;
                    width: 6px;
                }
            }
        }
    }
    .top-competitors-chart-classes {
        width: 100%;
        /deep/ image {
            height: 160px;
            transform: translateY(100px);
        }
    }
    .top-competitors-chart-classes_loading {
        opacity: 0;
    }
</style>
