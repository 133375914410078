import {
    SET_ACTIVE_TAG,
    SET_SHOW_ALL_TAGS,
    SET_ACTIVE_GROUPS,
    SET_HASH_FOR_SHARE,
    SET_MANAGE_TAGS_DATA,
} from '../mutations';
import apiFactory from '@/api';
import router from '@/routes';
import { getFetchFilters } from '../utils';
const keywordsApi = apiFactory.get('keywords');
const tagsApi = apiFactory.get('tags');

export const tagsPersistedKeys = [
    'tags.activeTag',
];

const tags = {
    state: {
        activeTag: {},
        manageTagsData: [],
        showAllTags: null,
    },
    mutations: {
        [SET_ACTIVE_TAG]: (state, payload) => state.activeTag = payload,
        [SET_SHOW_ALL_TAGS]: (state, payload) => state.showAllTags = payload,
        [SET_MANAGE_TAGS_DATA]: (state, payload) => state.manageTagsData = payload,
    },
    getters: {
        getActiveTag: state => state.activeTag,
        getShowAllTags: state => state.showAllTags,
        getManageTagsData: state => state.manageTagsData,
    },
    actions: {
        setShowAllTags({commit}, auth = null) {
            commit(SET_SHOW_ALL_TAGS, auth);
        },
        addActiveTag ({getters: { getProjectProperty, getCurrentProject}, commit, dispatch}, payload) {
            commit(SET_ACTIVE_TAG, payload);
            commit(SET_HASH_FOR_SHARE, '');
            // as we dont show charts in project page, there is no need to fetch chart data width metrics
            dispatch('fetchMetrics', { includeChart: true });

            if (router.history.current.name === 'shareViewkey' ||
                router.history.current.name === 'shareViewkeyWithHash' ||
                router.history.current.name === 'shareKeywordsTable' ||
                router.history.current.name === 'shareKeywordsTableWithHash'
            ) {
                dispatch('fetchKeywordsForShareViewKeys', {
                    id: getProjectProperty('auth'),
                    password: getProjectProperty('password'),
                    shortkey: getProjectProperty('shortkey'),
                });
            } else {
                dispatch('fetchKeywords', {id: getCurrentProject.id});
            }
        },

        resetTagFilter ({commit}) {
            commit(SET_ACTIVE_TAG, {});
        },

        async updateTags ({dispatch, getters}, {keywords, tags}) {
            const tagsArray = [];
            tags.map(tag => tagsArray.push(tag.text));
            let keywordsIds = [];
            keywords.map(keyword => keywordsIds.push(keyword.id));

            const queryData = {
                data: {
                    ids: keywordsIds,
                    type: 'keyword',
                    attributes: {tags: tagsArray},
                },
            };

            try {
                const res = await keywordsApi.updateTags(queryData);
                dispatch('updateLeftSidebarTags', res.data.meta.extra_data.tags);
                dispatch('setCurrentProject', {passedId: getters.getCurrentProject.id,forced: true});
                dispatch('resetTagFilter');
                dispatch('fetchMetrics');
                dispatch('fetchKeywords', {
                    id: getters.getCurrentProject.id,
                    not_reset_page: true,
                });
            } catch (e) {
                throw new Error(e)
            }

        },
        async manageTags ({dispatch, getters}, {data, projectId}) {
            try {
                const projectName = getters.getCurrentProject.id;
                const res = await keywordsApi.attachTags({payload: {data}, projectId, params: getFetchFilters(getters)});

                dispatch('updateLeftSidebarTags', {[projectName]: res.data.data.tags});
                dispatch('fetchMetrics');
                dispatch('fetchKeywords', {
                    id: projectName,
                    not_reset_page: true,
                });
                dispatch('setSelectedKeywords', []);
                dispatch('setAllCheckboxesSelected', false);
            } catch (e) {
                throw new Error(e)
            }

        },
        updateLeftSidebarTags ({getters, commit}, affected) {
            const projects = _.cloneDeep(getters.getOriginalActiveProjects);

            Object.keys(affected).forEach(key => {
                const project = projects.find(item => item.id === key);
                if (project) {
                    project.attributes.tags = affected[key];
                }
            });

            commit(SET_ACTIVE_GROUPS, projects);
        },

        //new methods >= 2/9/2021
        async createTags({dispatch, getters, commit}, {projectId, tags, fromManageTags = false}) {
            const queryData = {
                data: {
                    tags
                },
            };

            try {
                const res = await tagsApi.createTags(queryData, projectId);
                const projects = _.cloneDeep(getters.getOriginalActiveProjects);
                projects.forEach(item => {
                    if (item.attributes.project_id === projectId) {
                        const newTags =  [...item.attributes.tags, ...res.data.data.tags];
                        item.attributes.tags = newTags;
                        dispatch('updateCurrentProjectTags', newTags);
                    }
                });
                commit(SET_ACTIVE_GROUPS, projects);
                if(fromManageTags) {
                    const data = res.data.data.tags.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            keywordsIds: [],
                            statusCode: 3,
                            checkedStatus: 'unChecked',

                        }
                    });
                    commit(SET_MANAGE_TAGS_DATA, [...getters.getManageTagsData, ...data])
                }
            } catch (e) {
                throw new Error(e)
            }
        },

        async deleteProjectTag({dispatch, getters, commit}, {projectId, tagId}) {
            try {
                await tagsApi.deleteProjectTag(projectId, tagId);
                const projects = _.cloneDeep(getters.getOriginalActiveProjects);
                projects.forEach(item => {
                    if (item.attributes.project_id === projectId) {
                         const filteredTags = item.attributes.tags.filter((tagItem) => {
                           return tagItem.id !== tagId
                        })
                        item.attributes.tags = filteredTags;
                        dispatch('updateCurrentProjectTags', filteredTags);
                    }
                });
                commit(SET_ACTIVE_GROUPS, projects);

            } catch (e) {
                console.log(e, 'error')
                throw new Error(e)
            }
        },

        async editProjectTag({dispatch, getters, commit}, {projectId, tagId, payload}) {
            const bodyData = {
                data: {
                    ...payload
                },
            };

            try {
                const res = await tagsApi.editTag(bodyData, projectId, tagId);
                const projects = _.cloneDeep(getters.getOriginalActiveProjects);
                projects.forEach(item => {
                    if (item.attributes.project_id === projectId) {
                        const mappedTags = item.attributes.tags.map((tagItem) => {
                            if(tagItem.id === tagId) {
                                return res.data.data
                            }
                            return tagItem
                        });
                        item.attributes.tags = mappedTags;
                        dispatch('updateCurrentProjectTags', mappedTags);
                    }
                });
                commit(SET_ACTIVE_GROUPS, projects);
                dispatch('toggleProjectEditTag');

            } catch (e) {
                throw new Error(e)
            }
        },
        async fetchManageTagsData({getters, commit}, payload) {
            try {

                const params = {
                    ...getFetchFilters(getters),
                    ...payload,
                }
                const totalKeywords = getters.getIsAllCheckboxesSelected ? getters.getTotalKeywords: getters.keyKeywordsById.length;
                const response = await tagsApi.fetchManageTagsData({params}, getters.getCurrentProject.project_id);
                const adaptedData = response.data.data.map((item) => {
                    const additionalData = {
                        checkedStatus: 'checked',
                        statusCode: 1,
                    };
                    if(!item.keywords_count) {
                        additionalData.statusCode = 3;
                        additionalData.checkedStatus = 'unChecked';
                    }else if(totalKeywords > item.keywords_count) {
                        additionalData.statusCode = 2;
                        additionalData.checkedStatus = 'partialChecked';
                    }
                    return {
                        ...item,
                       ...additionalData,
                    }
                });
                commit(SET_MANAGE_TAGS_DATA, adaptedData);
            }catch(e) {
                console.log(e, 'fetchManageTagsData error');
            }
        }
    },

};

export default tags;