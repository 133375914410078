<template>
    <span v-if="getValue === null" :data-important-tooltip="$t('refreshing')">
        {{ $t('updating') }}
    </span>
    <span
        v-else-if="showEmptyValue"
        :data-important-tooltip="$t('not-changed-data')"
        class="empty-value"
        >&mdash;</span
    >
    <span v-else>{{ showValue }}</span>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'SearchDetailRenderer',
    computed: {
        ...mapGetters([
            'getProjectByProjectId',
            'getDisplaySetting',
            'getCurrencyByCode',
        ]),
        getValue() {
            return this.params.column.colId === 'searchvolume'
                ? this.params.data.ms
                : this.params.value;
        },
        showEmptyValue(){
            return this.getValue === 0 && this.params.column.colId !== 'competition';
        },
        showValue() {
            switch (this.params.column.colId) {
                case 'competition':
                    return this.params.value < 0.35
                        ? this.$t('low')
                        : this.params.value < 0.7
                        ? this.$t('medium')
                        : this.$t('high');
                case 'cpc':
                    return `${decodeURIComponent(
                        this.params.data.currency_symbol,
                    )} ${this.params.value.toFixed(2)}`;
                case 'searchvolume':
                    return this.params.data.ms.toLocaleString('en');
                case 'seocomp':
                    return this.params.value.toLocaleString('en');
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
