<template>
    <div class="billing-plan">
        <div
            v-if="!getSettingIsFetching && currentPlanStatusValid"
            class="panel-default current-plan"
        >
            <div :class="getStatusClasses">
                <span class="kw kw-credit-card" />
            </div>
            <div class="plan-info">
                <div :class="getPlanClasses">
                    {{ $t('billing-current-plan') }}
                </div>
                <span class="plan-description">
                    {{ keywordsCount | keywordsFilter }} Keywords with
                    {{ cycleUpdates | hourlyFilter }} updates
                    <span v-if="getIsTrial" class="description-trial-text">
                        {{
                            $t('free-trial', {
                                trialPeriodDays: this.$t(
                                    'signup-form.duration.' +
                                        this.getInitialTrialPeriod,
                                ),
                            })
                        }}
                    </span>
                </span>
                <div class="plan-price">
                    ${{ packagePrice }}/{{ packageBillingInterval }}
                    <span class="plan-discount" v-if="totalDiscount !== 0">
                        ({{
                            $t('billing-discount', { discount: totalDiscount })
                        }})
                    </span>
                </div>
                <kw-button
                    :button-text="$t('billing-update-plan')"
                    button-type="secondary"
                    @click="$router.push({ name: 'Pricing' })"
                />
            </div>
            <div class="plan-status">
                <plan-valid
                    v-if="getCurrentPlan.status === 'success'"
                    :date="getFormattedDate(tsInMilliseconds)"
                />
                <plan-warning
                    v-else-if="getCurrentPlan.status === 'warning'"
                    :date="getFormattedDate(tsInMilliseconds)"
                />
                <plan-error
                    v-else-if="getCurrentPlan.status === 'error'"
                    :date="getFormattedDate(tsInMilliseconds)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PlanError from './components/PlanError';
import PlanValid from './components/PlanValid';
import PlanWarning from './components/PlanWarning';

export default {
    name: 'Billing',
    components: {
        'plan-error': PlanError,
        'plan-valid': PlanValid,
        'plan-warning': PlanWarning,
    },
    filters: {
        keywordsFilter(keywords) {
            return keywords.toLocaleString();
        },
        hourlyFilter(value) {
            return value === 'hourly' ? 'bihourly' : value;
        },
    },
    computed: {
        ...mapGetters([
            'getInitialTrialPeriod',
            'getCurrentPlan',
            'getFormattedDate',
            'getSettingIsFetching',
        ]),
        packageTitle() {
            return this.getCurrentPlan.package_info.title || '';
        },
        keywordsCount() {
            return this.getCurrentPlan.subscription.package || '';
        },
        cycleUpdates() {
            return this.getCurrentPlan.subscription.cycle || '';
        },
        packagePrice() {
            return this.getCurrentPlan.subscription.stripe_plan_amount || 0;
        },
        totalDiscount() {
            return this.getCurrentPlan.subscription.total_discount || 0;
        },
        packageBillingInterval() {
            return this.getCurrentPlan.subscription.billing_cycle || '';
        },
        tsInMilliseconds() {
            return new Date(
                this.getCurrentPlan.subscription.account_expire_at,
            ).getTime();
        },

        currentPlanStatusValid() {
            const defaultStatus = 'na';

            const {
                status = defaultStatus,
                subscription,
            } = this.getCurrentPlan;

            if (subscription && subscription.active === 2) {
                return true;
            }

            // comming from legacy payment systems
            if (
                subscription &&
                subscription.active === 1 &&
                !subscription.scripe_active
            ) {
                return true;
            }

            return status !== defaultStatus;
        },
        isSubscriptionActive() {
            return (
                this.getCurrentPlan.subscription.stripe_active === true &&
                this.getCurrentPlan.expiredStatus === 'actual' &&
                this.getCurrentPlan.status === 'success'
            );
        },
        getStatusClasses() {
            const { subscription, status } = this.getCurrentPlan;
            let planStatus = status;

            if (
                !subscription.isExpired &&
                !subscription.cancel_at &&
                planStatus !== 'error'
            ) {
                planStatus = 'success';
            }
            return {
                [planStatus]: true,
                'plan-package': true,
                block_icon: true,
            };
        },
        getPlanClasses() {
            return {
                plan: true,
                'plan-name-success': this.getCurrentPlan.status === 'success',
                'plan-name-waring': this.getCurrentPlan.status === 'warning',
                'plan-name-error': this.getCurrentPlan.status === 'error',
            };
        },
        getIsTrial() {
            return this.getCurrentPlan.subscription.active === 2;
        },
    },
    async mounted() {
        await this.fetchPackages();

        if (_.isEmpty(this.getCurrentPlan) && !this.getSettingIsFetching) {
            await this.loadPlan();
        }
    },
    methods: {
        ...mapActions([
            'fetchSettings',
            'fetchPackages',
        ]),
        async loadPlan() {
            await this.fetchSettings();
            this.checkLoadedPlan();
        },

        checkLoadedPlan() {
            if (!this.currentPlanStatusValid) {
                this.$toastr.e(this.$t('billing-load-plan-error'));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.selected-plan {
    margin: 10px 0;
}

.billing-plan {
    .plan_change_button {
        box-shadow: none;
    }

    .current-plan {
        width: 100%;

        .plan-info {
            padding: 0 25px 50px 25px;

            .plan {
                color: kw-color(kw-grey, 1);
                font-size: 13px;
                line-height: 18px;
                margin-bottom: 10px;
            }

            .plan-name {
                font-size: 20px;
            }

            .plan-name-success {
                color: kw-color(kw-grey, 1);
            }

            .plan-name-waring {
                color: #fb7b05;
            }

            .plan-name-error {
                color: kw-color(kw-red, 1);
            }

            .plan-description {
                font-size: 20px;
                font-weight: 500;
                width: 100%;
                color: kw-color(kw-black, 1);
                line-height: 22px;
                margin-bottom: 15px;
                display: block;
                .description-trial-text {
                    display: block;
                }
            }

            .plan-price {
                font-size: 13px;
                font-weight: 500;
                color: kw-color(kw-black, 1);
                margin-bottom: 15px;

                .plan-discount {
                    color: #0074d9;
                }
            }
        }

        .plan-status {
            background-color: kw-color(kw-white, 1);
            padding: 0;
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
}
</style>
