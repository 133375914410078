import makeTableColumns, { fieldsConfig } from 'components/main-table/configs/fields';

import { GTM_EVENTS } from '@/constants';
import {
    SET_CURRENT_PAGE,
    SET_GRANK_RANGE,
    SET_KEYWORDS_TABLE_SETTINGS,
    SET_SEARCH_KEYWORD,
    UPDATE_KEYWORD_FILTERS,
    SET_HIDE_URL_KEYWORDS,
    SET_CURRENT_TOOLTIP
} from '@/store/mutations';

const keywordsTableSettings = {
    state: {
        changedKeywordsOnly: false,
        columnsConfig: fieldsConfig,
        currentPage: 1,
        grankRange: { min: '', max: '' },
        isHideURLKeywords: false,
        searchKeyword: '',
        filters: {
            activeTag: {},
            showFilterValue: 'all',
            keywordsDate: '',
            enginesValue: '',
        },
        currentTooltip: null
    },
    mutations: {
        [SET_CURRENT_PAGE]: (state, currentPage) => state.currentPage = currentPage,
        [SET_GRANK_RANGE]: (state, grankRange) => state.grankRange = grankRange,
        [SET_KEYWORDS_TABLE_SETTINGS]: (state, columnsConfig) => state.columnsConfig = columnsConfig,
        [SET_SEARCH_KEYWORD]: (state, payload) => state.searchKeyword = payload,
        [UPDATE_KEYWORD_FILTERS]: (state, payload) => state.filters = payload,
        [SET_HIDE_URL_KEYWORDS]: (state, payload) => state.isHideURLKeywords = payload,
        [SET_CURRENT_TOOLTIP]: (state, payload) => state.currentTooltip = payload,
    },
    getters: {
        getCurrentPage: state => state.currentPage,
        getColumnsConfig: state => state.columnsConfig,
        getTableColumns: (state, getters) => makeTableColumns(
            state.columnsConfig,
            getters.getUserDataProp('gscConnected'),
            getters.getViewkeyView,
            getters.getKeywordSetting('table_columns'),
            getters.getIsFreePlan
        ),
        getSearchKeyword: state => state.searchKeyword,
        getShowFilterForRequest: state => {
            switch (state.filters.showFilterValue) {
                case 'all':
                    return '';
                case 'favorited':
                    return '1';
                case 'unfavorited':
                    return '0';
            }
            return 'all';
        },
        getIsHideURLKeywords: state => state.isHideURLKeywords,
        getGrankRange: state => state.grankRange,
        getChangedKeywordsOnly: state => state.changedKeywordsOnly,
        getKeywordFilters: state => state.filters,
        getCurrentTooltip: state => state.currentTooltip,
    },
    actions: {
        updateFilters({ commit, dispatch, getters }, { params, skipFetch = false }) {
            const { getUserData } = getters;
            dispatch('setCurrentPage', 1);
            commit(UPDATE_KEYWORD_FILTERS, { ...getters.getKeywordFilters, ...params });
            if (!skipFetch) {
                /**
                 * GTM custom event d_used_filter
                 */
                let filter_types = [];

                if (Object.keys(params.activeTag).length) {
                    filter_types.push('tags');
                }
                if (params.enginesValue) {
                    filter_types.push('search engine');
                }
                if (params.keywordsDate) {
                    filter_types.push('keywords date');
                }
                if (params.showFilterValue !== 'all') {
                    filter_types.push(params.showFilterValue);
                }

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: GTM_EVENTS.USE_FILTER,
                    filter_types: filter_types,
                    uid: getUserData.userId,
                });
                dispatch('fetchKeywordsAndMetrics');
            }
        },
        searchKeywords({ commit, dispatch }, keyword) {
            commit(SET_SEARCH_KEYWORD, keyword);
            dispatch('setCurrentPage', 1);
            dispatch('fetchKeywordsAndMetrics');
        },
        fetchKeywordsAndMetrics({ getters, dispatch }) {
            if (getters.getViewkeyView) {
                const data = {
                    shortkey: getters.getProjectProperty('shortkey'),
                    id: getters.getProjectProperty('auth'),
                    password: getters.getProjectProperty('password'),
                };

                dispatch('fetchKeywordsForShareViewKeys', data);
                dispatch('fetchViewkeyMetricsAndChart');
            } else {
                dispatch('fetchMetrics');
                dispatch('fetchKeywords', { id: getters.getProjectProperty('id') });
            }
        },
        resetFilter: ({ commit, dispatch }) => {
            dispatch('setGrankRange', { max: '', min: '' })
            dispatch('setHideUrlKeywords', false);
            commit(SET_SEARCH_KEYWORD, '');
            commit(UPDATE_KEYWORD_FILTERS, {
                activeTag: {},
                showFilterValue: 'all',
                keywordsDate: '',
                enginesValue: '',
            });
        },
        setGrankRange({ commit }, payload) {
            commit(SET_GRANK_RANGE, payload);
        },
        setCurrentPage({ commit }, payload) {
            commit(SET_CURRENT_PAGE, payload);
        },
        toggleIsHideUrlKeywords({ dispatch, getters }) {
            const storeData = getters.getIsHideURLKeywords;
            dispatch('setHideUrlKeywords', !storeData);
        },
        setHideUrlKeywords({ commit }, payload) {
            commit(SET_HIDE_URL_KEYWORDS, payload)
        },
        setCurrentTooltip({ commit }, payload) {
            commit(SET_CURRENT_TOOLTIP, payload)
        },
    }
};

export default keywordsTableSettings;