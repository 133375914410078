export default (i18n, toastr) => {
    return error => {
        const { config, response } = error;

        if (!config || !response) {
            return Promise.reject(error);
        }

        if (config.method === 'post' && config.url.includes('/addon/users')) {
            if (response.data && response.data.errors && response.data.errors[0] && response.data.errors[0].code === '2014') {
                toastr.e(i18n.t('existed-addon-error'));
            } else {
                toastr.e(i18n.t('add-addon-error'));
            }
        }

        if (config.method === 'delete' && config.url.includes('/addon/users/')) {
            toastr.e(i18n.t('delete-addon-error'));
        }

        if (config.method === 'patch' && config.url.includes('/addon/users/')) {
            toastr.e(i18n.t('update-addon-error'));
        }

        return Promise.reject(error);
    };
};