<template functional>
    <div class="rotating-icon-wrapper">
        <span
            class="kw kw-refresh-ccw"
            :data-important-tooltip="parent.$t('refresh-state-tooltip')"
        />
    </div>
</template>

<script>
export default {
    name: 'RotatingUpdateIcon',
};
</script>

<style lang="scss" scoped></style>
