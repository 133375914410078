<template>
    <div class="header-links">
        <div
            :class="mobileLogoClasses"
            @click="goToDashboard"
        />
        <global-search-bar
            :classes="globalSearchBarClasses"
            :on-blur="() => globalSearchVisible = false"
        />
        <router-link v-if="showSettings" class="link-item" :to="getSettingsPath">
          <span class="kw kw-settings icon" />
        </router-link>
        <router-link class="link-item" to="/alerts">
          <span
            v-if="showUnread"
            class="alerts-label"
            v-text="getUnreadAlertsCount"
          />
          <span class="kw kw-bell icon" />
        </router-link>
       
    </div>
</template>

<script>
  import GlobalSearchBar from './GlobalSearchBar';
  import { mapGetters } from 'vuex';

  export default {
    name: 'HeaderLinks',
    components: {
        'global-search-bar': GlobalSearchBar
    },
    data() {
      return {
        globalSearchVisible: false
      };
    },
    computed: {
      ...mapGetters([
        'getTutorialMode',
        'isViewOnlyUser',
        'isAddonUser',
        'getUnreadAlertsCount',
      ]),
      showSettings(){
        return !this.getTutorialMode && !this.isViewOnlyUser;
      },
      globalSearchBarClasses() {
          return this.globalSearchVisible ? '' : 'global-search-bar_hidden';
      },
      mobileLogoClasses() {
          return {
            'header-link header-link_mobile-logo': true,
            'header-link_hidden': this.globalSearchVisible
          };
      },
      getSettingsPath(){
        return this.isAddonUser ? '/settings/notifications' : '/settings/account'
      },
      showUnread(){
        return (
          this.getUnreadAlertsCount !== '0' &&
          this.getUnreadAlertsCount !== null
        )
      }
    },
    methods: {
        goToDashboard() {
          this.$router.push({ name: 'Overview' });
        }
    }
  };
</script>

<style lang="scss" scoped>
  .header-links {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    padding: 0 6px;
  }

  .link-item {
    position: relative;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: kw-color(kw-white, 1);
    margin-left: 4px;
    border-radius: 4px;

    .icon {
      color: #667085;
      font-weight: 700;
      font-size: 14.82px;
    }

    &:hover {
      background: #F7F8FA;
    }

    .alerts-label {
      position: absolute;
      top: -4px;
      right: 0;
      transform: translateX(35%);
      background-color: kw-color(kw-red, 1);
      border-radius: 10px;
      color: kw-color(kw-white, 1);
      display: block;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      padding: 2px 5px;
      text-align: center;
    }
    @media screen and (max-width: 480px) {
      width: 28px;
    }
  }

  .header-link {
    display: none;
    padding: 0 12px;

    a {
      align-items: center;
      color: kw-color(kw-grey, 1);
      display: flex;
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
    }

    i {
      color: #828F9C;
      font-weight: 600;
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .header-link_mobile-logo {
    background-image: url(~img/logo-k.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: block;
    height: 25px;
    padding: 0;
    width: 18px;
    margin-right: auto;
  }

  .header-link_hidden {
    display: none;
  }

  @media screen and (min-width: 960px) {
    .header-links {
      justify-content: flex-end;
    }
    .header-link {
      display: flex;
    }
    .header-link_mobile-logo {
      display: none;
    }
  }
</style>
