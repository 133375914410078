import { render, staticRenderFns } from "./CompetitorsPanel.vue?vue&type=template&id=353c6de8&scoped=true&"
import script from "./CompetitorsPanel.vue?vue&type=script&lang=js&"
export * from "./CompetitorsPanel.vue?vue&type=script&lang=js&"
import style0 from "./CompetitorsPanel.vue?vue&type=style&index=0&id=353c6de8&lang=scss&scoped=true&"
import style1 from "./CompetitorsPanel.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "353c6de8",
  null
  
)

export default component.exports