import client from '../client';
import interceptors from '../interceptors';

const resource = 'whitelabel-domains';
const whitelabelDomainsInterceptor = interceptors.get('whitelabelDomains');

client.interceptors.response.use(
    whitelabelDomainsInterceptor.s,
    whitelabelDomainsInterceptor.e,
);

export default {
    fetchWhitelabelDomains: async () => await client.get(`${resource}`),
};
