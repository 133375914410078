const enReports = {
    'add-recipient': 'Add Recipient',
    'add-report': 'Add Scheduled Report',
    'create-new-report': 'Create New Scheduled Report',
    'create': 'Create',
    'delivery-date': 'Delivery Date',
    'edit-report': 'Edit Scheduled Report',
    'email-status': 'Email Status',
    'format': 'Format',
    'frequency': 'Frequency',
    'last-delivery': 'Last Delivery',
    'multiple-can-be-selected': 'Multiple Projects can be selected',
    'next-delivery': 'Next Delivery',
    'one-or-both-can-be-set': 'Frequency cannot be empty. Select one or both options',
    'pdf-settings': 'PDF Settings',
    'pdf-page-title': 'SEO Progress Report',
    'receive-pdf-or-csv': 'Receive PDF or CSV Reports',
    'recipient-email': 'Recipient Email',
    'recipient': 'Recipient',
    'remove-report': 'Remove Scheduled Report',
    'key-reset': 'API key reset successfully.',
    'key-reset-failed': 'API key was not reset.',
    'report-sent-out': 'Your report has been sent',
    'report-sent-error': 'Sending report failed. Please, try again later.',
    'reports-history': 'Reports History',
    'resend-failed': 'Resend failed',
    'scheduled-reports': 'Scheduled Email Reports',
    'schedules': 'Schedules',
    'select-a-project': 'Project cannot be empty',
    'send-report-every': 'Send report every',
    'send-report-msg': 'The report was resent.',
    'send-report': 'Send Report Now',
    'send-reports-on-dates': 'Send reports on these dates',
    'sent-to': 'Sent To',
    'where-send-report': 'Where Should We Send Report To?',
    'reports-sent-history': 'Reports Send History',
};

export default enReports;