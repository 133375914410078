<template>
    <div>
        <span
            v-if="isManual"
            class="manual-gmb"
            :data-important-max-width="108"
            :data-important-tooltip="$t('manual-gmb-msg')"
            >*</span
        >
        <span :data-important-tooltip="params.value">{{ params.value }}</span>
    </div>
</template>

<script>
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
export default {
    name: 'GeoRenderer',
    mixins: [ToolTipRenderMixin],
    computed: {
        isManual() {
            return (
                this.params.value &&
                this.params.colDef.field === 'gmb' &&
                this.params.data.notes?.manual_gmb
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.manual-gmb {
    cursor: pointer;
    color: var(--Blue-6, #0074d9);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
</style>
