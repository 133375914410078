<template>
    <div class="login-for-viewkey">
        <div 
            v-if="!isWhiteLabelDomain || (isWhiteLabelDomain && getCompanyLogo)"
            class="login-for-viewkey__logo"
        >
            <img
                v-if="!isWhiteLabelDomain"
                src="~img/logo-keyword.svg"
                alt="logo"
            >
            <img 
                v-else
                :src="getCompanyLogo"
                alt="logo"
            >
        </div>
        <div class="header">
            <p>{{ $t('password-required') }}</p>
        </div>
        <div class="body">
            <input
                v-model="password"
                type="text"
                class="text-input"
                @keyup.enter="enterPassword"
            >
        </div>
        <div class="footer">
            <kw-button
                :button-text="$t('continue')"
                button-type="primary"
                @click="enterPassword"
            />
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    export default {
        name: 'LoginForViewkey',
        props: {
            auth: {
                type: String,
                required: true,
            },
            viewkey: {
                type: String,
                required: true,
            },
            client: {
                type: Boolean,
                required: true,
            },
        },
        data () {
            return {
                password: ''
            }
        },
        computed: {
            ...mapGetters([
                'getViewkeyAccessStatus',
                'getOtherSetting',
                'getCurrentProject'
            ]),
            isWhiteLabelDomain () {
                return this.getOtherSetting('usewhitelabel') === 'show';
            },
            getCompanyLogo () {
                return this.getCurrentProject.company_logo;
            }
        },
        methods: {
            ...mapActions([
                'getViewkeyInfo',
            ]),
            async enterPassword () {
                await this.getViewkeyInfo({
                    shortkey: this.viewkey,
                    id: this.auth,
                    password: this.password
                });
                if (this.getViewkeyAccessStatus === 'denied') {
                    this.$toastr.e(this.$t('incorrect-password'));
                }
            },
            close () {
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss">
    @import '~sass/modal';
    .login-for-viewkey__logo {
        text-align: center;
        margin-bottom: 24px;
        img {
            max-width: 100%;
            max-height: 60px;
        }
    }
    .login-for-viewkey {
        padding: 24px;
        .header {
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            font-size: 17px;
            letter-spacing: 0.01em;
            margin-bottom: 8px;
            p {
                margin: 0;
                color: kw-color(kw-black, 1);
            }
        }
        .body {
            margin-bottom: 16px;
            input {
                width: 100%;
            }
        }
      .footer {
        button {
          width: 100%;
        }
      }
    }
</style>
