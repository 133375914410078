<template functional>
    <a
        class="share-header"
        :href="`${parent.getWebsiteUrl}`"
        data-cy="share-header"
        target="_blank"
    >
        {{ parent.$t('ranking-data-verified-by') }}
        <div class="share-header__logo" />

        <div class="share__learn-more">
            {{ parent.$t('learn-more') }}
            <span class="kw kw-chevron-right" />
        </div>
    </a>
</template>

<script>
export default {
    name: 'ShareHeader',
};
</script>

<style lang="scss" scoped>
.share-header {
    align-items: center;
    background-color: rgba(kw-color(kw-blue, 1), 0.1);
    color: kw-color(kw-black, 1);
    display: flex;
    flex: 1 1 100%;
    height: 33px;
    justify-content: center;
    margin: 0 -32px;
    text-decoration: none !important;
}

.share-header__logo {
    align-self: flex-start;
    background-image: url(~img/logo-full.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin: 6px 14px 0 14px;
    height: 20px;
    width: 104px;
}

.share__learn-more {
    color: kw-color(kw-blue, 1) !important;
    font-size: 13px;
    line-height: 15px;
    text-decoration: none !important;

    .kw-chevron-right {
        font-size: 9px;
        font-weight: bold;
    }
}

@media screen and (max-width: 959px) {
    .share-header {
        margin: 0;
    }
    .share-header__logo {
        background-image: url(~img/logo-k.svg);
        width: 15px;
    }
}
</style>
