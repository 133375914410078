import i18n from '@/i18n';

export const periods= [
    { label: i18n.t('today'), value: 1 },
    { label: i18n.t('last-week'), value: 7 },
    { label: i18n.t('last-month'), value: 30 },
    { label: i18n.t('last-3-months'), value: 91 },
    { label: i18n.t('last-6-months'), value: 184 },
    { label: i18n.t('last-year'), value: 365 },
];

