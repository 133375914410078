<template>
    <div
        class="confirm-manage-modal"
        data-cy="modal-dots-archive-confirm"
    >
        <div class="header">
            <span
                class="header__type"
                data-cy="action-confirm-title-type"
                v-text="actionName"
            />
            <span
                class="header__text"
                data-cy="action-confirm-text"
                @mouseover="getTooltipForText(`${actionName} ${headerText}`, $event)"
                v-text="headerText"
            />
            <div
                v-if="parentWithChildren"
                class="header__description"
                data-cy="action-confirm-description"
                v-text="descriptionText"
            />
            <closer
                closer-size="14"
                @click="close"
            />
        </div>
        <div
            v-if="projectsForManage.length > 1"
            class="body"
        >
            <ul>
                <li
                    v-for="project in projectsForManage"
                    :key="project.id"
                    :class="{ 'child': project.isSubproject }"
                    :data-cy="project.isSubproject? 'archive-group-child': 'archive-group-parent'"
                >
                    <span>{{ project.name }}</span>
                </li>
            </ul>
        </div>
        <div class="footer">
            <kw-button
                button-type="primary"
                :button-text="buttonText"
                :loading="requestRunning"
                data-cy="modal-confirm-button"
                @click="confirm"
            />
        </div>

        <global-events @keyup.enter="confirm" />
    </div>
</template>

<script>
    import {removeAllChecks} from '@/helpers/render-keywords-helpers';
    import DashboardMixin from '@/mixins/DashboardMixin';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    import {mapActions} from 'vuex';

    export default {
        name: 'ConfirmManageProjects',
        mixins: [ToolTipRenderMixin, DashboardMixin],
        props: {
            type: {
                type: String,
                required: true,
            },
            projectsForManage: {
                type: Array,
                required: true,
            },
            fromDashboard: {
                type: Boolean,
                required: true,
            },
            parentWithChildren: {
                type: Boolean,
                required: true,
            },
        },
        data () {
            return {
                requestRunning: false,
            };
        },
        computed: {
            actionName () {
                return this.type.charAt(0).toUpperCase() + this.type.slice(1);
            },
            headerText () {
                if (this.parentWithChildren) {
                    return this.$t('project-with-groups');
                }
                return this.projectsForManage.length > 1
                    ? this.$t('header-text-plural', {amount: `${this.projectsForManage.length}`})
                    : this.$t('header-single-project', {name:  `${this.projectsForManage[0].name}`, type: this.projectsForManage[0].isSubproject? this.$t('group'):  this.$t('project-upper')});
            },
            descriptionText () {
                return this.$t('project-with-groups-description', {type: this.type.slice(0, this.type.length - 1)});
            },
            buttonText () {
                return this.parentWithChildren
                    ? `${this.actionName} ${this.$t('project-and-groups')}`
                    : this.actionName;
            },
        },
        methods: {
            ...mapActions([
                'deleteProjects',
                'archiveProjects',
            ]),
            async confirm () {
                if (this.requestRunning) {
                    return;
                }

                this.requestRunning = true;

                const manageList = this.projectsForManage.length > 1
                    ? this.$store.getters.getCheckedProjects
                    : [this.projectsForManage[0].id];

                const requestParams = {
                    manageList, 
                    removedFromProjectDashboard: this.fromDashboard,
                    isSubproject: this.projectsForManage[0].isSubproject && this.projectsForManage.length === 1,
                }
                if(this.type === 'delete') {
                    await this.deleteProjects(requestParams);
                }else {
                    await this.archiveProjects(requestParams);
                }
                removeAllChecks('ProjectsOverview_V2');
                this.requestRunning = false;
                this.close();
            },
            close () {
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss">
  @import '~sass/modal';

  .confirm-manage-modal {
    padding: 10px;

    .header {
      display: flex;
      flex-wrap: wrap;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.01em;
      line-height: 24px;
      padding: 4px;

      .header__type {
        padding: 0;
      }

      .button-closer {
        position: absolute;
        right: 12px;
        top: 15px;
      }
    }

    span {
      padding: 0 4px;
    }

    .header__text {
      @import '~sass/cutting-name';
      width: 250px;
      display: block;
    }

    .header__description {
      color: kw-color(kw-grey, 1);
      font-size: 13px;
      font-weight: normal;
      line-height: 18px;
      margin-top: 6px;
    }

    .body {
      padding-right: 15px;
      max-height: calc(100vh - 220px);
      @extend .custom-scroll;
      overflow-y: auto;

      ul {
        padding-left: 30px;

        li {
          span {
            display: block;
            @import '~sass/cutting-name';
          }

          &.child {
            list-style-type: circle;
            margin-left: 16px;
          }
        }
      }
    }

    .footer {
      padding: 10px;
      display: flex;
      justify-content: flex-end;

      button {
        &.kw-button {
          &.kw-btn-primary {
            .button-loader {
              position: relative;
              background-color: transparent;
              border-color: transparent transparent transparent kw-color(kw-white, 1) !important;
              left: 0 !important;
              height: 18px !important;
              min-width: 18px !important;
              width: 18px !important;
            }
          }
        }
        &:last-child {
          background-color: kw-color(kw-red, 1) !important;
          line-height: 16px;
          border: none;
        }
      }
    }
  }
</style>
