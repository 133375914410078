<template>
    <div v-if="getPermissionsDataLoaded" class="competitors-panel" :class="{blocked: !getPermissionData('share_of_voice')}">
        <mindshare-update-message v-if="isSovUpdatingMessageVisible" />
        <div class="competitors-panel__header two-columns">
            <div id="tableTitle" class="title browser-view">
                <h4>{{ $t('share-of-voice-header') }}</h4>
                <span
                    class="kw kw-help-circle"
                    @mouseover="
                        tooltip({ text: $t('mindshare-help'), event: $event })
                    "
                />
                <div class="project">
                    <div class="project__title">
                        {{ $t('project-upper') }}:
                        {{ getCurrentProject ? getCurrentProject.name : '' }}
                    </div>
                    <div class="project__tags">
                        {{ $t('tags-uppercased') }}:
                        {{ showSelectedTag }}
                    </div>
                    <div class="project__keywords">
                        <div>{{ keywordsNumber }}</div>
                        {{ $t('keywords-first-upper') }}
                    </div>
                </div>
                <div class="project__btn">
                    <button-group v-model="mindshareView">
                        <radio selected-value="table">
                            <span class="kw kw-list" />
                        </radio>
                        <radio selected-value="chart">
                            <span class="kw kw-competitor" />
                        </radio>
                    </button-group>
                </div>
                <div :class="dropdownWrapperClasses">
                    <span
                        class="kw kw-help-circle"
                        @mouseover="
                            tooltip({
                                text: $t('line-chart-help'),
                                event: $event,
                            })
                        "
                    />
                    <span>{{ $t('time-frame-over') }}</span>
                    <date-ranges-dropdown type="trending" @onSelect="value=>onDateSelect(value, 'trending')" />
                </div>
            </div>
        </div>
        <div v-if="isOnlyMapYoutubeKeywords" class="top-competitors__info">
            <span>{{ $t('there-are-only-yt-maps-kw') }}</span>
        </div>
        <div
            v-else-if="isMindshareDataLimitExceed"
            class="top-competitors__info"
        >
            <span>{{ $t('competitors-keywords-limit-exceed') }}</span>
        </div>
        <div
            v-else
            class="top-competitors"
            :class="{
                'top-competitors__loading':
                    getMindshareHistoryDataLoading ||
                    getNewAddedKeywordsIsFetching,
            }"
        >
            <template v-if="isCompetitorsPanelVisible">
                <share-of-voice-chart
                    :mindshareView="mindshareView"
                    showChart
                    :mobileHide="false"
                    :chartStyles="chartStyles"
                />
            </template>
            <div v-else class="empty-top-competitors" />
        </div>
        <template v-if="this.$route.name !== 'shareOfVoice' && this.$route.name !== 'shareOfVoiceWithHash'">
            <div class="competitors-panel__header">
                <div class="title title_distribution browser-view">
                    <h4>{{ $t('ranking-vs-distribution-header') }}</h4>
                    <span
                        class="kw kw-help-circle"
                        @mouseover="
                            tooltip({
                                text: $t('timeframe-breakdown-chart'),
                                event: $event,
                            })
                        "
                    />
                    <button-group v-model="view">
                        <radio
                            v-for="option in viewsOptions"
                            :key="option.value"
                            :selected-value="option.value"
                            v-html="option.label"
                        />
                    </button-group>
                </div>
                <div class="dropdown-wrapper">
                    <span>{{ $t('time-frame-over') }}</span>
                    <date-ranges-dropdown type="ranking-distribution" @onSelect="value=>onDateSelect(value, 'ranking-distribution')"  />
                </div>
            </div>
            <div v-if="getViewkeyView" class="ranking-distributions-container">
                <ranking-distributions-viewkey :view="view" />
            </div>
            <div v-else class="ranking-distributions-container">
                <ranking-distributions :view="view" />
            </div>
        </template>

        <div class="blocker-message" v-if="!getPermissionData('share_of_voice')">
            <div class="header">{{ "Upgrade to refresh your Share of Voice data" }}</div>
            <div class="description">{{ "Discover your true competitors before they outrank you" }}</div>
            <div class="upgrade-button-wrapper">
                <kw-button
                    :button-text="$t('upgrade-now')"
                    button-type="primary"
                    class="upgrade-button"
                    @click="redirectToPackages"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Radio from 'vue-strap/src/Radio';
import buttonGroup from 'vue-strap/src/buttonGroup';

import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import { addPostfixToNumber } from '@/helpers/roundNumbers';

import { GTM_EVENTS } from '@/constants';
import { Events, EventBus } from '@/events';
import TopCompetitorsChart from './TopCompetitorsChart';
import RankingDistributionsViewkey from './RankingDistributionsViewkey';
import CustomTable from 'components/ui-elements/CustomTable';
import DateRangesDropdown from '../common/DateRangesDropdown.vue';
import MindshareUpdateMessage from './components/MindshareUpdateMessage';
import { viewsOptions } from './configs';
import ShareOfVoiceChart from './ShareOfVoiceChart.vue';
import RankingDistributions from './RankingDistributions.vue';

export default {
    name: 'CompetitorsPanel',
    components: {
        radio: Radio,
        'button-group': buttonGroup,
        'custom-table': CustomTable,
        'date-ranges-dropdown': DateRangesDropdown,
        'top-competitors-chart': TopCompetitorsChart,
        'ranking-distributions-viewkey': RankingDistributionsViewkey,
        'ranking-distributions': RankingDistributions,
        'mindshare-update-message': MindshareUpdateMessage,
        'share-of-voice-chart': ShareOfVoiceChart
    },
    mixins: [ToolTipRenderMixin],
    data() {
        return {
            viewsOptions,
            view: 'percentage',
            mindshareView: 'table',
            chartStyles: {
                height: 350,
                yOpposite: true,
            }
        };
    },
    computed: {
        ...mapGetters([
            'getUserData',
            'getActiveTag',
            'getViewkeyView',
            'getTotalKeywords',
            'getCurrentProject',
            'getPermissionData',
            'getListOfKeywordsTypes',
            'getMindshareLatestData',
            'getMindshareHistoryDates',
            'getPermissionsDataLoaded',
            'getMindshsareHistoryData',
            'getIsHaveNewAddedKeywords',
            'isMindshareDataLimitExceed',
            'getNewAddedKeywordsIsFetching',
            'getMindshareHistoryDataLoading',
            'getIsMindshareDataCalculationInProgress',
        ]),
        showSelectedTag() {
            if (this.getActiveTag.id) {
                return this.getActiveTag.name;
            } else {
                return '(none)';
            }
        },
        keywordsNumber() {
            return addPostfixToNumber(this.getTotalKeywords);
        },
        isOnlyMapYoutubeKeywords() {
            return (
                !this.getMindshareHistoryDataLoading &&
                this.getListOfKeywordsTypes.length &&
                !this.getListOfKeywordsTypes.filter(type =>
                    ['se', 'sem'].includes(type),
                ).length
            );
        },
        dropdownWrapperClasses() {
            return {
                'dropdown-wrapper': true,
                'mobile-hide': this.mindshareView !== 'chart',
            };
        },
        isSovUpdatingMessageVisible() {
            return (
                !this.getNewAddedKeywordsIsFetching &&
                this.getTotalKeywords > 0 &&
                (this.getIsMindshareDataCalculationInProgress ||
                    this.getIsHaveNewAddedKeywords)
            );
        },
        isCompetitorsPanelVisible() {
            return !(
                this.isSovUpdatingMessageVisible &&
                !this.getMindshareLatestData.length &&
                !this.getMindshareHistoryDates.length
            );
        },
    },
    watch: {
        $route(to) {
            if (to.name === 'keywordList') {
                // refetch top ten share of voice data
                if (!this.getActiveTag.id) {
                    this.fetchData();
                }
            }
        },
        getActiveTag() {
            this.fetchData();
        },
        getCurrentProject() {
            this.fetchData();
        },
    },
    mounted() {
        EventBus.emit(Events.OPEN_SOV_TAB);
        /**
         * GTM custom event d_share_of_voice_view
         */
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: GTM_EVENTS.CLICK_SHARE_OF_VOICE_TAB,
            uid: this.getUserData.userId,
        });
        this.fetchData();
    },
    beforeDestroy() {
        this.resetMindShareData();
    },
    methods: {
        ...mapActions([
            'fetchAverageRanks',
            'resetMindShareData',
            'fetchMindshareHistory',
            'setMindshareHistoryDaysRange',
            'setMindshareRankingDaysRange'
        ]),
        fetchData() {
            this.fetchAverageRanks();
            this.fetchMindshareHistory();
        },
        redirectToPackages() {
            this.$router.push({ name: 'Pricing' });
        },
        onDateSelect(value, type){
            if (type === 'trending') {
                this.setMindshareHistoryDaysRange(value);
                this.fetchMindshareHistory();
            }
            if (type === 'ranking-distribution') {
                this.setMindshareRankingDaysRange(value);
                this.fetchAverageRanks();
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/partials/button-group';

.competitors-panel {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 12px;

    &.blocked > *:not(.blocker-message) {
        opacity: 0.4;
    }

    .blocker-message {
        position: absolute;
        opacity: 1;
        width: 458px;
        height: 193px;
        padding: 24px 32px 32px;
        top: calc(50% - 96px);
        left: calc(50% - 229px);
        border-radius: 4px;
        background: #fff;
        box-shadow: 0px 0px 1px 0px rgba(50, 73, 98, 0.2),
            0px 18px 28px 0px rgba(50, 73, 98, 0.1);

        .header {
            color: kw-color(kw-black, 6);
            font-size: 20px;
            font-family: 'Roboto';
            font-weight: 700;
            line-height: 32px;
        }

        .description {
            margin-top: 17px;
            color: #24242C;
            font-size: 14px;
            font-family: Roboto;
            font-weight: 500;
            line-height: 16px;
        }

        .upgrade-button-wrapper {
            margin-top: 32px;

            .upgrade-button {
                width: 182px;
                height: 36px;

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
            }
        }
    }

    .dropdown-wrapper {
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-end;

        span {
            color: #919399;
            line-height: 36px;
            margin-right: 10px;
            font-weight: 500;
        }
    }
}

.top-competitors {
    display: flex;
    flex-flow: column nowrap;
    padding: 8px 0;

    @media (max-width: 500px) {
        padding: 14px 0;
    }

    &__loading {
        min-height: 400px;
    }

    .mobile-hide {
        @media (max-width: 959px) {
            display: none;
        }
    }
}

.empty-top-competitors {
    display: flex;
    flex: 1 1 auto;
    min-height: 400px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(~img/cactus.svg);
}

.project__btn {
    .btn-group {
        label {
            .kw {
                font-weight: 700;
            }
        }
        .btn.active {
            .kw {
                color: #0074d9;
            }
        }
    }
    .kw-list,
    .kw-competitor {
        display: block !important;
    }
}

.top-competitors__chart {
    display: flex;
    width: 100%;
}

.top-competitors__list {
    margin-bottom: 16px;
    position: relative;
    width: 100%;

    /deep/ .button-loader {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /deep/ span.icon-arrow_4 {
        font-size: 10px;
        line-height: 16px;
        width: 9px;
    }

    /deep/ span.icon-arrow_4.icon-rotate-down {
        color: kw-color(kw-red, 1);
    }

    /deep/ span.icon-arrow_4:not(.icon-rotate-down) {
        color: kw-color(kw-green, 1);
    }
}

.competitors-panel__header {
    align-items: center;
    display: flex;
    flex-flow: row wrap;

    &.two-columns {
        flex-flow: row wrap;
        justify-content: space-between;

        .btn-group {
            margin-left: auto;
            padding: 0;
        }

        @media (max-width: 959px) {
            .mobile-hide {
                display: none;
            }
            /deep/ .btn-group {
                margin-left: auto;
                padding: 0;

                label {
                    & > span {
                        display: none;
                    }
                }
            }
        }
    }

    &:not(.two-columns) {
        .dropdown-wrapper {
            span {
                white-space: nowrap;
            }
        }
    }

    .title_distribution {
        @media (max-width: 959px) {
            width: 100%;
        }
    }

    h4 {
        font-size: 17px;
        font-weight: 500;
    }

    span {
        color: #c4c4c4;
        font-size: 12px;
        font-weight: 500;
        margin-left: 4px;

        &.kw-help-circle {
            font-size: 14px;
            font-weight: 700;
            margin-right: 4px;
        }
    }

    .btn-group {
        @extend .button-group_outline;
        margin-left: 8px;

        .btn {
            font-size: 14px;
            line-height: 12px;
            width: 100%;
        }
    }
}

.browser-view {
    .project {
        display: none;
    }

    .kw-help-circle {
        display: block;
    }
}

.title {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;

    &:not(.browser-view) {
        .btn-group,
        .dropdown-wrapper {
            display: none;
        }
    }
}

.project {
    color: #919399;
    display: flex;
    flex: 1 1 100%;
    font-size: 12px;
    font-weight: 500;
    max-width: 400px;
}

.project__title,
.project__tags {
    align-self: center;
    margin-right: 8px;
}

.project__title {
    max-width: 160px;
}

.project__tags {
    max-width: 120px;
}

.project__keywords {
    align-items: center;
    display: flex;

    & > div {
        align-items: center;
        background-color: #919399;
        border-radius: 50%;
        color: kw-color(kw-white, 1);
        display: flex;
        font-size: 10px;
        height: 28px;
        justify-content: center;
        margin-right: 4px;
        padding: 4px;
        width: auto;
        min-width: 28px;
    }
}

.kw-help-circle {
    cursor: pointer;
    display: none;
    text-align: center;
}

@media (min-width: 960px) {
    .competitors-panel {
        padding: 0;
    }
    .top-competitors__list {
        margin-bottom: 0;
    }
    .competitors-panel__header {
        flex-flow: row nowrap;
    }
    .top-competitors {
        flex-flow: row nowrap;
    }
    .top-competitors__info {
        border: 1px solid #ccc;
        padding: 10%;
        margin: 20px 0;
        text-align: center;
        color: #808080;
        font-size: 15px;
    }

    .two-columns {
        .btn-group {
            display: none;
        }
    }
}
</style>

<style lang="scss">
.mouseover-mindshare {
    .title {
        font-weight: bold;
        display: block;
        margin-bottom: 7px;
    }
}
.competitors-panel{
    .top-competitors__chart {
        flex: 1;
        border: 1px solid kw-color(kw-light-grey, 1);;
        padding: 16px;
        background: kw-color(kw-white, 1);
        border-radius: 4px;
    }
    .top-competitors__list {
        flex: 1;
    }
} 
</style>
