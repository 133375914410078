const Profiler = {};

let timers = [];

Profiler.install = function (Vue) {

        Vue.startPoint = (startPointName) => {
            if (process.env.NODE_ENV !== 'development') {
                return;
            }
            performance.mark(startPointName);
        };

        Vue.getMaxDuration = (startPointName, endPointName, clearTime = 1000) => {
            return new Promise( (resolve) =>{
                if (process.env.NODE_ENV !== 'development') {
                    return resolve(null);
                }
                let measureName = 'measure-' + endPointName;
                let marks = performance.getEntries();
                let markNames = marks.map( mark => {
                    return mark.name;
                });
                if (markNames.indexOf(startPointName) === -1) {
                    return resolve(null);
                }
                performance.mark(endPointName);
                performance.measure(measureName, startPointName, endPointName);
                if (timers[measureName]) {
                    clearTimeout(timers[measureName]);
                }
                timers[measureName] = setTimeout( () => {
                    let measures = performance.getEntriesByName(measureName);
                    let maxTime = 0;
                    measures.forEach( measure => {
                        if (maxTime < measure.duration) {
                            maxTime =  measure.duration;
                        }
                    });
                    performance.clearMarks(endPointName);
                    performance.clearMarks(startPointName);
                    performance.clearMeasures(measureName);
                    resolve(maxTime);
                }, clearTime);
            });
        }

        Vue.reportDuradion = (startPointName, endPointName, clearTime = 1000) => {
            if (process.env.NODE_ENV !== 'development') {
                return;
            }
            Vue.getMaxDuration(startPointName, endPointName, clearTime).then( maxTime => {
                if (!maxTime) {
                    return;
                }
                const timeToInt = parseInt(maxTime);
                console.log(`Measure results: FROM ${startPointName} TO ${endPointName} TIME ${timeToInt} ms`);
            }).catch( error => {
                console.log('ERROR', error.message);
            });
        }
};

export default Profiler;