import i18n from '@/i18n';

const suggestionsFields = [
    { class: 'gsc-kw', name: 'kw', partOfTable: 50, title: i18n.t('keyword'), sorter: 'kw' },
    { class: 'gsc-impressions', name: 'impressions', partOfTable: 12, title: i18n.t('gsc-impressions'), sorter: 'impressions' },
    { class: 'gsc-position', name: 'position', partOfTable: 12, title: i18n.t('gsc-position'), sorter: 'position' },
    { class: 'gsc-clicks', name: 'clicks', partOfTable: 12, title: i18n.t('gsc-clicks'), sorter: 'clicks' },
    { class: 'gsc-ctr', name: 'ctr', partOfTable: 12, title: i18n.t('gsc-ctr'), sorter: 'ctr' },
];

export { suggestionsFields };