<template>
    <div
        class="confirm-export-modal"
        data-cy="confirm-export-modal"
    >
        <closer @click="close" />
        <div class="header">
            <h2>{{ $t('export-to-csv') }}</h2>
        </div>
        <div class="body">
            <div
                class="report__item"
                @click="confirmExport('withMetrics')"
            >
                <div class="report__item__block">
                    <div>
                        <span class="kw kw-download" />
                    </div>
                    <span class="csv-text">{{ $t('export-csv-project-list-width-metrics') }}</span>
                    <span class="csv-description">{{ $t('export-csv-project-list-width-metrics-desc') }}</span>
                </div>
            </div>
            <div
                class="report__item"
                @click="confirmExport('keywords')"
            >
                <div class="report__item__block">
                    <div>
                        <span class="kw kw-download" />
                    </div>
                    <span class="csv-text">{{ $t('export-csv-all-project-keywords') }}</span>
                    <span class="csv-description">{{ $t('export-csv-all-project-keywords-desc') }}</span>
                    <span v-if="maxExportCount" class="csv-limited-description">{{ $t('limited-to')}}</span>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { exportAllAsCSV, exportProjectsWithMetricsAsCSV } from '@/helpers/downloads-service';
    import i18n from '@/i18n';
    import toastr from '@/helpers/init-toastr';
    import {mapGetters} from 'vuex';

    export default {
        name: 'ConfirmExportCSV',
        computed: {
            ...mapGetters([
                'getUsedKeywords'
            ]),
            maxExportCount() {
                return process.env.MAX_EXPORT_ITEMS_COUNT;
            },
        },

        methods: {
            confirmExport (type) {
                switch (type) {
                    case 'keywords':
                        if (this.getUsedKeywords <= process.env.MAX_EXPORT_ITEMS_COUNT || process.env.MAX_EXPORT_ITEMS_COUNT === undefined) {
                            exportAllAsCSV();
                        } else  {
                            toastr.e(i18n.t('export-more-keywords-error'));
                        }
                        break;
                    case 'withMetrics':
                        exportProjectsWithMetricsAsCSV();
                }
                this.close();
            },
            close () {
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss">
@import '~sass/modal';

.confirm-export-modal {
    height: 100%;
    padding: 32px 40px 27px 40px;

    button {
        &.button-closer {
            position: absolute;
            top: 14px;
            right: 14px;

            .icon-cross-round {
                font-size: 16px !important;
                margin-right: 0;
            }
        }
    }

    .header {
        h2 {
            font-style: normal;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            margin: 0 0 16px 0;
        }
    }

    .body {
        margin: 0 0 24px 0;
        display: flex;

        .report__item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 214px;
            height: 130px;
            border: 1px solid #AFBAC7;
            border-radius: 4px;
            cursor: pointer;
            text-align: center;

            &:first-child {
                margin-right: 20px;
            }

            &__block {
                display: flex;
                flex-direction: column;
                position: relative;

                div {
                    text-align: center;
                    margin: 0 0 10px 0;

                    span {
                        &.kw-download {
                            font-size: 24px;
                            color: kw-color(kw-red, 1);
                        }
                    }
                }

                .csv-text {
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 500;
                    color: kw-color(kw-black, 7);
                }
                .csv-description {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 400;
                    color: #24242C;
                }
                .csv-limited-description {
                    font-size: 12px;
                    font-style: italic;
                    line-height: 16px;
                    font-weight: 400;
                    color: #24242C;
                }
            }
        }
    }
    @media screen and (max-width: 540px) {
        padding: 32px 15px 27px 15px;
        .body {
            flex-direction: column;
            .report__item {
                width: 100%;
                margin: 5px 0 !important;
            }
        }
    }
}
</style>
