<template>
    <div class="top-pages-table-placeholder">
        <placeholder-table :rows="10" :row="getRow" />
    </div>
</template>

<script>
import fields from './configs/fields';
import PlaceholderTable from '@/components/ui-elements/PlaceholderTable';
export default {
    name: 'TopPagesTablePlaceholder',
    components: { 'placeholder-table': PlaceholderTable },
    computed: {
        getRow() {
            return fields.map(field => field.width);
        },
    },
};
</script>
