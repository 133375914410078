<template functional>
    <div class="top-pages-table-header" data-cy="top-pages-table-header">
        <div
            v-for="field in props.fields"
            :key="field.name"
            :class="`top-pages-table-cell ${field.class}`"
            :style="{ width: field.width + 'px' }"
            @click="props.sortHandler(field.name)"
        >
            <span class="top-pages-table-header__name">
                {{ field.title }}{{
                    field.name == 'cpc' ||
                    field.name == 'average_cost_per_click'
                        ? `/${parent.getCurrentCurrency.code}`
                        : ''
                }}
            </span>
            <span
                v-show="props.sort.field === field.name"
                :class="`kw kw-sort ${props.sort.direction}`"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopPagesTableHeader',
    props: {
        fields: {
            type: Array,
            required: true,
        },
        sort: {
            type: Object,
            required: true,
        },
        sortHandler: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.top-pages-table-header {
    display: flex;
    flex-flow: row nowrap;
    min-width: fit-content;
}

.top-pages-table-cell {
    align-items: center;
    background-color: #f8f9fa;
    cursor: pointer;
    display: flex;
    flex: 1 0 auto;
    flex-flow: row nowrap;
    height: 36px;

    @media (max-width: 959px) {
        height: 32px;
    }
}

.top-pages-table-header__name {
    color: kw-color(kw-grey, 1);
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    white-space: nowrap;
    @media (max-width: 959px) {
        font-size: 9px;
        line-height: 12px;
    }
}

.kw-sort {
    color: kw-color(kw-grey, 1);
    font-size: 9px;
    line-height: 10px;
    margin-left: 4px;

    &.asc {
        transform: scaleY(-1);
    }

    @media (max-width: 959px) {
        font-size: 9px;
        line-height: 11px;
    }
}
</style>
