<template>
    <div v-if="show" :class="listLinkClasses" :style="linkStyles">
        <div class="list-link__value">
            {{ trend === 0 ? '' : trend > 0 ? `+${trend}` : trend }}
        </div>
    </div>
</template>

<script>
const HALF_ITEM_HEIGHT = 16;

export default {
    name: 'ListLink',
    props: {
        listClass: {
            type: String,
            required: true,
        },
        listItemHighlightedClass: {
            type: String,
            required: true,
        },
        highlighted: {
            type: String,
            required: true,
        },
        selectedLocal: {
            type: Boolean,
            required: true,
        },
        prevListIndex: {
            type: Number,
            required: true,
        },
        nextListIndex: {
            type: Number,
            required: true,
        },
        trend: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            prevList: null,
            nextList: null,
            prevItem: null,
            nextItem: null,
            linkTop: 0,
            linkBottom: 0,
            listHeight: 0,
            show: false,
        };
    },
    computed: {
        linkStyles() {
            return {
                top: `${this.linkTop}px`,
                bottom: `${this.linkBottom}px`,
            };
        },
        listLinkClasses() {
            return {
                'list-link': true,
                'list-link_top-limited': this.linkTop <= 0,
                'list-link_bottom-limited': this.linkBottom <= 0,
                'list-link_hide-trend':
                    this.linkTop >= this.listHeight && this.linkBottom <= 0,
                'list-link_positive': this.trend > 0,
                'list-link_negative': this.trend < 0,
                'list-link_inversed-limits':
                    this.prevItem &&
                    this.nextItem &&
                    this.prevItem.getBoundingClientRect().top <
                        this.nextItem.getBoundingClientRect().top,
            };
        },
    },
    watch: {
        highlighted() {
            this.$nextTick(() => this.initElements());
        },
        selectedLocal() {
            this.$nextTick(() => this.initElements());
        },
    },
    mounted() {
        this.initElements();
        this.prevList.addEventListener('scroll', this.calculateListLink);
        this.nextList.addEventListener('scroll', this.calculateListLink);
    },
    beforeDestroy() {
        this.prevList.removeEventListener('scroll', this.calculateListLink);
        this.nextList.removeEventListener('scroll', this.calculateListLink);
    },
    methods: {
        initElements() {
            this.prevList = document.getElementsByClassName(this.listClass)[
                this.prevListIndex
            ];
            this.nextList = document.getElementsByClassName(this.listClass)[
                this.nextListIndex
            ];
            this.prevItem = this.prevList.getElementsByClassName(
                this.listItemHighlightedClass,
            )[0];
            this.nextItem = this.nextList.getElementsByClassName(
                this.listItemHighlightedClass,
            )[0];
            this.listHeight = this.prevList.clientHeight;

            this.calculateListLink();
        },
        calculateListLink() {
            if (this.prevItem && this.nextItem) {
                const prevItemTop =
                    this.prevItem.getBoundingClientRect().top -
                    this.prevList.getBoundingClientRect().top;
                const nextItemTop =
                    this.nextItem.getBoundingClientRect().top -
                    this.nextList.getBoundingClientRect().top;

                const min = Math.min(prevItemTop, nextItemTop);
                const max = Math.max(prevItemTop, nextItemTop);

                this.linkTop = this.limitValues(min + HALF_ITEM_HEIGHT);
                this.linkBottom =
                    this.listHeight - this.limitValues(max + HALF_ITEM_HEIGHT);
                this.show = true;
            } else {
                this.show = false;
            }
        },
        limitValues(value) {
            if (value <= 0) return 0;
            if (value >= this.listHeight) return this.listHeight;
            return value;
        },
    },
};
</script>

<style lang="scss" scoped>
.list-link {
    background-color: kw-color(kw-blue, 1);
    margin-top: 28px;
    position: absolute;
    right: -5px;
    width: 1px;

    &:before {
        background-color: kw-color(kw-blue, 1);
        content: '';
        height: 1px;
        position: absolute;
        right: 1px;
        bottom: 0;
        width: 5px;
    }

    &:after {
        background-color: kw-color(kw-blue, 1);
        content: '';
        height: 1px;
        position: absolute;
        right: -5px;
        top: 0;
        width: 5px;
    }
}

.list-link_inversed-limits {
    &:before {
        right: -5px;
    }

    &:after {
        right: 1px;
    }
}

.list-link_top-limited {
    &:after {
        display: none;
    }
}

.list-link_bottom-limited {
    &:before {
        display: none;
    }
}

.list-link_positive {
    background-color: kw-color(kw-green, 1);

    &:before,
    &:after {
        background-color: kw-color(kw-green, 1);
    }

    .list-link__value {
        background-color: kw-color(kw-green, 1);
    }
}

.list-link_negative {
    background-color: kw-color(kw-red, 1);

    &:before,
    &:after {
        background-color: kw-color(kw-red, 1);
    }

    .list-link__value {
        background-color: kw-color(kw-red, 1);
    }
}

.list-link:not(.list-link_positive):not(.list-link_negative) {
    .list-link__value {
        height: 4px;
        width: 4px;
    }
}

.list-link__value {
    align-items: center;
    background-color: kw-color(kw-blue, 1);
    border-radius: 2px;
    color: kw-color(kw-white, 1);
    display: flex;
    font-size: 7px;
    height: 12px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    z-index: 1;
}

.list-link_hide-trend {
    .list-link__value {
        display: none;
    }
}
</style>
