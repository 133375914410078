<template>
    <div class="block-wrapper">
        <div class="header-wrapper">
            <chart-header
                :title="$t('available-chart-titles.visibility')"
                :toolTip="$t('visibility-tooltip')"
                showTimeframe
                isHidable
                @onHide="onHide"
                :date="date"
                @onDateSelect="onDateSelect"
                :isHideDisabled="isLoading"
                :defaultKey="interval"
            />
        </div>
        <div class="chart">
            <visibility-chart
                v-if="getCurrentProject"
                :key="`visibility_${chartKey}`"
                :project="getCurrentProject"
                :loading="getProjectVisibility.loading"
                :data="getProjectVisibility.data" 
                :timeframe="date"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChartBlockHeader from "./ChartBlockHeader.vue";
import VisibilityChart from "./VisibilityChart.vue";
import { projectMetricsDateDropdownRanges } from '@/components/metrics/configs'
import { datePickerList } from '@/components/date-range-picker/datePickerList'

export default {
    name: "VisibilityBlock",
    components: {
        "visibility-chart": VisibilityChart,
        "chart-header": ChartBlockHeader
    },
    data() {
        return {
            date: null,
            dateRanges: projectMetricsDateDropdownRanges,
            isLoading: false,
            interval: null,
            fetched: false
        }
    },
    computed: {
        ...mapGetters([
            "getCurrentProject",
            "getProjectVisibility",
            "getActiveTag",
            "getProjectOverviewOptionItem"
        ]),
        chartKey(){
            return this.getActiveTag?.id || this.getCurrentProject?.id;
        },
    },
    watch: {
        getActiveTag: function() {
            this.fetchData();
        },
    },
    mounted(){
        this.date = this.getProjectVisibility.timeframe;

        if(!this.fetched && this.getCurrentProject?.project_id){
            this.fetchData();
        }
    },
    methods: {
        ...mapActions([
            "setProjectOverviewOptionIsVisible",
            "fetchProjectVisibility",
            "updateProjectTimeFrame"
        ]),
        onHide() {
            this.isLoading = true;
            this.setProjectOverviewOptionIsVisible({ 
                projectId: this.getCurrentProject.id,
                option: "visibility",
                value: false 
            }).finally(()=>{
                this.isLoading = false;
            });
        },
        onDateSelect(date, key) {
            this.updateProjectTimeFrame({
                projectId: this.getCurrentProject.id,
                option: 'visibility',
                value: {
                    date_range: {
                        start: date[0],
                        end: date[1]
                    }
                },
                interval: {
                    interval: key
                }
            })
            this.fetchData(date, key);
        },
        fetchData(dateValue, key){
            this.fetched = true;
            const { interval } = this.getProjectOverviewOptionItem('visibility');

            let intervalDate = null;
            if(key && key !== this.interval){
                this.date = null;
                this.interval = key;
                intervalDate = datePickerList[key].value;
            } else {
                this.interval = interval;
                if(!dateValue){
                    if(!this.date && interval){
                        this.interval = interval;
                        intervalDate = datePickerList[interval].value;
                    } else {
                        this.date = null;
                    }
                } else {
                    this.date = dateValue;
                }
            }
            const [ startDate, endDate ] = intervalDate ? intervalDate : this.date ? this.date : [null, null];

            const tagId = this.$route.query?.tag;
            this.fetchProjectVisibility({ 
                startDate,
                endDate,
                projectId: this.getCurrentProject.project_id,
                tagId 
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.block-wrapper {
    width: 100%;
    /deep/ .chart {
        margin-top: 24px;
        margin-left: -10px;
        width: calc(100% + 10px);
        height: 369px;
    }
}
</style>
