export const  loadStripeScriptWithRetry = (url, retries = 2, delay = 5000) => {
    return new Promise((resolve, reject) => {
        const loadScript = (retryCount) => {
            const script = document.createElement('script');
            script.src = url;
            script.onload = () => {
                if (window.Stripe) {
                    resolve(window.Stripe);
                } else {
                    if (retryCount > 0) {
                        console.warn(`Retrying to load Stripe script, attempts left: ${retryCount}`);
                        setTimeout(() => loadScript(retryCount - 1), delay);
                    } else {
                        reject(new Error('Failed to load Stripe script after maximum retries.'));
                    }
                }
            };
            script.onerror = () => {
                if (retryCount > 0) {
                    console.warn(`Retrying to load Stripe script, attempts left: ${retryCount}`);
                    setTimeout(() => loadScript(retryCount - 1), delay);
                } else {
                    reject(new Error('Failed to load Stripe script after maximum retries.'));
                }
            };
            document.head.appendChild(script);
        };
        loadScript(retries);
    });
};
    
export const loadStripe = async () => {
    const stripeUrl = 'https://js.stripe.com/v3/';
    try {
        const Stripe = await loadStripeScriptWithRetry(stripeUrl);

        return Promise.resolve(Stripe);
    } catch (error) {
        return Promise.reject(error);
    }
};
