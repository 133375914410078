<template functional>
    <div class="table-cell">
        <div class="table-cell__icon table-cell__icon_flag">
            <flag
                :country="props.keyword.flag.image"
                :tooltip="props.keyword.flag.tooltip"
                :tooltip-callback="parent.tooltip"
            />
        </div>
        <div
            :class="$options.iconClasses(props)"
            @mouseover="
                parent.tooltip({
                    text: parent.$t(`${props.keyword.type}-ranking`),
                    event: $event,
                })
            "
        />
        <div
            v-if="props.keyword.near"
            class="kw kw-map-pin"
            data-cy="keyword-location-tag"
            @mouseover="
                parent.tooltip({
                    text: parent.$t('location', { near: props.keyword.near }),
                    event: $event,
                })
            "
        />
        <div
            class="table-cell__content-wrapper"
            data-cy="keyword-table-cell-name"
        >
            <div
                class="table-cell__content table-cell__content_cut table-cell__content_url"
                @mouseover="
                    parent.tooltip({ text: props.keyword.url, event: $event })
                "
            >
                <div
                    class="table-cell__text table-cell__text_url"
                    v-html="props.keyword.url"
                />
            </div>
            <div class="table-cell__content table-cell__content_cut">
                <div
                    class="table-cell__text"
                    @mouseover="
                        parent.tooltip({
                            text: props.keyword.fullKw,
                            event: $event,
                            allowHtml: false
                        })
                    "
                >{{ props.keyword.kw}}</div>
            </div>
        </div>
        <div class="table-cell__actions">
            <div class="actions-class__action-container">
                <kw-button
                    v-if="props.isViewKey != false"
                    :button-disabled="props.isSearch"
                    :append-icon-class="
                        props.keyword.isfav
                            ? 'kw kw-favorite'
                            : 'kw kw-iconsstar'
                    "
                    data-cy="favorite-iconsstar-in-table"
                    button-type="text"
                    @mouseover="
                        parent.tooltip({
                            text: props.keyword.isfav
                                ? parent.$t('unfav-kw')
                                : parent.$t('fav-kw'),
                            event: $event,
                        })
                    "
                    @click.stop="
                        props.toggleFavorite({
                            keywords: [props.keyword.id],
                            action: !props.keyword.isfav,
                        })
                    "
                />
            </div>
            <div class="actions-class__action-container">
                <kw-button
                    append-icon-class="kw kw-charts"
                    :button-active="
                        parent.$store.getters.getShownChart.keyword ===
                        props.keyword.auth
                    "
                    data-cy="show-historical-chart"
                    button-type="text"
                    :data-important-tooltip="parent.$t('view-charts')"
                    @click.stop="parent.toggleShowSingleChart(props.keyword)"
                />
            </div>
            <div class="actions-class__action-container">
                <a
                    :data-important-tooltip="parent.$t('see-in-search-results')"
                    :href="$options.spyglassLink(props, parent)"
                    target="_blank"
                    :test="props.keyword.grank"
                    @click.stop="parent.emitOpenSpyglass({project_id: props.keyword.project_id, keyword_id: props.keyword.id})"
                >
                    <span class="kw kw-search" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'KwField',
    spyglassLink: ({ keyword, dateFilter }, parent) => {
        const rank = parseInt(keyword.grank, 10);
        const page = rank && rank < 100 ? parseInt(keyword.gpage, 10) : 1;

        let params = `?project=${keyword.project_id}&keyword=${keyword.id}&auth=${keyword.auth}`;
        if (!dateFilter) {
            let now = new Date();
            let day = now.getDate();

            if (day < 10) {
                day = `0${day}`;
            }

            let month = now.getMonth() + 1;

            if (month < 10) {
                month = `0${month}`;
            }

            let year = now.getFullYear();

            dateFilter = `${year}-${month}-${day}`;
        }

        params += `&date=${dateFilter}`;
        params += !rank || isNaN(rank) ? '&unranked=true' : `&ranking=${rank}`;
        params += keyword.is_paginated == 1 ? `&page=${page}` : '';
        params += `&intercomHash=${parent.getUserData.intercomHash}`;
        params += `&intercomUserId=${parent.getUserData.intercomUserId}`;

        return `${window.location.origin}/spyglass.html${params}`;
    },
    iconClasses: function ({ keyword }) {
        const iconNames = {
            se: 'kw-airplay',
            sem: 'kw-smartphone',
            yt: 'kw-youtube',
            map: 'kw-image',
            location: 'kw-map-pin',
        };
        return {
            [`kw-icons kw ${iconNames[keyword.type]}`]: true,
        };
    },
};
</script>

<style lang="scss" scoped>
.table-cell__icon_flag {
    display: flex;
    margin-right: 4px;

    i {
        transform: scale(0.9) translateX(-0.5px) translateY(0.8px);
    }
}

.table-cell__content_url {
    display: none !important;
}

.table-cell__content-wrapper {
    flex-basis: 100%;
    flex-grow: 1;
}

.kw-airplay,
.kw-smartphone,
.kw-youtube,
.kw-image,
.kw-map-pin {
    margin-right: 3px;
    font-size: 12px;
}

@media (max-width: 959px) {
    .table-cell__text_url {
        display: block;
        font-size: 12px;
        color: #4080c6;
        line-height: 14px;
        margin-bottom: 2px;
    }
    .table-cell__content_cut {
        line-height: 14px;
    }
    .table-cell__content_url {
        display: block;
    }
}

.table-cell__actions {
    margin-left: 5px;
    display: none;
}

.actions-class__action-container {
    position: relative;

    &:not(:last-child) {
        margin-right: 5px;
    }

    /deep/ kw-btn-text,
    a,
    button {
        border: none;
        box-shadow: none;
        display: flex;
        height: 15px;
        padding: 0;
        margin-left: 0;

        span {
            color: kw-color(kw-blue, 1) !important;
            font-size: 13px;
            line-height: 13px;
            pointer-events: none;

            &:hover {
                color: #00a3ff !important;
            }

            &:focus {
                outline: transparent;
            }
        }
    }

    a:hover {
        text-decoration: none;
    }
}

.kw-search {
    margin-top: 1px;
    font-weight: 700;
}
</style>
