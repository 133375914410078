<template>
    <div class="tree-navigation">
        <div id="virtual-projects">
            <div class="tree-navigation-link">
                <div class="project-and-tags-wrap project_active">
                    <div class="row project_empty">
                        <div>
                            <span class="kw kw-chevron-up" />
                        </div>
                        <div class="project__name-wrapper"></div>
                        <span class="project_add_tag_icon_block">
                            <span class="project-add-tag-icon kw kw-plus" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// TODO rewrite component
export default {
    name: 'EmptyProject',
};
</script>

<style lang="scss" scoped>
.tree-navigation {
    width: 100%;
    height: 100%;
}

#virtual-projects {
    overflow: auto !important;
    @extend .custom-scroll;
    margin-right: 3px;
    height: calc(100vh - 217px);

    &.with_top_banner {
        height: calc(100vh - 275px);
    }
}

.tree-navigation-link {
    margin-right: 8px;
    text-decoration: none;
}

.project-and-tags-wrap {
    &.project_active {
        background: #e8edf2;
        border-radius: 5px 0 0 5px;
    }
}

.row {
    align-items: center;
    display: flex;
    margin: 0;
}

.project_empty {
    padding: 0;
    position: relative;
    height: 33px;
    transition: all 0.5s;
    display: flex;
    align-items: center;
}

.project__name-wrapper {
    align-items: center;
    display: flex;
    flex: 1;
}

.kw-chevron-up {
    color: kw-color(kw-blue, 1);
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    padding-left: 12px;
    transform: scaleY(-1);

    &.is-open {
        transform: scaleY(1);
    }
}

.project_add_tag_icon_block {
    width: 36px !important;
    height: 100%;
    cursor: pointer;
    transition: opacity 0.5s;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1 !important;
}

.project-add-tag-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7px;
    font-weight: 700;
    width: 16px !important;
    height: 16px;
    background-color: kw-color(kw-blue, 1);
    color: kw-color(kw-white, 1);
    border-radius: 2px;
}
</style>
