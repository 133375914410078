<template>
    <edit-project-tag
        :get-current-project="getCurrentProject"
        :toggle-project-edit-tag="toggleProjectEditTag"
        :edit-project-tag="editProjectTag"
        :get-active-tag="getActiveTag"
    />
</template>
<script>
    import {mapGetters, mapActions} from 'vuex';
    import EditProjectTag from './components/EditProjectTag';
    export default {
        name: 'EditProjectTagScreen',
        components: {
            'edit-project-tag': EditProjectTag
        },
        computed: {
            ...mapGetters([
                'getCurrentProject',
                'getActiveTag',
            ]),
            
        },
        methods: {
            ...mapActions([
                'toggleProjectEditTag',
                'editProjectTag',
            ]),
        }
        
    }
</script>
