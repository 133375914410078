<template>
    <div class="card card-default">
        <empty-project v-if="getTutorialMode && !loaded" />
        <div
            v-else-if="!loaded"
            id="categories-loading"
            class="placeholder placeholder_projects"
        >
            <div
                v-for="i in 4"
                :key="i"
                class="placeholder__project"
            >
                <div
                    v-for="j in 3"
                    :key="j"
                    class="placeholder_animate"
                />
            </div>
        </div>
        <div
            v-else-if="showNotFound"
            class="not-found"
        >
            {{ $t('not-found') }}
        </div>
        <div
            v-else
            class="card-body"
        >
            <component
                :is="component"
                :projects="projects"
            />
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import EmptyProject from './EmptyProject';
    import TreeNavigation from 'components/left-sidebar/components/TreeNavigation';
    import TreeNavigationArchived from 'components/left-sidebar/components/TreeNavigationArchived';
    export default {
        name: 'Projects',
        components: {
            'empty-project': EmptyProject,
            'tree-navigation': TreeNavigation,
            'tree-navigation-archived': TreeNavigationArchived,
        },
        props: {
            projects: {
                type: Array,
                required: true,
            },
            loaded: {
                type: Boolean,
                required: true,
            },
            component: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapGetters([
                'getUserSetting',
                'getTutorialMode',
                'getUserSettings',
            ]),
            showNotFound () {
                return this.loaded
                    && this.projects
                    && !this.projects.length
                    && this.getUserSetting('projectsSearch');
            },
        },
    }
</script>

<style lang="scss">
    .card.card-default {
        .loading {
            padding-left: 25px;
        }
    }
    .card-body {
        height: 100%;
        width: 100%;
    }
    .card.card-default-archived {
        padding-top: 7px;
    }
</style>
