<template>
    <div class="viewkey-preview">
        <share-header v-if="hideVerified === 'hide'" />
        <div class="viewkey-preview__data">
            <share-data :project="shareData" />
        </div>
        <div class="viewkey-preview__top-bar">
            <div class="viewkey-preview__refresh fake-refresh">
                <div class="fake-refresh__group-name">
                    {{ $t('group-name') }}
                </div>
                <div class="fake-refresh__last-update">
                    {{ $t('last-refreshed') }}
                </div>
            </div>
            <div class="viewkey-preview__dropdowns" />
        </div>
        <div class="placeholder placeholder_main-block">
            <div class="placeholder__item placeholder__item_metrics">
                <div v-for="i in 7" :key="i" class="placeholder__metric" />
            </div>
            <div class="placeholder__item placeholder__item_keywords">
                <div v-for="i in 10" :key="i" class="placeholder__keywords">
                    <div v-for="j in 13" :key="j" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShareHeader from 'components/share-viewkey/ShareHeader';
import ShareData from 'components/share-viewkey/ShareData';
export default {
    name: 'ViewkeyPreview',
    components: {
        'share-header': ShareHeader,
        'share-data': ShareData,
    },
    props: {
        companyName: {
            type: String,
            required: true,
        },
        companyUrl: {
            type: String,
            required: true,
        },
        companyLogo: {
            type: String,
            required: true,
        },
        companyDesc: {
            type: String,
            required: true,
        },
        hideVerified: {
            type: String,
            required: true,
        },
    },
    computed: {
        getWebsiteUrl() {
            return process.env.KEYWORD_WEBSITE_URL;
        },
        shareData() {
            return {
                company_name: this.sharedDataEmpty
                    ? this.$t('company-name')
                    : this.companyName,
                company_link: this.companyUrl,
                company_logo: this.sharedDataEmpty
                    ? 'http://via.placeholder.com/150x35'
                    : this.companyLogo,
                company_desc: this.sharedDataEmpty
                    ? this.$t('company-desc-placeholder-preview')
                    : this.companyDesc,
            };
        },
        sharedDataEmpty() {
            return (
                !this.companyName &&
                !this.companyUrl &&
                !this.companyLogo &&
                !this.companyDesc
            );
        },
    },
};
</script>

<style lang="scss">
.display-settings-project-modal {
    .viewkey-preview {
        border: 1px solid #dee5ec;
        border-radius: 3px;
        display: flex;
        flex-flow: row wrap;
        margin: 0 auto 21px;
        padding: 0 24px;
        position: relative;
        width: 805px;

        .share-header {
            margin: 0 -24px;
        }

        .placeholder_main-block {
            margin-top: 0;
            min-width: unset;
            width: 100%;
        }

        .placeholder__item_metrics {
            padding-bottom: 16px;
            padding-top: 5px;
        }

        .placeholder__metric {
            height: 84px;
            margin-right: 4px;

            &:last-child {
                margin-right: 0;
                max-width: 18px;
            }
        }

        .placeholder__keywords:last-child {
            border-bottom: none;
        }
    }

    .viewkey-preview__data {
        display: flex;
        flex-flow: column nowrap;
        margin: 0 auto;
        max-width: 1080px;
        padding: 10px 0;
        width: 100%;
    }

    .viewkey-preview__top-bar {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .fake-refresh__group-name {
        color: kw-color(kw-black, 1);
        font-size: 12px;
        font-weight: bold;
        line-height: 12px;
        padding-left: 17px;
        position: relative;

        &:before {
            background-color: #e6e6e6;
            border-radius: 50%;
            content: '';
            height: 12px;
            left: 0;
            position: absolute;
            width: 13px;
        }
    }

    .fake-refresh__last-update {
        color: #7b7e86;
        font-size: 12px;
        line-height: 14px;
        margin-top: 6px;
    }

    .viewkey-preview__dropdowns {
        align-self: flex-end;
        background-color: #f5f6f7;
        border-radius: 4px;
        height: 20px;
        width: 58px;
    }

    @media (max-width: 1279px) {
        .display-settings__viewkey-view {
            display: none;
        }
        .viewkey-preview {
            display: none;
        }
    }
}
</style>
