import { render, staticRenderFns } from "./ShareHeader.vue?vue&type=template&id=fb78fb62&scoped=true&functional=true&"
import script from "./ShareHeader.vue?vue&type=script&lang=js&"
export * from "./ShareHeader.vue?vue&type=script&lang=js&"
import style0 from "./ShareHeader.vue?vue&type=style&index=0&id=fb78fb62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "fb78fb62",
  null
  
)

export default component.exports