import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'getCurrentPlan',
            'getPermissionData',
            'getProjectAndSubprojectsKeywordsCount',
            'getSelectedAuth',
            'getAllWithoutIdsData',
            'getTotalKeywords',
            'getOtherSetting',
            'getCheckedProjects'
        ]),
        refreshTooltip() {
            const projectsCount = this.getCheckedProjects.length;
            const keywords = this.getSelectedAuth.length
                ? this.getSelectedAuth.length
                : this.getTotalKeywords - this.getAllWithoutIdsData.length;

            if (!this.getPermissionData('on_demand_updates')) {
                return this.$t('refresh-is-disabled');
            } else if (this.type === 'overview') {
                if (projectsCount) {
                    return projectsCount === 1 ? this.$t('refresh-all-keywords-in-selected-projects-single', { count: projectsCount }) :
                        this.$t('refresh-all-keywords-in-selected-projects-multiple', { count: projectsCount })
                } else {
                    return this.$t('refresh-all-keywords-in-all-projects')
                }
            } else {
                return this.$t('keywords-refreshed', { count: keywords, item: this.$tc('dynamic-keyword', keywords) });
            }
        },
    },
    methods: {
        ...mapActions([
            'refreshKeywords',
        ]),
        async handleRefreshKeywords() {
            if (!this.getPermissionData('on_demand_updates')) {
                this.$toastr.w(this.$t('refresh-is-disabled-warning'));
                return;
            } else if (this.getCurrentPlan.expiredStatus === 'expired') {
                this.$toastr.w(this.$t('please-renew-subscription'));
                return;
            }
            await this.refreshKeywords({ overview: this.type === 'overview' });
        },
    },
};
