import apiFactory from '@/api';
import * as Sentry from '@sentry/browser';

const eventTrackingApi = apiFactory.get('eventTracking');

const eventTrackingStates = {
    state: {},
    mutations: {},
    getters: {},
    actions: {
        async trackMixpanelEvent(context, data) {
            try {
                await eventTrackingApi.trackMixpanelEvent(data);
            } catch (error) {
                Sentry.captureException(error);
            }
        },
    },
};

export default eventTrackingStates;
