<template>
    <div
        id="customTable"
        ref="customTable"
        :class="customTableClasses"
    >
        <div class="custom-table__section custom-table__section_head">
            <div class="custom-table__row">
                <div
                    v-for="col in config"
                    :key="col.key"
                    :class="customTableCellClasses(col)"
                    :style="col.width ? `width: ${col.width}` : 'flex-grow: 1'"
                >
                    <hyphen
                        v-if="col.key === 'checkbox'"
                        :hyphen="isAllSelected"
                        @hyphen-click="selectAllHandler"
                    />
                    <template v-else>
                        {{ col.title }}
                    </template>
                </div>
                <div
                    v-if="downloadable || copyableCalculated"
                    class="custom-table__head-buttons controls"
                    data-html2canvas-ignore
                >
                    <kw-button
                        v-if="downloadable"
                        class="controls__button"
                        button-type="text"
                        prepend-icon-class="kw kw-download"
                        @click="download"
                    >
                        <div
                            v-if="downloadLoading"
                            slot="contentSlot"
                        >
                            <span>
                                <loader
                                    :size="12"
                                />
                            </span>
                        </div>
                    </kw-button>
                    <kw-button
                        v-if="copyableCalculated"
                        class="controls__button"
                        button-type="text"
                        prepend-icon-class="kw kw-copy"
                        @click="copy"
                    >
                        <div
                            v-if="copyLoading"
                            slot="contentSlot"
                        >
                            <span>
                                <loader
                                    :size="12"
                                />
                            </span>
                        </div>
                    </kw-button>
                </div>
            </div>
        </div>
        <div :class="`custom-table__section custom-table__section_body ${withBorder ? 'with-border' : ''}`">
            <div
                v-for="item in items"
                :key="item.rank"
                :class="getTableRowClasses(item)"
            >
                <div
                    v-for="col in config"
                    :key="col.key"
                    :class="customTableCellClasses(col)"
                    :style="customTableCellStyles(col)"
                >
                    <template v-if="col.link">
                        <a
                            :href="sanitizeUrl(item[col.key])"
                            target="_blank"
                            @mouseover="handleMouseOver(item, col, $event)"
                        >
                            {{ item[col.key] }}
                        </a>
                    </template>
                    <checkbox
                        v-else-if="col.key === 'checkbox'"
                        :checkbox="isSelected(item)"
                        :color="item.color"
                        @checkbox-click="() => selectHandler(item)"
                    />
                    <span
                        v-else-if="col.type === 'html'"
                        class="custom-table__cell-inner"
                        v-html="item[col.key]"
                    />

                    <div
                        v-else-if="col.type === 'chart' && showChart"
                        class="custom-table__chart"
                    >
                        <span :style="{width: `${item[col.key]}`, background: item.color}" class="chart-line" />
                        <span class="chart-value">{{ item[col.key] }}</span>
                    </div>
                    
                    <div
                        v-else-if="col.type === 'multi_chart' && showChart"
                        class="custom-table__chart multi"
                        @mouseover="
                            tooltip({ text: item[col.key].tooltip, event: $event, followCursor: true, type: 'right-end', arrow: false })
                        "
                    >
                        <span v-for="data in item[col.key].data" :style="{width: `${data.value}`, background: data.color}" class="chart-line" />
                    </div>
                   
                    <template v-else>{{ item[col.key] }}</template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';

    export default {
        name: 'CustomTable',
        props: {
            config: {
                type: Array,
                required: true,
            },
            items: {
                type: Array,
                required: false,
                default: () => [],
            },
            selectHandler: {
                type: Function,
                required: false,
                default: () => {},
            },
            selectAllHandler: {
                type: Function,
                required: false,
                default: () => {},
            },
            isSelected: {
                type: Function,
                required: false,
                default: () => {},
            },
            isAllSelected: {
                type: Boolean,
                required: false,
                default: false,
            },
            copyable: {
                type: Boolean,
                required: false,
                default: true,
            },
            copyCallback: {
                type: Function,
                required: false,
                default: () => {},
            },
            downloadable: {
                type: Boolean,
                required: false,
                default: true,
            },
            downloadCallback: {
                type: Function,
                required: false,
                default: () => {},
            },
            showChart: {
                type: Boolean,
                default: false
            },
            withBorder: {
                type: Boolean,
                default: false
            }
        },
        mixins: [ToolTipRenderMixin],
        data () {
            return {
                copyLoading: false,
                downloadLoading: false,
            };
        },
        computed: {
            customTableClasses () {
                return {
                    'custom-table': true,
                    'custom-table_empty': !this.items.length,
                };
            },
            copyableCalculated () {
                return this.copyable
                    && typeof navigator.clipboard !== 'undefined'
                    && typeof navigator.clipboard.write !== 'undefined'
                    && typeof ClipboardItem !== 'undefined';
            },
        },
        methods: {
            customTableCellClasses ({key, unionToColumn}) {
                return {
                    'custom-table__cell': true,
                    'custom-table__cell_checkbox': key === 'checkbox',
                    'custom-table__cell_union-left': unionToColumn === 'left',
                    'custom-table__cell_union-right': unionToColumn === 'right',
                };
            },
            customTableCellStyles ({width, align}) {
                return {
                    width: width ? `${width}` : 'flex-grow: 1',
                    justifyContent: align || 'flex-start',
                };
            },
            sanitizeUrl(url) {
                if (!url.match(/^[a-zA-Z]+:\/\//)){
                    return 'http://' + url;
                }
                return url;
            },
            async download () {
                this.downloadLoading = true;

                setTimeout(async () => {
                    await this.downloadCallback(this.$refs.customTable);
                    this.downloadLoading = false;
                }, 100);
            },
            async copy () {
                this.copyLoading = true;

                setTimeout(async () => {
                    await this.copyCallback(this.$refs.customTable);
                    this.copyLoading = false;
                }, 100);
            },
            getTableRowClasses (item) {
                return {
                    'custom-table__row': true,
                    'highlighted-row': item.domain_exist_in_project
                }
            },
            handleMouseOver(item, col, event) {
                if (!col.hasTooltip) {
                    return
                }
                this.tooltip({ text: this.sanitizeUrl(item.url), event });
            }
        },
    }
</script>

<style lang="scss" scoped>
  .custom-table {
    background-color: kw-color(kw-white, 1);
    display: flex;
    flex-flow: column nowrap;
    min-height: 300px;
  }

  .custom-table_empty {
    background-image: url(~img/cactus.svg);
    background-repeat: no-repeat;
    background-position: center center;
  }

  .table__section {
    display: flex;
    flex-flow: column nowrap;
  }

  .custom-table__section_head {
    background-color: #F8F9FA;
    border: 1px solid #E1E8EF;
    border-radius: 4px 4px 0 0;

    .custom-table__row {
      height: 35px;

      .custom-table__head-buttons {
        .controls__button {
          align-items: center;
          background-color: kw-color(kw-white, 1);
          border-radius: 4px;
          border: 1px solid kw-color(kw-light-grey, 1);
          @include box-shadow-1;
          color: kw-color(kw-grey, 4);
          display: flex;
          height: 24px;
          justify-content: center;
          margin: 0;
          padding: 0;
          position: relative;
          width: 24px;

          /deep/.kw {
            font-size: 15px;
            font-weight: 700;
          }

          &:last-child {
            margin-left: 4px;
          }

          /deep/ .button-loader {
            left: unset !important;
            margin: 0;
            position: relative !important;
            top: unset !important;
            transform: translate(0px, 0px);
          }
        }
      }
    }

    .custom-table__cell {
      color: kw-color(kw-grey, 1);
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;

      &:nth-child(5) {
        border-right: none;
      }
    }
  }

  .custom-table__section_body {
    border-left: 1px solid transparent;

    .custom-table__row {
      height: 34px;

      &:last-child {
        border-bottom: none;
      }

      &:nth-child(even) {
        background-color: #F7F8FA;
      }

      &.highlighted-row {
        background-color: rgba(0, 116, 217, 0.15);

        .custom-table__cell {
          border-color: transparent;
        }
      }
    }
    &.with-border {
        border-right: 1px solid kw-color(kw-light-grey, 1);
        border-left: 1px solid kw-color(kw-light-grey, 1);
        border-bottom: 1px solid kw-color(kw-light-grey, 1);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: auto;
    }
  }

  .custom-table__row {
    display: flex;
    position: relative;
  }

  .custom-table__chart {
    width: 100%;
    display: flex;
    align-items: center;

    .chart-line {
        height: 16px;
        border-radius: 4px;
        margin-right: 4px;
    }

    .chart-value{
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
    }

    &.multi{
        position: relative;
        height: 14px;

        .chart-line {
            position: absolute;
            max-width: 100%;
        }
    }
  }

  .custom-table__cell {
    align-items: center;
    border-right: 1px solid #E1E8EF;
    display: flex;
    padding: 0 8px;
    overflow: hidden;

    &:last-child {
      border-right: none;
    }

    a {
      @import '~sass/cutting-name';
      color: #4080C6;
    }
  }

  .custom-table__cell_checkbox {
    justify-content: center;
    padding: 0;
    position: relative;

    /deep/ .checkbox {
      position: relative;
      top: 0;
    }
  }

  .custom-table__cell_union-right {
    border-right: none;
    padding-right: 0;
  }

  .custom-table__cell_union-left {
    border-left: none;
    padding-left: 6px;
  }

  .custom-table__head-buttons {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 500px) {
        top: -40%;
    }
  }

  .controls {
    display: flex;
  }

  .custom-table__cell-inner {
    align-items: center;
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    justify-content: flex-end;
    @media screen and (max-width: 500px) {
        justify-content: end;
        gap: 4px;  
    }
    /deep/ .icon-arrow_4 {
        margin-right: auto;
    }
  }
</style>
