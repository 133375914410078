<template>
    <project-keywords-and-charts v-if="!overviewTabIsDisplayed" />
    <tabs v-else v-model="activeTab" :class="{
        'tab__share-of-voice':
            getIsMindshareDataCalculationInProgress &&
            activeTab === activeTabs.competitions,
        'tab__keyword-suggestions': activeTab === activeTabs.suggestions
    }">
        <tab :header="getTabHeader($t('keywords-first-upper'), 'keywords-tab')">
            <project-keywords-and-charts v-if="activeTab == activeTabs.keywords" />
        </tab>

        <tab :header="getTabHeader($t('top-pages.title'), 'top-pages-tab')">
            <top-pages-tab v-if="activeTab == activeTabs.topPages" />
        </tab>

        <tab :header="getTabHeader($t('share-of-voice'), 'share-of-voice-tab')">
            <competitors-panel v-if="getCurrentProject && activeTab === activeTabs.competitions" />
        </tab>
    </tabs>
</template>

<script>
import { mapGetters } from "vuex";
import { overviewChartOptions } from "@/configs/overviewCharts";
import { tabs, tab } from "vue-strap";
import ChartAndMetrics from "components/common/ChartAndMetrics";
import CompetitorsPanel from "components/competitors-panel/CompetitorsPanel";
import KeywordSuggestions from "components/keyword-suggestions/KeywordSuggestions";
import TopPagesTab from "./TopPages/Tab";
import Metrics from "components/metrics/Metrics";
import ProjectKeywordsAndCharts from "./ProjectKeywordsAndCharts";
import {
    PROJECT_ACTIVE_TABS
} from "@/constants";
export default {
    name: "Project",
    components: {
        "chart-and-metrics": ChartAndMetrics,
        "competitors-panel": CompetitorsPanel,
        "keyword-suggestions": KeywordSuggestions,
        "top-pages-tab": TopPagesTab,
        metrics: Metrics,
        "project-keywords-and-charts": ProjectKeywordsAndCharts,
        tab: tab,
        tabs: tabs
    },
    props: {
        overviewTabIsDisplayed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            overviewChartOptions,
            editKeywordsPopup: {
                isVisible: false,
                editableKeyword: null,
                multiple: false
            },
            activeTab: 0,
            activeTabs: PROJECT_ACTIVE_TABS,
        };
    },
    computed: {
        ...mapGetters([
            "getActiveTag",
            "getCurrentProject",
            "getIsMindshareDataCalculationInProgress"
        ]),
    },
    watch: {
        activeTab(tab) {
            if (
                tab === this.activeTabs.keywords ||
                tab === this.activeTabs.topPages
            ) {
                const staticTableHeader = document.querySelector(
                    ".keywords-table_static .keywords-table__header"
                );
                const dynamicTableHeader = document.querySelector(
                    ".keywords-table_dynamic .keywords-table__header"
                );
                if (staticTableHeader && dynamicTableHeader) {
                    dynamicTableHeader.classList.remove("keywords-table__header_fixed");
                    staticTableHeader.classList.remove("keywords-table__header_fixed");
                    staticTableHeader.style.width = "unset";
                    dynamicTableHeader.style.width = "unset";
                }
            }
        },
        getActiveTag() {
            this.setShownChart({ keyword: null, project: null });
        }
    },
    methods: {
        getTabHeader(tabTile, cyAttr) {
            return (
                `<span data-cy="${cyAttr}">${tabTile}</span>` +
                (cyAttr === 'keyword-suggestions-tab'
                    ? '<span data-important-tooltip="' +
                    this.$t('i-keyword-suggestions') +
                    '" class="kw kw-information kw-information-suggestions"></span>'
                    : '')
            );
        },
    }
}
</script>
