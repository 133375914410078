<template>
    <div
        id="remove-dropdown"
        class="other-actions"
    >
        <dropdown
            ref="otherActionsDropdown"
            :x="-224"
            :y="4"
        >
            <template slot="btn">
                <kw-button
                    button-type="secondary"
                    :button-active="$refs.otherActionsDropdown && !$refs.otherActionsDropdown.isHidden"
                    append-icon-class="kw kw-more-vertical"
                    class="other-actions__button"
                    data-cy="dashboard-dots-button"
                />
            </template>
            <template slot="body">
                <ul
                    class="v-context"
                    data-cy="dashboard-other-menu"
                >
                    <li
                        v-for="(item, i) in config"
                        :id="item.type"
                        :key="i"
                    >
                        <template v-if="!item.hide">
                            <div
                                v-if="item.type === 'subtitle'"
                                class="v-context__subtitle"
                                v-text="item.text"
                            />
                            <hr
                                v-else-if="item.type === 'divider'"
                                class="v-context__divider"
                            >
                            <a
                                v-else
                                :data-cy="item.dataCy"
                                @click.stop="itemClick(item.type)"
                            >
                                <span :class="item.icon" />
                                {{ item.text }}
                            </a>
                        </template>
                    </li>
                    <li v-if="isNoteVisible">
                        <hr
                            class="v-context__divider"
                        >
                        <div class="v-context__subtitle">
                            {{ $t('notes') }}
                        </div>
                        <project-notes />
                    </li>
                </ul>
            </template>
        </dropdown>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { beforeModalOpen, beforeModalClose } from '@/helpers/modalHelpers';
    import ProjectsActionsMixin from '@/mixins/ProjectsActionsMixin';
    import ProjectNotes from 'components/common/ProjectNotes';
    import SettingsModal from 'components/modals/SettingsModal';
    import ConfirmProjectTagDeleteModal from 'components/modals/ConfirmProjectTagDeleteModal';

    export default {
        name: 'OtherActions',
        components: {
            'project-notes': ProjectNotes,
        },
        mixins: [ProjectsActionsMixin],
        props: {
            config: {
                type: Array,
                required: true,
            },
            showNotes: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        computed: {
            ...mapGetters([
                'getCheckedProjects',
                'getCurrentProject',
                'isViewOnlyUser',
                'getSelectedTag',
                'getProjectNotes',
            ]),
            isNoteVisible () {
                const currentProject = this.getCurrentProject;
                const dataToReturn =
                    this.showNotes
                    && !this.isViewOnlyUser && currentProject
                    && (this.getProjectNotes[currentProject.id] && this.getProjectNotes[currentProject.id].length);
                return dataToReturn;
            }
        },
        methods: {
            ...mapActions([
                'resetCheckedProjects',
                'selectEditProject',
                'checkProjectWithChildren',
                'toggleProjectEditTag'
            ]),
            itemClick (type) {
                switch (type) {
                    case 'pdf-settings':
                        this.$modal.show(
                            SettingsModal,
                            {type: type},
                            {
                                classes: 'v--modal',
                                draggable: '.custom-modal_draggable',
                                height: 'auto',
                                name: 'SettingsModal',
                                width: document.body.offsetWidth < 649 ? '90%' : 370,
                            }, {
                                'before-open': beforeModalOpen,
                                'before-close': beforeModalClose
                            },
                        );
                        break;
                    case 'email-settings':
                        this.$modal.show(
                            SettingsModal,
                            {type: type},
                            {
                                classes: 'v--modal',
                                draggable: '.custom-modal_draggable',
                                height: 'auto',
                                name: 'SettingsModal',
                                clickToClose: false,
                                width: document.body.offsetWidth < 649 ? '90%' : 447,
                            }, {
                                'before-open': beforeModalOpen,
                                'before-close': beforeModalClose
                            },
                        );
                        break;
                    case 'project-settings':
                        this.$modal.show(
                            SettingsModal,
                            {type: type},
                            {
                                classes: 'v--modal v--modal-vertical-scroll',
                                draggable: '.custom-modal_draggable',
                                height: 'auto',
                                name: 'SettingsModal',
                                width: document.body.offsetWidth < 1280 ? 370 : 1208,
                            }, {
                                'before-open': beforeModalOpen,
                                'before-close': beforeModalClose
                            },
                        );
                        break;
                    case 'edit-project':
                        this.selectEditProject(this.getCurrentProject.id);
                        break;
                    case 'delete-tag':
                        this.$modal.show(
                            ConfirmProjectTagDeleteModal,
                            {type: 'deleteProjectTtag'},
                            {
                                classes: 'v--modal',
                                draggable: '.custom-modal_draggable',
                                height: 'auto',
                                name: 'DeleteProjectTag',
                                width: document.body.offsetWidth < 649 ? '90%' : 370,
                            },
                        );
                        break;
                    case 'edit-tag':
                        this.toggleProjectEditTag();
                        break;
                    case 'viewkey-settings':
                        this.$router.push({name:'settingsAccountViewKey'});
                        break;
                    case 'table-settings':
                        this.$router.push({name:'settingsAccountTable'});
                        break;
                    default:
                        this.resetCheckedProjects();
                        if (this.getCurrentProject) this.checkProjectWithChildren(this.getCurrentProject.id);
                        this.manageProjects(type, false, null, true, this.getCheckedProjects.length > 1);
                }

                this.$refs.otherActionsDropdown.isHidden = true;
            },
        }
    }
</script>

<style lang="scss" scoped>
  .other-actions {
    position: relative;

    .other-actions__button {
      font-size: 18px;
      font-weight: 700;
      margin-left: 9px;
      padding: 8px 8px;
    }

    .v-context {
      max-height: 80vh;
      overflow: auto;
      padding-bottom: 0;
      width: 270px;

      li {
        a {
          span {
            margin-right: 8px;

            &.kw-link-2 {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 959px) {
    .other-actions {
      display: none;
    }
  }
</style>
