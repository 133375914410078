<template>
    <div class="profile-wrapper" data-cy="profile-icon">
        <dropdown :close-on-click="true" :x="-176">
            <template slot="btn">
                <div class="button-profile" data-cy="button-profile">
                    <span class="kw kw-user" />
                    <div class="mobile-button-text">
                        {{ $t('profile') }}
                    </div>
                    <div class="desktop-button-text">
                        <v-gravatar
                            class="button-profile__gravatar"
                            default-img="mp"
                            :email="getUserDataProp('email')"
                        />
                    </div>
                </div>
            </template>
            <template slot="body">
                <ul class="v-context" data-cy="profile-dropdown-menu">
                    <li
                        v-for="item in profileItemsConfigs"
                        :key="item.text"
                        :data-cy="item.dataCy"
                    >
                        <template v-if="!item.hidden">
                            <hr v-if="item.type === 'divider'" />
                            <span
                                v-else-if="item.type === 'subtitle'"
                                class="v-context__subtitle"
                                data-cy="dropdown-menu-subtitle"
                                @click="item.event ? handleEvent(item.event) : null"
                            >
                                {{ item.text }}
                            </span>
                            <router-link
                                v-else-if="item.type.includes('router-link')"
                                :to="item.to"
                                @click="item.event ? handleEvent(item.event) : null"
                            >
                                <span v-if="item.class" :class="item.class" />
                                {{ item.text }}
                                <span v-if="item.icon" :class="item.icon" />
                            </router-link>

                            <a
                                v-else-if="item.type.includes('a-link')"
                                :href="item.href"
                                target="_blank"
                                @click="item.event ? handleEvent(item.event) : null"
                            >
                                <span v-if="item.class" :class="item.class" />
                                {{ item.text }}
                                <span v-if="item.icon" :class="item.icon" />
                            </a>

                            <a
                                v-else-if="item.type.includes('log-out')"
                                @click.prevent="profileActionsHandler"
                            >
                                <span v-if="item.class" :class="item.class" />
                                {{ item.text }}
                            </a>

                            <template
                                v-else-if="item.type.includes('logged-in-as')"
                            >
                                <div
                                    class="menu-profile"
                                    data-cy="menu-profile"
                                >
                                    <div class="menu-button-avatar">
                                        <v-gravatar
                                            default-img="mp"
                                            :email="getUserDataProp('email')"
                                        />
                                    </div>
                                    <div class="menu-button-text">
                                        <span class="logged-in-as">{{
                                            item.text
                                        }}</span>
                                        <span
                                            class="user-email"
                                            :title="item.email"
                                            >{{ item.email }}</span
                                        >
                                    </div>
                                </div>
                            </template>
                        </template>
                    </li>
                </ul>
            </template>
        </dropdown>
        <div v-if="showSwitchToAnnualButton" class="annual-upgrade-btn" @click="upgradeToAnnual">
            <span class="title">{{ $t('get-months-free', {count: 2}) }}</span>
            <span>{{ $t('upgrade-to-annual') }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Events, EventBus } from '@/events';
import Gravatar from 'vue-gravatar';
import { logout } from '@/helpers/service';
import { API_DOCUMENTATION_LINK } from '@/constants';

export default {
    name: 'Profile',
    components: {
        'v-gravatar': Gravatar,
    },
    computed: {
        ...mapGetters([
            'getCurrentPlan',
            'isViewOnlyUser',
            'getTutorialMode',
            'getTrialPeriod',
            'getUserDataProp',
            'getPermissionData',
            'isAdminOrMasterUser',
            'getUnreadAlertsCount',
            'getArchivedProjectsAvailable',
            'getIsRestrictedAccessUser'
        ]),
        profileItemsConfigs() {
            return [
                {
                    text: this.$t('development'),
                    type: 'subtitle',
                    hidden: this.getTutorialMode || this.getIsRestrictedAccessUser,
                },
                {
                    class: 'kw kw-pie-chart',
                    hidden: !this.isAdminOrMasterUser || this.getTutorialMode || this.getIsRestrictedAccessUser,
                    text: this.$t('google-analytics'),
                    to: { name: 'googleAnalytics' },
                    type: 'router-link-analytics',
                    dataCy: 'router-link-analytics',
                },
                {
                    class: 'kw kw-globe',
                    hidden:
                        process.env.SHOW_GSC_SERVICE == 'false' ||
                        !this.isAdminOrMasterUser ||
                        this.getTutorialMode
                        || this.getIsRestrictedAccessUser,
                    text: this.$t('google-search-console'),
                    to: { name: 'googleSearchConsole' },
                    type: 'router-link-search-console',
                    dataCy: 'router-link-search-console',
                },
                this.getPermissionData('google_integrations')
                    ? {
                          class: 'kw kw-database',
                          icon: 'kw kw-external-link',
                          hidden:
                              process.env.SHOW_GLS_SERVICE == 'false' ||
                              !this.isAdminOrMasterUser ||
                              this.getTutorialMode
                              || this.getIsRestrictedAccessUser,
                          href: 'https://datastudio.google.com/datasources/create?connectorId=AKfycbzWt3lnabx6uAh4gEF0s_jA7_V_gD66FrXiJpMFRZCeyiMfqQw-kWT7EYjwpzm9lP2I&authuser=0',
                          text: this.$t('google-looker-studio'),
                          type: 'a-link-google-looker-studio',
                          dataCy: 'a-link-google-looker-studio',
                      }
                    : {
                          class: 'kw kw-database',
                          icon: 'kw kw-external-link',
                          hidden:
                              process.env.SHOW_GLS_SERVICE == 'false' ||
                              !this.isAdminOrMasterUser ||
                              this.getTutorialMode
                              || this.getIsRestrictedAccessUser,
                          text: this.$t('google-looker-studio'),
                          to: { name: 'googleLookerStudio' },
                          type: 'router-link-google-looker-studio',
                          dataCy: 'router-link-google-looker-studio',
                      },
                this.getPermissionData('api_access')
                    ? {
                          class: 'kw kw-api',
                          icon: 'kw kw-external-link',
                          hidden: this.getTutorialMode,
                          href: API_DOCUMENTATION_LINK,
                          text: this.$t('api'),
                          type: 'a-link-api',
                          dataCy: 'a-link-api',
                          event: Events.OPEN_API_DOCUMENTATION,
                      }
                    : {
                          class: 'kw kw-api',
                          icon: 'kw kw-external-link',
                          hidden: this.getTutorialMode,
                          text: this.$t('api'),
                          to: { name: 'apiAccess' },
                          type: 'router-link-api',
                          dataCy: 'router-link-api',
                          event: Events.OPEN_API_DOCUMENTATION,
                      },
                {
                    type: 'divider',
                    hidden: !this.isAdminOrMasterUser || this.getTutorialMode || this.getIsRestrictedAccessUser,
                    dataCy: 'divider',
                },
                {
                    text: this.$t('account'),
                    type: 'subtitle',
                    hidden: !this.isAdminOrMasterUser || this.getTutorialMode || this.getIsRestrictedAccessUser,
                },
                // {
                //     class: 'kw kw-settings',
                //     hidden: !this.isAdminOrMasterUser || this.getTutorialMode,
                //     text: this.$t('settings'),
                //     to: { path: '/settings/account' },
                //     type: 'router-link-settings',
                //     dataCy: 'router-link-settings',
                // },
                {
                    class: 'kw kw-dollar-sign',
                    hidden: !this.isAdminOrMasterUser || this.getTutorialMode || this.getIsRestrictedAccessUser,
                    to: { path: '/settings/plan' },
                    text: this.$t('billing-plan'),
                    type: 'router-link-plan',
                    dataCy: 'router-link-plan',
                },
                {
                    type: 'divider',
                    hidden: this.isViewOnlyUser || this.getTutorialMode || this.getIsRestrictedAccessUser,
                    dataCy: 'divider',
                },
                {
                    text: this.$t('data-management'),
                    type: 'subtitle',
                    hidden: this.getTutorialMode || this.getIsRestrictedAccessUser,
                },
                // {
                //     class: 'kw kw-bell',
                //     hidden: this.getTutorialMode,
                //     text: this.$t('alerts'),
                //     to: { name: 'alerts' },
                //     type: 'router-link-alerts',
                //     dataCy: 'router-link-alerts',
                // },
                {
                    class: 'kw kw-file-text',
                    hidden: this.isViewOnlyUser || this.getTutorialMode || this.getIsRestrictedAccessUser,
                    text: this.$t('reports'),
                    to: { name: 'Reports' },
                    type: 'router-link-reports',
                    dataCy: 'router-link-reports',
                },
                {
                    class: 'kw kw-archive',
                    hidden:
                        this.isViewOnlyUser ||
                        (this.getTutorialMode &&
                            !this.getArchivedProjectsAvailable)
                        || this.getIsRestrictedAccessUser,
                    text: this.$t('archive'),
                    to: { name: 'archived' },
                    type: 'router-link-archive',
                    dataCy: 'router-link-archive',
                },
                { type: 'divider', hidden: true },
                {
                    class: 'icon-to-old-design',
                    hidden: true,
                    text: 'Go to old design',
                    to: { name: 'gotoOldDesign' },
                    type: 'router-link-old-design',
                },
                {
                    class: 'kw kw-download',
                    text: this.$t('import-data'),
                    hidden: !this.isAdminOrMasterUser || this.getIsRestrictedAccessUser,
                    to: { name: 'ImportData' },
                    type: 'router-link-import-data',
                    dataCy: 'router-link-import-data',
                },
                {
                    hidden: this.getTutorialMode || this.getIsRestrictedAccessUser,
                    type: 'divider',
                    dataCy: 'divider',
                },
                {
                    class: 'kw kw-gift',
                    icon: 'kw kw-external-link',
                    text: this.$t('whats-new'),
                    type: 'a-link',
                    href: 'https://keyword.canny.io/changelog',
                    hidden: this.getIsRestrictedAccessUser
                },
                {
                    class: 'kw kw-life-buoy',
                    icon: 'kw kw-external-link',
                    text: this.$t('help'),
                    type: 'a-link',
                    href: 'https://support.keyword.com/',
                    hidden: this.getIsRestrictedAccessUser
                },
                // {
                //     class: 'kw kw-phone-call',
                //     text: this.$t('contact'),
                //     to: { name: 'contactUs' },
                //     type: 'router-link-contact',
                //     dataCy: 'router-link-contact',
                // },
                {
                    class: 'kw kw-log-out',
                    text: this.$t('logout'),
                    type: 'log-out',
                    dataCy: 'log-out',
                },
                {
                    hidden: this.getTutorialMode || this.getIsRestrictedAccessUser,
                    type: 'divider',
                    dataCy: 'divider',
                },
                {
                    class: 'kw kw-logged-in-as',
                    text: this.$t('logged-in-as'),
                    email: this.getUserDataProp('email'),
                    type: 'logged-in-as',
                    dataCy: 'logged-in-as',
                },
            ];
        },
        showSwitchToAnnualButton(){
            return this.isAdminOrMasterUser &&
                    !this.getTrialPeriod &&
                    this.getCurrentPlan?.subscription &&
                    this.getCurrentPlan.subscription.package <= 5000 &&
                    this.getCurrentPlan.subscription.price_type !== 'negotiated' &&
                    !this.getCurrentPlan.subscription.is_free_plan &&
                    this.getCurrentPlan.subscription.billing_cycle === 'monthly';
        }
    },
    methods: {
        profileActionsHandler() {
            logout(this);
        },
        showUnread(item) {
            return (
                item.type === 'router-link-alerts' &&
                this.getUnreadAlertsCount !== '0' &&
                this.getUnreadAlertsCount !== null
            );
        },
        handleEvent(event) {
            EventBus.emit(event);
        },
        upgradeToAnnual(){
            const packageId = this.getCurrentPlan?.subscription?.package_identity;
            if(packageId){
                this.$router.push({name: 'AccountDetails', query: {id: packageId, billing_cycle: 'annually', switchToAnnual: true}});
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/partials/dropdowns';

.profile-wrapper {
    align-items: center;
    display: flex;
    user-select: none;

    .v-context {
        width: 230px;
        box-shadow: 0px 6px 10px 3px rgba(39, 60, 123, 0.2);

        span.kw-external-link {
            position: absolute;
            right: 0;
            color: kw-color(kw-blue, 6);
        }
    }
}

.button-profile {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    width: 40px;
    height: 40px;
    padding-bottom: 1.5px;

    * {
        pointer-events: none;
    }

    span {
        &.kw-user {
            color: kw-color(kw-grey, 1);
            font-size: 14px;
            font-weight: 700;
            padding-right: 5px;
        }
    }
}

.button-profile_alerts {
    position: relative;

    &:before {
        background-color: kw-color(kw-red, 1);
        border-radius: 50%;
        content: '';
        height: 8px;
        position: absolute;
        right: -4px;
        top: -2px;
        width: 8px;
    }
}

.desktop-button-text {
    display: none;
}

.mobile-button-text {
    color: kw-color(kw-grey, 1);
    display: block;
    font-size: 9px;
    font-weight: 500;
    line-height: 16px;
    text-transform: capitalize;
    user-select: none;
}

/deep/ #router-link-archive {
    display: none;
}

.menu-profile {
    display: flex;
    align-items: center;
    padding: 6px 16px;
    overflow: hidden;

    .menu-button-avatar {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        box-shadow: 0 2px 6px rgba(59, 64, 68, 0.15);
    }

    .menu-button-text {
        margin-left: 8px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        overflow: hidden;
        text-overflow: ellipsis;

        .logged-in-as {
            font-style: italic;
            color: #0074d9;
        }

        .user-email {
            font-style: normal;
            color: #63697b;
        }
    }
}

@media screen and (min-width: 960px) {
    .desktop-button-text {
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 2px 6px rgba(59, 64, 68, 0.15);
        color: kw-color(kw-blue, 1);
        display: flex;
        font-size: 15px;
        font-weight: 500;
        height: 100%;
        justify-content: center;
        overflow: hidden;
        text-transform: uppercase;
        width: 100%;
    }

    .mobile-button-text {
        display: none;
    }

    .button-profile {
        margin: 12px 10px;
        padding-bottom: 0;

        span {
            &.kw-user {
                display: none;
            }
        }

        .button-profile__gravatar {
            height: 100%;
        }
    }

    /deep/ #router-link-archive {
        display: block;
    }
}
.annual-upgrade-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 117px;
    height: 45px;
    border-radius: 8px 8px 2px 2px;
    background: linear-gradient(83.57deg, #CB1A02 -38.9%, #C879BB 1.45%, #0074D9 104.26%);
    cursor: pointer;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 400;
    line-height: 11.72px;
    color: kw-color(kw-white, 8);
    padding: 8px 10px;
    & > .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 14.06px;
        color: kw-color(kw-white, 1);
        padding-bottom: 2px;
    }
    @media screen and (max-width: 960px) {
        margin-left: 12px;
    }
    &:hover{
        background: linear-gradient(83.57deg, #911302 -38.9%, #c150ae 1.45%, #025aa6 104.26%);
    }
}
</style>
