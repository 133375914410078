<template>
    <div data-cy="email-verification" class="email-verification info">
        <div class="title">
            {{
                checking
                    ? $t('verify.account-activation')
                    : verified
                    ? $t('verify.account-activated')
                    : errorMsg
            }}
        </div>
        <div class="status">
            <loader v-if="checking" :size="41" />
            <img v-else-if="verified" src="~img/check.svg" alt="checked" />
        </div>
        <div class="description">
            <div>
                <div class="line first">Thank you for using Keyword.com.</div>
                <div class="line" v-if="verified">
                    Click below to log in magically.
                </div>
            </div>

            <kw-button
                v-if="verified"
                button-type="action"
                :button-text="$t('verify.button-text')"
                @click="autoLogin"
            />
        </div>
        <div class="logo"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { saveLoginData } from '@/helpers/service';
import { cookieGetters } from '@/helpers/cookieManager';
export default {
    name: 'EmailVerification',
    data() {
        return {
            verified: false,
            checking: true,
            recaptcha: '',
            userData: false,
            errorMsg: this.$t('verify.error-msg'),
            hash: this.$route.query.hash,
            email: this.$route.query.email,
        };
    },
    mounted() {
        if (!this.$route.query.email || !this.$route.query.hash) {
            this.$router.push({ name: 'login' });
        }
    },
    async created() {
        if (this.email && this.hash) {
            await this.$recaptchaLoaded();
            this.recaptcha = await this.$recaptcha('verification');

            const res = await this.verifyEmail({
                hash: this.hash,
                email: this.email,
                recaptcha: this.recaptcha,
            });

            if (res && res.status === 200) {
                this.userData = res.data;
                this.verified = true;
            } else {
                this.$router.push({
                    name: 'thankYou',
                    query: { email: this.email },
                });
            }
        }

        this.checking = false;
    },
    methods: {
        ...mapActions(['verifyEmail']),
        autoLogin() {
            if (this.userData) {
                saveLoginData(this.userData);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.email-verification {
    .title {
        color: #15394f;
        font-family: Aeonik;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .status img {
        width: 41px;
        height: 41px;
    }

    .description {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 27px;

        .line {
            color: var(--black, #15394f);
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;

            &.first {
                font-weight: 500;
            }
        }


        /deep/ .kw-button {
            height: 50px;
            width: 500px;
            border-radius: 6px;

            color: #FFF;
            text-align: center;
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .logo {
        background-image: url(~img/logo-full.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 153px;
        height: 30px;
    }
}
</style>
