<template>
    <div>
        <competitors-panel />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CompetitorsPanel from 'components/competitors-panel/CompetitorsPanel';
import LoginForViewkey from 'components/modals/LoginForViewkey';

export default {
    name: 'ShareOfVoice',
    props: {
        viewkey: {
            type: String,
            required: false,
            default: '',
        },
        auth: {
            type: String,
            required: false,
            default: '',
        },
        hash: {
            type: String,
            required: false,
            default: '',
        },
        client: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        'competitors-panel': CompetitorsPanel,
    },
    computed: {
        ...mapGetters(['getViewkeyAccessStatus']),
        showViewkey() {
            return (
                this.getViewkeyAccessStatus &&
                this.getViewkeyAccessStatus !== 'denied'
            );
        },
    },
    watch: {
        showViewkey(val) {
            this.openLoginModalForViewkey(val);
        },
    },
    async created() {
        this.setIsViewkeyView(true);
        await this.getViewkeyInfo({
            shortkey: this.viewkey,
            id: this.auth,
            client: this.client,
        });

        const {
            name: routeName,
            params: routeParams,
        } = this.$router.currentRoute;
        if (routeName === 'shareOfVoiceWithHash') {
            await this.getTagDataForShareAction(routeParams.hash);
        }
        this.openLoginModalForViewkey(this.showViewkey);
    },
    beforeCreate() {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'robots');
        meta.setAttribute('content', 'noindex');
        document.getElementsByTagName('head')[0].append(meta);
    },
    methods: {
        ...mapActions(['setIsViewkeyView', 'getTagDataForShareAction', 'getViewkeyInfo']),
        openLoginModalForViewkey(val) {
            if (!val) {
                this.$modal.show(
                    LoginForViewkey,
                    {
                        viewkey: this.viewkey,
                        auth: this.auth,
                        client: this.client,
                    },
                    {
                        width: 282,
                        height: 'auto',
                        classes: 'v--modal v--modal_login-viewkey',
                        clickToClose: false,
                        name: 'loginForViewkeyModal',
                    },
                );
            } else {
                this.$modal.hide('loginForViewkeyModal');
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
