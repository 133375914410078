<template>
    <span v-if="params.value === null" />
    <span v-else-if="params.value === -1" class="empty-value">&mdash;</span>
    <span
        v-else-if="params.value === 0"
        class="empty-value"
        style="color: #c1c4cd"
        >> 10</span
    >
    <span v-else>
        {{ params.value.toLocaleString('en') }}
        {{ params.column.colId === 'ctr' ? '%' : '' }}
    </span>
</template>

<script>
import RotatingUpdateIcon from '@/components/ui-elements/RotatingUpdateIcon';
export default {
    name: 'PageRenderer',
    components: { RotatingUpdateIcon },
};
</script>

<style lang="scss" scoped></style>
