export default (i18n, toastr) => {
    return error => {
        const { config } = error;

        if (!config) {
            return Promise.reject(error);
        }

        if (config.method === 'post' && config.url.includes('/notes')) {
            toastr.e(i18n.t('notes-add-error'));
        }

        if (config.method === 'delete' && config.url.includes('/notes/')) {
            toastr.e(i18n.t('notes-delete-error'));
        }

        if (config.method === 'put' && config.url.includes('/notes/')) {
            toastr.e(i18n.t('notes-update-error'));
        }

        return Promise.reject(error);
    };
};