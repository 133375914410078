const enAlerts = {
    'alert-interval': 'Alert interval',
    'alerts': 'Alerts',
    'show-pivot-table': 'Show Pivot Table',
    'show-latest-records': 'Show Latest Records',
    'project-filter': 'Project Filter',
    'search-anything': 'Search Anything',
    'project-has-been-deleted': 'The selected project was deleted',
    'group-alerts-by-project': 'Group Alerts By Project',
};

export default enAlerts;