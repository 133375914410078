import apiFactory from '@/api';
const notesApi = apiFactory.get('notes');

import {SET_PROJECT_NOTES,} from '@/store/mutations';

export default {
    state: {
        projectNotes: {},
    },
    mutations: {
        [SET_PROJECT_NOTES]: (state, payload) => state.projectNotes = payload,
    },
    getters: {
        getProjectNotes: state => state.projectNotes,
        getProjectNotesById: state => id => {
            return !state.projectNotes[id]
                ? []
                : state.projectNotes[id]
                    .sort((a, b) => new Date(b.attributes.created_at).getTime() - new Date(a.attributes.created_at).getTime());
        },
    },
    actions: {
        async fetchProjectNotes ({getters, commit}, {id}) {
            const projectId = id;
            try {
                const res = await notesApi.fetch(id, {
                    params: {
                        per_page: 100,
                    },
                });
                commit(
                    SET_PROJECT_NOTES,
                    !getters.getProjectNotesById(projectId).length
                        ? {[id]: res.data.data}
                        : {...getters.getProjectNotes, [projectId]: res.data.data}
                );
            } catch (error) {
                //
            }
        },
        async fetchProjectNotesForPDF ({getters, commit}, project) {
            const query = {
                params: {viewkey: getters.getDataForViewkey.shortkey}
            };

            try {
                const res = await notesApi.viewkeyFetch(project.id, query);
                commit(SET_PROJECT_NOTES, {[project.id]: res.data.data});
            } catch (error) {
                //
            }
        },
        async addProjectNote ({getters, commit}, {date, note, projectId, keywordId, competitorDomain}) {
            const notes = _.cloneDeep(getters.getProjectNotesById(projectId));

            const postData = {
                data: {attributes: {note, date}},
            };

            if (keywordId) {
                postData.data.attributes.keyword_id = keywordId;
            }

            if (competitorDomain) {
                postData.data.attributes.competitor_domain = competitorDomain;
            }

            try {
                const res = await notesApi.add(projectId, postData);
                notes.push(res.data.data);
                commit(SET_PROJECT_NOTES, {...getters.getProjectNotes, [projectId]: notes});
            } catch (error) {
                //
            }
        },
        async deleteProjectNote ({getters, commit}, id) {
            const project = getters.getCurrentProject;
            const notes = _.cloneDeep(getters.getProjectNotesById(project.id));

            try {
                await notesApi.delete(project.id, id);
                const newNotes = notes.filter(note => note.id !== id);
                commit(SET_PROJECT_NOTES, {[project.id]: newNotes});
            } catch (error) {
                //
            }
        },
        async updateProjectNote ({getters, commit}, {id, note, date}) {
            const project = getters.getCurrentProject;
            const notes = _.cloneDeep(getters.getProjectNotesById(project.id));

            const postData = {
                data: {
                    attributes: {note, date}
                }
            };

            try {
                const res = await notesApi.update(project.id, id, postData);
                const updatedNotes = notes.filter(el => el.id !== id);
                updatedNotes.push(res.data.data);
                commit(SET_PROJECT_NOTES, {[project.id]: updatedNotes});
            } catch (error) {
                //
            }
        },
    }
};
