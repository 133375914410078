export default {
    'tutorial': {
        'step': 'Step {step}/4',
        'go-back': 'Go Back',
        'continue': 'Continue',
        'project-name': 'Project Name',
        'first-project-placeholder': 'Name your first project',
        'save-keywords': 'Save Keywords',
        'text-box-placeholder': 'Your answer...',
        'add-keywords': 'Add Keywords',
        'import-data': 'Import Data',
        'or': 'or',
        'previous': {
            'title': 'How were you tracking keywords\nin the past?',
            'message': 'Let us know so we can help you with relevant tips and tricks to get you started on your SEO journey.',
            'options': {
                "HAVE_NOT": 'I haven’t.',
                "XYZ": 'With XYZ Ranker',
                "MANUALLY": 'Manually',
            },
            'values': {
                "1": 'I was not tracking keywords.',
                "2": 'With XYZ Ranker',
                "3": 'Manually',
            },
            'icons': {
                "HAVE_NOT": '🙅',
                "XYZ": '🤓',
                "MANUALLY": '👨‍💻',
            },
        },
        'seoGoal': {
            'title': 'What is your primary SEO goal?',
            'message': 'Help us better understand your needs.',
            "options": {
                "IMPROVE_SEO": 'Improve local and global SEO',
                "SEND_REPORTS": 'Send jaw-dropping reports',
                "DISCOVER_COMPETITORS": 'Discover and outrank competitors',
                "TRAKER_API": 'Use the rank tracker API',
                "OTHER": 'Other',
            },
            "values": {
                "1": 'Keyword research',
                "2": 'Live updates of ranking data',
                "3": 'Automatic reporting',
            },
            'icons': {
                "HAVE_NOT": '🧐',
                "XYZ": '📈',
                "MANUALLY": '📊',
            },
        },
        'source': {
            'title': 'How did you hear about us',
            'message': 'Let us know how did you find out about keyword.com',
            "options": {
                "GOOGLE": 'Google',
                "LINKEDIN_ADS": 'LinkedIn Ads',
                "SOCIAL_MEDIA": 'Social media',
                "BING": 'Bing',
                "WEBINAR": 'Webinar',
            },
            "values": {
                "1": 'Google',
                "2": 'LinkedIn Ads',
                "3": 'Social media',
                "4": 'Bing',
                "5": 'Webinar',
            },
        },
        'firstProject': {
            'title': 'Let’s add your first keywords',
            'message': 'Experience the power behind keyword.com by adding your first keywords below. 🧑‍💻',
        },
        'finish': {
            'title': 'Great! 🎉 Your {project} project has been created.',
            'message': 'Now, let’s add some keywords and see the magic happen.',
        },
    },
};
