<template functional>
    <div class="login-info-section">
        <div class="title"></div>
        <div class="desc">{{ parent.$t('info-section.login-desc') }}</div>
    </div>
</template>

<script>
export default {
    name: 'LoginInfo',
};
</script>

<style lang="scss" scoped>
.login-info-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    .title {
        width: 332px;
        height: 228px;
        background: url(~img/not-average.svg) no-repeat center center;
    }

    .desc {
        color: #fdefef;
        text-shadow: 0px 6.713850021362305px 6.713850021362305px
            rgba(0, 0, 0, 0.25);
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
</style>
