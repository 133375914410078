import Vue from 'vue';

const bus = new Vue();

/**
 * Trigger event
 *
 * @param {string} event
 * @param {object} data
 */
function emit(event, data) {
    bus.$emit(event, data);
}

/**
 * Attach event listener to specific event
 *
 * @param event
 * @param listener
 */
function on(event, listener) {
    bus.$on(event, listener);
}

/**
 * Detach particular event listener from an event
 *
 * @param event
 * @param listener
 */
function off(event, listener) {
    bus.$off(event, listener);
}

export default {
    emit,
    on,
    off,
}
