<template>
    <div
        class="custom-modal custom-modal_viewkey"
        data-cy="overview-viewkey-modal"
    >
        <div class="custom-modal__header">
            {{ $t('view-keys') }}
        </div>
        <div class="custom-modal__body custom-modal__body_viewkey">
            <div class="view_key_modal_scroll_container">
                <div
                    v-for="(project, i) in inputProjects"
                    :key="project.id"
                >
                    <viewkey-link-item :project="project" />
                    <hr
                        v-if="inputProjects.length > 1 && i < inputProjects.length - 1"
                        class="custom-modal__divider"
                    >
                </div>
            </div>
        </div>
        <div class="custom-modal__footer custom-modal__footer_viewkey">
            <kw-button
                button-type="primary"
                :button-text="$t('cancel')"
                @click="close"
            />
        </div>
    </div>
</template>

<script>
    import ViewkeyLinkItem from 'components/modals/ViewkeyLinkItem';
    export default {
        name: 'ViewkeyLinkModal',
        components: {
            'viewkey-link-item': ViewkeyLinkItem,
        },
        props: {
            inputProjects: {
                type: Array,
                required: true,
            },
        },
        watch: {
            inputProjects (val) {
                if (!val.length) this.close();
            },
        },
        methods: {
            close () {
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss">
    @import '~sass/modal';
    .custom-modal__body_viewkey {
        margin: 16px 0 !important;
    }
    .view_key_modal_scroll_container {
        @extend .custom-scroll;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 250px);
    }
    .custom-modal__footer_viewkey {
        justify-content: flex-end !important;
        padding: 0 0 !important;
        button {
            width: 102px;
        }
    }
</style>
