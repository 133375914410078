import client from '../client';
import interceptors from '../interceptors';
const notesInterceptor = interceptors.get('notes');

client.interceptors.response.use(
    notesInterceptor.s,
    notesInterceptor.e,
);

const resource = id => `/groups/${encodeURIComponent(id)}/notes`;

export default {
    fetch: async (projectId, payload) => await client.get(`${resource(projectId)}`, payload),
    viewkeyFetch: async (projectId, payload) => await client.get(`${resource(projectId)}viewkeyinfo`, payload),
    add: async (projectId, payload) => await client.post(`${resource(projectId)}`, payload),
    delete: async (projectId, id) => await client.delete(`${resource(projectId)}/${id}`),
    update: async (projectId, id, payload) => await client.put(`${resource(projectId)}/${id}`, payload),
};
