<template>
    <div class="pdf-project-table-container">
        <table>
            <thead>
                <tr>
                    <template v-for="(item, i) in getTableColumns">
                        <th
                            v-if="item.name !== 'checkbox'"
                            :key="i"
                            :class="getTableHeaderClass(item.name)"
                        >
                            {{ item.title }}
                        </th>
                    </template>
                </tr>
            </thead>
            <tbody>
                <template v-for="(keyword, index) in items">
                    <tr
                        v-if="title(keyword, index)"
                        :key="index + '_row_title'"
                        class="table-row__title"
                    >
                        <td v-html="title(keyword, index)" />
                    </tr>

                    <tr :key="index" :class="tableRowClasses(keyword, index)">
                        <template v-for="(item, i) in getTableColumns">
                            <td
                                v-if="item.name !== 'checkbox'"
                                :key="i"
                                :class="getTabledColumnClasses(item)"
                            >
                                <template v-if="simpleFields(item.name)">
                                    <template v-if="isPeriodRow(item.name)">
                                        <span
                                            :class="
                                                periodIconClasses(item, keyword)
                                            "
                                        />
                                        <span
                                            class="period-value"
                                            v-html="
                                                processedField(
                                                    item.name,
                                                    keyword,
                                                )
                                            "
                                        />
                                    </template>
                                    <span
                                        v-else
                                        v-html="
                                            processedField(item.name, keyword)
                                        "
                                    />
                                </template>

                                <div
                                    v-else-if="geoFields(item.name)"
                                    class="table-cell"
                                >
                                    <div
                                        class="table-cell__content table-cell__content_cut"
                                    >
                                        <div
                                            class="table-cell__text"
                                            v-text="keyword[item.name]"
                                        />
                                    </div>
                                </div>

                                <div
                                    v-else-if="item.name === 'grank'"
                                    class="table-cell"
                                >
                                    <div
                                        v-if="keyword.in_featured"
                                        class="table-cell__icon"
                                    >
                                        <span class="kw kw-featured-snippet" />
                                    </div>
                                    <div
                                        v-if="keyword.in_local_pack"
                                        class="table-cell__icon"
                                    >
                                        <span class="kw kw-flag" />
                                    </div>
                                    <div
                                        class="table-cell__content table-cell__content-grank"
                                    >
                                        <div
                                            class="table-cell__text table-cell__text_ranks"
                                            v-html="
                                                processedField(
                                                    item.name,
                                                    keyword,
                                                )
                                            "
                                        />
                                    </div>
                                </div>
                                <component
                                    :is="`${item.name}-field`"
                                    v-else
                                    :value.sync="keywordForStart.start"
                                    :keyword="keyword"
                                    :is-multi-domain="isMultiDomain"
                                    :is-view-key="
                                        !getViewkeyView && !isViewOnlyUser
                                    "
                                />
                                <span
                                    v-if="item.name === 'kw'"
                                    class="kw kw-favorite"
                                />
                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { KEYWORD_SOURCES, KW_TABLE_COLUMNS } from '@/constants';

import KeywordsTableMixin from '@/mixins/KeywordsTableMixin';
import StartField from 'components/main-table/fields/StartField';
import TagsField from 'components/main-table/fields/TagsField';
import UrlField from 'components/main-table/fields/UrlField';
import KwField from 'components/main-table/fields/KwField';
import { switchDateFormat } from '@/helpers/format-date-service';
import { showCompetition } from '@/helpers/keywordTablesHelper';

export default {
    name: 'PdfTable',
    components: {
        'start-field': StartField,
        'tagsSplited-field': TagsField,
        'url-field': UrlField,
        'kw-field': KwField,
    },
    mixins: [KeywordsTableMixin],
    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            keywordForStart: {
                kwid: '',
                auth: '',
                start: '',
            },
        };
    },
    computed: {
        ...mapGetters(['getUserData', 'getTableColumns', 'getListOfKeywordsDomains']),
        staticColumns() {
            const staticColumns = this.getTableColumns.filter(el =>
                KW_TABLE_COLUMNS.STATIC.includes(el.name),
            );
            return staticColumns;
        },
        isMultiDomain() {
            return this.getListOfKeywordsDomains.length > 1;
        },
    },
    methods: {
        showTrendOrDot(trend) {
            return trend === 0
                ? '<span class="empty-value">&mdash;</span>'
                : Math.abs(trend);
        },
        processedField(field, keyword) {
            switch (field) {
                case 'source':
                    return Object.values(KEYWORD_SOURCES).find(
                        item => item.value === keyword[field],
                    ).title;
                case 'timestamp':
                    // the date should always be in this format Y-m-d in the table
                    return switchDateFormat(
                        new Date(keyword.updated_at),
                        'Y-m-d',
                    );
                case 'grank':
                    return keyword[field] === 0
                        ? '<span class="empty-value">> 100</span>'
                        : keyword[field] === null
                        ? '<span class="empty-value">&mdash;</span>'
                        : keyword[field];
                case 'gpage':
                    return keyword[field] === 0
                        ? '<span class="empty-value">> 10</span>'
                        : keyword[field] === null
                        ? '<span class="empty-value">&mdash;</span>'
                        : keyword[field];
                case 'seocomp':
                    return keyword[field] === null
                        ? 'n/a'
                        : ['sem', 'map', 'yt'].includes(keyword.type) &&
                          keyword[field] === 0
                        ? '<span class="empty-value" >&mdash;</span>'
                        : keyword[field].toLocaleString('en');
                case 'clicks':
                    return keyword.clicks === null
                        ? '<span>Updating</span>'
                        : keyword.clicks === -1
                            ? '<span class="empty-value">&mdash;</span>'
                            : keyword.clicks.toLocaleString('en');
                case 'impressions':
                    return keyword.impressions === null
                        ? '<span>Updating</span>'
                        : keyword.impressions === -1
                            ? '<span class="empty-value">&mdash;</span>'
                            : keyword.impressions.toLocaleString('en');
                case 'ctr':
                    return keyword.ctr === null
                        ? '<span>Updating</span>'
                        : keyword.ctr === -1
                            ? '<span class="empty-value">&mdash;</span>'
                            : keyword.ctr.toLocaleString('en') + '%';
                case 'searchvolume':
                    return keyword.ms === null
                        ? '<span>Updating</span>'
                        : keyword.ms === 0
                        ? '<span class="empty-value">&mdash;</span>'
                        : keyword.ms.toLocaleString('en');
                case 'cpc':
                    return keyword.cpc === null
                        ? '<span>Updating</span>'
                        : keyword.cpc === 0
                        ? '<span class="empty-value">&mdash;</span>'
                        : '$ ' + keyword.cpc.toLocaleString('en');
                case 'competition':
                    return keyword.competition === null
                        ? '<span>Updating</span>'
                        : keyword.competition === 0
                        ? '<span class="empty-value">&mdash;</span>'
                        : showCompetition(keyword.competition);
                case 'day':
                case 'week':
                case 'month':
                case 'life':
                    return this.showTrendOrDot(
                        keyword.trends[field],
                        keyword.grank,
                    );
                case 'best':
                    return keyword[field] === 0
                        ? '<span class="empty-value">> 100</span>'
                        : keyword[field] === null
                        ? '<span class="empty-value">&mdash;</span>'
                        : keyword[field];
            }
        },

        simpleFields(field) {
            return KW_TABLE_COLUMNS.SIMPLE.includes(field);
        },
        geoFields(field) {
            return KW_TABLE_COLUMNS.GEO.includes(field);
        },
        isPeriodRow(row) {
            return KW_TABLE_COLUMNS.PERIODS.includes(row);
        },
        tableRowClasses(keyword, index) {
            return {
                'pdf-table-row': true,
                'table-row_fav': keyword.isfav,
                'table-row_even': index % 2,
            };
        },
        getTabledColumnClasses(item) {
            return {
                keywords: item.name === 'kw',
                start: item.name === 'start',
                'th-page': item.name === 'gpage',
                best: item.name === 'best',
                rank: item.name === 'grank',
                'one-day': item.name === 'day',
                'seven-day': item.name === 'week',
                'thirty-day': item.name === 'month',
                life: item.name === 'life',
                clicks: item.name === 'clicks',
                impressions: item.name === 'impressions',
                ctr: item.name === 'ctr',
                'search-vol': item.name === 'searchvolume',
                competition: item.name === 'competition',
                cpc: item.name === 'cpc',
                'search-result': item.name === 'seocomp',
                'date-added': item.name === 'timestamp',
                'search-location': item.name === 'near',
                'td-url': item.name === 'url',
                gmb: item.name === 'gmb',
            };
        },
        getTableHeaderClass(header) {
            return {
                'text-right': header.match(
                    /start|gpage|best|grank|life|searchvolume|cpc|competition|timestamp|day|week|month|seocomp/g,
                ),
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.pdf-project-table-container {
    overflow: hidden;
    margin: 25px 0;
    border-radius: 3px;

    table {
        border-collapse: collapse;
        width: 100%;

        thead {
            tr {
                th {
                    padding: 7px 15px;
                    border: 1px solid #e1e8ef;
                    background-color: #f8f9fa;
                    color: #63697b;
                    font-size: 12px;
                    font-weight: 700;
                    white-space: nowrap;
                }
                .text-right {
                    padding: 7px 5px;
                    text-align: right;
                }
            }
        }

        tbody {
            tr {
                overflow: hidden;
                border-right: 1px solid #e1e8ef;
                border-left: 1px solid #e1e8ef;
                td {
                    padding: 10px 5px;
                    border-left: 1px solid #e1e8ef;
                    position: relative;
                    border-top: 0;
                    border-bottom: 0;
                    line-height: 14px;
                    text-align: right;

                    .country-com {
                        background-position: -5px -768px;
                        width: 16px;
                        height: 11px;
                    }

                    &:first-child {
                        text-align: left;
                        border-left: none;
                    }

                    &:last-child {
                        border-right: none;
                    }

                    &.keywords {
                        min-width: 300px;
                        max-width: 300px;
                        width: 300px;
                        padding-left: 15px;
                        /deep/ .table-cell {
                            display: flex;
                            align-items: center;

                            .table-cell__icon {
                                &.table-cell__icon_flag {
                                    display: inline;
                                }
                            }

                            .table-cell__text {
                                word-break: break-word;
                            }
                        }
                    }

                    &.start {
                        width: 55px;
                        min-width: 55px;
                        max-width: 55px;
                        padding: 10px 5px;
                    }

                    &.th-page {
                        width: 55px;
                        min-width: 55px;
                        max-width: 55px;
                        padding: 10px 5px;
                    }

                    &.best {
                        width: 55px;
                        min-width: 55px;
                        max-width: 55px;
                        padding: 10px 5px;
                    }

                    &.rank {
                        width: 55px;
                        min-width: 55px;
                        max-width: 55px;
                        padding: 10px 5px;
                        .table-cell {
                            display: flex;
                            flex-direction: row;
                            div {
                                flex: 1 1 auto;
                            }
                        }
                    }

                    &.one-day,
                    &.seven-day,
                    &.thirty-day,
                    &.life {
                        width: 55px;
                        min-width: 55px;
                        max-width: 55px;
                        padding: 10px 5px;
                    }

                    &.search-vol {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                        padding: 10px 5px;
                        span {
                            display: inline-block;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    &.search-result {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                        padding: 10px 5px;
                        span {
                            display: inline-block;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    &.date-added {
                        width: 110px;
                        min-width: 110px;
                        max-width: 110px;
                    }

                    &.search-location {
                        width: 135px;
                        min-width: 135px;
                        max-width: 135px;

                        .table-cell {
                            .table-cell__text {
                                display: inline-block;
                                width: 100%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                    &.gmb {
                        width: 110px;
                        min-width: 110px;
                        max-width: 110px;

                        .table-cell {
                            .table-cell__text {
                                display: inline-block;
                                width: 100%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }

                    span {
                        &.icon-arrow_4 {
                            &.icon-rotate-down {
                                color: kw-color(kw-red, 1);
                                font-size: 10px;
                                line-height: 16px;
                                margin-right: 5px;
                            }
                        }

                        &.icon-arrow_4 {
                            color: #078d0c;
                            font-size: 10px;
                            line-height: 16px;
                            margin-right: 5px;
                        }

                        &.kw-airplay,
                        &.kw-image,
                        &.kw-smartphone,
                        &.kw-map-pin {
                            margin-right: 3px;
                            font-size: 12px;
                        }
                    }

                    &.empty {
                        color: #d3d3d3;
                    }

                    &.start-value {
                        color: #d3d3d3;
                    }

                    &.td-url {
                        text-align: left;

                        /deep/ .table-cell {
                            display: flex;

                            .table-cell__url-not-found {
                                position: relative;
                                top: 0;
                                left: 0;
                            }

                            .not-found__icon {
                                position: relative;
                                top: 0;
                                left: 0;
                                display: inline-flex;
                            }

                            .kw-lock {
                                position: relative;
                                left: 0;
                            }
                        }

                        /deep/ .kw {
                            margin-right: 5px;
                        }

                        /deep/ .table-cell__url-link-blok {
                            display: grid;
                            a {
                                overflow: hidden;
                                -o-text-overflow: ellipsis;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                display: block;
                                min-width: 120px;
                            }
                        }
                    }

                    span {
                        &.text {
                            line-height: 14px;
                            word-break: break-word;
                        }
                    }

                    .kw-favorite {
                        font-size: 8px;
                        color: #f4a732;
                        position: absolute;
                        top: calc(50% - 5px);
                        left: 2px;
                    }

                    &.url-not-found {
                        .not-found-text {
                            color: #ccc;
                            text-transform: uppercase;
                        }
                    }
                }

                &.table-row_even {
                    background-color: #f7f8fa;
                }

                &.pdf-table-row {
                    .kw-favorite {
                        display: none;
                    }
                }

                &.table-row_fav {
                    background-color: #fff8ee !important;
                    &:nth-child(even) {
                        background-color: #fdf2e0 !important;
                    }
                    .kw-favorite {
                        display: block;
                    }
                }

                &.table-row__title {
                    background: #ffffff;
                    font-size: 17px;
                    font-weight: 500;
                    line-height: 46px;
                    height: 46px;
                    margin-bottom: 0;
                    margin-top: 0;
                    outline: transparent;
                    padding: 10px 0;
                    border-right: none;
                    border-left: none;

                    td {
                        position: relative;
                        &:after {
                            content: '';
                            display: block;
                            height: 1px;
                            background: #e1e8ef;
                            width: 100vw;
                            position: absolute;
                            left: 0;
                            bottom: 100%;
                        }
                        &:before {
                            content: '';
                            display: block;
                            height: 1px;
                            background: #e1e8ef;
                            width: 100vw;
                            position: absolute;
                            left: 0;
                            top: 100%;
                        }
                    }
                    /deep/ {
                        .kw-corner-down-right {
                            margin: 0 4px;
                        }
                    }
                }

                &:last-child {
                    td {
                        border-bottom: 1px solid #e1e8ef;
                    }
                }
            }
        }
    }
}
</style>
