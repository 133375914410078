<template>
    <div
        class="custom-modal custom-modal_delete-tag"
    >
        <div class="custom-modal__header">
            <span class="delet-project-tag-title">
                {{ $t('delete-project-tag-question', {tagName: getActiveTag.name}) }}
            </span>
            <span
                class="kw kw-x"
                @click="close"
            />
        </div>
        <div class="custom-modal__footer">
            <kw-button
                :button-text="$t('delete')"
                button-type="primary"
                :loading="loading"
                @click="confirmDelete"
            />
        </div>

        <global-events @keyup.enter="confirmDelete" />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import DashboardMixin from '@/mixins/DashboardMixin';

    export default {
        name: 'ConfirmProjectTagDeleteModal',
        mixins: [
            DashboardMixin,
        ],
        data () {
            return {
                loading: false,
            };
        },
        computed: {
            ...mapGetters([
                'getActiveTag',
                'getCurrentProject'
            ]),
        },
        methods: {
            ...mapActions([
                'deleteProjectTag',
                'addActiveTag',
            ]),
            async confirmDelete () {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    const params = {
                        tagId:this.getActiveTag.id,
                        projectId: this.getCurrentProject.project_id,
                    }

                    await this.deleteProjectTag(params);

                    this.addActiveTag({});
                    this.close();
                } catch (e) {
                    console.log(e, 'e')
                }

                this.loading = false;

            },
            close () {
                this.$emit('close');
            },
        }
    };
</script>

<style lang="scss">
    @import '~sass/modal';
    .custom-modal_delete-tag {
        .custom-modal__header {
            display: flex;
            align-items: flex-start;
            .delet-project-tag-title {
                display: block;
                font-size: 18px;
                font-weight: 500;
                color: kw-color(kw-black, 7);
                flex: 1;
            }
            .icon-close-cross {
                font-size: 11px;
                color: rgba($color: #000000, $alpha: 0.3);
                cursor: pointer;
                transition: color .7s;
                &:hover {
                    color: rgba($color: #000000, $alpha: 0.7);
                }
            }
        }
        .custom-modal__footer {
            justify-content: flex-end;
            button {
                width: 120px;
                padding: 10px;
                transition: opacity .7s;
                opacity: 1;
                &:hover {
                    opacity: .7;
                }
                &:last-child {
                    background-color: kw-color(kw-red, 1) !important;
                    border: none;
                }
            }
        }
    }
</style>
