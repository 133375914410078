<template>
    <div :class="burgerAndLogoTranslated">
        <kw-button
            v-if="showNavButton"
            class="burger-button"
            button-type="text"
            :prepend-icon-class="expandIconClass"
            @click="toggle"
        />
        <router-link
            class="logo__container"
            data-cy="logo"
            :to="{name: 'Overview'}"
        >
            <img
                alt="logo"
                src="~img/logo-colour.svg"
            >
        </router-link>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    export default {
        name: 'BurgerAndLogo',
        computed: {
            ...mapGetters([
                'isLeftSidebarOpen',
                'getIsExpired',
                'getIsRestrictedAccessUser'
            ]),
            burgerAndLogoTranslated() {
                return {
                    'burger-and-logo': true,
                    'burger-and-logo_translated': this.isLeftSidebarOpen,
                };
            },
            expandIconClass() {
              return this.isLeftSidebarOpen ? 'kw kw-chevron-left' : 'kw kw-chevron-right';
            },
            showNavButton() {
              return !this.getIsExpired && !this.getIsRestrictedAccessUser;
            }
        },
        mounted() {
            this.$nextTick(() => this.bgClickListener());
        },
        methods: {
            ...mapActions([
                'toggleSideBar'
            ]),
            toggle () {
                this.toggleSideBar();
                this.$nextTick(() => this.bgClickListener());
            },
            bgClickListener () {
                const bg = document.querySelector('.mobile-background');

                if (bg) {
                    bg.addEventListener('click', () => this.toggleSideBar());
                }
            },
        }
    };

</script>

<style lang="scss" scoped>
  .burger-and-logo {
    align-items: center;
    display: flex;
  }

  .mobile-button-text {
    color: kw-color(kw-grey, 1);
    display: block;
    font-size: 9px;
    font-weight: 500;
    line-height: 16px;
    text-transform: capitalize;
    user-select: none;
  }

  .logo__container {
    display: none;
    height: 24px;
    margin-left: 16px;
    transition: all .3s ease;

    img {
      height: 100%;
    }
  }

  button.kw-button.burger-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: kw-color(kw-white, 1);
    box-shadow: 0px 2px 4px 0px #273C7B33;
    border: none;
    cursor: pointer;
    margin: 0;

    /deep/.kw-chevron-left,
    /deep/.kw-chevron-right{
      color: kw-color(kw-blue, 1);
      font-size: 10px;
      font-weight: 700;
    }
  }

  @media (min-width: 960px) {
    .mobile-button-text {
      display: none;
    }
    .logo__container {
      display: flex;
    }
  }
</style>
