import axios from 'axios';
import { cookieGetters } from '@/helpers/cookieManager';
import interceptors from './interceptors';

const apiToken = cookieGetters.getApiKey() || '';
const globalInterceptor = interceptors.get('global');
const baseURL = process.env.NODE_ENV === 'development'
    ? localStorage.getItem('axiosBaseURL') || process.env.API_V2_BASE_URL
    : process.env.API_V2_BASE_URL;

const fileManager = axios.create({
    baseURL,
    headers: {
        ['Accept']: 'application/vnd.api+json',
        ['Content-Type']: 'multipart/form-data',
    },
    params: {
        ['api_token']: apiToken,
    },
});

fileManager.interceptors.response.use(
    response => response,
    globalInterceptor.e,
);

export function resetApiToken () {
    fileManager.defaults.params.api_token = cookieGetters.getApiKey();
}

export default fileManager;
