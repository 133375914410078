export default {
    data () {
        return {
            billingOptions: [
                { name: 'Annual billing', value: 'annually', extra: 'Save 20%' },
                { name: 'Monthly billing', value: 'monthly' },
            ],
            updatesOptions: [
                { name: 'Bihourly updates', value: 'bihourly' },
                { name: 'Daily updates', value: 'daily' },
            ],
            dropMenuStatuses: {
                billing: false,
                updates: false,
            },
            addKeywordsDomainOptions: [
                {
                    text: '*.domain/*',
                    value: false,
                    type: ''
                },
                {
                    text: 'Exact domain',
                    value: true,
                    type: ''
                }
            ]
        };
    },
    methods: {
        /**
         * Close handler for drop menu
         * @param {string} type - type of drop menu
         */
        closeDropMenu (type) {
            this.dropMenuStatuses[type] = false;
        },
        /**
         * Toggle handler for drop menu
         * @param {string} type - type of drop menu
         */
        toggleDropMenuStatus (type) {
            if (!this.dropMenuStatuses[type]) {
                this.closeAllBillingPopups();
                this.dropMenuStatuses[type] = true;
            } else {
                this.dropMenuStatuses[type] = false;
            }
        },
        /**
         * Closes all drop menus
         */
        closeAllBillingPopups () {
            Object.keys(this.dropMenuStatuses).forEach(el => this.dropMenuStatuses[el] = false);
        },
        /**
         * Select item from drop menu
         * @param {Object} payload - function payload
         * @param {string} payload.type - type of drop menu
         * @param {Object} payload.item - selected item
         * @param {string} payload.item.value - value of selected item
         */
        setDropItem ({ type, item }) {
            this[type] = item.value;
        },
        /**
         * Method that returns selected option
         * @param {string} type - type of dropdown
         * @returns {Object} selected option object
         */
        selectedOption (type) {
            return this[`${type}Options`].find(el => el.value === this[type]);
        },
        /**
         * Dropdowns classes
         * @param {string} type - type of dropdown
         * @returns {Object} classes for dropdowns
         */
        dropdownClasses (type) {
            return {
                'new-custom-dropdown': true,
                'new-custom-dropdown_active': this.dropMenuStatuses[type],
                [`new-custom-dropdown_${type}`]: true,
            };
        },
    },
};