<template>
    <div class="block-wrapper">
        <div class="header-wrapper">
            <chart-header
                :title="$t('available-chart-titles.distribution')"
                :toolTip="$t('distribution-tooltip')"
            />
            <date-ranges-dropdown showDatePickerList type="trending" :value="date" @onSelect="onDateSelect" :hiddenDays="hiddenDays" />
            <kw-button
                :disabled="isLoading"
                class="hide-button"
                prepend-icon-class="kw kw-eye-off"
                button-type="secondary"
                @click="onHide"
            />
        </div>
        <button-group v-model="view" class="button-wrapper">
            <radio
                v-for="option in viewsOptions"
                :key="option.value"
                :selected-value="option.value"
                v-html="option.label"
            />
        </button-group>
        <div class="chart">
            <ranking-distributions v-if="isReady" :view="view" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChartBlockHeader from "./ChartBlockHeader.vue";
import RankingDistributions from '@/components/competitors-panel/RankingDistributions.vue';
import buttonGroup from 'vue-strap/src/buttonGroup';
import Radio from 'vue-strap/src/Radio';
import { viewsOptions } from '@/components/competitors-panel/configs';
import { exactDatePickerList } from '@/components/date-range-picker/datePickerList'
import DateRangesDropdown from '@/components/common/DateRangesDropdown.vue';

export default {
    name: "DistributionBlock",
    components: {
        "chart-header": ChartBlockHeader,
        'ranking-distributions': RankingDistributions,
        'button-group': buttonGroup,
        'radio': Radio,
        'date-ranges-dropdown': DateRangesDropdown
    },
    data() {
        return {
            isLoading: false,
            interval: null,
            fetched: false,
            view: 'percentage',
            viewsOptions,
            date: 'Last month',
            hiddenDays: ['today', 'this_month', 'last_3_months'],
            isReady: false
        }
    },
    computed: {
        ...mapGetters([
            "getCurrentProject",
            "getActiveTag",
            "getProjectOverviewOptionItem",
            "getProjectKeywordsInTopPositions"
        ]),
        chartKey(){
            return this.getActiveTag?.id || this.getCurrentProject?.id;
        }
    },
    watch: {
        getActiveTag: function() {
            this.isReady = false;
            this.fetched = true;
            this.init();
        },
    },
    mounted(){
        if(!this.fetched && this.getCurrentProject?.project_id){
            this.isReady = false;
            this.fetched = true;
            this.init();
        }
    },
    methods: {
        ...mapActions([
            "setProjectOverviewOptionIsVisible",
            "updateProjectTimeFrame",
            "setMindshareRankingDaysRange",
            "fetchAverageRanks",
            "fetchProjectKeywordsInTopPositionsChartData"
        ]),
        onHide() {
            this.isLoading = true;
            this.setProjectOverviewOptionIsVisible({ 
                projectId: this.getCurrentProject.id,
                option: "distribution",
                value: false 
            }).finally(()=>{
                this.isLoading = false;
            });
        },
        onDateSelect(dateKey) {
            const value = exactDatePickerList[dateKey];

            if (value) {
                this.date = exactDatePickerList[dateKey].label;

                this.updateProjectTimeFrame({
                    projectId: this.getCurrentProject.id,
                    option: 'distribution',
                    value: null,
                    interval: {
                        interval: dateKey
                    }
                });

                this.setMindshareRankingDaysRange({value: value.count, label: this.date});
                this.fetchAverageRanks();
            }
        },
        async init(){
            await this.fetchAverageRanks();
            if((!this.getProjectKeywordsInTopPositions.data && !this.getProjectKeywordsInTopPositions.loading)
                || (this.getProjectKeywordsInTopPositions.projectId && 
                (this.getProjectKeywordsInTopPositions.projectId != this.getCurrentProject?.project_id))){

                await this.fetchProjectKeywordsInTopPositionsChartData({projectId: this.getCurrentProject.project_id});
            }
            const interval = this.getProjectOverviewOptionItem('distribution')?.interval || 'last_month';
            const value = exactDatePickerList[interval];
            this.setMindshareRankingDaysRange({value: value.count, label: this.date});
            this.date = value.label;
            this.isReady = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.block-wrapper {
    width: 100%;
    
    .header-wrapper {
        width: 100%;
        display: flex;

        .hide-button{
            min-width: 24px;
            width: 24px;
            height: 24px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 8px;

            /deep/.kw {
                font-weight: 900;
                font-size: 16px;
                color: #757C90;
            }
        }
    }
    .button-wrapper{
        margin: 18px 0 10px;
        width: 230px;
    }
}
</style>
