export default {
    'back': 'Back',
    'host': 'Host',
    'domains': 'Domains',
    'track-status': 'Track Status',
    'add-a-domain': 'Add a Domain',
    'adding-domain': 'Adding Domain...',
    'enter-this-value': 'Enter this value',
    'deleting-domain': 'Deleting Domain...',
    'verifying-domain': 'Verifying Domain...',
    'add-another-domain': 'Add Another Domain',
    'whitelabel-domain-example': 'mail.example.com',
    'chose-an-email-domain': 'Chose an email domain',
    'updated-refreshing': 'Updated. Refreshing page...',
    'active-verified-domains': 'ACTIVE VERIFIED DOMAINS',
    'message-refreshing': `{message}. Refreshing page...`,
    'whitelabel-domain-verification': 'Whitelabel Domain Verification',
    "white-label-your-domain": "White Label Your Domain",
    'whitelabel-domain-fetch-error': 'Error during whitelabel domains fetch',
    'empty-whitelabel-domains-message': 'You currently do not have any verified domains.',
    'i-whitelabel-domain-verification': 'Only available to packages with over 5,000 keywords',
    'whitelabel-domains-dns-settings-msg': 'Make the following changes to your domain DNS settings.',
    'add-whitelabel-domain-message': 'You can setup and send reports through your verified domain name using a subdomain (i.e. mail.example.com) or a root domain (i.e. example.com).',
};
