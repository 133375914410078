<template functional>
    <div class="mindshare-update-container">
        <span
            class="kw kw-refresh-ccw"
        />
        <span
            class="kw kw-information"
            @mouseover="e => $options.showTooltip(parent, e)"
        />
        <p class="mindshare-update-message">{{ parent.$t('share-of-voice-refresh-message') }}</p>
    </div>
</template>

<script>
    export default {
        name: 'MindshareUpdateMessage',

        showTooltip: (parent, event) => {
            parent.tooltip({text: `<span class="sov-refresh-message_tooltip">${parent.$t('share-of-voice-refresh-message-tooltip')}</span>`, event, maxWidth: 250 });
        }
    };
</script>

<style lang="scss">

.sov-refresh-message_tooltip {
    font-weight: bold;
    font-size: 10px;
}

.mindshare-update-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: #E5ECF3;
    padding: 7px 0;
    margin-bottom: 10px;

    .kw-information {
        padding: 1px 5px;
    }

    >* {
        margin: 0 6px;
    }

    .kw-refresh-ccw {
        color: kw-color(kw-blue, 1);
        font-size: 16px;
        font-weight: 700;
        cursor: default;
        transform: scale(1, -1);
        animation: infiniteRotating 2s linear infinite;
    }

    .mindshare-update-message {
        color: kw-color(kw-blue, 1);
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }
}
</style>
