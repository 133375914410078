import account from './modules/account';
import addons from './modules/addons';
import alerts from './modules/alerts';
import billing from './modules/billing';
import ga from './modules/ga';
import gsc from './modules/gsc';
import importData from './modules/importData'
import invoices from './modules/invoices';
import keywords from './modules/keyword';
import metrics from './modules/metrics';
import projects from './modules/projects';
import reports from './modules/reports'
import tags from './modules/tags';
import users from './modules/users';
import whitelabelDomains from './modules/whitelabelDomains'
import currencies from './modules/currencies';
import countries from './modules/countries';
import legacy from './modules/legacy';
import notes from './modules/notes';
import eventTracking from './modules/eventTracking';
import user from './modules/user';
import incidents from './modules/incidents';

const modules = {
    account,
    addons,
    alerts,
    billing,
    ga,
    gsc,
    importData,
    invoices,
    keywords,
    metrics,
    projects,
    reports,
    tags,
    users,
    whitelabelDomains,
    currencies,
    countries,
    legacy,
    notes,
    eventTracking,
    user,
    incidents
};

export default {
    get: name => modules[name],
};
