const addPostfixToNumber = (val, precision = 2) => {
    const n = typeof val === 'string' ? parseInt(val, 10) : val;

    if (isNaN(n)) {
        return val;
    }

    if (n >= 1000000) return `${_.round(n / 1000000, precision)}M`;
    if (n >= 1000) return `${_.round(n / 1000, precision)}K`;
    return _.round(n, precision);
};

export {addPostfixToNumber};