import client from '../client';
import interceptors from '../interceptors';
const userInterceptor = interceptors.get('user');

client.interceptors.response.use(
    userInterceptor.s,
    userInterceptor.e,
);

const resource = '/user';

export default {
    saveEmailNotificationSettings: async params => await client.put(`${resource}/email-notifications`, params),
    fetchEmailNotificationSettings: async () => await client.get(`${resource}/email-notifications`),
};
