import client from '../client';
import interceptors from '../interceptors';

const globalInterceptor = interceptors.get('global');

client.interceptors.response.use(
    response => response,
    globalInterceptor.e,
);

const resource = '/google';

export default {
    fetch: async () => await client.get(`${resource}/incidents`),
};
