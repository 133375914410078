var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"table-cell-serp-features"},[(
            _vm.props.keyword.crawled_at < 1700161638 ||
            _vm.$options.getCount(_vm.props.keyword.serp_features) === 0
        )?_c('span',{staticClass:"empty-value"},[_vm._v("—")]):[_vm._l((_vm.$options.getSorted(
                _vm.props.keyword.serp_features,
                _vm.props.maxCount
            )),function(active,feature){return _c('div',{key:feature,class:("kw-sf-icon kw-sf-icon-" + feature + " " + (active ? 'active' : '')),attrs:{"data-important-tooltip":_vm.parent.$t(("serp-feature-" + feature))}})}),_vm._v(" "),(
                _vm.$options.getCount(_vm.props.keyword.serp_features) >
                _vm.props.maxCount
            )?_c('div',{staticClass:"kw-sf-number",attrs:{"data-important-tooltip":_vm.$options.getOthers(
                    _vm.props.keyword.serp_features,
                    _vm.props.maxCount,
                    _vm.parent
                )}},[_vm._v("\n            +"+_vm._s(_vm.$options.getCount(_vm.props.keyword.serp_features) -
                _vm.props.maxCount)+"\n        ")]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }