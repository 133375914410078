<template>
    <div
        class="project_add_tags_section"
        data-cy="add-tags-section"
    >
        <div class="project_add_tags_container">
            <div class="add_tags_title_block">
                <div class="add_tags_title">
                    {{ $t('new-tag') }}
                </div>
                <div class="add_tags_sub_title">
                    {{ $t('for-project') }}: {{ getProjectForTagAction.name }}
                </div>
            </div>
            <form class="project_add_tags_form">
                <span class="tag_input_label">
                    {{ $t('tag-name') }}
                </span>
                <div
                    class="project_tags_input"
                    data-cy="new-tag-input"
                >
                    <vue-tags-input
                        v-model="tag"
                        :tags="tags"
                        :placeholder="$t('create-tags')"
                        :avoid-adding-duplicates="true"
                        @tags-changed="updateTags"
                    />
                </div>
                
                <div class="project_add_tags_actions">
                    <div class="button_item">
                        <kw-button
                            :button-text="$t('cancel')"
                            button-type="secondary"
                            :disabled="createTagsLoading"
                            data-cy="cancel-new-tag"
                            @click="handleCancelClick"
                        />
                    </div>
                    <div class="button_item">
                        <kw-button
                            :button-text="$t('add-tags')"
                            button-type="primary"
                            :disabled="!isSubmitDisabled || createTagsLoading"
                            :loading="createTagsLoading"
                            data-cy="confirm-new-tag"
                            @click="handleCreateTagsClick"
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    export default {
        name: 'AddProjectTags',
        components: {
            'vue-tags-input':VueTagsInput,
        },
        props:{
            getProjectForTagAction: {
                type: Object,
                required: true,
            },
            toggleProjectAddTags: {
                type: Function,
                required: true,
            },
            createTags: {
                type: Function,
                required: true,
            },
        },
        data () {
            return {
                tag: '',
                tags: [],
                createTagsLoading: false
            };
        },
        computed: {
            isSubmitDisabled (){
                return !!this.tags.length;
            }
        },
        methods: {
            updateTags (newTags) {
                this.tags = newTags;
            },
            handleCancelClick () {
                this.toggleProjectAddTags();
            },

            async handleCreateTagsClick () {
                this.createTagsLoading = true;
                const tagsData = this.tags.map((item) => {
                    return item.text
                });
                if(this.tag) {
                    tagsData.push(this.tag);
                }
                try {
                    await this.createTags({
                        projectId: this.getProjectForTagAction.project_id, 
                        tags: tagsData
                    });
                    this.toggleProjectAddTags();
                } catch (error) {
                    console.log(error, 'error')
                }
               
                this.createTagsLoading = false;
            }

        }
       
        
    }
</script>

<style lang="scss" scoped>
  .project_add_tags_section {
    width: 100%;

    .project_add_tags_container {
      padding: 25px 20px;

      .add_tags_title_block {
        padding-bottom: 40px;

        .add_tags_title {
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
        }

        .add_tags_sub_title {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #828F9C;
          padding-top: 5px;
        }
      }

    }

    .project_add_tags_form {
      .tag_input_label {
        color: kw-color(kw-black, 1);
        font-size: 14px;
        padding-bottom: 5px;
        display: block;
        font-weight: 500;
      }

      /deep/.project_tags_input {
        .ti-input {
          border: 1px solid #AFBAC7;
          border-radius: 4px;

          .ti-tags {
            .ti-new-tag-input-wrapper {
              .ti-new-tag-input {
                width: 100%;
                color: kw-color(kw-black, 1);
                font-size: 14px;
                font-weight: 400;
                cursor: text;
                transition: .2s;
              }
            }
          }
        }
      }
    }

    .project_add_tags_actions {
      display: flex;
      padding: 50px 0 0 0;

      .button_item {
        flex: 1;

        button {
          width: 100%;
        }

        &:last-child {
          margin-left: 15px;
        }
      }

    }

  }

  /deep/ .ti-input {
    border: 1px solid #DEE5EC;
    border-radius: 4px;
    @media screen and (max-width: 959px) {
      width: 100%;
    }

  }

  /deep/ .ti-tag {
    background: #F5F6F7 !important;
    border: 1px solid #CBD6E9;
    box-sizing: border-box;
    border-radius: 4px;
    color: #265596 !important;
  }
</style>