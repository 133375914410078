<template>
    <div
        class="modal-missing-billing-details"
        data-cy="modal-missing-billing-details"
    >
        <div class="header">
            <closer @click="close" />
            <h4 v-text="$t('billing-details-need-updating')" />
        </div>
        <div class="body">
            <div>
                <p
                    class="content"
                    v-text="$t('billing-details-missing-msg')"
                />
                <p
                    class="content"
                    v-text="$t('ensure-taxes-msg')"
                />
            </div>
            <div class="controls">
                <kw-button
                    button-type="primary"
                    :button-text="$t('update-billing-details')"
                    data-cy="update-billing-details"
                    @click="confirm"
                />
                <kw-button
                    button-type="text"
                    :button-text="$t('dismiss')"
                    data-cy="update-billing-details"
                    @click="close"
                />
            </div>
        </div>

        <global-events @keyup.enter="confirm" />
    </div>
</template>

<script>
export default {
    name: "MissingBillingDetailsModal",
    methods: {
        confirm () {
            this.$emit("close");
            this.$router.push({name: 'AccountDetails'});
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-missing-billing-details {
    height: 100%;
    /deep/ .button-closer {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .body {
        padding: 8px 32px 24px;
        .content {
            font-size: 14px;
            line-height: 24px;
            color: #24242c;
            margin: 8px 0;
        }
        .controls {
            display: inline-flex;
            margin-top: 16px;

            button {
                width: 166px;
            }
        }
    }
    .header {
        padding: 24px 32px 8px;
        h4 {
            margin: 0;
            font-weight: 700;
            line-height: 32px;
            font-size: 20px;
            color: kw-color(kw-black, 7);
        }
    }
}
</style>
