import { cookieGetters } from '@/helpers/cookieManager';
export default ({$store}) => {
    if (window.wootric) {
        const {email = ''} = cookieGetters.getUserProfile() || {};
        const {createdAtTs = ''} = $store.getters.getUserData;
        const {
            packageName = '',
            packageLimit = '',
            cycle = '',
        } = $store.getters.getSubscriptionData;

        // Shows survey immediately for testing purposes. TODO: Comment out for production.
        // window.wootric_survey_immediately = true;

        window.wootricSettings = {
            email: email,
            created_at: createdAtTs,
            account_token: process.env.MIX_WOOTRIC_ACCOUNT_TOKEN,
            properties: {
                package_name: packageName,
                package_limit: packageLimit,
                cycle: cycle
            },
        };

        window.wootric('run');
    }
};