<template functional>
    <div :class="`placeholder_v2 ${props.loading ? 'placeholder_animate' : ''}`">
        <div v-for="rKey in props.rows" :key="rKey" class="placeholder_row">
            <div
                v-for="(col, cKey) in props.row"
                :key="`${rKey}-${cKey}`"
                :style="`width: calc(${col}px - 20px)`"
                class="placeholder_col"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlaceholderTable',
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        rows: {
            type: Number,
            required: false,
            default: () => 10,
        },
        row: {
            type: Array,
            required: false,
            default: () => [100]
        },
    },
};
</script>
