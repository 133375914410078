export default (i18n, toastr) => {
	return res => {
		const {config, data} = res;
		if (config.method === 'post' && config.url.includes('/refresh')) {
			if (data && data.refreshed === 1) {
				toastr.s(i18n.t('refreshing-one-msg'));
			} else {
				toastr.s(i18n.t('refreshing-many-msg'));
			}
			toastr.s(data.message);
		}
		if (config.method === 'post' && config.url.includes('attach-tags')) {
			toastr.s(data.message);
		}

		return res;
	};
};