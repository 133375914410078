import client from '../client';
import interceptors from '../interceptors';
const keywordsInterceptor = interceptors.get('keywords');

client.interceptors.response.use(
    keywordsInterceptor.s,
    keywordsInterceptor.e,
);

// works for patch/delete - provides affected by tags changing projects (with list of theirs tags)
const includeTags = {
    params: {include: 'tags'},
};

const resource = '/keywords';

const encodedId = id => encodeURIComponent(id);

export default {
    undoDeleting: async (payload, id, params = {}) => await client.patch(`/projects/${encodedId(id)}${resource}`, payload, {params: {...params, ...includeTags.params}}),
    undoDeletingFromGlobalSearch: async payload => await client.patch(`${resource}`, payload, includeTags),
    favorite: async (payload, id, params = {}) => await client.patch(`projects/${encodedId(id)}${resource}`, payload, {params}),
    fetchFavoriteData: async (payload, id) => await client.get(`projects/${encodedId(id)}${resource}/isfavorite`, payload),
    update: async (payload, id, params = {}) => await client.patch(`/projects/${encodedId(id)}${resource}`, payload, {params}),
    updateBaseline: async (projectId, keywordId, payload, params = {}) => await client.patch(`/groups/${encodedId(projectId)}/keywords/${encodedId(keywordId)}`, payload, {params}),
    updateFromGlobalSearch: async payload => await client.patch(`${resource}`, payload),
    suggestions: async payload => await client.get(`${resource}/suggestions`, payload),
    updateTags: async payload => await client.patch(`${resource}`, payload, includeTags),
    deleteKeywords: async (payload, id, params = {}) => await client.delete(`projects/${encodedId(id)}${resource}`, {...payload, params: {...params, ...includeTags.params}}),
    deleteKeywordsFromGlobalSearch: async (payload) => await client.delete(`${resource}?include=tags`, {...payload}),
    moveKeywords: async (payload, params = {}) => await client.post(`${resource}/move?include=tags`, payload, {params}),
    search: async payload => await client.get(`${resource}/search`, {params: payload}),
    refresh: async (payload, params = {}) => await client.post(`${resource}/refresh`, payload, {params}),
    attachTags: async ({payload, projectId, params = {}}) => await client.post(`projects/${encodedId(projectId)}${resource}/manage-tags`, payload, {params}),
    fetchDuplicationProgresses: async () => await client.get(`${resource}/duplication/progresses`),
    duplicateKeywords: async (payload, params = {}) => await client.post(`${resource}/duplicate`, payload, {params}),
    fetchKeywordPosition: async ( projectId, keyword_id, params) => client.get(`/projects/${projectId}/keywords/${keyword_id}/get-keyword-page-number`, params),
};
