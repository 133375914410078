var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isFreePlan)?_c('upgrade-for-access',{attrs:{"title":_vm.$t('upgrade-to-integrate-gsc.title')}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('upgrade-to-integrate-gsc.desc'))}})]):_c('div',{staticClass:"keyword-suggestions"},[(!_vm.loading && !_vm.getGSCUsernameIsFetching && !_vm.getGSCUsername)?_c('div',{staticClass:"not-authenticated empty"},[_c('div',{staticClass:"connect-google-search-console"},[_vm._v("\n            "+_vm._s(_vm.$t('connect-google-search-console-and'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"section"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"step"},[_vm._v(_vm._s(_vm.$t('step-n', { n: 1 })))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('connect-google-search-console')))])]),_vm._v(" "),_c('kw-button',{attrs:{"button-text":_vm.$t('connect-account'),"button-type":"primary"},on:{"click":function($event){return _vm.locateTo(_vm.googleAuthLink)}}})],1),_vm._v(" "),_c('div',{staticClass:"section"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"step"},[_vm._v(_vm._s(_vm.$t('step-n', { n: 2 })))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('set-rules-for-discovering')))])]),_vm._v(" "),_c('kw-button',{staticClass:"set-rules-button",attrs:{"button-text":_vm.$t('set-rules'),"button-type":"default","disabled":""}})],1)]):(
            !_vm.loading &&
            !_vm.getGSCProfilesIsFetching &&
            !_vm.getGSCProfileConnectionsIsFetching
        )?_c('div',{staticClass:"authenticated",class:{
            empty: !_vm.getGSCProfileCurrentConnection,
        }},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('active-google-search-console')))]),_vm._v(" "),_c('div',{staticClass:"header-part"},[_c('div',{staticClass:"profile-select"},[_c('v-select',{class:_vm.profilesSelectClasses,attrs:{"id":"search-console-profile","options":_vm.getGSCProfiles,"placeholder":_vm.$t('select-profile'),"search":"","value":_vm.getConnectionProfile},on:{"update:value":function($event){_vm.getConnectionProfile=$event},"change":_vm.setConnectionProfile}})],1),_vm._v(" "),(_vm.getGSCProfileCurrentConnection)?[_c('kw-button',{staticClass:"delete-profile-button",attrs:{"prepend-icon-class":"kw kw-trash-2 bold-icon","button-type":"secondary"},on:{"click":_vm.confirmDelete}}),_vm._v(" "),_c('kw-button',{staticClass:"set-rules-button",attrs:{"button-text":_vm.setEditRulesButtonText,"button-type":"primary"},on:{"click":_vm.openRulesModal}}),_vm._v(" "),(
                        !_vm.getGSCDeletedSuggestionsIsFetching &&
                        _vm.getGSCDeletedSuggestions.length
                    )?_c('div',{staticClass:"view-removed-keyword-list",on:{"click":_vm.openDeletedSuggestionsModal}},[_vm._v("\n                    "+_vm._s(_vm.$t('view-removed-keyword-suggestions-list'))+"\n                ")]):_vm._e()]:_vm._e()],2),_vm._v(" "),(_vm.getGSCProfileCurrentConnection)?_c('div',{staticClass:"table-part"},[_c('keyword-suggestions-table',{attrs:{"loading":_vm.getGSCSuggestionsIsFetching,"suggestions":_vm.getGSCSuggestions}})],1):_vm._e()]):_c('placeholder-cell',{attrs:{"width":200,"height":14}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }