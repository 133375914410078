import client from '@/api/client';
import objToQueryString from './objToQueryString';
import router from '@/routes';

/**
 * Export group to CSV
 *
 * @param {Object} project
 * @param {string} title
 */
const exportAsCSV = function (project, title, startDate, queryObj = {}, isShared = false) {
    let url = '';
    if (isShared) {
        const { name, params } = router.history.current;
        if (name === 'shareViewkeyWithHash' || name === 'shareKeywordsTableWithHash') {
            url = `${client.defaults.baseURL}projects/shared/link/${params.hash}/csv?auth=${project.auth}&password=${project.password ? project.password : ''}&${objToQueryString(queryObj)}`;
        } else {
            url = `${client.defaults.baseURL}shared/${project.shortkey}/csv?auth=${project.auth}&password=${project.password ? project.password : ''}&${objToQueryString(queryObj)}`;
        }
    } else {
        url = `${client.defaults.baseURL}projects/${project.project_id}/csv?api_token=${client.defaults.params.api_token}&${objToQueryString(queryObj)}`;
    }
    _downloadFile(`${title} ${startDate ? `from ${startDate}` : ''} ${new Date().toISOString().substr(0, 19).replace('T', ' ')}.csv`, url);
};

// Download CSV template
const downloadCSVTemplate = function () {
    downloadFile(`${client.defaults.baseURL}import/download-template?api_token=${client.defaults.params.api_token}`)
};

/**
 * Export group to CSV for the period
 *
 * @param {Object} project
 * @param {string} title
 * @param {Object} range
 */
const exportRangeAsCSV = function (project, title, range, queryObj = {}, isShared = false) {
    const { endDate, startDate } = range;
    let url = '';
    if (isShared) {
        const { name, params } = router.history.current;
        if (name === 'shareViewkeyWithHash' || name === 'shareKeywordsTableWithHash') {
            url = `${client.defaults.baseURL}projects/shared/link/${params.hash}/csv?viewkey=${project.shortkey}&auth=${project.auth}&start_date=${startDate}&end_date=${endDate}&${objToQueryString(queryObj)}`;
        } else {
            url = `${client.defaults.baseURL}shared/${project.shortkey}/csv?viewkey=${project.shortkey}&auth=${project.auth}&start_date=${startDate}&end_date=${endDate}&${objToQueryString(queryObj)}`;
        }
    } else {
        url = `${client.defaults.baseURL}projects/${project.project_id}/csv?api_token=${client.defaults.params.api_token}&start_date=${startDate}&end_date=${endDate}&${objToQueryString(queryObj)}`;
    }

    _downloadFile(
        `${title} ${startDate} - ${endDate}.csv`,
        url
    );
};

const exportRankingUrlAdCSV = (name, data) => {
    const rankingUrls = data
        .reduce((acc, cur) => acc += `${Object.keys(cur).map(key => cur[key]).join(',')}\n`, 'DATE,RANK,URL\n')
        .replace(/"/g, '""');

    _downloadFile(
        `${name}.csv`,
        new Blob([rankingUrls], { type: 'data:text/csv;charset=utf-8;' })
    );
};

const exportSerpHistoryAsCSV = (name, data) => {
    if (!data || !data.length) {
        return;
    }

    const rows = _.maxBy(data, o => o.results.length).results.length;
    let serpHistory = `${data.reduce((acc, cur) => acc += `"${cur.date}",`, '')}\n`;

    for (let i = 0; i < rows; i++) {
        serpHistory += `${data.reduce((acc, cur) => {
            const results = cur.results[i];
            acc += results ? `"${cur.results[i].url}",` : '-,';
            return acc;
        }, '')}\n`;
    }

    _downloadFile(
        `${name}.csv`,
        new Blob([serpHistory], { type: 'data:text/csv;charset=utf-8;' })
    );
};

/**
 * Export entire account data to CSV
 */
const exportAllAsCSV = function () {
    _downloadFile(
        `${new Date().toISOString().substr(0, 19).replace('T', ' ')}.csv`,
        client.defaults.baseURL + 'account/csv?api_token=' + client.defaults.params.api_token
    )
}

const exportProjectsWithMetricsAsCSV = () => {
    _downloadFile(
        `${new Date().toISOString().substr(0, 19).replace('T', ' ')}.csv`,
        client.defaults.baseURL + 'account/projects-metrics-csv?api_token=' + client.defaults.params.api_token
    )
}

const copyAsPNG = async canvas =>
    navigator.clipboard.write([
        new ClipboardItem({ 'image/png': await new Promise(res => canvas.toBlob(res, 'image/png')) })
    ]);

const exportAsPNG = async (canvas, name) =>
    _downloadFile(
        `${name}.png`,
        await new Promise(res => canvas.toBlob(res, 'image/png'))
    );

const exportAsPDF = (data, name) =>
    _downloadFile(
        `${name}_keywords_Serpbook.pdf`,
        data,
    );

const exportAsTXT = function (params, queryObj = {}, isShared = false) {

    let queryParams = '';
    let url = '';
    if (!isShared) {
        queryParams = `?api_token=${client.defaults.params.api_token}&${objToQueryString(queryObj)}`;
        if (params.project_id) {
            url = `${client.defaults.baseURL}projects/${params.project_id}/txt${queryParams}`;
        } else {
            url = `${client.defaults.baseURL}projects/global-search/txt${queryParams}`;
        }
    } else {
        const { name, params } = router.history.current;
        queryParams = `?auth=${params.auth}&${objToQueryString(queryObj)}`;
        if (name === 'shareViewkeyWithHash' || name === 'shareKeywordsTableWithHash') {
            url = `${client.defaults.baseURL}projects/shared/link/${params.hash}/txt${queryParams}`;
        } else {
            url = `${client.defaults.baseURL}shared/${params.viewkey}/txt${queryParams}`;
        }
    }
    _downloadFile(`${params.id} ${new Date().toISOString().substr(0, 19).replace('T', ' ')}.txt`, url);
};

const _downloadFile = (name, file) => {
    const downloadLink = document.createElement('a');

    downloadLink.download = name;
    downloadLink.innerHTML = 'hidden link';

    window.URL = window.URL || window.webkitURL;

    downloadLink.href = file instanceof Blob ? window.URL.createObjectURL(file) : file;
    downloadLink.onclick = _destroyClickedElement;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();
}

const downloadFile = (file) => {
    let frame = document.createElement("iframe");
    frame.style.display = 'none';
    frame.src = file;
    frame["download"] = 1;
    document.body.appendChild(frame);
}

const recurringProgressChecking = async (callback) => {
    if (await callback()) {
        setTimeout(async () => {
            recurringProgressChecking(callback);
        }, 2000);
    }
};

const _destroyClickedElement = e => document.body.removeChild(e.target);

export {
    downloadFile,
    copyAsPNG,
    exportAllAsCSV,
    exportProjectsWithMetricsAsCSV,
    exportAsCSV,
    exportAsPNG,
    exportRangeAsCSV,
    exportRankingUrlAdCSV,
    exportSerpHistoryAsCSV,
    recurringProgressChecking,
    exportAsTXT,
    exportAsPDF,
    downloadCSVTemplate,
};
