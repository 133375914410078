export const clickOutside = {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (e) {
            if (e.target.id === 'table-settings') return;
            if (e.target.classList?.contains('projects-list__item') && vnode.elm.id !== 'move-keywords') return;
            if (e.target.classList?.contains('ti-icon-close')) return;
            if (
                e.target.classList?.contains('edit-icon') ||
                e.target.classList?.contains('back-button') ||
                (e.target.parentNode && e.target.parentNode.classList?.contains('close')) ||
                e.target.classList?.contains('delete-note') ||
                (e.target.classList?.contains('v--modal-background-click') && (e.target.children && e.target.children[1] && !e.target.children[1].classList?.contains('bulk-import-modal'))) ||
                (e.target.parentNode && e.target.parentNode.classList?.contains('delete-note'))
            )
               return;

            if (!(el === e.target || el.contains(e.target))) {
                vnode.context[binding.expression](e);
            }
        };
        document.addEventListener('mousedown', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.removeEventListener('mousedown', el.clickOutsideEvent);
    }
};
