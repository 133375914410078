import client from '../client';
import fileManager from '../fileManager';
import interceptors from '../interceptors';

const importDataInterceptor = interceptors.get('importData');

fileManager.interceptors.response.use(
    importDataInterceptor.s,
    importDataInterceptor.e,
);
client.interceptors.response.use(
    importDataInterceptor.s,
    importDataInterceptor.e,
);

export default {
    uploadImportDataFile: async payload => await fileManager.post('import', payload),

    fetchInvalidRows: async id => await client.get(`import/${id}/validate-and-stream`),
    fetchImportingProgress: async id => await client.get(`import/${id}/progress`),

    updateHeaders: async (id, payload) => await client.put(`import/${id}/map-headings`, payload),
    updateInvalidRow: async (id, payload) => await client.put(`import/${id}/update`, payload),

    acceptImporting: async (id, payload) => await client.post(`import/${id}/accept`, payload),
};

