import { render, staticRenderFns } from "./TableColums.vue?vue&type=template&id=41bce66a&scoped=true&"
import script from "./TableColums.vue?vue&type=script&lang=js&"
export * from "./TableColums.vue?vue&type=script&lang=js&"
import style0 from "./TableColums.vue?vue&type=style&index=0&id=41bce66a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41bce66a",
  null
  
)

export default component.exports