<template>
    <div class="ranking-distribution">
        <div
            id="ranking-distributions-chart"
            ref="rankingDistributionsChart"
            :class="rankingDistributionsChartClasses"
        />
        <loader v-if="getMetricsIsFetching" />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Highcharts from 'highcharts';
    import NoData from 'highcharts/modules/no-data-to-display';
    import { config } from './configs/RankingDistributionsChartConfig';

    NoData(Highcharts);

    export default {
        name: 'RankingDistributionsViewkey',
        props: {
            view: {
                type: String,
                required: true,
            },
        },
        data () {
            return {
                chart: null,
            };
        },
        computed: {
            ...mapGetters([
                'getMetricsData',
                'getAverageRanksData',
                'getMetricsIsFetching',
                'getMindshareRankingDaysRange',
                'getViewkeyView',
            ]),
            rankingDistributionsChartClasses () {
                return {
                    'ranking-distributions-chart-classes': true,
                    'ranking-distributions-chart-classes_loading': this.getMetricsIsFetching,
                };
            },
        },
        watch: {
            getMetricsData () {
                this.renderChart();
            },
            view () {
                this.renderChart();
            },
            getAverageRanksData () {
                this.renderChart();
            }
        },
        mounted () {
            if(this.getViewkeyView && !this.getMetricsData.chart){
                this.fetchViewkeyMetrics().then(()=>{
                    this.renderChart();
                })
            }  
        },
        methods: {
            ...mapActions([
                'fetchViewkeyMetrics'
            ]),
            renderChart () {
                if (!this.$refs.rankingDistributionsChart
                    || !this.getMetricsData.chart
                    || !this.getMetricsData.chart.length) {
                    return;
                }

                this.chart = new Highcharts.chart(
                    'ranking-distributions-chart',
                    config(this.getMetricsData.chart, this.view, this.getMindshareRankingDaysRange, this.getAverageRanksData)
                );

                const chartBG = document.querySelector('.ranking-distribution image');

                if (chartBG) {
                    chartBG.setAttribute('preserveAspectRatio', 'xMidYMid slice');
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .ranking-distribution {
        width: 100%;
        height: 300px;
        position: relative;
        /deep/ .button-loader {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        /deep/ .highcharts-label:not(.highcharts-legend-title) {
            > span {
                background-color: rgba(255, 255, 255, 0.75);
                border-radius: 3px;
                padding: 12px;
                min-width: 220px;
            }
        }
    }
    .ranking-distributions-chart-classes {
        transition: opacity .25s ease;
    }
    .ranking-distributions-chart-classes_loading {
        opacity: .25;
    }
</style>
