<template>
    <div class="hyphen__container">
        <div
            :id="id"
            :class="hyphenClasses"
            @click.stop="hyphenClick"
            @mouseover="tooltip(getTooltipsOptions($event))"
        />
    </div>
</template>

<script>
    import ToolTipMixin from '@/mixins/ToolTipRenderMixin';
    export default {
        name: 'Hyphen',
        mixins: [ToolTipMixin],
        props: {
            hyphen: {
                type: Boolean,
                required: false,
                default: false
            },
            hyphenType: {
                type: String,
                required: false,
                default: '',
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            id: {
                type: String,
                required: false,
                default: 'hyphen-id',
            },
        },
        computed: {
            hyphenClasses () {
                return {
                    hyphen: true,
                    hyphen_abled: !this.disabled,
                    hyphen_disabled: this.disabled,
                    hyphen_active: this.hyphen,
                }
            }
        },
        methods: {
            hyphenClick () {
                this.$emit('hyphen-click');
            },
            getTooltipsOptions (event) {
                return event.target.classList.contains('hyphen_active') && this.hyphenType
                    ? { text: this.hyphenType === 'projects' ? this.$t('deselect-all') : this.$t('deselect-of-keyword'), event }
                    : { disabled: true };
            },
        },
    };

</script>

<style lang="scss">
  .hyphen__container {
    height: 16px;
    position: relative;
    width: 16px;
  }

  .hyphen {
    border-radius: 4px;
    border: 2px solid #AFBAC7;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    margin: 0;
    outline-color: transparent;
    position: absolute;
    width: 16px;
    transition: .5s all;

    &:after {
      content: '';
      height: 2px;
      left: 1px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
    }

    &:hover {
      border-color: kw-color(kw-blue, 1);
    }
  }

  // for activated
  .hyphen_abled {
    &.hyphen_active {
      background-color: kw-color(kw-blue, 1);
      border: 2px solid kw-color(kw-blue, 1);

      &:after {
        background-color: kw-color(kw-white, 1);
      }
    }
  }

  .hyphen_abled:focus {
    outline: unset;
  }

  // for disabled
  // ...
</style>
