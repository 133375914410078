let GAHelper = {};

GAHelper.install = function (Vue) {

        Vue.send = (params) => {
            if (process.env.NODE_ENV === 'development') {
                return;
            }
            window.ga(...params);
        };
};

export default GAHelper;