<template>
    <div class="stripe-component-wrap">
        <div v-if="isInputsDisabled">
            <div class="stripe_form_fileds">
                <label class="stripe_element_label element_number">
                    <span class="stripe_element_label_text">
                        {{ $t('card-number') }}
                    </span>
                    <div class="card_input_block">
                        <span class="inline">{{ cardNumberPlaceholder }}</span>
                    </div>
                </label>
                <label class="stripe_element_label element_expiry">
                    <span class="stripe_element_label_text">
                        {{ $t('card-expiry') }}
                    </span>
                    <div class="card_input_block">
                        <span class="inline">{{ cardExpiryPlaceholder }}</span>
                    </div>
                </label>
                <label class="stripe_element_label element_cvc">
                    <span class="stripe_element_label_text">
                        {{ $t('card-cvc') }}
                    </span>
                    <div class="card_input_block">
                        <span class="inline">{{ cardCvcPlaceholder }}</span>
                    </div>
                </label>
            </div>
        </div>
        <form v-else>
            <div class="stripe_form_fileds">
                <label class="stripe_element_label element_number">
                    <span class="stripe_element_label_text">
                        {{ $t('card-number') }}
                    </span>
                    <div id="cardNumber" class="card_input_block" />
                </label>
                <label class="stripe_element_label element_expiry">
                    <span class="stripe_element_label_text">
                        {{ $t('card-expiry') }}
                    </span>
                    <div id="cardExpiry" class="card_input_block" />
                </label>
                <label class="stripe_element_label element_cvc">
                    <span class="stripe_element_label_text">
                        {{ $t('card-cvc') }}
                    </span>
                    <div id="cardCvc" class="card_input_block" />
                </label>
                <label class="stripe_element_label element_billing">
                    <span class="stripe_element_label_text">
                        {{ $t('billing-details') }}
                    </span>
                    <div
                        class="card_input_block billing_details"
                        @click="checkHandleBillingDetailsModal"
                    >
                        <span class="inline" v-if="billingDetails.city">
                            <p>{{ billingDetails.company }}</p>

                            <p>{{ billingDetails.street }}</p>
                            <p>{{ billingDetails.suite }}</p>
                            <p>
                                {{ billingDetails.city }}
                                {{ billingDetails.countrystate }}
                                {{ billingDetails.postal_code }}
                                {{ billingDetails.country }}
                            </p>
                        </span>
                    </div>
                </label>
                <kw-button
                    class="button_cancel"
                    :button-text="this.$t('edit-billing-details')"
                    button-type="text"
                    @click="handleBillingDetails"
                />
            </div>

            <div v-if="getErrorMessage" class="stripe_error_block">
                {{ getErrorMessage }}
            </div>

            <div class="stripe_buttons_block">
                <kw-button
                    class="button_submit"
                    :button-text="
                        confirmButtonText || this.$t('update-credit-card')
                    "
                    button-type="primary"
                    :disabled="getIsButtonDisabled"
                    :loading="getButtonLoading"
                    @click="generateCardToken"
                />
                <kw-button
                    v-if="showCancelButton"
                    class="button_cancel"
                    :button-text="cancelButtonText || this.$t('cancel')"
                    button-type="text"
                    :disabled="getButtonLoading"
                    @click="handleCancelClick"
                />
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { loadStripe } from '@/helpers/stripeHelpers';

export default {
    name: 'StripeComponent',
    props: {
        isInputsDisabled: {
            required: false,
            default: false,
            type: Boolean,
        },
        handleSubmit: {
            required: true,
            type: Function,
        },
        loading: {
            required: false,
            default: false,
            type: Boolean,
        },
        showCancelButton: {
            required: false,
            default: false,
            type: Boolean,
        },
        handleCancelClick: {
            required: false,
            default: () => null,
            type: Function,
        },
        confirmButtonText: {
            required: false,
            default: '',
            type: String,
        },
        cancelButtonText: {
            required: false,
            default: '',
            type: String,
        },
        errorMessage: {
            required: false,
            default: '',
            type: String,
        },
        cardNumberPlaceholder: {
            required: false,
            default: '',
            type: String,
        },
        cardExpiryPlaceholder: {
            required: false,
            default: '',
            type: String,
        },
        cardCvcPlaceholder: {
            required: false,
            default: '',
            type: String,
        },
        selectedBillingInfo: {
            required: false,
            type: Object,
        },
        billingDetails: {
            required: false,
            type: Object,
            default: {},
        },
        isNotStripeUser: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            base: {
                fontSize: '13px',
                color: '#32325d',
                '::placeholder': {
                    color: '#828F9C',
                },
                ':disabled': {
                    color: '#828F9C',
                },
            },
            cardToken: '',
            zip: '',
            notApplicable: false,
            cardNumberObj: null,
            zipCodeErrors: [],
            stripe: null,
            stripeFormData: {
                isCardNumberCompleted: false,
                isCvcCompleted: false,
                isExpiryCompleted: false,
            },
            stripeFormDataErrors: {
                errorNumber: '',
                errorCvc: '',
                errorExpiry: '',
            },
            submitError: '',
            createTokenLoading: false,
            card_country: null,
        };
    },
    computed: {
        getIsButtonDisabled() {
            const {
                isCardNumberCompleted,
                isCvcCompleted,
                isExpiryCompleted,
            } = this.stripeFormData;

            const isDisabled =
                !isCardNumberCompleted ||
                !isCvcCompleted ||
                !isExpiryCompleted ||
                !!this.submitError ||
                (this.isNotStripeUser &&
                    (this.billingDetails.country === '' ||
                        this.billingDetails.postal_code === ''));
            return isDisabled;
        },
        getButtonLoading() {
            return this.createTokenLoading || this.loading;
        },
        getErrorMessage() {
            return this.submitError || this.errorMessage;
        },
    },
    mounted() {
        if (this.isInputsDisabled) {
            return;
        }
        
        this.init();
    },
    methods: {
        ...mapActions(['setupStripeIntent']),
        async init(){
            try {
                if(typeof window.Stripe !== 'function'){
                    await loadStripe();
                }
                
                this.stripe = window.Stripe(process.env.MIX_STRIPE_KEY);
                const elements = this.stripe.elements();
                const cardNumberOptions = {
                    style: this.base,
                    disabled: this.isInputsDisabled,
                };
                const cardExpiryOptions = {
                    style: this.base,
                    disabled: this.isInputsDisabled,
                };
                const cardCvcOptions = {
                    style: this.base,
                    disabled: this.isInputsDisabled,
                };
                if (this.cardNumberPlaceholder) {
                    cardNumberOptions.placeholder = this.cardNumberPlaceholder;
                }
                if (this.cardExpiryPlaceholder) {
                    cardExpiryOptions.placeholder = this.cardExpiryPlaceholder;
                }
                if (this.cardCvcPlaceholder) {
                    cardCvcOptions.placeholder = this.cardCvcPlaceholder;
                }

                const cardNumber = elements.create('cardNumber', cardNumberOptions);
                cardNumber.mount('#cardNumber');
                const cardExpiry = elements.create('cardExpiry', cardExpiryOptions);
                cardExpiry.mount('#cardExpiry');
                const cardCvc = elements.create('cardCvc', cardCvcOptions);
                cardCvc.mount('#cardCvc');
                cardNumber.addEventListener('change', event =>
                    this.handleInputChange(event, 'CardNumber'),
                );
                cardCvc.addEventListener('change', event =>
                    this.handleInputChange(event, 'Cvc'),
                );
                cardExpiry.addEventListener('change', event =>
                    this.handleInputChange(event, 'Expiry'),
                );
                this.cardNumberObj = cardNumber;
            } catch (error) {
                this.$toastr.e(this.$t('sentry-loading-error-message'));
            }
        },
        async generateCardToken() {
            this.createTokenLoading = true;
            await this.stripe.createToken(this.cardNumberObj).then(result => {
                if (result.error) {
                    this.submitError = result.error.message;
                }
                this.card_country = result.token.card.country;
            });

            const props = {
                card_country: this.card_country,
            };
            if (this.selectedBillingInfo) {
                props.plan_id = this.selectedBillingInfo.plan_id;
                props.billing_cycle = this.selectedBillingInfo.billing_cycle;
                props.trial = this.selectedBillingInfo.trial;
            }
            this.setupStripeIntent(props).then(async response => {
                this.stripe
                    .confirmCardSetup(response.data.client_secret, {
                        payment_method: {
                            card: this.cardNumberObj,
                            billing_details: {
                                // name: 'Jenny Rosen',
                            },
                        },
                    })
                    .then(result => {
                        if (result.error) {
                            this.submitError = result.error.message;
                        } else {
                            this.handleSubmit(
                                result.setupIntent.payment_method,
                            );
                        }

                        this.createTokenLoading = false;
                    });
            });
        },

        handleInputChange(event, dataName) {
            const errorName = `error${dataName}`;
            const isCompletedName = `is${dataName}Completed`;
            if (this.submitError) {
                this.submitError = '';
            }
            if (event.error) {
                this.stripeFormDataErrors = {
                    ...this.stripeFormDataErrors,
                    [errorName]: event.error.message,
                };
            } else {
                this.stripeFormDataErrors = {
                    ...this.stripeFormDataErrors,
                    [errorName]: '',
                };
            }
            if (event.complete) {
                this.stripeFormData = {
                    ...this.stripeFormData,
                    [isCompletedName]: true,
                };
            } else {
                this.stripeFormData = {
                    ...this.stripeFormData,
                    [isCompletedName]: false,
                };
            }
        },
        checkHandleBillingDetailsModal() {
            if (this.isNotStripeUser) {
                this.handleBillingDetails();
            }
        },
        handleBillingDetails() {
            this.$router.push({ name: 'AccountDetails' });
            this.$modal.hideAll();
        },
    },
};
</script>

<style lang="scss">
.stripe-component-wrap {
    .stripe_element_label_text {
        color: #17181b;
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 8px;
        font-weight: 500;
        cursor: pointer;
        display: block;
    }

    .inline {
        color: rgb(117, 117, 117);
        font-size: 14px;
        font-stretch: 100%;
        font-style: normal;
        font-weight: 400;
        word-spacing: 0px;
        p {
            margin: 0;
            color: #63697b;
        }
    }

    .stripe_form_fileds {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }

    .stripe_element_label {
        width: 50%;

        &.element_number {
            width: 100%;
            margin-bottom: 15px;
        }
        &.element_billing {
            width: 100%;
            margin-top: 15px;
        }

        &.element_expiry {
            padding-right: 4px;
        }

        &.element_cvc {
            padding-left: 4px;
        }
    }

    .card_input_block {
        border: 1px solid #dee5ec;
        border-radius: 4px;
        padding: 10px;

        &.billing_details {
            word-break: break-word;
            min-height: 50px;
        }
    }

    .button_submit {
        width: 160px;
    }

    .stripe_error_block {
        color: kw-color(kw-red, 1);
        margin-bottom: 20px;
        font-size: 13px;
        font-weight: 500;
    }
}
</style>
