<template>
    <div class="billing-plan">
        <div class="payment_method block_icon">
            <span class="kw kw-credit-card" />
        </div>
        <div class="settings_plan_title">
            {{ $t('payment-details') }}
        </div>
        <div class="settings_update_card">
            <stripe-component
                id="stripe-component-disabled"
                :handle-submit="()=>{}"
                :loading="loading"
                :isInputsDisabled="true"
                :billing-details="getBillingDetails"
                :card-number-placeholder="getCardPlaceholder('number')"
                :card-expiry-placeholder="getCardPlaceholder('expire')"
                :card-cvc-placeholder="getCardPlaceholder('cvc')"
            />
            <kw-button
                class="button_submit"
                :button-text="this.$t('update-payment-method')"
                button-type="primary"
                @click="openBillingPage"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import StripeComponent from '@/components/common/StripeComponent';

    export default {
        name: 'UpdateCard',
        components: {
            'stripe-component': StripeComponent,
        },
        data () {
            return {
                loading: false,
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentPlan',
                'getSettingIsFetching',
                'getBillingDetails',
            ]),
        },
        methods: {
            getCardPlaceholder (type) {
                const { default_card } = this.getCurrentPlan;

                switch (type) {
                    case 'number':
                        return default_card && default_card.last4
                            ? `**** **** **** ${default_card.last4}`
                            : '**** **** **** ****';

                    case 'expire':
                        return default_card && default_card.exp_year
                            ? `** / ${String(default_card.exp_year).slice(-2)}`
                            : '** / **';

                    case 'cvc':
                        return '***';

                    default:
                        return '';
                }
            },
            openBillingPage() {
                this.$router.push({name: "AccountDetails"});
            },
        },
    }
</script>

<style lang="scss" scoped>

    .create-stripe-card-block {
        p {
            margin: 16px 0;
            font-family: Roboto;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: kw-color(kw-red, 6);
        }
        .kw-button {
            padding: 8px 6px;
        }
    }

  .billing-plan {
    padding: 0px 25px 25px 25px;
  }

  .settings_update_card {
    margin-top: 20px;
  }
</style>
