<template>
    <div
        v-if="!newKeyword.isBulkImport && show"
        class="suggestions-wrap"
    >
        <keywords-popover-web
            v-if="showSuggestedKeywords"
            :add-all-suggested-into-keywords="addAllSuggestedIntoKeywords"
            data-cy="add-keyword-web-suggestions"
            :keywords="newKeyword.keywords"
            :toggle-keyword-source="toggleKeywordSource"
            :toggle-keyword="toggleKeyword"
            :isSimple="isSimple"
        />

        <div v-else-if="isSimple" class="simple">
            <p class="title">
                {{ $t('suggested-keywords') }}
            </p>
            <div class="placeholder-box">
                <div class="placeholder-row">
                    <span class="kw kw-arrow-left" />
                    {{ $t('start-typing-for-suggestions') }}
                </div>
            </div>
        </div>

        <div
            v-else
            class="empty-suggestions-wrap"
            data-cy="kw-empty-suggestions"
        >
            <div class="empty-keywords-header">
                <span>{{ $t('suggest-keywords') }}</span>
            </div>
            <div class="suggestions-placeholder">
                <span class="kw kw-arrow-left" />
                <strong>
                    {{ $t('start-typing-keywords') }}
                </strong>
                <span class="keyword-to-track">
                    {{ $t('additional-suggestions') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    const KeywordsPopoverWeb = () => import('./KeywordsPopoverWeb');
    export default {
        name: 'SuggestionsComponent',
        components: {
            'keywords-popover-web': KeywordsPopoverWeb,
        },
        props: {
            newKeyword: {
                type: Object,
                required: true
            },
            showSuggestedKeywords: {
                type: Boolean,
                required: true,
            },
            addAllSuggestedIntoKeywords: {
                type: Function,
                required: true
            },
            toggleKeyword: {
                type: Function,
                required: true
            },
            toggleKeywordSource: {
                type: String,
                required: true,
            },
            show: {
                type: Boolean,
                required: true
            },
            isSimple: {
                type: Boolean,
                default: false
            }
        },
    }
</script>
<style lang="scss">
    .simple {
        display: flex;
        flex-direction: column;
        height: 100%;

        .title {
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            color: #8B91A2;
            margin-bottom: 4px;
        }
    }
    .suggestions-placeholder {
        strong {
            margin-right: 4px;
        }
        .keyword-to-track {
            line-height: 15px;
        }
    }

    .placeholder-box{
        width: 100%;
        height: 100%;
        border-radius: 4px;
        padding: 10px;
        background-color: #F5F6F7;
        .placeholder-row{
            display: inline-flex;
            align-items: center;
            gap: 10px;
            font-size: 13px;
            color: #8B91A2;
        }
    }
</style>
