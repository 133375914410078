import {MOBILE_WIDTH_RESOLUTION_PHONE} from '@/constants';
import {switchDateFormat} from '../../../helpers/format-date-service';
// import backgroundImage from 'img/chart-background.svg';
// import i18n from '@/i18n';

const colors = [
    '#B1B7BD',
    '#B6C6E4',
    '#EDC399',
    '#F1BE83',
    '#569B3D',
    '#ADDA95',
    '#BE4135',
    '#ED9F9B',
    '#866DB5',
    '#DEDCDF',
];

const mapSeriesData = data => {
    return data.map((value) => {
        return {
            color: colors[value.rank -1],
            data: value.data.map(value => parseFloat(value)),
            marker: {symbol: 'circle'},
            name: value.domain,
            stringData: value.data,
        };
    });
};

const config = (data, categories, styles) => ({
    chart: {
        animation: false,
        height: styles?.height || 400,
        // plotBackgroundImage: data && data.length ? backgroundImage : null,
        style: {fontFamily: 'Roboto'},
        events: {
            load: function () {
                Array.from(document.querySelectorAll('.top-competitors__chart .highcharts-legend-item path.highcharts-point')).forEach(el => {
                    el.style.display = 'none';
                });
                Array.from(document.querySelectorAll('.top-competitors__chart .highcharts-legend-item path.highcharts-graph')).forEach(el => {
                    el.setAttribute('d', 'M7,7 h5 a2,2 0 0 1 2,2 v5 a2,2 0 0 1 -2,2 h-5 a2,2 0 0 1 -2,-2 v-5 a2,2 0 0 1 2,-2 z');
                    el.setAttribute('fill', el.getAttribute('stroke'));
                });
            },
            redraw: function () {
                this.update({legend: {enabled: window.screen.width <= MOBILE_WIDTH_RESOLUTION_PHONE}}, false);
                Array.from(document.querySelectorAll('.top-competitors__chart .highcharts-legend-item path.highcharts-point')).forEach(el => {
                    el.style.display = 'none';
                });
                Array.from(document.querySelectorAll('.top-competitors__chart .highcharts-legend-item path.highcharts-graph')).forEach(el => {
                    el.setAttribute('d', 'M7,7 h5 a2,2 0 0 1 2,2 v5 a2,2 0 0 1 -2,2 h-5 a2,2 0 0 1 -2,-2 v-5 a2,2 0 0 1 2,-2 z');
                    el.setAttribute('fill', el.getAttribute('stroke'));
                });
            }
        }
    },
    credits: {
        href: 'https://app.keyword.com/',
        text: '',
    },
    exporting: {enabled: false},
    legend: {
        align: 'left',
        enabled: false,
        // enabled: window.screen.width <= MOBILE_WIDTH_RESOLUTION_PHONE,
        // verticalAlign: 'top',
        // itemStyle: {
        //     fontFamily: 'Roboto',
        //     fontSize: '10px',
        //     fontWeight: 400,
        // },
        // title: {
        //     text: i18n.t('domain-site'),
        //     style: {
        //         fontFamily: 'Roboto',
        //         fontWeight: 600,
        //         fontSize: '10px',
        //     },
        // },
    },
    plotOptions: {
        series: {
            marker: {
                enabled: false
            }
        }
    },
    series: mapSeriesData(data),
    title: {text: ''},
    tooltip: {
        backgroundColor: '#fff',
        borderColor: '#AEBBC8',
        shadow: {
            color: 'rgba(0, 0, 0, 0.25)',
            offsetX: 0,
            offsetY: 1,
            width: 4,
        },
        shared: true,
        style: {opacity: 0.75},
        useHTML: true,
        crosshairs: {
            color: '#CCD2DB',
            width: 1,
        },
        formatter: function() {
            return this.points
                .sort((a, b) => b.y - a.y)
                .reduce(
                    (acc, cur) => {
                        const row = `
                            ${acc}
                            <div class="top-competitors-chart__label-item">
                                <div>
                                    <i style="background-color: ${cur.series.color}"></i>
                                    ${cur.series.name}
                                </div>
                                <div>${cur.series.userOptions.stringData[cur.point.index]}</div>
                            </div>
                        `;

                        return row;
                    },
                    `
                        <div class="top-competitors-chart__label-header">
                            ${this.x === 'latest' ? this.x : switchDateFormat(new Date(this.x), 'l F jS, Y')}
                        </div>
                    `,
                );
        },
    },
    xAxis: {
        categories,
        labels: {
            formatter: function () {
                return this.value === 'latest' ? this.value : switchDateFormat(new Date(this.value), 'chart-date');
            },
        },
    },
    yAxis: {
        title: {enabled: false},
        labels: {format: '{value}%'},
        opposite: styles?.yOpposite ?? false
    },
});

export {config};
