<template>
    <div data-cy="users-wrapper" class="users-wrapper">
        <router-view class="users-container" />
    </div>
</template>

<script>
export default {
    name: 'Users',
};
</script>

<style lang="scss" scoped>
.users-wrapper {
    min-height: 100vh;
    width: 100%;
    height: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background: no-repeat url(~img/users-background.svg) center center;
    background-size: cover;

    .users-container {
        border-radius: 24px;
        box-shadow: 0px 0px 1px 0px rgba(50, 73, 98, 0.1),
            0px 12px 28px 0px rgba(50, 73, 98, 0.1);
        background: white;
        overflow: hidden;

        &.auth {
            width: 100%;
            max-width: 980px;
            // max-width: 596px;
        }

        &.info {
            width: 634px;
            display: inline-flex;
            padding: 42px 40px 32px 40px;
            flex-direction: column;
            align-items: center;
            gap: 24px;
        }
    }

    @media (max-width: 980px) {
        .users-container {
            &.auth {
                max-width: 506px;
            }
        }
    }
}
</style>
