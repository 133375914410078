<template>
    <div class="shared-keywords-table">
        <project-keywords-and-charts />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoginForViewkey from '@/components/modals/LoginForViewkey';
import ProjectKeywordsAndCharts from '@/components/main-block/components/ProjectKeywordsAndCharts';

export default {
    name: 'ShareKeywordsTable',
    props: {
        viewkey: {
            type: String,
            required: false,
            default: '',
        },
        auth: {
            type: String,
            required: false,
            default: '',
        },
        hash: {
            type: String,
            required: false,
            default: '',
        },
        client: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        'project-keywords-and-charts': ProjectKeywordsAndCharts,
    },
    computed: {
        ...mapGetters(['getViewkeyAccessStatus']),
        showViewkey() {
            return (
                this.getViewkeyAccessStatus &&
                this.getViewkeyAccessStatus !== 'denied'
            );
        },
    },
    watch: {
        showViewkey(val) {
            this.openLoginModalForViewkey(val);
        },
    },
    async created() {
        this.setIsViewkeyView(true);
        await this.getViewkeyInfo({
            shortkey: this.viewkey,
            id: this.auth,
            client: this.client,
        });

        const {
            name: routeName,
            params: routeParams,
        } = this.$router.currentRoute;
        if (routeName === 'shareOfVoiceWithHash') {
            await this.getTagDataForShareAction(routeParams.hash);
        }
        this.openLoginModalForViewkey(this.showViewkey);
    },
    beforeCreate() {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'robots');
        meta.setAttribute('content', 'noindex');
        document.getElementsByTagName('head')[0].append(meta);
    },
    methods: {
        ...mapActions(['setIsViewkeyView', 'getTagDataForShareAction', 'getViewkeyInfo']),
        openLoginModalForViewkey(val) {
            if (!val) {
                this.$modal.show(
                    LoginForViewkey,
                    {
                        viewkey: this.viewkey,
                        auth: this.auth,
                        client: this.client,
                    },
                    {
                        width: 282,
                        height: 'auto',
                        classes: 'v--modal v--modal_login-viewkey',
                        clickToClose: false,
                        name: 'loginForViewkeyModal',
                    },
                );
            } else {
                this.$modal.hide('loginForViewkeyModal');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.shared-keywords-table {
    padding-top: 33px;
    /deep/ .keywords-table__header_fixed {
        top: 0 !important;
    }
}
</style>
