const enBilling = {
    'billing-subscription-error': 'Your subscription has expired. Please contact support for assistance.',
    'export-more-keywords-error': 'You cannot export more than 70,000 keywords. Contact support for assistance.',
    'plan-already-set': 'You are choosing the same package as having been currently used. Please choose another plan',
    'please-renew-subscription': 'Please, renew your subscription to continue using Serpbook',
    'you-do-not-have-active-subscription': 'You do not have an active subscription, please select a package below.',
    'billing-discount': '{discount}% Discount coupon applied',
    'dismiss': 'Dismiss',
    'billing-details-need-updating': 'Your billing details need updating.',
    'billing-details-missing-msg': 'We have noticed that your profile is missing certain mandatory billing details like country or zip code.',
    'ensure-taxes-msg': 'To ensure the correct taxes (if applicable) are applied, please update your billing details.',
    'package': '{title} Package',
    'package-info': {
        'title': '{title} Package',
        'kw-tracked': '<b>{keywords_tracked} Keywords</b> tracked <b>{cycle}</b>',
        'kw-on-demand': '{count} {cycle} On demand updates',
        'websites': 'Unlimited websites',
        'local-GMB': 'Local GMB tracking',
        'google-maps': 'Google maps tracking',
        'mobile': 'Mobile tracking',
        'users': 'Unlimited users',
        'GSC': 'Google Search Console integration',
        'GA': 'Google Analytics integration',
        'GLS': 'Google Looker Studio integration',
        'reports': 'Branded reports',
        'SOV': 'Share of Voice',
        'suggestions': 'Keyword suggestions',
        'API': 'API access',

        'on_demand_updates': '{count} {cycle} On demand updates',
        'unlimited_websites': 'Unlimited websites',
        'local_gmb_tracking': 'Local GMB tracking',
        'google_maps_tracking': 'Google maps tracking',
        'mobile_tracking': 'Mobile tracking',
        'unlimited_users': 'Unlimited users',
        'google_search_console_integration': 'Google Search Console integration',
        'google_analytics_integration': 'Google Analytics integration',
        'google_looker_studio_integration': 'Google Looker Studio integration',
        'branded_reports': 'Branded reports',
        'share_of_voice': 'Share of Voice',
        'keyword_suggestions': 'Keyword suggestions',
        'api_access': 'API access',
        'white_label_reports': 'White label reports',
    },
    'permissions': {
        'kw-tracked': '{keywords_tracked} Keywords tracked {cycle}',
        'on_demand_updates': '{count} On demand updates',
        'unlimited_domains': 'Unlimited domains',
        'customer_support': 'Customer support',
        'api_access': 'API access',
        'unlimited_add_ons': 'Unlimited add-on users with permissions',
        'share_of_voice': 'Share of Voice',
        'serp_history': 'SERP history',
        'mobile_tracking': 'Mobile tracking',
        'spyglass': 'Spyglass',
        'suggestions': 'Keyword suggestions',
        'search_intent': 'Search intent',
        'serp_features': 'SERP features',
        'custom_alerts': 'Custom alerts',
        'local_rank_tracking': 'Local rank tracking',
        'viewkey_reports': 'ViewKey real-time reports',
        'custom_viewkey_domain': 'Custom ViewKey domain',
        'scheduled_reports': 'Scheduled branded email reports',
        'google_integrations': 'Google Analytics, Google Search Console & Looker Studio integration',
        'dashthis_integration': 'DashThis integration',
        // old keys
        'websites': 'Unlimited websites',
        'local_gmb_tracking': 'Local GMB tracking',
        'google_maps_tracking': 'Google maps tracking',
        'unlimited_users': 'Unlimited users',
        'google_analytics_integration': 'Google Analytics integration',
        'google_search_console_integration': 'Google Search Console integration',
        'google_looker_studio_integration': 'Google Looker Studio integration',
        'branded_reports': 'Branded reports',
        'white_label_reports': 'White label reports',
    },
    'pricing-info': {
        'coupon': 'Coupon - {coupon}',
        'your-trial': 'Your Trial',
        'total-now': 'Total due now',
        'total-after': 'Total due after trial',
        'billing-cycle': {
            'monthly': '@:common.monthly-short',
            'annually': '@:common.annually-short',
        },
    },
    'trial-expires': {
        'title': 'Trial ended! Subscribe to continue tracking your Keywords.',
        'desc': 'To avoid automatic deletion of your account, please make a selection within 30 days of your trial ending.',
        'note': 'If no selection is made within 24 hours of your trial expiring, your account will automatically be downgraded to the Free Package.',
        'package': {
            'move': {
                'title': 'Move to Free Package',
                'button': 'Move To Free Plan',
            },
            'keep': {
                'title': 'Keep Current Package',
                'desc': '{keywordsTracked} Keywords with {cycle} Updates',
                'button': 'Subscribe Now',
                'change-package': 'Change Package',
                'choose-package': 'Choose a Package'
            },
        },
    },
    'keywords-exceeded': {
        'title': 'Some of your Keywords are not being updated',
        'desc-1': 'You have too many Keywords for the current package you are on. This has resulted in some of your Keywords not being updated.',
        'desc-2': 'To start tracking all your Keywords again upgrade to a higher package or remove some Keywords.',
    },
    'pricing': {
        'header': {
            'note': 'Our Pricing',
            'main': 'See your rankings climb, not your billing',
            'desc': 'Get the accuracy all-in-one tools can’t provide, at a fraction of what they are charging you.',
        },
        'cycle-picker': {
            'header': 'Plan',
        },
        'permissions': {
            'headers': {
                'rank-tracking': 'Rank tracking',
                'local-rank-tracking': 'Local rank tracking',
                'reporting': 'Reporting',
                'integrations': 'Integrations',
            },
            'rows': {
                'automatic-ranking-updates': 'Automatic ranking updates',
                'on-demand-ranking-updates': 'On-demand ranking updates',
                'unlimited-domains-tracked': 'Unlimited domains tracked',
                'customer-support': 'Customer support',
                'dedicated-account-manager': 'Dedicated account manager',
                'api': 'API',
                'add-on-users': 'Add-on users',
                'user-permissions': 'User permissions',

                'top-100-google-serps': 'Top 100 Google SERPs',
                'share-of-voice': 'Share of voice',
                'serp-history': 'SERP history',
                'mobile-tacking': 'Mobile tracking',
                'spyglass': 'Spyglass',
                'keyword-suggestions': 'Keyword suggestions',
                'search-intent': 'Search intent',
                'serp-features': 'SERP features',
                'keyword-competition': 'Keyword competition',
                'custom-alerts': 'Custom alerts',
                'keyword-organization': 'Keyword organization',
                'search-volume': 'Search volume',
                'cpc': 'CPC',
                'ctr': 'CTR',
                'data-import': 'Data Import',

                'local-keyword-tracking': 'Local keyword tracking',
                'google-business-profile-tracking': 'Google Business Profile tracking',
                'google-maps-tracking': 'Google Maps tracking',

                'viewkey-real-time-reports': 'ViewKey real-time reports',
                'custom-viewkey-domain': 'Custom ViewKey domain',
                'white-label-viewkey-domain': 'White-label ViewKey domain',
                'scheduled-email-reports': 'Scheduled email reports',
                'pdf-csv-exports': 'PDF/CSV exports',
                'branded-exports': 'Branded reports',

                'google-analytics': 'Google Analytics',
                'google-search-console': 'Google Search Console',
                'google-looker-studio': 'Google Looker Studio',
                'dash-this': 'DashThis',
            },
            'tooltips': {
                'automatic-ranking-updates': 'Based on your chosen package, your keywords will refresh regularly to reflect the latest ranking trends.',
                'on-demand-ranking-updates': 'Refresh your keyword rank results in under 2-3 minutes and see real-time performance.',
                'unlimited-domains-tracked': 'Monitor an unlimited number of domains within a project or group.',
                'customer-support': 'Enjoy exceptional customer support available 7 days a week for all your needs!',
                'dedicated-account-manager': 'Experience personalized assistance with a dedicated account manager assigned to you.',
                'api': "Integrate Keyword.com's API for seamless access to advanced keyword research and analysis, boosting your apps and services.",
                'add-on-users': 'Add colleagues to manage accounts and collaborate efficiently.',
                'user-permissions': 'Give team members access, manage their permissions with ease.',

                'top-100-google-serps': 'See what is happening in the top 100 results of the Google SERPs with every update.',
                'share-of-voice': "Gain insight into your brand's market visibility relative to competitors. Share of Voice analysis reveals your presence and influence, offering actionable insights to expand your reach and enhance your impact.",
                'serp-history': "Explore SERP History in Historical Charts to track your keyword's performance over time, comparing it with competitors.",
                'mobile-tacking': 'Get ranking data for mobile devices to gain insights into mobile-specific performance and optimize your digital presence accordingly.',
                'spyglass': 'Spyglass reveals a saved snapshot of the exact search results we collected, giving you peace of mind and transparency.',
                'keyword-suggestions': "Discover keywords you're ranking for in Google but aren't currently tracked within the app's monitoring system.",
                'search-intent': "Uncover searchers' true intentions with Search Intent. Craft content that hits the spot, boosting traffic & conversions.",
                'serp-features': 'View the SERP features present in Google search results and identify those in which you are featured.',
                'keyword-competition': 'Assess ad competitiveness for selected keywords and locations with this metric.',
                'custom-alerts': "Schedule email updates to receive instant notifications about significant ranking changes, ensuring you're always aware of vital shifts in your performance.",
                'keyword-organization': 'Enhance your keyword management by organizing them into groups or tags. This allows for more meaningful metrics, enabling focused analysis on specific niches or projects.',
                'search-volume': 'Gain insights into keyword popularity, helping you understand the frequency of searches for specific terms.',
                'cpc': 'Discover the average cost per click (CPC) for the selected keyword over the past 30 days Reported in US Dollars ($), currency customization per project allows for personalized reporting tailored to your needs.',
                'ctr': 'Connect your Google Search Console account to access keyword Click-Through Rate (CTR) data, providing valuable insights into user engagement with your search listings.',
                'data-import': 'Bulk import your keyword data in CSV.',

                'local-keyword-tracking': 'Specify your desired location, from country-level to ZIP/postal code, to accurately monitor keyword rankings tailored to your target audience.',
                'google-business-profile-tracking': 'Monitor the performance of your Google My Business profile directly within Google SERPs. Gain insights into your local search visibility and track the effectiveness of your business listings seamlessly.',
                'google-maps-tracking': 'Track your Google My Business (GMB) listing within Google Maps results. Monitor your presence and performance directly on the map to ensure visibility and engagement with potential customers.',

                'viewkey-real-time-reports': 'Easily share real-time rank metrics with stakeholders. Share project, group, or tag insights with customers or team members, including Share of Voice and Top Pages via a secure and accessible link.',
                'custom-viewkey-domain': 'Tailor your ViewKey reports with custom subdomains for personalized branding and accessibility.',
                'white-label-viewkey-domain': 'Deliver a seamless and branded experience to your stakeholders with personalized subdomains and a fully branded ViewKey domain for live report access. Extra costs apply for this option.',
                'scheduled-email-reports': 'Effortlessly schedule email reports to send to your customers or stakeholders. Customize these reports with your branding for a professional touch.',
                'pdf-csv-exports': 'Export your ranking results and metrics to PDF, CSR of Text with all history.',
                'branded-exports': "Add your company's logo, colors, and style to create professional and personalized reports tailored to your brand identity.",

                'google-analytics': "Connect seamlessly to view GA4's Traffic Acquisition > Users by Organic Searches data over time directly within the Project Overview dashboard.",
                'google-search-console': 'Gain access to additional valuable metrics including Impressions, Click-Through Rate (CTR), and Clicks, providing deeper insights into your search performance.',
                'google-looker-studio': 'Use your Looker Studio connector to gain deeper insights and enhance decision-making with powerful reporting tools directly within the app.',
                'dash-this': 'See your keyword ranking data directly in your DashThis dashboard.',
            },
            'values': {
                'weekly': '@:common.weekly',
                'wdb': 'Weekly, daily or bi-hourly',
                'unlimited': '@:common.unlimited',
                '6-months': '6-months',
                '2-years': '2-years',
            }
        },
    },
    'packages': {
        'free': {
            'title': 'Free Forever',
            'desc': 'For individuals wanting to get started with rank tracking. Track up to 10 keywords for free.',
            'btn': '@:common.choose-package',
        },
        'business': {
            'title': '@:common.business',
            'desc': 'For growing Agencies and Businesses that need to track their SEO efforts.',
            'btn': '@:common.choose-package',
            'cycle': {
                'title': 'Ranking update frequency',
                'weekly': '@:common.weekly',
                'daily': '@:common.daily',
                'hourly': '@:common.bi-hourly',
            },
        },
        'enterprise': {
            'title': '@:common.enterprise',
            'desc': 'For large SEO Agencies and Teams with advanced rank tracking needs. ',
            'btn': '@:contact-us',
        },
    },
    'plan-features': '{plan} Plan Features',
    'see-details': 'See Details',
    'read-more': 'Read more',
    'talc-to-us': 'Talk to us for',
    'custom-pricing': 'Custom Pricing',
    'sentry-loading-error-message': 'We are currently unable to retrieve your billing details. Please try again later.'
};

export default enBilling;
