<template>
    <div
        :class="dashboardOverviewClasses"
        @click="clickHandler"
        @mouseover="mouseoverHandler"
    >
        <page-header
            text="Dashboard"
            type="overview"
        />

        <overview-actions
            :get-overview-search="getOverviewSearch"
            :search-projects="searchProjects"
            :sort-by-changes="getSortByChanges"
            :toggle-sort-by-changes="toggleSortByChanges"
            :type="getOverviewView"
            :view-list="list"
            :view-value="getOverviewView"
        />

        <metrics
            v-if="getDisplaySetting('metrics') !== 'hide'"
            :data="getAllProjectsMetricsData"
            :loading="getMetricsIsFetching"
        />

        <component :is="`overview-${getOverviewView}-view`" />
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import { Events, EventBus } from '@/events';
    import {viewList} from '@/configs/overviewConfigs';
    import Metrics from 'components/metrics/Metrics';
    import OverviewActions from './components/OverviewActions';
    import OverviewChartView from './components/OverviewChartView';
    import OverviewTableView from './components/OverviewTableView';
    import PageHeader from 'components/ui-elements/PageHeader';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    export default {
        name: 'Overview',
        components: {
            'metrics': Metrics,
            'overview-actions': OverviewActions,
            'overview-chart-view': OverviewChartView,
            'overview-table-view': OverviewTableView,
            'page-header': PageHeader,
        },
        mixins: [ToolTipRenderMixin],
        data () {
            return {
                list: viewList,
            };
        },
        computed: {
            ...mapGetters([
                'getPaginatedProjectsWithMetrics',
                'getAllProjectsMetricsData',
                'getOverviewDataLoading',
                'getCheckedProjects',
                'getDisplaySetting',
                'getMetricsIsFetching',
                'getOverviewSearch',
                'getOverviewView',
                'getSortByChanges',
            ]),
            dashboardOverviewClasses () {
                return {
                    'dashboard-overview': true,
                    'dashboard-overview_action-bar': this.getCheckedProjects.length,
                };
            },
        },
        watch: {
            getOverviewView (to) {
                if(to === 'chart') {
                    if (this.getOverviewSearch) {
                        this.searchProjects('');
                    }
                }
            }
        },
        mounted () {
          if (this.getDisplaySetting('metrics') !== 'hide') {
            this.fetchAllProjectsMetricsData();
          }
          this.emitDashboardViewEvent();
        },
        destroyed () {
            if (this.getOverviewSearch) {
                this.searchProjects('');
            }
        },
        methods: {
            ...mapActions([
                'fetchAllProjectsMetricsData',
                'overviewSorterChanged',
                'toggleSortByChanges',
                'searchProjects',
            ]),
            clickHandler (e) {
                const el = e.target;
                const event = el.dataset.event;
                const sorter = el.dataset.sorter;

                switch (event) {
                    case 'sort':
                        if (sorter === 'none') {
                            return;
                        }

                        this.overviewSorterChanged(sorter);
                        break;
                }
            },
            emitDashboardViewEvent() {
                if (this.getOverviewDataLoading) {
                    setTimeout(this.emitDashboardViewEvent, 1000);
                } else {
                    EventBus.emit(Events.DASHBOARD_VIEW_EVENT, {
                        num_projects_shown: this.getPaginatedProjectsWithMetrics
                            .length,
                    });
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
  .dashboard-overview {
    padding-bottom: 65px;
    width: 100%;

    @media (max-width: 959px) {
      .page-header {
        padding: 25px 16px 15px 16px;
      }

      .dashboard-overview__inner {
        padding: 0 16px;
      }

      .dashboard-metrics-wrap {
        padding: 0 16px;
      }
    }

    @media (max-width: 768px) {
      /deep/ .overview-actions {
        justify-content: flex-start;
        position: relative;

        .overview-actions__label {
          position: absolute;
          top: 214px;
        }
      }

      /deep/ .overview-actions__export-wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
      }

      .dashboard-overview__inner {
        &.overiview-table__wrapper {
          margin-top: 52px;
          padding: 0 0;
        }
      }
    }

    @media (max-width: 500px) {
      .dashboard-overview__inner {
        margin: 8px auto 0 auto;
      }
    }
  }

  .dashboard-overview_action-bar {
    padding-bottom: 70px;
  }
</style>
