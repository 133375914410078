<template>
    <component
        :is="type"
        :close="close"
    />
</template>

<script>
    import ProjectSettings from 'components/settings/ProjectSettings';
    import EmailSettings from 'components/settings/EmailSettings';
    import PDFSettings from 'components/settings/PDFSettings';
    export default {
        name: 'SettingsModal',
        components: {
            'project-settings': ProjectSettings,
            'email-settings': EmailSettings,
            'pdf-settings': PDFSettings,
        },
        props: {
            type: {
                type: String,
                required: true,
            },
        },
        methods: {
            close () {
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss">
    @import '~sass/modal';
</style>
