
export function checkIsKeywordRecentlyAdded(keyword) {
  // { attributes: { updated_at } }
  if (!keyword?.attributes?.updated_at) return;
  const dateAdded = new Date(keyword.attributes.updated_at);
  // add one day to find out if keywords have been created in the last 24 hours
  dateAdded.setDate(dateAdded.getDate() + 1);
  return dateAdded > new Date();
}

export function getLatestTimestamp(projectList) {
  return projectList.reduce((latestTimestamp, current) => {
      // Convert updated_at to Unix timestamp (if it's an ISO string)
      const updatedAtTimestamp = new Date(current.attributes.updated_at).getTime() / 1000; // Convert milliseconds to seconds
      // Use crawled_at directly (assuming it's already in Unix timestamp format)

      // Get the later timestamp
      const currentLatestTimestamp = Math.max(current.attributes.crawled_at, updatedAtTimestamp);

      // Compare with the latest timestamp found so far
      return Math.max(latestTimestamp, currentLatestTimestamp);
  }, 0); // Initialize with 0 or a very low value
}
