import i18n from '@/i18n';

const viewsOptions = [
    { value: 'percentage', label: `% <span>${i18n.t('percentage')}</span>` },
    { value: 'count', label: `# <span>${i18n.t('count')}</span>` },
];

const competitorsTableConfig = [
    { key: 'checkbox', width: '28px', unionToColumn: 'right', align: 'center' },
    { key: 'rank', title: i18n.t('rank'), link: false, width: '43px', align: 'flex-start' },
    {
        key: 'domain',
        title: i18n.t('domain'),
        link: true,
        unionToColumn: 'left',
        width: 'calc(100% - 230px)',
    },
    { key: 'share', title: i18n.t('share'), link: false, width: '70px', align: 'flex-end', type: 'chart' },
    {
        key: 'change',
        title: `% ${i18n.tc('change', 2)}`,
        link: false,
        width: '130px',
        align: 'flex-start',
        type: 'html',
    },
    
];

export { viewsOptions, competitorsTableConfig };
