var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pdf-project-table-container"},[_c('table',[_c('thead',[_c('tr',[_vm._l((_vm.getTableColumns),function(item,i){return [(item.name !== 'checkbox')?_c('th',{key:i,class:_vm.getTableHeaderClass(item.name)},[_vm._v("\n                        "+_vm._s(item.title)+"\n                    ")]):_vm._e()]})],2)]),_vm._v(" "),_c('tbody',[_vm._l((_vm.items),function(keyword,index){return [(_vm.title(keyword, index))?_c('tr',{key:index + '_row_title',staticClass:"table-row__title"},[_c('td',{domProps:{"innerHTML":_vm._s(_vm.title(keyword, index))}})]):_vm._e(),_vm._v(" "),_c('tr',{key:index,class:_vm.tableRowClasses(keyword, index)},[_vm._l((_vm.getTableColumns),function(item,i){return [(item.name !== 'checkbox')?_c('td',{key:i,class:_vm.getTabledColumnClasses(item)},[(_vm.simpleFields(item.name))?[(_vm.isPeriodRow(item.name))?[_c('span',{class:_vm.periodIconClasses(item, keyword)}),_vm._v(" "),_c('span',{staticClass:"period-value",domProps:{"innerHTML":_vm._s(
                                            _vm.processedField(
                                                item.name,
                                                keyword
                                            )
                                        )}})]:_c('span',{domProps:{"innerHTML":_vm._s(
                                        _vm.processedField(item.name, keyword)
                                    )}})]:(_vm.geoFields(item.name))?_c('div',{staticClass:"table-cell"},[_c('div',{staticClass:"table-cell__content table-cell__content_cut"},[_c('div',{staticClass:"table-cell__text",domProps:{"textContent":_vm._s(keyword[item.name])}})])]):(item.name === 'grank')?_c('div',{staticClass:"table-cell"},[(keyword.in_featured)?_c('div',{staticClass:"table-cell__icon"},[_c('span',{staticClass:"kw kw-featured-snippet"})]):_vm._e(),_vm._v(" "),(keyword.in_local_pack)?_c('div',{staticClass:"table-cell__icon"},[_c('span',{staticClass:"kw kw-flag"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"table-cell__content table-cell__content-grank"},[_c('div',{staticClass:"table-cell__text table-cell__text_ranks",domProps:{"innerHTML":_vm._s(
                                            _vm.processedField(
                                                item.name,
                                                keyword
                                            )
                                        )}})])]):_c(((item.name) + "-field"),{tag:"component",attrs:{"value":_vm.keywordForStart.start,"keyword":keyword,"is-multi-domain":_vm.isMultiDomain,"is-view-key":!_vm.getViewkeyView && !_vm.isViewOnlyUser},on:{"update:value":function($event){return _vm.$set(_vm.keywordForStart, "start", $event)}}}),_vm._v(" "),(item.name === 'kw')?_c('span',{staticClass:"kw kw-favorite"}):_vm._e()],2):_vm._e()]})],2)]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }