<template>
    <div class="sidebar-search-wrap">
        <div class="search-block">
            <input
                v-model="search"
                class="text-input text-input_search-project"
                data-cy="sidebar-search"
                :placeholder="$t('search')"
                type="text"
                @input="$emit('search-changed', search)"
            >
            <span class="kw kw-search" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Search',
        props: {
            defaultSearch: {
                type: String,
                required: false,
                default: '',
            },
        },
        data () {
            return {
                search: '',
            }
        },
        watch:{
            'defaultSearch': function(newVal) {
                this.search = newVal;
            }
        },
        created () {
            if (this.defaultSearch) {
                this.search = this.defaultSearch;
            }
        }
    };
</script>

<style lang="scss">
    .sidebar-search-wrap {
        width: 100%;
        .kw-search {
            color: #828F9C;
            font-size: 12px;
            font-weight: 700;
            left: 12px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .search-block {
        display: flex;
        text-align: center;
        position: relative;
        .text-input_search-project {
            display: inline-block;
            width: 100%;
            padding-left: 30px;
            background: #E6E9EC;
            border-color: #DEE5EC;

            &:disabled {
                & ~ .kw-search {
                    &:before {
                        color: #7b7e86;
                    }
                }
            }
        }
    }
</style>