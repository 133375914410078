<template>
    <div
        v-if="!socialLoading && currentPackage"
        data-cy="signup-form"
        class="signup-form"
    >
        <div class="package-note">
            <template v-if="isFreePackage">
                <div v-html="$t('auth-note.title.free')" />
            </template>
            <div v-else>
                <div v-if="isTrial">
                    {{
                        $t('auth-note.title.paid-trial', {
                            count: currentPackage.keywords_tracked,
                        })
                    }}
                    <p>{{ $t('auth-note.msg') }}</p>
                </div>
                <span
                    v-else
                    class="paid-title"
                    v-html="
                        $t('auth-note.title.paid', {
                            ...currentPackage,
                            cycle: currentPackageCycle,
                            type: $t(
                                `common.${
                                    isEnterprise ? 'enterprise' : 'business'
                                }`,
                            ),
                        })
                    "
                >
                </span>
            </div>
        </div>
        <social-sign-in
            :open-social-sign-in="openSocialSignIn"
            :title="$t('sign-up-with')"
            :social-media-list="INTEGRATED_SOCIAL_MEDIA_NAMES"
        />
        <divider :text="$t('or')" />
        <form :novalidate="true" @submit.prevent="validateForm">
            <div class="form-group">
                <kw-input-field
                    id="login"
                    name="email"
                    type="email"
                    data-cy="login"
                    class="text-input"
                    :label="$t('signup-form.email')"
                    placeholder="email@email.com"
                    v-model.trim="username"
                    v-validate="'required|email'"
                    :error-data="emailFieldError"
                />
            </div>
            <div class="form-group password-wrapper">
                <kw-input-field
                    id="password"
                    name="password"
                    data-cy="password"
                    class="text-input"
                    :type="fieldType"
                    :label="$t('signup-form.password')"
                    placeholder="••••••••"
                    v-model.trim="password"
                    v-validate="'required||max:32|include_special_character'"
                    :error-data="passwordFieldError"
                />
                <div
                    @click="togglePasswordVisibility"
                    class="password-visibility"
                >
                    <div>
                        <span :class="`kw ${eyeIcon}`" />
                    </div>
                </div>
                <div class="password-hint">
                    <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8 0.5C12.4062 0.5 16 4.09375 16 8.5C16 12.9375 12.4062 16.5 8 16.5C3.5625 16.5 0 12.9375 0 8.5C0 4.09375 3.5625 0.5 8 0.5ZM11.5938 7.125C11.9375 6.78125 11.9375 6.25 11.5938 5.90625C11.25 5.5625 10.7188 5.5625 10.375 5.90625L7 9.28125L5.59375 7.90625C5.25 7.5625 4.71875 7.5625 4.375 7.90625C4.03125 8.25 4.03125 8.78125 4.375 9.125L6.375 11.125C6.53125 11.3125 6.75 11.375 7 11.375C7.21875 11.375 7.4375 11.3125 7.59375 11.125L11.5938 7.125Z"
                            fill="#D0D5DD"
                        />
                    </svg>
                    <span>
                        {{ $t('signup-form.password-hint') }}
                    </span>
                </div>
                <div class="errors">
                    <span v-if="firstError">{{ firstError }}</span>
                </div>
            </div>
            <div class="button-section">
                <kw-button
                    class="signup-submit"
                    type="submit"
                    data-cy="signup-submit"
                    :loading="loading"
                    :button-disabled="isDisabled"
                    button-type="action"
                    :button-text="$t('signup')"
                />
                <div class="terms-and-privacy">
                    <div class="form-item">
                        {{ $t('signup-form.reg-terms-msg') }}
                        <a :href="getWebsiteUrl + '/tos'" target="_blank">{{
                            $t('signup-form.terms-of-service')
                        }}</a>
                    </div>
                    <div class="form-item">
                        {{ $t('signup-form.reg-privacy-msg') }}
                        <a
                            :href="getWebsiteUrl + '/privacy-policy'"
                            target="_blank"
                            >{{ $t('signup-form.privacy-policy') }}</a
                        >
                    </div>
                </div>
            </div>
            <div class="newsletter-subscription">
                <div>
                    <checkbox
                        color="#AFBAC7"
                        :data-cy="`checkbox-newsletter`"
                        :checkbox="newsletterSubscription"
                        @checkbox-click="toggleNewsletterSubscription"
                    />
                </div>
                <p>
                    {{ $t('newsletter-subscription') }}
                </p>
                <div />
            </div>
        </form>
    </div>
    <loader v-else color="0074D9" :size="100" :width="4" />
</template>

<script>
import { Events, EventBus } from '@/events';
import { mapGetters, mapActions } from 'vuex';
import { cookieGetters } from '@/helpers/cookieManager';
import SocialSignInMixin from '@/mixins/SocialSignInMixin';

export default {
    name: 'Signup',
    mixins: [SocialSignInMixin],
    props: {
        currentPackage: {
            type: Object | null,
            required: true,
        },
    },
    data() {
        return {
            username: this.$route.query.email ?? '',
            expirePostTrial:
                this.$route.query.expire_post_trial === '0' ? false : true,
            password: '',
            errorMsg: '',
            recaptcha: '',
            loading: false,
            validated: false,
            showPassword: false,
            trial_period: this.$route.query['trial-period'],
            mixinResource: 'sign-up',
            newsletterSubscription: false,
        };
    },
    computed: {
        ...mapGetters(['getBillingCycle']),
        getWebsiteUrl() {
            return process.env.KEYWORD_WEBSITE_URL;
        },
        emailFieldError() {
            return {
                error: this.validated && this.errors.collect('email').length,
            };
        },
        passwordFieldError() {
            return {
                error: this.validated && this.errors.collect('password').length,
            };
        },
        isDisabled() {
            return !!(
                (this.validated && this.errors.all().length) ||
                !this.password ||
                !this.username
            );
        },
        firstError() {
            return this.validated && this.errors.all().length
                ? this.errors.items[0].msg
                : this.errorMsg || false;
        },
        eyeIcon() {
            return this.showPassword ? 'kw-eye' : 'kw-eye-off';
        },
        fieldType() {
            return this.showPassword ? 'text' : 'password';
        },
        isFreePackage() {
            return !this.currentPackage || this.currentPackage?.price === 0;
        },
        isTrial() {
            return (
                this.$route.query.package_identity &&
                this.$route.query.trial == 'true'
            );
        },
        isEnterprise() {
            return this.currentPackage?.keywords_tracked > 5000;
        },
        currentPackageCycle() {
            return this.currentPackage?.cycle === 'hourly'
                ? 'bi-hourly'
                : this.currentPackage?.cycle;
        },
    },
    methods: {
        ...mapActions(['reset', 'register']),
        async validateForm() {
            this.validated = true;
            await this.$validator.validateAll();

            if (this.errors.all().length) {
                return;
            }

            await this.$recaptchaLoaded();
            this.recaptcha = await this.$recaptcha('sign_up');
            await this.signUp();
        },
        async signUp() {
            this.errorMsg = '';
            this.loading = true;
            const referral = cookieGetters.getRewardfulReferral();

            this.trackSignupStartEvent('default-sign-up', this.username);

            const data = {
                data: {
                    type: 'user',
                    attributes: {
                        email: this.username,
                        expire_post_trial: this.expirePostTrial,
                        password: this.password,
                        recaptcha: this.recaptcha,
                        ga_client_id: cookieGetters.getGaClientId(),
                        mixpanel_id: cookieGetters.getMixpanelId(),
                        first_visit_date: cookieGetters.getFirstVisitDate(),
                        ...cookieGetters.getUtmAndMarketingParameters(),
                        referral: referral ? referral : null,
                        newsletter_subscription: this.newsletterSubscription,
                    },
                },
            };

            const params = {
                plan_id: this.currentPackage.package_identity,
                billing_cycle: this.isFreePackage ? 'monthly' : this.getBillingCycle,
                trial_period: this.$route.query.expire_post_trial == 1 || this.trial_period,
            };

            try {
                const res = await this.register({ data, params });

                if (res.error) {
                    console.log(res.error);
                } else {
                    this.$router.push({
                        name: 'thankYou',
                        query: { email: this.username },
                    });
                }
            } catch (error) {
                this.errorMsg = error.response.data.errors[0].message;

                if (error.response.status === 422) {
                    this.errorMsg = error.response.data.errors[0].detail;
                } else if (this.errorMsg == undefined) {
                    this.errorMsg = this.$t('recaptcha-error-msg');
                }

                this.trackSignupFailedEvent('default-sign-up', this.errorMsg);
            } finally {
                this.loading = false;
            }
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        toggleNewsletterSubscription() {
            this.newsletterSubscription = !this.newsletterSubscription;
            window.sessionStorage.setItem(
                'newsletterSubscription',
                this.newsletterSubscription ? 1 : 0,
            );
        },
        trackSignupStartEvent(signup_cta, email) {
            EventBus.emit(Events.ACCOUNT_SIGNUP_STARTED, {
                email: email,
                signup_cta: signup_cta,
                signup_location_url: this.$route.query.package_identity
                    ? 'app.keyword.com'
                    : 'keyword.com',
                subscription_plan: this.currentPackage.title,
                selected_refresh_cycle: this.currentPackage.cycle,
                selected_num_keywords: this.currentPackage.keywords_tracked,
            });
        },
        trackSignupFailedEvent(signup_method, error_message) {
            EventBus.emit(Events.ACCOUNT_SIGNUP_FAILED, {
                signup_method,
                error_message,
            });
        },
    },
};
</script>

<style>
.signup-form {
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
}
</style>
<style lang="scss" scoped>
.signup-form {
    .package-note {
        width: 100%;
        color: #15394f;
        text-align: center;
        font-family: Aeonik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
    }

    form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 8px;

        .form-group {
            margin-bottom: 0;
        }

        /deep/ .kw__input {
            height: 50px;
        }

        /deep/ .field__label {
            color: var(--black, #15394f);
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 8px;
        }

        .password-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 6px;

            .password-visibility {
                cursor: pointer;
                width: 30px;
                height: 30px;
                position: absolute;
                top: 42px;
                right: 0;
            }

            .password-visibility span {
                width: 25px;
                height: 25px;
                font-size: 20px;
                color: #0074d9;
            }

            .password-hint {
                color: #7e8693;
                font-family: Aeonik;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                gap: 6px;
            }

            .errors {
                height: 17px;
                color: #f04438;
                font-family: Aeonik;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
            }
        }

        .button-section {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .terms-and-privacy {
                color: var(--black, #15394f);
                text-align: center;
                font-family: Aeonik;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                a {
                    color: var(--Blue-6, #0074d9);
                    font-family: Aeonik;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        /deep/ .kw-button {
            height: 50px;
            width: 100%;
            color: #fff;
            text-align: center;
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
        }
    }

    .newsletter-subscription {
        display: flex;
        max-width: 435px;
        margin: 16px auto;
        gap: 6px;

        & > div {
            position: relative;
            width: 16px;
            height: 16px;
        }

        p {
            flex: 1;
            font-family: 'Aeonik';
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: center;
        }
    }

    @media (max-width: 980px) {
        padding-bottom: 0;
    }
}
</style>

<style lang="scss">
.paid-title {
    b {
        font-weight: 500;
    }
}
</style>
