var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentPackage)?_c('div',{staticClass:"package-permissions"},[_c('div',{staticClass:"permission"},[_c('span',{staticClass:"kw kw-check"}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(_vm.isBold ? 'package-info.kw-tracked' : 'permissions.kw-tracked', {
                keywords_tracked: _vm.expirePostTrial ? 200 : _vm.currentPackage.keywords_tracked,
                cycle: _vm.currentPackageCycle,
            })
        )}})]),_vm._v(" "),_c('div',{staticClass:"permission"},[(_vm.onDemandUpdates || _vm.expirePostTrial)?_c('span',{staticClass:"kw kw-check"}):_c('span',{staticClass:"kw kw-x"}),_vm._v("\n        "+_vm._s(_vm.$t("permissions.on_demand_updates", {
                count: _vm.onDemandUpdates ? _vm.onDemandUpdatesCount : _vm.expirePostTrial ? 200 : '',
            }))+"\n    ")]),_vm._v(" "),_vm._l((_vm.permissionsArray),function(permission,key){return _c('permission',{key:key,attrs:{"name":key,"permission":permission,"isDefaultChecked":_vm.expirePostTrial}})})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }