import client from '../client';
import interceptors from '../interceptors';
const alertsInterceptor = interceptors.get('alerts');

client.interceptors.response.use(
    alertsInterceptor.s,
    alertsInterceptor.e,
);

const resource = '/alerts';

export default {
    count: async () => await client.get(`${resource}/count`),
    fetch: async payload => await client.get(resource, payload),
    update: async payload => await client.put(`${resource}/settings`, payload),
    fetchPivot: async payload => await client.get(`${resource}/pivot`, payload),
};