<template functional>
    <div class="table-cell-serp-features">
        <span
            v-if="
                props.keyword.crawled_at < 1700161638 ||
                $options.getCount(props.keyword.serp_features) === 0
            "
            class="empty-value"
            >&mdash;</span
        >
        <template v-else>
            <div
                v-for="(active, feature) in $options.getSorted(
                    props.keyword.serp_features,
                    props.maxCount,
                )"
                :key="feature"
                :data-important-tooltip="parent.$t(`serp-feature-${feature}`)"
                :class="`kw-sf-icon kw-sf-icon-${feature} ${
                    active ? 'active' : ''
                }`"
            ></div>
            <div
                v-if="
                    $options.getCount(props.keyword.serp_features) >
                    props.maxCount
                "
                class="kw-sf-number"
                :data-important-tooltip="
                    $options.getOthers(
                        props.keyword.serp_features,
                        props.maxCount,
                        parent,
                    )
                "
            >
                +{{
                    $options.getCount(props.keyword.serp_features) -
                    props.maxCount
                }}
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'SerpFeaturesField',
    props: {
        keyword: {
            type: Object,
            required: true,
        },
        maxCount: {
            type: Number,
            default: () => 5,
        },
    },
    getCount: serp_features => Object.keys(serp_features).length,
    getSorted: (serp_features, maxCount) => {
        const sortedKeys = Object.keys(serp_features).sort((a, b) => {
            if (serp_features[a] === serp_features[b]) {
                return 0;
            }
            return serp_features[a] ? -1 : 1;
        });

        const sortedObject = {};
        let count = 0;
        sortedKeys.forEach(key => {
            if (count < maxCount) {
                sortedObject[key] = serp_features[key];
                count++;
            }
        });

        return sortedObject;
    },
    getOthers: (serp_features, maxCount, par) => {
        let sortedKeys = Object.keys(serp_features).sort((a, b) => {
            if (serp_features[a] === serp_features[b]) {
                return 0;
            }

            return serp_features[a] ? -1 : 1;
        });

        return sortedKeys
            .slice(maxCount)
            .map(feature => par.$t(`serp-feature-${feature}`))
            .join(',');
    },
};
</script>

<style lang="scss" scoped>
.table-cell-serp-features {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2px;
    overflow: hidden;
}
.empty-value {
    color: #d3d3d3;
}

.kw-sf-number {
    color: kw-color(kw-blue);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
</style>
