import tippy, {followCursor, hideAll} from 'tippy.js';
import '@/helpers/tippy.css';

export default {
    install: Vue => {
        Vue.renderTooltip = options => {
            if (options.disabled) {
                return;
            }

            return tippy(options.event.target, {
                plugins: [followCursor],
                allowHTML: options.allowHtml ?? true,
                animation: 'fade',
                arrow: options.arrow ?? true,
                content: options.text,
                delay: [10, 0],
                duration: [10, 0],
                interactive: options.interactive || false,
                followCursor: options.followCursor || false,
                placement: options.type || 'top',
                theme: options.theme ? options.theme : options.arrow !== false ? 'custom' : 'light',
                maxWidth: options.maxWidth || 350, //default max-width is 350
                onHidden: options.onHidden ?? (instance => instance.destroy()),
                onCreate({ reference }) {
                    reference.setAttribute('data-tooltip-text', options.text);
                },
                onDestroy({ reference }) {
                    reference.removeAttribute('data-tooltip-text');
                }
            });
        }
    }
};
