<template>
    <div
        class="projects-list-modal"
        data-cy="projects-list-modal"
    >
        <div class="header">
            {{ $t('select-project-restrictions') }}
            <div class="search-wrapper">
                <search-input
                    icon-position="left"
                    :search-input-value="searchValue"
                    :search-input-handler="searchHandler"
                    :search-input-placeholder="$t('search')"
                />
            </div>
        </div>
        <div class="body">
            <p v-text="$t('restrict-following-project')" />
            <projects-list-for-select
                :projects="allList"
                width="100%"
                @option-click="optionCheckHandler"
            />
        </div>
        <div class="footer">
            <kw-button
                :button-text="$t('save')"
                button-type="primary"
                data-cy="projects-list-modal-save"
                @click="select"
            />
        </div>

        <global-events @keyup.enter="select" />
    </div>
</template>

<script>
    export default {
        name: 'ProjectsListModal',
        props: {
            projects: {
                type: Array,
                required: true,
            },
            selectHandler: {
                type: Function,
                required: true,
            },
        },
        data() {
            return {
                allList: [],
                searchValue: ''
            }
        },
        created() {
            this.allList = this.projects;
            document.body.classList.add('modal-open');
        },
        destroyed() {
            document.body.classList.remove('modal-open');
        },
        methods: {
            searchHandler (searchValue) {
                this.searchValue = searchValue;

                if(!searchValue) {
                    this.allList = this.projects;
                    return;
                }

                this.allList = this.projects.filter((item) => {
                    return item.id !== 'select-all' && item.name.toLowerCase().match(searchValue.toLowerCase());
                });
            },
            optionCheckHandler ({project, only}) {
                if (only) {
                    this.projects.forEach(p => p.selectForAddProfile = p.auth === project.auth);
                } else {
                    const {auth, selectForAddProfile} = project;

                    if (auth === 'select-all') {
                        this.projects.forEach(el => el.selectForAddProfile = selectForAddProfile);
                        return;
                    }

                    const allSelected = this.projects
                        .filter(el => el.auth !== 'select-all')
                        .every(el => el.selectForAddProfile);

                    this.projects[0].selectForAddProfile = allSelected;
                }
            },
            select () {
                const projects = this.projects.reduce((acc, cur) => {
                    if (!cur.selectForAddProfile) return acc;
                    if (cur.auth === 'select-all') return acc;

                    const projectName = cur.type === 'parent' ? cur.name : `${cur.parent}[sub]${cur.name}`;
                    acc.push(projectName);
                    return acc;
                }, []);

                this.selectHandler(projects);
                this.$emit('close');
            },
        },
    };

</script>

<style lang="scss">
    @import '~sass/modal';
    .projects-list-modal {
        .header {
            color: kw-color(kw-black, 1);
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            padding: 36px 50px 20px;
            @media screen and (max-width: 480px) {
                font-size: 20px;
                padding: 36px 20px 20px;
            }
        }

        .search-wrapper {
            margin-top: 20px;

            .search-input {
                input {
                    font-weight: 400;
                }
            }
        }

        .body {
            background-color: #F8F9FB;
            overflow-y: auto;
            padding: 0 12px 0 50px;
            p {
                color: kw-color(kw-black, 7);
                margin: 20px 0 10px 0;
            }
            .projects-list {
                background-color: transparent;
                box-shadow: none;
                margin: 0;
                max-height: 350px;
                position: relative;
            }
            .projects-list__item {
                padding: 6px 0;
                .checkbox {
                    left: unset;
                    margin: 0 4px 0 0;
                    position: relative;
                    top: 2px;
                }
                &.projects-list__item_child {
                    padding-left: 40px;
                    &:before,
                    &:after {
                        left: 26px;
                    }
                }
                &:hover {
                    background-color: transparent !important;
                }
            }
            @media screen and (max-width: 480px) {
                padding: 0 12px 0 20px;
            }
        }
        .footer {
            padding: 20px 50px;
            .kw-btn-primary {
                width: 168px;
            }
            @media screen and (max-width: 480px) {
                padding: 20px 20px;
            }
        }
    }
</style>
