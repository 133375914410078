<template>
    <div class="serp-history-modal">
        <div class="header">
            <closer @click="close" />
            <h4 v-text="$t('serp-history')" />
            <p class="keyword-name">
                {{ $t('keyword') }}: <span v-text="keywords[0].kw" />
            </p>
        </div>
        <div class="body">
            <div class="controls">
                <date-range
                    :end-label="$t('end-date')"
                    :end-value="endValue"
                    :set-date="setRange"
                    :start-label="$t('start-date')"
                    :start-value.sync="startValue"
                />
                <kw-button
                    class="show-serp-history"
                    button-type="primary"
                    :button-text="$t('apply-date-range')"
                    @click="fetchSerpHistory"
                />
                <kw-button
                    button-type="primary"
                    :button-text="$t('export')"
                    :button-disabled="!getSerpHistoryResults.length"
                    :loading="loading"
                    @click="exportSerpHistory"
                />
            </div>
            <div v-if="!loading" class="history-tables-wrapper">
                <div v-if="getSerpHistoryResults.length">
                    <div
                        v-for="(result, i) in getSerpHistoryResults"
                        :key="result.date"
                        class="single-table"
                    >
                        <div class="single-table__date">{{ result.date }}</div>
                        <div class="single-table__results">
                            <div
                                v-for="(item, index) in result.results"
                                :key="index"
                                :class="
                                    tableResultClasses(
                                        item,
                                        index + 1 === result.rank,
                                    )
                                "
                                @click="
                                    highlightSERP(
                                        item,
                                        index + 1 === result.rank,
                                    )
                                "
                            >
                                {{ index + 1 }}
                                <template
                                    v-if="
                                        !item.url &&
                                        item.result_type === 'local'
                                    "
                                >
                                    <span class="kw kw-map-pin" />
                                    {{ $t('local-listing') }}
                                </template>
                                <template v-else>
                                    <img
                                        :src="`https://www.google.com/s2/favicons?sz=64&domain_url=${item.url}`"
                                    />
                                    {{ item.url }}
                                </template>
                            </div>
                        </div>
                        <list-link
                            v-if="showLink(i)"
                            list-class="single-table__results"
                            list-item-highlighted-class="table-result_highlighted"
                            :highlighted="highlighted"
                            :selected-local="selectedLocal"
                            :is-local="isLocal"
                            :next-list-index="i + 1"
                            :prev-list-index="i"
                            :trend="trend(i, highlighted, isLocal)"
                        />
                    </div>
                </div>
                <div v-else>
                    <span>{{ $t('no-result') }}</span>
                </div>
            </div>
            <div v-else class="loading">
                <loader />
            </div>
        </div>
    </div>
</template>

<script>
import { Events, EventBus } from '@/events';
import { exportSerpHistoryAsCSV } from '@/helpers/downloads-service';
import { mapActions, mapGetters } from 'vuex';
import { switchDateFormat } from '@/helpers/format-date-service';
import DateRange from 'components/ui-elements/DateRange';
import ListLink from './ListLink';
import SimpleConfirmWithCustomHandler from 'components/modals/SimpleConfirmWithCustomHandler';
const today = new Date();
export default {
    name: 'SerpHistoryModal',
    components: {
        'date-range': DateRange,
        'list-link': ListLink,
    },
    props: {
        keywords: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            endValue: switchDateFormat(today, 'm/d/YY'),
            startValue: switchDateFormat(
                new Date(_.cloneDeep(today).setDate(today.getDate() - 30)),
                'm/d/YY',
            ),
            highlighted: '',
            isLocal: false,
            selectedLocal: false,
        };
    },
    computed: {
        ...mapGetters(['getSerpHistoryResults']),
    },
    watch: {
        getSerpHistoryResults() {
            setTimeout(() => {
                const lastList =
                    document &&
                    _.last([
                        ...document.getElementsByClassName('single-table'),
                    ]);
                lastList && lastList.scrollIntoView();

                if (this.getSerpHistoryResults.length) {
                    this.isLocal =
                        this.getSerpHistoryResults[0].rank &&
                        this.getSerpHistoryResults[0].results[
                            this.getSerpHistoryResults[0].rank - 1
                        ].result_type === 'local';

                    if (this.isLocal) {
                        this.selectedLocal = true;
                        this.highlighted = '';
                    }
                }
            }, 0);
        },
    },
    async created() {
        await this.fetchSerpHistory();

        if (this.keywords[0]) {
            this.highlighted = this.keywords[0].rankingurl;
        }
    },
    mounted() {
        EventBus.emit(Events.OPEN_SERP_HISTORY, {keywords: this.keywords});
    },
    methods: {
        ...mapActions(['fetchKeywordSerpHistory']),
        setRange(type, date) {
            this[`${type}Value`] = date;
        },
        exportSerpHistory() {
            this.$modal.show(
                SimpleConfirmWithCustomHandler,
                {
                    header: this.$t('export-serp-history-csv'),
                    positiveButton: this.$t('export'),
                    handler: confirmed =>
                        confirmed &&
                        exportSerpHistoryAsCSV(
                            `${this.keywords[0].kw} ${switchDateFormat(
                                new Date(this.startValue),
                                'Y-m-d',
                            )} - ${switchDateFormat(
                                new Date(this.endValue),
                                'Y-m-d',
                            )}`,
                            this.getSerpHistoryResults,
                        ),
                },
                {
                    height: 'auto',
                    width: 282,
                    classes: 'export-serp-history-csv',
                },
            );
        },
        async fetchSerpHistory() {
            const endDate = switchDateFormat(new Date(this.endValue), 'Y-m-d');
            const startDate = switchDateFormat(
                new Date(this.startValue),
                'Y-m-d',
            );
            const payload = {
                keywords: this.keywords,
                dateRange: `${startDate}.${endDate}`,
            };
            this.loading = true;
            await this.fetchKeywordSerpHistory(payload);
            this.loading = false;
        },
        close() {
            this.$emit('close');
        },
        highlightSERP(item, selectedLocal) {
            this.highlighted = item.url;
            this.selectedLocal = selectedLocal;
        },
        showLink(listIndex) {
            if (
                this.getSerpHistoryResults[listIndex + 1]?.results &&
                this.getSerpHistoryResults[listIndex]?.results
            ) {
                const linkPrevAndNext =
                    this.getSerpHistoryResults[listIndex + 1] &&
                    this.getSerpHistoryResults[listIndex + 1]?.results
                        .map(r => r.url)
                        .includes(this.highlighted) &&
                    this.getSerpHistoryResults[listIndex]?.results
                        .map(r => r.url)
                        .includes(this.highlighted);
                return (
                    listIndex + 1 !== this.getSerpHistoryResults.length &&
                    linkPrevAndNext
                );
            } else {
                return false;
            }
        },
        trend(i, highlighted, isLocal) {
            const { getSerpHistoryResults } = this;

            if (!highlighted) {
                const rank1 = getSerpHistoryResults[i]?.rank ?? 0;
                const rank2 = getSerpHistoryResults[i + 1]?.rank ?? 0;

                return isLocal && rank1 && rank2 ? rank1 - rank2 : 0;
            }

            return (
                getSerpHistoryResults[i]?.results.findIndex(
                    r => r.url === highlighted,
                ) -
                getSerpHistoryResults[i + 1]?.results.findIndex(
                    r => r.url === highlighted,
                )
            );
        },
        tableResultClasses(item, selfLocal) {
            return {
                'table-result': true,
                'table-result_highlighted':
                    this.highlighted === item.url
                        ? this.highlighted !== '' ||
                          (this.selectedLocal && selfLocal) ||
                          (!this.selectedLocal && !selfLocal)
                        : false,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.serp-history-modal {
    height: 100%;
    .loading {
        padding: 100px;
        width: 100px;
        margin: auto;
    }

    /deep/ .button-closer {
        position: absolute;
        right: 12px;
        top: 16px;
    }

    .body {
        padding: 20px;
        height: calc(100% - 58px);

        .controls {
            display: inline-flex;

            button {
                margin: 23px 10px 0 10px;

                &.show-serp-history {
                    margin: 23px 0 0 10px;
                }

                &.kw-btn-primary {
                    /deep/.button-loader {
                        position: relative;
                        background-color: transparent;
                        border-color: transparent transparent transparent
                            kw-color(kw-white, 1) !important;
                        left: 0 !important;
                        height: 18px !important;
                        min-width: 18px !important;
                        width: 18px !important;
                    }
                }
            }
        }
    }

    .header {
        padding: 16px 12px;

        h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: kw-color(kw-black, 7);
            margin: 0 0;
        }

        p {
            margin: 0;

            &.keyword-name {
                font-weight: 500;
                font-size: 13px;
                line-height: 15px;
                color: #63697b;

                span {
                    font-weight: 400;
                }
            }
        }
    }

    .history-tables-wrapper {
        height: calc(100% - 80px);
        margin: 20px 0;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        width: 100%;

        & > div {
            height: 100%;
        }

        .single-table {
            width: 300px;
            display: inline-block;
            margin-right: 10px;
            height: 100%;
            position: relative;

            .table-result {
                background-color: #f7f8fa;
                border: 1px solid #e1e8ef;
                cursor: pointer;
                margin: 0 5px 10px 5px;
                overflow: hidden;
                padding: 5px;
                text-overflow: ellipsis;

                img {
                    width: 16px;
                    margin: 0 3px;
                }
            }

            .table-result_highlighted {
                background-color: kw-color(kw-blue, 1);
                color: kw-color(kw-white, 1);
            }
        }
    }
}

.single-table__date {
    padding: 4px;
}

.single-table__results {
    height: calc(100% - 28px);
    overflow: auto;
}

@media screen and (min-width: 960px) {
    .add-note-modal {
        /deep/ .button-closer {
            display: block;
        }
    }
}
</style>
