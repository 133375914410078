<template>
    <div class="package-permissions" v-if="currentPackage">
        <div class="permission">
            <span class="kw kw-check" />
            
            <span v-html="
                $t(isBold ? 'package-info.kw-tracked' : 'permissions.kw-tracked', {
                    keywords_tracked: expirePostTrial ? 200 : currentPackage.keywords_tracked,
                    cycle: currentPackageCycle,
                })
            ">
            </span>
        </div>
        <div class="permission">
            <span v-if="onDemandUpdates || expirePostTrial" class="kw kw-check" />
            <span class="kw kw-x" v-else />
            {{
                $t(`permissions.on_demand_updates`, {
                    count: onDemandUpdates ? onDemandUpdatesCount : expirePostTrial ? 200 : '',
                })
            }}
        </div>
        <permission v-for="(permission, key) in permissionsArray" :key="key" :name="key" :permission="permission"
            :isDefaultChecked="expirePostTrial" />
    </div>
</template>

<script>
import Permission from './components/Permission';
export default {
    name: 'Permissions',
    components: { Permission },
    props: {
        currentPackage: {
            type: Object | Boolean,
            required: true,
        },
        isBold: {
            type: Boolean,
            default: false,
        },
        expirePostTrial: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        permissionsArray() {
            return this.currentPackage?.permissions ?? [];
        },
        currentPackageCycle() {
            return this.expirePostTrial ? 'daily' :
                this.currentPackage?.cycle === 'hourly'
                    ? 'bi-hourly'
                    : this.currentPackage?.cycle;
        },
        onDemandUpdates() {
            return this.currentPackage?.permissions?.on_demand_updates ?? false;
        },
        onDemandUpdatesCount() {
            return this.onDemandUpdates &&
                this.currentPackage?.on_demand_updates
                ? this.currentPackage.on_demand_updates
                : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.package-permissions {
    .permission {
        margin: 0 0 8px;
        color: kw-color(kw-white, 1);
        font-family: 'Aeonik';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 17px;

        /deep/ b {
            font-weight: 500;
        }

        .kw {
            font-size: 10px;
            font-weight: 700;
            margin-right: 15px;

            &.kw-check {
                color: #00a900;
            }

            &.kw-x {
                color: #d73855;
            }
        }
    }
}
</style>
