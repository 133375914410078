<template>
    <div class="top-pages-table-wrapper">
        <div class="top-pages-table" data-cy="top-pages-table">
            <div class="top-pages-table-header__wrapper">
                <top-pages-table-header
                    :fields="fields"
                    :sort="sort"
                    :sort-handler="sortHandler"
                />
            </div>
            <top-pages-table-body
                v-if="!loading"
                :items="items"
                :fields="fields"
            />
            <top-pages-table-placeholder v-else />
        </div>
        <table-pagination
            v-if="items && items.length"
            :current-page="currentPage"
            :total-pages="totalPages"
            :per-page="perPage"
            :total-records="totalItems"
            :on-change-page="pageChanged"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import fields from './Table/configs/fields';
import TopPagesTableBody from './Table/Body';
import TopPagesTableHeader from './Table/Header';
import TopPagesTablePlaceholder from './Table/Placeholder';
import KeywordsByRankingUrl from '@/components/modals/KeywordsByRankingUrl';
export default {
    name: 'TopPagesTable',
    components: {
        'top-pages-table-body': TopPagesTableBody,
        'top-pages-table-header': TopPagesTableHeader,
        'top-pages-table-placeholder': TopPagesTablePlaceholder,
    },
    data() {
        return {
            loading: false,
            sort: {
                field: fields[0].name,
                direction: 'asc',
            },
            items: [],
            fields,
            currentPage: 1,
            totalPages: 0,
            totalItems: 0,
            perPage: 10,
        };
    },
    computed: {
        ...mapGetters([
            'getActiveTag',
            'getCurrentProject',
            'getCurrentCurrency',
        ]),
    },
    created() {
        this.fetchData();
    },
    watch: {
        sort: {
            deep: true,
            handler: 'fetchData',
        },
        getCurrentProject: {
            deep: true,
            handler: 'fetchData',
        },
        getActiveTag: {
            deep: true,
            handler: 'fetchData',
        },
        currentPage: {
            deep: false,
            handler: 'fetchData',
        },
    },
    methods: {
        ...mapActions(['fetchTopPages']),
        async fetchData() {
            if(this.getCurrentProject) {
                let params = {
                    id: this.getCurrentProject.id,
                    page: this.currentPage,
                    sort_field: this.sort.field,
                    sort_direction: this.sort.direction,
                };

                this.loading = true;

                const res = await this.fetchTopPages(params);

                this.totalItems = res.meta.total;
                this.totalPages = Math.ceil(this.totalItems / this.perPage);
                this.items = res.data;
                this.loading = false;
            }
        },
        sortHandler(field) {
            if (this.sort.field === field) {
                this.sort.direction =
                    this.sort.direction === 'asc' ? 'desc' : 'asc';
            } else {
                this.sort.field = field;
                this.sort.direction = 'asc';
            }
        },
        pageChanged(page) {
            switch (page) {
                case 'next':
                    this.currentPage++;
                    break;
                case 'prev':
                    this.currentPage--;
                    break;
                default:
                    this.currentPage = page;
                    break;
            }
        },
        openModal(rankingUrl, selectedKeyword = null) {
            this.$modal.show(
                KeywordsByRankingUrl,
                {
                    rankingUrl: rankingUrl,
                    selectedKeyword: selectedKeyword,
                },
                {
                    clickToClose: false,
                    width: document.body.offsetWidth < 649 ? '100%' : '90%',
                    height: 'auto',
                    classes: 'v--modal center-modal-popup',
                },
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.top-pages-table {
    overflow-x: auto;

    /deep/ .top-pages-table-cell {
        display: flex;
        align-items: center;
        padding: 0 8px;
        border: 1px solid #e1e8ef;
        justify-content: right;

        &:first-child {
            border-radius: 4px 0 0 0;
        }

        &:last-child {
            border-radius: 0 4px 0 0;
        }

        &:not(:first-child) {
            border-left: none;
        }

        &.body {
            border-radius: 0;
            border-top: none;
        }

        &.current_ranking_url,
        &.keyword {
            justify-content: left;
        }

        &.total_keyword_count,
        &.traffic {
            padding-right: 21px;
        }

        &.current_rank,
        &.average_current_rank {
            padding-right: 11px;
        }
    }
}
</style>
