/**
 * Parse URL with or without scheme
 *
 * @param {string} str
 * @returns {URL}
 */
function parseUrl(str) {
    str = str.replace(/(^http[s]?:\/{2})/i, '');
    return new URL('http://' + str);
}

/**
 * Build Keyword.com-specific URL string depending on the "exact" option
 *
 * If the "exact" option is set, the URL will contain all parts except a scheme,
 * otherwise, it will be just a hostname
 *
 * Example output for different "exact" variants:
 *
 * Exact is true
 *   Input: example.net
 *   Output: example.net/
 *
 *   Input: example.net/index.html?user_id=10#hash
 *   Output: example.net/index.html
 *
 * Exact is false
 *   Input: example.net/index.html?user_id=10#hash
 *   Output: example.net
 *
 * @param {URL} url
 * @param {boolean} exact
 * @returns {string}
 */
function buildUrl(url, exact = false) {
    return exact
        ? url.host + url.pathname + url.search + url.hash
        : url.host;
}

export {
    parseUrl,
    buildUrl
};
