<template>
    <div class="pricing-info-wrapper">
        <div class="price-row">
            <div class="price-row-content light">
                <div class="title">
                    {{
                        isFreePackage
                            ? currentPackage.title
                            : $t('signup-form.title', {
                                  type: $t(
                                      `common.${
                                          isEnterprise
                                              ? 'enterprise'
                                              : 'business'
                                      }`,
                                  ),
                              })
                    }}
                </div>
                <div class="price">
                    $
                    {{ packagePrice ? packagePrice.toFixed(2) : packagePrice
                    }}{{ billingCycleText }}
                </div>
            </div>
            <div
                class="price-row-content light"
                v-if="!isFreePackage && getCoupon"
            >
                <div class="title">
                    {{ $t('pricing-info.coupon', { coupon: showCouponCode ? getCoupon : '' }) }}
                </div>
                <div class="price">
                    -$ {{ couponPrice ? couponPrice.toFixed(2) : couponPrice
                    }}{{ billingCycleText }}
                </div>
            </div>
        </div>
        <div class="price-row">
            <div class="price-row-content">
                <div class="title">
                    {{ $t('pricing-info.total-now') }}
                    <span v-if="!isFreePackage && showAdditionalInfo" class="ast">*</span>
                </div>
                <div class="price">
                    $
                    {{
                        trialExists
                            ? 0
                            : totalPrice
                            ? totalPrice.toFixed(2)
                            : totalPrice
                    }}{{ billingCycleText }}
                    
                </div>
            </div>
        </div>
        <div class="price-row" v-if="trialExists">
            <div class="price-row-content">
                <div class="title">
                    {{ $t('pricing-info.total-after') }}
                </div>
                <div class="price">
                    $ {{ totalPrice ? totalPrice.toFixed(2) : totalPrice
                    }}{{ billingCycleText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BILLING_CYCLES } from '@/constants';
import { mapGetters } from 'vuex';
export default {
    name: 'PricingInfo',
    props: {
        billingCycle: {
            type: String,
            required: true,
        },
        currentPackage: {
            type: Object,
            required: true,
        },
        trialExists: {
            type: Boolean | null,
            required: false,
            default: () => false,
        },
        showAdditionalInfo: {
            type: Boolean,
            required: false,
        },
        showCouponCode: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        ...mapGetters(['getCoupon']),
        billingCycleText() {
            return this.$t('pricing-info.billing-cycle.' + this.billingCycle);
        },
        packagePrice() {
            return this.billingCycle === BILLING_CYCLES.monthly
                ? this.currentPackage.price
                : this.currentPackage.annual_price * 12;
        },
        couponPrice() {
            return (
                this.packagePrice -
                (this.billingCycle === BILLING_CYCLES.monthly
                    ? this.currentPackage.price_by_coupon
                    : this.currentPackage.annual_price_by_coupon * 12)
            );
        },
        totalPrice() {
            return this.packagePrice - this.couponPrice;
        },
        isFreePackage() {
            return this.currentPackage?.keywords_tracked === 10;
        },
        isEnterprise() {
            return this.currentPackage?.keywords_tracked > 5000;
        },
    },
};
</script>

<style lang="scss" scoped>
.pricing-info-wrapper {
    .price-row {
        font-family: 'Aeonik';
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: #15394f;

        &:not(:first-child) {
            border-top: 1px solid kw-color(kw-light-grey, 5);
            border-bottom: 1px solid kw-color(kw-light-grey, 5);

            .price-row-content {
                margin-bottom: 4px;
                .title{
                    font-weight: 500;
                }
            }
        }

        .price-row-content {
            margin: 8px 0;
            font-weight: 400;
            display: flex;
            justify-content: space-between;
            .title{
                position: relative;

                .ast{
                    position: absolute;
                }
            }
        }
    }
}
</style>
