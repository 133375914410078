import {defaultArchivedSorter} from '../configs';
import apiFactory from '@/api';
const projectsApi = apiFactory.get('projects');

import {
    CLEAR_UNDO_DELETED_PROJECTS,
    SET_ARCHIVED_PROJECTS_FOR_UNDO,
    SET_ARCHIVED_PROJECTS_LOADED,
    SET_ARCHIVED_PROJECTS_SEARCH_QUERY,
    SET_ARCHIVED_PROJECTS,
    SET_CHECKED_ARCHIVED_PROJECTS,
    SET_DELETED_PROJECTS_FOR_UNDO,
    SET_DELETED_PROJECTS_LOADED,
    SET_DELETED_PROJECTS,
    SET_ARCHIVED_PROJECTS_SORTER,
} from '../mutations';

export const archivedProjectsPersistedKeys = [
    'archivedProjects.checkedProjects',
    'archivedProjects.searchQuery',
    'archivedProjects.sorter',
];

const archivedProjects = {
    state: {
        archivedProjectsForUndo: [],
        archivedProjects: [],
        checkedProjects: [],
        deletedProjectsForUndo: [],
        deletedProjects: [],
        isArchivedProjectsLoaded: false,
        isDeletedProjectsLoaded: false,
        searchQuery: '',
        sorter: defaultArchivedSorter,
    },
    mutations: {
        [SET_DELETED_PROJECTS]: (state, projects) => state.deletedProjects = projects,
        [SET_DELETED_PROJECTS_LOADED]: (state, payload) => state.isDeletedProjectsLoaded = payload,
        [SET_ARCHIVED_PROJECTS]: (state, projects) => state.archivedProjects = projects,
        [SET_ARCHIVED_PROJECTS_LOADED]: (state, payload) => state.isArchivedProjectsLoaded = payload,
        [SET_ARCHIVED_PROJECTS_FOR_UNDO]: (state, payload) => state.archivedProjectsForUndo = payload,
        [SET_DELETED_PROJECTS_FOR_UNDO]: (state, payload) => state.deletedProjectsForUndo = payload,
        [CLEAR_UNDO_DELETED_PROJECTS]: state => state.deletedProjectsForUndo = [],
        [SET_ARCHIVED_PROJECTS_SEARCH_QUERY]: (state, payload) => state.searchQuery = payload,
        [SET_CHECKED_ARCHIVED_PROJECTS]: (state, categories) => state.checkedProjects = categories,
        [SET_ARCHIVED_PROJECTS_SORTER]: (state, payload) => state.sorter = payload,
    },
    getters: {
        getArchivedCategoriesSearchQuery: state => state.searchQuery,
        getArchivedProjects: state => state.archivedProjects,
        getArchivedProjectsLoaded: state => state.isArchivedProjectsLoaded,
        getCategoriesForUndo: state => state.archivedProjectsForUndo,
        getCheckedArchivedCategories: state => state.checkedProjects,
        getDeletedCategoriesForUndo: state => state.deletedProjectsForUndo,
        getDeletedCategoriesSorter: state => state.sorter,
        getDeletedProjects: state => state.deletedProjects,
        getDeletedProjectsLoaded: state => state.isDeletedProjectsLoaded,
        getProcessedArchivedCategories: (state, getters) => params => {
            let categories = [];
            if (params === 'archived') {
                categories = _.cloneDeep(getters.getArchivedProjects);
            } else if (params === 'deleted') {
                categories = _.cloneDeep(getters.getDeletedProjects);
            }

            const sortOrder = getters.getDeletedCategoriesSorter;
            const searchQuery = getters.getArchivedCategoriesSearchQuery;
            const {direction, field} = sortOrder;

            categories = _.orderBy(categories.map(el => {
                el.category = el.category.toLowerCase();
                return el;
            }), field, direction);

            if (searchQuery && searchQuery.length) {
                categories = categories.filter(category => category.name.toLowerCase().includes(searchQuery.toLowerCase()));
            }

            return categories;
        },
    },
    actions: {
        async fetchArchivedProjects ({ commit }) {
            commit(SET_ARCHIVED_PROJECTS_LOADED, false);

            const res = await projectsApi.fetchArchivedProjects();
            const archievedProjects = res.data.data;

            for (let i = 0; i < archievedProjects.length; i++) {
                archievedProjects[i].name = archievedProjects[i].id;
                archievedProjects[i].category = archievedProjects[i].id;
                archievedProjects[i].id = archievedProjects[i].name + archievedProjects[i].attributes.updated_at;
                archievedProjects[i].time = archievedProjects[i].attributes.updated_at;
                archievedProjects[i].count = parseInt(archievedProjects[i].attributes.keywords_count.ARCHIVED, 10);
            }

            commit(SET_ARCHIVED_PROJECTS, archievedProjects);
            commit(SET_ARCHIVED_PROJECTS_LOADED, true);
        },
        async fetchDeletedProjects ({ commit }) {
            commit(SET_DELETED_PROJECTS_LOADED, false);

            const res = await projectsApi.fetchDeletedProjects();
            const deletedProjects = res.data.data;

            if (!deletedProjects) {
                commit(SET_DELETED_PROJECTS_LOADED, true);
                return;
            }

            for (let i = 0; i < deletedProjects.length; i++) {
                deletedProjects[i].name = deletedProjects[i].id;
                deletedProjects[i].category = deletedProjects[i].id;
                deletedProjects[i].id = deletedProjects[i].name + deletedProjects[i].attributes.updated_at;
                deletedProjects[i].time = deletedProjects[i].attributes.updated_at;
                deletedProjects[i].count = parseInt(deletedProjects[i].attributes.keywords_count.INACTIVE, 10);
            }

            commit(SET_DELETED_PROJECTS, deletedProjects);
            commit(SET_DELETED_PROJECTS_LOADED, true);
        },
        async archiveCategoriesUndo ({ dispatch }, achievableCategories) {
            await Promise.all(achievableCategories.map(el => projectsApi.archive(el.name)));

            dispatch('setArchivedProjectsForUndo', []);
            dispatch('fetchArchivedProjects', 'undo');
            dispatch('fetchUsed');
        },
        async deleteCategoriesUndo ({ commit, dispatch }, deletableCategories) {
            await Promise.all(deletableCategories.map(el => projectsApi.delete(el.name)));

            commit(CLEAR_UNDO_DELETED_PROJECTS);
            dispatch('fetchDeletedProjects');
            dispatch('fetchUsed');
        },
        async restoreProjects ({getters, commit, dispatch}, categories) {
            const deletedProjects = _.cloneDeep(getters.getDeletedProjects);
            const archivedProjects = _.cloneDeep(getters.getArchivedProjects);

            let undeleteQueries = [];
            let unarchiveQueries = [];

            let deletedIndexesToRemove = [];
            let archivedIndexesToRemove = [];
            let archivedProjectsForUndo = [];
            let deletedProjectsForUndo = [];
            for (let i = 0; i < categories.length; i++) {
                const deletedIndex = _.findIndex(deletedProjects, ['id', categories[i]]);
                const archivedIndex = _.findIndex(archivedProjects, ['id', categories[i]]);
                if (deletedIndex !== -1) {
                    const categoryName = deletedProjects[deletedIndex].name;
                    const deletedCount = deletedProjects[deletedIndex].count;
                    undeleteQueries.push(projectsApi.undelete(categoryName));
                    deletedIndexesToRemove.push(deletedIndex);
                    deletedProjectsForUndo.push({name: categoryName, keywords: deletedCount});
                } else if (archivedIndex !== -1) {
                    const categoryName = archivedProjects[archivedIndex].name;
                    const archivedCount = archivedProjects[archivedIndex].count;
                    unarchiveQueries.push(projectsApi.unarchive(categoryName));
                    archivedIndexesToRemove.push(archivedIndex);
                    archivedProjectsForUndo.push({name: categoryName, keywords: archivedCount});
                } else {
                    throw new Error('Something went wrong');
                }
            }

            try {
                await Promise.all([...undeleteQueries, ...unarchiveQueries]);

                dispatch('setCheckedArchivedProjects', []);
                deletedIndexesToRemove.sort((a, b) => b - a);

                for (let i = 0; i < deletedIndexesToRemove.length; i++) {
                    deletedProjects.splice(deletedIndexesToRemove[i], 1);
                }

                commit(SET_DELETED_PROJECTS, deletedProjects);

                archivedIndexesToRemove.sort((a, b) => b - a);

                for (let i = 0; i < archivedIndexesToRemove.length; i++) {
                    archivedProjects.splice(archivedIndexesToRemove[i], 1);
                }

                commit(SET_ARCHIVED_PROJECTS, archivedProjects);
                dispatch('setArchivedProjectsForUndo', archivedProjectsForUndo);
                commit(SET_DELETED_PROJECTS_FOR_UNDO, deletedProjectsForUndo);
                dispatch('setTutorialMode');
            } catch (e) {
                throw(e);
            } finally {
                dispatch('fetchUsed');
            }
        },
        toggleArchivedProjectsSorter({getters, commit}, payload){
            const sorter = getters.getDeletedCategoriesSorter
            let newSorter = {
                ...sorter,
            }
            if (payload === sorter.field) {
                const { direction } = sorter;
                newSorter.direction = direction === 'asc' ? 'desc' : 'asc';
            } else {
                newSorter = {
                    field: payload,
                    direction: 'asc',
                }
            }
            commit(SET_ARCHIVED_PROJECTS_SORTER, newSorter);
        },
        updateCheckedArchivedProjects ({getters, dispatch}, payload = null) {
            const checkedProjects = [
                ...getters.getCheckedArchivedCategories,
            ];
            const index = checkedProjects.indexOf(payload);
            if (index >= 0) {
                checkedProjects.splice(index, 1);
            } else {
                checkedProjects.push(payload);
            }
            dispatch('setCheckedArchivedProjects', checkedProjects);
        },
        setCheckedArchivedProjects ({commit}, payload = []) {
            commit(SET_CHECKED_ARCHIVED_PROJECTS, payload)
        },
        setArchivedProjectsForUndo({commit}, payload = []) {
            commit(SET_ARCHIVED_PROJECTS_FOR_UNDO, payload);
        },
        setArchivedProjectsSearchQuery({commit}, payload = '') {
            commit(SET_ARCHIVED_PROJECTS_SEARCH_QUERY, payload);
        }
    }
};

export default archivedProjects;
