<template>
    <div class="forgot-password">
        <div class="title">{{ $t('forgot-password.title') }}</div>
        <form :novalidate="true" @submit.prevent="validateForm">
            <div class="form-group">
                <kw-input-field
                    id="login"
                    name="email"
                    type="email"
                    data-cy="login"
                    class="text-input"
                    :label="$t('login-form.email')"
                    placeholder="email@email.com"
                    v-model.trim="username"
                    v-validate="'required|email'"
                />
            </div>
            <div class="errors">
                <span v-if="firstError">{{ firstError }}</span>
            </div>
            <kw-button
                type="submit"
                :loading="loading"
                button-type="action"
                class="forgot-password-submit"
                data-cy="forgot-password-submit"
                :button-disabled="isDisabled"
                :button-text="$t('submit')"
            />
            <router-link :to="{ name: 'login' }" class="login">
                {{ $t('forgot-password.bak-to-login') }}
            </router-link>
        </form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'ForgotPassword',
    data() {
        return {
            username: '',
            errorMsg: '',
            loading: false,
            validated: false,
        };
    },
    computed: {
        emailFieldError() {
            return {
                error: this.validated && this.errors.collect('email').length,
            };
        },
        isDisabled() {
            return !!(
                (this.validated && this.errors.all().length) ||
                !this.username
            );
        },
        firstError() {
            return this.validated && this.errors.all().length
                ? this.errors.items[0].msg
                : this.errorMsg || false;
        },
    },
    methods: {
        ...mapActions(['linkRefreshPassword']),
        async validateForm() {
            this.validated = true;
            this.errorMsg = '';
            await this.$validator.validateAll();

            if (this.errors.all().length) {
                return;
            }

            await this.refreshPassword();
        },
        async refreshPassword() {
            this.loading = true;
            let res = await this.linkRefreshPassword({ email: this.username });

            if (res !== undefined) {
                this.errorMsg =
                    res.response.data.errors[0] &&
                    res.response.data.errors[0].code === '2001'
                        ? this.$t('no-such-user')
                        : this.$t('refresh-password-error');
            }

            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.forgot-password {
    display: inline-flex;
    flex-direction: column;
    gap: 39px;

    /deep/ .form-group {
        margin-bottom: 6px;
    }

    /deep/ .kw__input {
        height: 50px;
    }

    /deep/ .field__label {
        color: var(--black, #15394f);
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
    }

    .title {
        color: var(--black, #15394f);
        font-family: Aeonik;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    form {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .errors {
            height: 14px;
            color: #f04438;
            font-family: Aeonik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .forgot-password-submit {
            margin-top: 24px;
            margin-bottom: 24px;
        }

        /deep/ .kw-button {
            height: 50px;
            width: 100%;
            color: #fff;
            text-align: center;
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 50px;
        }

        .login {
            align-self: stretch;
            text-decoration: none;
            color: var(--black, #15394f);
            text-align: center;
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    @media (max-width: 980px) {
        padding-bottom: 48px;
    }
}
</style>
