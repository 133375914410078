import i18n from '@/i18n';
import router from '@/routes';
import apiFactory from '@/api';
import toastr from '@/helpers/init-toastr';
import { Events, EventBus } from '@/events';
import { defaultReportsSort } from '../utils';
import { REPORT_GENERATION_STATUS } from '@/constants';
import { cookieSetters } from '@/helpers/cookieManager';
import { switchDateFormat } from '@/helpers/format-date-service';
import { recurringProgressChecking, downloadFile } from '@/helpers/downloads-service';
const reportsApi = apiFactory.get('reports');

import {
    SET_REPORTS_HISTORY_LOADING,
    SET_REPORTS_HISTORY_SORT,
    SET_REPORTS_HISTORY,
    SET_REPORTS_LOADING,
    SET_REPORTS_SEARCH,
    SET_ACTIVITY_LOGS,
    SET_REPORTS_SORT,
    SET_REPORTS,
} from '@/store/mutations';

const reports = {
    state: {
        reports: [],
        reportsHistory: [],
        reportsHistoryLoading: false,
        reportsHistorySort: defaultReportsSort('status_date', 'desc'),
        reportsLoading: false,
        reportsSearch: '',
        reportsSort: defaultReportsSort('last_delivery_sort'),
        activityLogs: {},
    },
    mutations: {
        [SET_REPORTS_HISTORY_LOADING]: (state, payload) => state.reportsHistoryLoading = payload,
        [SET_REPORTS_HISTORY_SORT]: (state, payload) => state.reportsHistorySort = payload,
        [SET_REPORTS_HISTORY]: (state, payload) => state.reportsHistory = payload,
        [SET_REPORTS_LOADING]: (state, payload) => state.reportsLoading = payload,
        [SET_REPORTS_SEARCH]: (state, payload) => state.reportsSearch = payload,
        [SET_REPORTS_SORT]: (state, payload) => state.reportsSort = payload,
        [SET_REPORTS]: (state, payload) => state.reports = payload,
        [SET_ACTIVITY_LOGS]: (state, payload) => {
            cookieSetters.setReportActionLogs(Object.keys(payload));
            state.activityLogs = payload
        },
    },
    getters: {
        getReports: state => state.reports,
        getReportsHistory: state => state.reportsHistory,
        getReportsHistoryLoading: state => state.reportsHistoryLoading,
        getReportsHistorySort: state => state.reportsHistorySort,
        getReportsLoading: state => state.reportsLoading,
        getActivityLogs: state => state.activityLogs,
        getActivityLog: state => logId => state.activityLogs[logId],
        getReportsSearch: state => state.reportsSearch,
        getReportsSort: state => state.reportsSort,
        getSortedReports: (state, { getReports, getReportsSort, getReportsSearch }) => {
            const { direction, field } = getReportsSort;

            const reports = getReports
                .map(r => {
                    const splittedFreq = r.frequency_html.split(' & ');
                    const frequencyFirstPart = r.frequency === 1
                        ? 'Daily'
                        : r.frequency === 7
                            ? 'Weekly'
                            : r.frequency === 30
                                ? 'Monthly' : _.upperFirst(splittedFreq[0].replace(' on', 'On'));

                    return {
                        ...r,
                        category: r.category.replace(' <img src=\"https://cdn.serpbook.com/assets/images/subcategory-arrow.png\"> ', '[sub]'),
                        formatExt: r.format,
                        frequency_html: `${frequencyFirstPart} ${splittedFreq[1] ? ` & ${splittedFreq[1]}` : ''}`,
                        recipient: r.emails.join(', '),
                    };
                })
                .filter(r => r.category.includes(getReportsSearch) || r.emails.some(e => e.includes(getReportsSearch)));

            if (['last_delivery_sort', 'next_delivery_sort', 'frequency'].includes(field)) {
                return reports.sort((a, b) => direction === 'asc'
                    ? a[field] - b[field]
                    : b[field] - a[field]);
            }

            return reports.sort((a, b) =>
                direction === 'asc'
                    ? a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1
                    : a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1);
        },
        getSortedReportsHistory: (state, { getReportsHistory, getReportsHistorySort }) => {
            const { direction, field } = getReportsHistorySort;

            const reports = getReportsHistory.map(r => {
                const splittedDate = r.status_date.split(',')[0].split(' ');
                splittedDate[1] = parseInt(splittedDate[1], 10);

                return {
                    ...r,
                    status_date: switchDateFormat(new Date(splittedDate.join(' ')), 'Y-m-d'),
                    status_html: r.status_html.replace('<br>', ''),
                    sentTo: r.emails.join(', '),
                };
            });

            if (['status_date', 'status_html'].includes(field) === 'status_sort') {
                return reports.sort((a, b) => direction === 'asc'
                    ? a.status_sort - b.status_sort
                    : b.status_sort - a.status_sort);
            }

            return reports.sort((a, b) =>
                direction === 'asc'
                    ? a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1
                    : a[field].toLowerCase() > b[field].toLowerCase() ? -1 : 1);
        },
    },
    actions: {
        async fetchReports({ commit }) {
            commit(SET_REPORTS, []);
            commit(SET_REPORTS_LOADING, true);

            try {
                const res = await reportsApi.fetchReports();
                commit(SET_REPORTS, res.data.data || []);

                if (!res.data.data) {
                    toastr.e(i18n.t('report-fetch-error'));
                    throw new Error(i18n.t('report-fetch-error'));
                }
            } catch (error) {
                console.error(error);
            } finally {
                commit(SET_REPORTS_LOADING, false);
            }
        },
        async fetchReportsHistory({ commit }, id) {
            commit(SET_REPORTS_HISTORY, []);
            commit(SET_REPORTS_HISTORY_LOADING, true);

            try {
                const res = await reportsApi.fetchReportsHistory(id);

                if (!res.data.data) {
                    toastr.e(i18n.t('report-fetch-error'));
                    throw new Error(i18n.t('report-fetch-error'));
                }

                commit(SET_REPORTS_HISTORY, res.data.data.map(el => {
                    if (el.categories.length > 1) {
                        el.categories[0].category = el.categories.map(elem => elem.category).join(',');
                    }

                    return el;
                }));
            } catch (error) {
                console.error(error);
            } finally {
                commit(SET_REPORTS_HISTORY_LOADING, false);
            }
        },
        reportsSorterChanged({ getters, commit }, payload) {
            const { getReportsSort } = getters;

            commit(SET_REPORTS_SORT, {
                field: payload,
                direction: getReportsSort.field === payload
                    ? getReportsSort.direction === 'asc' ? 'desc' : 'asc'
                    : 'desc',
            });
        },
        reportsHistorySorterChanged({ getters, commit }, payload) {
            const { getReportsHistorySort } = getters;

            commit(SET_REPORTS_HISTORY_SORT, {
                field: payload,
                direction: getReportsHistorySort.field === payload
                    ? getReportsHistorySort.direction === 'asc' ? 'desc' : 'asc'
                    : 'desc',
            });
        },
        async sendReport({getters}, id) {
            if (getters.getIsFreePlan) {
                toastr.w(i18n.t('upgrade-for-scheduled-reports'));
                return false;
            }

            try {
                const res = await reportsApi.sendReport(id);
                toastr.s(res.data.message || i18n.t('report-sent-out'))
            } catch (error) {
                toastr.e(i18n.t('report-sent-error'));
                throw error;
            }
        },
        async deleteReport({ dispatch, getters }, { edit_id }) {
            if (getters.getIsFreePlan) {
                toastr.w(i18n.t('upgrade-for-scheduled-reports'));
                return false;
            }

            try {
                const res = await reportsApi.deleteReport(edit_id);
                if (res.data.meta.code === 200) {
                    dispatch('fetchReports');
                    toastr.s(i18n.t('report-successfully-deleted'));
                } else {
                    throw new Error(res.data.errors[0]);
                }
            } catch (error) {
                toastr.e(i18n.t('report-delete-error'));
                console.error(error);
            }
        },

        async addScheduledReport({ dispatch, getters }, { emails, categories: projects, frequency, dates, type }) {
            if (getters.getIsFreePlan) {
                toastr.w(i18n.t('upgrade-for-scheduled-reports'));
                return false;
            }

            try {
                const report = { data: { type, dates, emails, frequency, projects } };
                const res = await reportsApi.addReport(report);

                EventBus.emit(Events.CREATE_SCHEDULED_REPORT, report.data);

                if (res.data.meta.code === 200) {
                    dispatch('fetchReports');
                    toastr.s(i18n.t('report-successfully-added'));
                } else {
                    throw new Error(res.data.errors[0]);
                }
            } catch (error) {
                toastr.e(i18n.t('report-add-error'));
                console.error(error);
            }
        },

        async updateScheduledReport({ dispatch, getters }, { id: report_id, emails, categories: projects, frequency, dates, type }) {
            if (getters.getIsFreePlan) {
                toastr.w(i18n.t('upgrade-for-scheduled-reports'));
                return false;
            }

            try {
                if (!frequency) {
                    frequency = '';
                }
                const res = await reportsApi.addReport({ data: { report_id, type, dates, emails, frequency, projects } });
                if (res.data.meta.code === 200) {
                    dispatch('fetchReports');
                    toastr.s(i18n.t('report-successfully-edited'));
                } else {
                    throw new Error(res.data.errors[0]);
                }
            } catch (error) {
                toastr.e(i18n.t('report-add-error'));
                console.error(error);
            }
        },

        searchReports({ commit }, payload) {
            commit(SET_REPORTS_SEARCH, payload);
        },
        async checkStatus({ commit, getters }, { logId, close }) {
            const { getActivityLogs } = getters;
            const activityLogs = _.clone(getActivityLogs);

            try {
                const { getActivityLog } = getters;
                const activityLog = _.clone(getActivityLog(logId));
                const res = await reportsApi.checkStatus(logId);
                const newActivityLog = res.data.data;

                if (!activityLog || activityLog.status !== newActivityLog.status) {

                    switch (newActivityLog.status) {
                        case REPORT_GENERATION_STATUS.FILE_DOWNLOADED:
                            if (close) {
                                close();
                            }
                        case REPORT_GENERATION_STATUS.FAILED:
                        case REPORT_GENERATION_STATUS.FILE_SENT:
                            delete activityLogs[logId];
                            break;
                        case REPORT_GENERATION_STATUS.FILE_IS_READY:
                            downloadFile(newActivityLog.generated_report_url);
                        default:
                            activityLogs[logId] = newActivityLog;
                            break;
                    }

                    commit(SET_ACTIVITY_LOGS, activityLogs);
                }

                return newActivityLog.status < REPORT_GENERATION_STATUS.FAILED ||
                    newActivityLog.status === REPORT_GENERATION_STATUS.FILE_IS_READY;
            } catch (e) {
                if (close) {
                    close();
                }

                delete activityLogs[logId];
                commit(SET_ACTIVITY_LOGS, activityLogs);
            }

            return false;
        },
        async setActivityLogEmail({ commit, getters }, { logId, email }) {
            let data = {
                emails: [email],
            };

            try {
                const res = await reportsApi.setRecipients(logId, data);

                if (res.status === 200) {
                    return true;
                }
            } catch (e) {
                // throw e;
            }

            return false;
        },
        async downloadReport({ commit, getters, dispatch }, { project, queryData, close }) {
            let res, activityLog = false;
            const { getViewkeyView, getActivityLogs } = getters;
            const activityLogs = _.clone(getActivityLogs);

            try {
                if (getViewkeyView) {
                    const { name: routeName, params } = router.history.current;

                    if (routeName === 'shareViewkeyWithHash' || routeName === 'shareKeywordsTableWithHash') {
                        res = await reportsApi.downloadReportForShareWithHash(params.hash, queryData);
                    } else {
                        queryData.auth = project.auth;
                        res = await reportsApi.downloadReportForShare(
                            project.shortkey,
                            queryData
                        );
                    }
                } else {
                    res = await reportsApi.downloadReport(queryData);
                }

                activityLog = res.data.data;
                let logId = activityLog.report_activity_log_id;
                activityLogs[logId] = activityLog;

                commit(SET_ACTIVITY_LOGS, activityLogs);
                dispatch('runIntervalChecking', { logId, close });
            } catch (e) {
                toastr.e(i18n.t('unexpected-error'));
                close();
            }

            return activityLog;
        },
        runIntervalChecking({ dispatch }, data) {
            recurringProgressChecking(() => dispatch('checkStatus', data));
        },
    }
};

export default reports;
