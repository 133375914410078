export default (i18n, toastr) => {
	return error => {
		const { config, response } = error;

		if (!config || !response) {
			return Promise.reject(error);
		}

		const { errors } = response.data;

		if (config.url.includes('/alerts')) {
			if (config.url.includes('/settings')) {
				toastr.e(i18n.t('settings-update-error'));
			}

			errors.forEach(element => {
				if (element.detail) toastr.e(element.detail);
			});
		}

		return Promise.reject(error);
	};
};
