import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import account from './modules/settings/account';
import addons from './modules/settings/addons';
import addKeywords, {addKeywordsPersistedKeys} from './modules/add-keywords';
import alerts, {alertsPersistedKeys} from './modules/alerts';
import alertsSettings from './modules/settings/alerts';
import archivedProjects, {archivedProjectsPersistedKeys} from './modules/archivedProjects';
import auth from './modules/auth';
import billing from './modules/billing';
import charts, {chartsPersistedKeys} from './modules/charts';
import countriesStates from './modules/countries-states';
import currenciesStates from './modules/currencies';
import currentProject from './modules/current-project';
import eventTracking from './modules/eventTracking';
import display, {displayPersistedKeys} from './modules/settings/display';
import expandedOverview from './modules/expanded-overview';
import googleAnalytics from './modules/google-analytics';
import googleSearchConsole from './modules/google-search-console';
import keywords, {keywordsPersistedKeys} from './modules/keywords';
import keywordsTableSettings from './modules/keywordsTableSettings';
import manageProjects from './modules/manageProjects';
import metrics from './modules/metrics';
import notes from './modules/notes';
import overview, {overviewPersistedKeys} from './modules/overview';
import refreshes from './modules/refreshes';
import reports from './modules/reports';
import whitelabelDomains from './modules/whitelabelDomains';
import pdfSettings from './modules/settings/pdf';
import reportsSettings from './modules/settings/reports';
import settings from './modules/settings/settings';
import sidebars, {sidebarsPersistedKeys} from './modules/sidebars';
import tags, {tagsPersistedKeys} from './modules/tags';
import tutorial from './modules/tutorial';
import viewkey from './modules/viewkey';
import importData from './modules/importData'
import projectOverview from './modules/projectOverview'

import * as constants from '@/constants';
import * as mutations from './mutations';

import {
    updateCurrencyForCPCPlugin,
    createCurrentProjectPlugin,
    createManageProjectsPlugin,
    createDashboardOverviewPlugin,
    updateIntercomUserInformationPlugin,
} from './plugins';

Vue.use(Vuex);

const version = {
    packageVersion: process.env.VERSION,
};

export const resetVuexLocalStorage = () => {
    if (version.packageVersion !== localStorage.getItem('version')) {
        localStorage.removeItem('vuex');
    }

    localStorage.setItem('version', version.packageVersion);
};

const defaultState = {
    account,
    addons,
    addKeywords,
    alerts,
    alertsSettings,
    archivedProjects,
    auth,
    billing,
    charts,
    countriesStates,
    currenciesStates,
    currentProject,
    eventTracking,
    display,
    googleAnalytics,
    googleSearchConsole,
    keywords,
    keywordsTableSettings,
    manageProjects,
    metrics,
    notes,
    overview,
    expandedOverview,
    refreshes,
    pdfSettings,
    reportsSettings,
    reports,
    whitelabelDomains,
    settings,
    sidebars,
    tags,
    tutorial,
    viewkey,
    importData,
    projectOverview
};

export default new Vuex.Store({
    modules: {
        ..._.cloneDeep(defaultState)
    },
    plugins: [
        createPersistedState({
            key: 'vuex',
            paths: [
                ...addKeywordsPersistedKeys,
                ...alertsPersistedKeys,
                ...archivedProjectsPersistedKeys,
                ...chartsPersistedKeys,
                ...displayPersistedKeys,
                ...keywordsPersistedKeys,
                ...overviewPersistedKeys,
                ...sidebarsPersistedKeys,
                ...tagsPersistedKeys,
            ],
        }),
        updateCurrencyForCPCPlugin(mutations),
        createCurrentProjectPlugin(mutations),
        createDashboardOverviewPlugin(mutations),
        createManageProjectsPlugin(mutations, constants),
        updateIntercomUserInformationPlugin(mutations),
    ],
    mutations: {
        [mutations.RESET] (state) {
            Object.keys(defaultState).forEach(key => {
                state[key] = {...defaultState[key].state}
            });

            localStorage.setItem('version', version.packageVersion);
        }
    },
    actions: {
        reset({commit}) {
            commit(mutations.RESET);
        }
    },
});
