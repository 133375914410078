<template>
    <div class="account-settings">
        <form class="account-settings__account">
            <span class="kw kw-user" />
            <template v-if="isMasterUser">
                <h2
                    class="settings__subtitle settings__subtitle_account"
                    v-text="$t('account-subtitle')"
                />
                <!-- username setting -->
                <kw-input-field
                    id="profile-name"
                    :value="account.name"
                    :label="$t('full-name')"
                    class="text-input"
                    :error-data="nameErrorData"
                    :disabled="!isAdminOrMasterUser"
                    name="profileName"
                    :placeholder="$t('name-surname')"
                    @input="nameInputHandler"
                />
                <!-- contact number setting -->
                <label
                    class="settings__label"
                    for="profile-contact-number"
                    v-text="$t('contact-number')"
                />
                <div :class="phoneWrapperClasses">
                    <custom-v-select
                        options-label="label"
                        options-value="value"
                        :options="getAllCallingCodes"
                        search
                        search-key="searchKey"
                        search-text="Country"
                        :value.sync="contact.code"
                        :disable-de-select="true"
                        @change="contactCodeHandler"
                    >
                        <template v-slot:toggler="props">
                            <span
                                v-show="props.selected"
                                :class="
                                    selectedPhoneCodeClasses(props.selected)
                                "
                            />
                            <span
                                v-show="props.selected"
                                class="phone-code"
                                v-text="`+${props.selected.split(',')[0]}`"
                            />
                        </template>
                        <template v-slot:option="props">
                            <span
                                :class="`flag-icon flag-icon-${props.option[1].toLowerCase()}`"
                            />
                            <span class="num" v-text="`+${props.option[0]}`" />
                        </template>
                    </custom-v-select>
                    <kw-input-field
                        id="profile-contact-number"
                        v-model.trim="contact.contactnum"
                        class="text-input contact-number"
                        name="Phone"
                        :placeholder="$t('number-placeholder')"
                        @blur="blurPhoneCode"
                        @focus="focusPhoneCode"
                        @input="phoneInputHandler"
                    />
                </div>
                <!-- country setting -->
                <label
                    class="settings__label"
                    for="profile-country"
                    v-text="$t('country')"
                />
                <custom-v-select
                    id="profile-country"
                    search
                    search-key="label"
                    options-label="label"
                    options-value="value"
                    :options="getAllCountries"
                    :value.sync="account.country"
                    :disable-de-select="true"
                    @change="$event => selectHandler('country', $event)"
                />
                <!-- countrystate setting -->
                <label
                    class="settings__label"
                    for="profile-country-state"
                    v-text="$t('country-state')"
                />
                <custom-v-select
                    id="profile-country-state"
                    options-label="label"
                    options-value="value"
                    :options="getStates"
                    :value.sync="account.countrystate"
                    :disable-de-select="true"
                    @change="$event => selectHandler('countrystate', $event)"
                />
            </template>

            <div
                class="api-token-wrapper"
                :class="{ 'without-border': isMasterUser }"
            >
                <div>
                    <label
                        class="settings__label"
                        for="profile-country-state"
                        v-text="$t('api-token')"
                    />
                    <p class="api_key">
                        <span @click="copyApiKey">{{ apiKey }}</span>
                        <kw-button
                            :id="refreshButtonId"
                            class="button-refresh-api-token"
                            button-type="primary"
                            :loading="getApiKeyResetInProgress"
                            prepend-icon-class="kw kw-refresh-cw"
                            data-cy="button-refresh-api-token"
                            @click="openConfirmModal"
                            @mouseover="renderTooltip"
                        />
                    </p>
                </div>
                <a
                    class="view_documentation_link"
                    :href="API_DOCUMENTATION_LINK"
                    target="_blank"
                >
                    {{ $t('view-documentation') }}
                </a>
            </div>
            <kw-button
                v-if="isMasterUser"
                id="account-form-button"
                :button-disabled="saveAccountDisabled"
                :button-text="$t('save-changes')"
                button-type="primary"
                :loading="profileRequestRunning"
                @click="saveAccount"
            />
        </form>
        <!-- password setting -->
        <form class="account-settings__password" v-if="isMasterUser">
            <span class="kw kw-lock" />
            <h2
                class="settings__subtitle settings__subtitle_password"
                v-text="$t('change-password')"
            />
            <kw-input-field
                id="current-password"
                v-model="password.current"
                :label="$t('current-password')"
                autocomplete="new-password"
                class="text-input"
                name="currentPassword"
                type="password"
                :error-data="getCurrentPasswordErrorData"
                @input="currentPasswordHandler"
                @blur="blurPhoneCode"
                @focus="focusPhoneCode"
            />
            <kw-input-field
                id="new-password"
                v-model="password.new"
                :label="$t('new-password')"
                class="text-input"
                name="newPassword"
                type="password"
                :error-data="getNewPasswordErrorData"
                @input="newPasswordHandler"
                @blur="blurPhoneCode"
                @focus="focusPhoneCode"
            />
            <kw-button
                id="change-password-form-button"
                :button-disabled="savePasswordDisabled"
                :button-text="$t('save-changes')"
                button-type="primary"
                :loading="passwordRequestRunning"
                @click="savePassword"
            />
        </form>
    </div>
</template>

<script>
import { apiCodes, API_DOCUMENTATION_LINK } from '@/constants';
import { mapGetters, mapActions } from 'vuex';
import { SET_ACCOUNT_SETTINGS } from '@/store/mutations';
import ipstack from '@/services/ipstack';
import ValidationMixin from '@/mixins/ValidationMixin';
import { cookieGetters } from '@/helpers/cookieManager';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import ConfirmModal from 'components/modals/ConfirmModal';

export default {
    name: 'Account',
    mixins: [ValidationMixin, ToolTipRenderMixin],
    data() {
        return {
            profileRequestRunning: false,
            account: {
                contactnum_code: '',
                contactnum: '',
                country: '',
                countrystate: '',
                name: '',
            },
            // helper object
            contact: {
                code: '',
                contactnum: '',
            },
            err: {
                name: {},
                contactnum: {},
            },
            passwordRequestRunning: false,
            password: {
                current: '',
                new: '',
            },
            passwordErr: {
                current: {},
                new: {},
            },
            refreshButtonId: 'button-refresh-api-token',
            apiKey: cookieGetters.getApiKey(),
            API_DOCUMENTATION_LINK,
        };
    },
    computed: {
        ...mapGetters([
            'getStates',
            'isMasterUser',
            'getAllCountries',
            'getCountryByCode',
            'getAllCallingCodes',
            'getAccountSettings',
            'isAdminOrMasterUser',
            'getApiKeyResetInProgress',
        ]),
        saveAccountNotChanged() {
            return _.isEqual(this.account, this.getAccountSettings);
        },
        nameErrorData() {
            return {
                error: !!this.err.name.text,
            };
        },
        phoneWrapperClasses() {
            return {
                'account-settings__phone-wrapper': true,
                'account-settings__phone-wrapper_invalid': this.err.contactnum
                    .text,
            };
        },
        getCurrentPasswordErrorData() {
            return {
                error: !!this.passwordErr.current.text,
            };
        },
        getNewPasswordErrorData() {
            return {
                error: !!this.passwordErr.new.text,
            };
        },
        savePasswordDisabled() {
            return (
                this.passwordRequestRunning ||
                !this.password.current.length ||
                !this.password.new.length ||
                !!this.passwordErr.current.text ||
                !!this.passwordErr.new.text
            );
        },
        saveAccountDisabled() {
            return (
                this.getApiKeyResetInProgress ||
                this.profileRequestRunning ||
                this.saveAccountNotChanged ||
                !this.contact.contactnum ||
                !!this.err.name.text ||
                !!this.err.contactnum.text
            );
        },
    },
    watch: {
        'contact.code'() {
            this.setFullContact();
            this.selectHandler('country', this.contact.code.split('-')[1]);
        },
        'contact.contactnum'() {
            this.setFullContact();
        },
        'account.country'(value) {
            this.fetchStates(value);
        },
        getStates(val) {
            if (!this.getStates[0]) return;
            if (val.find(el => el.value === this.account.countrystate)) return;
            this.account.countrystate = this.getStates[0].value;
        },
    },
    async created() {
        await this.fetchCountries();

        this.unSubscribe = this.$store.subscribe(({ type }) => {
            if (type === SET_ACCOUNT_SETTINGS) {
                this.initAccountData();
            }
        });

        await this.initAccountData();
    },
    beforeDestroy() {
        this.unSubscribe();
    },
    methods: {
        ...mapActions([
            'resetApiKey',
            'fetchStates',
            'updateAccount',
            'fetchCountries',
            'updatePassword',
        ]),
        async initAccountData() {
            // set to account form saved settings before
            this.account = _.cloneDeep(this.getAccountSettings);

            // set default (by ipstack) calling code and country if these options are empty
            if (!this.account.contactnum) {
                try {
                    const res = await ipstack.calculateByIP();
                    this.contact.code = `${res.data.location.calling_code}-${res.data.country_code}`;
                } catch (error) {
                    // in case of ipstack error set default values
                    this.contact.code = '1-US';
                }

                return;
            }

            const country = this.getCountryByCode(this.account.contactnum_code);
            this.contact.code = `${country.attributes.phone_code}-${country.attributes.code}`;
            this.contact.contactnum = this.account.contactnum.replace(
                country.attributes.phone_code,
                '',
            );
        },
        setFullContact() {
            this.account.contactnum =
                this.contact.code.split('-')[0] +
                (this.contact.contactnum || '');
            this.account.contactnum_code = this.contact.code.split('-')[1];
        },
        nameInputHandler(value) {
            this.account.name = value;
            this.err.name = this.accountNameValidator(this.account.name);
        },
        phoneInputHandler() {
            this.err.contactnum = this.accountPhoneValidator(
                this.contact.contactnum,
            );
        },
        focusPhoneCode() {
            document
                .querySelector(
                    '.account-settings__phone-wrapper .dropdown-toggle',
                )
                .classList.add('dropdown-toggle_focused');
        },
        blurPhoneCode() {
            document
                .querySelector(
                    '.account-settings__phone-wrapper .dropdown-toggle',
                )
                .classList.remove('dropdown-toggle_focused');
        },
        contactCodeHandler(value) {
            this.contact.code = value;
        },
        selectHandler(key, value) {
            this.account[key] = value;
        },
        async saveAccount() {
            const { err, account, contact } = this;
            account.name = account.name.trim();
            err.name = this.accountNameValidator(account.name);
            err.contactnum = this.accountPhoneValidator(contact.contactnum);

            if (err.name.text || err.contactnum.text) {
                Object.keys(err).forEach(key => {
                    if (err[key].text) this.$toastr.e(err[key].text);
                });

                return;
            }

            if (this.saveAccountNotChanged) {
                return;
            }
            this.profileRequestRunning = true;
            await this.updateAccount(account);
            this.profileRequestRunning = false;
        },
        currentPasswordHandler() {
            this.passwordErr.current = this.oldPasswordValidator(
                this.password.current,
            );
        },
        newPasswordHandler() {
            this.passwordErr.new = this.passwordValidator(this.password.new);
        },
        async savePassword() {
            const { passwordErr, password } = this;
            passwordErr.current = this.oldPasswordValidator(password.current);
            passwordErr.new = this.passwordValidator(password.new);

            if (passwordErr.current.text || passwordErr.new.text) {
                Object.keys(passwordErr).forEach(key => {
                    if (passwordErr[key].text)
                        this.$toastr.e(passwordErr[key].text);
                });

                return;
            }

            this.passwordRequestRunning = true;

            try {
                await this.updatePassword(this.password);
                this.resetPasswordForm();
            } catch (errors) {
                if (
                    errors.some(el => el.code === apiCodes.WRONG_USER_PASSWORD)
                ) {
                    passwordErr.current = {
                        text: this.$t('invalid-old-password'),
                        type: 'error',
                    };
                }
            }

            this.passwordRequestRunning = false;
        },
        resetPasswordForm() {
            this.password.current = '';
            this.password.new = '';
        },
        selectedPhoneCodeClasses(selected) {
            const countryCode = selected.split(',')[1];
            return `flag-icon flag-icon-${
                countryCode ? countryCode.toLowerCase() : ''
            }`;
        },
        renderTooltip(event) {
            if (event.target.id === this.refreshButtonId) {
                return this.tooltip({
                    text: this.$t('api-key-reset-tooltip'),
                    event,
                });
            }
        },
        copyApiKey() {
            this.$copyText(this.apiKey);
            this.$toastr.s(this.$t('api-key-copied-msg'));
        },
        openConfirmModal() {
            this.$modal.show(
                ConfirmModal,
                {
                    title: this.$t('api-key-reset-question'),
                    description: this.$t('api-key-reset-note'),
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    confirmCallback: this.refreshApiToken,
                },
                {
                    classes: 'v--modal',
                    draggable: '.custom-modal_draggable',
                    height: 'auto',
                    name: 'resetApiToken',
                    width: document.body.offsetWidth < 649 ? '90%' : 500,
                },
            );
        },
        async refreshApiToken() {
            await this.resetApiKey();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/partials/dropdowns';
@import '~flag-icon-css/sass/flag-icon';

.account-settings {
    color: kw-color(kw-black, 1);
    display: flex;
    flex-flow: row wrap;
    margin-top: 4px;

    /deep/ .form-control.dropdown-toggle {
        @extend .custom-dropdown;
        color: kw-color(kw-black, 1);

        &:focus {
            border-color: #afbac7 !important;
        }
    }

    /deep/ .open .form-control.dropdown-toggle {
        @extend .custom-dropdown_active;
    }

    /deep/ .dropdown-menu {
        @extend .custom-scroll;
        @extend .custom-dropdown-menu;
        width: 100%;

        .glyphicon.glyphicon-ok.check-mark:before {
            content: '';
        }
    }

    .api-token-wrapper {
        padding-top: 8px;
        margin-bottom: 24px;

        &:before {
            position: absolute;
            left: 0;
            margin-top: -16px;
            content: ' ';
            display: block;
            height: 40px;
            width: 100%;

            &:not(.without-border) {
                border-top: 1px solid #dee5ec;
            }
        }

        .api_key {
            display: flex;
            justify-content: space-between;

            & > span {
                width: 250px;
                height: 36px;
                cursor: pointer;
                padding: 0 10px;
                color: #63697b;
                font-family: Roboto;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 36px;
                border-radius: 4px;
                border: 1px solid var(--grey-10, #dee5ec);
                background: var(--white, #fff);
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .button-refresh-api-token {
                width: 36px;
                height: 36px;

                /deep/ span:before {
                    font-weight: 700;
                }
            }
        }
    }

    &__phone-wrapper {
        display: flex;
        flex-flow: row nowrap;

        /deep/.text-input {
            &.contact-number {
                width: 100%;

                .kw__inputfield__name {
                    .kw__input {
                        border-left: none;
                        border-bottom-left-radius: 0;
                        border-top-left-radius: 0;
                    }
                }
            }
        }

        /deep/ .form-control.dropdown-toggle {
            background-color: #f8f9fa;
            border-right: none !important;
            border-color: #afbac7 !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-left: 8px;
            padding-right: 18px;
            transition: 0.2s;
            height: 100%;
            width: 84px;

            &:after {
                right: 8px;
            }

            &.dropdown-toggle_focused {
                border-color: kw-color(kw-blue, 1) !important;
            }

            .flag-icon {
                margin-right: 4px;
                width: 16px;
            }

            .phone-code {
                @import '~sass/cutting-name';
            }
        }

        /deep/ .dropdown-menu {
            width: 84px;

            .bs-searchbox {
                display: flex;
                justify-content: flex-end;
                padding: 4px 8px 4px 8px;

                input {
                    width: 56px;
                    height: 24px;
                    padding: 4px;

                    &::placeholder {
                        font-size: 12px;
                    }
                }

                .kw-search {
                    top: 10px;
                    left: 4px;
                    font-size: 12px;
                }

                .close {
                    display: none;
                }
            }

            li > a {
                padding: 6px 0 6px 8px;
                justify-content: flex-start;

                .num {
                    font-size: 12px;
                }
            }

            .flag-icon {
                margin-right: 4px;
                width: 16px;
            }
        }

        input {
            border-bottom-left-radius: 0;
            border-left: none;
            border-top-left-radius: 0;
            width: 100%;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &_invalid {
            /deep/ .form-control.dropdown-toggle {
                border-color: kw-color(kw-red, 1) !important;

                &.dropdown-toggle_focused,
                &:focus {
                    border-color: kw-color(kw-red, 1) !important;
                }
            }

            input {
                border-color: kw-color(kw-red, 1);
                color: kw-color(kw-red, 1);
            }

            /deep/.text-input {
                &.contact-number {
                    .kw__inputfield__name {
                        .kw__input {
                            border-color: #f55667;
                        }
                    }
                }
            }
        }

        /deep/ .kw__inputfield {
            &__name {
                .kw__input {
                    &:hover {
                        border-color: #afbac7;
                    }

                    &:focus {
                        border-color: kw-color(kw-blue, 1);
                    }
                }
            }
        }
    }

    &__account,
    &__password {
        background-color: kw-color(kw-white, 1);
        border: 1px solid #dee5ec;
        border-radius: 4px;
        display: flex;
        flex-basis: 350px;
        flex-flow: column nowrap;
        max-width: 350px;
        padding: 42px 24px 20px;
        position: relative;

        .kw-button {
            width: 160px;
        }

        span.kw-user,
        span.kw-lock {
            background-color: #c0d6fa;
            border-radius: 50%;
            color: kw-color(kw-blue, 1);
            font-size: 20px;
            font-weight: 700;
            height: 46px;
            left: 22px;
            line-height: 45px;
            padding-left: 13px;
            position: absolute;
            top: -23px;
            width: 47px;
        }
    }

    &__account {
        .text-input {
            margin-bottom: 24px;
        }
    }

    &__password {
        margin-left: 20px;

        .text-input {
            margin-bottom: 20px;
        }
    }
}

.settings__subtitle {
    &_account,
    &_password {
        margin: 0 0 20px;
    }
}

.btn-group {
    margin-bottom: 24px;

    &.profile-country-state {
        margin-bottom: 8px;
    }
}

@media screen and (max-width: 960px) {
    .account-settings__account,
    .account-settings__password {
        flex-basis: unset;
        margin-left: 0 !important;
        max-width: unset;
        width: 100%;
    }
    .account-settings__account {
        margin-bottom: 48px;
    }
}
</style>
