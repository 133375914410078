<template>
    <div class="confirm-handler-modal">
        <template>
            <div
                v-if="header"
                class="header"
                v-html="header"
            />
            <div class="divider" />
            <div class="footer">
                <div 
                    class="dont-split-wrap button-wrap"
                    data-cy="simple-confirm-cancel"
                    @click="cancel"
                >
                    <p class="title">{{ $t('single-keyword-title') }}</p>
                    <span class="value">{{ fullKeyword }}</span>
                </div>
                <div 
                    class="split-wrap button-wrap"
                    data-cy="simple-confirm-accept"
                    @click="confirm"
                >
                    <div class="keyword">
                        <p class="title">{{ $t('keyword-title') }}</p>
                        <span class="value">{{ keyword }}</span>
                    </div>
                    <div class="tag">
                        <p class="title">{{ $t('tag-title') }}</p>
                        <span class="value">{{ tag }}</span>
                    </div>
                </div>
            </div>
        </template>

        <global-events @keyup.enter="confirm" />
    </div>
</template>

<script>
    export default {
        name: 'BulkConfirmationModal',
        props: {
            header: {
                type: String,
                required: false,
                default: '',
            },
            fullKeyword: {
                type: String,
                required: true,
            },
            tag: {
                type: String,
                required: true,
            },
            keyword: {
                type: String,
                required: true,
            }, 
            handler: {
                type: Function,
                required: true,
            },
        },
        data () {
            return {
                loading: false,
            };
        },
        methods: {
            selectPlan (payload) {
                this.handler(payload);
                this.close();
            },
            async confirm () {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                await this.handler(true);
                this.loading = false;
                this.close();
            },
            cancel () {
                this.handler(false);
                this.close();
            },
            close () {
                this.$emit('close');
            },
        }
    };
</script>

<style lang="scss" scoped>
  @import '~sass/modal';

  .confirm-handler-modal {
    .header {
      color: #3F4248;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.01em;
      line-height: 24px;
      text-align: center;
      margin-top: 17px;
      word-break: break-word;
      padding: 0 20px;
    }

    .divider {
      height: 1px;
      background: kw-color(kw-light-grey, 1);
      margin: 18px 20px;
    }

    .body {
      @extend .custom-scroll;
      margin-bottom: 20px;
      margin-top: 24px;
      max-height: 25vh;
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 13px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;
      padding-left: 20px;
      padding-right: 20px;

      .title {
        margin-bottom: 3px;
      }

      .value {
        color: #828F9C;
        word-break: break-word;
        display: block;
      }

      .tag {
        margin-top: 3px;
      }

      .button-wrap {
        width: 48%;
        border-radius: 4px;
        padding: 9px;
        transition: all .5s;
        background: #F5F6F7;
        cursor: pointer;

        &:hover {
          background: rgba(23, 107, 250, 0.08);
        }
      }
    }
  }
</style>
