export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const getDateDetails = (date, isUTC) => {
    let dayNumber;
    let dayOfWeekNumber;
    let dayName;
    let monthNumber;
    let monthName;
    let yearNumber;
    let h;
    let min;
    let s;
    let amPm;

    if(isUTC){
        dayNumber = date.getUTCDate();
        dayOfWeekNumber = date.getUTCDay();
        monthNumber = +date.getUTCMonth();
        yearNumber = date.getUTCFullYear();
        h = date.getUTCHours();
        min = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
        s = date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds();
    } else {
        dayNumber = date.getDate();
        dayOfWeekNumber = date.getDay();
        monthNumber = +date.getMonth();
        yearNumber = date.getFullYear();
        h = date.getHours();
        min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    }
    dayName = dayNames[dayOfWeekNumber];
    monthName = monthNames[monthNumber];
    amPm = h >= 12 ? 'PM' : 'AM';

    return {
        dayNumber,
        dayOfWeekNumber,
        dayName,
        monthNumber,
        monthName,
        yearNumber,
        h,
        min,
        s,
        amPm
    }
}

const switchDateFormat = function (dateArgument, type, isUTC) {
    const date = typeof dateArgument === 'number'
        ? new Date(dateArgument)
        : dateArgument;

    let {
        dayNumber,
        dayName,
        monthNumber,
        monthName,
        yearNumber,
        h,
        min,
        s,
        amPm
    } = getDateDetails(date, isUTC);

    let dayEnding;

    switch (dayNumber) {
        case 1:
        case 21:
        case 31:
            dayEnding = 'st';
            break;
        case 2:
        case 22:
            dayEnding = 'nd';
            break;
        case 3:
        case 23:
            dayEnding = 'rd';
            break;
        default:
            dayEnding = 'th';
            break;
    }

    monthNumber += 1;

    if (isNaN(monthNumber)) {
        return;
    }

    switch (type) {
        case 'M dS, Y': // Mar 22nd, 2019
            return `${monthName.substr(0, 3)} ${dayNumber}${dayEnding}, ${yearNumber}`;

        case 'M dS, Y hh:mm': // Mar 22nd, 2019 18:13 UCT
            return  `${monthName.substr(0, 3)} ${dayNumber}${dayEnding}, ${yearNumber} ${h}:${min} UCT`;

        case 'F jS, Y': // March 22nd, 2019
            return `${monthName} ${dayNumber}${dayEnding}, ${yearNumber}`;

        case 'F jS': // March 22nd
            return `${monthName} ${dayNumber}${dayEnding}`;

        case 'hh:mm': //16:58
            return `${h}:${min}`;

        case 'F jS, Y hh:mm': // March 22nd, 2019 16:58 PST
            return `${monthName} ${dayNumber}${dayEnding}, ${yearNumber} ${h}:${min} PST`;

        case 'F j, Y': // March 22, 2019
            return `${monthName} ${dayNumber}, ${yearNumber}`;
            
        case 'l F jS, Y': // Friday March 22nd, 2019
            return `${dayName} ${monthName} ${dayNumber}${dayEnding}, ${yearNumber}`;

        case 'd/m/Y': // 22/03/19
            return `${dayNumber < 10 ? '0' + dayNumber : dayNumber}/${monthNumber < 10 ? '0' + monthNumber : monthNumber}/${yearNumber - 2000}`;

        case 'd/m/YY': // 22/03/2019
            return `${dayNumber < 10 ? '0' + dayNumber : dayNumber}/${monthNumber < 10 ? '0' + monthNumber : monthNumber}/${yearNumber}`;

        case 'm/d/YY': // 03/22/2019
            return `${monthNumber < 10 ? '0' + monthNumber : monthNumber}/${dayNumber < 10 ? '0' + dayNumber : dayNumber}/${yearNumber}`;

        case 'Y-m-d': // 2019-03-22
            return `${yearNumber}-${monthNumber < 10 ? '0' + monthNumber : monthNumber}-${dayNumber < 10 ? '0' + dayNumber : dayNumber}`;

        case 'note': // Mar 22, 2019
            return `${monthName.substr(0, 3)} ${dayNumber}, ${yearNumber}`;

        case 'pdf-date': // Mar 22, 2019 10:39:25 am
            return `${monthName.substr(0, 3)} ${dayNumber}, ${yearNumber} ${h}:${min}:${s} ${amPm}`;

        case 'new-note': // 22 Mar 2019
            return `${dayNumber} ${monthName.substr(0, 3)} ${yearNumber}`;

        case 'date-picker-timeframe': // 22 Mar, 2019
            return `${dayNumber} ${monthName.substr(0, 3)}, ${yearNumber}`;

        case 'd F Y': // 22 March 2019
            return `${dayNumber} ${monthName} ${yearNumber}`;

        case 'MMMM dd, yyyy':
            return `${monthName} ${dayNumber < 10 ? '0' + dayNumber : dayNumber}, ${yearNumber}`;

        case 'reports-timestamp': // 2020-02-14 18.39.25
            return `${yearNumber}-${monthNumber < 10 ? '0' + monthNumber : monthNumber}-${dayNumber} ${h}.${min}.${s}`;

        case 'alerts-date': // 03 MAR
            return `${dayNumber < 10 ? '0' + dayNumber : dayNumber} ${monthName.substr(0, 3).toUpperCase()}`;

        case 'chart-date': // 1 Mar
            return `${dayNumber} ${monthName.substr(0, 3)}`;

        case 'Y-m-d hh:mm': // 2020-02-14 18:39 UCT
            return `${yearNumber}-${monthNumber < 10 ? '0' + monthNumber : monthNumber}-${dayNumber < 10 ? '0' + dayNumber : dayNumber} ${h}:${min} UCT`;

        case 'bi-hourly-timestamp': // March 22nd at 16:58 UTC
            return `${monthName} ${dayNumber}${dayEnding} at ${h}:${min} UTC`;

        default: // Mar 22nd, 2019
            return `${monthName.substr(0, 3)} ${dayNumber}${dayEnding}, ${yearNumber}`;
    }
};

const getDateByPeriod = (period, endDate = null) => {
    let d = new Date();

    if (endDate) {
        d = new Date(endDate);
    }

    d.setDate(d.getDate() - period);
    return d;
};

function formatRelativeTime(timestamp) {
    const date = typeof timestamp === 'number' ||
        typeof timestamp === 'string'
        ? new Date(timestamp)
        : timestamp;
    const today = new Date();
    const diffTime = today.getTime() - date.getTime();
    const diffSeconds = Math.floor(diffTime / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffDays / 365);

    if (diffSeconds === 0) {
        return '';
    } else if (diffSeconds < 60) {
        return `${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`;
    } else if (diffMinutes < 60) {
        return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
    } else if (diffHours < 24) {
        return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    } else if (diffDays < 31) {
        return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    } else if (diffMonths < 12) {
        return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
    } else {
        return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`;
    }
}

const localToTimestamp = (localDateTime) => {
    const [datePart, timePart] = localDateTime.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    
    // Create a UTC date using Date.UTC
    const utcTimestamp = Date.UTC(year, month - 1, day, hours, minutes, seconds);
    
    return utcTimestamp;
}


const getDayFromNow = (format, daysCount = 1) => {
    const d = new Date();
    d.setDate(d.getDate() - daysCount);
    return format ? switchDateFormat(d, format) : d;
};

const getFirstDayOfWeek = (format) => {
    const curr = new Date();
    const day = curr.getDay();
    const diff = curr.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    const firstday = new Date(curr.setDate(diff));

    return format ? switchDateFormat(firstday, format) : firstday;
};

const getLastDayOfWeek = (format) => {
    const curr = new Date;
    const day = curr.getDay();
    const diff = curr.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    const last = diff + 6;
    const lastday = new Date(curr.setDate(last));
    return format ? switchDateFormat(lastday, format) : lastday;
};

const getFirstDayOfMonth = (format, count) => {
    const date = new Date();
    const month = count ? new Date(date.getFullYear(), date.getMonth() - count, 1) : date;
    const res = new Date(month.getFullYear(), month.getMonth(), 1);
    return format ? switchDateFormat(res, format) : res;
};

const getLastDayOfMonth = (format, count) => {
    const date = new Date();
    const month = count ? new Date(date.getFullYear(), date.getMonth() - count, 1) : date;
    const res = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    return format ? switchDateFormat(res, format) : res;
};

const getNextMonday = (format, date = new Date()) => {
    const nextMonday = new Date(date);
    const day = date.getDay();
    const daysUntilNextMonday = (8 - day) % 7 || 7;
    nextMonday.setDate(date.getDate() + daysUntilNextMonday);
    
    return format ? switchDateFormat(nextMonday, format) : nextMonday;
}

const getMonthsBeforeDate = (format, monthsToSubtract) => {
    const newDate = new Date();
    
    const originalMonth = newDate.getMonth();
    const originalYear = newDate.getFullYear();
    const originalDay = newDate.getDate();
    
    let targetMonth = originalMonth - monthsToSubtract;
    let targetYear = originalYear;
    
    while (targetMonth < 0) {
        targetMonth += 12;
        targetYear -= 1;
    }
    
    newDate.setFullYear(targetYear, targetMonth, originalDay);
    
    return format ? switchDateFormat(newDate, format) : newDate;
}

function getHoursLater(format, hours, date = new Date()) {
    const hoursLater = new Date(date);
    hoursLater.setHours(date.getHours() + hours);
    return format ? switchDateFormat(hoursLater, format) : hoursLater;
}

export { 
    switchDateFormat,
    getDateByPeriod,
    formatRelativeTime,
    localToTimestamp,
    getDayFromNow,
    getFirstDayOfWeek,
    getLastDayOfWeek,
    getFirstDayOfMonth,
    getLastDayOfMonth,
    getNextMonday,
    getMonthsBeforeDate,
    getHoursLater
};
