<template>
    <div
        class="confirm-handler-modal"
        data-cy="confirm-track-url-modal"
    >
        <div
            class="header"
            v-html="header"
        />
        <div class="footer">
            <kw-button
                :button-text="$t('track-broad')"
                button-type="text"
                @click="confirm(false)"
            />
            <kw-button
                :button-text="$t('track-exact')"
                button-type="primary"
                @text-mouseover="tooltip({text: tooltipText, event: $event, disabled: exact})"
                @click="confirm(true)"
            />
        </div>
    </div>
</template>

<script>
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    export default {
        name: 'ExactDomainModal',
        mixins: [ToolTipRenderMixin],
        props: {
            exact: {
                type: Boolean,
                required: true,
            },
            hasSlashes: {
                type: Boolean,
                required: true,
            },
            url: {
                type: String,
                required: true,
            },
            handler: {
                type: Function,
                required: true,
            },
        },
        computed: {
            header () {
                return this.exact ? this.$t('we-noticed-broad') : this.$t('we-noticed-exact');
            },
            tooltipText () {
                return !this.exact
                    ? this.$t('track-and-ignore', {page: `<strong>/${this.url.split('/').slice(1).join('/')}</strong>`})
                    : '';
            },
        },
        methods: {
            confirm (exact) {
                this.handler(exact);
                this.$emit('close');
            },
        }
    };
</script>

<style lang="scss" scoped>
  @import '~sass/modal';

  .confirm-handler-modal {
    padding: 24px 8px 24px 24px;

    .header {
      color: kw-color(kw-black, 7);
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.01em;
      line-height: 24px;
      padding-right: 13px;
      text-align: center;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding-top: 8px;
      padding-right: 13px;
    }
  }
</style>
