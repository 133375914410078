import router from '@/routes';
import { updateProjectPageTitle } from '@/helpers/update-document-title';
import { cookieGetters } from '@/helpers/cookieManager';

const createDashboardOverviewPlugin = ({ SET_USER_DATA, SET_OVERVIEW_CHARTS_DATA_PAGE }) => store => {
    store.subscribe(({ type, payload }) => {
        if (router.currentRoute.name !== 'Overview') {
            return;
        }

        const { getters, dispatch } = store;

        switch (type) {
            case SET_USER_DATA:
                // if all metrics empty and showall cookie was put by SET_USER_DATA mutation - dispatch action (fix direct link and incognito cases)
                // or if showall cookie is wrong - if user change account at the legacy and returns to the beta
                if (getters.getOverviewData.showall !== payload.showall && !getters.getOverviewDataLoading) {
                    dispatch('fetchOverviewData');
                }

                break;

            case SET_OVERVIEW_CHARTS_DATA_PAGE:
                dispatch('fetchOverviewChartsData');
                break;
        }
    });
};

const createCurrentProjectPlugin = ({ SET_CURRENT_GROUP_OBJECT }) => store => {
    store.subscribe(async ({ type, payload }) => {
        switch (type) {
            case SET_CURRENT_GROUP_OBJECT:
                store.dispatch('clearRefreshProgress');

                if (store.getters.getViewkeyView) {
                    const routeName = router.history.current.name;
                    updateProjectPageTitle(routeName, store.getters.getCurrentProject);
                    if (
                        payload.password_valid &&
                        routeName !== 'pdfView' &&
                        routeName !== 'shareOfVoice' &&
                        routeName !== 'shareOfVoiceWithHash' &&
                        routeName !== 'shareKeywordsTable' &&
                        routeName !== 'shareKeywordsTableWithHash'
                    ) {
                        await store.dispatch('fetchViewkeyMetricsAndChart');
                    }

                    return;
                }

                store.dispatch('resetTagFilter');

                // payload
                //     ? store.dispatch('fetchMetrics', { includeChart: true })
                //     : store.dispatch('resetMetricsData');

                // fetch metrics for shareViewkey
                if (router.currentRoute.name === 'shareViewkey'){
                    if (store.getters.getDisplaySetting('metrics') !== 'hide') {
                        store.dispatch('fetchViewkeyMetrics');
                    }
                }
                // fetch keywords if current project changed
                if (router.currentRoute.name === 'keywordList' && payload) {
                    const queryTag = router.currentRoute.query?.tag;
                    if (store.getters.getDisplaySetting('metrics') !== 'hide' && !queryTag) {
                        store.dispatch('fetchMetrics');
                    }

                    if (!router.currentRoute.params.skipFetch) {
                        if (queryTag && !store.getters.getActiveTag.id) {
                            const currentProject = store.getters.getCurrentProject;
                            if (currentProject) {
                                const foundedTag = currentProject.tags.find((item) => item.id === +queryTag);
                                if (foundedTag) {
                                    store.dispatch('addActiveTag', foundedTag);
                                    if (currentProject.parent) {
                                        const parentProject = store.getters.getActiveProjects.find(parent => parent.name === currentProject.parent);
                                        store.dispatch('setOpenedProjectsKeys', { project: parentProject });
                                    }
                                    store.dispatch('setOpenedProjectsKeys', { project: currentProject });
                                } else {
                                    router.replace({ 'query.tag': null });
                                }
                            }
                        }
                        let not_reset_page = false;
                        if (store.getters.getDisplayLength <= 5000) {
                            const { query: { keyword_id } } = router.history.current;
                            const projectId = store.getters.getCurrentProject.project_id;

                            if (keyword_id && projectId && !store.isSearch) {
                                await store.dispatch('fetchKeywordPosition', { keyword_id, projectId });
                                not_reset_page = true;
                            }
                        }
                        await store.dispatch('fetchKeywords', { id: payload.id, not_reset_page });
                        store.dispatch('fetchProjectNotes', { id: payload.id });
                    }
                }

                break;
        }
    });
};

const createManageProjectsPlugin = ({ SET_ACTIVE_GROUPS }, { FIRST_GROUP_NAME }) => store => {
    store.subscribe(async ({ type, payload }, state) => {
        switch (type) {
            case SET_ACTIVE_GROUPS:
                if (payload.length) {
                    if (state.tutorial.tutorialMode) {
                        //     const myFirstProject = payload.find(project => project.id.toLowerCase() === FIRST_GROUP_NAME.toLowerCase());

                        //     if (myFirstProject) {
                        //         router.push({name: 'keywordList', params: {id: myFirstProject.id}});
                        //     } else {
                        //         await store.dispatch('addEmptyProject', fastProject(FIRST_GROUP_NAME));
                        //     }
                    } else {
                        let lastOpened = null;

                        // set last opened project id if it had not removed before
                        payload.forEach(project => {
                            if (localStorage.getItem('lastOpenedProjectId') === project.id) {
                                lastOpened = project.id;
                            }
                        });

                        // When application is loaded and there is no category selected via URL path
                        // we select first available category from the list if any.
                        if (router.history.current.path === '/') {
                            router.push({
                                name: 'keywordList',
                                params: { id: lastOpened ? lastOpened : payload[0] ? payload[0].id : null },
                            });
                        }
                    }

                    if (!state.tutorial.tutorialMode) {
                        store.dispatch('setCurrentProject');
                    }

                    updateProjectPageTitle(router.history.current.name, store.getters.getCurrentProject);

                    if (router.currentRoute.name === 'keywordList') {
                        store.dispatch('firstRefreshesCheck', { overview: false });

                        if (!store.getters.getCurrentProject) {
                            router.replace({ name: 'PageNotFound' });
                        }
                    }
                }

                // if payload is empty array (no projects at all) we should create first empty project (no matter tutorial mode or not)
                if (!payload.length) {
                    await store.dispatch('checkAvailabilityOfArchivedProjects');
                    if (store.getters.getArchivedProjectsAvailable) {
                        store.dispatch('setAddProjectScreenOpened', true);
                    } else {
                        // store.dispatch('setTutorialMode', true);
                        // await store.dispatch('addEmptyProject', fastProject(FIRST_GROUP_NAME));
                    }
                }

                break;
        }
    });
};

const updateIntercomUserInformationPlugin = mutations => store => {
    const {
        SET_USED,
        SET_USER_DATA,
        SET_CURRENT_PLAN,
        SET_ACCOUNT_SETTINGS,
        SET_SUBSCRIPTION_DATA,
        SET_USER_SETTINGS
    } = mutations;

    store.subscribe(({ type, payload }, payload2) => {
        const { getters } = store;

        const {
            intercomUserId = '',
            intercomHash = '',
        } = getters.getUserData;

        const newsletter_subscription = getters.getUserSetting('newsletter_subscription')

        const settings = {
            email: cookieGetters.getUserProfile()?.email ?? '',
            user_hash: intercomHash,
            user_id: intercomUserId,
        };

        switch (type) {
            case SET_USED:
                const {
                    getUsedKeywords = 0,
                } = getters;
                settings.package_used = getUsedKeywords;
                break;
            case SET_USER_DATA:
                const {
                    getAccountSetting,
                    isMasterUser,
                    getUserData,
                } = getters;

                if (isMasterUser) {
                    settings.name = getAccountSetting('name');
                    settings.notes = 'Main Account';
                } else {
                    settings.notes = _.upperFirst(getUserData.accessLevel ?? '');
                }

                settings.created_at = getUserData.createdAtTs ?? '';
                settings.addon_masteremail = getUserData.addonMasterEmail ?? '';
                break;
            case SET_ACCOUNT_SETTINGS:
                if (isMasterUser) {
                    settings.name = getAccountSetting('name');
                }
                break;
            case SET_CURRENT_PLAN:
                const {
                    subscription: { next_billing_at_ts = '' },
                } = getters.getCurrentPlan;
                settings.subscription = next_billing_at_ts;
                break;
            case SET_SUBSCRIPTION_DATA:
                const { getCurrentPlan } = getters;
                settings.package_name =
                    getCurrentPlan.subscription?.active === 2 &&
                        getCurrentPlan.subscription?.expire_post_trial
                        ? 'PACKAGE 200 DAILY'
                        : (getCurrentPlan.subscription?.package_name ?? '');
                settings.stripe_subscription_status = getCurrentPlan.subscription?.active === 2 ? 'trialing' : '';
                settings.active = getCurrentPlan.subscription?.active ?? 0;
                settings.billingcycle = getCurrentPlan.subscription?.billing_cycle ?? '';
                settings.package_price = getCurrentPlan.subscription?.nextpay ?? 0;
                settings.package_limit = getCurrentPlan.subscription?.package ?? 0;
                settings.days_left_on_trial = getCurrentPlan.subscription?.active === 0 ? '-' : `${getCurrentPlan.subscription?.trial_period ?? 0}`;
                break;
            case SET_USER_SETTINGS:
                settings.newsletter_subscription = newsletter_subscription;   
                break;
        }

        if (window.Intercom && Object.keys(settings).length > 3) {
            window.Intercom('update', settings);
        }
    });
};

const updateCurrencyForCPCPlugin = ({ SET_DISPLAY_SETTINGS, SET_CURRENT_GROUP_OBJECT }) =>
    store => {
        store.subscribe(async ({ type }) => {
            const { getters, dispatch } = store;
            const {
                getCurrentProject,
                getDisplaySetting,
                getCurrentCurrency,
                getCurrencyByCode,
            } = getters;

            switch (type) {
                case SET_DISPLAY_SETTINGS:
                    if (getDisplaySetting('currency_code') === getCurrentCurrency.code) {
                        return
                    }
                case SET_CURRENT_GROUP_OBJECT:
                    const code = getCurrentProject?.currency_code ??
                        getDisplaySetting('currency_code') ?? 'USD';
                    const symbol = getCurrencyByCode(code)?.symbol ??
                        getCurrentProject?.currency_symbol ?? '$';

                    dispatch('setCurrentCurrency', { code, symbol });
                    break;
            }
        });
    };

export {
    updateCurrencyForCPCPlugin,
    createCurrentProjectPlugin,
    createManageProjectsPlugin,
    createDashboardOverviewPlugin,
    updateIntercomUserInformationPlugin,
};
