<template>
    <div class="block-wrapper">
        <div class="header-wrapper">
            <chart-header
                :title="$t('available-chart-titles.serp_feature_performance')"
                :toolTip="$t('serp_feature_performance-tooltip')"
                showTimeframe
                isHidable
                @onHide="onHide"
                :date="date"
                @onDateSelect="onDateSelect"
                :isHideDisabled="isLoading"
                :includeAllTime="false"
                exactDate
                :defaultKey="comparedDate"
            />
        </div>
        <div class="table-content">
            <loader v-if="getProjectSerpFeaturePerformance.loading" />
            <div v-else>
                <div v-if="getProjectSerpFeaturePerformance.data" class="count-block">
                    <p class="count">
                        <span class="current">{{ getProjectSerpFeaturePerformance.data.current }}</span>/{{ getProjectSerpFeaturePerformance.data.total }}
                    </p>
                    <p v-if="getProjectSerpFeaturePerformance.data.featuredChange" class="percentage">
                        <span :class="getArrowClass"></span>
                        {{ getProjectSerpFeaturePerformance.data.featuredChange.amount }}%
                    </p>
                </div>
                <custom-table
                    :config="config"
                    :items="items"
                    :downloadable="false"
                    :showChart="true"
                    :copyable="false"
                    withBorder
                />
            </div>
        </div>
    </div>
</template>
 
<script>
    import { mapGetters, mapActions } from "vuex";
    import CustomTable from 'components/ui-elements/CustomTable';
    import { serpFeaturePerformanceTableConfig } from './config/tablesConfig'
    import ChartBlockHeader from "./ChartBlockHeader.vue";
    import { exactDatePickerList } from '@/components/date-range-picker/datePickerList'

    export default {
        name: 'SerpFeaturePerformanceBlock',
        components: {
            'custom-table': CustomTable,
            'chart-header': ChartBlockHeader
        },
        data(){
            return {
                config: serpFeaturePerformanceTableConfig,
                date: null,
                isLoading: false,
                comparedDate: null,
                fetched: false
            }
        },
        watch: {
            getActiveTag: function() {
                this.fetchData();
            },
        },
        mounted(){
            if(!this.fetched && this.getCurrentProject?.project_id){
                this.fetchData();
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentProject',
                'getProjectSerpFeaturePerformance',
                'getActiveTag',
                'getProjectOverviewOptionItem'
            ]),
            items(){
                return this.getProjectSerpFeaturePerformance.data?.serp_features || [];
            },
            getArrowClass(){
                return `icon-arrow_4${this.getProjectSerpFeaturePerformance.data.featuredChange?.arrow==='down' ? ' icon-rotate-down' : ''}`
            }
        },
        methods: {
            ...mapActions([
                'setProjectOverviewOptionIsVisible',
                'fetchProjectSerpFeaturePerformance',
                'updateProjectTimeFrame'
            ]),
            onHide() {
                this.isLoading = true;
                this.setProjectOverviewOptionIsVisible({ 
                    projectId: this.getCurrentProject.id,
                    option: "serp_feature_performance",
                    value: false 
                }).finally(()=>{
                    this.isLoading = false;
                });
            },
            onDateSelect(date, key) {
                this.updateProjectTimeFrame({
                    projectId: this.getCurrentProject.id,
                    option: 'serp_feature_performance',
                    value: {
                        date: date
                    },
                    interval: {
                        compared_date: key
                    }
                })
                this.fetchData(date, key);
            },
            fetchData(dateValue, key){
                this.fetched = true;

                const { compared_date } = this.getProjectOverviewOptionItem('serp_feature_performance');
                let intervalDate = null;

                if(key && key !== this.comparedDate){
                    this.date = null;
                    this.comparedDate = key;
                } else {
                    this.comparedDate = compared_date;
                    if(!dateValue){
                        if(!this.date && compared_date){
                            this.comparedDate = compared_date;
                            intervalDate = exactDatePickerList[compared_date].value;
                        } else {
                            this.date = null;
                        }
                    } else {
                        this.date = dateValue;
                    }
                }
                const date = intervalDate ? intervalDate : this.date;

                const tagId = this.$route.query?.tag;
                this.fetchProjectSerpFeaturePerformance({ 
                    projectId: this.getCurrentProject.project_id, 
                    tagId,
                    comparedDate: date || dateValue,
                });
            },
        }
    };
</script>

<style lang="scss" scoped>
    .block-wrapper {
        width: 100%;

        .header-wrapper {
            margin-bottom: 10px;
        }

        .table-content {
            width: 100%;
            height: 100%;
            min-height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            & > * {
                width: 100%;
            }

            .count-block {
                display: flex;
                align-items: flex-end;
                align-self: flex-start;
                gap: 16px;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.01em;
                margin-bottom: 10px;

                p {
                    margin: 0;
                }

                .current {
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }

        .percentage {
            font-family: Roboto;
            font-weight: 700;
            display: inline-flex;
            align-items: center;
            gap: 4px;
        }

        /deep/ .icon-arrow_4 {
            margin-right: 2px;
            font-size: 10px;
            color: kw-color(kw-green, 1);

            &.icon-rotate-down{
                color: kw-color(kw-red, 1);
            }
        }
        
        /deep/ .custom-table {
            min-height: 250px;

            .custom-table__section_head {
                .custom-table__row {
                    text-transform: capitalize;
                }
            }

            .custom-table__cell {
                &:nth-child(2) {
                    flex: 1;
                }

                &:nth-child(3),
                &:nth-child(4) {
                    justify-content: flex-end;
                }
            }
            .custom-table__section_body {
                .custom-table__cell {
                    &:nth-child(1) {
                        font-weight: 500;

                        span {
                            display: inline-block;
                        }

                        .feature-wrapper {
                            padding: 3px 0;
                            display: inline-flex;
                            align-items: center;
                            gap: 6px;

                            .kw-sf-icon {
                                width: 16px;
                                min-width: 16px;
                                height: 16px;
                                opacity: 1;
                                background-size: contain;
                            }
                        }
                    }

                    &:nth-child(3),
                    &:nth-child(4) {
                        font-weight: 700;
                    }
                }

                .custom-table__row {
                    min-height: 34px;
                    height: unset;
                }
            }
        }
    }
</style>
