const getRangeTitle = range =>
    range.includes('p') ? `Top ${range.replace('p', '')} Pages` : `Top ${range}`;

const processChardData = data => {
    if (!data.length) {
        return data;
    }

    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    let total = 0;
    let totalLastItems = 0;
    // Check if the chart has data
    _.clone(data).map((chart, index) => {
        // ignore the first item; the unranked kws
        if (index !== 0) {
            totalLastItems = totalLastItems + chart[1][chart[1].length - 1];
            total = total + chart[1].reduce(reducer);
        }
    });
    // if char has data and there is a drop
    if (total > 0 && totalLastItems === 0) {
        // remove the last item, prevent the drop
        data.forEach(el => el[1].pop());
    }
    return data;
};

const calculateCompetitorsChange = value => {
    const dot = `
        <span class="empty-value" style="width: 9px;">
            <span class="dot-neutral" style="margin: auto;"></span>
        </span>
    `;

    if (!value.changes || (!value.changes.month && typeof value.changes.month !== 'number')) {
        return dot;
    }

    const change = value.percentage - value.changes.month;
    if (change === 0) {
        return dot;
    }

    return `
        <span class="icon-arrow_4 ${change < 0 && 'icon-rotate-down'}${change ? '' : ' transparent'}"></span>
        <span class="change${change < 0 ? ' negative' : change > 0 ? ' positive' : ''}">
        ${change ? Math.abs(change).toFixed(2) : ''}%</span>
    `;
};
export { getRangeTitle, processChardData, calculateCompetitorsChange };
