import {switchDateFormat} from '@/helpers/format-date-service';
import {VIEW_ACCESS, ADDON_ACCESS, ADMIN_ACCESS} from '@/configs/accessConfig.json';
import i18n from '@/i18n';

const attachmentFormatOptions = [
    {
        label: `Timestamp (ie. SEO Progress ${switchDateFormat(new Date(), 'reports-timestamp')}.pdf)`,
        value: 'timestamp',
    },
    {
        label: 'Project Name (ie. keyword.pdf)',
        value: 'categoryname',
    },
];

const sharedAccessTableHeaders = [
    {title: i18n.t('email')},
    {title: i18n.t('password'), tooltip: i18n.t('set-password-for-addon')},
    {title: i18n.t('access'), tooltip: i18n.t('addon-accesses')},
    {title: i18n.t('restrictions'), tooltip: i18n.t('restrict-projects-for-addon')}
];

const sharedAccessOptions = [
    {value: VIEW_ACCESS, label: i18n.t('view-only')},
    {value: ADDON_ACCESS, label: i18n.t('addon-user')},
    {value: ADMIN_ACCESS, label: i18n.t('admin-access')},
];

const sharedAccessEnableOptions = [
    {value: false, label: i18n.t('enabled')},
    {value: true, label: i18n.t('disabled')},
];

export {
    attachmentFormatOptions,
    sharedAccessEnableOptions,
    sharedAccessOptions,
    sharedAccessTableHeaders,
};