<template>
    <div class="link-item">
        <h5 class="link-item__subtitle">
            {{ project.name }}
        </h5>
        <div class="link-item__field">
            <div class="link-item__input-wrapper">
                <kw-input-field
                    :input-id="`viewkey-${project.id}`"
                    :label="$t('viewkey')"
                    :value="viewkeyLink(project)"
                    class="text-input"
                    name="projectName"
                    :placeholder="$t('viewkey')"
                    type="text"
                    readonly
                    @click="$event.target.select()"
                />
            </div>
            <kw-button
                button-type="text"
                :button-text="$t('copy')"
                data-cy="overview-viewkey-copy"
                @click="
                    copyViewkey(
                        `viewkey-${project.id}`,
                        viewkeyLink(project),
                        project,
                    )
                "
            />
        </div>
    </div>
</template>

<script>
import ViewkeyLinks from '@/mixins/ViewkeyLinks';
export default {
    name: 'ViewkeyLinkItem',
    mixins: [ViewkeyLinks],
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.link-item__subtitle {
    display: inline-block;
    margin: 16px 0 0 0;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #909399;
    font-size: 12px;
    font-weight: 500;
}

.link-item__field {
    display: flex;
    flex-direction: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 0;
    margin-top: 16px;

    input {
        width: 100%;
    }
}

.link-item__input-wrapper {
    width: 412px;

    @media screen and (max-width: 959px) {
        width: 100%;
    }
}

button {
    &.kw-btn-text {
        margin-left: 8px;
        color: kw-color(kw-blue, 1) !important;
    }
}
</style>
