<template>
    <div class="option" @click="$emit('onSelect')">
        <div class="content">
            <div v-if="icon" class="icon">
                <span :class="icon" />
            </div>
            <p class="text">
                {{ label }}
            </p>
        </div>

        <div class="next-icon">
            <span class="kw kw-arrow-right"></span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ModalOption',

    props: {
        label: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: false
        }
    },
};
</script>

<style lang="scss" scoped>
.option {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid kw-color(kw-white, 6);

    .content {
        display: flex;
        flex: 1;
        align-items: center;
        gap: 12px;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid kw-color(kw-white, 6);
            background-color: kw-color(kw-white, 1);

            span {
                display: inline-block;
                width: 20px;
                height: 20px;
            }
        }

        .text {
            font-family: 'Aeonik';
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            margin: 0;
        }
    }

    .next-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid kw-color(kw-white, 6);
        background-color: kw-color(kw-white, 1);

        /deep/ .kw-arrow-right {
            color: kw-color(kw-blue, 1)
        }
    }
    &:hover{
        background: kw-color(kw-blue, 2);
    }
}
</style>
