import i18n from '@/i18n';

const getUrlColumns = [
    { 'title': i18n.t('date'), class: 'table-header date' },
    { 'title': i18n.t('rank'), class: 'table-header rank' },
    { 'title': i18n.t('url'), class: 'table-header url' },
    { 'title': i18n.t('historical-serp'), class: 'table-header historical-serp' },
];

const exportPeriodConfig = [
    { name: i18n.t('export-live-data'), value: 'live' },
    { name: i18n.t('export-data-range'), value: 'range' },
];

const deviceOptions = [
    { name: `<i class="kw kw-airplay"></i> ${i18n.t('desktop')}`, value: 'desktop' },
    { name: `<i class="kw kw-smartphone"></i> ${i18n.t('mobile')}`, value: 'mobile' },
    { name: `<i class="kw kw-desktop-mobile"></i> ${i18n.t('desktop')} & ${i18n.t('mobile')}`, value: 'desktop-mobile' },
];

export {
    getUrlColumns,
    deviceOptions,
    exportPeriodConfig,
};