<template functional>
    <div
        class="table-cell table-cell_start"
        @click.stop="listeners['open-input']"
    >
        <template v-if="props.keyword.crawled_at">
            <input
                v-if="parent.keywordForStart.kwid === props.keyword.id"
                ref="keywordStartInput"
                class="text-input"
                :value="props.value"
                @blur="parent.saveAndCloseInput"
                @input="e => listeners['update:value'](e.target.value)"
                @keypress.13.prevent="parent.$refs.keywordStartInput.blur()"
            >

            <span
                v-else
                v-html="$options.startValue(props.keyword.start)"
            />
        </template>
        <div
            v-else-if="
                props.routeName === 'keywordList' ||
                props.routeName === 'searchResults' ||
                props.routeName === 'shareViewkey' ||
                props.routeName === 'shareKeywordsTable'
            "
            class="start-refresh-icon"
        >
            <span
                class="kw kw-refresh-ccw"
                @mouseover="parent.tooltip({text: parent.$t('refresh-state-tooltip'), event: $event})"
            />
        </div>
        <span
            v-else
            class="empty-value no-value"
            :data-important-tooltip="props.routeName !== 'pdfView' ? parent.$t('not-changed-data') : ''"
        >
            &mdash;
        </span>
    </div>
</template>

<script>
    export default {
        name: 'StartField',
        startValue: start => {
            return start === 0
                ? '<span class="empty-value">> 100</span>'
                : start === null ? '<span class="empty-value no-value">&mdash;</span>' : start;
        },
    };
</script>

<style lang="scss">
  .table-cell_start {
    align-items: center;
    cursor: pointer;
    height: 100%;

    span {
      width: 100%;
      text-align: right;

      &.empty-value {
        &.no-value {
          &:hover {
            text-decoration: none;
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &.kw-refresh-ccw {
        color: kw-color(kw-grey, 1);
        font-size: 16px;
        width: auto;
        font-weight: 700;
        animation: infiniteRotating 2s linear infinite;
      }
    }

    .start-refresh-icon {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      span {
        &.kw-refresh-ccw {
          color: kw-color(kw-grey, 1);
          font-size: 16px;
          font-weight: 700;
          cursor: default;
          animation: infiniteRotating 2s linear infinite;
        }
      }
    }

    input {
      height: 20px !important;
      padding: 4px !important;
      width: 100%;
    }
  }
</style>
