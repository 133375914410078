import { render, staticRenderFns } from "./TagRenderer.vue?vue&type=template&id=14a3e6e8&scoped=true&"
import script from "./TagRenderer.vue?vue&type=script&lang=js&"
export * from "./TagRenderer.vue?vue&type=script&lang=js&"
import style0 from "./TagRenderer.vue?vue&type=style&index=0&id=14a3e6e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a3e6e8",
  null
  
)

export default component.exports