<template>
    <div class="connection-rules-modal" data-cy="connection-rules-modal">
        <closer @click="close" />
        <div
            class="custom-modal__header custom-modal_draggable"
            data-cy="connection-rules-modal-header"
        >
            {{ $t('connection-rules-modal-header') }}
        </div>
        <div
            class="custom-modal__body custom-modal__body_export"
            data-cy="connection-rules-modal-body"
        >
            <div>{{ $t('customize-rules') }}</div>

            <form
                class="form"
                :novalidate="true"
                @submit.prevent="validateForm"
            >
                <div class="modal-row">
                    <div class="modal-label">
                        {{ $t('average-position-label') }}
                    </div>
                    <div class="inputs">
                        <kw-input-field
                            :input-id="`position-min`"
                            class="number-input"
                            name="positionMin"
                            :data-vv-as="$t('average-pos-min')"
                            v-model.trim="rules.positionMin"
                            placeholder="1"
                            type="number"
                            v-validate="
                                `min_value:1|max_value:${
                                    this.rules.positionMax
                                        ? this.rules.positionMax
                                        : 100
                                }`
                            "
                            :error-data="positionMinError"
                        />-<kw-input-field
                            :input-id="`position-max`"
                            class="number-input"
                            name="positionMax"
                            :data-vv-as="$t('average-pos-max')"
                            v-model.trim="rules.positionMax"
                            placeholder="100"
                            type="number"
                            v-validate="
                                `min_value:${
                                    this.rules.positionMin
                                        ? this.rules.positionMin
                                        : 1
                                }|max_value:100`
                            "
                            :error-data="positionMaxError"
                        />
                    </div>
                </div>
                <div class="modal-row">
                    <div class="modal-label">
                        {{ $t('average-impression-label') }}
                    </div>
                    <div class="inputs">
                        <kw-input-field
                            :input-id="`impression-min`"
                            class="number-input"
                            name="impressionMin"
                            :data-vv-as="$t('average-imp-min')"
                            v-model.trim="rules.impressionMin"
                            placeholder="1"
                            type="number"
                            v-validate="
                                `min_value:0|max_value:${
                                    this.rules.impressionMax
                                        ? this.rules.impressionMax
                                        : 100
                                }`
                            "
                            :error-data="impressionMinError"
                        />-<kw-input-field
                            :input-id="`impression-max`"
                            class="number-input"
                            name="impressionMax"
                            :data-vv-as="$t('average-imp-max')"
                            v-model.trim="rules.impressionMax"
                            placeholder="100"
                            type="number"
                            v-validate="
                                `min_value:${
                                    this.rules.impressionMin
                                        ? this.rules.impressionMin
                                        : 0
                                }|max_value:100`
                            "
                            :error-data="impressionMaxError"
                        />
                    </div>
                </div>
                <div class="modal-row">
                    <div class="modal-label">
                        {{ $t('average-ctr-label') }}
                    </div>
                    <div class="inputs">
                        <kw-input-field
                            :input-id="`ctr-min`"
                            class="number-input"
                            name="ctrMin"
                            :data-vv-as="$t('average-ctr-min')"
                            v-model.trim="rules.ctrMin"
                            placeholder="0.01%"
                            type="number"
                            :step="0.01"
                            v-validate="
                                `min_value:0|max_value:${
                                    this.rules.ctrMax ? this.rules.ctrMax : 100
                                }`
                            "
                            :error-data="ctrMinError"
                        />-<kw-input-field
                            :input-id="`ctr-max`"
                            class="number-input"
                            name="ctrMax"
                            :data-vv-as="$t('average-ctr-max')"
                            v-model.trim="rules.ctrMax"
                            placeholder="100%"
                            type="number"
                            v-validate="
                                `min_value:${
                                    this.rules.ctrMin ? this.rules.ctrMin : 0
                                }|max_value:100`
                            "
                            :error-data="ctrMaxError"
                        />
                    </div>
                </div>
                <div class="modal-row">
                    <div class="modal-label">
                        {{ $t('date-range') }}
                    </div>
                    <custom-v-select
                        class="date-range"
                        options-label="label"
                        options-value="value"
                        data-cy="date-range"
                        :options="periods"
                        :disableDeSelect="true"
                        :value.sync="rules.period"
                        @change="dateRange($event)"
                    />
                </div>
                <div class="modal-row hide">
                    <div class="dropdowns">
                        <div class="modal-dropdown">
                            <label class="text-label" for="country">
                                {{ $t('region') }}
                            </label>
                            <custom-v-select
                                id="country"
                                options-label="name"
                                options-value="country"
                                select-type="country"
                                data-cy="connection-rules-country-dropdown"
                                :placeholder="$t('select-a-country')"
                                search
                                :class="{
                                    'custom-dropdown_filled': rules.country,
                                }"
                                :options="regionsForSelect"
                                :value.sync="rules.country"
                                dropdown-menu-data-cy="edit-connection-rules-country-list"
                                dropdown-search-data-cy="edit-connection-rules-country-search"
                                @change="changeCountry($event)"
                            />
                        </div>
                        <div class="modal-dropdown">
                            <label class="text-label" for="device">
                                {{ $t('device') }}
                            </label>
                            <custom-v-select
                                id="device"
                                options-class="class"
                                options-label="name"
                                options-value="value"
                                select-type="device"
                                data-cy="connection-rules-device-dropdown"
                                :placeholder="$t('select-a-device')"
                                :class="{
                                    'custom-dropdown_filled': rules.device,
                                }"
                                :options="deviceOptions"
                                :value.sync="rules.device"
                                dropdown-menu-data-cy="edit-connection-rules-device-list"
                                dropdown-search-data-cy="edit-connection-rules-device-search"
                                @change="changeDevice($event)"
                            />
                        </div>
                    </div>
                </div>
                <div class="modal-row">
                    <div class="checkbox-row">
                        <checkbox
                            id="rules-autoAdd-checkbox"
                            :data-cy="`checkbox-rules-autoAdd`"
                            :checkbox="rules.autoAdd"
                            v-model="rules.autoAdd"
                            @checkbox-click="rules.autoAdd = !rules.autoAdd"
                        />
                        <label
                            for="rules-autoAdd-checkbox"
                            class="rules-autoAdd__label rules-autoAdd__label_other"
                            v-text="$t('automatically-create-keyword-label')"
                            @click="rules.autoAdd = !rules.autoAdd"
                        />
                    </div>
                </div>
                <div class="connection-rules-buttons">
                    <kw-button
                        :button-text="$t('cancel')"
                        button-type="secondary"
                        :disabled="getGSCProfileConnectionsUpdating"
                        @click="close"
                    />
                    <kw-button
                        type="submit"
                        button-type="primary"
                        :button-text="$t('save')"
                        :button-disabled="getGSCProfileConnectionsUpdating"
                        :loading="getGSCProfileConnectionsUpdating"
                    />
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { deviceOptions } from 'components/modals/configs';
import { regionsForSelect } from '@/helpers/regions-service';
import { periods } from './configs/index';

export default {
    name: 'GSCProfileConnectionRulesModal',
    props: {
        connection: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            rules: {
                positionMin: this.connection?.rules?.positionMin ?? '',
                positionMax: this.connection?.rules?.positionMax ?? '',
                impressionMin: this.connection?.rules?.impressionMin ?? '',
                impressionMax: this.connection?.rules?.impressionMax ?? '',
                ctrMin: this.connection?.rules?.ctrMin ?? '',
                ctrMax: this.connection?.rules?.ctrMax ?? '',
                period: this.connection?.rules?.period ?? 30,
                country:
                    this.connection?.rules.length === 0
                        ? regionsForSelect[0].country
                        : this.connection.rules.country ?? '',
                device:
                    this.connection?.rules.length === 0
                        ? deviceOptions[0].value
                        : this.connection.rules.device ?? '',
                autoAdd: this.connection?.rules?.autoAdd ?? false,
            },
            deviceOptions: deviceOptions.filter(
                el => el.value != 'desktop-mobile',
            ),
            regionsForSelect,
            periods,
        };
    },
    computed: {
        ...mapGetters(['getGSCProfileConnectionsUpdating']),
        positionMinError() {
            return {
                error: this.errors.collect('positionMin').length,
            };
        },
        positionMaxError() {
            return {
                error: this.errors.collect('positionMax').length,
            };
        },
        impressionMinError() {
            return {
                error: this.errors.collect('impressionMin').length,
            };
        },
        impressionMaxError() {
            return {
                error: this.errors.collect('impressionMax').length,
            };
        },
        ctrMinError() {
            return {
                error: this.errors.collect('ctrMin').length,
            };
        },
        ctrMaxError() {
            return {
                error: this.errors.collect('ctrMax').length,
            };
        },
    },
    methods: {
        ...mapActions(['updateGSCProfileConnection']),
        dateRange(elem) {
            this.rules.period = elem;
        },
        close() {
            this.$emit('close');
        },
        changeDevice(newDevice) {
            this.rules.device = newDevice;
        },
        changeCountry(newCountry) {
            this.rules.country = newCountry;
        },
        async validateForm() {
            await this.$validator.validateAll();

            if (this.errors.all().length) {
                this.errors.all().forEach(el => this.$toastr.e(el));
                return;
            }

            this.save();
        },
        async save() {
            this.rules.country = '';
            this.rules.device = '';
            let data = {
                connection: this.connection.id,
                rules: this.rules,
                fetchAll: false,
            };

            try {
                await this.updateGSCProfileConnection(data);
            } catch (e) {
                //
            } finally {
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/modal';
.button-closer {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 13px;
}

.date-range {
    width: 135px !important;
}

.connection-rules-modal {
    padding: 24px 16px;

    .custom-modal__header {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #17181b;
    }

    .custom-modal__body {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #17181b;

        .modal-row {
            margin-top: 16px;

            .modal-label {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 24px;
            }

            .inputs,
            .dropdowns {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .inputs {
                /deep/ .kw__input {
                    text-align: center;
                    width: 135px;
                }
            }

            .modal-dropdown {
                width: 50%;

                &:last-child {
                    margin-left: 16px;
                }
            }
            .checkbox-row {
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                label {
                    width: 310px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                }
            }

            .btn-group {
                width: 160px;
            }
        }

        .connection-rules-buttons {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                width: 158px;
            }
        }
    }
}
</style>

<style lang="scss">
#rules-autoAdd-checkbox {
    position: unset;

    span {
        position: unset;
        margin-left: 3px;
        margin-top: -1px;
    }
}
#device {
    .kw {
        color: kw-color(kw-blue, 1);
    }

    .dropdown-menu {
        .kw {
            color: kw-color(kw-black, 1);
        }
    }

    .selectedOption {
        .kw {
            color: kw-color(kw-white, 1);
        }
    }
}
</style>
