import client from '../client';
import interceptors from '../interceptors';
const reportsInterceptor = interceptors.get('reports');

client.interceptors.response.use(
    reportsInterceptor.s,
    reportsInterceptor.e,
);

const resource = 'reports';

export default {
    addReport: reportData => client.post(resource, reportData),
    deleteReport: id => client.delete(`${resource}/${id}`),
    fetchReports: async () => await client.get(`${resource}/scheduled`),
    fetchReportsHistory: id => client.get(`${resource}/${id}/history`),
    sendReport: report_id => client.post(`${resource}/send-now`, { report_id }),

    downloadReport: async payload => await client.post(`${resource}/download`, payload),
    downloadReportForShare: async (shortKey, payload) =>
        await client.post(`shared/${shortKey}/${resource}/download`, payload),
    downloadReportForShareWithHash: async (hash, payload) =>
        await client.post(`projects/shared/link/${hash}/reports/download`, payload),
    checkStatus: async activity_log_id => await client.get(`${resource}/${activity_log_id}/check-status`),
    setRecipients: async (activity_log_id, payload) =>
        await client.patch(`${resource}/${activity_log_id}/set-recipients`, payload),
};
