import ConfirmManualGMBModal from '@/components/modals/ConfirmManualGMBModal';

export default {
    data () {
        return {
            geotype: '',
        }
    },
    methods: {
        updateTutorialLocationYoutubeMap (addressData, placeResultData, id) {
            document.getElementById(id).value = placeResultData.formatted_address;
            this.newKeyword.geolocation.location = placeResultData.formatted_address;
        },
        updateTutorialMapGMB (addressData, placeResultData, id) {
            document.getElementById(id).value = placeResultData.name;
            this.newKeyword.geolocation.GMB = placeResultData.name;
        },
        updateTutorialLocationWeb (addressData, placeResultData, id, item) {
            const options = { ...this.valueObject };
            if (item.name === 'Change Location') {
                document.getElementById(id).value = placeResultData.formatted_address;
                options.location = placeResultData.formatted_address;
            } else {
                document.getElementById(id).value = placeResultData.name;
                options.GMB = placeResultData.name;
            }

            this.$emit('update:valueObject', options);
        },
        emptyInputAutocompleteHandler (val, prop, type) {
            if (type === 'fromDropMenu' && !val.trim().length) {
                const options = { ...this.valueObject };
                options[prop] = val;
                this.$emit('update:valueObject', options);
            }

            if (!type && !val.trim().length) this.newKeyword.geolocation[prop] = val;
        },
        getAddressDataForNewKeyword (addressData, placeResultData, id) {
            document.getElementById(id).value = placeResultData.formatted_address;
            this.newKeyword.geolocation.location = placeResultData.formatted_address;
        },
        onBlurGMB (ref) {
            setTimeout(() => {
                if (!this.newKeyword.geolocation.GMB && ref.autocompleteText && !document.getElementsByClassName('vm--container').length) {
                    this.showManualGMBModal(ref);
                } else {
                    this.newKeyword.geolocation.isManualGmb = false;
                }
            }, 500);
        },
        getGMBDataForNewKeyword (addressData, placeResultData, id) {
            setTimeout(() => {
                document.getElementById(id).value = placeResultData.name;
                this.newKeyword.geolocation.GMB = placeResultData.name;

                if (this.err.gmb) {
                    this.err.gmb = {};
                }
            }, 0);
        },
        zUp () {
            const autocomplete = document.getElementsByClassName('pac-container');

            for (let i = 0; i < autocomplete.length; i++) {
                autocomplete[i].style.zIndex = 1052;
            }
        },
        resetLocation (object) {
            if (!object.newVal.length) {
                this.newKeyword.geolocation.location = '';
            }
        },
        resetGMB () {
            this.newKeyword.geolocation.GMB = '';
            this.newKeyword.geolocation.isManualGmb = false;
        },
        showManualGMBModal (ref, confirmCB = () => null) {
            this.$modal.show(ConfirmManualGMBModal, {
                handler: confirmed => {
                    if (confirmed) {
                        this.newKeyword.geolocation.GMB = ref.autocompleteText;
                        this.newKeyword.geolocation.isManualGmb = true;
                        confirmCB();
                    } else {
                        ref.autocompleteText = '';
                        this.newKeyword.geolocation.isManualGmb = false;
                        if (this.err.gmb) {
                            this.err.gmb = {text: this.$t('gmb-should-be-valid'), type: 'error'};
                        }
                    }
                },
            }, {
                clickToClose: false,
                height: 'auto',
                width: 507,
            });
        },
    }
}
