const vipUsers = [
    'tribe@firstpage.com.hk',
    'info@firstpagedigital.sg',
    'marketing@primal.co.th',
];

export default ({username}) => {
    const vip = vipUsers.includes(username) ? '-vip' : '';

    if (!vip) {
        return;
    }

    document.querySelector('link[rel*="icon"]').href = `/favicons/favicon${vip}.png`;
    
    const dynamicManifest = {
        'short_name': 'Keyword.com',
        'name': 'keyword.com',
        'icons': [
            {
                'src': `/favicons/favicon-192${vip}.png`,
                'type': 'image/png',
                'sizes': '192x192'
            },
            {
                'src': `/favicons/favicon-512${vip}.png`,
                'type': 'image/png',
                'sizes': '512x512'
            }
        ],
        'start_url': '/landing.html',
        'background_color': '#0B347A',
        'display': 'standalone',
        'theme_color': '#0B347A',
        'description': 'SEO rank tracking and reporting',
        'lang': 'en-US'
    };

    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL);
};