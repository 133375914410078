<template>
    <div class="upgrade-for-access">
        <h2 class="title">{{ title }}</h2>

        <div class="desc">
            <slot />
        </div>

        <div class="buttons-wrapper">
            <kw-button
                :button-text="$t('upgrade-now')"
                button-type="primary"
                data-cy="upgrade-now-button"
                @click="goToPricing"
                :disabled="isDisabled"
            />
            <kw-button
                v-if="!hideTrialButton"
                :button-text="$t('start-14-day-trial')"
                button-type="primary"
                data-cy="start-14-days-trial-button"
                @click="startFreeTrial"
                :disabled="isDisabled"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UPGRADE_PLAN, TRIAL_PACKAGE_ID } from '@/constants';

export default {
    name: 'UpgradeForAccess',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    data(){
        return {
            isDisabled: false,
        }
    },
    computed: {
        ...mapGetters([
            'getUserDataProp'
        ]),
        hideTrialButton(){
            return this.getUserDataProp('trialIsUsed');
        }
    },
    methods: {
        ...mapActions(['changePlan']),
        goToPricing(){
            this.$router.push({ name: 'Pricing' });
        },
        startFreeTrial(){
            this.isDisabled = true;

            this.changePlan({
                plan_id: TRIAL_PACKAGE_ID,
                billing_cycle: 'monthly',
                type: UPGRADE_PLAN,
                trial_period: true
            }).finally(()=>{
                this.isDisabled = false;
            });
        }
    },
}
</script>

<style lang="scss" scoped>
    .upgrade-for-access{
        width: 100%;
        padding: 32px 27px;

        h2.title {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            margin: 0 0 24px 0;
        }

        .desc {
            font-family: Roboto;
            font-size: 13px;
            font-weight: 500;
            line-height: 15px;

            /deep/ ul {
                margin-top: 16px;
                padding-left: 16px;
                line-height: 20px;
            }
        }

        .buttons-wrapper {
            display: flex;
            gap: 16px;
            margin-top: 32px;

            button {
                width: 182px;
                font-size: 13px;
            }
        }
    }
</style>
