<template>
    <div
        :class="keywordsTableClasses"
        data-cy="keywords-table-static"
    >
        <div class="keywords-table__header">
            <div class="header-row">
                <template
                    v-for="field in staticColumns"
                >
                    <div
                        v-if="isCheckboxVisible(field)"
                        :key="field.name"
                        :class="headerClasses(field)"
                        :style="getColumnStyle(field)"
                        @click.stop="headerClick(field)"
                    >
                        <checkbox
                            v-if="field.name === 'checkbox'"
                            id="hyphen-keywords"
                            :icon-class="getCheckBoxIconClass"
                            :checkbox="getIsHeaderCheckboxsChecked"
                            @checkbox-click="handleHeaderCheckboxChange"
                        />

                        <div
                            class="column-header__title"
                            v-html="field.title"
                        />
                        <div
                            class="column-header__sort-container"
                            :class="getDisplaySetting('sort_by_order')"
                        >
                            <span
                                v-if="field.sortField === getDisplaySetting('sort_by')"
                                class="kw kw-sort"
                                :data-important-tooltip="`Sort by ${getDisplaySetting('sort_by_order')}ending order`"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div
            class="keywords-table__body"
            data-cy="parent-keywords-table-body"
        >
            <virtual-list
                id="virtual-static"
                :debounce="50"
                :remain="50"
                :pagemode="true"
                :size="VIRTUAL_LIST_ITEM_HEIGHT"
                :wclass="['both', 'left'].includes(dynamicShadow()) ? 'virtual-list_shadow' : ''"
            >
                <div
                    v-for="(keyword, index) in items"
                    :key="index"
                    :class="tableRowClasses(keyword.isfav)"
                    @click="setShowKeywordAction(keyword.auth)"
                >
                    <h4
                        v-if="title(keyword, index)"
                        class="table-row__title"
                        :style="`width: ${commonLineWidth};`"
                        data-cy="table-row-title"
                        :data-tooltip="title(keyword, index)"
                    >
                        <router-link
                            v-if="isSearch"
                            :to="titleAnchor(keyword)"
                        >
                            <span v-html="title(keyword,index)"/>
                        </router-link>
                        <span
                            v-else
                            v-html="title(keyword,index)"
                        />
                    </h4>

                    <div
                        :id="`row-static-${keyword.id}`"
                        :class="tableRowInnerClasses(index, getBodyCheckboxChecked(keyword.id))"
                        :style="tableRowInnerStyles(keyword.id, title(keyword, index))"
                        data-cy="row-with-keyword-and-hover"
                        @mouseover="setHoverState(keyword.id)"
                        @mouseleave="removeHoverState(keyword.id)"
                    >
                        <span class="kw kw-favorite" />
                        <template
                            v-for="field in staticColumns"
                        >
                            <div
                                v-if="isCheckboxVisible(field)"
                                :key="field.name"
                                :class="field.dataClass"
                                :style="getColumnStyle(field)"
                                :data-cy="field.dataCy"
                                @click.ctrl="e => handleBodyCheckoboxChange(keyword.id, 'Keyword', index, e, items)"
                                @click.meta="e => handleBodyCheckoboxChange(keyword.id, 'Keyword', index, e, items)"
                                @click.shift="e => handleColumnClick(keyword, index, e, items)"
                            >
                                <div class="row-wrap">
                                    <div
                                        v-if="field.name === 'checkbox'"
                                        class="checkbox-block"
                                        @click.stop="handleBodyCheckoboxChange(keyword.id, 'Keyword', index, $event, items)"
                                    >
                                        <checkbox
                                            :id="`Keyword-${keyword.id}`"
                                            :checkbox="getBodyCheckboxChecked(keyword.id)"
                                            @checkbox-click="e => handleBodyCheckoboxChange(keyword.id, 'Keyword', index, e, items)"
                                        />
                                    </div>
                                    <component
                                        :is="`${field.name}-field`"
                                        v-else
                                        :keyword="keyword"
                                        :project="getCurrentProject"
                                        :date-filter="getKeywordFilters.keywordsDate"
                                        :is-search="isSearch"
                                        :toggle-favorite="options => handleToggleKeywordFavorite(options, keyword.isfav)"
                                        :is-view-key="!getViewkeyView && !isViewOnlyUser"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>

                    <keyword-chart
                        v-if="getShownChart.keyword === keyword.id"
                        class="row-keyword-chart"
                        :close-handler="() => toggleShowSingleChart(keyword)"
                        :keywords="[keyword]"
                        :style="`width: ${commonLineWidth};`"
                    />
                    <mobile-actions
                        v-if="showMobileActions(keyword)"
                        :keyword="keyword"
                    />
                </div>
            </virtual-list>
        </div>
    </div>
</template>

<script>
    import { Events, EventBus } from '@/events';
    import {KW_TABLE_COLUMNS, MOBILE_WIDTH_RESOLUTION_PHONE} from '@/constants';
    import {mapGetters, mapActions} from 'vuex';
    import {VIRTUAL_LIST_ITEM_HEIGHT} from '@/constants';
    import DashboardMixin from '@/mixins/DashboardMixin';
    import {isSafari} from '@/helpers/device-detection';
    import KeywordsTableMixin from '@/mixins/KeywordsTableMixin';
    import KwField from 'components/main-table/fields/KwField';
    import virtualList from 'vue-virtual-scroll-list';
    const KeywordChart = () => import('components/main-table/KeywordChart');
    const MobileActions = () => import('components/main-table/MobileActions');
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';

    const ROW_HEIGHT = 33;

    export default {
        name: 'KeywordsTableStatic',
        components: {
            'keyword-chart': KeywordChart,
            'kw-field': KwField,
            'virtual-list': virtualList,
            'mobile-actions': MobileActions
        },
        mixins: [
            DashboardMixin,
            KeywordsTableMixin,
            ToolTipRenderMixin,
        ],
        props: {
            items: {
                type: Array,
                required: true,
            },
            commonLineWidth: {
                type: String,
                required: true,
            },
        },
        data () {
            return {
                VIRTUAL_LIST_ITEM_HEIGHT,
            };
        },
        computed: {
            ...mapGetters([
                'getUserData',
                'getShownChart',
                'getViewkeyView',
                'isViewOnlyUser',
                'getLazyLoadMode',
                'getSelectedAuth',
                'getTableColumns',
                'keyKeywordsById',
                'getOriginalItems',
                'getDisplayLength',
                'withoutIdsGetItem',
                'getCurrentProject',
                'getKeywordFilters',
                'getDisplaySetting',
                'getDisplaySettings',
                'getShowKeywordAction',
                'getAllWithoutIdsData',
                'getGlobalSearchQuery',
                'getIsAllCheckboxesSelected',
                'getCommonKeywordsChartIsShow',
            ]),
            staticColumns () {
                const staticColumns = this.getTableColumns.filter(el => KW_TABLE_COLUMNS.STATIC.includes(el.name));
                return staticColumns;
            },
            keywordsTableClasses () {
                return {
                    'keywords-table keywords-table_static': true,
                };
            },
            isSearch() {
                return this.$route.name === 'searchResults';
            },
            getIsHeaderCheckboxsChecked () {
                const tablePerPage = this.getDisplayLength;
                return !!this.keyKeywordsById.length || (this.getIsAllCheckboxesSelected && this.getOriginalItemsAndWithoutIdsFilter.length !== tablePerPage);
            },
            getOriginalItemsAndWithoutIdsFilter () {

                const originalItems = this.getOriginalItems;
                const allWithoutIds = this.getAllWithoutIdsData;

                return allWithoutIds.filter((item) => originalItems.find((originalItem) => originalItem.id === item));
            },
            getCheckBoxIconClass() {
                const className = ((this.getIsAllCheckboxesSelected && !this.getOriginalItemsAndWithoutIdsFilter.length) || this.keyKeywordsById.length === this.getOriginalItems.length) ? 'kw kw-check': 'kw kw-minus';
                return className;
            },
        },
        watch: {
            getShownChart(value) {
                if (value?.keyword && this.$route.query.keyword_id) {
                    const keywordForDisplayingSingleChart = this.items.find(kw => +kw.id === +value.keyword);
                    this.$nextTick(() => {
                        window.scrollTo(0, this.$el.scrollTop + (this.items.indexOf(keywordForDisplayingSingleChart) + 1) * ROW_HEIGHT);
                    })
                }
            }
        },
        created() {
            const { keyword_id } = this.$route.query;
            if (keyword_id) {
                const keywordForDisplayingSingleChart = this.items.find(kw => +kw.id === +keyword_id);
                if (keywordForDisplayingSingleChart) {
                    this.$nextTick(() => {
                        this.toggleShowSingleChart(keywordForDisplayingSingleChart);
                    })
                }
            }
        },
        methods: {
            ...mapActions([
                'fetchKeywords',
                'toggleKeywordsFavorite',
                'selectAllKeywords',
                'resetAllKeywords',
                'toggleKeywordSelection',
                'setAllCheckboxesSelected',
                'setSelectedKeywords',
                'setShownChart',
                'setAllWithoutIds',
                'resetAllWithoutIds',
                'toggleKeywordsTableSorter',
                'setShowKeywordAction',
                'toggleCommonKeywordsChartIsShow',
                'fetchKeywordsFromSearch',
                'updateUserDisplaySettings',
            ]),
            emitOpenSpyglass(data) {
                EventBus.emit(Events.OPEN_SPYGLASS_PAGE, data);
            },
            keywordChartClasses(keyword) {
                return {
                    'actions-opened': this.showMobileActions(keyword),
                    'row-keyword-chart': true

                }
            },
            showMobileActions (keyword) {
                return MOBILE_WIDTH_RESOLUTION_PHONE > window.screen.width && this.getShowKeywordAction === keyword.auth
            },
            async headerClick ({sortField}) {
                if (sortField) {
                    this.toggleKeywordsTableSorter(sortField);
                    if(this.getGlobalSearchQuery) {
                        this.fetchKeywordsFromSearch();
                    }else {
                        if (!this.getViewkeyView) {
                            this.updateUserDisplaySettings({
                                setting: 'sort_by',
                                value: this.getDisplaySetting('sort_by'),
                                showMsg: false,
                            });
                            this.updateUserDisplaySettings({
                                setting: 'sort_by_order',
                                value: this.getDisplaySetting('sort_by_order'),
                            });
                        }
                        await this.fetchKeywords({id: this.getCurrentProject.id});
                    }
                }
            },
            toggleShowSingleChart (kw) {
                if (this.getCommonKeywordsChartIsShow) {
                    this.toggleCommonKeywordsChartIsShow();
                }

                if (this.getShownChart.keyword !== kw.id) {
                    const data = {keyword: kw.id, project: kw.category};
                    this.setShownChart(data);
                    EventBus.emit(Events.OPEN_HISTORICAL_CHART, data);
                } else {
                    this.setShownChart({keyword: null, project: null});
                }
            },
            headerClasses (field) {
                return {
                    [field.titleClass]: true,
                    'column-header_safari': isSafari(),
                };
            },
            handleColumnClick ({id}, index, e, items) {
                this.setAllCheckboxesSelected(false);
                //  this.handleDashboardRowSelect(id, 'Keyword', index, e, items);
                const store = {
                    selectedGetter:'getSelectedAuth',
                    selectedAction: 'selectKeywordRow',
                    selectedItemsAction:  'setSelectedKeywords',
                };

                this.applyShiftLogic(e, id, index, items, store);
            },
            titleAnchor(keyword) {
                return { name: 'keywordList', params: { id: keyword.category } };
            },
            getCurrentPageCheckedItems () {
                const pageItems = this.getOriginalItems;
                const selectedKeywords = this.getSelectedAuth;
                const filteredData = pageItems.filter((item) => {
                    return selectedKeywords.find((selectedItem) => {
                        return item.id === selectedItem;
                    })
                });
                return filteredData;
            },
            handleHeaderCheckboxChange() {
                const checkedItems = this.keyKeywordsById;

                if (checkedItems.length === this.getOriginalItems.length) {
                    this.resetAllKeywords();
                } else if (this.getIsAllCheckboxesSelected) {
                    const originalItemsIds = this.getOriginalItems.map(
                        (item) => item.id
                    );

                    if (!this.getOriginalItemsAndWithoutIdsFilter.length) {
                        if (this.getLazyLoadMode) {
                            this.setAllCheckboxesSelected(false);
                            this.setSelectedKeywords([]);
                        } else {
                            this.setAllWithoutIds(originalItemsIds);
                        }
                    } else {
                        this.resetAllWithoutIds(originalItemsIds);
                    }
                } else {
                    if (this.getLazyLoadMode) {
                        if (!this.getIsAllCheckboxesSelected) {
                            this.setAllCheckboxesSelected(true);
                        }

                        if (this.getAllWithoutIdsData.length) {
                            this.resetAllWithoutIds();
                        }
                    } else {
                        this.selectAllKeywords();
                    }
                }
            },
            handleBodyCheckoboxChange (id) {
                if(this.getIsAllCheckboxesSelected) {
                    if(this.withoutIdsGetItem(id)) {
                        this.resetAllWithoutIds(id);
                    }else {
                        this.setAllWithoutIds(id);
                    }
                }else {
                    this.toggleKeywordSelection(id);
                }

            },

            isCheckboxVisible(field) {
                return !(this.$route.name === 'pdfView' && field.name === 'checkbox')
            },

            async handleToggleKeywordFavorite(options, isFavorite) {
                const message = isFavorite ? 'success-keywords-were-removed-from-favorites' : 'success-keywords-were-added-to-favorites';
                try {
                    await this.toggleKeywordsFavorite(options);
                    this.$toastr.s(this.$t(message));
                } catch (error) {
                    this.$toastr.e(error);
                }
            },
        },
    }

</script>

<style lang="scss">
  // important class for overflow shadows of dynamic table
  .header-row_with-shadow {
    &:before {
      background: linear-gradient(to right, rgba(31, 63, 97, 0.07) 0%, rgba(31, 63, 97, 0) 97.37%);
      content: '';
      height: 100%;
      left: 100%;
      position: absolute;
      width: 8px;
      z-index: 1;
    }
  }

  // important class for overflow shadows of dynamic table
  .table-row__inner_with-shadow {
    &:after {
      background: linear-gradient(to right, rgba(31, 63, 97, 0.07) 0%, rgba(31, 63, 97, 0) 97.37%);
      content: '';
      height: 34px;
      left: 100%;
      position: absolute;
      width: 8px;
    }
  }

  .keywords-table_static {
    flex: 1 1 100%;
    max-width: 330px;
    min-width: 270px;

    .column-header_checkbox {
      justify-content: center;
      position: sticky;
      left: 0;
      position: relative;
      z-index: 1;

      .column-header__sort-container {
        margin-left: 0;
      }

      .checkbox {
        position: relative;
        top: auto;
      }
    }

    .column-data_checkbox {
      background-color: kw-color(kw-white, 1) !important;
      position: sticky;
      left: 0;
      z-index: 2;

      .row-wrap {
        padding: 0;
        width: 100%;
        height: 100%;
      }

      .checkbox-block {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .checkbox {
        position: relative;
        top: 0;
      }
    }

    .table-row__inner {
      // border-left: 1px solid #E1E8EF;
      &:after {

        background: linear-gradient(to right, rgba(31, 63, 97, 0.07) 0%, rgba(31, 63, 97, 0) 97.37%);
        content: '';
        height: 32px;
        left: 100%;
        position: absolute;
        transform: scale(0);
        width: 8px;
      }
    }

    // header
    .column-header {
      &.column-header_keyword {
        border-top-right-radius: 0;
      }
    }

    // body
    .keywords-table__body {
      position: relative;
      z-index: 1;
    }

    .table-row_fav {
      .table-row__inner {
        & > span.kw-favorite {
          color: #F4A732;
          font-size: 8px;
          left: 35px;
          line-height: 8px;
          position: absolute;
          width: 8px;
          top: 13px;
          z-index: 1;
          @media screen and (max-width: 959px) {
            font-size: 7px;
            top: 16px;
          }
        }
      }
    }

    .column-data {
      &:nth-child(2) {
        border-left: 1px solid #E1E8EF;
      }

      &.column-data_keyword {
        position: relative;

        .table-cell {
          position: static;
        }
      }
    }
  }

  // if table grouped by keyword and column kw removed from table
  .keywords-table_shrinked {
    flex-grow: 0;
    min-width: unset;
  }

  //responsive design
  @media (max-width: 959px) {
    .keywords-table_static {
      flex: 1 1 auto;
      min-width: 176px;

      &.keywords-table_shrinked {
        flex: 0 1 auto;
      }

      // header
      .column-header {
        &.column-header_keyword {
          width: 100%;
          flex: unset !important;
        }

        &:first-child {
          border-top-left-radius: 0;
        }

        &:not(.column-header_checkbox) {
          padding: 0 10px;
        }
      }

      .table-row {
        .column-header_checkbox {
          display: none;
        }

        .column-data {
          user-select: none;

          &:first-child {
            border-left: none;
          }

          &.column-data_keyword {
            flex: unset !important;
            padding: 0 15px !important;
            width: 100%;

            .table-cell__actions {
              display: none !important;
            }
          }

          &:not(.column-data_checkbox) {
            padding: 0 10px;
          }
        }
      }

      .table-row__inner {
        &:after {
          height: 40px;
        }
      }
    }
  }
</style>
