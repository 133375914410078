<template>
    <div class="progress-bar__used" :data-cy="dataCy">
        <span :style="progressStyles" />
    </div>
</template>

<script>
export default {
    name: "ProgressBar",
    props: {
        all: {
            type: Number,
            required: true,
        },
        fill: {
            type: Number,
            required: true,
        },
        dataCy: {
            type: String,
            required: true,
        },
    },
    computed: {
        progressStyles() {
            const progressWidth =
                this.all === 0 && this.fill === 0
                    ? null
                    : (this.fill * 100) / this.all;

            return {
                background: progressWidth > 90 ? "#B02828" : "#7DC380",
                width: `${progressWidth}%`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.progress-bar__used {
    background: #e2e8ee;
    border-radius: 12px;
    height: 12px;
    margin: 5px 0;
    overflow: hidden;

    span {
        border-radius: 12px 0px 0px 12px;
        display: block;
        height: 100%;
    }
}
</style>