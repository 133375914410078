<template>
    <div
        class="project-notes"
        @click.stop
    >
        <div
            data-cy="dashboard-notes-list"
            class="project-notes__list"
        >
            <div
                v-for="note in notes"
                :key="note.id"
                class="project-note"
            >
                <div
                    class="project-note__body"
                    data-cy="dashboard-note-body"
                >
                    <div
                        :class="projectNoteTextClasses(note.id)"
                        @click="startEditNote(note.id)"
                        v-text="note.attributes.note"
                    />
                    <textarea
                        :ref="`projectNoteTextarea${note.id}`"
                        v-model="note.attributes.note"
                        class="project-note__textarea"
                        @keypress.enter.exact.prevent="editNote(note)"
                    />
                    <i
                        class="icon-cross-round icon-rotate-45"
                        data-cy="notes-icon-cross"
                        @click="openDeleteKeywordsModal(note)"
                    />
                </div>
                <span
                    v-if="note.attributes.kw"
                    class="project-kw__name"
                >
                    {{ note.attributes.kw }}
                </span>
                <span
                    class="project-note__date"
                    @click="selectDateForEdit($event, note)"
                >
                    {{ getFormattedDate(note.attributes.created_at) }}
                </span>
            </div>
        </div>
        <datepicker
            v-if="selectedNote"
            format="dd MMMM yyyy"
            edit-date
            :selected-note="selectedNote"
            :value.sync="selectedNote.attributes.created_at"
            @edit-date="editNote(selectedNote)"
        />
    </div>     
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import {MOBILE_WIDTH_RESOLUTION_PHONE} from '@/constants';
    import {switchDateFormat} from '@/helpers/format-date-service';
    import VueStrapDataPicker from 'components/extended/VueStrapDataPicker';
    import SimpleDeleteWithCustomHandler from 'components/modals/SimpleDeleteWithCustomHandler';
    export default {
        name: 'ProjectNotes',
        components: {
            'datepicker': VueStrapDataPicker,
        },
        data () {
            return {
                isFormOpened: false,
                formNote: {},
                selectedNote: null,
                editedNoteId: null,
                editNoteRunning: false,
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentProject',
                'getFormattedDate',
                'getProjectNotesById',
            ]),
            notes () {
                return this.getCurrentProject ? this.getProjectNotesById(this.getCurrentProject.id) : [];
            },
            getCancelButtonText () {
                return this.notes.length ? this.$t('cancel'): this.$t('clear-note');
            }
        },
        async created () {
            this.setDefaultNote();
        },
        methods: {
            ...mapActions([
                'addProjectNote',
                'deleteProjectNote',
                'updateProjectNote',
            ]),
            startEditNote (id) {
                this.editedNoteId = id;
                this.$refs[`projectNoteTextarea${id}`][0].focus();
            },
            noteValidator (text) {
                if (!text.length) {
                    this.$toastr.e(this.$t('note-empty-err'));
                    return false;
                }

                if (text.length > 500) {
                    this.$toastr.e(this.$t('note-limit-err'));
                    return false;
                }

                return true;
            },
            setDefaultNote () {
                this.formNote = {
                    date: switchDateFormat(new Date(), 'Y-m-d'),
                    id: '',
                    text: '',
                };

                this.selectedNote = null;
                this.editedNoteId = null;
            },
            openDeleteKeywordsModal (note) {
                this.$modal.show(
                    SimpleDeleteWithCustomHandler,
                    { 
                        type: 'Note', 
                        deleteHandler: this.deleteNote,
                        unicClasses: {
                            'delete-note': true
                        },
                        item: note.id
                    },
                    {
                        width: 282,
                        height: 'auto',
                        classes: 'v--modal center-modal-popup',
                        clickToClose: window.screen.width > MOBILE_WIDTH_RESOLUTION_PHONE, 
                    }
                );
            },
            async deleteNote (note) {
                await this.deleteProjectNote(note);
                this.selectedNote = null;
                this.editedNoteId = null;
            },
            selectDateForEdit (e, note) {
                const listBottom = document.querySelector('.project-notes__list').getBoundingClientRect().bottom;
                const noteBottom = e.target.parentElement.getBoundingClientRect().bottom;

                if (this.selectedNote && this.selectedNote.id === note.id) {
                    this.selectedNote = null;
                    return;
                }

                this.selectedNote = {
                    ...note,
                    // x position for edit date datepicker
                    top: Math.round(listBottom - noteBottom + 10),
                };
            },
            async editNote ({id, attributes}) {
                const {note, created_at} = attributes;
                if (this.editNoteRunning) return;
                if (!this.noteValidator(note)) return;

                this.editNoteRunning = true;
                await this.updateProjectNote({
                    id: id,
                    note,
                    date: switchDateFormat(new Date(created_at), 'Y-m-d'),
                });

                this.editNoteRunning = false;
                this.setDefaultNote();
                this.editedNoteId = null;
            },
            projectNoteTextClasses (id) {
                return {
                    'project-note__text': true,
                    'project-note__text_active': this.editedNoteId === id,
                };
            }
        }
    }       
</script>

<style lang="scss">
  .project-notes {
    background-color: #F5F6F7;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10px;
    max-height: 192px;
    right: 8px;
    z-index: 6;
  }

  .icon-cross-round {
    margin-right: 8px;
  }

  .project-notes__list {
    @extend .wide-scroll;
    padding: 10px 8px 0 16px;
    overflow-x: visible;
    overflow-y: auto;
  }

  .project-note {
    background: kw-color(kw-white, 1);
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
    margin: 0 3px 8px 0;
    padding: 8px;
    position: relative;
    border: 1px solid #AFBAC7;
    border-radius: 4px;
    &:last-child {
      margin-bottom: 10px;
    }
  }

  .project-note__body {
    display: flex;
    position: relative;

    i {
      color: kw-color(kw-grey, 1);
      height: 14px;
      margin-left: 8px;
      margin-right: 0;
      transition: opacity .3s;
      cursor: pointer;
      &:hover {
          opacity: 0.7;
      }
    }

    .project-note__text {
      background: kw-color(kw-white, 1);
      color: kw-color(kw-black, 1);
      cursor: text;
      font-size: 14px;
      line-height: 16px;
      outline: transparent;
      width: calc(100% - 22px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      z-index: 1;

      &_active {
        z-index: -1;
      }
    }

    .project-note__textarea {
      @extend .hidden-scroll;
      border: none;
      color: kw-color(kw-black, 1);
      font-size: 14px;
      height: 100%;
      line-height: 16px;
      outline: transparent;
      padding: 0;
      position: absolute;
      resize: none;
      width: calc(100% - 22px);
    }
  }

  .project-note__date {
    color: #AFBAC7;
    cursor: pointer;
    flex: 1 1 auto;
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;
    transition: color .3s;
    &:hover {
      color: kw-color(kw-blue, 1);
    }
  }
  .project-kw__name {
    color: #AFBAC7;
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;
    cursor: default;
    overflow: hidden;
  }
</style>   