/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './app_bootstrap';
require('sass/app.scss');

import vue from 'vue';
import { MOBILE_WIDTH_RESOLUTION_PHONE } from '@/constants';
window.Vue = vue;
window.MOBILE_WIDTH_RESOLUTION_PHONE = MOBILE_WIDTH_RESOLUTION_PHONE;
Vue.config.performance = true;
Vue.config.productionTip = false;

Vue.prototype.$eventHub = new Vue();

import VueRouter from 'vue-router';
import router from './routes';

// hack for fix 'NavigationDuplicated' error
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

import HighchartsVue from 'highcharts-vue';
Vue.use(HighchartsVue);

import VModal from 'vue-js-modal';
Vue.use(VModal, { dynamic: true, injectModalsContainer: true, dialog: true });

import VeeValidate from 'vee-validate';
import { Validator } from 'vee-validate';
Validator.extend('include_special_character', {
    validate: (value) => {
        const specialCharacterPattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|'"`]/;
        return specialCharacterPattern.test(value);
    },
    getMessage: field => 'The ' + field + ' must contain at least one special character.',
});
Vue.use(VeeValidate, { fieldsBagName: 'fieldsBag' });

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);

import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
Vue.component('vue-draggable-resizable', VueDraggableResizable);

import App from './App.vue';

import Vuex from 'vuex'
import store, { resetVuexLocalStorage } from './store';
Vue.use(Vuex);

import Profiler from './helpers/performancerPlugin';
Vue.use(Profiler);

import GAHealper from './helpers/ga-event-helper';
Vue.use(GAHealper);

import TooltipRender from './helpers/toolTipRender';
Vue.use(TooltipRender);

import InitToStr from '@/helpers/init-toastr';
require('vue-toastr/src/vue-toastr.scss');
Vue.prototype.$toastr = { ...InitToStr };

import { VueReCaptcha } from 'vue-recaptcha-v3';
import {detectIsViewKey} from '@/helpers';

if(!detectIsViewKey()){
    Vue.use(VueReCaptcha, { siteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY });
}

import i18n from './i18n';

if (process.env.MIX_SENTRY_DSN) {
    require('./services/sentry').init(Vue);
}

require('./services/mixpanel').init();

import GlobalEvents from 'vue-global-events';
import Dropdown from '@/components/extended/Dropdown';
// import VTooltip from 'v-tooltip';
// Vue.use(VTooltip);
// Vue.directive('tooltip', VTooltip.VTooltip)

import { clickOutside } from './helpers/click-outside';
Vue.directive('click-outside', clickOutside);


// if application changes version -> remove just persisted copy of store from localStorage
// !!! not whole store !!!
resetVuexLocalStorage();

// often used components
import VueStrapSelect from 'components/extended/VueStrapSelect';
Vue.component('global-events', GlobalEvents);
Vue.component('button-component', () => import('components/ui-elements/ButtonComponent'));
Vue.component('custom-v-select', VueStrapSelect);
Vue.component('checkbox', () => import('components/ui-elements/Checkbox'));
Vue.component('closer', () => import('components/ui-elements/Closer'));
Vue.component('dropdown', Dropdown);
Vue.component('flag', () => import('components/ui-elements/Flag'));
Vue.component('divider', () => import('components/ui-elements/Divider'));
Vue.component('hyphen', () => import('components/ui-elements/Hyphen'));
Vue.component('loader', () => import('components/ui-elements/Loader'));
Vue.component('placeholder-cell', () => import('components/ui-elements/PlaceholderCell'));
Vue.component('project-link', () => import('components/ui-elements/ProjectLink'));
Vue.component('projects-list-for-select', () => import('components/ui-elements/ProjectsListForSelect'));
Vue.component('radio-buttons', () => import('components/ui-elements/RadioButtons'));
Vue.component('search-input', () => import('components/ui-elements/SearchInput'));
Vue.component('table-pagination', () => import('components/ui-elements/TablePagination'));
Vue.component('toggler', () => import('components/ui-elements/Toggler'));
Vue.component('custom-dropdown-body', () => import('components/custom-dropdown-body'));
Vue.component('custom-select', () => import('components/ui-elements/CustomSelect'));
Vue.component('kw-button', () => import('components/ui-elements/KwButton'));
Vue.component('kw-input-field', () => import('components/ui-elements/KwInputField'));


export default new Vue({
    el: '#app',
    i18n,
    render: h => h(App),
    router,
    store,
});
