<template>
    <div class="chart" v-resize="() => debouncedHandler(resizeChart)">
        <div
            v-show="!loading"
            :id="`organic_traffic_acquisition-chart-${project.id}`"
            class="organic-traffic-acquisition_chart"
        />
        <loader v-if="loading" /> 
    </div>
</template>

<script>
import Highcharts from 'highcharts';
import resize from 'vue-resize-directive';
import {
    monthNames,
    switchDateFormat,
} from '@/helpers/format-date-service';

export default {
    name: 'OrganicTrafficAcquaisitionChart',
    directives: {
        resize,
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        project: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        data() {
            this.$nextTick(() => this.renderChart(this.data));
        },
    },
    methods: {
        debouncedHandler: _.debounce(fn => fn(), 50),
        resizeChart() {
            if (this.chart) {
                window.dispatchEvent(new Event('resize'));
            }
        },
        objIsNotEmpty(obj) {
            for (var key in obj) {
                if (obj[key] !== null && obj[key] != '') return true;
            }
            return false;
        },
        renderChart(dataByDate) {
            if(!dataByDate && this.chart){
                this.chart.destroy();
                return;
            } 
            const colors = ['#0074D9', '#A2E0D8'];
            let i = 0;
            const series = [];
            if (Object.keys(dataByDate).length) {
                for (const month in dataByDate) {
                    let monthName = new Date(month).getMonth() === new Date().getMonth() ?
                        this.$t('current-month') :
                        this.$t('previous-month');

                    let data = this.objIsNotEmpty(dataByDate[month])
                        ? Object.values(dataByDate[month])
                        : [];

                    series.unshift({
                        name: monthName,
                        data: data,
                        marker: {symbol: 'circle'},
                        color: colors[i],
                        month: month
                    });

                    i++;
                }
            } else {
                let date = new Date();

                for (let i = 2; i > 0; i--) {
                    let monthName = monthNames[date.getMonth()];

                    series.unshift({
                        name: monthName,
                        data: [],
                    });

                    date.setMonth(date.getMonth() - 1);
                }
            }

            const chartConfigs = {
                chart: {
                    events: [],
                    borderColor: '#DCE4EC',
                    borderWidth: 1,
                    marginTop: 18,
                    borderRadius: 4,
                    plotBorderColor: '#F2F4F7',
                    plotBorderWidth: 1,
                    style: {
                        fontFamily: 'Roboto',
                    },
                },
                exporting: {
                    enabled: false,
                },
                title: false,
                credits: false,
                xAxis: {
                    type: 'category',
                    showFirstLabel: true,
                    allowDecimals: false,
                },
                yAxis: {
                    allowDecimals: false,
                    title: false,
                    opposite: true
                },
                legend: {
                    align: 'left',
                    layout: 'horizontal',
                    verticalAlign: 'bottom',
                    itemStyle: {
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#909399',
                        marginBottom: '-30px'
                    },
                },
                tooltip: {
                    crosshairs: true,
                    shared: true,
                    useHTML: true,
                    backgroundColor: '#ffffff',
                    borderColor: '#AEBBC8',
                    className: 'organic-traffic-tooltip',
                    formatter: function() {
                        return this.points.reduce(
                            (acc, cur) => {
                                const row = `
                                    ${acc}
                                    <div class="organic-traffic-chart__label-item">
                                        <div class="organic-traffic-legend-color" style="background: ${cur.series.color}">
                                        </div>
                                        <div>
                                            ${switchDateFormat(new Date(`${cur.series.userOptions.month}-${cur.x}`), 'd F Y')}
                                        </div>
                                        <div class="organic-traffic-value">
                                            ${cur.y}
                                        </div>
                                    </div>
                                `;

                                return row;
                            },
                            `
                                <div class="organic-traffic-chart__label-header">
                                    Organic Searches
                                </div>
                            `,
                        );
                    },
                },
                plotOptions: {
                    series: {
                        pointStart: 1,
                        marker: {
                            enabled: false
                        }
                    },
                },
                series: series,
            };

            this.chart = new Highcharts.chart(
                `organic_traffic_acquisition-chart-${this.project.id}`,
                chartConfigs
            );
        },
    },
    mounted(){
        if(this.data) {
            this.renderChart(this.data);
        }
    }
};
</script>

<style lang="scss" scoped>
    .organic-traffic-acquisition_chart {
        height: 100%;
        width: 100%;
        padding-top: 10px;

        /deep/ .organic-traffic-tooltip {
            font-family: Roboto;
            font-size: 12px;
            line-height: 14px;

            .organic-traffic-chart__label-header {
                font-family: Roboto;
                font-weight: 700;
                color: kw-color(kw-grey, 1);
                margin-bottom: 6px;
            }
            .organic-traffic-chart__label-item {
                display: flex;
                align-items: center;
                gap: 6px;
                font-weight: 500;
                
                .organic-traffic-legend-color {
                    width: 20px;
                    height: 4px;
                    border-radius: 2px;
                }
                .organic-traffic-value {
                    padding-left: 6px;
                    margin-left: auto;
                }
            }
        } 


    }
    .chart {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
    }
</style>
