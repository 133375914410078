<template>
    <div class="overview-chart-wrapper">
        <div
            v-show="!loading"
            :id="`overview-chart-${project.id}`"
            class="project-overview__chart"
        />

        <loader v-if="loading" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Highcharts from 'highcharts';
import { overviewChartOptions } from '@/configs/overviewCharts';

export default {
    name: 'KeywordsInTopChart',
    props: {
        chart: {
            type: Object,
        },
        project: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
        },
        isChartDisplayed: {
            type: Boolean,
        },
        projectId: {
            type: String,
        },
        id: {
            type: String,
        },
        showLatest: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            isRendered: false,
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentProject',
            'getKeywordFilters',
        ]),
    },
    watch: {
        project() {
            if(this.project.id){
                this.isRendered = false;
                this.$nextTick(() => this.renderChart());
            }
        },
        loading() {
            if(!this.isRendered && !this.loading){
                this.$nextTick(() => this.renderChart());
            }
        }
    },
    methods: {
        renderChart() {
            if (this.loading || !this.isChartDisplayed) {
                // to remove old charts after fetch next page
                if (this.chart) {
                    this.chart.destroy();
                }

                return;
            }

            if(!this.isRendered){
                this.isRendered = true;
                this.$emit('setChart', new Highcharts.chart(
                    `overview-chart-${this.project.id}`,
                    overviewChartOptions(
                        this.project.charts,
                        this.getCurrentProject
                            ? this.getCurrentProject.client
                            : false,
                        this.getKeywordFilters.keywordsDate,
                        this.showLatest,
                        this.project.categories
                    ),
                ));
            }
        },
    },
}
</script>

<style lang="scss">
    .overview-chart-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .project-overview__chart{
            width: 100%;
            height: 100%;

            .highcharts-tooltip {
                width: 163px;

                span {
                    left: 0 !important;
                    top: 0 !important;
                }
            }

            .overview-chart__label-header,
            .overview-chart__label-item {
                align-items: center;
                display: flex;
                font-family: Roboto;
                font-weight: 500;
                height: 20px;
                letter-spacing: 0.01em;
                line-height: 20px;
                padding: 0 8px;
                width: 207px;

                & > div:nth-child(1) {
                    align-items: center;
                    display: flex;
                    max-width: 100px;
                    min-width: 100px;

                    i {
                        border-radius: 50%;
                        display: block;
                        margin-right: 3px;
                        height: 6px;
                        width: 6px;
                    }
                }

                & > div:nth-child(2) {
                    padding: 0 10px;
                    text-align: right;
                    max-width: 40px;
                    min-width: 40px;
                }

                & > div:nth-child(3) {
                    padding: 0 10px;
                    text-align: right;
                    max-width: 50px;
                    min-width: 50px;
                }
            }

            .overview-chart__label-header {
                border-bottom: 1px solid #dee5ec;
                color: #909399;
                font-size: 10px;
                line-height: 12px;
            }

            .overview-chart__label-item {
                display: flex;

                & > div:nth-child(2) {
                    border-left: 1px solid #dee5ec;
                    border-right: 1px solid #dee5ec;
                }
            }
        }
    } 
</style>
