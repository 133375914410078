import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

// we need in jsonp-adapter because axios does not support jsonp by default
const ipstackClient = axios.create({
    adapter: jsonpAdapter,
    baseURL: 'http://api.ipstack.com/',
    params: {'access_key': process.env.IPSTACK_KEY, format: 1},
});

ipstackClient.interceptors.response.use(
    response => {
        if (response.status !== 200 || response.data.error) {
            if (process.env.NODE_ENV !== 'production') {
                console.warn('Status: ' + response.status);
                console.log(response.data);
            }

            return Promise.reject(response.data);
        }

        return response;
    },
    error => {
        if (process.env.NODE_ENV !== 'production') {
            console.warn('Status: ' + error.response.status);
            console.log(error.response.data.errors[0]);
        }

        return Promise.reject(error);
    },
);

// fetch data accordingly ip
const calculateByIP = async () => await ipstackClient('check');

export default {calculateByIP};