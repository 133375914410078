<template>
    <div :class="getClassNames">
        <refresh
            :title="text"
            :type="type"
        />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Refresh from 'components/refresh/Refresh';
    export default {
        name: 'PageHeader',
        components: {
            'refresh': Refresh,
        },
        computed: {
            ...mapGetters(['getTrialPeriod']),
            getClassNames() {
                return {
                    'page-header': true,
                    'with_top_banner': this.getTrialPeriod,
                };
            },
        },
        props: {
            text: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>
  .page-header {
    background-color: kw-color(kw-white, 1);
    line-height: 86px;
    margin: 0 0;
    padding: 25px 0 15px 0;
    border-bottom: none;
    position: sticky;
    top: 60px;
    z-index: 101;

    &.with_top_banner {
        top: 108px;
    }

    @media (max-width: 959px) {
      top: calc(#{$header-height} - 20px);
    }
  }

  .refresh {
    max-width: 1280px;
  }
</style>