<template functional>
    <div class="search-intents-tooltip">
        <div
            v-for="intent in Object.keys(props.searchIntents)"
            :key="intent"
            class="search-intent-line"
        >
            <div class="intent-name">
                {{ parent.$t(`search-intents.${intent}`) }}
            </div>

            <!-- <div v-if="props.searchIntents[intent] < 0.5">
                <div class="intent-percentage less">{{ '<50%' }}</div>
            </div> -->
            <template>
                <div class="intent-progress-bar" :data-cy="dataCy">
                    <span
                        :class="intent"
                        :style="`width: ${props.searchIntents[intent] * 100}%;`"
                    />
                </div>
                <div class="intent-percentage">
                    {{ (props.searchIntents[intent] * 100).toFixed(0) }}%
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchIntentsTooltip',
    props: {
        searchIntents: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.search-intents-tooltip {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 6px 8px;
    .search-intent-line {
        display: flex;
        align-items: center;

        .intent-name {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            width: 101px;
            color: kw-color(kw-black, 1);
        }

        .intent-progress-bar {
            background: kw-color(kw-light-grey, 4);
            border-radius: 4px;
            height: 8px;
            width: 72px;
            overflow: hidden;

            span {
                border-radius: 12px 0px 0px 12px;
                display: block;
                height: 100%;

                &.commercial {
                    background: kw-color(kw-red, 4);
                }

                &.transactional {
                    background: kw-color(kw-green, 4);
                }
                &.navigational {
                    background: kw-color(kw-purple, 6);
                }
                &.informational {
                    background: kw-color(kw-light-blue, 4);
                }
            }
        }

        .intent-percentage {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: right;
            width: 25px;
            color: kw-color(kw-black, 1);

            &.less {
                color: kw-color(kw-light-grey, 5);
            }
        }
    }
}
</style>
