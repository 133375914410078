<template>
    <div class="table-cell-search_intents" ref="column">
        <span
            v-if="notSupportedSearchIntents || emptySearchIntents"
            class="empty-value"
            >&mdash;</span
        >
        <span v-else-if="nullSearchIntents" class="empty-value">
            {{ $t('updating') }}
        </span>

        <template v-else>
            <div
                v-for="(value, intent) in getIntents"
                :key="intent"
                :class="`kw-si-icon kw-si-icon-${intent} ${
                    value < 0.05 ? 'hidden' : ''
                }`"
            />
        </template>
        <div v-if="!nullSearchIntents" style="display: none" ref="tooltip">
            <search-intents-tooltip :search-intents="getIntents" />
        </div>
    </div>
</template>

<script>
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import SearchIntentsTooltip from '@/components/ui-elements/SearchIntentsTooltip';
export default {
    name: 'SearchIntentsRenderer',
    components: {
        'search-intents-tooltip': SearchIntentsTooltip,
    },
    mixins: [ToolTipRenderMixin],
    data() {
        return {
            tooltipInstance: null,
        };
    },
    computed: {
        notSupportedSearchIntents() {
            return this.params.data.search_intents === null;
        },
        nullSearchIntents() {
            return (
                !this.params.data.search_intents ||
                (!this.notSupportedSearchIntents &&
                this.params.data.search_intents?.commercial === null)
            );
        },
        emptySearchIntents() {
            return !this.nullSearchIntents &&
                    !Object.values(this.params.data.search_intents).filter(
                    (e) => e >= 0.05
                    ).length
        },
        getIntents() {
            return this.params.data.search_intents
                ? Object.fromEntries(
                    Object.entries(this.params.data.search_intents).sort(
                    (a, b) => b[1] - a[1]
                    )
                )
                : [];
        },
        getText() {
            return this.notSupportedSearchIntents
                ? this.$t('not-supported-search-intents')
                : this.nullSearchIntents
                ? this.$t('refreshing')
                : this.$refs.tooltip.innerHTML;
        },
    },
    mounted() {
        this.tooltipInstance?.destroy();
        this.tooltipInstance = this.tooltip({
            text: this.getText,
            event: {
                target: this.$refs.column,
            },
            arrow: this.nullSearchIntents,
            type: !this.nullSearchIntents ? 'right-start' : 'top',
            interactive: false,
            followCursor: !this.nullSearchIntents,
            onHidden: () => {},
        });
    },
    beforeDestroy() {
        this.tooltipInstance?.destroy();
    },
};
</script>

<style lang="scss" scoped>
.table-cell-search_intents {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.empty-value {
    color: #d3d3d3;
}
</style>
