<template>
    <rotating-update-icon v-if="params.value === null" />
    <span v-else-if="params.value === -1" class="empty-value">&mdash;</span>
    <span
        v-else-if="params.value === 0"
        class="empty-value"
        style="color: #c1c4cd"
        >> 100</span
    >
    <div v-else-if="params.column.colId === 'grank'" class="table-cell">
        <div class="table-cell__content-grank">{{ params.value }}</div>
    </div>
    <span v-else>{{ params.value }}</span>
</template>

<script>
import RotatingUpdateIcon from '@/components/ui-elements/RotatingUpdateIcon';
export default {
    name: 'RankRenderer',
    components: { RotatingUpdateIcon },
};
</script>

<style lang="scss" scoped>
.table-cell {
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    position: relative;
    width: 100%;

    .table-cell__content-grank {
        margin-left: auto;
    }
}
</style>
