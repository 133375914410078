//detection for safari
const isSafari = () => {
    const agent = navigator.userAgent.toLowerCase();
    return agent.includes('safari') && !agent.includes('chrome');
};

//detection for ios
const toMatch = [
    /iPhone/i,
    /iPad/i,
    /iPod/i,
];

const isIOS = () => {
    return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem));
}

//user agent detection
const userAgent = () => {
    const ua = navigator.userAgent.toLowerCase();
    
    if (ua.includes('firefox')) {
        return 'firefox';
    }

    if (ua.includes('safari')) {
        return ua.includes('chrome') ? 'chrome' : 'safari';
    }
};

export {
    isSafari,
    isIOS,
    userAgent,
}