export const EventBus = require('./bus').default;
export const Events = {
    USER_UPDATE: 'user.update',
    SET_EDITABLE_KEYWORDS: 'set.editable.keywords',
    SET_KEYWORDS_TO_MOVE: 'set.keywords.to.move',
    DOCUMENT_CLICK: 'document.click',

    USER_ONBOARD: 'user.onboard',

    USED_TABLE_FILTER: 'used.table.filter',
    USED_GLOBAL_SEARCH: 'used.global.search',
    USED_ON_DEMAND_UPDATE: 'used.on.demand.update',

    CONNECT_GA_ACCOUNT: 'connect.ga.account',

    ADD_COMPETITOR_TO_HISTORICAL_CHART: 'add.competitor.to.historical.chart',

    CREATE_PROJECT: 'create.project',
    CREATE_GROUP: 'create.group',
    CREATE_GSC_PROFILE: 'create.gsc.profile',
    CREATE_KWS_FROM_SUGGESTIONS: 'create.keywords.from.suggestions',
    CREATE_SCHEDULED_REPORT: 'create.scheduled.report',
    CREATE_ADD_ON_USER: 'create.add.on.user',
    CREATE_KWS_FROM_IMPORT: 'create.keywords.from.import',
    ADD_GOOGLE_KEYWORDS: 'add.google.keywords',

    OPEN_SOV_TAB: 'open.sov.tab',
    OPEN_SUGGESTIONS_TAB: 'open.gsc.suggestions.tab',
    OPEN_TOP_PAGES_TAB: 'open.top.pages.tab',
    OPEN_GA_PAGE: 'open.ga.page',
    OPEN_GSC_PAGE: 'open.gsc.page',
    OPEN_SETTINGS_PAGE: 'open.settings.page',
    OPEN_BILLING_DETAILS_PAGE: 'open.billing.details.page',
    OPEN_ALERTS_PAGE: 'open.alerts.page',
    OPEN_SPYGLASS_PAGE: 'open.spyglass.page',
    OPEN_HISTORICAL_CHART: 'open.historical.chart',
    OPEN_SERP_HISTORY: 'open.SERP.history',
    OPEN_API_DOCUMENTATION: 'open.api.documentation',
    OPEN_SCHEDULED_REPORTS_HISTORY: 'open.scheduled.reports.history',

    EXPORT_PROJECT_CSV: 'export.project.csv',
    EXPORT_PROJECT_TEXT: 'export.project.text',
    EXPORT_PROJECT_PDF: 'export.project.pdf',

    SHARE_VIEWKEY: 'share.viewkey.link',
    SHARE_EMBEDDED: 'share.viewkey.embedded',

    UPDATE_TABLE_SETTINGS: 'update.table.settings',
    UPDATE_START_DATE: 'update.start.date',
    UPDATE_ACCOUNT_WIDE_BRANDING: 'update.account.wide.branding',
    UPDATE_PROJECT_SPECIFIC_BRANDING: 'update.project.specific.branding',
    UPDATE_ALERT_SETTINGS: 'update.alert.settings',

    UPGRADE_PACKAGE: 'upgrade.package',
    CANCEL_SUBSCRIPTION: 'cancel.subscription',

    IMPORT_CSV_UPLOAD_VIEW_EVENT: 'import.csv.view',
    IMPORT_MAPPING_VIEW_EVENT: 'import.mapping.view',
    IMPORT_MAPPING_ERROR_EVENT: 'import.mapping.error',
    IMPORT_FINISH_VIEW_EVENT: 'import.finish',
    IMPORT_ERROR_EVENT: 'import.error',

    PACKAGES_VIEW_EVENT: 'packages.view',
    PLAN_SELECTED_EVENT: 'plan.selected',
    CYCLE_CHANGED_EVENT: 'cycle.changed',
    KEYWORDS_CHANGED_EVENT: 'keywords.changed',
    SUBSCRIPTION_FAILED_EVENT: 'subscription.failed',

    PROJECT_ADD_INITIATED_EVENT: 'project.adding.initiated',
    DASHBOARD_VIEW_EVENT: 'dashboard.view',
    PROJECT_VIEW_EVENT: 'project.view',

    ADD_KEYWORD_ONBOARDING_VIEW_EVENT: 'onboarding.add.keyword.view',
    ADD_KEYWORD_GOOGLE_VIEW_EVENT: 'add.keyword.google.view',
    ADD_KEYWORD_MAPS_VIEW_EVENT: 'add.keyword.maps.view',
    KEYWORDS_LISTED_EVENT: 'keywords.listed',
    KEYWORDS_SUGGESTED_ADDED_EVENT: 'suggested.keywords.added',
    KEYWORDS_SUGGESTED_ADDED_ALL_EVENT: 'suggested.keywords.added.all',

    VIEWKEY_COPIED_EVENT: 'share.viewkey.link',
    EMBEDDED_COPIED_EVENT: 'share.viewkey.embedded',

    SERP_VIEW_EVENT: 'SERP.view',
    SERP_COMPETITORS_VIEW_EVENT: 'add.competitor.to.historical.chart',
    SERP_HISTORY_VIEW_EVENT: 'open.SERP.history',

    ACCOUNT_SIGNUP_STARTED: 'account.signup.started',
    ACCOUNT_SIGNUP_FAILED: 'account.signup.failed',
};

export const intercomEvents = {
    USED_TABLE_FILTER: Events.USED_TABLE_FILTER,
    USED_GLOBAL_SEARCH: Events.USED_GLOBAL_SEARCH,
    USED_ON_DEMAND_UPDATE: Events.USED_ON_DEMAND_UPDATE,
    USER_ONBOARD: Events.USER_ONBOARD,
    CONNECT_GA_ACCOUNT: Events.CONNECT_GA_ACCOUNT,
    ADD_COMPETITOR_TO_HISTORICAL_CHART: Events.ADD_COMPETITOR_TO_HISTORICAL_CHART,
    CREATE_PROJECT: Events.CREATE_PROJECT,
    CREATE_GROUP: Events.CREATE_GROUP,
    CREATE_GSC_PROFILE: Events.CREATE_GSC_PROFILE,
    CREATE_KWS_FROM_SUGGESTIONS: Events.CREATE_KWS_FROM_SUGGESTIONS,
    CREATE_SCHEDULED_REPORT: Events.CREATE_SCHEDULED_REPORT,
    CREATE_ADD_ON_USER: Events.CREATE_ADD_ON_USER,
    CREATE_KWS_FROM_IMPORT: Events.CREATE_KWS_FROM_IMPORT,
    ADD_GOOGLE_KEYWORDS: Events.ADD_GOOGLE_KEYWORDS,
    OPEN_SOV_TAB: Events.OPEN_SOV_TAB,
    OPEN_SUGGESTIONS_TAB: Events.OPEN_SUGGESTIONS_TAB,
    OPEN_TOP_PAGES_TAB: Events.OPEN_TOP_PAGES_TAB,
    OPEN_GA_PAGE: Events.OPEN_GA_PAGE,
    OPEN_GSC_PAGE: Events.OPEN_GSC_PAGE,
    OPEN_SETTINGS_PAGE: Events.OPEN_SETTINGS_PAGE,
    OPEN_BILLING_DETAILS_PAGE: Events.OPEN_BILLING_DETAILS_PAGE,
    OPEN_ALERTS_PAGE: Events.OPEN_ALERTS_PAGE,
    OPEN_SPYGLASS_PAGE: Events.OPEN_SPYGLASS_PAGE,
    OPEN_HISTORICAL_CHART: Events.OPEN_HISTORICAL_CHART,
    OPEN_SERP_HISTORY: Events.OPEN_SERP_HISTORY,
    OPEN_API_DOCUMENTATION: Events.OPEN_API_DOCUMENTATION,
    OPEN_SCHEDULED_REPORTS_HISTORY: Events.OPEN_SCHEDULED_REPORTS_HISTORY,
    EXPORT_PROJECT_CSV: Events.EXPORT_PROJECT_CSV,
    EXPORT_PROJECT_TEXT: Events.EXPORT_PROJECT_TEXT,
    EXPORT_PROJECT_PDF: Events.EXPORT_PROJECT_PDF,
    SHARE_VIEWKEY: Events.SHARE_VIEWKEY,
    SHARE_EMBEDDED: Events.SHARE_EMBEDDED,
    UPDATE_TABLE_SETTINGS: Events.UPDATE_TABLE_SETTINGS,
    UPDATE_START_DATE: Events.UPDATE_START_DATE,
    UPDATE_ACCOUNT_WIDE_BRANDING: Events.UPDATE_ACCOUNT_WIDE_BRANDING,
    UPDATE_PROJECT_SPECIFIC_BRANDING: Events.UPDATE_PROJECT_SPECIFIC_BRANDING,
    UPDATE_ALERT_SETTINGS: Events.UPDATE_ALERT_SETTINGS,
    UPGRADE_PACKAGE: Events.UPGRADE_PACKAGE,
    CANCEL_SUBSCRIPTION: Events.CANCEL_SUBSCRIPTION,
};

export const mixpanelEvents = {
    NOT_SENSITIVE: {
        ACCOUNT_SIGNUP_STARTED: 'Account: Signup Started',
        ACCOUNT_SIGNUP_FAILED: 'Account: Signup Failed',
    },
    SENSITIVE: {
        IMPORT_CSV_UPLOAD_VIEW_EVENT: 'Import data: Upload CSV View',
        IMPORT_MAPPING_VIEW_EVENT: 'Import data: Mapping Data View',
        IMPORT_MAPPING_ERROR_EVENT: 'Import data: Mapping Data Error',
        IMPORT_FINISH_VIEW_EVENT: 'Import data: Fix & Finish View',
        IMPORT_ERROR_EVENT: 'Import data: Completion Error',
        PACKAGES_VIEW_EVENT: 'Subscription: Plans Page Viewed',
        PLAN_SELECTED_EVENT: 'Subscription: Plan Selected',
        CYCLE_CHANGED_EVENT: 'Subscription: Refresh Cycle Toggle Clicked',
        SUBSCRIPTION_FAILED_EVENT: 'Subscription: Creation Failed',
        KEYWORDS_CHANGED_EVENT: 'Subscription: Keyword Package Dropdown Clicked',
        PROJECT_ADD_INITIATED_EVENT: 'Project: Add Initiated',
        ADD_KEYWORD_ONBOARDING_VIEW_EVENT: 'Add Keyword: Onboarding Google Organic Page View',
        ADD_KEYWORD_GOOGLE_VIEW_EVENT: 'Add Keyword: Google Organic Page View',
        ADD_KEYWORD_MAPS_VIEW_EVENT: 'Add Keyword: Google Local Maps Page View',
        KEYWORDS_LISTED_EVENT: 'Add Keyword: Keywords listed',
        KEYWORDS_SUGGESTED_ADDED_EVENT: 'Add Keyword: Added from Suggested Keywords',
        KEYWORDS_SUGGESTED_ADDED_ALL_EVENT: 'Add Keyword: Add all From Suggested Keywords',
        DASHBOARD_VIEW_EVENT: 'Dashboard Page View',
        PROJECT_VIEW_EVENT: 'Project: Page View',
        VIEWKEY_COPIED_EVENT: 'Share: ViewKey Link Copied',
        EMBEDDED_COPIED_EVENT: 'Share: Embedded Code Copied',
        SERP_VIEW_EVENT: 'SERP: View',
        SERP_COMPETITORS_VIEW_EVENT: 'SERP: Competitors View',
        SERP_HISTORY_VIEW_EVENT: 'SERP: History view',
    },
};
