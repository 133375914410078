import i18n from '@/i18n';

const actions = [
    {type: 'get-pdf', icon: 'kw kw-download', text: 'PDF'},
    {type: 'get-csv', icon: 'kw kw-download', text: 'CSV'},
    {type: 'get-viewkey', icon: 'kw kw-external-link', text: i18n.tc('get-viewkeys', 1)},
    {type: 'edit-project', icon: 'kw kw-edit', text: i18n.t('edit-project')},
    {type: 'archive-project', icon: 'kw kw-archive', text: i18n.t('archive-project')},
];

const viewList = [
    {value: 'chart', icon: 'kw kw-bar-chart-2'},
    {value: 'table', icon: 'kw kw-menu'},
];

export {actions, viewList};