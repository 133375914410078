<template>
    <rotating-update-icon v-if="showLoader" />
    <span v-else>{{ getValue }}</span>
</template>

<script>
import { mapGetters } from 'vuex';
import { switchDateFormat } from '@/helpers/format-date-service';
import RotatingUpdateIcon from '@/components/ui-elements/RotatingUpdateIcon';
export default {
    name: 'LastUpdatedRenderer',
    components: { RotatingUpdateIcon },
    computed: {
    ...mapGetters(['getIsRefreshRunning','getCurrentRefreshingKeywords']),
        getValue() {
           const crawled = new Date(this.params.data.crawled_at * 1000);
           const updated = new Date(this.params.data.updated_at);

           return switchDateFormat(crawled > updated ? crawled : updated, 'Y-m-d hh:mm', true);
        },
        showLoader(){
            return this.getIsRefreshRunning && this.getCurrentRefreshingKeywords.includes(this.params.data.id);
        }
    },
};
</script>
