<template>
    <div
        v-click-outside="onClose"
        class="date-ranges-dropdown dropdown_container"
    >
        <kw-button
            button-type="secondary"
            :button-text="value || getLabel"
            class="top-bar__button-dates-ranges"
            @click="handleButtonClick"
        />
        <custom-dropdown-body
            ref="dateRangeDropdown"
            position="right-bottom"
        >
            <div v-if="showDatePickerList" class="date-list">
                <div
                    v-for="(item,key) in dateList"
                    :key="item.label"
                    @click="dateRangeClick(key)"
                    :class="{'date-item': true, 'active': value === item.value}"
                >
                    {{ item.label }}
                </div>
            </div>
            <ul v-else class="v-context">
                <li
                    v-for="(item, i) in rangesConfigs"
                    :id="item.value"
                    :key="i"
                    @click="dateRangeClick(item)"
                >
                    <template v-if="!item.hide">
                        <a><span class="range-option">{{ item.label }}</span></a>
                    </template>
                </li>
            </ul>
           
        </custom-dropdown-body>
    </div>
</template>

<script>
import {
    mapGetters,
} from 'vuex';
import { projectMetricsDateDropdownRanges } from '@/components/metrics/configs'
import { exactDatePickerList } from '@/components/date-range-picker/datePickerList'

export default {
    name: 'DateRangesDropdown',
    props: {
        type: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: false,
        },
        allTime: {
            type: Boolean,
            default: true
        },
        showDatePickerList: {
            type: Boolean,
            default: false
        },
        hiddenDays: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            rangesConfigs: projectMetricsDateDropdownRanges.filter(item => (item.label!=='All time') || (this.allTime && item.label==='All time')),
            dateList: Object.keys(exactDatePickerList)
            .filter(item => !this.hiddenDays.includes(item))
            .reduce((obj, key) => {
                obj[key] = exactDatePickerList[key];
                return obj;
            }, {})
        }
    },
    computed: {
        ...mapGetters([
            'getMindshareteHistoryDaysRange',
            'getMindshareRankingDaysRange'
        ]),
        getLabel() {
            if (this.type === 'trending') {
                return this.getMindshareteHistoryDaysRange ? this.getMindshareteHistoryDaysRange.label : '';
            }
            if (this.type === 'ranking-distribution') {
                return this.getMindshareRankingDaysRange ? this.getMindshareRankingDaysRange.label : '';
            }
            return '';
        }
    },
    methods: {
        dateRangeClick(value) {
            this.$emit('onSelect', value);
            this.onClose();
        },
        handleButtonClick() {
            this.$refs.dateRangeDropdown.toggleDropdownOpen();
        },
        onClose() {
            if (this.$refs.dateRangeDropdown?.getIsDropdownOpened) {
                this.$refs.dateRangeDropdown.closeDropdown();
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.date-ranges-dropdown {
    display: flex;
    height: 22px;
    flex-flow: row nowrap;
    align-self: center;

    /deep/ .kw-button {
        width: 98px;
        height: 22px !important;
        padding: 0 4px !important;
        gap: 4px;
        border-radius: 4px;
        border: 1px 0px 0px 0px;
        display: flex;
        justify-content: center !important;
        font-size: 12px;
        color: kw-color(kw-grey, 1) !important;
    }

    .v-context {
        width: 100px;
        a {
            align-items: flex-start;
            span {
                margin: 0;
            }
        }

    }

    &::after {
        display: none;
    }

    .btn-content {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        display: inline-block;
        text-align: center;
        color: kw-color(kw-grey, 1);
    }
}

.v-context {
    width: 150px;
    position: relative;
}

.custom_dropdown_body {
    box-shadow: none !important;
    margin-top: 4px !important;
}

.date-list {
    position: relative;
    padding: 24px 16px;
    background-color: kw-color(kw-white, 1);
    box-shadow: 0px 2px 4px rgba(31, 63, 97, 0.1);
    .date-item {
        padding: 9px 12px;
        height: 36px;
        cursor: pointer;
        white-space: nowrap;
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;

        &:hover,
        &.active{
            background-color: kw-color(kw-white, 7);
        }
    }
}

@media screen and (max-width: 959px) {
    .date-ranges-dropdown {
        flex-grow: 1;
        justify-content: flex-end;
    }

    /deep/ .top-bar__button-date-ranges {
        padding: 8px 10px;

        span {
            font-size: 14px;
        }
    }
}
</style>
