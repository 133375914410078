<template>
    <div class="project_edit_tag_section">
        <div class="project_edit_tag_container">
            <div class="edit_tag_title_block">
                <div class="edit_tag_title">
                    {{ $t('edit-tag') }}
                </div>
                <div class="edit_tag_sub_title">
                    {{ $t('for-project') }}: {{ getCurrentProject.name }}
                </div>
            </div>
            <form class="project_edit_tag_form">
                <span class="tag_input_label">
                    {{ $t('tag-name') }}
                </span>
                <div class="project_edit_tag_input">
                    <input
                        v-model="tag"
                        type="text"
                        :placeholder="$t('edit-tag')"
                        class="text-input"
                    >
                </div>
                
                <div class="project_edit_tag_actions">
                    <div class="button_item">
                        <kw-button
                            :button-text="$t('cancel')"
                            button-type="secondary"
                            :disabled="editTagLoading"
                            @click="handleCancelClick"
                        />
                    </div>
                    <div class="button_item">
                        <kw-button
                            :button-text="$t('save')"
                            button-type="primary"
                            type="submit"
                            :disabled="!isSubmitDisabled || editTagLoading"
                            :loading="editTagLoading"
                            @click.prevent="handleEditTagClick"
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'EditProjectTag',
        props:{
            getCurrentProject: {
                type: Object,
                required: true,
            },
            toggleProjectEditTag: {
                type: Function,
                required: true,
            },
            editProjectTag: {
                type: Function,
                required: true,
            },
            getActiveTag: {
                type: Object,
                required: true,
            },
            
        },
        data () {
            return {
                tag: '',
                editTagLoading: false
            };
        },
        computed: {
            isSubmitDisabled (){
                return !!this.tag.length;
            }
        },
        watch: {
            'getActiveTag' () {
                this.tag = this.getActiveTag.name || ''
            }
        },
        mounted () {
            this.tag = this.getActiveTag.name || ''
        },
        methods: {
            updateTags (newTags) {
                this.tags = newTags;
            },
            handleCancelClick () {
                this.toggleProjectEditTag()
            },

            async handleEditTagClick () {
                this.editTagLoading = true;
                try {
                    await this.editProjectTag({
                        projectId: this.getCurrentProject.project_id, 
                        tagId: this.getActiveTag.id,
                        payload: {
                            name:  this.tag
                        }
                    });
                } catch (error) {
                    console.log(error, 'error')
                }
               
                this.editTagLoading = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
  .project_edit_tag_section {
    width: 100%;

  .project_edit_tag_container {
    padding: 25px 20px;

    .edit_tag_title_block {
      padding-bottom: 40px;

      .edit_tag_title {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
      }

      .edit_tag_sub_title {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #828F9C;
        padding-top: 5px;
      }
    }

  }

  .project_edit_tag_form {
    .tag_input_label {
      color: kw-color(kw-black, 1);
      font-size: 12px;
      padding-bottom: 5px;
      display: block;
      font-weight: 500;
    }

    .project_edit_tag_input {
      input {
        width: 100%;
      }
    }
  }

  .project_edit_tag_actions {
    display: flex;
    padding: 50px 0 0 0;

    .button_item {
      flex: 1;

      button {
        width: 100%;
      }

      &:last-child {
        margin-left: 15px;
      }
    }

  }

}
</style>