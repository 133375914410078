<template>
    <div class="trial-period-header">
        <span>
            {{
                $t('trial-period-rest', {
                    trialPeriod: $t(`months.${getInitialTrialPeriod}`),
                    trialPeriodRestDays: getTrialPeriod,
                })
            }}
            <span
                @click="navigateToBilling"
                v-text="$t('click-here')"
                class="click-here"
            />
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TrialPeriodHeader',
    computed: {
        ...mapGetters(['getTrialPeriod', 'getInitialTrialPeriod']),
    },
    methods: {
        navigateToBilling() {
            this.$router.push({
                name: 'Pricing',
            });
        },
    },
};
</script>

<style scoped lang="scss">
.trial-period-header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #d73855;
    color: kw-color(kw-white, 1);
    font-weight: 500;
    font-size: 14px;
    height: $trial-header-height;
    z-index: 9999;

    span {
        @media (max-width: 960px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 12px;
        }
    }

    .click-here {
        text-decoration: underline;
    }
    .click-here:hover {
        background-color: #e2748a;
        cursor: pointer;
    }
}
</style>
