<template>
    <div class="signup-info-section">
        <template v-if="currentPackage">
            <h2 class="title">{{
                    (isFreePackage && !expirePostTrial)
                        ? $t('packages.free.title')
                        : (trialExists || expirePostTrial)
                        ? $t('signup-form.trial')
                        : $t('signup-form.title', {
                              type: $t(
                                  `common.${
                                      isEnterprise ? 'enterprise' : 'business'
                                  }`,
                              ),
                    })
                }}
                <span class="like-link" @click="goToPackages">{{
                    $tc('change', 1)
                }}</span>
            </h2>
            <div class="permissions">
                <permissions :current-package="currentPackage" :expirePostTrial="expirePostTrial"  />
            </div>
            <div v-if="!isFreePackage">
                <pricing-info
                    :current-package="currentPackage"
                    :billing-cycle="billingCycle"
                    :trial-exists="trialExists"
                    :showAdditionalInfo="true"
                />
                <div v-if="!isFreePackage">
                    <div class="toggler-row">
                        <div
                            class="billing-label billing-monthly"
                            @click="changeBillingCycle(false)"
                            v-text="$t('signup-form.billing-cycle.monthly')"
                        />
                        <label
                            data-cy="billing-cycle-label"
                            class="billing-cycle-label"
                        >
                            <toggler
                                :toggler="billingCycleToggle"
                                @toggler_click="changeBillingCycle"
                                color="grey"
                            />
                        </label>
                        <div
                            class="billing-label billing-annually"
                            @click="changeBillingCycle(true)"
                            v-text="$t('signup-form.billing-cycle.annually')"
                        />
                    </div>

                    <p class="hint"><span class="ast">*</span>{{ $t('signup-form.billing-cycle.hint') }}</p>
                </div>
        </div>
        </template>
        <loader v-else :size="60" :width="4" />
    </div>
</template>

<script>
import { BILLING_CYCLES } from '@/constants';
import { mapActions } from 'vuex';
import Permissions from '@/components/billing/Permissions';
import PricingInfo from '@/components/billing/PricingInfo';

export default {
    name: 'SignupInfo',
    components: {
        Permissions,
        PricingInfo,
    },
    props: {
        currentPackage: {
            type: Object | null,
            required: true,
        },
    },
    data() {
        return {
            packageIdentity:
                this.$route.query.package_identity ?? process.env.FREE_PLAN_ID,
            billingCycle:
                this.$route.query.billing_cycle ?? BILLING_CYCLES.monthly,
            billingCycleToggle:
                this.$route.query.billing_cycle === BILLING_CYCLES.annually,
            trialExists: this.$route.query.trial == 'true' || this.$route.query.expire_post_trial == 1,
        };
    },
    computed: {
        isFreePackage() {
            return this.currentPackage?.keywords_tracked === 10;
        },
        isEnterprise() {
            return this.currentPackage?.keywords_tracked > 5000;
        },
        expirePostTrial(){
            return this.$route.query.expire_post_trial == 1
        }
    },
    async mounted() {
        this.setBillingCycle(this.billingCycle);
    },
    methods: {
        ...mapActions(['setCoupon', 'setBillingCycle']),
        changeBillingCycle(newVal) {
            this.billingCycleToggle = newVal ?? !this.billingCycleToggle;
            this.billingCycle = this.billingCycleToggle
                ? BILLING_CYCLES.annually
                : BILLING_CYCLES.monthly;
            this.setBillingCycle(this.billingCycle);
        },
        goToPackages() {
            window.location.href = 'https://keyword.com/packages';
        },
    },
};
</script>

<style lang="scss" scoped>
.signup-info-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%;

    /deep/ .button-loader {
        margin: auto;
    }

    h2.title {
            display: inline-block;
            margin: 0;
            font-family: 'Aeonik';
            font-size: 16px;
            font-weight: 400;
            line-height: 19.2px;
            letter-spacing: 0em;
            margin-bottom: 8px;
            color: kw-color(kw-white, 1);


        .like-link {
            color: kw-color(kw-blue, 6);
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            cursor: pointer;
            margin-left: 4px;
        }
    }

    .permissions {
        display: flex;
        gap: 20px;
        flex-direction: column;
        #packages-selector {
            height: 24px;
            width: 100%;
            background: transparent;

            /deep/ .form-control.dropdown-toggle {
                padding: 0;
                margin: 0;
                display: flex;
                height: 24px;
                padding: 0px 10px 0px 8px;
                align-items: center;
                border-radius: 4px;
                border: 1px solid #c4c4c4;
                background: linear-gradient(
                    0deg,
                    rgba(30, 44, 67, 0.9) 0%,
                    rgba(30, 44, 67, 0.9) 100%
                );

                &:focus {
                    border: 1px solid #c4c4c4 !important;
                }

                &:after {
                    top: 8px;
                }
            }

            /deep/ .dropdown-menu {
                color: rgba(255, 255, 255, 0.7) !important;
                background: linear-gradient(
                    0deg,
                    rgba(30, 44, 67, 0.9) 0%,
                    rgba(30, 44, 67, 0.9) 100%
                );
            }

            /deep/ .btn-content,
            /deep/ .dropdown-list li span {
                color: rgba(255, 255, 255, 0.7);
                font-family: Aeonik;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }
        }
    }

    /deep/ .price-row {
        color: var(--White, #fff);
        font-family: Aeonik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .toggler-row {
        margin-top: 16px;
        position: relative;
        display: flex;
        justify-content: flex-start;
        gap: 10px;

        /deep/ .toggler_abled.toggler_active .kw-check {
            color: transparent;
        }

        .billing-cycle-label {
            width: 35px;
        }

        .billing-label {
            cursor: pointer;
            color: #fff;
            font-family: 'Aeonik';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .hint {
        font-family: 'Aeonik';
        font-size: 10px;
        font-weight: 400;
        margin: 16px 0 0;
        line-height: 12px;
        .ast {
            font-size: 14px;
            line-height: 16.8px;
        }
    }

    @media screen and (max-width: 980px) {
        /deep/ .title {
            color: #15394f !important;
            font-family: 'Aeonik';
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
        }
        .hint{
            color: #15394f;
        }
    }
}
</style>
