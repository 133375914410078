<template>
    <div class="addons-settings__mobile">
        <template v-for="(user, i) in addons">
            <div
                v-if="isRowVisible(user)"
                :key="i"
                class="user"
            >
                <div class="user__row">
                    <div
                        class="user__header"
                        v-text="headers[0].title"
                    />
                    <div class="user__content">
                        <kw-input-field
                            v-model.trim="user.email"
                            class="text-input"
                            :disabled="user.master"
                            :name="`mobile-addon-email-${i}`"
                            :placeholder="$t('example-domain')"
                            :error-data="userInputError('email', i)"
                            @input="emailInputHandler(i)"
                        />
                        <span
                            v-if="user.master"
                            class="kw kw-lock"
                        />
                    </div>
                </div>
                <div class="user__row">
                    <div
                        class="user__header"
                        v-text="headers[1].title"
                    />
                    <div class="user__content">
                        <kw-input-field
                            v-model.trim="user.password"
                            class="text-input"
                            :disabled="user.master"
                            :name="`mobile-addon-password-${i}`"
                            :placeholder="passwordPlaceholder(user)"
                            type="password"
                            :error-data="userInputError('password', i)"
                            @input="passwordInputHandler(i)"
                            @blur="passwordBlurHandler(i)"
                        />
                        <span
                            v-if="user.master"
                            class="kw kw-lock"
                        />
                    </div>
                </div>
                <div class="user__row">
                    <div
                        class="user__header"
                        v-text="headers[2].title"
                    />
                    <div class="user__content">
                        <custom-v-select
                            :id="user.master && 'masterAccess'"
                            class="btn-block"
                            :class="{'masterAccessDropdown': user.master}"
                            options-label="label"
                            options-value="value"
                            :options="getCustomSelectOptions(user)"
                            :value="getCustomSelectValue(user)"
                            @change="selectAccess(user, $event)"
                        />
                    </div>
                </div>
                <div class="user__row">
                    <div
                        class="user__header"
                        v-text="headers[3].title"
                    />
                    <div class="user__content">
                        <div class="btn-group">
                            <kw-button
                                :button-disabled="isAdminUser(user) || user.master"
                                :append-icon-class="getIconClass(user)"
                                class="restrictions "
                                :button-text="getButtonText(user)"
                                button-type="secondary"
                                @click="openProjectsList(i)"
                            />
                        </div>
                        <span
                            v-if="!user.master"
                            class="kw kw-trash-2"
                            @click="deleteAddonUser(i)"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { ADMIN_ACCESS } from '@/configs/accessConfig.json';

    export default {
        name: 'AddonsSettingsMobile',
        props: {
            addons: {
                type: Array,
                required: true,
            },
            deleteAddonUser: {
                type: Function,
                required: true,
            },
            emailInputHandler: {
                type: Function,
                required: true,
            },
            headers: {
                type: Array,
                required: true,
            },
            isRowVisible: {
                type: Function,
                required: true,
            },
            openProjectsList: {
                type: Function,
                required: true,
            },
            passwordBlurHandler: {
                type: Function,
                required: true,
            },
            passwordInputHandler: {
                type: Function,
                required: true,
            },
            passwordPlaceholder: {
                type: Function,
                required: true,
            },
            selectAccess: {
                type: Function,
                required: true,
            },
            userInputError: {
                type: Function,
                required: true,
            },
            getIconClass: {
                type: Function,
                required: true
            },
            getButtonText: {
                type: Function,
                required: true
            },
            getCustomSelectOptions: {
                type: Function,
                required: true
            },
            getCustomSelectValue: {
                type: Function,
                required: true
            }
        },

        data() {
            return {
                menu: true,
            }
        },

        methods: {
            isAdminUser(user) {
                return user.access === ADMIN_ACCESS;
            }
        }
    }
</script>

<style lang="scss" scoped>
  .addons-settings__mobile {
    display: flex;
    flex-flow: column nowrap;

    /deep/ .form-control.dropdown-toggle {
      background-color: #F8F9FA !important;
    }

    @media screen and (min-width: 961px) {
      display: none;
    }
  }

  .user {
    background-color: #F8F9FA;
    border: 1px solid #DEE5EC;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 12px;
    width: 100%;

    &:first-child {
      background-color: rgba(38, 66, 98, .03);
    }
  }

  .user__row {
    align-items: center;
    border-bottom: 1px solid #DEE5EC;
    display: flex;
    flex-flow: row nowrap;
    height: 48px;

    &:last-child {
      border-bottom: none;
    }
  }

  .user__header,
  .user__content {
    flex: 1 0 50%;
    padding: 0 8px;
    width: 50%;
  }

  .user__header {
    border-right: 1px solid #DEE5EC;
    color: kw-color(kw-grey, 1);
    font-size: 12px;
    font-weight: 500;
    line-height: 48px;
    text-transform: capitalize;
  }

  .user__content {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;

    /deep/ .kw-button {
      width: 100%;

      &.restrictions {
        justify-content: space-between;
        border: 1px solid #AFBAC7 !important;
        color: kw-color(kw-black, 1);
        font-weight: 400;
        box-shadow: none;

        span {
          font-size: 14px;
          line-height: 15px;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.kw-chevron-down {
            font-size: 10px;
          }

          &.kw-heart {
            font-size: 14px;
            width: 15px;
          }
        }

        &:disabled {
          opacity: .5;
          color: kw-color(kw-black, 1);
          border: none;
          box-shadow: none;
        }
      }
    }

    .text-input {
      background: #F8F9FA;
      font-size: 13px;
      width: 100%;

      &:disabled {
        color: kw-color(kw-grey, 1);
        padding-right: 20px;
      }
    }

    .kw-lock {
      color: kw-color(kw-grey, 1);
      font-weight: 700;
      margin-left: -20px;
    }

    .kw-button {
      width: 100%;
    }

    .button-component_select-trigger {
      padding: 0 8px 0 10px;

      /deep/ .kw-heart {
        color: kw-color(kw-grey, 1) !important;
        font-size: 12px;
        transform: rotate(0);
      }
    }
    /deep/ .btn-group {
      margin-right: 6px;
      width: calc(100% - 20px);
    }

    .masterAccessDropdown.disabled {
      /deep/ .dropdown-toggle {
        background-color: #E9EAEB;
      }
    }
  }
</style>
