import apiFactory from '@/api';
const countriesApi = apiFactory.get('countries');

import {SET_COUNTRIES, SET_STATES, SET_BILLING_STATES} from '../mutations';

const countriesStates = {
    state: {
        countries: [],
        states: [],
        billingStates: [],
    },
    mutations: {
        [SET_COUNTRIES]: (state, countries) => state.countries = countries,
        [SET_STATES]: (state, states) => state.states = states,
        [SET_BILLING_STATES]: (state, states) => state.billingStates = states,
    },
    getters: {
        getAllCountries: state => state.countries
            .map(el => ({label: el.attributes.name, value: el.attributes.code}))
            .sort((a, b) => a.label > b.label ? 1 : -1),
        getCountryByCode: state => country => state.countries.find(el => el.attributes.code === country),
        getStates: state => state.states.reduce((acc, cur) => {
            const {name} = cur.attributes;
            // remove empty and doubles states
            if (!name || acc.some(el => el.label === name)) return acc;
            acc.push({label: name, value: name});

            return acc;
        }, []),
        getBillingStates: state => state.billingStates.reduce((acc, cur) => {            
            const {name} = cur.attributes;
            // remove empty and doubles states
            if (!name || acc.some(el => el.label === name)) return acc;
            acc.push({label: name, value: name});
            return acc;
        }, []),
        getAllCallingCodes: state => state.countries
            .filter(el => el.attributes.phone_code)
            .map(el => {
                const {code, name, phone_code} = el.attributes;

                return {
                    label: [phone_code.replace(/\s/g, ''), code],
                    searchKey: name,
                    value: `${phone_code.replace(/\s/g, '')}-${code}`,
                };
            }),
    },
    actions: {
        async fetchCountries ({getters, commit}) {
            // we already loaded countries array to state
            if (getters.getAllCountries.length) {
                return;
            }

            try {
                const res = await countriesApi.fetchCountries();
                commit(SET_COUNTRIES, res.data.data);
            } catch (error) {
                commit(SET_COUNTRIES, []);
            }
        },
        async fetchStates ({commit}, code) {
            try {
                const res = await countriesApi.fetchStates(code);
                commit(SET_STATES, res.data.data);
            } catch (error) {
                commit(SET_STATES, []);
            }
        },
        async fetchBillingStates ({commit}, code) {
            try {
                const res = await countriesApi.fetchStates(code);
                commit(SET_BILLING_STATES, res.data.data);
            } catch (error) {
                commit(SET_BILLING_STATES, []);
            }
        },
    },
};

export default countriesStates;
