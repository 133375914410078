<template>
    <div
        class="create-keywords-from-suggestions-modal"
        data-cy="create-keywords-from-suggestions-modal"
    >
        <closer @click="close" />
        <div
            class="custom-modal__header custom-modal_draggable"
            data-cy="create-keywords-from-suggestions-modal-header"
        >
            {{ $t("add-keyword-or-s") }}
        </div>
        <div
            class="custom-modal__body custom-modal__body_export"
            data-cy="create-keywords-from-suggestions-modal-body"
        >
            <div class="projects-select">
                <div class="projects-select-label">
                    {{ $t("to-project", { project: connection.project.name }) }}
                </div>
            </div>
            <div class="dropdowns">
                <div class="dropdown-wrapper region">
                    <label class="text-label" for="region">
                        {{ $t("region") }}
                    </label>
                    <custom-v-select
                        id="region"
                        options-label="name"
                        options-value="value"
                        select-type="region"
                        data-cy="keywords-region-dropdown"
                        :placeholder="$t('select-a-region')"
                        search
                        :class="{
                            'custom-dropdown_filled': region,
                        }"
                        :options="regionsForSelect"
                        :value.sync="region.value"
                        dropdown-menu-data-cy="edit-keywords-region-list"
                        dropdown-search-data-cy="edit-keywords-region-search"
                        @change="changeRegion"
                    />
                </div>
                <div class="dropdown-wrapper device">
                    <label class="text-label" for="device">
                        {{ $t("device") }}
                    </label>
                    <custom-v-select
                        id="device"
                        options-class="class"
                        options-label="name"
                        options-value="value"
                        select-type="device"
                        data-cy="keywords-device-dropdown"
                        :placeholder="$t('select-a-device')"
                        :class="{
                            'custom-dropdown_filled': device,
                        }"
                        :options="deviceOptions"
                        :value.sync="device"
                        dropdown-menu-data-cy="edit-keywords-device-list"
                        dropdown-search-data-cy="edit-keywords-device-search"
                        @change="changeDevice"
                    />
                </div>
            </div>
            <div class="tags">
                <div class="tags-label">{{ $t("tag-s") }}</div>
                <manage-tags-container
                    :activeTag="activeTag"
                    @change="selectTags"
                />
            </div>
            <div class="keywords-from-suggestions-buttons">
                <kw-button
                    :button-text="$t('cancel')"
                    button-type="secondary"
                    :disabled="getGSCKeywordsFromSuggestionsCreating"
                    @click="close"
                />
                <kw-button
                    type="submit"
                    button-type="primary"
                    :button-text="$t('add-keyword-or-s')"
                    :button-disabled="getGSCKeywordsFromSuggestionsCreating"
                    :loading="getGSCKeywordsFromSuggestionsCreating"
                    @click="createKeywords"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { KEYWORD_SOURCES, MIXPANEL_EVENT_CONSTANTS } from "@/constants";
import { deviceOptions } from "components/modals/configs";
import { regionsForSelect } from "@/helpers/regions-service";
import ManageTagsForGSCModalContainer from "./components/ManageTagsForGSCModalContainer";
export default {
    name: "GSCCreateKeywordsFromSuggestionsModal",
    components: {
        "manage-tags-container": ManageTagsForGSCModalContainer,
    },
    props: {
        activeTag: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        connection: {
            type: Object,
            required: true,
        },
        keywords: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            "getGSCSuggestionsRestoring",
            "getGSCProfileCurrentConnection",
            "getGSCKeywordsFromSuggestionsCreating",
        ]),
    },
    data() {
        return {
            isProjectsListVisible: false,
            projects: [],
            region: regionsForSelect[0],
            device: deviceOptions[0].value,
            tags: [],
            regionsForSelect,
            deviceOptions,
        };
    },
    created() {
        this.setDefaults();
    },
    methods: {
        ...mapActions(["addKeyword", "setKeywordsFromSuggestionsCreating"]),
        close() {
            this.$emit("close");
        },
        async createKeywords() {
            this.setKeywordsFromSuggestionsCreating(true);
            await this.addKeyword({
                keyword: {
                    domain: false,
                    geolocation: {
                        GMB: "",
                        changeLocation: false,
                        ignoreLocal: true,
                        location: [""],
                    },
                    isBulkImport: false,
                    includedInSov: true,
                    isManualGmb: false,
                    language: { value: "en" },
                    tags: this.tags,
                    platform: this.device,
                    region: this.region,
                    keywords: this.keywords,
                    source: KEYWORD_SOURCES.GSC.value,
                    url: this.getGSCProfileCurrentConnection.domain,
                },
                type: "web",
                tagsAsPartOfKeyword: true,
                exact: false,
                leading_source: MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.PROJECT_DASHBOARD,
            })
                .then(() => {
                    this.close();
                })
                .catch(error => {
                    this.$toastr.e(error);
                })
                .finally(() => {
                    this.setKeywordsFromSuggestionsCreating(false);
                });
        },
        changeRegion(newRegion) {
            this.region = newRegion
                ? regionsForSelect.filter(
                      region => region.value === newRegion,
                  )[0]
                : "";
        },
        changeDevice(newDevice) {
            this.device = newDevice;
        },
        selectTags(tags) {
            this.tags = tags.map(el => {
                return { text: el.name };
            });
        },
        setDefaults() {
            this.device = this.connection.rules?.device ?? "desktop";
            this.region = regionsForSelect.filter(
                region =>
                    region.country ===
                    (this.connection.rules?.country ?? "USA"),
            )[0];
            this.tags = [{ text: this.activeTag.name }];
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~sass/modal";

.create-keywords-from-suggestions-modal {
    padding: 24px 16px;

    /deep/ .button-closer {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 13px;
    }

    .custom-modal__header {
        padding: 0 16px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #17181b;
    }

    .custom-modal__body {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #17181b;

        .projects-select {
            position: relative;
            width: 100%;
            margin-top: 17px;

            .projects-select-label {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
            }

            .custom-dropdown {
                width: 100%;
            }

            .projects-list {
                margin-left: 0;
            }
        }

        .dropdowns {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            .dropdown-wrapper {
                width: 170px;

                #region,
                #device {
                    width: 100%;
                }
            }
        }

        .tags {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            margin-top: 16px;
        }

        .keywords-from-suggestions-buttons {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;

            button {
                width: 160px;
            }
        }
    }
}
</style>
