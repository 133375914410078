<template>
    <div class="header">
        <burger-and-logo />
        <header-links v-if="showHeaderLinks" />
        <profile />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BurgerAndLogo from './components/BurgerAndLogo';
import HeaderLinks from './components/HeaderLinks';
import Profile from './components/Profile';
export default {
    name: 'AppHeader',
    computed: {
        ...mapGetters(['getIsExpired', 'getIsRestrictedAccessUser', 'getUserDataProp']),
        showHeaderLinks(){
            return !this.getIsExpired && !this.getIsRestrictedAccessUser && !!this.getUserDataProp('userId');
        }
    },
    components: {
        'burger-and-logo': BurgerAndLogo,
        'header-links': HeaderLinks,
        profile: Profile,
    },
};
</script>

<style lang="scss" scoped>
.header {
    width: 100%;
    background-color: kw-color(kw-white, 1);
    border-bottom: 1px solid #e1e8ef;
    box-shadow: 0 2px 4px rgba(31, 63, 97, 0.03);
    box-sizing: border-box;
    color: kw-color(kw-black, 1);
    display: flex;
    height: $header-height;
    justify-content: space-between;
    padding: 0 24px;
    @media screen and (max-width: 960px) {
        padding: 0 16px;   
    }
}
</style>
