import {PUBLIC_ROUTES} from '@/constants';
import i18n from '@/i18n';
import initIntercom from './intercom';
import initProfitwell from './profitwell';
import initWootric from './wootric';
import initMixpanel from './mixpanel';
import toastr from '@/helpers/init-toastr';
import { cookieGetters } from '@/helpers/cookieManager';

const intercomErrorMessage = i18n.t('fail-to-init-intercom-service');

export default async (vueContext, prev) => {
    if (process.env.NODE_ENV !== 'production') return;

    if(!cookieGetters.getUserProfile) return;

    const services = {
        intercom: {init: initIntercom},
        profitwell: {init: initProfitwell},
        wootric: {init: initWootric},
    };

    Object.keys(services).forEach(async key => {
        try {
            await services[key].init(vueContext);
        } catch (error) {
            key === 'intercom' && toastr.e(intercomErrorMessage);
            console.error(error);
        }
    });
};