<template>
    <div class="block-wrapper">
        <div class="header-wrapper">
            <chart-header :title="$t('available-chart-titles.project_metrics')"
                :toolTip="$t('project_metrics-tooltip')" />
            <p class="info">
                {{ $t('comparison-timeframe') }}
            </p>
            <date-ranges-dropdown showDatePickerList type="trending" :value="date" @onSelect="onDateSelect" :hiddenDays="hiddenDays" />
            <kw-button
                :disabled="isLoading"
                class="hide-button"
                prepend-icon-class="kw kw-eye-off"
                button-type="secondary"
                @click="onHide"
            />
        </div>

        <metrics :data="getMetricsData" :loading="getMetricsIsFetching" type="chart" showPercentage />
    </div>
</template>

<script>
import Metrics from '@/components/metrics/Metrics';
import { mapGetters, mapActions } from "vuex";
import ChartBlockHeader from "./ChartBlockHeader.vue";
import DateRangesDropdown from '@/components/common/DateRangesDropdown.vue';
import { exactDatePickerList } from '@/components/date-range-picker/datePickerList'

export default {
    name: 'ProjectMetricsBlock',
    components: {
        'chart-header': ChartBlockHeader,
        'metrics': Metrics,
        'date-ranges-dropdown': DateRangesDropdown
    },
    data() {
        return {
            chart: null,
            date: 'Last month',
            isLoading: false,
            hiddenDays: ['today', 'this_month', 'last_3_months']
        };
    },
    computed: {
        ...mapGetters([
            'getCurrentProject',
            'getMetricsIsFetching',
            'getMetricsData',
            'getDisplaySettings',
            'getDisplaySetting'
        ]),
    },
    watch: {
        getMetricsData: function () {
            this.init();
        }
    },
    methods: {
        ...mapActions([
            'setProjectOverviewOptionVisibility',
            'updateOnlyDisplaySettings',
            'setProjectOverviewOptionIsVisible'
        ]),
        debouncedHandler: _.debounce(fn => fn(), 50),
        onHide() {
            this.setProjectOverviewOptionVisibility({ option: 'keywords_in_top', value: false });
        },
        onDateSelect(dateKey) {
            const metrics = exactDatePickerList[dateKey].altLabel;

            if (metrics) {
                this.date = exactDatePickerList[dateKey].label;
                this.updateOnlyDisplaySettings({ ...this.getDisplaySettings, metrics });
            }
        },
        setChart(value) {
            this.chart = value;
        },
        init() {
            const metricsValue = this.getDisplaySetting('metrics');
            const dateKey = Object.entries(exactDatePickerList).find(([key, val]) => val.altLabel === metricsValue)?.[0];
            if(dateKey){
                this.date = exactDatePickerList[dateKey].label;
            }
        },
        onHide() {
            this.isLoading = true;
            this.setProjectOverviewOptionIsVisible({ 
                projectId: this.getCurrentProject.id,
                option: "project_metrics",
                value: false 
            }).finally(()=>{
                this.isLoading = false;
            });
        },
    },
    mounted() {
        if (this.getMetricsData) {
            this.init();
        }
    },
};
</script>

<style lang="scss" scoped>
.block-wrapper {
    /deep/ .dashbord-metrics {
        .dashboard {
            display: flex;
            flex-flow: row wrap;
            gap: 8px;

            .dashboard-block {
                margin: 0;
                height: 144px;
                flex: 1 1 30%;
                display: flex;
                flex-direction: column;

                @media screen and (max-width: 900px) {
                    flex: 1 1 40%;
                }

                .dash-total {
                    flex: 1 1 auto;
                }
            }
        }

        .metrics-btn {
            display: none;
        }
    }
}

.header-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;

    .info {
        display: flex;
        align-items: center;
        text-wrap: nowrap;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        color: kw-color(kw-light-grey, 8);
        margin: 0 12px;
        .count {
            margin-right: 4px;
        }
    }

    @media screen and (max-width: 500px) {
        flex-wrap: wrap;
        gap: 10px;

        /deep/ .header {
            width: 100%;
        }

        .info {
            margin: 0;
        }
    }
    .hide-button{
        min-width: 24px;
        width: 24px;
        height: 24px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;

        /deep/.kw {
            font-weight: 900;
            font-size: 16px;
            color: #757C90;
        }
    }
}
</style>
