const enShare = {
    'download-csv-file': 'Download CSV file',
    'end-date': 'End Date',
    'export-data-range': 'Export a date range',
    'export-live-data': 'Export live data',
    'learn-more': 'Learn more',
    'ranking-data-verified-by': 'Ranking Report Data Verified By',
    'ranking-report': 'Ranking Report',
    'start-date': 'Start Date',
    'acc-wide-settings': 'Account-wide settings',
};

export default enShare;