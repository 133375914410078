<template>
    <div
        class="project_add_sub_project_section"
        data-cy="add-sub-project-section"
    >
        <div class="add_sub_project_container">
            <div class="title_block">
                <div class="title" data-cy="new-sub-project-title">
                    {{ $t('new-group') }}
                </div>
                <div class="parent_project_name" data-cy="new-sub-for-project">
                    {{ $t('for-project') }}: {{ getProjectForChildAction.name }}
                </div>
            </div>
            <form class="add_sub_project_form">
                <div class="add_sub_project_content">
                    <div class="add_sub_project_field">
                        <kw-input-field
                            v-model.trim="subProject"
                            :label="$t('group-name')"
                            class="text-input"
                            :class="{
                                'text-input_invalid': subProjectErr.text,
                            }"
                            :name="$t('category-name')"
                            :placeholder="$t('group-placeholder')"
                            type="text"
                            data-cy="create-sub-project-name-field"
                            :error-data="getGroupNameError"
                            @input="inputHandler"
                        />
                    </div>
                    <div class="add_sub_project_field mt20">
                        <label
                            class="text-label"
                            for="project-reporting-currency"
                        >
                            {{ $t('project-reporting-currency') }}
                        </label>
                        <custom-v-select
                            :class="visibilityClass"
                            id="project-reporting-currency"
                            data-cy="project-reporting-currency"
                            :options="getCurrencies"
                            search
                            search-key="label"
                            :value="currencyCode"
                            @change="handleCurrencyChange"
                            :placeholder="getCurrency"
                        />
                    </div>

                    <div class="add_sub_project_field mt20">
                        <div class="add-project__info">
                            {{ $t('project-reporting-currency-info-1') }}
                        </div>
                        <div class="add-project__info">
                            {{ $t('project-reporting-currency-info-2') }}
                        </div>
                    </div>
                </div>
                <div class="add_sub_project_footer">
                    <div class="button_item">
                        <kw-button
                            :button-text="$t('cancel')"
                            button-type="text"
                            :disabled="createSubProjectLoading"
                            data-cy="cancel-sub-project"
                            @click="handleCancelClick"
                        />
                    </div>
                    <div class="button_item">
                        <kw-button
                            :button-text="$t('add-group')"
                            button-type="primary"
                            type="submit"
                            :loading="createSubProjectLoading"
                            data-cy="confirm-add-sub-project-button"
                            @click.prevent="handleCreateSubProject"
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ValidationMixin from '@/mixins/ValidationMixin';
export default {
    name: 'AddSubProject',
    mixins: [ValidationMixin],
    props: {
        getProjectForChildAction: {
            type: Object,
            required: true,
        },
        toggleAddSubProject: {
            type: Function,
            required: true,
        },
        createSubProject: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            subProject: '',
            visibilityClass: '',
            currencyCode: null,
            subProjectErr: {},
            createSubProjectLoading: false,
        };
    },
    computed: {
        ...mapGetters([
            'getCurrencies',
            'getDisplaySetting',
            'getCurrencyByCode',
        ]),
        getGroupNameError() {
            return {
                error: this.subProjectErr.text,
            };
        },
        getCurrency() {
            let code = this.getDisplaySetting('currency_code');

            return this.getCurrencyByCode(code)?.label;
        },
    },
    methods: {
        inputHandler(value) {
            this.subProject = value;
            this.subProjectErr = this.projectNameValidator(
                value,
                this.getProjectForChildAction.name,
                'new',
            );
        },
        handleCurrencyChange(value) {
            this.currencyCode = value;
            this.visibilityClass = value ? 'selected' : '';
        },
        handleCancelClick() {
            this.toggleAddSubProject();
        },
        async handleCreateSubProject() {
            this.subProject = this.subProject.trim();
            this.subProjectErr = this.projectNameValidator(
                this.subProject,
                this.getProjectForChildAction.name,
                'new',
            );
            this.createSubProjectLoading = true;
            if (this.subProjectErr.text) {
                this.$toastr.e(this.subProjectErr.text);
                this.createSubProjectLoading = false;
                return;
            }

            const subProject = {
                categoryName: this.subProject,
                currency_code: this.currencyCode,
                selectedParentCategory: this.getProjectForChildAction.name,
            };
            try {
                await this.createSubProject(subProject);
                this.toggleAddSubProject();
            } catch (error) {
                console.log(error, 'error');
            }
            this.createSubProjectLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/partials/dropdowns';
.project_add_sub_project_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 20px;

    .add_sub_project_container {
        flex: 1;
        display: flex;
        flex-direction: column;

        .title_block {
            padding-bottom: 40px;

            .title {
                font-weight: 500;
                font-size: 22px;
                line-height: 26px;
            }

            .parent_project_name {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: #828f9c;
                padding-top: 5px;
            }
        }

        .add_sub_project_form {
            flex: 1;
            display: flex;
            flex-direction: column;

            .add_sub_project_content {
                flex: 1;

                .add_sub_project_field {
                    &.mt20 {
                        margin-top: 20px;
                    }

                    .add-project__info {
                        color: #818f9c;
                        font-family: Roboto;
                        font-size: 11px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: normal;

                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }
                    }
                    .input_label {
                        color: kw-color(kw-black, 1);
                        font-size: 12px;
                        padding-bottom: 5px;
                        display: block;
                        font-weight: 500;
                    }

                    .input {
                        width: 100%;
                        padding: 7px;
                        border: 1px solid #dee5ec;
                        border-radius: 4px;

                        &.text-input_invalid {
                            border-color: kw-color(kw-red, 1);
                            color: kw-color(kw-red, 1);
                        }
                    }

                    /deep/ .open .form-control.dropdown-toggle {
                        @extend .custom-dropdown_active;
                    }

                    /deep/ .form-control.dropdown-toggle {
                        @extend .custom-dropdown;
                        width: 280px;
                    }

                    .custom-dropdown_filled {
                        /deep/ .form-control.dropdown-toggle {
                            color: kw-color(kw-black, 1);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    /deep/ .dropdown-menu {
                        @extend .custom-dropdown-menu;
                        @extend .custom-scroll;
                        width: 280px;

                        .glyphicon.glyphicon-ok.check-mark:before {
                            content: '';
                        }
                    }
                    /deep/ .selected {
                        &.btn-group {
                            .form-control {
                                &.dropdown-toggle {
                                    color: kw-color(kw-black, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .add_sub_project_footer {
        display: flex;
        justify-content: center;

        .button_item {
            flex: 1;
            padding: 0 20px;

            button {
                width: 100%;
            }

            &:first-child {
                padding-right: 5px;

                button {
                    &:hover {
                        border-color: #265596;
                    }
                }
            }

            &:last-child {
                padding-left: 5px;
            }
        }
    }
}
</style>
