import VueRouter from 'vue-router';
import guards from './guards';
import hooks from './hooks';

const alerts = () => import('pages/alerts/Alerts');
const AppHeader = () => import('components/app-header/AppHeader');
const contactUs = () => import('pages/contact-us/ContactUs');
const googleAnalytics = () => import('pages/google-analytics/GoogleAnalytics');
const googleSearchConsole = () => import('pages/google-search-console/GoogleSearchConsole');
const googleLookerStudio = () => import('pages/google-looker-studio/GoogleLookerStudio');
const apiAccess = () => import('pages/api-access/ApiAccess');
const mainBlock = () => import('pages/project/ProjectPage');
const Policies = () => import('pages/static-pages/PrivacyPolicies');
const Reports = () => import('pages/reports/Reports');
const WhitelabelDomains = () => import('pages/whitelabel-domains/WhitelabelDomains');
const Pricing = () => import('pages/subscription/Pricing');
const AccountDetails = () => import('pages/billing/account-details/AccountDetails.vue');
const ImportData = () => import('pages/import-data/ImportData')
const Tutorial = () => import('pages/tutorial')

import notFound from './modules/not-found';
import projects from './modules/projects';
import settings from './modules/settings';
import users from './modules/users';
import deprecated from './modules/deprecated';
import share from './modules/share';

const baseRoutes = [
    {
        name: 'root',
        path: '/',
        meta: { requiresSubscription: true },
        beforeEnter: guards('root'),
        components: {
            AppHeader,
            mainBlock: mainBlock,
        },
    },
    {
        path: '/packages',
        name: 'Pricing',
        components: {
            AppHeader,
            Pricing,
        },
    },
    {
        path: '/privacy',
        name: 'privacy',
        components: {
            privacy: Policies,
        },
    },
    {
        path: '/integrations/google-analytics',
        name: 'googleAnalytics',
        meta: { requiresSubscription: true },
        components: {
            AppHeader,
            googleAnalytics,
        },
    },
    {
        path: '/integrations/google-search-console',
        name: 'googleSearchConsole',
        meta: { requiresSubscription: true },
        components: {
            AppHeader,
            googleSearchConsole,
        },
    },
    {
        path: '/integrations/google-looker-studio',
        name: 'googleLookerStudio',
        meta: { requiresSubscription: true },
        components: {
            AppHeader,
            googleLookerStudio,
        },
    },
    {
        path: '/api-access',
        name: 'apiAccess',
        meta: { requiresSubscription: true },
        components: {
            AppHeader,
            apiAccess,
        },
    },
    {
        path: '/reports',
        name: 'Reports',
        meta: { requiresSubscription: true },
        components: {
            Reports,
            AppHeader,
        },
        beforeEnter: guards('notViewOnly'),
    },
    {
        path: '/whitelabel-domains',
        name: 'WhitelabelDomains',
        meta: { requiresSubscription: true },
        components: {
            WhitelabelDomains,
            AppHeader,
        },
        beforeEnter: guards('notViewOnly'),
    },
    {
        path: '/alerts',
        name: 'alerts',
        meta: { requiresSubscription: true },
        components: {
            alerts,
            AppHeader,
        },
    },
    {
        path: '/archived',
        name: 'archived',
        meta: { requiresSubscription: true },
        components: {
            AppHeader,
            mainBlock: mainBlock,
        },
    },
    {
        path: '/search',
        name: 'searchResults',
        meta: { requiresSubscription: true },
        components: {
            AppHeader,
            mainBlock: mainBlock,
        },
    },
    {
        path: '/contact',
        name: 'contactUs',
        components: {
            contactUs,
            AppHeader,
        },
    },
    {
        path: '/account-details',
        name: 'AccountDetails',
        components: { AppHeader, AccountDetails },
    },
    {
        path: '/import-data',
        name: 'ImportData',
        meta: { requiresSubscription: true },
        components: {
            AppHeader,
            ImportData,
        },
    },
    {
        path: '/tutorial',
        name: 'Tutorial',
        components: {
            AppHeader,
            Tutorial,
        },
        beforeEnter: guards('tutorial'),
    },
];

const routes = baseRoutes.concat(deprecated, users, projects, share, settings, notFound);
const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach(guards('global'));
router.afterEach(hooks('global'));
router.afterEach(hooks('keywordList'));

export default router;
