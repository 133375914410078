export default (i18n, toastr) => {
    return res => {
        const {config} = res;
        
        if (config.method === 'post' && config.url.includes('/notes')) {
            toastr.s(i18n.t('add-note-msg'));
        }

        if (config.method === 'delete' && config.url.includes('/notes/')) {
            toastr.s(i18n.t('note-delete-ok'));
        }

        if (config.method === 'put' && config.url.includes('/notes/')) {
            toastr.s(i18n.t('note-saved-ok'));
        }

        return res;
    };
};