import i18n from '@/i18n';
import apiFactory from '@/api';
import toastr from '@/helpers/init-toastr';
import { Events, EventBus } from '@/events';
import { cookieGetters } from '@/helpers/cookieManager';
import {ADDON_ACCESS} from '@/configs/accessConfig.json';
import {SET_ADDON_USERS, SET_USER_DATA_PROP} from '@/store/mutations';
const addonsApi = apiFactory.get('addons');

const addons = {
    state: {
        addonUsers: [],
    },
    mutations: {
        [SET_ADDON_USERS]: (state, users) => state.addonUsers = users,
    },
    getters: {
        getAddonUsers: state => state.addonUsers,
    },
    actions: {
        async recieveAddonUsers ({commit}) {
            commit(SET_ADDON_USERS, []);

            try {
                const res = await addonsApi.fetch();

                const data = res.data.data.map(item => ({
                    access: item.attributes.access,
                    email: item.id,
                    projects: item.attributes.categories,
                    id: item.id,
                    password: '',
                }));

                if (!data.length) {
                    data.push({access: ADDON_ACCESS, email: '', projects: [], password: ''});
                }

                commit(SET_ADDON_USERS, data);
            } catch (error) {
                //
            }
        },
        async addAddonUser ({getters, commit}, user) {
            try {
                const res = await addonsApi.add(user);
                const {id} = res.data.data;
                const users = getters.getAddonUsers;
                users.push({...user, id});
                commit(SET_ADDON_USERS, users);
            } catch (error) {
                //
            }
        },
        async deleteAddonUser ({getters, commit}, user) {
            try {
                await addonsApi.delete(user.id);
                const users = getters.getAddonUsers.filter(storedUser => storedUser.id != user.id);
                commit(SET_ADDON_USERS, users);
            } catch (error) {
                //
            }
        },
        async updateAddonUsers ({getters, commit}, user) {
            try {
                await addonsApi.update(user.id, user);
                const users = getters.getAddonUsers.map(item => {
                    if (item.id === user.id) {
                        item = user;
                    }
                    return item;
                });

                commit(SET_ADDON_USERS, users);
            } catch (error) {
                //
            }
        },
        async saveAddonUsers ({commit}, payload) {
            const fd = new FormData();
            // transform the master access variable from payload from bool to string
            let masterAccess = 'enabled';
            if (payload.masterDisabled) {
                masterAccess = 'disabled';
            }
            fd.append('masteraccess', masterAccess);

            payload.users.forEach(user => {
                fd.append('addonemail[]', user.email);
                fd.append('addonpassword[]', user.password);
                fd.append('categories[]', user.projects.join(';'));
                fd.append('access[]', user.access);
            });

            const queryParameters = {
                params: {
                    action: 'updateaddonUser',
                    auth: cookieGetters.getApiKey(),
                }
            };

            try {
                const res = await axios.post('', fd, queryParameters);
                EventBus.emit(Events.CREATE_ADD_ON_USER, {users: payload.users});

                if (res.data.response === 'error') {
                    return toastr.e(res.data.message);
                }

                commit(SET_ADDON_USERS, payload.users.map(user => ({
                    access: user.access,
                    email: user.email,
                    projects: user.projects,
                    id: user.email,
                    password: user.password,
                })));

                // Update the master user disabled property
                commit(SET_USER_DATA_PROP, { prop: 'disabled', value: payload.masterDisabled });

                // update the addonMasterDisabled property if addon user
                commit(SET_USER_DATA_PROP, {
                    prop: 'addonMasterDisabled',
                    value: payload.masterDisabled,
                });

                !payload.masterDisabled && toastr.s(i18n.t('addon-users-updated'));
            } catch (error) {
                toastr.e('Error during Addon Users update');
            }
        },
    },
};

export default addons;
