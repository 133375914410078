import {
    DEFAULT_LANGUAGE,
    MIXPANEL_EVENT_CONSTANTS,
} from '@/constants';
import { mapActions } from 'vuex';
import { Events, EventBus } from '@/events';

export default {
    computed: {
        getDraftsQuantity() {
            const { newKeyword } = this;
            const quantity = Object.keys(newKeyword).reduce((acc, cur) => {
                switch (cur) {
                    case 'url':
                    case 'tags':
                        return newKeyword[cur].length ? acc = 1 : acc;

                    case 'domain':
                        return newKeyword[cur] ? acc = 1 : acc;

                    case 'region':
                        return (newKeyword[cur].value !== 'google.com' && newKeyword[cur].value !== 'youtube.com') ? acc = 1 : acc;

                    case 'language':
                        return newKeyword[cur].value !== DEFAULT_LANGUAGE ? acc = 1 : acc;

                    case 'platform':
                        if (['yt', 'maps'].includes(this.keywordType)) {
                            return acc;
                        }

                        return newKeyword[cur] !== 'desktop' ? acc = 1 : acc;

                    case 'geolocation':
                        if (this.keywordType === 'yt') {
                            return acc;
                        }

                        return newKeyword[cur].location.some(l => l.length) || newKeyword[cur].GMB.length
                            ? acc = newKeyword[cur].location.filter(l => l.length).length || 1
                            : acc;
                }

                return acc;
            }, 0);

            const keywordsQuantity = newKeyword.keywords.filter(el => {
                if (el && el.hasOwnProperty('value')) {
                    return el.value;
                }
                return el;
            }).length;

            let draftsQuantity = keywordsQuantity || quantity;

            if (newKeyword.platform && newKeyword.platform === 'desktop-mobile') {
                draftsQuantity = draftsQuantity * 2;
            }

            if (newKeyword.geolocation.location.some(l => l.length)) {
                draftsQuantity = newKeyword.geolocation.location.filter(el => el.length).length * draftsQuantity;
            }

            return draftsQuantity;
        },
    },
    watch: {
        'newKeyword.keywords'(val) {
            this.setSelectedSuggestions(val);
        },
    },
    beforeDestroy() {
        if (['web', 'map'].includes(this.keywordType)) {
            this.removeEmptyLocations();
        }

        if (typeof this.isKeywordAdded === 'undefined') return;

        if (!this.isKeywordAdded) {
            this.setKeywordDraft({
                payload: this.newKeyword,
                type: this.keywordType,
            });

            this.setDraftsQuantity({ payload: this.getDraftsQuantity, type: this.keywordType });
        } else {
            this.setDraftsQuantity({ payload: 0, type: this.keywordType });
        }
    },
    methods: {
        ...mapActions([
            'setSelectedSuggestions',
            'setKeywordDraft',
            'setDraftsQuantity',
        ]),
        googleRegions(suggestedRegions, regions) {
            const suggested = _.uniq(suggestedRegions(this.apiKeywordTypes).map(el => el.region));
            return [
                ...suggested.map(el => regions.find(elem => elem.value === el)).filter(el => !!el),
                ...regions.filter(el => !suggested.includes(el.value)),
            ];
        },
        googleLanguages(suggestedRegions, languages) {
            const suggested = _.uniq(suggestedRegions(this.apiKeywordTypes).map(el => el.lang));
            return [
                ...suggested.map(el => languages.find(elem => elem.value.indexOf(`${el} `) === 0)).filter(el => !!el),
                ...languages.filter(el => suggested.every(elem => el.value.indexOf(`${elem} `) !== 0)),
            ];
        },
        getRegionDomain(url) {
            const str = url.replace(/^(?:(http[s]?|ftp):\/{2})?(?:www\.)?/, '');
            return str.split('/')[0].split('.').slice(1).join('.');
        },
        addLocation() {
            this.newKeyword.geolocation.location.push('');
        },
        removeLocation(i) {
            const location = _.cloneDeep(this.newKeyword.geolocation.location);
            location.splice(i, 1);
            this.newKeyword.geolocation.location = location;
        },
        setLocation(i, place) {
            this.newKeyword.geolocation.location[i] = place;

            if (this.err.location) {
                this.err.location = this.newKeyword.geolocation.location.every(el => !el)
                    ? { text: this.$t('location-should-be-valid'), type: 'error' }
                    : {};
            }
        },
        removeEmptyLocations() {
            this.newKeyword.geolocation.location = this.newKeyword.geolocation.location.filter(l => l.length);

            if (!this.newKeyword.geolocation.location.length) {
                this.addLocation();
            }
        },
        addAllSuggestedIntoKeywords(suggested, leading_source) {
            const concatedArray = this.newKeyword.keywords.concat(suggested);
            const uniq = _.uniq(concatedArray);
            if (concatedArray.length !== uniq.length) {
                this.$toastr.i(this.$t('suggestions-duplicates'));
            }

            let keyword_type = MIXPANEL_EVENT_CONSTANTS.KEYWORD_TYPE[this.keywordType]

            if (leading_source === MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.ONBOARDING) {
                keyword_type = MIXPANEL_EVENT_CONSTANTS.KEYWORD_TYPE['web'];
            }

            this.newKeyword.keywords = uniq;
            this.textarea = this.newKeyword.keywords.join('\n');

            EventBus.emit(Events.KEYWORDS_SUGGESTED_ADDED_ALL_EVENT, {
                num_keywords: this.newKeyword.keywords.length,
                keyword_type: keyword_type,
                leading_source: leading_source,
            });
        },
        toggleKeyword(keyword, leading_source) {
            const { keywords } = this.newKeyword;
            this.newKeyword.keywords = keywords.includes(keyword)
                ? keywords.filter(el => el !== keyword)
                : [...keywords, keyword];

            this.textarea = this.newKeyword.keywords.join('\n');

            let keyword_type = MIXPANEL_EVENT_CONSTANTS.KEYWORD_TYPE[this.keywordType]

            if (leading_source === MIXPANEL_EVENT_CONSTANTS.LEADING_SOURCE.ONBOARDING) {
                keyword_type = MIXPANEL_EVENT_CONSTANTS.KEYWORD_TYPE['web'];
            }

            EventBus.emit(Events.KEYWORDS_SUGGESTED_ADDED_EVENT, {
                num_keywords: this.newKeyword.keywords.length,
                keyword_type: keyword_type,
                leading_source: leading_source,
            });
        },
    },
};