export const options = [
    {
        label: 'I had some technical issues',
        icon: 'kw-cf-1',
        type: 'option',
        title: 'Have you reached to support for help?',
        hasSupport: true,
        subQuestions: [
            {
                label: 'Yes, but I’m still experiencing issues',
                icon: 'kw-cf-1',
                type: 'textarea',
                title: 'I had some technical issues',
                desc: 'Please tell us about your technical issues'
            },
            {
                label: 'No I haven’t',
                icon: 'kw-cf-6',
                type: 'textarea',
                title: 'I had some technical issues',
                desc: 'Please tell us about your technical issues'
            }
        ]
    },
    {
        label: 'It’s too expensive',
        icon: 'kw-cf-2',
        type: 'textarea',
        title: 'It’s too expensive',
        desc: 'How much did you expect to pay?',
    },
    {
        label: 'Switching to another product',
        icon: 'kw-cf-3',
        type: 'option',
        title: 'Switching to another product',
        desc: 'What product are you switching to?',
        subQuestions: [
            {
                label: 'Ahrefs',
                type: null,
            },
            {
                label: 'Semrush',
                type: null,
            },
            {
                label: 'SE Ranking',
                type: null,
            },
            {
                label: 'Other',
                type: 'textarea',
                title: 'Switching to another product',
                desc: 'What product are you switching to?'
            },
        ]
    },
    {
        label: 'Missing the features I need',
        icon: 'kw-cf-4',
        type: 'textarea',
        title: 'Missing the features I need',
        desc: 'What features were missing?',
    },
    {
        label: 'Shutting down the company',
        icon: 'kw-cf-5',
        type: null,
    },
    {
        label: 'Not sure how to the use the app',
        icon: 'kw-cf-6',
        type: 'option',
        title: 'Have you tried using our <a class="link" href="https://support.keyword.com">Help Center</a>?',
        hasSupport: true,
        subQuestions: [
            {
                label: 'Yes, but it hasn’t helped me',
                icon: 'kw-cf-1',
                type: null,
            },
            {
                label: 'No I haven’t',
                icon: 'kw-cf-6',
                type: null,
            }
        ]
    },
    {
        label: 'Other',
        icon: 'kw-cf-7',
        type: 'textarea',
        title: 'Other',
        desc: 'What made you decide to leave us?',
    },
];
