<template>
    <div>
        <span :class="getClasses" />
        <span class="period-value" v-html="getValue" />
    </div>
</template>

<script>
export default {
    name: 'PeriodRenderer',
    computed: {
        getClasses() {
            return {
                'icon-arrow_4': this.params.data.trends[
                    this.params.column.colId
                ],
                'icon-rotate-down':
                    this.params.data.trends[this.params.column.colId] < 0,
            };
        },
        getValue() {
            return this.params.data.start === null
                ? `<span data-important-tooltip="${this.$t(
                      'data-not-available',
                  )}" class="empty-value">??</span>`
                : this.params.data.trends[this.params.column.colId] === 0
                ? `<span data-important-tooltip="${this.$t(
                      'not-changed-data',
                  )}" class="empty-value">&mdash;</span>`
                : Math.abs(this.params.data.trends[this.params.column.colId]);
        },
    },
};
</script>

<style lang="scss" scoped>
span.icon-arrow_4 {
    font-size: 10px;
    line-height: 16px;
    margin-right: 5px;
    width: 9px;
}

span.icon-arrow_4.icon-rotate-down {
    color: kw-color(kw-red, 1);
}

span.icon-arrow_4:not(.icon-rotate-down) {
    color: kw-color(kw-green, 1);
}
</style>
