<template>
    <div
        :id="watcherId"
        class="pdf-project only-pdf"
    >
        <share-header v-if="isHeaderVisiblePdfSettings" />
        <div class="pdf-project-container">
            <div class="share-data__wrapper">
                <share-data
                    :is-company-data-displayed="isCompanyDataDisplayed"
                    :project="getCurrentProject"
                />
            </div>
            <div class="project-overview__metrics-container">
                <metrics
                    v-if="getDisplaySetting('metrics') !== 'hide'"
                    :data="getProjectWithOverviewChartsData"
                    :loading="getMetricsAndChartIsFetching"
                    type="chart"
                />
            </div>
        </div>
        <div
            v-if="isGaChartVisible"
            class="chart_section chart_section_ga"
        >
            <google-analytics-chart
                :is-animation-active="false"
            />
        </div>
        <div
            v-if="itemsWithProcessedFields.length"
            class="pdf-project-table"
        >
            <pdf-table
                :items="itemsWithProcessedFields"
            />
        </div>
        <div
            v-if="getPdfChartsData.length && isHistoricalGraphVisible"
            class="chart_section"
        >
            <div
                v-for="(item, index) in getPdfChartsData"
                :key="`item_${index}`"
                class="chart_block"
            >
                <pdf-chart
                    :series="item"
                    :item-index="index"
                />
            </div>
        </div>
        <span
            v-if="itemsWithProcessedFields.length > MAX_KEYWORDS_COUNT_TO_FETCH_CHARTS && isHistoricalGraphVisible"
            class="historical-charts-info"
        >
            {{ $t('contact-us-for-view-key-link', { count: MAX_KEYWORDS_COUNT_TO_FETCH_CHARTS }) }}
        </span>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';

    import PdfTable from './components/PdfTable';
    import ShareData from './components/ShareData';
    import Metrics from '@/components/metrics/Metrics';
    import ShareHeader from 'components/share-viewkey/ShareHeader';
    import pdfChart from '@/components/main-table/pdfTable/PdfKeywordChart';
    const  GoogleAnalyticsChart = () => import('../../components/main-block/components/GoogleAnalyticsChart');

    import { MAX_KEYWORDS_COUNT_TO_FETCH_CHARTS } from '@/constants';

    export default {
        name: 'PdfView',
        components: {
            'share-data': ShareData,
            'share-header': ShareHeader,
            'metrics': Metrics,
            'pdf-chart': pdfChart,
            'pdf-table': PdfTable,
            'google-analytics-chart': GoogleAnalyticsChart
        },
        mixins: [ToolTipRenderMixin],
        props: {
            viewkey: {
                type: String,
                required: false,
                default: '',
            },
            auth: {
                type: String,
                required: false,
                default: '',
            },
        },

        data () {
            return {
                interval: null,
                tableDataCheckerInterval: null,
                MAX_KEYWORDS_COUNT_TO_FETCH_CHARTS,
                watcherId: '',
                staticStyles: {},
            };
        },
        computed: {
            ...mapGetters([
                'getCurrentProject',
                'getMetricsAndChartIsFetching',
                'getProjectWithOverviewChartsData',
                'getOriginalItems',
                'getKeywordsIsFetching',
                'getSortedAndFilteredKeywords',
                'getPdfChartsData',
                'getDisplaySetting',
                'getAnalyticsChartData',
                'getGaChartDataIsFetching',
                'getPdfChartsDataIsFetching',
                'getAnalyticsChartData'
            ]),
            isCompanyDataDisplayed () {
                return !!this.getCurrentProject && this.getProjectSetting('companyInformationPdfSettings') === undefined;
            },
            itemsWithProcessedFields () {
                const processedItems = this.getSortedAndFilteredKeywords(this.getOriginalItems);
                return processedItems;
            },
            isHeaderVisiblePdfSettings () {
                const foundedOption = this.getCurrentProject && this.getProjectSetting('hideVerifiedPdfSettings');
                return !!foundedOption
            },
            isHistoricalGraphVisible () {
                return !(this.getCurrentProject && this.getProjectSetting('generateHistoricalGraphPdf'));
            },
            isGaChartVisible() {
                return this.getCurrentProject && !this.getProjectSetting('googleAnalyticsCharts');
            }
        },
        watch: {
            async getCurrentProject (newValue, oldValue) {
                if(!oldValue && newValue) {
                    const params = {
                        auth: this.auth,
                        viewkey: this.viewkey,
                        is_pdf: true,
                        ...this.$route.query
                    }

                    this.fetchMetricsForPdf(params);
                    await this.fetchKeywordForPdf({ ...params,  per_page: 999999 });
                }
            },
            getPdfChartsDataIsFetching (val) {
                if (!val && !this.getGaChartDataIsFetching) {
                    this.setWatcherId();
                }
            },
            getGaChartDataIsFetching (val) {
                if (val || this.getKeywordsIsFetching || this.getPdfChartsDataIsFetching ) return;
                if (!this.getPdfChartsDataIsFetching) {
                    this.setWatcherId();
                }
            },
            getKeywordsIsFetching (val) {
                if (val) return;
                if (this.isHistoricalGraphVisible && this.itemsWithProcessedFields.length < MAX_KEYWORDS_COUNT_TO_FETCH_CHARTS) {
                    const keywordIds = this.itemsWithProcessedFields.map((element) => element.id);
                    this.fetchPdfCharts();
                    return;
                }
                if (!this.getGaChartDataIsFetching) {
                    this.setWatcherId();
                }
            },
            itemsWithProcessedFields (keywords) {
                if(keywords && keywords.length) {
                    this.$nextTick(() => {
                        this.tableDataCheckerInterval = setInterval(() => {
                            if(document.querySelectorAll('#virtual-static .table-row__inner').length === keywords.length) {
                                this.addStyles();
                                clearInterval(this.tableDataCheckerInterval);
                            }
                        }, 300)
                    })
                }
            },
        },
        created() {
            window.prerendercloudReady = false;
            this.setIsViewkeyView(true);
            const {
                start_date = '',
                end_date = ''
            } = this.$route.query;
            const params = {
                shortkey: this.viewkey,
                id: this.auth,
                is_pdf: true,
                start_date,
                end_date,
            }

            this.getViewkeyInfo(params);

        },
        mounted () {
            const self = this;
            self.interval = setInterval(() => {
                const totalCount = self.getOriginalItems.length;
                let tableRowCount = 0;
                if( document.getElementsByClassName('keywords-table_static')[0]) {
                    tableRowCount = document.getElementsByClassName('keywords-table_static')[0].getElementsByClassName('table-row').length
                }
                if(totalCount && +totalCount === +tableRowCount) {
                    window.prerendercloudReady = true;
                    clearInterval(self.interval);
                }

            }, 500)
        },
        beforeDestroy () {
            clearInterval(self.interval);
            clearInterval(this.tableDataCheckerInterval);
        },
        methods: {
            ...mapActions([
                'fetchMetricsForPdf',
                'getViewkeyInfo',
                'setIsViewkeyView',
                'fetchKeywordForPdf',
                'fetchPdfCharts'
            ]),
            addStyles() {
              const rowList = Array.from(document.querySelectorAll('#virtual-dynamic .table-row__inner'));
              rowList.forEach((item, index) => {
                    item.style['height'] = rowList[index].offsetHeight + 'px';
              });
            },
            setWatcherId() {
                // used setTimeut with nextTik to set watcherId after finialy render
                this.$nextTick(() => setTimeout(() => this.watcherId = 'pdfWatcher'));
            },
            getProjectSetting(setting) {
                return this.getCurrentProject.settings.columns.find((item) => item === setting);
            }
        },
    }
</script>

<style lang="scss">
  .pdf-project {
    &.only-pdf {
      background: kw-color(kw-white, 1);
      .share-header {
        height: 42px;
        font-size: 14px;
        line-height: 16px;
        width: 100%;
        margin: 0 0 !important;
        background-color: #EAF0FE !important;


        &__logo {
          margin: 10px 14px 0 14px;
        }
          .share__learn-more {
              color: kw-color(kw-blue, 1) !important;
              font-size: 13px;
              line-height: 15px;
              text-decoration: none !important;

              .kw-chevron-right {
                  font-size: 9px;
                  font-weight: bold;
              }
          }
      }

      .pdf-project-container {
        padding: 0 30px;
      }

      .share-data__wrapper {
        max-width: 100%;
        padding-top: 24px;

        .share-data {
          display: flex;
          flex-direction: column;
          margin: 0 0 20px 0;

            .share-data__company-name {
                font-weight: bold;
                line-height: 40px;
                margin: 0 0 10px 0;
            }

          &__link {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .refresh {
              padding: 0;
              margin: 0 0 16px 0;

              .refresh__header {
                .refresh__title-wrapper {
                  margin-bottom: 5px;

                  .title-name {
                    font-size: 21px;
                    line-height: 25px;
                  }
                }

                .title-favicons {
                  height: 24px;
                  margin-right: 8px;

                  img {
                    width: 24px;
                  }
                }

                .sub_title{
                  font-size: 14px;
                  line-height: 16px;
                  font-weight: 500;
                  color: kw-color(kw-grey, 1);
                }
              }
            }

            .share-data__logo-link {
              display: flex;
              width: 200px;
              max-height: 60px;
              margin-right: 0;

              .share-data__logo-image {
                height: 100%;
                margin: 0 0 0 auto;
                width: auto;
                max-width: 100%;
              }
            }
          }

          &__info {
            width: 100%;
            display: flex;
            justify-content: flex-start;

            .share-data__description {
              max-width: 768px;
              color: #909399;
              font-size: 13px;
              line-height: 16px;
            }
          }
        }
      }

      .chart_section_ga {
          .ga-chart-wrapper {
              margin-top: 25px;
              padding: 0;
          }
      }

      .chart_section {
        padding: 0 30px;
        margin-top: 60px;

        .chart_block {
          margin: 0 0 60px 0;

          .pdf_title_block {
            text-align: center;
            margin: 0 0 15px 0;
          }

          .pdf-keyword-chart {
            div {
              &:nth-child(2) {
                width: 1516px !important;
                div {
                  width: 100% !important;
                }
              }
            }
          }

          .chart-periods {
            justify-content: flex-end;
            top: 0;

            .view-title {
              color: #2d2d2d;
              font-weight: 500;
            }

            .period-item {
              color: #8bb5d6;
              font-weight: 500;

              &:after {
                content: '\22C5';
                position: relative;
                left: -2px;
              }

              &.selected-period {
                color: #3970af;
                font-weight: 500;
              }
            }
          }

          .highcharts-legend {
            margin: 0 auto;
          }
        }
      }

        .historical-charts-info {
            display: flex;
            flex: 1;
            justify-content: center;
            margin-bottom: 29px;
            margin-top: 50px;
            color: kw-color(kw-blue, 6);
        }

      #virtual-static {
        .table-row {
          &:first-child {
            .table-row__title {
              border-top: none;
            }
          }
        }
      }
    }

    .pdf-project-table {
        padding: 0 30px;
    }
  }

  main.app.container-fluid.app_pdf {
    max-width: 100%;
    padding: 0 0;
  }

  .main-page_pdf-view.main-page {
    margin-right: 0;
    padding: 0;

    .share-data {
      flex-wrap: nowrap;
      flex-flow: revert;
      justify-content: space-between;
      margin: 25px 0;
      align-items: center;
    }

    .keywords-table_static .table-row_fav .table-row__inner {
      span.kw-favorite {
        left: 2px;
      }
    }

    .chart-header-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      font-size: 9px;
      margin-bottom: -35px;
      margin-top: 20px;
      position: relative;
      z-index: 99;

      .chart-header-item {
        display: flex;
        align-items: center;

        .color-box {
          width: 10px;
          height: 10px;
          border-radius: 3px;
          display: inline-block;
          margin-left: 5px;
          margin-right: 2px;
        }
      }

      .text-box {
        &:first-child {
          font-weight: 900;
        }
      }
    }

    .table-row {
      .row-wrap {
        @media (max-width: 959px) {
          font-size: 10px;
        }
      }
    }

    .ranking-vs-distribution-header {
      display: flex;
      justify-content: space-between;

      .timeframe-over-text {
        color: #909399;
      }
    }

    .project-overview__metrics-container {
      margin-bottom: 12px;

      .dashboard-metrics-wrap {
        .dashbord-metrics {
          padding: 0 0;

          .dashboard {
            flex-wrap: nowrap;

            .dashboard-block {
              margin: 0 10px 0 0;

              .dash-total {
                height: 86px;

                .count {
                  &.decrease,
                  &.increase {
                    .total-keyword-count {
                      font-size: 16px;
                    }
                  }
                }

                .dash-title {
                  font-size: 16px;
                  line-height: 20px;

                  span {
                    font-size: 16px;
                    line-height: 20px;
                  }
                }
              }

              .change-direction {
                height: 30px;

                .total-up {
                  font-size: 14px;
                }
              }

              &:last-child {
                margin: 0 0 0 0;
              }
            }
          }
        }
      }
    }

    .show_more_keywords_link {
      text-align: center;
      display: block;
      margin: 35px;
      font-size: 20px;

      i {
        font-size: 15px;
      }
    }

  }

  .page
  {
    -webkit-transform: rotate(-90deg);
    -moz-transform:rotate(-90deg);
    filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }
  @media print{@page {size: landscape}}

  .only-pdf {
      .chart_section {
          &_ga {
              margin-top: 0 !important;
          }

          .ga-chart {
              div {
                  width: 1500px !important;

                  div {
                      width: 100% !important;
                  }
              }
          }
      }
  }
</style>

