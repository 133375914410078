<template>
    <div class="placeholder__item placeholder__item_keywords placeholder_animate">
        <div
            v-for="i in 10"
            :key="i"
            class="placeholder__keywords"
        >
            <div
                v-for="j in 13"
                :key="j"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'KeywordsTablePlaceholder',
    }
</script>