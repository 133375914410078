<template>
    <div>
        <div v-if="!partialSelected" class="wrapper-add-keywords-info">
            <kw-button
                :button-text="$t('add-all-keywords')"
                :disabled="!suggestions.length"
                button-type="primary"
                class="add-all-keywords"
                @click="addAllKeywords"
            />
            <span
                class="kw kw-information suggested-information-all-keywords"
                @mouseover="
                    tooltip({
                        text: $t('all-suggested-keywords-information'),
                        event: $event,
                        maxWidth: 169,
                    })
                "
            >
            </span>
        </div>
        <div v-else class="keyword-suggestions-actions">
            <kw-button
                class="create-suggestions-button"
                prepend-icon-class="kw kw-plus-circle bold-icon"
                :data-important-tooltip="$t('add-selected-keywords')"
                :loading="
                    getKeywordAdding || getGSCKeywordsFromSuggestionsCreating
                "
                button-type="outline-primary"
                @click="openCreateModal"
            />
            <kw-button
                class="delete-suggestions-button"
                prepend-icon-class="kw kw-x-circle bold-icon"
                :data-important-tooltip="$t('remove-selected-keywords')"
                :loading="getGSCSuggestionsDeleting"
                button-type="outline-danger"
                @click="deleteKeywords"
            />
        </div>
        <div
            class="keyword-suggestions-table"
            :class="{ empty: !suggestions.length }"
        >
            <div class="header">
                <div
                    class="header__cell header__cell_checkbox"
                    :style="getColumnStyle({ width: 32 })"
                    :data-important-tooltip="$t('select-all')"
                    @click="selectAll"
                >
                    <div class="header__cell-inner">
                        <checkbox
                            :icon-class="getCheckBoxIconClass"
                            :checkbox="partialSelected"
                            @checkbox-click="selectAll"
                        />
                    </div>
                </div>
                <div
                    v-for="(field, i) in suggestionsFields"
                    :key="i"
                    :class="headerCellClass(field)"
                    :style="getColumnStyle(field)"
                    @click="GSCSorterChanged(field.sorter)"
                >
                    <div class="header__cell-inner" v-text="field.title" />
                    <span class="kw kw-sort" />
                </div>
            </div>
            <div v-if="!loading" :class="bodyClasses">
                <div
                    v-for="(suggestion, i) in suggestions"
                    :key="i"
                    class="body__row"
                    @click.exact="selectOnly(suggestion['kw'])"
                    @click.ctrl="select(suggestion['kw'])"
                >
                    <div
                        class="body__cell"
                        :style="getColumnStyle({ width: 32 })"
                        @click.stop="select(suggestion['kw'])"
                    >
                        <checkbox
                            :checkbox="isSelected(suggestion['kw'])"
                            @checkbox-click="select(suggestion['kw'])"
                        />
                    </div>
                    <div
                        v-for="(field, j) in suggestionsFields"
                        :key="j"
                        :class="bodyCellClass(field)"
                        :style="getColumnStyle(field)"
                    >
                        <div
                            class="kw-icon-gsc"
                            v-if="field.name === 'kw'"
                        ></div>
                        <span
                            class="body__cell-inner"
                            v-html="getValue(suggestion, field.name)"
                        />
                    </div>
                </div>
            </div>
            <div v-else :class="`placeholder placeholder_animate`">
                <div class="placeholder__item placeholder__item_suggestions">
                    <div
                        v-for="i in 7"
                        :key="i"
                        :class="`placeholder__suggestions placeholder__suggestions-keywords`"
                    >
                        <div v-for="j in 11" :key="j" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { suggestionsFields } from '../utils/fields';
import KeywordsTableMixin from '@/mixins/KeywordsTableMixin';
import GSCCreateKeywordsFromSuggestionsModal from './modals/GSCCreateKeywordsFromSuggestionsModal';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';

export default {
    name: 'KeywordSuggestionsTable',
    mixins: [KeywordsTableMixin, ToolTipRenderMixin],
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        suggestions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            suggestionsFields,
            selectedKeywords: [],
        };
    },
    computed: {
        ...mapGetters([
            'getActiveTag',
            'getKeywordAdding',
            'getGSCSuggestionsSort',
            'getGSCSuggestionsDeleting',
            'getGSCProfileCurrentConnection',
            'getGSCKeywordsFromSuggestionsCreating',
        ]),
        bodyClasses() {
            return {
                body: true,
                body_empty: !this.loading && !this.suggestions.length,
            };
        },
        allSelected() {
            return this.suggestions.length === this.selectedKeywords.length;
        },
        partialSelected() {
            return !!this.selectedKeywords.length;
        },
        getCheckBoxIconClass() {
            return this.allSelected ? 'kw kw-check' : 'kw kw-minus';
        },
    },
    methods: {
        ...mapActions([
            'GSCSorterChanged',
            'deleteGSCSuggestions',
            'setKeywordsFromSuggestionsCreating',
        ]),
        getValue(suggestion, field) {
            let empty = '<span style="color: #C1C4CD;">-</span>';
            let over100 = '<span style="color: #C1C4CD;">>100</span>';

            switch (field) {
                case 'impressions':
                    return suggestion[field]
                        ? new Intl.NumberFormat('us').format(suggestion[field])
                        : empty;
                case 'position':
                    return suggestion[field] && suggestion[field] <= 100
                        ? parseFloat(suggestion[field].toFixed(1))
                        : over100;
                case 'clicks':
                    return suggestion[field] ?? empty;
                case 'ctr':
                    let val = suggestion[field] * 100;
                    return suggestion[field]
                        ? (val % 1 ? val.toFixed(2) : val.toFixed(0)) + '%'
                        : empty;
                default:
                    return suggestion[field]
                        .toString()
                        .replace('<', '&lt;')
                        .replace('>', '&gt;');
            }
        },
        bodyCellClass(f) {
            return {
                body__cell: true,
                [`body__cell_${f.class}`]: true,
            };
        },
        headerCellClass(f) {
            const { field, direction } = this.getGSCSuggestionsSort;

            return {
                header__cell: true,
                [`header__cell_${f.class}`]: true,
                [direction]: f.sorter === field,
            };
        },
        isSelected(keyword) {
            return this.selectedKeywords.includes(keyword);
        },
        selectAll() {
            this.selectedKeywords = this.allSelected
                ? []
                : this.suggestions.map(a => a['kw']);
        },
        select(keyword) {
            if (this.isSelected(keyword)) {
                this.selectedKeywords = this.selectedKeywords.filter(
                    item => item !== keyword,
                );
            } else {
                this.selectedKeywords.push(keyword);
            }
        },
        selectOnly(keyword) {
            this.selectedKeywords =
                this.isSelected(keyword) && this.selectedKeywords.length === 1
                    ? []
                    : [keyword];
        },
        openCreateModal() {
            if (
                this.selectedKeywords &&
                this.getGSCProfileCurrentConnection?.id
            ) {
                this.$modal.show(
                    GSCCreateKeywordsFromSuggestionsModal,
                    {
                        keywords: this.selectedKeywords,
                        activeTag: this.getActiveTag,
                        connection: this.getGSCProfileCurrentConnection,
                    },
                    {
                        classes: 'v--modal',
                        height: 'auto',
                        name: 'GSCCreateKeywordsFromSuggestionsModal',
                        width: 420,
                    },
                    {
                        'before-close': this.clearSelection,
                    },
                );
            }
        },
        clearSelection() {
            this.selectedKeywords = [];
        },
        async deleteKeywords() {
            try {
                await this.deleteGSCSuggestions(this.selectedKeywords);
            } catch (error) {
                this.$toastr.e(error);
            } finally {
                this.clearSelection();
            }
        },
        addAllKeywords() {
            this.selectAll();
            this.openCreateModal();
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper-add-keywords-info {
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
    gap: 16px;

    .add-all-keywords {
        width: 120px;
        height: 36px;
    }

    .suggested-information-all-keywords {
        font-size: 20px !important;
        font-weight: 700 !important;
    }
}

.keyword-suggestions-actions {
    display: flex;

    .delete-suggestions-button {
        position: relative;
    }

    /deep/ .kw {
        font-size: 20px;

        &.bold-icon {
            font-weight: 600;
        }
    }
}

.keyword-suggestions-table {
    margin-top: 8px;
    border: 1px solid #e1e8ef;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .header {
        align-items: center;
        border-radius: 4px 4px 0 0;
        color: kw-color(kw-grey, 1);
        cursor: default;
        display: flex;
        flex-flow: row nowrap;
        font-size: 12px;
        font-weight: 500;
        line-height: 34px;
        text-transform: capitalize;
        user-select: none;
    }

    .header__cell {
        @import '~sass/cutting-name';
        align-items: center;
        background-color: #f8f9fa;
        border-bottom: 1px solid #e1e8ef;
        border-right: 1px solid #e1e8ef;
        cursor: pointer;
        display: flex;
        height: 34px;
        min-width: 74px;
        overflow: hidden;
        padding: 0 16px;
        position: relative;
        white-space: nowrap;

        &:last-child {
            border-right: none;
        }

        .kw-sort {
            display: none;
            font-size: 8px;
            margin-left: 0;
            transform: scaleX(0.75);
        }

        &.asc {
            .kw-sort {
                display: block;
                transform: scaleY(-1) scaleX(0.75);
            }
        }

        &.desc {
            .kw-sort {
                display: block;
            }
        }

        /deep/ .checkbox {
            top: 8px;
            left: 8px;
        }
    }

    .header__cell_project {
        min-width: 200px;
    }

    .header__cell_recipient {
        min-width: 120px;
    }

    .header__cell-inner {
        @import '~sass/cutting-name';
    }

    .body__row {
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;

        &:nth-child(odd) {
            .body__cell {
                background-color: kw-color(kw-white, 1);
            }
        }

        &:nth-child(even) {
            .body__cell {
                background-color: #f7f8fa;
            }
        }

        &:not(:hover) {
            /deep/ a {
                color: #828f9c;
            }
        }

        &:hover {
            .body__cell-actions {
                display: flex;
            }
        }
    }

    .body__cell {
        align-items: center;
        border-right: 1px solid #e1e8ef;
        color: #333;
        display: flex;
        flex-flow: row nowrap;
        font-size: 14px;
        height: 34px;
        line-height: 34px;
        min-width: 74px;
        padding: 0 16px;
        position: relative;

        &:last-child {
            border-right: none;
        }

        /deep/ a {
            color: kw-color(kw-blue, 1);
        }

        /deep/ .checkbox {
            top: 8px;
            left: 8px;
        }
    }

    .body__cell_recipient {
        min-width: 120px;
    }

    .body__cell_project {
        justify-content: space-between;
        min-width: 200px;
    }

    .body__cell_format {
        text-transform: uppercase;
    }

    .body__cell-actions {
        display: none;
        flex-flow: row nowrap;

        span {
            color: kw-color(kw-blue, 1);
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }

    .body__cell-inner {
        @import '~sass/cutting-name';
    }

    .body__cell_gsc-impressions,
    .body__cell_gsc-position,
    .body__cell_gsc-clicks,
    .body__cell_gsc-ctr,
    .header__cell_gsc-impressions,
    .header__cell_gsc-position,
    .header__cell_gsc-clicks,
    .header__cell_gsc-ctr {
        justify-content: flex-end;
    }
}
</style>
