import { apiCodes } from '@/constants';
import { initColumnsConfig } from 'components/main-table/configs/fields';
import apiFactory from '@/api';
import router from '@/routes';
import { SET_USER_DATA_PROP } from '@/store/mutations';
import updateDocumentTitle from '@/helpers/update-document-title';
const projectsApi = apiFactory.get('projects');

import {
    SET_CURRENT_GROUP_OBJECT,
    SET_DATA_FOR_VIEWKEY,
    SET_KEYWORDS_TABLE_SETTINGS,
    SET_VIEWKEY_ACCESS_STATUS,
    SET_VIEWKEY_HAS_CHILDREN,
    SET_VIEWKEY_VIEW,
    SET_HASH_FOR_SHARE_LOADING,
    SET_HASH_FOR_SHARE,
    SET_SHARE_VIEW_HASH_DATA,
} from '../mutations';

const viewkey = {
    state: {
        dataForViewkey: {},
        hasChildren: false,
        viewkeyAccessStatus: null,
        viewkeyView: false,
        hashForShareLoading: false,
        hashForShare: '',
        shareViewHashData: null,
    },
    mutations: {
        [SET_DATA_FOR_VIEWKEY]: (state, payload) => state.dataForViewkey = payload,
        [SET_VIEWKEY_ACCESS_STATUS]: (state, payload) => state.viewkeyAccessStatus = payload,
        [SET_VIEWKEY_HAS_CHILDREN]: (state, payload) => state.hasChildren = payload,
        [SET_VIEWKEY_VIEW]: (state, payload) => state.viewkeyView = payload,
        [SET_HASH_FOR_SHARE_LOADING]: (state, payload) => state.hashForShareLoading = payload,
        [SET_HASH_FOR_SHARE]: (state, payload) => state.hashForShare = payload,
        [SET_SHARE_VIEW_HASH_DATA]: (state, payload) => state.shareViewHashData = payload,
    },
    getters: {
        getDataForViewkey: state => state.dataForViewkey,
        getViewkeyProjectName: (state, getters) => {
            let name = '';

            if (getters.getCurrentProject) {
                name = getters.getCurrentProject.parent
                    ? getters.getCurrentProject.parent
                    : getters.getCurrentProject.name;
            }

            return name;
        },
        getViewkeySubprojectName: (state, getters) => {
            let name = '';

            if (getters.getCurrentProject && getters.getCurrentProject.parent) {
                name = getters.getCurrentProject.name;
            }

            return name;
        },
        getViewkeyHasChildren: state => state.hasChildren,
        getViewkeyView: state => state.viewkeyView,
        getViewkeyAccessStatus: state => state.viewkeyAccessStatus,
        getHashForShareLoading: state => state.hashForShareLoading,
        getHashForShare: state => state.hashForShare,
        getShareViewHashData: state => state.shareViewHashData,
    },
    actions: {
        async getViewkeyInfo({ getters, commit, dispatch }, payload) {
            const {
                shortkey,
                id,
                password,
                client,
                is_pdf = false,
                start_date = '',
                end_date = ''
            } = payload;
            commit(SET_DATA_FOR_VIEWKEY, payload);
            const params = { password: password };
            if (is_pdf) {
                params.is_pdf = true;
            }
            if (start_date) {
                params.start_date = start_date;
            }
            if (end_date) {
                params.end_date = end_date;
            }
            let project = null;
            try {
                let res;
                const { name: routeName, params: routeParams } = router.history.current;
                if (
                    routeName === 'shareViewkeyWithHash' ||
                    routeName === 'shareOfVoiceWithHash' ||
                    routeName === 'shareKeywordsTableWithHash'
                ) {
                    // view-key api with hash
                    res = await projectsApi.fetchViewkeyWithHash(routeParams.hash, { params });
                } else {
                    res = await projectsApi.fetchViewkey(id, shortkey, { params });
                }

                await dispatch('setPermissionsData', res?.data?.data?.attributes?.permissions);
                await dispatch('setIsFreePlan', res?.data?.data?.attributes?.is_free_plan);

                commit(SET_USER_DATA_PROP, {
                    prop: 'gscConnected',
                    value: res?.data?.data?.attributes?.gsc_connected ?? false
                });

                if ((res.data.errors && res.data.errors.some(el => el.code === apiCodes.GROUP_NOT_FOUND)) ||
                    (!getters.getPermissionData('viewkey_reports') && window.location.host.includes('local'))) {
                    router.push({ name: 'PageNotFound' });
                    return;
                }

                const { attributes } = res.data.data;
                commit(SET_KEYWORDS_TABLE_SETTINGS, initColumnsConfig(attributes.settings.columns));
                commit(SET_VIEWKEY_HAS_CHILDREN, attributes.has_children);
                dispatch('setDisplaySettings', {
                    ...attributes.settings.display,
                    ...attributes.settings.display.sorting,
                });
                dispatch('setOtherSettings', {
                    hidecolumns: attributes.settings.columns.join(';'),
                    chartheight: attributes.settings.display.chartheight,
                    display_length: attributes.settings.display.display_length,
                });
                dispatch('updateKeywordSettings', attributes.keyword_settings);
                project = {
                    ...attributes,
                    id: res.data.data.id,
                    type: res.data.data.type,
                    password,
                    shortkey,
                    client,
                };

                const splittedName = attributes.name.split('[sub]');

                if (splittedName.length > 1) {
                    project.name = splittedName[1];
                    project.parent = splittedName[0];
                }

                if (getters.getViewkeyView && !payload.is_pdf) {
                    if (project.password_valid === false) {
                        commit(SET_VIEWKEY_ACCESS_STATUS, 'denied');
                    } else {
                        commit(SET_VIEWKEY_ACCESS_STATUS, 'allowed');
                        if (
                            routeName !== 'shareOfVoice' &&
                            routeName !== 'shareOfVoiceWithHash'
                        ) {
                            await dispatch('fetchKeywordsForShareViewKeys', payload);
                        }
                    }
                }

                commit(SET_CURRENT_GROUP_OBJECT, project);
                updateDocumentTitle(router.history.current, getters);

                if (routeName !== 'shareOfVoice' && routeName !== 'shareOfVoiceWithHash') {
                    dispatch('firstRefreshesCheck', { overview: false });
                }
            } catch (error) {
                console.log(error, 'error')
                commit(SET_VIEWKEY_ACCESS_STATUS, 'allowed');
                router.push({ name: 'PageNotFound' });
            }
        },
        setIsViewkeyView({ commit }, payload) {
            commit(SET_VIEWKEY_VIEW, payload);
        },
        async getHashForShareAction({ getters, commit }, tagId) {
            try {
                const { project_id } = getters.getCurrentProject;
                commit(SET_HASH_FOR_SHARE_LOADING, true);
                const res = await projectsApi.getHashForShare(project_id);
                const { data } = res.data;
                const hashObj = data.find(item => {
                    return item.attributes.filters.tagId === tagId;
                });

                if (hashObj) {
                    commit(SET_HASH_FOR_SHARE, hashObj.attributes.hash);
                } else {
                    const res = await projectsApi.createHashForShare(project_id, { data: { filters: { tags: [tagId] } } });
                    const { hash } = res.data.data[0].attributes;
                    commit(SET_HASH_FOR_SHARE, hash);
                }
            } catch (error) {
                console.error(error);
            } finally {
                commit(SET_HASH_FOR_SHARE_LOADING, false);
            }
        },
        async getTagDataForShareAction({ commit }, hash) {
            const resTagData = await projectsApi.fetchTagDataForShare(hash);

            commit(SET_SHARE_VIEW_HASH_DATA, resTagData.data.data.attributes);
        },
    }
};

export default viewkey;
