<template>
    <div class="settings_view_key">
        <div class="display-settings__body display-settings__body_branding">
            <span class="block_icon view_key_icon" />
            <div class="settings_title_block">
                <h2 class="settings_title" v-text="$t('vk-brand-setting')" />
                <span
                    class="settings_text"
                    v-text="$t('vk-brand-setting-text')"
                />
            </div>
            <form class="settings__company" data-cy="branding-company-settings">
                <label class="text-label">
                    <toggler
                        :disabled="getIsFreePlan"
                        :toggler="
                            !getIsFreePlan && projectColumnSettings.hideverified
                        "
                        @toggler_click="toggleSetting('hideverified')"
                    />
                    <span @click="() => {}" v-text="$t('hide-verified')" />
                </label>
                <label class="text-label">
                    <toggler
                        :toggler="!projectColumnSettings.shareFromViewkey"
                        @toggler_click="toggleSetting('shareFromViewkey')"
                    />
                    <span @click="() => {}" v-text="$t('hide-share-options')" />
                </label>
                <label class="text-label">
                    <toggler
                        :disabled="!getPermissionData('viewkey_reports')"
                        :toggler="
                            getPermissionData('viewkey_reports') &&
                            projectColumnSettings.usewhitelabel
                        "
                        @toggler_click="toggleSetting('usewhitelabel')"
                    />
                    <span @click="() => {}" v-text="$t('use-whitelabel')" />
                </label>
                <label class="text-label">
                    <toggler
                        :toggler="projectColumnSettings.vkinfo"
                        @toggler_click="toggleSetting('vkinfo')"
                    />
                    <span
                        @click="() => {}"
                        v-text="$t('company-information-setting')"
                    />
                </label>
                <label class="text-label">
                    <toggler
                        :disabled="getIsFreePlan"
                        :toggler="
                            !getIsFreePlan &&
                            projectColumnSettings.customSubdomain
                        "
                        @toggler_click="toggleCustomSubdomain"
                    />
                    <span @click="() => {}" v-text="$t('custom-subdomain')" />
                </label>
                <kw-input-field
                    id="company-name"
                    ref="company_name"
                    v-model.trim="whitelabelSettings.company_name"
                    :label="$t('company-name')"
                    class="text-input"
                    name="companyName"
                    :placeholder="$t('your-company-name')"
                    type="text"
                    :error-data="inputCompanyError('company_name')"
                    @input="companyInputHandler('company_name')"
                />
                <kw-input-field
                    id="company-url"
                    ref="company_url"
                    v-model.trim="whitelabelSettings.company_url"
                    :label="$t('company-url-link')"
                    class="text-input"
                    name="companyUrl"
                    :placeholder="$t('settings-view-key-url-placeholder')"
                    type="text"
                    :error-data="inputCompanyError('company_url')"
                    @input="companyInputHandler('company_url')"
                />
                <kw-input-field
                    id="company-logo"
                    ref="company_logo"
                    v-model.trim="whitelabelSettings.company_logo"
                    :label="$t('company-logo')"
                    class="text-input"
                    name="companyLogo"
                    :placeholder="$t('settings-view-key-logo-placeholder')"
                    type="text"
                    :error-data="inputCompanyError('company_logo')"
                    @input="companyInputHandler('company_logo')"
                />
                <label
                    for="company-description"
                    class="settings__label"
                    v-text="$t('company-description')"
                />
                <textarea
                    id="company-description"
                    ref="company_desc"
                    v-model.trim="whitelabelSettings.company_desc"
                    :class="inputCompanyClasses('company_desc')"
                    name="CompanyDescription"
                    :placeholder="$t('company-description-placeholder')"
                    type="text"
                    @input="companyInputHandler('company_desc')"
                />
                <kw-button
                    :button-disabled="buttonDisabled"
                    :button-text="$t('save')"
                    button-type="primary"
                    :loading="companySettingsLoading"
                    data-cy="branding-save-button"
                    @click="saveSettings()"
                />
            </form>
        </div>
        <div class="display-settings__viewkey-view">
            <viewkey-preview
                :company-desc="whitelabelSettings.company_desc"
                :company-logo="whitelabelSettings.company_logo"
                :company-name="whitelabelSettings.company_name"
                :company-url="whitelabelSettings.company_url"
                :hide-verified="getHideVerfied"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SimpleConfirmWithCustomHandler from 'components/modals/SimpleConfirmWithCustomHandler';
import ValidationMixin from '@/mixins/ValidationMixin';
import ViewkeyPreview from './components/ViewkeyPreview';
import { CUSTOM_SUBDOMAINS } from '@/constants';

export default {
    name: 'AcountViewKey',
    components: {
        'viewkey-preview': ViewkeyPreview,
    },
    mixins: [ValidationMixin],
    data() {
        return {
            companySettingsLoading: false,

            projectColumnSettings: {
                customSubdomain: false,
                hideverified: false,
                shareFromViewkey: true,
                usewhitelabel: false,
                vkinfo: false,
            },
            whitelabelSettings: {
                company_name: '',
                company_url: '',
                company_logo: '',
                company_desc: '',
            },
            companyErr: {},
        };
    },
    computed: {
        ...mapGetters([
            'getCompanySettings',
            'getPermissionData',
            'getIsFreePlan',
            'getOtherSetting',
            'getOtherSettings',
            'getUserData',
        ]),
        buttonDisabled() {
            const isWhitelabelEqual = _.isEqual(
                this.whitelabelSettings,
                this.getCompanySettings,
            );
            const initialHideVerified =
                this.getOtherSetting('hideverified') === 'show';
            const initialShareFromViewkey =
                this.getOtherSetting('shareFromViewkey') === 'show';
            const initialUsewhitelabel =
                this.getOtherSetting('usewhitelabel') === 'show';
            const initialVkinfo = this.getOtherSetting('vkinfo') === 'show';
            const isProjectColumnSettingsSame =
                initialHideVerified ===
                    this.projectColumnSettings.hideverified &&
                initialShareFromViewkey ===
                    this.projectColumnSettings.shareFromViewkey &&
                initialUsewhitelabel ===
                    this.projectColumnSettings.usewhitelabel &&
                initialVkinfo === this.projectColumnSettings.vkinfo;

            return isWhitelabelEqual && isProjectColumnSettingsSame;
        },
        getHideVerfied() {
            const data =
                !this.getIsFreePlan && this.projectColumnSettings.hideverified
                    ? 'show'
                    : 'hide';
            return data;
        },
    },
    watch: {
        getUserData: {
            deep: true,
            handler: 'setCustomSubDomain',
        },
        getCompanySettings: {
            deep: true,
            handler() {
                this.whitelabelSettings = _.cloneDeep(this.getCompanySettings);
            },
        },
        getOtherSettings: {
            deep: true,
            handler() {
                this.initProjectColumnSettings();
            },
        },
    },
    mounted() {
        this.whitelabelSettings = _.cloneDeep(this.getCompanySettings);
        this.initProjectColumnSettings();
        this.setCustomSubDomain();
    },
    methods: {
        ...mapActions(['updateUserAccountViewKey']),
        initProjectColumnSettings() {
            this.projectColumnSettings.hideverified =
                this.getOtherSetting('hideverified') === 'show';
            this.projectColumnSettings.shareFromViewkey =
                this.getOtherSetting('shareFromViewkey') === 'show';
            this.projectColumnSettings.usewhitelabel =
                this.getOtherSetting('usewhitelabel') === 'show';
            this.projectColumnSettings.vkinfo =
                this.getOtherSetting('vkinfo') === 'show';
        },
        setCustomSubDomain() {
            let customUsers = Object.keys(CUSTOM_SUBDOMAINS);
            this.projectColumnSettings.customSubdomain =
                customUsers.includes(this.getUserData.email) ||
                customUsers.includes(this.getUserData.addonMasterEmail);
        },
        toggleSetting(type) {
            this.projectColumnSettings[type] = !this.projectColumnSettings[
                type
            ];
        },
        toggleCustomSubdomain() {
            this.projectColumnSettings.customSubdomain = !this
                .projectColumnSettings.customSubdomain;
            this.$modal.show(
                SimpleConfirmWithCustomHandler,
                {
                    handler: value => {
                        if (value) {
                            window.location.href =
                                'mailto:support@keyword.com?subject=Custom subdomain using CNAME';
                        }
                    },
                    header: this.$t('support-custom-subdomains'),
                    positiveButton: this.$t('contact-us'),
                },
                {
                    classes: 'v--modal center-modal-popup',
                    height: 'auto',
                    width: 282,
                },
                {
                    'before-close': () =>
                        (this.projectColumnSettings.customSubdomain = !this
                            .projectColumnSettings.customSubdomain),
                },
            );
        },
        companyInputHandler(type) {
            switch (type) {
                case 'company_name':
                    this.companyErr.company_name = this.reportsSettingsInputValidator(
                        this.whitelabelSettings.company_name,
                        this.$t('company-name'),
                        50,
                    );
                    break;
                case 'company_url':
                    this.companyErr.company_url = this.companyUrlValidator(
                        this.whitelabelSettings.company_url,
                        this.$t('company-url'),
                    );
                    break;
                case 'company_logo':
                    this.companyErr.company_logo = this.companyLogoUrlValidator(
                        this.whitelabelSettings.company_logo,
                        this.$t('logo-url'),
                    );
                    break;
                case 'company_desc':
                    this.companyErr.company_desc = this.reportsSettingsInputValidator(
                        this.whitelabelSettings.company_desc,
                        this.$t('company-description'),
                        1000,
                    );
                    break;
                default:
                    this.companyErr[type] = {};
                    break;
            }
        },
        async saveSettings() {
            if (this.settingsValidator()) {
                const { companyErr } = this;
                Object.keys(companyErr).forEach(el => {
                    if (companyErr[el] && companyErr[el].text) {
                        this.$toastr.e(companyErr[el].text);
                        // touch input
                        this.$refs[el].focus();
                        this.$refs[el].blur();
                    }
                });

                return;
            }

            this.companySettingsLoading = true;
            const dataParams = {
                projectColumnSettings: this.projectColumnSettings,
                whitelabelSettings: this.whitelabelSettings,
            };
            await this.updateUserAccountViewKey(dataParams);
            this.companySettingsLoading = false;
        },
        settingsValidator() {
            const { whitelabelSettings, companyErr } = this;
            Object.keys(whitelabelSettings).forEach(el =>
                this.companyInputHandler(el),
            );
            return Object.keys(companyErr).some(
                el => companyErr[el] && companyErr[el].text,
            );
        },
        inputCompanyClasses(type) {
            return {
                'text-input': true,
                'text-input_invalid':
                    this.companyErr[type] && this.companyErr[type].text,
            };
        },
        inputCompanyError(type) {
            return {
                error: this.companyErr[type] && this.companyErr[type].text,
            };
        },
    },
};
</script>

<style lang="scss">
.settings_view_key {
    display: flex;
    position: relative;

    .block_icon {
        position: absolute;
        left: 20px;
        top: -23px;
        height: 46px;
        width: 46px;
        background: url('~img/view-key-settings.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        display: block;
    }

    .display-settings__header_branding {
        position: relative;
        display: flex;
        flex: 1 1 100%;
    }

    .display-settings__body_branding {
        flex: 1 1 auto;
        max-width: 370px;
        border: 1px solid kw-color(kw-light-grey, 1);
        padding: 20px 20px 30px;

        .settings__company {
            .text-input {
                word-break: break-word;
                margin-bottom: 16px;
            }
        }

        @media (max-width: 1279px) {
            max-width: none;
        }
    }

    .display-settings__viewkey-view {
        flex: 1 1 auto;
        border: 1px solid #dee5ec;
        border-left: none;
    }

    .button-closer {
        position: absolute;
        right: 24px;
        top: 15px;
    }

    .settings__subtitle {
        margin-bottom: 0;
    }

    .settings__optional {
        color: #b9babd;
        font-size: 16px;
        margin-left: 8px;
    }

    .text-label {
        display: flex;
        font-size: 13px;
        padding-bottom: 17px;

        .toggler {
            position: relative;
            margin-right: 10px;
        }

        span {
            padding-left: 0;
            white-space: nowrap;
        }
    }

    .settings__company {
        display: flex;
        flex-flow: column nowrap;
    }

    textarea {
        @extend .custom-scroll;
        min-height: 114px;
        min-width: 100%;
        max-width: 100%;
        @media screen and (max-width: 960px) {
            width: 100%;
        }
    }

    .kw-btn-primary {
        margin-top: 20px;
        align-self: flex-end;
        width: 160px;
    }

    .settings_title_block {
        padding-bottom: 15px;

        .settings_title {
            color: kw-color(kw-black, 1);
            font-size: 16px;
            display: block;
        }

        .settings_text {
            color: kw-color(kw-grey, 1);
            font-size: 13px;
            display: block;
        }
    }

    @media (max-width: 1279px) {
        .display-settings__viewkey-view {
            display: none;
        }
    }
}
</style>
