<template>
    <div class="share-dropdown">
        <export-dropdown />
        <div
            v-if="isShareButtonVisible"
            id="share-dropdown"
            v-click-outside="handleClickOutSide"
            class="share-dropdown dropdown_container"
            data-cy="dashboard-share-button"
        >
            <kw-button
                :button-text="$t('share')"
                button-type="secondary"
                prepend-icon-class="kw kw-share"
                @click="handleButtonClick"
                :disabled="isLoading"
            />
            <custom-dropdown-body ref="shareDropdown" position="bottom-right">
                <ul class="v-context" data-cy="dashboard-share-menu">
                    <li>
                        <dashboard-share-viewkey
                            :hideEmbedded="hideEmbedded"
                            :project="getCurrentProject || {}"
                        />
                    </li>
                </ul>
            </custom-dropdown-body>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardShareViewkey from './DashboardShareViewkey';
import ExportDropdown from './ExportDropdown';
import ProjectsActionsMixin from '@/mixins/ProjectsActionsMixin';
import { detectIsSurferSEO } from '@/helpers';

export default {
    name: 'ShareDropdown',
    components: {
        'dashboard-share-viewkey': DashboardShareViewkey,
        'export-dropdown': ExportDropdown,
    },
    mixins: [ProjectsActionsMixin],
    props: {
        project: {
            type: String,
            required: false,
            default: '',
        },
        subproject: {
            type: String,
            required: false,
            default: '',
        },
    },
    data(){
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters([
            'getActiveTag',
            'getViewkeyView',
            'getOtherSetting',
            'getCurrentProject',
        ]),
        isShareButtonVisible() {
            return (
                !this.getViewkeyView ||
                this.getOtherSetting('shareFromViewkey') === 'show'
            );
        },
        hideEmbedded() {
            return this.getViewkeyView && detectIsSurferSEO();
        }
    },
    methods: {
        ...mapActions(['getHashForShareAction']),
        handleClickOutSide() {
            if (this.$refs.shareDropdown?.getIsDropdownOpened) {
                this.$refs.shareDropdown.closeDropdown();
            }
        },
        async handleButtonClick() {
            if (this.getActiveTag.id) {
                try {
                    this.isLoading = true;
                    await this.getHashForShareAction(this.getActiveTag.id);
                } finally {
                    this.isLoading = false;
                }
            }
            this.$refs.shareDropdown?.toggleDropdownOpen();
        },
    },
};
</script>

<style lang="scss" scoped>
.share-dropdown {
    display: flex;
    flex-flow: row nowrap;

    /deep/ .top-bar__button-share {
        padding: 8px 10px;
    }

    /deep/ .export-dropdown {
        .kw-btn-secondary {
            .kw-download {
                font-size: 18px;
            }
        }
    }

    /deep/.kw-btn-secondary {
        .kw-share {
            font-size: 18px;
        }
    }

    /deep/.dropdown_container {
        margin-left: 10px;
    }
}
.share-dropdown {
    display: flex;
    position: relative;
    .v-context {
        width: 250px;
        position: relative;
    }
}
.custom_dropdown_body {
    box-shadow: none !important;
    margin-top: 5px !important;
}
@media screen and (max-width: 959px) {
    .share-dropdown {
        justify-content: left;
    }
    .share-dropdown {
        margin-right: 0;
    }
    /deep/ .top-bar__button-share {
        padding: 8px 10px;
        span {
            font-size: 14px;
        }
    }
}
</style>
