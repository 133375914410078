// remove only view part of selected keyowords
const removeAllChecks = type => {

    const hyphen = getHyphen(type)

    const checkboxes = type === 'Keyword' ||  type === 'ProjectsOverview_v2'
        ? document.querySelectorAll('.row-wrap .checkbox.checkbox_active')
        : [...Array.from(document.querySelectorAll('.overview-table-body__cell .checkbox.checkbox_active')), ...Array.from(document.querySelectorAll('.row-wrap .checkbox.checkbox_active'))];

    if (hyphen) {
        hyphen.classList.remove('hyphen_active');
    }

    if (checkboxes.length) {
        checkboxes.forEach(el => el.classList.remove('checkbox_active'));
    }

    if (type === 'Keyword' || type === 'ProjectsOverview_v2') {
        const innerRows =  Array.from(document.querySelectorAll('.table-row__inner.table-row__inner_checked'));

        if (innerRows.length) {
            innerRows.forEach(el => el.classList.remove('table-row__inner_checked'));
        }
    }
};

// renders only checked checkboxes
const renderCheckboxes = (type, ids) => {
    const hyphen = getHyphen(type);

    switch (type) {
        case 'ProjectsOverview_v2':
            ids.forEach(el => {
                const activeCheckbox = document.getElementById(`${type}-${el}`);
                activeCheckbox && activeCheckbox.classList.add('checkbox_active');

            });
            document.querySelectorAll('.table-row__inner .checkbox_active').length
              ? hyphen.classList.add('hyphen_active')
              : hyphen.classList.remove('hyphen_active');
            break;

        case 'Keyword':
            ids.forEach(el => {
                const activeCheckbox = document.getElementById(`${type}-${el}`);
                activeCheckbox && activeCheckbox.classList.add('checkbox_active');

                const activeStaticRow = document.getElementById(`row-static-${el}`);
                activeStaticRow && activeStaticRow.classList.add('table-row__inner_checked');

                const activeDynamicRow = document.getElementById(`row-dynamic-${el}`);
                activeDynamicRow && activeDynamicRow.classList.add('table-row__inner_checked');
            });

            document.querySelectorAll('.table-row__inner .checkbox_active').length
                ? hyphen.classList.add('hyphen_active')
                : hyphen.classList.remove('hyphen_active');

            break;

    }
};

function getHyphen(type) {
    // the default matches the type "Keywords"
    let id = type === 'Keywords' ? 'hyphen-keywords' : 'hyphen-app';
    return document.getElementById(id);
}

export { removeAllChecks, renderCheckboxes, getHyphen};