<template>
    <add-project-tags 
        :get-project-for-tag-action="getProjectForChildAction"
        :toggle-project-add-tags="toggleProjectAddTags"
        :create-tags="createTags"
    />
</template>

<script>
    import AddProjectTags from './components/AddProjectTags';
    import {mapGetters, mapActions} from 'vuex';
    export default {
        name: 'AddProjectTagsScreen',
        components: {
            'add-project-tags': AddProjectTags
        },
        computed: {
            ...mapGetters([
                'getProjectForChildAction',
            ]),
            
        },
        methods: {
            ...mapActions([
                'toggleProjectAddTags',
                'createTags',
            ]),
        }
        
    }
</script>
