import { render, staticRenderFns } from "./GSCDeletedSuggestionsModal.vue?vue&type=template&id=38467524&scoped=true&"
import script from "./GSCDeletedSuggestionsModal.vue?vue&type=script&lang=js&"
export * from "./GSCDeletedSuggestionsModal.vue?vue&type=script&lang=js&"
import style0 from "./GSCDeletedSuggestionsModal.vue?vue&type=style&index=0&id=38467524&lang=scss&scoped=true&"
import style1 from "./GSCDeletedSuggestionsModal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38467524",
  null
  
)

export default component.exports