<template>
    <div data-cy="auth" class="auth" :class="$route.name">
        <div class="functional-section">
            <auth-tabs :selected="$route.name" />
            <div class="content">
                <router-view :currentPackage="currentPackage" />
            </div>
            <div class="logo"></div>
        </div>
        <div class="informational-section">
            <div class="information-wrapper">
                <signup-info
                    v-if="$route.name === 'signup'"
                    :currentPackage="currentPackage"
                />
                <login-info v-else />
                <div class="mobile-logo"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AuthTabs from './components/partials/AuthTabs';
import LoginInfo from './components/partials/LoginInfo';
import SignupInfo from './components/partials/SignupInfo';
export default {
    name: 'Auth',
    components: {
        AuthTabs,
        LoginInfo,
        SignupInfo,
    },
    data() {
        return {
            packageIdentity:
                this.$route.query.package_identity ?? process.env.FREE_PLAN_ID,
            currentPackage: null,
        };
    },
    async created() {
        this.currentPackage = await this.fetchPackageById(this.packageIdentity);
    },
    methods: {
        ...mapActions(['fetchPackageById']),
    },
};
</script>
<style lang="scss" scoped>
.auth {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;

    /deep/ .functional-section {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 39px;
        padding: 32px 48px;
        width: 100%;
        max-width: 596px;

        .content {
            display: flex;
            justify-content: center;
            width: 100%;

            & > div {
                width: 100%;
                max-height: 422px;
            }
        }

        .logo {
            background-image: url(~img/logo-full.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 153px;
            height: 30px;
        }
    }

    .informational-section {
        width: 384px;
        background-image: url(~img/auth-info-frame-background.png);
        background-repeat: no-repeat;
        background-size: cover;

        .information-wrapper {
            display: flex;
            width: 384px;
            height: 100%;
            gap: 10px;
            color: white;
            background: linear-gradient(
                0deg,
                rgba(30, 44, 67, 0.8) 0%,
                rgba(30, 44, 67, 0.8) 100%
            );
            padding: 0px 17px 90px 17px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;

            .mobile-logo {
                display: none;
            }
        }
    }

    &.signup {
        /deep/ .functional-section {
            gap: 24px;

            .content > div {
                max-height: 580px;
            }
        }

        .informational-section {
            .information-wrapper {
                align-items: flex-start;
                justify-content: start;
                padding: 45px 32px;
                background: linear-gradient(
                    0deg,
                    rgba(30, 44, 67, 0.9) 0%,
                    rgba(30, 44, 67, 0.9) 100%
                );
            }
        }
    }

    @media (max-width: 980px) {
        /deep/ .functional-section {
            max-width: 506px;
            padding: 32px 16px;

            .content > div {
                height: fit-content;
            }
        }

        .informational-section {
            display: none;
        }

        &.forgotPassword {
            .functional-section {
                gap: 39px;
            }
        }

        &.signup {
            display: flex;
            flex-direction: column;
            gap: 48px;

            .functional-section {
                padding-bottom: 0;
                .logo {
                    display: none;
                }
            }

            .informational-section {
                display: initial;
                // display: none;
                background: none;
                width: 100%;

                .information-wrapper {
                    background: none;
                    padding: 0 16px 32px;
                    width: 100%;

                    /deep/ .toggler-row .billing-label,
                    /deep/ .price-row,
                    /deep/ .permission {
                        color: #15394f;
                        font-family: Aeonik;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .mobile-logo {
                        display: block;
                        background-image: url(~img/logo-full.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 153px;
                        height: 30px;
                        margin: 48px auto;
                    }
                }
            }
        }
    }
}
</style>
