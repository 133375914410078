const enUpgrade = {
    'start-14-day-trial': 'Start 14-Day Trial',
    'upgrade-to-integrate-ga': {
        'title': 'Upgrade to integrate with Google Analytics',
        'desc': 'Connect your Google Analytics account and see how your user traffic for Organic Searches.',
    },
    'upgrade-to-integrate-gsc': {
        'title': 'Upgrade to integrate with Google Analytics',
        'desc': `Connect your Google Search Console account and get
                    <ul>
                    <li>Keyword suggestions directly from Google Search Console for the domain you are tracking</li>
                    <li>Additional Keyword metrics in your ranking table - Impressions, CTR and Clicks</li>
                    </ul>
                `,
    },
    'upgrade-to-integrate-reports': {
        'title': 'Upgrade to schedule PDF, CSV or Text reports',
        'desc': 'Automate your reporting by scheduling all your reports with customized frequencies and recipients.',
    },
};

export default enUpgrade;
