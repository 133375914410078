<template functional>
    <div class="auth-tabs">
        <router-link
            :to="{ name: 'login' }"
            :class="{ active: props.selected === 'login' }"
            class="tab"
        >
            {{ parent.$t('login') }}
        </router-link>
        <router-link
            :to="{ name: 'signup' }"
            :class="{ active: props.selected === 'signup' }"
            class="tab"
        >
            {{ parent.$t('signup') }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'AuthTabs',
    props: {
        selected: {
            type: String,
            required: false,
            default: () => 'login',
        },
    },
};
</script>

<style lang="scss" scoped>
.auth-tabs {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 42px;
    width: 100%;

    .tab {
        color: #7f8890;
        font-family: Aeonik;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        position: relative;
        padding-bottom: 14px;
        text-decoration: none;

        &.active {
            color: #15394f;
            &:after {
                content: '';
                border: 3px solid kw-color(kw-red, 10);
                position: absolute;
                width: 59px;
                border-radius: 3px;
                left: calc(50% - 28px);
                bottom: 0;
            }
        }
    }
}
</style>
