import store from '@/store';
import updateDocumentTitle from '@/helpers/update-document-title';

const hooks = {
    global: to => {
        store.dispatch('setCurrentProject');
        updateDocumentTitle(to, store.getters);
        store.dispatch('setSelectedKeywords', []);

        if (to.name === 'keywordList') {
            store.dispatch('firstRefreshesCheck', {overview: false});
        }
    },
    keywordList: (to, from) => {
        if (!['keywordList', 'addKeywords'].includes(from.name)) return;
        if (from.params.id === to.params.id) return;
        if ('searchResults' === to.name) return;

        store.dispatch('setShownChart', {keyword: null, project: null});

        if (decodeURIComponent(to.params.id) !== decodeURIComponent(from.params.id)) {
            // setTimeout because of add keyword components created drafts on beforeDestroy (after this hook)
            setTimeout(() => store.dispatch('resetKeywordsDrafts'), 0);
        }
    },
};

export default type => hooks[type];
