<template>
    <div class="display-settings">
        <div class="display-settings__pdf">
            <closer @click="close" />
            <h2 class="settings__subtitle settings__subtitle_pdf custom-modal_draggable">
                {{ $t('pdf-settings') }}
            </h2>
            <div
                v-for="(item, i) in displayCheckboxesConfig"
                :key="i"
                class="display-settings__pdf-item"
            >
                <toggler
                    :id="`${item.name}-checkbox`"
                    :toggler="checkboxCondition(item.name)"
                    @toggler_click="toggleCheckbox(item.name)"
                />
                <label
                    :for="`${item.name}-checkbox`"
                    class="settings__label settings__label_pdf"
                    @click="toggleCheckbox(item.name)"
                    v-text="item.label"
                />
                <span
                    v-if="item.name === 'companyInformationPdfSettings' || item.name === 'generateHistoricalGraphPdf' && item.tooltip"
                    class="kw kw-information icon-info-in-cell"
                    @mouseover="tooltip({ text: item.tooltip, event: $event, maxWidth: 163 })"
                />
            </div>
        </div>

        <div class="display-action-btn">
            <kw-button
                class="customize-table-columns"
                button-type="outline-primary"
                :button-text="$t('customize-table-columns')"
                @click="goToCustomizeTableColumns"
            />
        </div>

        <div
            v-if="getPermissionData('viewkey_reports')"
            class="display-action-btn"
        >
            <kw-button
                class="whitelabel-domain-ver"
                button-type="outline-primary"
                :button-text="$t('whitelabel-domain-verification')"
                :disabled="!isPackageOver5000"
                @click="goToWhitelabelDomains"
            />
            <span
                v-if="!isPackageOver5000"
                class="kw kw-information icon-info-in-cell"
                @mouseover="
                    tooltip({
                        text: $t('i-whitelabel-domain-verification'),
                        event: $event,
                        maxWidth: 163,
                    })
                "
            />
        </div>
    </div>
</template>

<script>
    import {displayCheckboxesConfig} from './configs';
    import {mapGetters, mapActions} from 'vuex';
    import 'vue-slider-component/theme/antd.css';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    import ValidationMixin from '@/mixins/ValidationMixin';
    export default {
        name: 'PDFSettings',
        mixins: [
            ValidationMixin,
            ToolTipRenderMixin,
        ],
        props: {
            close: {
                type: Function,
                required: true,
            },
        },
        data () {
            return {
                displayCheckboxesConfig,
            }
        },
        computed: {
            ...mapGetters([
                'getPermissionData',
                'getOtherSetting',
                'getPackageKeywords',
            ]),
            isPackageOver5000() {
                return this.getPackageKeywords >= 5000;
            },
        },
        methods: {
            ...mapActions([
                'updateUserTableSettings',
            ]),
            checkboxCondition (name) {
                return this.getOtherSetting(name) === 'show';
            },
            toggleCheckbox (name) {
                this.updateUserTableSettings({
                    setting: name,
                    value: this.getOtherSetting(name) === 'show' ? 'hide' : 'show',
                });
            },
            goToCustomizeTableColumns () {
                this.$modal.hide('SettingsModal');
                this.$router.push({name:'settingsAccountTable'});
            },
            goToWhitelabelDomains () {
                this.$modal.hide('SettingsModal');
                this.$router.push({name: 'WhitelabelDomains'});
            },
        }
    };

</script>

<style lang="scss" scoped>
  .display-settings {
    padding: 15px;

    .button-closer {
      align-self: flex-end;
    }
  }

  .display-settings__pdf {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    width: 100%;
    margin-bottom: 35px;
  }

  .display-settings__pdf-item {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 10px 0;
    position: relative;

    /deep/ .toggler {
      position: relative;
      top: unset;
    }
  }

  .settings__label_pdf {
    cursor: pointer;
    font-weight: normal !important;
    margin-bottom: 0;
    padding-left: 8px;
    margin-right: 5px;
  }

  .settings__subtitle_pdf {
    border-bottom: 1px solid kw-color(kw-light-grey, 1);
    margin: 0 -15px;
    padding: 0 15px 10px;
  }
  .kw-information {
    color: #828F9C;
    font-size: 12px;
    font-weight: 700;
    margin: 0 4px;
  }
  .display-action-btn {
    display: flex;
    align-items: baseline;
    margin-top: 12px;
  }
</style>
