import {defaultAlertsSort} from '../configs';
import {processAlerts, processAlertsPivot} from '../utils';
import {updateAlertsPageTitle} from '@/helpers/update-document-title';
import { cookieGetters } from '@/helpers/cookieManager';
import apiFactory from '@/api';
import router from '@/routes';
const alertsApi = apiFactory.get('alerts');

import {
    SET_ALERTS_LOADING,
    SET_ALERTS_PAGE,
    SET_ALERTS_PER_PAGE,
    SET_ALERTS_SORT,
    SET_ALERTS_TOTAL,
    SET_ALERTS_VIEW,
    SET_ALERTS,
    SET_IS_LATEST,
    SET_IS_PIVOT,
    SET_KEYWORD_FOR_ALERTS_FILTER,
    SET_PROJECTS_FOR_ALERTS_FILTER,
    SET_TOTAL_ALERTS_PAGES,
    SET_UNREADS_ALERTS_COUNT,
} from '../mutations';

export const alertsPersistedKeys = [
    'alerts.alertsPage',
    'alerts.alertsPerPage',
    'alerts.alertsSort',
    'alerts.alertsView',
    'alerts.isLatest',
    'alerts.isPivot',
    'alerts.keywordForAlertsFilter',
];

const alerts = {
    state: {
        alerts: [],
        keywordForAlertsFilter: '',
        alertsLoading: false,
        alertsPage: 1,
        alertsPerPage: 100,
        projectsForAlertsFilter: [],
        alertsSort: defaultAlertsSort,
        alertsTotal: 0,
        alertsView: 'All alerts',
        isLatest: false,
        isPivot: false,
        totalAlertsPages: 0,
        unreadAlertsCount: null,
    },
    mutations: {
        [SET_ALERTS_LOADING]: (state, payload) => state.alertsLoading = payload,
        [SET_ALERTS_PAGE]: (state, payload) => state.alertsPage = payload,
        [SET_ALERTS_PER_PAGE]: (state, payload) => state.alertsPerPage = payload,
        [SET_ALERTS_SORT]: (state, payload) => state.alertsSort = payload,
        [SET_ALERTS_TOTAL]: (state, payload) => state.alertsTotal = payload,
        [SET_ALERTS_VIEW]: (state, payload) => state.alertsView = payload,
        [SET_ALERTS]: (state, payload) => state.alerts = payload,
        [SET_IS_LATEST]: state => state.isLatest = !state.isLatest,
        [SET_IS_PIVOT]: state => state.isPivot = !state.isPivot,
        [SET_KEYWORD_FOR_ALERTS_FILTER]: (state, payload) => state.keywordForAlertsFilter = payload,
        [SET_PROJECTS_FOR_ALERTS_FILTER]: (state, payload) => state.projectsForAlertsFilter = payload,
        [SET_TOTAL_ALERTS_PAGES]: (state, payload) => state.totalAlertsPages = payload,
        [SET_UNREADS_ALERTS_COUNT]: (state, payload) => state.unreadAlertsCount = payload,
    },
    getters: {
        getAlerts: state => state.alerts,
        getAlertsKeyword: state => state.keywordForAlertsFilter,
        getAlertsLoading: state => state.alertsLoading,
        getAlertsPage: state => state.alertsPage,
        getAlertsPerPage: state => state.alertsPerPage,
        getAlertsSort: state => state.alertsSort,
        getAlertsTotal: state => state.alertsTotal,
        getAlertsView: state => state.alertsView,
        getIsLatest: state => state.isLatest,
        getIsPivot: state => state.isPivot,
        getProjectsForAlertsFilter: state => state.projectsForAlertsFilter,
        getTotalAlertsPages: state => state.totalAlertsPages,
        getUnreadAlertsCount: state => state.unreadAlertsCount,
    },
    actions: {
        async fetchAlerts ({getters, commit}) {
            commit(SET_ALERTS_LOADING, true);

            const query = {
                params: {
                    last: getters.getIsLatest,
                    page: getters.getAlertsPage,
                    per_page: getters.getAlertsPerPage,
                    sort_direction: getters.getAlertsSort.direction,
                    sort_field: getters.getAlertsSort.field,
                    view_type: getters.getAlertsView,
                },
            };

            const {getProjectsForAlertsFilter, getAlertsKeyword} = getters;

            if (getProjectsForAlertsFilter.length) {
                query.params.category = getProjectsForAlertsFilter;
            }

            if (getAlertsKeyword.length) {
                query.params.keyword = getAlertsKeyword;
            }

            try {
                let res;

                if (getters.getIsPivot) {
                    res = await alertsApi.fetchPivot(query);
                    commit(SET_ALERTS, processAlertsPivot(res.data.data));
                } else {
                    res = await alertsApi.fetch(query);
                    commit(SET_ALERTS, processAlerts(res.data.data));
                }

                commit(SET_ALERTS_TOTAL, res.data.meta.pagination.total);
                commit(SET_TOTAL_ALERTS_PAGES, res.data.meta.pagination.total_pages);
            } catch (error) {
                //
            } finally {
                commit(SET_ALERTS_LOADING, false);
            }
        },
        async sortAlerts ({getters, commit, dispatch}, field) {
            // @TODO: Set the sort by monthly seach vol. function in API
            if (field === 'ms') {
                return;
            }
            commit(SET_ALERTS_SORT, {
                direction: field === getters.getAlertsSort.field
                    ? getters.getAlertsSort.direction === 'asc' ? 'desc' : 'asc'
                    : 'asc',
                field,
            });

            await dispatch('fetchAlerts');
        },
        async toggleLatestAlerts ({commit, dispatch}) {
            commit(SET_IS_LATEST);
            commit(SET_ALERTS_PAGE, 1);
            await dispatch('fetchAlerts');
        },
        async togglePivot ({commit, dispatch}) {
            commit(SET_IS_PIVOT);
            commit(SET_ALERTS_SORT, defaultAlertsSort);
            commit(SET_ALERTS_PAGE, 1);
            await dispatch('fetchAlerts');
        },
        async searchAlerts ({commit, dispatch}, payload) {
            commit(SET_KEYWORD_FOR_ALERTS_FILTER, payload);
            commit(SET_ALERTS_PAGE, 1);
            await dispatch('fetchAlerts');
        },
        async changeAlertsView ({commit, dispatch}, payload) {
            commit(SET_ALERTS_VIEW, payload);
            commit(SET_ALERTS_PAGE, 1);
            await dispatch('fetchAlerts');
        },
        async changeAlertsPerPage ({commit, dispatch}, payload) {
            commit(SET_ALERTS_PER_PAGE, payload);
            commit(SET_ALERTS_PAGE, 1);
            await dispatch('fetchAlerts');
        },
        async filterAlertsByProjects ({getters, commit, dispatch}, payload) {
            if (Array.isArray(payload)) {
                // payload is a flat array of all active projects
                getters.getProjectsForAlertsFilter.length === payload.length
                    ? commit(SET_PROJECTS_FOR_ALERTS_FILTER, [])
                    : commit(SET_PROJECTS_FOR_ALERTS_FILTER, payload.map(el => el.fullName));
            }

            if (payload.deleted) {
                return;
            }

            if (!Array.isArray(payload)) {
                getters.getProjectsForAlertsFilter.includes(payload.fullName)
                    ? commit(SET_PROJECTS_FOR_ALERTS_FILTER, getters.getProjectsForAlertsFilter.filter(g => g !== payload.fullName))
                    : commit(SET_PROJECTS_FOR_ALERTS_FILTER, [...getters.getProjectsForAlertsFilter, payload.fullName]);
            }

            commit(SET_ALERTS_PAGE, 1);
            await dispatch('fetchAlerts');
        },
        async filterAlertsByOnlyProjects ({commit, dispatch}, payload) {
            if (payload.deleted) {
                return;
            }

            commit(SET_PROJECTS_FOR_ALERTS_FILTER, [payload.fullName]);
            commit(SET_ALERTS_PAGE, 1);
            await dispatch('fetchAlerts');
        },
        async alertsPageChanged ({getters, commit, dispatch}, payload) {
            switch (payload) {
                case 'next':
                    commit(SET_ALERTS_PAGE, getters.getAlertsPage + 1);
                    break;
                case 'prev':
                    commit(SET_ALERTS_PAGE, getters.getAlertsPage - 1);
                    break;
                default:
                    commit(SET_ALERTS_PAGE, payload);
                    break;
            }

            await dispatch('fetchAlerts');
        },
        async fetchAlertsCount ({commit}) {
            if (!cookieGetters.getApiKey()) return;

            try {
                const res = await alertsApi.count();
                commit(SET_UNREADS_ALERTS_COUNT, res.data.unread.toLocaleString('en'));
                updateAlertsPageTitle(router.history.current.name, res.data.unread.toLocaleString('en'));
            } catch (error) {
                //
            }
        },
    }
};

export default alerts;
