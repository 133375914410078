import i18n from '@/i18n';
import {getRangeTitle} from '@/helpers/process-metrics';
import { actions } from '@/configs/overviewConfigs';


export default (getDisplaySetting) => {

    return [
        {
            headerClass: 'column-header_checkbox cursor-pointer',
            bodyClass: 'column-data column-data_checkbox',
            required: true,
            enabled: false,
            disabledInReports: false,
            name: 'checkbox',
            width: 32,
            isStatic: true,
        },
        {
            headerClass: 'column-header_app cursor-pointer',
            bodyClass: 'column-data column-data_app',
            name: 'id',
            percentWidth: 22,
            width: 170,
            sorter: 'id',
            title: i18n.t('project-name'),
            required: true,
            enabled: false,
            disabledInReports: false,
            isStatic: true,
            component: () => import('@/components/ui-elements/ProjectLink'),
            /**
             * default arguments
             * @param item current element of data
             * @param header current header ( row header)
             * @param emitEvent emitter of table to emit and handle custom events
             * */
            props: project => ({ projectRaw: project.id, newTab: false, })
        },
        {
            headerClass: 'column-header cursor-pointer',
            bodyClass: 'column-data text-right',
            name: 'attributes.updated_at',
            percentWidth: 16,
            width: 150,
            sorter: 'attributes.updated_at',
            title: i18n.t('date-added'),
        },
        {
            headerClass: 'column-header cursor-pointer',
            bodyClass: 'column-data',
            name: 'attributes.total',
            percentWidth: 11,
            width: 150,
            sorter: 'keywords',
            title: i18n.t('total-keywords'),
        },
        ...getDisplaySetting('metrics_range').map((range, i) => ({
            headerClass: 'column-header cursor-pointer',
            bodyClass: 'column-data column-data_period',
            name: `attributes.ranges[${i}]`,
            width: 150,
            percentWidth: 11,
            sorter: `attributes.ranges[${i}]`,
            title: getRangeTitle(range),
        })),
        {
            bodyClass: 'column-data overview-table-body__cell_actions',
            headerClass: 'column-header column-header__actions',
            name: 'actions',
            title: i18n.t('actions'),
            width: 60,
            component: () => import('@/components/ui-elements/OverviewTableActions'),
            props: (project, _, emitEvent) => ({ project, emit: emitEvent, dropdownItems: actions })

        },
    ];
};
