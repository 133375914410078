var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connection-rules-modal",attrs:{"data-cy":"connection-rules-modal"}},[_c('closer',{on:{"click":_vm.close}}),_vm._v(" "),_c('div',{staticClass:"custom-modal__header custom-modal_draggable",attrs:{"data-cy":"connection-rules-modal-header"}},[_vm._v("\n        "+_vm._s(_vm.$t('connection-rules-modal-header'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"custom-modal__body custom-modal__body_export",attrs:{"data-cy":"connection-rules-modal-body"}},[_c('div',[_vm._v(_vm._s(_vm.$t('customize-rules')))]),_vm._v(" "),_c('form',{staticClass:"form",attrs:{"novalidate":true},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm($event)}}},[_c('div',{staticClass:"modal-row"},[_c('div',{staticClass:"modal-label"},[_vm._v("\n                    "+_vm._s(_vm.$t('average-position-label'))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"inputs"},[_c('kw-input-field',{directives:[{name:"validate",rawName:"v-validate",value:(
                            ("min_value:1|max_value:" + (this.rules.positionMax
                                    ? this.rules.positionMax
                                    : 100))
                        ),expression:"\n                            `min_value:1|max_value:${\n                                this.rules.positionMax\n                                    ? this.rules.positionMax\n                                    : 100\n                            }`\n                        "}],staticClass:"number-input",attrs:{"input-id":"position-min","name":"positionMin","data-vv-as":_vm.$t('average-pos-min'),"placeholder":"1","type":"number","error-data":_vm.positionMinError},model:{value:(_vm.rules.positionMin),callback:function ($$v) {_vm.$set(_vm.rules, "positionMin", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"rules.positionMin"}}),_vm._v("-"),_c('kw-input-field',{directives:[{name:"validate",rawName:"v-validate",value:(
                            ("min_value:" + (this.rules.positionMin
                                    ? this.rules.positionMin
                                    : 1) + "|max_value:100")
                        ),expression:"\n                            `min_value:${\n                                this.rules.positionMin\n                                    ? this.rules.positionMin\n                                    : 1\n                            }|max_value:100`\n                        "}],staticClass:"number-input",attrs:{"input-id":"position-max","name":"positionMax","data-vv-as":_vm.$t('average-pos-max'),"placeholder":"100","type":"number","error-data":_vm.positionMaxError},model:{value:(_vm.rules.positionMax),callback:function ($$v) {_vm.$set(_vm.rules, "positionMax", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"rules.positionMax"}})],1)]),_vm._v(" "),_c('div',{staticClass:"modal-row"},[_c('div',{staticClass:"modal-label"},[_vm._v("\n                    "+_vm._s(_vm.$t('average-impression-label'))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"inputs"},[_c('kw-input-field',{directives:[{name:"validate",rawName:"v-validate",value:(
                            ("min_value:0|max_value:" + (this.rules.impressionMax
                                    ? this.rules.impressionMax
                                    : 100))
                        ),expression:"\n                            `min_value:0|max_value:${\n                                this.rules.impressionMax\n                                    ? this.rules.impressionMax\n                                    : 100\n                            }`\n                        "}],staticClass:"number-input",attrs:{"input-id":"impression-min","name":"impressionMin","data-vv-as":_vm.$t('average-imp-min'),"placeholder":"1","type":"number","error-data":_vm.impressionMinError},model:{value:(_vm.rules.impressionMin),callback:function ($$v) {_vm.$set(_vm.rules, "impressionMin", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"rules.impressionMin"}}),_vm._v("-"),_c('kw-input-field',{directives:[{name:"validate",rawName:"v-validate",value:(
                            ("min_value:" + (this.rules.impressionMin
                                    ? this.rules.impressionMin
                                    : 0) + "|max_value:100")
                        ),expression:"\n                            `min_value:${\n                                this.rules.impressionMin\n                                    ? this.rules.impressionMin\n                                    : 0\n                            }|max_value:100`\n                        "}],staticClass:"number-input",attrs:{"input-id":"impression-max","name":"impressionMax","data-vv-as":_vm.$t('average-imp-max'),"placeholder":"100","type":"number","error-data":_vm.impressionMaxError},model:{value:(_vm.rules.impressionMax),callback:function ($$v) {_vm.$set(_vm.rules, "impressionMax", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"rules.impressionMax"}})],1)]),_vm._v(" "),_c('div',{staticClass:"modal-row"},[_c('div',{staticClass:"modal-label"},[_vm._v("\n                    "+_vm._s(_vm.$t('average-ctr-label'))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"inputs"},[_c('kw-input-field',{directives:[{name:"validate",rawName:"v-validate",value:(
                            ("min_value:0|max_value:" + (this.rules.ctrMax ? this.rules.ctrMax : 100))
                        ),expression:"\n                            `min_value:0|max_value:${\n                                this.rules.ctrMax ? this.rules.ctrMax : 100\n                            }`\n                        "}],staticClass:"number-input",attrs:{"input-id":"ctr-min","name":"ctrMin","data-vv-as":_vm.$t('average-ctr-min'),"placeholder":"0.01%","type":"number","step":0.01,"error-data":_vm.ctrMinError},model:{value:(_vm.rules.ctrMin),callback:function ($$v) {_vm.$set(_vm.rules, "ctrMin", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"rules.ctrMin"}}),_vm._v("-"),_c('kw-input-field',{directives:[{name:"validate",rawName:"v-validate",value:(
                            ("min_value:" + (this.rules.ctrMin ? this.rules.ctrMin : 0) + "|max_value:100")
                        ),expression:"\n                            `min_value:${\n                                this.rules.ctrMin ? this.rules.ctrMin : 0\n                            }|max_value:100`\n                        "}],staticClass:"number-input",attrs:{"input-id":"ctr-max","name":"ctrMax","data-vv-as":_vm.$t('average-ctr-max'),"placeholder":"100%","type":"number","error-data":_vm.ctrMaxError},model:{value:(_vm.rules.ctrMax),callback:function ($$v) {_vm.$set(_vm.rules, "ctrMax", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"rules.ctrMax"}})],1)]),_vm._v(" "),_c('div',{staticClass:"modal-row"},[_c('div',{staticClass:"modal-label"},[_vm._v("\n                    "+_vm._s(_vm.$t('date-range'))+"\n                ")]),_vm._v(" "),_c('custom-v-select',{staticClass:"date-range",attrs:{"options-label":"label","options-value":"value","data-cy":"date-range","options":_vm.periods,"disableDeSelect":true,"value":_vm.rules.period},on:{"update:value":function($event){return _vm.$set(_vm.rules, "period", $event)},"change":function($event){return _vm.dateRange($event)}}})],1),_vm._v(" "),_c('div',{staticClass:"modal-row hide"},[_c('div',{staticClass:"dropdowns"},[_c('div',{staticClass:"modal-dropdown"},[_c('label',{staticClass:"text-label",attrs:{"for":"country"}},[_vm._v("\n                            "+_vm._s(_vm.$t('region'))+"\n                        ")]),_vm._v(" "),_c('custom-v-select',{class:{
                                'custom-dropdown_filled': _vm.rules.country,
                            },attrs:{"id":"country","options-label":"name","options-value":"country","select-type":"country","data-cy":"connection-rules-country-dropdown","placeholder":_vm.$t('select-a-country'),"search":"","options":_vm.regionsForSelect,"value":_vm.rules.country,"dropdown-menu-data-cy":"edit-connection-rules-country-list","dropdown-search-data-cy":"edit-connection-rules-country-search"},on:{"update:value":function($event){return _vm.$set(_vm.rules, "country", $event)},"change":function($event){return _vm.changeCountry($event)}}})],1),_vm._v(" "),_c('div',{staticClass:"modal-dropdown"},[_c('label',{staticClass:"text-label",attrs:{"for":"device"}},[_vm._v("\n                            "+_vm._s(_vm.$t('device'))+"\n                        ")]),_vm._v(" "),_c('custom-v-select',{class:{
                                'custom-dropdown_filled': _vm.rules.device,
                            },attrs:{"id":"device","options-class":"class","options-label":"name","options-value":"value","select-type":"device","data-cy":"connection-rules-device-dropdown","placeholder":_vm.$t('select-a-device'),"options":_vm.deviceOptions,"value":_vm.rules.device,"dropdown-menu-data-cy":"edit-connection-rules-device-list","dropdown-search-data-cy":"edit-connection-rules-device-search"},on:{"update:value":function($event){return _vm.$set(_vm.rules, "device", $event)},"change":function($event){return _vm.changeDevice($event)}}})],1)])]),_vm._v(" "),_c('div',{staticClass:"modal-row"},[_c('div',{staticClass:"checkbox-row"},[_c('checkbox',{attrs:{"id":"rules-autoAdd-checkbox","data-cy":"checkbox-rules-autoAdd","checkbox":_vm.rules.autoAdd},on:{"checkbox-click":function($event){_vm.rules.autoAdd = !_vm.rules.autoAdd}},model:{value:(_vm.rules.autoAdd),callback:function ($$v) {_vm.$set(_vm.rules, "autoAdd", $$v)},expression:"rules.autoAdd"}}),_vm._v(" "),_c('label',{staticClass:"rules-autoAdd__label rules-autoAdd__label_other",attrs:{"for":"rules-autoAdd-checkbox"},domProps:{"textContent":_vm._s(_vm.$t('automatically-create-keyword-label'))},on:{"click":function($event){_vm.rules.autoAdd = !_vm.rules.autoAdd}}})],1)]),_vm._v(" "),_c('div',{staticClass:"connection-rules-buttons"},[_c('kw-button',{attrs:{"button-text":_vm.$t('cancel'),"button-type":"secondary","disabled":_vm.getGSCProfileConnectionsUpdating},on:{"click":_vm.close}}),_vm._v(" "),_c('kw-button',{attrs:{"type":"submit","button-type":"primary","button-text":_vm.$t('save'),"button-disabled":_vm.getGSCProfileConnectionsUpdating,"loading":_vm.getGSCProfileConnectionsUpdating}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }