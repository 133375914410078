import { mapGetters } from 'vuex';
import { removeAllChecks, renderCheckboxes, getHyphen } from '@/helpers/render-keywords-helpers';

export default {
    data() {
        return {
            startCheck: null,
            endCheck: null,
            checksRange: [],
        };
    },
    computed: {
        ...mapGetters([
            'getCommonKeywordsChartIsShow',
        ]),
    },
    methods: {
        // fixed header at the dashboard table
        mainPageScrollHandler() {
            this.toggleFloatScroll();

            if (document.querySelector('.alerts-table__wrapper')) {
                this.alertsTableScrollHandler();
                this.fixTableHeader('alerts');
            }

            if (document.querySelector('.keywords-table__wrapper')) {
                this.keywordsTableScrollHandler();
                this.fixTableHeader('keywords');
            }

            if (document.querySelector('.app-table__wrapper')) {
                this.fixTableHeader('app');
            }
        },
        /**
         * @description this method toggles float scroll in case of table was scrolled to the end
         * @param {HTMLElement} el main-page element
         */
        toggleFloatScroll() {
            const floatScroll = document.querySelector('.ps__rail-x');

            if (!floatScroll) {
                return;
            }

            window.screen.height < document.getElementsByTagName('body')[0].scrollHeight
                ? floatScroll.classList.add('ps__rail-x_fixed')
                : floatScroll.classList.remove('ps__rail-x_fixed');
        },
        fixTableHeader(tableType) {
            const tableWrap = document.querySelector(`.${tableType}-table__wrapper`);

            const offset = window.screen.width > window.MOBILE_WIDTH_RESOLUTION_PHONE
                ? this.$route.name === 'Overview'
                    ? 168
                    : 215
                : 195;

            if (tableType === 'keywords' || tableType === 'app') {
                const staticTable = document.querySelector(`.${tableType}-table_static`);
                const dynamicTable = document.querySelector(`.${tableType}-table_dynamic`);
                const container = document.querySelector(`.${tableType}-container`);

                const staticTableHeader = document.querySelector(`.${tableType}-table_static .${tableType}-table__header`);
                const dynamicTableHeader = document.querySelector(`.${tableType}-table_dynamic .${tableType}-table__header`);
                const dashboardActionBar = document.querySelector('.dashboard-action-bar');
                if (!staticTableHeader && !dynamicTableHeader) {
                    return;
                }
                const tableHeaderClasses = [`${tableType}-table__header_fixed`];
                if (dashboardActionBar) {
                    tableHeaderClasses.push('with_action_bar');
                } else {
                    if (staticTableHeader.classList.contains('with_action_bar')) {
                        staticTableHeader.classList.remove('with_action_bar');
                        dynamicTableHeader.classList.remove('with_action_bar');
                    }
                }

                if (tableWrap.getBoundingClientRect().top > offset) {
                    staticTableHeader.style.width = 'unset';
                    dynamicTableHeader.style.width = 'unset';
                    if (dashboardActionBar) {
                        dashboardActionBar.style.width = '100%';
                        dashboardActionBar.classList.remove('dashboard-action-bar-fixed');
                    }


                    staticTableHeader.classList.remove(...tableHeaderClasses);
                    dynamicTableHeader.classList.remove(...tableHeaderClasses);

                    return;
                }
                dynamicTableHeader.scrollLeft = dynamicTableHeader.parentElement.scrollLeft;
                staticTableHeader.style.width = staticTable.clientWidth + 'px';
                dynamicTableHeader.style.width = dynamicTable.clientWidth + 'px';

                if (dashboardActionBar) {
                    dashboardActionBar.style.width = container.offsetWidth + 'px';
                    dashboardActionBar.classList.add('dashboard-action-bar-fixed');

                }
                staticTableHeader.classList.add(...tableHeaderClasses);
                dynamicTableHeader.classList.add(...tableHeaderClasses);
                return;
            }

            if (tableType === 'alerts') {
                const alertsHeaderWrapper = document.querySelector('.alerts-table-header__wrapper');
                const alertsHeader = document.querySelector('.alerts-table-header');
                const alertsBody = document.querySelector('.alerts-table-body');

                if (!alertsHeader || !alertsHeaderWrapper || !alertsBody) {
                    return;
                }

                if (tableWrap.getBoundingClientRect().top > 0) {
                    alertsHeaderWrapper.style.width = 'auto';
                    alertsHeader.style.width = 'auto';
                    alertsHeaderWrapper.classList.remove('alerts-table-header__wrapper_fixed');
                    alertsBody.style.paddingTop = '0';
                    return;
                }

                // 3 - alert markers
                alertsHeaderWrapper.style.width = `${tableWrap.clientWidth - 3}px`;
                alertsHeader.style.width = `${alertsBody.clientWidth}px`;
                alertsHeaderWrapper.classList.add('alerts-table-header__wrapper_fixed');
                alertsBody.style.paddingTop = `${alertsHeader.clientHeight}px`;
                return;
            }

        },
        // add shadows to rows if dynamic table gets horizontal scroll
        addShadowsToTable() {
            const staticHeaderRow = document.querySelector('.keywords-table_static .header-row');
            const headerCheckbox = document.querySelector('.column-header_checkbox');
            const virtualStatic = document.getElementById('virtual-static')
            const virtualDynamic = document.getElementById('virtual-dynamic')

            if (!staticHeaderRow || !headerCheckbox || !virtualStatic || !virtualDynamic) {
                return false;
            }

            switch (this.dynamicShadow()) {
                case 'both':
                    staticHeaderRow.classList.add('header-row_with-shadow');
                    headerCheckbox.classList.add('column-header_with-shadow');
                    virtualStatic.classList.add('virtual-list_shadow');
                    virtualDynamic.classList.add('virtual-list_shadow');
                    break;
                case 'none':
                    staticHeaderRow.classList.remove('header-row_with-shadow');
                    headerCheckbox.classList.remove('column-header_with-shadow');
                    virtualStatic.classList.remove('virtual-list_shadow');
                    virtualDynamic.classList.remove('virtual-list_shadow');
                    break;
                case 'left':
                    staticHeaderRow.classList.add('header-row_with-shadow');
                    headerCheckbox.classList.remove('column-header_with-shadow');
                    virtualStatic.classList.add('virtual-list_shadow');
                    virtualDynamic.classList.remove('virtual-list_shadow');
                    break;
                case 'right':
                    staticHeaderRow.classList.remove('header-row_with-shadow');
                    headerCheckbox.classList.add('column-header_with-shadow');
                    virtualStatic.classList.remove('virtual-list_shadow');
                    virtualDynamic.classList.add('virtual-list_shadow');
                    break;
            }
        },
        /**
         * @description shows scroll status of dynamic part of the table
         * @returns {string} position of the shadow
         */
        dynamicShadow() {
            const dynTable = document.querySelector('.scroll-area');
            const dynHeaderRow = document.querySelector('.keywords-table_dynamic .header-row');

            if (!dynTable || !dynHeaderRow) {
                return 'both';
            }

            if (dynHeaderRow.scrollWidth === dynTable.clientWidth) {
                return 'none';
            }

            if (dynTable.scrollLeft < 1) {
                return 'right';
            }

            if (Math.round(dynTable.clientWidth + dynTable.scrollLeft) === dynHeaderRow.scrollWidth) {
                return 'left';
            }

            return 'both';
        },
        keywordsTableScrollHandler() {
            const mainPage = document.querySelector('.main-page');
            const scrolled = document.documentElement.scrollTop + window.innerHeight;
            const currentProject = this.$store.getters.getCurrentProject

            if (
                mainPage &&
                currentProject &&
                this.$store.getters.getLazyLoadMode &&
                !this.$store.getters.getLazyLoadDone &&
                !this.$store.getters.getLazyLoadFetching &&
                !this.$store.getters.getKeywordsIsFetching &&
                scrolled >= mainPage.offsetHeight - 800
            ) {
                this.$store.dispatch('fetchKeywords', {
                    id: currentProject.id,
                    page: this.$store.getters.getCurrentPage + 1
                });
            }
        },
        alertsTableScrollHandler() {
            const tableWrapper = document.querySelector('.alerts-table__wrapper');
            const table = document.querySelector('.alerts-table');
            const tableHeaderWrapper = document.querySelector('.alerts-table-header__wrapper');

            if (!table) {
                return;
            }

            // sync body and header scroll offset
            if (tableHeaderWrapper) {
                tableHeaderWrapper.scrollLeft = tableWrapper.scrollLeft;
            }

            // 3 - alert markers
            if (Math.round(table.scrollWidth) + 3 === tableWrapper.clientWidth) {
                this.leftAlertsShadow(false, tableWrapper.getBoundingClientRect());
                this.rightAlertsShadow(false, tableWrapper.getBoundingClientRect());
                return;
            }

            if (tableWrapper.scrollLeft < 1) {
                this.leftAlertsShadow(false, tableWrapper.getBoundingClientRect());
                this.rightAlertsShadow(true, tableWrapper.getBoundingClientRect());
                return;
            }

            if (tableWrapper.clientWidth + tableWrapper.scrollLeft >= table.scrollWidth) {
                this.leftAlertsShadow(true, tableWrapper.getBoundingClientRect());
                this.rightAlertsShadow(false, tableWrapper.getBoundingClientRect());
                return;
            }

            this.leftAlertsShadow(true, tableWrapper.getBoundingClientRect());
            this.rightAlertsShadow(true, tableWrapper.getBoundingClientRect());
        },
        leftAlertsShadow(show, { left, top, height }) {
            const shadow = document.querySelector('.alerts-table__shadow-left');

            if (show) {
                shadow.style.display = 'block';
                shadow.style.left = `${left}px`;
                shadow.style.top = `${top}px`;
                // height of scrollbar
                shadow.style.height = `${height - 6}px`;
            } else {
                shadow.style.display = 'none';
            }
        },
        rightAlertsShadow(show, { left, height, top, width }) {
            const shadow = document.querySelector('.alerts-table__shadow-right');

            if (show) {
                shadow.style.display = 'block';
                shadow.style.left = `${left + width - 8}px`;
                shadow.style.top = `${top}px`;
                shadow.style.height = `${height - 6}px`;
            } else {
                shadow.style.display = 'none';
            }
        },
        setHoverState(id) {
            let hoverTimeout;

            hoverTimeout = setTimeout(() => {
                clearTimeout(hoverTimeout);

                try {
                    Array.from(document.querySelectorAll('.table-row__inner'))
                        .forEach(el => el.classList.remove('table-row__inner_hovered'));

                    document
                        .getElementById(`row-static-${id}`)
                        .classList.add('table-row__inner_hovered');

                    document
                        .getElementById(`row-dynamic-${id}`)
                        .classList.add('table-row__inner_hovered');
                } catch (e) {
                    //
                }
            }, 25);
        },
        removeHoverState(id) {
            setTimeout(() => {
                try {
                    document
                        .getElementById(`row-static-${id}`)
                        .classList.remove('table-row__inner_hovered');

                    document
                        .getElementById(`row-dynamic-${id}`)
                        .classList.remove('table-row__inner_hovered');
                } catch (e) {
                    //
                }
            }, 25);
        },
        tableRowInnerClasses(i, checked = false) {
            return {
                'table-row__inner': true,
                'table-row__inner_even': i % 2,
                'table-row__inner_checked': checked,
            };
        },
        /**
         * @description method that handles single checkbox selection (click) on the dashboard
         * @param {string} id id of project/keyword
         * @param {string} type type of dashboard (overview or single project)
         * @param {Event} e event of click
         * @param {number} index row index
         * @param {Array} items table items
         */
        handleDashboardCheckboxClick(id, type, index, e, items) {
            const store = {
                selectedGetter: type === 'Keyword' ? 'getSelectedAuth' : 'getCheckedProjects',
                selectedAction: type === 'Keyword' ? 'toggleKeywordSelection' : 'checkProject',
                selectedItemsAction: type === 'Keyword' ? 'setSelectedKeywords' : 'setCheckedGroups',
            };
            this.applyShiftLogic(e, id, index, items, store);
            removeAllChecks(type);
            renderCheckboxes(type, this.$store.getters[store.selectedGetter]);
        },
        /**
         * @description method that handles single row selection (click) on the dashboard
         * @param {string} id id of project/keyword
         * @param {string} type type of dashboard (overview or single project)
         * @param {Event} e event of click
         * @param {number} index row index
         * @param {Array} items table items
         */
        handleDashboardRowSelect(id, type, index, e, items) {
            const store = {
                selectedGetter: type === 'Keyword' ? 'getSelectedAuth' : 'getCheckedProjects',
                selectedAction: type === 'Keyword' ? 'selectKeywordRow' : 'checkOnlyProject',
                selectedItemsAction: type === 'Keyword' ? 'setSelectedKeywords' : 'setCheckedGroups',
            };

            this.applyShiftLogic(e, id, index, items, store);
            removeAllChecks(type);
            renderCheckboxes(type, this.$store.getters[store.selectedGetter]);
        },
        applyShiftLogic(e, id, index, items, { selectedGetter, selectedAction, selectedItemsAction }) {
            let selectedCheckedFromStore;

            if (!e.shiftKey || !this.startCheck) {
                this.$store.dispatch(selectedAction, id);
                if (this.$store.getters[selectedGetter].includes(id)) {
                    this.startCheck = { index, id };
                }

                if (this.checksRange.length) {
                    this.checksRange = [];
                }
            } else {
                this.endCheck = { index, id };
                const [start, end] = [this.startCheck.index, this.endCheck.index].sort((a, b) => a - b);

                selectedCheckedFromStore = this.checksRange.length
                    ? _.xor(this.$store.getters[selectedGetter], this.checksRange)
                    : [...this.$store.getters[selectedGetter]];

                this.checksRange = items.reduce((acc, cur, i) => {
                    if (i <= end && i >= start) {
                        acc.push(cur.id);
                    }

                    return acc;
                }, []);

                selectedCheckedFromStore = _.union(selectedCheckedFromStore, this.checksRange);
                this.$store.dispatch(selectedItemsAction, selectedCheckedFromStore);
            }
        },
        /**
         * @description toggle view and store parts of selected items of dashboard
         * @param {string} type type of dashboard (overview or single project)
         */
        toggleAllDashboardCheckboxes(type) {
            const hyphen = getHyphen(type);
            const selectAction = type === 'Keyword' ? 'setSelectedKeywords' : 'setCheckedGroups';
            const selectAllAction = type === 'Keyword' ? 'selectAllKeywords' : 'checkAllProjects';
            hyphen.classList.toggle('hyphen_active');

            !hyphen.classList.contains('hyphen_active')
                ? this.$nextTick(() => this.$store.dispatch(selectAction, []))
                : this.$nextTick(() => this.$store.dispatch(selectAllAction));

            this.startCheck = null;
            this.endCheck = null;
            this.checksRange = [];

            switch (type) {
                case 'ProjectsOverview_v2':
                case 'Keyword':
                    if (!hyphen.classList.contains('hyphen_active')) {
                        document
                            .querySelectorAll('.row-wrap .checkbox_active')
                            .forEach(el => el.classList.remove('checkbox_active'));

                        document
                            .querySelectorAll('.table-row__inner.table-row__inner_checked')
                            .forEach(el => el.classList.remove('table-row__inner_checked'));
                    } else {
                        document
                            .querySelectorAll('.row-wrap .checkbox')
                            .forEach(el => el.classList.add('checkbox_active'));

                        document
                            .querySelectorAll('.table-row__inner')
                            .forEach(el => el.classList.add('table-row__inner_checked'));
                    }

                    break;

            }
        },
        // table checkboxes methods
        setCheckboxesOnUpdate(type, selectedItemsGetter) {
            const hyphen = getHyphen(type);

            if (!hyphen) {
                return;
            }
            removeAllChecks(type);

            if (this.$store.getters[selectedItemsGetter] && !this.$store.getters[selectedItemsGetter].length) {
                hyphen.classList.remove('hyphen_active');
                return;
            }

            switch (type) {
                case 'ProjectsOverview_v2':
                    this.$store.getters[selectedItemsGetter].forEach(el => {
                        const projectsOverviewCheckbox = document.getElementById(`${type}-${el}`);
                        if (projectsOverviewCheckbox) {
                            projectsOverviewCheckbox.classList.add('checkbox_active');
                        }
                    })
                    break;
                case 'Keyword':
                    this.$store.getters[selectedItemsGetter].forEach(el => {
                        const keywordCheckbox = document.getElementById(`Keyword-${el}`);
                        const keywordStaticRow = document.getElementById(`row-static-${el}`);
                        const keywordDynamicRow = document.getElementById(`row-dynamic-${el}`);

                        if (!keywordCheckbox || !keywordStaticRow || !keywordDynamicRow) {
                            return;
                        }

                        keywordCheckbox.classList.add('checkbox_active');
                        keywordStaticRow.classList.add('table-row__inner_checked');
                        keywordDynamicRow.classList.add('table-row__inner_checked');
                    });

                    break;

            }

            hyphen.classList.add('hyphen_active');
        },
        triggerScroll() {
            window.scrollTo(window.scrollX, window.scrollY + 1);
            window.scrollTo(window.scrollX, window.scrollY - 1);
        }
    },
};
