<template>
    <div class="deleted-suggestions-modal" data-cy="deleted-suggestions-modal">
        <closer @click="close" />
        <div
            class="custom-modal__header custom-modal_draggable"
            data-cy="deleted-suggestions-modal-header"
        >
            {{ $t("removed-keyword-suggestions") }}
        </div>
        <div
            class="custom-modal__secondary__header custom-modal_draggable"
            data-cy="deleted-suggestions-modal-secondary-header"
        >
            <kw-button
                class="restore-suggestions-button"
                prepend-icon-class="kw kw-x-circle bold-icon"
                :data-important-tooltip="$t('restore-keyword-suggestions')"
                :loading="getGSCSuggestionsRestoring"
                button-type="outline-danger"
                @click="restoreSuggestions"
            />
            <span @click="restoreSuggestions">
                {{ $t("restore-keyword-suggestions") }}
            </span>
        </div>
        <div
            class="custom-modal__body custom-modal__body_export"
            data-cy="deleted-suggestions-modal-body"
        >
            <div class="select-all-checkbox">
                <checkbox
                    :icon-class="getCheckBoxIconClass"
                    :checkbox="partialSelected"
                    @checkbox-click="selectAll"
                />
                <span @click="selectAll">
                    {{ $t("keyword") }}
                </span>
            </div>
            <div class="removed-keyword-suggestions-body custom-scroll">
                <div
                    v-for="(suggestion, i) in deletedSuggestions"
                    :key="i"
                    class="deleted-suggestion"
                    @click.exact="selectOnly(suggestion.id)"
                    @click.ctrl="select(suggestion.id)"
                >
                    <checkbox
                        :checkbox="isSelected(suggestion.id)"
                        @checkbox-click="select(suggestion.id)"
                    />
                    {{ suggestion.keyword }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "GSCDeletedSuggestionsModal",
    components: {},
    props: {
        deletedSuggestions: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters(["getGSCSuggestionsRestoring"]),
        allSelected() {
            return (
                this.deletedSuggestions.length ===
                this.selectedDeletedSuggestions.length
            );
        },
        partialSelected() {
            return !!this.selectedDeletedSuggestions.length;
        },
        getCheckBoxIconClass() {
            return this.allSelected ? "kw kw-check" : "kw kw-minus";
        },
    },
    data() {
        return {
            selectedDeletedSuggestions: [],
        };
    },
    methods: {
        ...mapActions(["restoreGSCSuggestions"]),
        close() {
            this.$emit("close");
        },
        isSelected(suggestion) {
            return this.selectedDeletedSuggestions.includes(suggestion);
        },
        selectAll() {
            this.selectedDeletedSuggestions = this.allSelected
                ? []
                : this.deletedSuggestions.map((a) => a["id"]);
        },
        select(suggestion) {
            if (this.isSelected(suggestion)) {
                this.selectedDeletedSuggestions =
                    this.selectedDeletedSuggestions.filter(
                        (item) => item !== suggestion
                    );
            } else {
                this.selectedDeletedSuggestions.push(suggestion);
            }
        },
        selectOnly(suggestion) {
            this.selectedDeletedSuggestions =
                this.isSelected(suggestion) &&
                this.selectedDeletedSuggestions.length === 1
                    ? []
                    : [suggestion];
        },
        async restoreSuggestions() {
            if (this.selectedDeletedSuggestions.length) {
                try {
                    await this.restoreGSCSuggestions(
                        this.selectedDeletedSuggestions
                    );
                } catch (error) {
                    this.$toastr.e(error);
                } finally {
                    this.selectedDeletedSuggestions = [];
                    this.close();
                }
            } else {
                this.$toastr.e(this.$t("keywords-not-selected"));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~sass/modal";

.deleted-suggestions-modal {
    padding: 24px 16px;

    /deep/ .button-closer {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 13px;
    }

    .custom-modal__header {
        padding: 0 16px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #17181b;
    }

    .custom-modal__secondary__header {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: #17181b;
        display: flex;
        align-items: center;
        padding-left: 8px;

        .restore-suggestions-button {
            position: relative;

            /deep/ .bold-icon {
                font-weight: 600;
            }

            /deep/ .button-loader {
                width: 16px !important;
                min-width: 16px !important;
                height: 16px !important;
            }
        }
    }

    .custom-modal__body {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #17181b;

        .select-all-checkbox {
            position: relative;
            padding-left: 24px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;

            span {
                cursor: pointer;
            }
        }

        .removed-keyword-suggestions-body {
            overflow: hidden;
            overflow-y: scroll;
            max-height: 350px;
            border-top: 1px solid #dee5ec;

            .deleted-suggestion {
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                padding-left: 24px;
                margin-top: 8px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: kw-color(kw-blue, 1);
            }
        }
    }
}
</style>

<style lang="scss">
#rules-autoAdd-checkbox {
    position: unset;

    span {
        position: unset;
        margin-left: 3px;
        margin-top: -1px;
    }
}
#device {
    .kw {
        color: kw-color(kw-blue, 1);
    }

    .dropdown-menu {
        .kw {
            color: kw-color(kw-black, 1);
        }
    }

    .selectedOption {
        .kw {
            color: kw-color(kw-white, 1);
        }
    }
}
</style>
