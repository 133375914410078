<template>
    <div class="overview">
        <div
            v-for="item in chartsData"
            :key="item.name"
            :class="['chart-block', item.name, target===item.name ? 'active' : '']"
            @dragenter="onDragOver(item.name)"
            @dragover.prevent
            @drop.prevent="onDrop($event, item.name)" 
        >
            <div
                class="inner"
                :draggable="isDraggable"
                @dragstart="onDragStart($event, item.name)"
                @dragend="onDragEnd"
                @dragenter.prevent
            >
                <span 
                    @mousedown="setDraggable(true)"
                    @mouseup="setDraggable(false)"
                    class="target kw kw-drag" />
                    
                <component :is="`${item.name}-block`" />
            </div>
        </div>

        <div @dragenter="onDragOver('last-item')" :class="['chart-block', 'last-item', target==='last-item' ? 'active' : '']" >
            <div
                @drop.prevent="onDropLast($event)" 
                @dragenter.prevent
                @dragover.prevent
                class="inner"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ShareOfVoiceBlock from './Charts/ShareOfVoiceBlock.vue';
    import KeywordsInTopBlock from './Charts/KeywordsInTopBlock.vue';
    import ProjectMetricsBlock from './Charts/ProjectMetricsBlock.vue';
    import VisibilityBlock from './Charts/VisibilityBlock.vue';
    import EstimatedTrafficBlock from './Charts/EstimatedTrafficBlock.vue';
    import TopPagesBlock from './Charts/TopPagesBlock.vue';
    import OrganicTrafficAcquaisitionBlock from './Charts/OrganicTrafficAcquaisitionBlock.vue';
    import SerpFeaturePerformanceBlock from './Charts/SerpFeaturePerformanceBlock.vue';
    import KeywordsByIntentBlock from './Charts/KeywordsByIntentBlock.vue';
    import AverageRankBlock from './Charts/AverageRankBlock.vue';
    import DistributionBlock from './Charts/DistributionBlock.vue';

    export default {
        name: 'ProjectOverview',
        components: {
            'sov-block': ShareOfVoiceBlock,
            'keywords_in_top-block': KeywordsInTopBlock,
            'project_metrics-block': ProjectMetricsBlock,
            'visibility-block': VisibilityBlock,
            'estimated_traffic-block': EstimatedTrafficBlock,
            'top_pages-block': TopPagesBlock,
            'organic_traffic_acquisition-block': OrganicTrafficAcquaisitionBlock,
            'serp_feature_performance-block': SerpFeaturePerformanceBlock,
            'keywords_by_intent-block': KeywordsByIntentBlock,
            'average_rank-block': AverageRankBlock,
            'distribution-block': DistributionBlock,
        },
        data(){
            return {
                target: null,
                isDraggable: false,
                dragImageRef: null
            }
        },
        computed: {
            ...mapGetters([
                'getProjectOverviewOptions',
                'getCurrentProject'
            ]),
            chartsData(){
                return Object.entries(this.getProjectOverviewOptions)
                    .filter(([_, value]) => value.visible)
                    .sort((a, b) => a[1].order - b[1].order)
                    .map(([key, value]) => ({ name: key, ...value }));  
            }
        },
        methods: {
            ...mapActions([
                'setProjectOverviewOptionOrder',
            ]),
            onDragStart(e, name) {
                e.dataTransfer.setData("name", name);
                const dragImage = e.target.cloneNode(true);
                dragImage.classList.add('current-draggable');

                document.body.appendChild(dragImage);
                e.dataTransfer.setDragImage(dragImage, 0, 0);
                // document.addEventListener('dragover', this.followCursor);

                this.dragImageRef = dragImage;
            },
            onDragEnd(){
                this.setDraggable(false);
                // document.removeEventListener('dragover', this.followCursor);
                if(this.dragImageRef){
                    document.body.removeChild(this.dragImageRef);
                }
            },
            // followCursor(e) {
            //     if(this.dragImageRef){
            //         this.dragImageRef.style.left = `${e.pageX}px`;
            //         this.dragImageRef.style.top = `${e.pageY}px`;
            //     }
            // },
            setDraggable(val){
                this.isDraggable = val;

                if (this.target) {
                    this.target = null;
                }
            },
            onDrop(e, name){
                if (!this.isDraggable) return;
                
                setTimeout(()=>{ window.dispatchEvent(new Event('resize')) }, 100);
                
                const dragEl = e.dataTransfer.getData("name");
                this.setProjectOverviewOptionOrder({
                    order: this.getProjectOverviewOptions[name].order,
                    name: dragEl,
                    projectId: this.getCurrentProject.id
                });
            },
            onDropLast(e) {
                const dragEl = e.dataTransfer.getData("name");
                this.setProjectOverviewOptionOrder({
                    order: 9,
                    name: dragEl,
                    projectId: this.getCurrentProject.id
                });
            },
            onDragOver(name){
                if (!this.isDraggable) return;

                this.target = name;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .overview {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        min-height: 100vh;
        
        .chart-block {
            position: relative;
            flex: 1;
            min-width: calc(50% - 16px);
            border-radius: 4px;
            border: 1px solid kw-color(kw-light-grey, 1);
            min-height: 300px;
            max-height: 520px;
            background-color: kw-color(kw-white, 1);

            &.active {
               &::after{
                position: absolute;
                top: 0;
                left: -10px;
                height: 100%;
                width: 2px;
                background: rgba(23, 107, 250, 0.37);
                content: '';
               }
            }
            
            &.last-item {
                min-height: 80px;
                border: none;
                background: none;
                &.active {
                    &::after{
                        position: absolute;
                        top: -10px;
                        left: 0;
                        height: 2px;
                        width: 200%;
                        background: rgba(23, 107, 250, 0.37);
                        content: '';
                    }
                }
            }

            &.sov {
                min-width: 100%;
            }
            
            &.serp_feature_performance {
                max-height: unset;
            }

            @media screen and (max-width: 959px){
                min-width: 100%;
                max-height: unset;
            }


            .inner {
                width: 100%;
                height: 100%;
                padding: 24px;

                & > img {
                    width: 100%;
                    height: 100%;
                    max-height: 300px;
                    object-fit: contain;
                }
            }

            @media screen and (max-width: 500px) {
                .target {
                    display: none;
                }
                /deep/ .block-wrapper{
                    .header-wrapper {
                        padding-left: 0;
                    }
                }
            }
            /deep/ .project-overview__chart {
                overflow: visible !important;
            }
        }  
    }
    .inner {
        min-width: 50%;
        .target {
            position: absolute;
            display: inline-block;
            top: 29px;
            left: 24px;
            width: 20px;
            height: 30px;
            cursor: grab;
            color: kw-color(kw-light-grey, 8);
            z-index: 9;
        }

        /deep/ .block-wrapper{
            .header-wrapper {
                padding-left: 20px;
            }
        }
    }
    .current-draggable {
        position: absolute;
        pointer-events: none;
        opacity: 0.8;
        background: kw-color(kw-white, 1);
        overflow: hidden;
        padding: 24px;
        border-radius: 4px;
        border: 1px solid kw-color(kw-light-grey, 1);
    }
</style>
