import store from '@/store';
import mixpanel from 'mixpanel-browser';
import { cookieGetters } from '@/helpers/cookieManager';
import { mixpanelEvents, Events, EventBus } from '@/events';

export function init() {
    if (process.env.MIXPANEL_PROJECT_TOKEN) {
        Object.keys(mixpanelEvents.NOT_SENSITIVE).map(
            key => EventBus.on(
                Events[key],
                data => {
                    let eventData = {};
                    mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN,
                        {
                            debug: process.env.NODE_ENV !== 'production',
                            track_pageview: false,
                            persistence: 'localStorage'
                        }
                    );
                    mixpanel.identify(cookieGetters.getMixpanelId());

                    switch (key) {
                        case 'ACCOUNT_SIGNUP_STARTED':
                            mixpanel.people.set({ "$email": data.email });
                            eventData = {
                                'signup_cta': data.signup_cta,
                                'signup_location_url': data.signup_location_url,
                                'subscription_plan': data.subscription_plan,
                                'selected_refresh_cycle': data.selected_refresh_cycle,
                                'selected_num_keywords': data.selected_num_keywords,
                            };
                            break;
                        case 'ACCOUNT_SIGNUP_FAILED':
                            eventData = {
                                'signup_method': data.signup_method,
                                'error_message': data.error_message,
                            };
                            break;
                        default:
                            break;
                    }

                    mixpanel.track(mixpanelEvents.NOT_SENSITIVE[key], eventData);
                }
            )
        );

        // delay for some events
        let stopEvent = [];

        Object.keys(mixpanelEvents.SENSITIVE).map(
            key => EventBus.on(
                Events[key],
                data => {
                    let eventData = {};
                    let stopDuration = false;
                    if (stopEvent[key]) {
                        return;
                    }

                    switch (key) {
                        case 'IMPORT_MAPPING_VIEW_EVENT':
                            eventData = {
                                num_keywords: data.num_keywords,
                                fields_filled: data.fields_filled,
                            };
                            break;
                        case 'IMPORT_MAPPING_ERROR_EVENT':
                            eventData = {
                                error_message: data.error_message
                            };
                            break;
                        case 'IMPORT_FINISH_VIEW_EVENT':
                            eventData = {
                                num_keywords: data.num_keywords,
                            };
                            break;
                        case 'IMPORT_ERROR_EVENT':
                            eventData = {
                                num_keywords: data.num_keywords,
                                error_message: data.error_message,
                            };
                            break;

                        case 'PACKAGES_VIEW_EVENT':
                            eventData = {
                                cta_location_source: data.cta_location_source,
                            };
                            break;
                        case 'PLAN_SELECTED_EVENT':
                            eventData = {
                                selected_plan: data.selected_plan,
                                selected_term: data.selected_cycle,
                                selected_num_keywords: data.selected_num_keywords,
                            };
                            break;
                        case 'CYCLE_CHANGED_EVENT':
                            eventData = {
                                refresh_cycle: data.refresh_cycle,
                            };
                            break;
                        case 'KEYWORDS_CHANGED_EVENT':
                            eventData = {
                                keyword_package: data.keyword_package,
                            };
                            break;

                        case 'PROJECT_ADD_INITIATED_EVENT':
                            eventData = {
                                project_source: data.project_source,
                            };
                            break;
                        case 'DASHBOARD_VIEW_EVENT':
                            eventData = {
                                num_projects_shown: data.num_projects_shown,
                            };
                            break;
                        case 'PROJECT_VIEW_EVENT':
                            eventData = {
                                num_keywords_in_project: data.num_keywords_in_project,
                                tag_view: data.tag_view,
                            };
                            break;

                        case 'ADD_KEYWORD_GOOGLE_VIEW_EVENT':
                        case 'ADD_KEYWORD_MAPS_VIEW_EVENT':
                            eventData = {
                                project_name: data.project_name,
                                leading_source: data.leading_source,
                            };
                            break;
                        case 'KEYWORDS_LISTED_EVENT':
                            stopDuration = 20000;
                            eventData = {
                                num_keywords: data.num_keywords,
                                num_keywords_suggested: data.num_keywords_suggested,
                                bulk_import_enabled: data.bulk_import_enabled,
                                leading_source: data.leading_source,
                            };
                            break;
                        case 'KEYWORDS_SUGGESTED_ADDED_EVENT':
                        case 'KEYWORDS_SUGGESTED_ADDED_ALL_EVENT':
                            stopDuration = 20000;
                            eventData = {
                                num_keywords: data.num_keywords,
                                keyword_type: data.keyword_type,
                                leading_source: data.leading_source,
                            };
                            break;

                        case 'VIEWKEY_COPIED_EVENT':
                        case 'EMBEDDED_COPIED_EVENT':
                            eventData = {
                                project_name: data.project_name,
                            };
                            break;

                        case 'SERP_VIEW_EVENT':
                            eventData = {
                                empty_view: data.empty_view,
                            };
                            break;

                        case 'IMPORT_CSV_UPLOAD_VIEW_EVENT':
                            eventData = {
                                leading_source: data.leading_source,
                            };
                            break;

                        case 'SERP_COMPETITORS_VIEW_EVENT':
                        case 'SERP_HISTORY_VIEW_EVENT':
                        case 'ADD_KEYWORD_ONBOARDING_VIEW_EVENT':
                        case 'SUBSCRIPTION_FAILED_EVENT':
                        default:
                            break;
                    }

                    store.dispatch('trackMixpanelEvent', {
                        event_name: mixpanelEvents.SENSITIVE[key],
                        event_data: eventData,
                    });

                    if (stopDuration) {
                        stopEvent[key] = true;
                        setTimeout(() => {
                            delete stopEvent[key];
                        }, stopDuration);
                    }
                }
            )
        );
    }
};
