import axios from 'axios';
import {cookieGetters} from '@/helpers/cookieManager';
import interceptors from './interceptors';

const apiToken = cookieGetters.getApiKey() || '';
const globalInterceptor = interceptors.get('global');
const baseURL = process.env.NODE_ENV === 'development'
    ? localStorage.getItem('axiosBaseURL') || process.env.API_V2_BASE_URL
    : process.env.API_V2_BASE_URL;

const client = axios.create({
    baseURL,
    headers: {
        ['Accept']: 'application/vnd.api+json',
        ['Content-Type']: 'application/vnd.api+json',
    },
    params: {
        ['api_token']: apiToken,
    },
});

client.interceptors.response.use(
    response => response,
    globalInterceptor.e,
);

export const legacyClient = axios.create({
    baseURL: process.env.API_LOGIN_LOGOUT_URL,
    params: {
        ['auth']: apiToken,
    },
});

export function setApiToken (token) {
    client.defaults.params.api_token = token;
    legacyClient.defaults.params.auth = token;
}

export default client;