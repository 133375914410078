import guards from '../guards';

import addKeywords from 'pages/add-keywords/AddKeywords';
import AppHeader from 'components/app-header/AppHeader';
import mainBlock from 'pages/project/ProjectPage';
import Overview from 'pages/overview/Overview';
import ShareViewkey from 'pages/share-viewkey/ShareViewkey';

export default [
    {
        path: '/projects/:id',
        name: 'keywordList',
        beforeEnter: guards('keywordList'),
        meta: {requiresSubscription: true},
        components: {
            AppHeader,
            mainBlock,
        },
    },
    {
        path: '/projects/:id/add',
        name: 'addKeywords',
        meta: {requiresSubscription: true},
        components: {
            AppHeader,
            addKeywords,
        },
    },
    {
        path: '/dashboard',
        name: 'Overview',
        beforeEnter: guards('overview'),
        meta: {requiresSubscription: true},
        components: {
            AppHeader,
            Overview,
        },
    },
    {
        path: '/projects/:viewkey/:auth/:client?',
        name: 'shareViewkey',
        components: {
            shareViewkey: ShareViewkey,
        },
        props: {
            shareViewkey: route => ({
                auth: route.params.auth,
                client: route.params.client === 'client',
                viewkey: route.params.viewkey,
            })
        }
    },
    {
        path: '/share-view/:viewkey/:hash/:client?',
        name: 'shareViewkeyWithHash',
        components: {
            shareViewkey: ShareViewkey,
        },
        props: {
            shareViewkey: route => ({
                hash: route.params.hash,
                client: route.params.client === 'client',
                viewkey: route.params.viewkey,
            })
        }
    },
    // old route for share viewkey - redirects to new one
    {
        path: '/share_viewkey',
        redirect: ({query}) => ({
            path: `/projects/${query.viewkey}/${query.auth}${'client' in query ? '/client' : ''}`,
            query: '',
        }),
    },
    // old route for share viewkey - redirects to new one
    {
        path: '/project/:viewkey/:auth/:client?',
        redirect: ({params}) => ({
            path: `/projects/${params.viewkey}/${params.auth}${params.client ? '/client' : ''}`,
            query: '',
        }),
    },
];