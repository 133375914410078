import {legacyClient} from '../client';
import interceptors from '../interceptors';
const legacyInterceptor = interceptors.get('legacy');

legacyClient.interceptors.response.use(
    legacyInterceptor.s,
    legacyInterceptor.e,
);

export default {
    login: async ({username, password}) => await legacyClient.get(`?action=authorize&email=${username}&password=${password}`, { withCredentials: true }),
    logout: async () => await legacyClient.get('?action=logout', { withCredentials: true }),
    addReport: async payload => await legacyClient.post('?action=addschedulereport', payload),
    deleteReport: async payload => await legacyClient.post('?action=deletescheduledemailreports', payload),
    addWhitelabelDomain: async domain => await legacyClient.post(`?action=addemaildomain&domain=${domain}`),
    deleteWhitelabelDomain: async domain => await legacyClient.post(`?action=deleteemaildomain&domain=${domain}`),
    verifyWhitelabelDomain: async domain => await legacyClient.post(`?action=verifyemaildomain&domain=${domain}`),
    resetApiKey: async () => await legacyClient.get(`?viewkey=resetviewkey`),
};
