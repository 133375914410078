<template functional>
    <div class="top-pages-table-body">
        <div
            v-for="(item, i) in props.items"
            :key="i"
            class="top-pages-table-row"
            :class="{ 'top-pages-table-row-even': i % 2 }"
        >
            <div
                v-for="field in props.fields"
                :key="field.name"
                class="top-pages-table-cell body"
                :class="`${field.class}`"
                :style="{ width: field.width + 'px' }"
            >
                <div
                    v-if="field.name == 'current_ranking_url'"
                    class="url-field"
                    :data-important-tooltip="item[field.name]"
                    @click="parent.openModal(item[field.name])"
                >
                    <span class="kw kw-eye"></span>
                    <div :data-important-tooltip="item[field.name]">
                        {{ item[field.name] }}
                    </div>
                </div>
                <template v-else-if="field.name == 'keyword'">
                    <i :class="`country-${item['region'].split('.')[1]}`"></i>
                    <span
                        @click="
                            parent.openModal(
                                item['current_ranking_url'],
                                item[field.name],
                            )
                        "
                    >
                        {{ item[field.name] }}
                    </span>
                </template>
                <div
                    v-else
                    v-html="$options.getCell(item, field.name, parent)"
                    class="rank-cell"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopPagesTableBody',
    props: {
        items: {
            type: Array,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
    },
    getCell(item, fieldName, parent) {
        switch (fieldName) {
            case 'traffic':
            case 'total_keyword_count':
            case 'search_volume':
            case 'total_search_volume':
                return new Intl.NumberFormat('us').format(item[fieldName]);
            case 'current_rank':
            case 'average_current_rank':
                let previousFieldName =
                    fieldName == 'average_current_rank'
                        ? 'average_previous_rank'
                        : 'previous_rank';
                return `${
                    item[fieldName] != item[previousFieldName]
                        ? `<span class="icon-arrow_4 ${
                              item[fieldName] > item[previousFieldName]
                                  ? 'icon-rotate-down'
                                  : ''
                          }"></span>`
                        : ''
                }<div class="rank">${parseInt(item[fieldName])}</div>`;
            case 'cpc':
            case 'average_cost_per_click':
                return item[fieldName] === null
                    ? `<span data-important-tooltip="${parent.$t(
                          'refreshing',
                      )}">Updating</span>`
                    : item[fieldName] === 0
                    ? `<span data-important-tooltip="${parent.$t(
                          'not-changed-data',
                      )}" class="empty-value">&mdash;</span>`
                    : `${parent.getCurrentCurrency.symbol} ` +
                      item[fieldName].toFixed(2);
            default:
                return item[fieldName];
        }
    },
};
</script>

<style lang="scss" scoped>
.top-pages-table-body {
    .top-pages-table-row {
        display: flex;
        min-width: fit-content;

        &.top-pages-table-row-even {
            background-color: #f7f8fa;
        }

        &:hover {
            background-color: #e5ecf3;
        }

        .top-pages-table-cell {
            display: flex;
            flex: 1 0 auto;
            align-items: center;
            overflow: hidden;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: 33px;
            padding-left: 8px;
            text-align: right;

            @media (max-width: 959px) {
                height: 24px;
            }

            &.keyword,
            &.current_ranking_url {
                text-align: left;
                min-width: unset;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                div {
                    width: 100%;

                    div {
                        text-overflow: ellipsis;
                        width: calc(100% - 14px);
                        overflow: hidden;
                    }
                }
            }

            .url-field {
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                .kw-eye {
                    color: kw-color(kw-blue, 1);
                    font-weight: 900;
                }
            }

            &.traffic {
                color: kw-color(kw-grey, 1);
            }

            &.total_keyword_count {
                color: kw-color(kw-black, 1);
                font-weight: 500;
            }

            &.current_rank,
            &.average_current_rank {
                color: #333;

                /deep/ .icon-arrow_4 {
                    font-size: 10px;
                    line-height: 16px;
                    width: 9px;
                    color: kw-color(kw-green, 1);

                    &.icon-rotate-down {
                        color: kw-color(kw-red, 1);
                    }
                }

                /deep/ .rank {
                    display: inline;
                    color: #333;
                }
            }
        }
        .rank-cell{
            display: inline-flex;
            gap: 5px;
        }
    }
}
</style>
