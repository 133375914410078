<template>
    <div
        class="dashboard-block"
        data-cy="keywords-up-box"
    >
        <div
            class="dash-total"
            data-cy="keywords-up-body"
        >
            <template v-if="!loading">
                <div class="count">
                    {{ activeData.up ? Math.abs(activeData.up) : '-' }}
                </div>
            </template>
            <placeholder-cell
                v-else
                :width="80"
                :height="14"
            />
            <div class="dash-title">
                {{ $t('keywords-up') }}
            </div>
        </div>
        <div
            class="change-direction"
            data-cy="keywords-up-direction"
        >
            <span
                v-if="!loading"
                class="total-up"
            >
                {{ periodTitle }}
            </span>
            <placeholder-cell
                v-else
                :width="50"
                :height="8"
            />
        </div>
    </div>
</template>

<script>
    import {DEFAULTS_METRICS} from '@/constants';
    import {mapGetters} from 'vuex';
    export default {
        name: 'MetricsBlockKeywordsChange',
        props: {
            loading: {
                type: Boolean,
                required: true,
                default: false,
            },
            metrics: {
                type: Object,
                required: true
            },
        },
        computed: {
            ...mapGetters([
                'getOriginalItems',
                'getDisplaySetting',
                'showKeywordsTotal',
                'getCurrentProject'
            ]),
            activeData () {
                if (!this.metrics || !this.metrics.movements) {
                    return {}
                }

                switch (this.getDisplaySetting('metrics')) {
                    case 'daily':
                        return this.metrics.movements.daily;
                    case 'life':
                    case 'lifetime':
                        return this.metrics.movements.life;  
                    case 'monthly':
                        return this.metrics.movements.monthly;  
                    case 'weekly':
                        return this.metrics.movements.weekly;  
                    default: 
                        return this.metrics.movements[DEFAULTS_METRICS.PROP];      
                }
            },
            periodTitle () {
                switch (this.getDisplaySetting('metrics')) {
                    case 'daily':
                        return 'for 1 day';
                    case 'life':
                    case 'lifetime':
                        return 'since start';  
                    case 'yearly':
                        return 'for 1 year';
                    case 'semester':
                        return 'for 6 months';
                    case 'monthly':
                        return 'for 30 days';  
                    case 'weekly':
                        return 'for 7 days';  
                    default: 
                        return DEFAULTS_METRICS.TITLE;     
                }
            },
        },
    }

</script>

<style lang="scss" scoped>
  .dashboard-block {
    background: kw-color(kw-white, 1);
    border: 1px solid kw-color(kw-light-grey, 1);
    border-radius: 4px;
    overflow: hidden;

    .placeholder_overview-table-cell {
      margin: 4px auto;
    }

    .dash-total {
      align-items: center;
      color: kw-color(kw-black, 1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 70px;
      padding: 3px 0 5px 0;

      .count {
        align-items: center;
        display: flex;
        font-size: 36px;
        font-weight: 500;
        line-height: 42px;
        @media (max-width: 959px) {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }

    .dash-title {
      align-items: center;
      color: kw-color(kw-grey, 1);
      display: flex;
      font-size: 14px;
      justify-content: center;
      line-height: 20px;
      text-align: center;
      @media (min-width: 960px) and (max-width: 1279px) {
        font-size: 12px;
      }
    }

    .change-direction {
      align-items: center;
      border-top: 1px solid kw-color(kw-light-grey, 1);
      color: kw-color(kw-black, 1);
      display: flex;
      font-size: 14px;
      height: 34px;
      justify-content: center;
      line-height: 15px;
      position: relative;

      .total-up {
        @media (min-width: 960px) and (max-width: 1279px) {
          font-size: 12px;
        }
      }
    }

    //responsive design
    @media screen and (max-width: 959px) {
      .dash-total {
        min-height: unset;
        padding: 1px 0 4px 0;

        .count {
          margin-top: unset;
        }
      }
      .dash-title {
        font-size: 12px;
        line-height: 14px;
      }
      .change-direction {
        font-size: 12px;
        height: 21px;
      }
    }
  }
</style>
