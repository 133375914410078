<template>
    <div class="error-plan">
        <div class="plan-content-message">
            <div class="message">{{ $t('billing-subscription-expired-on') }}</div>
        </div>
        <div class="plan-content-date">
            <div class="date">{{ date }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PlanError',
        props: {
            date: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>
  .error-plan {
    background-color: #FFECEA;
    border: 1px solid kw-color(kw-red, 1);
    padding: 20px 20px;
    height: auto;
    width: calc(100% + 2px);
    bottom: -1px;
    left: -1px;
    position: relative;
    border-radius: 0 0 4px 4px;

    .plan-content-message {
      height: auto;
      width: 207px;
      margin: 0 auto;

      .message {
        color: kw-color(kw-red, 1);
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 5px;
        text-align: center;
      }
    }

    .plan-content-date {
      height: auto;
      width: 167px;
      margin: 0 auto;

      .date {
        color: #17181B;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 5px;
        text-align: center;
      }
    }

    .plan-action {
      height: auto;
      margin: 0 auto;
      width: 240px;

      .button-component_primary {
        margin-top: 15px;
      }
    }
  }
</style>