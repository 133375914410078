export default (i18n, toastr) => error => {
    const { config, response } = error;

    if (!config || !response) {
        return Promise.reject(error);
    }

    const { data, status } = response;

    if (status === 400 &&
        config.method === 'put' &&
        config.url.includes('/import') &&
        config.url.includes('/update')
    ) {
        for (let rowIndex in data.data) {
            let row = data.data[rowIndex];
            for (let key in row.errors) {
                toastr.e(row.errors[key]);
            }
        }
    }

    if (
        (
            config.method === 'post' &&
            config.url.includes('/import') &&
            !config.url.includes('import/')
        ) || (
            config.method === 'get' &&
            config.url.includes('/validate-and-stream')
        )
    ) {
        toastr.e(i18n.t('something-went-wrong'));
    } else if (config.method === 'put' && config.url.includes('/map-headings')) {
        response.data.errors.forEach(element => {
            if (element.detail) {
                toastr.e(element.detail);
            }
        });
    }

    return Promise.reject(error);
};
