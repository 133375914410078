const convertToShort = (num) => {
    if(isNaN(num)) return '0'

    if (num >= 1000) {
      const suffixes = ["", "k", "m", "b", "t"];
      const suffixNum = Math.floor((num.toString().length - 1) / 3);
      let shortValue = parseFloat((suffixNum !== 0 ? (num / Math.pow(1000, suffixNum)) : num).toPrecision(2));
      if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    }

    return num.toString();
}

export default convertToShort;
