const defaultAlertsSort = {direction: 'desc', field: 'created_at'};
const defaultArchivedSorter = {direction: 'asc', field: 'category'};

const defaultOverviewSort = {
    direction: 'desc',
    field: 'id',
};

export {
    defaultAlertsSort,
    defaultArchivedSorter,
    defaultOverviewSort,
};