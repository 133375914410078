<template>
    <div class="refresh-label">
        <last-refreshed
            v-if="
                (!refreshRunning || refreshProgress.total === 0) &&
                lastUpdate.date
            "
            :is-refresh-gathering="getIsRefreshGathering"
            :last-update="lastUpdate"
            :isEmpty="isEmpty"
        />
        <placeholder-cell
            v-else-if="refreshProgress.remaining == 0"
            :width="170"
            :height="8"
        />
        <div v-else class="refresh-progress">
            {{ refreshProgress.total - refreshProgress.remaining }}/{{
                refreshProgress.total
            }}
            Keywords

            <div class="progress">
                <progressbar :now="progressPercent" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LastRefreshed from './LastRefreshed';
import progressbar from 'vue-strap/src/Progressbar';
export default {
    name: 'RefreshLabel',
    components: {
        'last-refreshed': LastRefreshed,
        progressbar: progressbar,
    },
    props: {
        lastUpdate: {
            type: Object,
            required: true,
        },
        refreshProgress: {
            type: Object,
            required: true,
        },
        refreshRunning: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        isEmpty: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['getIsRefreshGathering']),
        progressPercent() {
            const { remaining, total } = this.refreshProgress;
            const percentage = (100 / total) * (total - remaining);
            return !isNaN(percentage) ? percentage : false;
        },
    },
};
</script>

<style lang="scss">
.refresh-progress {
    align-items: flex-start;
    color: kw-color(kw-blue, 1);
    display: flex;
    flex-flow: column nowrap;
    font-size: 11px;
    line-height: 12px;

    .progress {
        background-color: #dee5ec;
        border-radius: 4px;
        height: 4px;
        margin-top: 4px;
        margin-bottom: 0;
        width: 140px;

        .progress-bar {
            background-color: kw-color(kw-blue, 1);
            box-shadow: none;
        }
    }
}
</style>
