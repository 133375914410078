<template>
    <div class="block-wrapper">
        <div class="header-wrapper">
            <chart-header
                :title="$t('share-of-voice-header')"
                :toolTip="$t('sov-chart-tooltip')"
                showTimeframe
                isHidable
                @onHide="onHide"
                :date="date"
                @onDateSelect="onDateSelect"
                :isHideDisabled="isLoading"
                :defaultKey="interval"
            />
            <p class="info">
                {{ infoText }}
            </p>
        </div>
        <mindshare-update-message v-if="isSovUpdatingMessageVisible" />
        <share-of-voice-chart
            showChart
            :mobileHide="false"
            :downloadable="false"
            :copyable="false"
            :chartStyles="chartStyles"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChartBlockHeader from "./ChartBlockHeader.vue";
import { switchDateFormat, formatRelativeTime } from "@/helpers/format-date-service";
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import ShareOfVoiceChart from "@/components/competitors-panel/ShareOfVoiceChart.vue";
import MindshareUpdateMessage from '@/components/competitors-panel/components/MindshareUpdateMessage';
import { datePickerList } from '@/components/date-range-picker/datePickerList'

export default {
    name: "ShareOfVoiceBlock",
    mixins: [ToolTipRenderMixin],
    components: {
        "share-of-voice-chart": ShareOfVoiceChart,
        "chart-header": ChartBlockHeader,
        'mindshare-update-message': MindshareUpdateMessage,
    },
    data() {
        return {
            chartStyles: {
                height: 350,
                yOpposite: true,
            },
            isLoading: false,
            date: null,
            interval: null,
            fetched: false
        };
    },
    computed: {
        ...mapGetters([
            "getCurrentProject",
            "getMindshareRankingDaysRange",
            "getMindshareDataCalculation",
            "getNewAddedKeywordsIsFetching",
            "getTotalKeywords",
            "getIsHaveNewAddedKeywords",
            "getProjectOverviewOptionItem"
        ]),
        infoText() {
            return this.getMindshareDataCalculation?.is_in_progress
                ? ''
                : this.getMindshareDataCalculation?.last_updated_at
                ? `Last updated: ${this.formatDate(new Date(this.getMindshareDataCalculation.last_updated_at))}`
                : '';
        },
        isSovUpdatingMessageVisible() {
            return (
                !this.getNewAddedKeywordsIsFetching &&
                this.getTotalKeywords > 0 &&
                (this.getMindshareDataCalculation?.is_in_progress ||
                    this.getIsHaveNewAddedKeywords)
            );
        },
    },
    watch: {
        getCurrentProject() {
            this.fetchData();
        }
    },
    methods: {
        ...mapActions([
            "resetMindShareData",
            "fetchMindshareHistory",
            "setProjectOverviewOptionIsVisible",
            "updateProjectTimeFrame"
        ]),
        fetchData(dateValue, key) {
            this.fetched = true;
            const { interval } = this.getProjectOverviewOptionItem('sov');

            let intervalDate = null;
            if(key && key !== this.interval){
                this.date = null;
                this.interval = key;
                intervalDate = datePickerList[key].value;
            } else {
                this.interval = interval;
                if(!dateValue){
                    if(!this.date && interval){
                        this.interval = interval;
                        intervalDate = datePickerList[interval].value;
                    } else {
                        this.date = null;
                    }
                } else {
                    this.date = dateValue;
                }
            }

            const [ start_date, end_date ] = intervalDate ? intervalDate : this.date ? this.date : [null, null];

            this.fetchMindshareHistory({ interval: {
                start_date,
                end_date,
            }});
        },
        onHide() {
            this.isLoading = true;
            this.setProjectOverviewOptionIsVisible({ projectId: this.getCurrentProject.id, option: "sov", value: false })
            .finally(()=>{
                this.isLoading = false;
            });
        },
        onDateSelect(date, key) {
            this.updateProjectTimeFrame({
                projectId: this.getCurrentProject.id,
                option: 'sov',
                value: {
                    date_range: {
                        start: date[0],
                        end: date[1]
                    }
                },
                interval: {
                    interval: key
                }
            })
            this.fetchData(date, key);
        },
        formatDate(date) {
            return `${formatRelativeTime(date)} (${switchDateFormat(date, 'M dS, Y hh:mm')})`;
        }
    },
    mounted() {
        if(!this.fetched && this.getCurrentProject?.project_id){
            this.fetchData();
        }
    },
    beforeDestroy() {
        this.resetMindShareData();
    }
};
</script>

<style lang="scss" scoped>
.block-wrapper {
    width: 100%;

    .info {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: kw-color(kw-grey, 1);
        margin: 0 0 12px;
    }

    /deep/ .top-competitors {
        .custom-table {
            .custom-table__row {
                .custom-table__cell:nth-child(5) {
                    max-width: 85px;
                    @media screen and (max-width: 500px) {
                        max-width: 72px;
                    }
                }
            }
        }
    }
}
</style>
