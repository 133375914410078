<template>
    <div
        v-if="!isViewOnlyUser"
        class="archived-projects-sidebar"
        data-cy="archived-projects-sidebar"
    >   
        <div class="sidebar-header">
            <search 
                :default-search="getArchivedCategoriesSearchQuery"
                @search-changed="searchChanged($event)" 
            />
        </div>
        <div class="row header">
            <div
                class="name"
                data-cy="archived-header-name"
            >
                <span @click="toggleArchivedProjectsSorter('category')">
                    {{ $t('name') }}
                    <span
                        v-if="sorter.field === 'category'"
                        class="kw kw-sort"
                        :class="sorter.direction"
                    />
                </span>
            </div>
            <div
                class="date"
                data-cy="archived-header-date"
            >
                <span @click="toggleArchivedProjectsSorter('time')">
                    {{ $t('first-added') }}
                    <span
                        v-if="sorter.field === 'time'"
                        class="kw kw-sort"
                        :class="sorter.direction"
                    />
                </span>
            </div>
            <div
                class="keywords"
                data-cy="archived-header-keywords"
            >
                <span @click="toggleArchivedProjectsSorter('count')">
                    {{ $t('keywords-first-upper') }}
                    <span
                        v-if="sorter.field === 'count'"
                        class="kw kw-sort"
                        :class="sorter.direction"
                    />
                </span>
            </div>
        </div>
        <div class="body">
            <div class="divider archived">
                <span>{{ $t('archived-projects') }}</span>
                <hr>
            </div>
            <projects
                class="card-default-archived"
                data-cy="card-default-archive"
                component="tree-navigation-archived"
                :projects="archivedCategories"
                :loaded="getArchivedProjectsLoaded"
            />
            <div class="divider deleted">
                <span>{{ $t('deleted-gr-kw') }}</span>
                <hr>
            </div>
            <projects
                class="card-default-archived"
                data-cy="card-default-deleted"
                component="tree-navigation-archived"
                :projects="deletedCategories"
                :loaded="getDeletedProjectsLoaded"
            />
        </div>
        <div class="footer">
            <div>
                <kw-button
                    button-type="primary"
                    :button-disabled="checkedLength === 0"
                    :button-text="`${$t('restore')} ${checkedLength !== 0 ? `(${checkedLength})` : ''}`"
                    data-cy="archive-restore-button"
                    @click="toggleRestore"
                />
            </div>
            <div class="footer__manage">
                <kw-button
                    id="back-to-project-list"
                    :button-text="$t('back-project-list')"
                    button-type="text"
                    class="button-back-to-project-list"
                    prepend-icon-class="kw kw-chevrons-left"
                    data-cy="back-to-project-list"
                    @click="backToTheProjectList"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import Projects from 'components/left-sidebar/components/Projects';
    import Search from 'components/left-sidebar/components/Search';

    export default {
        name: 'ArchivedProjectsScreen',
        components: {
            'projects': Projects,
            'search': Search,
        },
        computed: {
            ...mapGetters([
                'getArchivedCategoriesSearchQuery',
                'getArchivedProjectsLoaded',
                'getCategoriesForUndo',
                'getCheckedArchivedCategories',
                'getDeletedCategoriesForUndo',
                'getDeletedCategoriesSorter',
                'getDeletedProjectsLoaded',
                'getProcessedArchivedCategories',
                'isViewOnlyUser',
            ]),
            sorter () {
                return this.getDeletedCategoriesSorter;
            },
            archivedCategories () {
                return this.getProcessedArchivedCategories('archived');
            },
            deletedCategories () {
                return this.getProcessedArchivedCategories('deleted');
            },
            checkedLength () {
                return this.getCheckedArchivedCategories.length;
            },
        },
        mounted () {
            this.fetchArchivedProjects();
            this.fetchDeletedProjects();
            this.setArchivedProjectsForUndo([]);
        },
        methods: {
            ...mapActions([
                'fetchArchivedProjects',
                'fetchDeletedProjects',
                'restoreProjects',
                'archiveCategoriesUndo',
                'deleteCategoriesUndo',
                'fetchActiveProjects',
                'toggleArchivedProjectsSorter',
                'setArchivedProjectsForUndo',
                'setArchivedProjectsSearchQuery',
                'toggleArchivedProjectsOpened',
            ]),
            searchChanged (search) {
                this.setArchivedProjectsSearchQuery(search);
            },
            backToTheProjectList () {
                this.toggleArchivedProjectsOpened();

                window.history.length > 2
                    ? this.$router.go(-1)
                    : this.$router.push({name: 'Overview'});
            },
            async toggleRestore () {
                try {
                    await this.restoreProjects(this.getCheckedArchivedCategories);
                    this.$toastr.createToastr({
                        msg: this.$t('archived-projects-restore-msg'),
                        type: 'success',
                        undoCallback: this.undoHandler,
                    });
                    await this.fetchActiveProjects(true);
                } catch (error) {
                    console.log(error, 'error')
                }
            },
            async undoHandler () {
                const promises = [];

                if (this.getCategoriesForUndo.length) {
                    promises.push(this.archiveCategoriesUndo(this.getCategoriesForUndo));
                }

                if (this.getDeletedCategoriesForUndo.length) {
                    promises.push(this.deleteCategoriesUndo(this.getDeletedCategoriesForUndo));
                }

                try {
                    await Promise.all(promises);
                    this.$toastr.s(this.$t('restore-ok-msg'));
                } catch (e) {
                    this.$toastr.e(e);
                }
            },
        },
    };
</script>

<style lang="scss">
  .archived-projects-sidebar {
    padding-top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .placeholder_projects {
      width: 100%;
      max-width: 608px;
      margin-left: 24px;

      .placeholder_animate:nth-child(1) {
        width: 100%;
      }

      .placeholder_animate:nth-child(3) {
        width: 52px;
      }
    }

    .row {
      margin: 0;
    }

    .button-back-to-project-list {
      color: kw-color(kw-blue, 1) !important;
      font-size: 12px;

      span {
        &.kw-chevrons-left {
          margin-right: 10px !important;
          font-size: 10px !important;
        }
      }
    }

    .sidebar-header {
      justify-content: flex-start;
      padding-left: 8px;
      padding-right: 13px !important;
    }

    .header {
      display: flex;
      font-size: 12px;
      letter-spacing: 0.1em;
      line-height: 16px;
      white-space: nowrap;
      min-height: 22px;

      .name {
        align-items: center;
        display: flex;
        flex-grow: 1;
        height: 16px;
        justify-content: flex-start;
        margin: 5px 11px 5px 0;
        max-width: 277px;
        padding-left: 49px;

        span {
          position: relative;
        }
      }

      .search-block {
        text-align: left;
        padding: 16px;
      }

      .name {
        padding-left: 49px;
      }

      .date {
        display: flex;
        flex-grow: 1;
        height: 16px;
        justify-content: flex-start;
        margin: 5px 0 5px 15px;
        max-width: 102px;

        span {
          position: relative;
        }
      }

      .actions {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 7px;
      }

      .keywords {
        flex-grow: 1;
        height: 16px;
        margin: 5px 0 5px 15px;
        max-width: 91px;

        span {
          position: relative;
        }
      }

      span {
        align-items: center;
        color: #3F4248;
        cursor: pointer;
        display: flex;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
        user-select: none;
        padding-right: 12px;

        span {
          font-size: 9px;
          margin-left: 2px;
          top: -1px;
          position: absolute;
          right: 0;

          &.asc {
            transform: scaleY(-1);
          }

          &.desc {
            top: 0;
          }
        }
      }
    }

    .body {
      overflow: auto;
      flex: 1 1 auto;
      @extend .custom-scroll;

      .divider {
        display: flex;
        align-items: center;
        padding: 8px 0 8px 24px;

        span {
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          font-size: 14px;
          color: #CDCECF;
        }

        hr {
          width: 100%;
          display: inline-block;
          margin: 0 24px 0 10px;
          border-color: #CDCECF;
        }

        &.archived hr {
          width: 74%;
        }

        &.deleted hr {
          width: 58%;
        }
      }

      .project__name-wrapper {
        align-items: center;
        flex-direction: row;
        height: 36px;
        outline: transparent !important;
      }
    }

    .footer {
      div:nth-child(1) button {
        margin: 17px 21px;
        width: 184px;
      }
    }
  }

  .footer__manage {
    border-top: 1px solid #E9EAEB;
    padding: {
      bottom: 1px;
      left: 18px;
      right: 0;
      top: 1px;
    }
  }
</style>
