<template>
    <span>{{ getValue }}</span>
</template>

<script>
import { KEYWORD_SOURCES } from '@/constants';
export default {
    name: 'SourceRenderer',
    computed: {
        getValue() {
            return Object.values(KEYWORD_SOURCES).find(
                item => item.value === this.params.value,
            ).title;
        },
    },
};
</script>

<style lang="scss" scoped></style>
