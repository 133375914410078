const enNotifications = {
    "weekly-project-portfolio-snapshot": {
        "title": "Weekly project portfolio snapshot",
        "desc": "Weekly summary of the performance of all your projects sent to the Master Account and Admin users. See performances, Average rankings, and insights at a glance, all in one concise overview."
    },
    "keyword-movement-alerts": {
        "title": "Keyword movement alerts",
        "desc": "Get notified of all keyword movements."
    },
    "weekly-project-performance-pulse": {
        "title": "Weekly project performance pulse",
        "desc": "This snapshot includes keyword rankings, top performing keywords and pages, and a complete keyword metric breakdown."
    },
}

export default enNotifications;