<template>
    <add-sub-project
        :get-project-for-child-action="getProjectForChildAction"
        :toggle-add-sub-project="toggleAddSubProject"
        :create-sub-project="addProject"
    />
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AddSubProject from './components/AddSubProject';
    export default {
        name: 'AddSubProjectScreen',
        components: {
            'add-sub-project': AddSubProject
        },
        computed: {
            ...mapGetters([
                'getProjectForChildAction',
            ])
        },
        methods: {
            ...mapActions([
                'toggleAddSubProject',
                'addProject',
            ])
        }
    }
</script>
