<template functional>
    <div class="table-cell">
        <div
            v-if="props.keyword.grank === 0 && props.keyword.exact"
            class="kw kw-not-found table-cell__url-not-found"
            @mouseover="parent.tooltip({ text: $options.urlTooltip(parent.$t('not-found')), event: $event })"
        />
        <span
            v-else-if="props.keyword.grank && $options.lockedURL(props, parent.$store.getters)"
            class="kw kw-lock"
            @mouseover="parent.tooltip({ text: parent.$t('https-detected'), event: $event })"
        />
        <span
            v-if="props.keyword.exact"
            class="kw kw-crosshair target_exact_icon"
            @mouseover="parent.tooltip({ text: parent.$t('indicated-tracking-exact-url'), event: $event })"
        />
        <div
            v-if="props.keyword.grank === 0 && props.keyword.exact"
            class="table-cell__url-link-blok table-cell__content_url"
        >
            <a
                class="table-cell__url-link"
                :href="$options.clearUrl(props.keyword.fullUrl)"
                target="_blank"
                v-html="$options.clearUrl(props.keyword.url)"
            />
        </div>
        <div v-else-if="props.keyword.grank === 0 && !props.keyword.exact && !props.isMultiDomain">
            <span class="kw kw-not-found not-found__icon" />
            <span
                class="not-found"
                v-html="parent.$t('not-found')"
            />
        </div>
        <div
            v-else
            class="table-cell__content table-cell__content_url"
        >
            <a
                class="table-cell__url-link"
                @mouseover="parent.tooltip({ text: $options.urlTooltip(props.keyword.fullUrl), event: $event })"
                :href="props.keyword.fullUrl"
                target="_blank"
                v-html="$options.clearUrl(props.keyword.url)"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UrlField',
        urlTooltip: url => `<a href="${url}" target="_blank">${url}</a>`,
        lockedURL: ({ keyword }) => {
            return keyword.rankingurl !== null
                && keyword.rankingurl.indexOf('https') === 0;
        },
        clearUrl: url => url.indexOf('?') < 0 ? url : url.substring(0, url.indexOf('?'))
    };
</script>

<style lang="scss" scoped>
    .kw-lock {
      color: kw-color(kw-grey, 1);
      font-size: 12px;
      font-weight: 700;
      left: -17px;
      position: absolute;
    }

    .table-cell__url-not-found {
      color: kw-color(kw-grey, 1);
      font-size: 12px;
      font-weight: 700;
      position: absolute;
      left: -17px;
    }

    .table-cell__content_url {
        display: grid;
    }

    .table-cell__url-link {
        color: kw-color(kw-blue, 1);
        display: block;
        @import '~sass/cutting-name';
    }

    .not-found__icon {
      color: kw-color(kw-grey, 1);
      font-size: 12px;
      font-weight: 700;
      position: absolute;
      left: -15px;
      top: 2px;
    }

    .not-found {
        color: #ccc;
        text-transform: uppercase;
    }

    .target_exact_icon {
      color: kw-color(kw-grey, 1);
      font-size: 12px;
      font-weight: 700;
      margin-right: 5px;
    }
</style>
