import i18n from '@/i18n';

const keywordsByIntentTableConfig = [
    { key: 'intent', title: i18n.t('intent'), width: '106px'},
    { key: 'share', title: `% ${i18n.t('share')}`, width: '100px', type: 'chart' },
    { key: 'keywords', title: i18n.t('keywords'), align: 'flex-end', width: '73px' },
    { key: 'est_traffic', title: i18n.t('est-traffic'), align: 'flex-end', width: '109px'},
];

const serpFeaturePerformanceTableConfig = [
    { key: 'serp_feature', title: i18n.t('serp-features'), width: '154px', align: 'center', type: 'html'},
    { key: 'chart', title: i18n.t('chart'), width: '100px', type: 'multi_chart' },
    { key: 'total', title: i18n.t('total-features'), align: 'flex-end', width: '100px' },
    { key: 'featured', title: i18n.t('featured'), align: 'flex-end', width: '80px', type: 'html'},
];

const topPagesTableConfig = [
    { key: 'url', title: 'URL', width: '130px', align: 'flex-start', link: true, hasTooltip: true },
    { key: 'avg_rank', title: i18n.t('avg-rank'), align: 'flex-end', width: '80px', type: 'html' },
    { key: 'keywords', title: i18n.t('ranking-keywords'), align: 'flex-end', width: '90px' },
    { key: 'visibility', title: i18n.t('available-chart-titles.visibility'), align: 'flex-end', width: '80px', type: 'html' },
    { key: 'est_traffic', title: i18n.t('est-traffic'), align: 'flex-end', width: '80px', type: 'html' },
];

export { keywordsByIntentTableConfig, serpFeaturePerformanceTableConfig, topPagesTableConfig };
