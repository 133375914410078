<template>
    <div class="action-bar-container">
        <div
            class="action-bar"
            data-cy="overview-action-bar"
        >
            <div
                :class="contentClasses"
                data-cy="action-bar-body"
            >
                <template v-if="!isViewOnlyUser && actionBarType === 'Overview'">
                    <kw-button
                        v-if="!getGlobalSearchQuery"
                        class="button-action-bar"
                        :disabled="getIsRefreshRunning || !this.getPermissionData('on_demand_updates')"
                        button-type="text"
                        prepend-icon-class="kw kw-refresh-update"
                        @click="handleRefreshKeywords"
                        @prepend-icon-mouseover="tooltip({text: refreshTooltip, event: $event, placement: getToolTipPlacement})"
                    >
                        <div
                            v-if="getIsRefreshRunning"
                            slot="contentSlot"
                        >
                            <span @mouseover="tooltip({text: $t('refreshing-kw'), event: $event, placement: getToolTipPlacement})">
                                <loader
                                    color="0074D9"
                                />
                            </span>
                        </div>
                    </kw-button>
                    <kw-button
                        class="button-action-bar"
                        button-type="text"
                        prepend-icon-class="kw kw-external-link"
                        @prepend-icon-mouseover="tooltip({text: $tc('get-viewkeys', 2), event: $event, placement: getToolTipPlacement})"
                        @click="openGetViewkey(selectedOriginalProjects)"
                    />
                    <kw-button
                        class="button-action-bar"
                        button-type="text"
                        prepend-icon-class="kw kw-archive"
                        @prepend-icon-mouseover="tooltip({text: $t('archive-projects'), event: $event, placement: getToolTipPlacement})"
                        @click="manageProjects('archive', false)"
                    />
                    <kw-button
                        class="button-action-bar"
                        button-type="text"
                        prepend-icon-class="kw kw-trash-2"
                        @prepend-icon-mouseover="renderToolTip($event)"
                        @click="manageProjects('delete', false)"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import DashboardMixin from '@/mixins/DashboardMixin';
    import RefreshMixin from '@/mixins/RefreshMixin';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    export default {
        name: 'OverviewActionBar',
        type: 'overview',
        mixins: [
            DashboardMixin,
            RefreshMixin,
            ToolTipRenderMixin,
        ],
        props: {
            actionBarType: {
                type: String,
                required: false,
                default: '',
            },
            manageProjects: {
                type: Function,
                required: true,
            },
            openGetViewkey: {
                type: Function,
                required: true,
            },
            selected: {
                type: Array,
                required: true,
            },
            selectedOriginalProjects: {
                type: Array,
                required: true,
            },
        },
        data () {
            return {
                type: 'overview',
            }
        },
        computed: {
            ...mapGetters([
                'getGlobalSearchQuery',
                'getIsRefreshRunning',
                'getSearchKeyword',
                'isViewOnlyUser',
            ]),
            contentClasses () {
                return {
                    'action-bar__variable-content': true,
                    'action-bar__variable-content_dashboard-overview': this.actionBarType === 'Overview',
                };
            },

            getToolTipPlacement () {
                const actionBar = document.querySelector('.action-bar');
                if (actionBar.classList.contains('fixed')) {
                    return 'bottom';
                } else {
                    return 'top';
                }
            }
        },
        methods: {
            renderToolTip (event) {
                return this.tooltip({text: this.$t('delete-projects'), event, type: this.getToolTipPlacement});
            },
        },
    }
</script>

<style lang="scss" scoped>
  @import '~sass/partials/action-bar';

  .action-bar-container {
    height: 26px;
  }

  .action-bar {
    align-items: center;
    color: kw-color(kw-blue, 1);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    z-index: 101;

    button {
      margin-left: 0;
      height: 26px !important;

      &.button-action-bar {
        /deep/.kw {
          color: kw-color(kw-blue, 1);
          font-size: 15px;
          font-weight: 700;
        }

        div {
          /deep/.button-loader {
            position: static;
            height: 15px !important;
            min-width: 14px !important;
            width: 14px !important;
          }
        }
      }
    }

    &.fixed {
      position: fixed;
      background: kw-color(kw-white, 1);
      top: 70px;
    }

    @media screen and (max-width: 959px) {
      flex-flow: row wrap;
    }
  }

  .action-bar__description {
    align-items: center;
    color: kw-color(kw-white, 1);
    cursor: default;
    display: flex;
    font-size: 14px;
    order: 1;
    height: 50px;
    padding: 0 24px;
    @media screen and (max-width: 959px) {
      padding: 0 16px;
    }
  }

  .button-action-bar_last-button {
    margin-right: 100px;
    order: 3;
    @media screen and (max-width: 959px) {
      margin-right: 0;
      order: 2;
    }
  }

  .action-bar__variable-content {
    display: flex;

    .button-action-bar {
      &:disabled {
        opacity: .6;
      }
    }
  }
</style>
