import { mapGetters } from 'vuex';
import router from '@/routes';
import { Events, EventBus } from '@/events';
import { GTM_EVENTS, CUSTOM_SUBDOMAINS } from '@/constants';
import { detectIsSurferSEO } from '@/helpers';

export default {
    computed: {
        ...mapGetters([
            'getUserData',
            'getOtherSetting',
            'getIsFreePlan',
            'getPermissionData',
            'getActiveTag',
            'getHashForShare',
        ]),
    },
    methods: {
        viewkeyLink(project) {
            const { name: routeName, query } = router.history.current;
            const isSurferSEO = detectIsSurferSEO();
            if (
                (routeName === 'shareViewkeyWithHash' ||
                routeName === 'shareKeywordsTableWithHash') &&
                !isSurferSEO
            ) return window.location.href;

            const client = !this.getIsFreePlan && this.getOtherSetting('hideverified') === 'show' ? '/client' : '';

            const domain = isSurferSEO
                ? 'ranktracker.surferseo.app'
                : (this.getPermissionData('viewkey_reports') && this.getOtherSetting('usewhitelabel') === 'show')
                ? this.getDomainForUser('https://app.seranktracker.com')
                : this.getDomainForUser();
            let route = '';
            let authOrHash = '';

            if (this.getActiveTag.id && this.getHashForShare) {
                route = '/share-view';
                authOrHash = this.getHashForShare;
            } else {
                route = this.$router.resolve({ name: 'shareViewkey' }).href.includes('#/')
                    ? '/#/projects'
                    : '/projects';
                authOrHash = project.auth;
            }

            return `${domain}${route}/${project.shortkey}/${authOrHash}${client}`;
        },
        getDomainForUser(customDomain = null) {
            let domain = customDomain ? customDomain : window.location.origin;

            Object.keys(CUSTOM_SUBDOMAINS).forEach(email => {
                if (this.getUserData.email === email
                    || this.getUserData.addonMasterEmail === email) {
                    domain = CUSTOM_SUBDOMAINS[email];
                }
            });

            return domain;
        },
        embeddedCode(project) {
            const url = this.viewkeyLink(project);
            return `<iframe src="${url}" style="position:fixed; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"> Your browser doesn't support iframes </iframe>`;
        },
        async copyViewkey(id, link, project, embedded = false) {

            if (embedded) {
                EventBus.emit(Events.SHARE_EMBEDDED, {id, link, project_name: project.name});
            } else {
                EventBus.emit(Events.SHARE_VIEWKEY, {id, link, project_name: project.name});
            }

            /**
             * GTM custom event d_share_view_key
             */
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: GTM_EVENTS.CLICK_SHARE_VIEW_KEY,
                uid: this.getUserData.userId,
            });
            try {
                await this.$copyText(link);
                document.getElementById(id).select();
                this.$toastr.s(this.$t('link-copied-msg'));
            } catch (error) {
                console.error('An error on copy', error);
            }
        },
    },
}