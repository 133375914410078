<template>
    <div :class="keywordsContainerClasses">
        <template v-if="!getGlobalSearchQuery">
            <common-keyword-chart v-if="getCommonKeywordsChartIsShow" />
            <google-analytics-chart v-if="showGaChart" />
        </template>
        <dashboard-action-bar
            :app-width="appWidth"
            v-show="isActionBarVisible"
            :hideActionButtons="hideActionButtons"
        />
        <template
            v-if="
                !isSearch &&
                !getLazyLoadMode &&
                isMobile &&
                itemsWithProcessedFields.length
            "
        >
            <div v-if="isSelectAllVisible" class="select_all_block">
                <template v-if="!keyKeywordsById.length">
                    {{
                        $t('other-pages-selected-keywords', {
                            count: getSelectedAuth.length,
                        })
                    }}
                    <span @click="handleSelectAll">{{
                        $t('select-all-count-keywords', {
                            count: getTotalKeywords,
                        })
                    }}</span>
                </template>
                <template
                    v-else-if="
                        getSelectedAuth.length === getOriginalItems.length
                    "
                >
                    {{
                        $t('one-pages-selected-keywords', {
                            count: keyKeywordsById.length,
                        })
                    }}
                    <span @click="handleSelectAll">{{
                        $t('select-all-count-keywords', {
                            count: getTotalKeywords,
                        })
                    }}</span>
                </template>
                <template v-else>
                    {{
                        $t('selected-keywords-count', {
                            count: getSelectedAuth.length,
                        })
                    }}
                    <span @click="handleSelectAll">{{
                        $t('select-all-count-keywords', {
                            count: getTotalKeywords,
                        })
                    }}</span>
                </template>
            </div>
            <div
                v-if="
                    getIsAllCheckboxesSelected && !getAllWithoutIdsData.length
                "
                class="select_all_block"
            >
                {{
                    $t('all-keywords-are-selected', { count: getTotalKeywords })
                }}
                <span @click="handleUnSelectAll">{{ $t('unselect-all') }}</span>
            </div>
            <div
                v-if="getIsAllCheckboxesSelected && getAllWithoutIdsData.length"
                class="select_all_block"
            >
                <template v-if="isCurrentPageUnselected">
                    {{
                        $t('other-pages-selected-keywords', {
                            count:
                                getTotalKeywords - getAllWithoutIdsData.length,
                        })
                    }}
                    <span @click="handleSelectAll">{{
                        $t('select-all-count-keywords', {
                            count: getTotalKeywords,
                        })
                    }}</span>
                </template>
                <template v-else-if="isOnlyCurrentPageItemsSelected">
                    {{
                        $t('one-pages-selected-keywords', {
                            count:
                                getTotalKeywords - getAllWithoutIdsData.length,
                        })
                    }}
                    <span @click="handleSelectAll">{{
                        $t('select-all-count-keywords', {
                            count: getTotalKeywords,
                        })
                    }}</span>
                </template>
                <template v-else>
                    {{
                        $t('selected-keywords-count', {
                            count:
                                getTotalKeywords - getAllWithoutIdsData.length,
                        })
                    }}
                    <span @click="handleSelectAll">{{
                        $t('select-all-count-keywords', {
                            count: getTotalKeywords,
                        })
                    }}</span>
                </template>
            </div>
        </template>

        <keywords-table :keywords="itemsWithProcessedFields" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import KeywordsTableDynamic from 'components/main-table/KeywordsTableDynamic';
import KeywordsTablePlaceholder from './KeywordsTablePlaceholder';
import KeywordsTableStatic from 'components/main-table/KeywordsTableStatic';
const CommonKeywordChart = () =>
    import('components/main-table/CommonKeywordChart');
const GoogleAnalyticsChart = () => import('./GoogleAnalyticsChart');
const DashboardActionBar = () =>
    import('components/action-bar/DashboardActionBar');
import DashboardMixin from '@/mixins/DashboardMixin';
import KeywordsTable from '@/components/main-table/KeywordsTable';
import resize from 'vue-resize-directive';

export default {
    name: 'ProjectKeywordsAndCharts',
    props: {
        hideActionBarButtons: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'common-keyword-chart': CommonKeywordChart,
        'google-analytics-chart': GoogleAnalyticsChart,
        'keywords-table-dynamic': KeywordsTableDynamic,
        'keywords-table-placeholder': KeywordsTablePlaceholder,
        'keywords-table-static': KeywordsTableStatic,
        'keywords-table': KeywordsTable,
        'dashboard-action-bar': DashboardActionBar,
    },
    mixins: [DashboardMixin],
    directives: {
        resize,
    },
    data() {
        return {
            appWidth: '100%',
            isMobile:
                window.screen.width <= window.MOBILE_WIDTH_RESOLUTION_PHONE,
        };
    },
    computed: {
        ...mapGetters([
            'getCommonKeywordsChartIsShow',
            'getCurrentProject',
            'getGlobalSearchQuery',
            'getOriginalItems',
            'getProjectProperty',
            'getRefreshProgress',
            'getSortedAndFilteredKeywords',
            'getTotalKeywords',
            'getViewkeyView',
            'getSelectedAuth',
            'getActiveTag',
            'getIsAllCheckboxesSelected',
            'getKeywordFilters',
            'getSearchKeyword',
            'getAllWithoutIdsData',
            'keyKeywordsById',
            'getOtherSetting',
            'getLazyLoadMode',
            'getLazyLoadFetching',
            'getKeywordsIsFetching',
        ]),
        /**
         * Called when data for table is loaded or when table settings is changed.
         * @returns Array of sorted and filtered items for table
         */
        itemsWithProcessedFields() {
            let processedItems = [];
            processedItems = this.getSortedAndFilteredKeywords(
                this.getOriginalItems,
            );
            return processedItems;
        },
        isSearch() {
            return this.$route.name === 'searchResults';
        },
        keywordsContainerClasses() {
            return {
                'keywords-container': true,
                'keywords-container_share-view': this.getViewkeyView,
            };
        },
        isSelectAllVisible() {
            return (
                this.getTotalKeywords !== this.getOriginalItems.length &&
                this.getSelectedAuth.length &&
                !this.getIsAllCheckboxesSelected
            );
        },
        isCurrentPageUnselected() {
            //used only when select all and withoutIds
            let dataCount = 0;
            this.getOriginalItems.forEach(item => {
                if (
                    this.getAllWithoutIdsData.find(item1 => item.id === item1)
                ) {
                    dataCount++;
                }
            });
            return dataCount === this.getOriginalItems.length;
        },
        isOnlyCurrentPageItemsSelected() {
            //used only when select all and withoutIds
            let dataCount = 0;
            this.getOriginalItems.forEach(item => {
                if (
                    this.getAllWithoutIdsData.find(item1 => item.id !== item1)
                ) {
                    dataCount++;
                }
            });
            return (
                dataCount ===
                this.getTotalKeywords - this.getAllWithoutIdsData.length
            );
        },
        showGaChart() {
            return (
                this.getCurrentProject &&
                this.getOtherSetting('googleAnalyticsCharts') === 'show'
            );
        },
        isActionBarVisible() {
            return (
                !this.getKeywordsIsFetching &&
                (this.itemsWithProcessedFields.length ||
                    this.getSearchKeyword ||
                    this.getKeywordFilters.keywordsDate === '' ||
                    this.getKeywordFilters.enginesValue === '' ||
                    Object.keys(this.getKeywordFilters.activeTag).length ||
                    this.getKeywordFilters.showFilterValue === 'all')
            );
        },
        hideActionButtons(){
            return this.hideActionBarButtons;
        }
    },
    watch: {
        $route(to, from) {
            this.clearGatheringTimer();

            if (!this.getCurrentProject && from.name === 'archived') {
                this.setCurrentProject({
                    passedId:
                        localStorage.getItem('lastOpenedProjectId') ||
                        this.getOriginalActiveProjects[0].id,
                });
            }

            if (this.getCommonKeywordsChartIsShow) {
                this.toggleCommonKeywordsChartIsShow();
            }
        },
        getRefreshProgress({ clear, remaining, total }) {
            if (!clear && total !== 0 && remaining === 0) {
                // this.fetchData();
                this.fetchMetrics();
            }
        },
        getActiveTag: {
            deep: true,
            handler() {
                this.resetAllKeywords();
                this.setSelectedKeywords([]);
                this.toggleCommonKeywordsChartIsShow();
            },
        },
        getSelectedAuth(val) {
            if (!val.length && this.getCommonKeywordsChartIsShow) {
                this.toggleCommonKeywordsChartIsShow();
            }
            if (
                val.length &&
                val.length === this.getTotalKeywords &&
                !this.isSearch &&
                this.getOriginalItems.length !== this.getTotalKeywords
            ) {
                this.handleSelectAll();
            }
            this.triggerScroll();
        },
        getOriginalItems() {
            const dashboardActionBar = document.querySelector(
                '.dashboard-action-bar',
            );
            if (dashboardActionBar) {
                dashboardActionBar.style.width = '100%';
                dashboardActionBar.classList.remove(
                    'dashboard-action-bar-fixed',
                );
            }
        },
        getKeywordFilters: {
            deep: true,
            handler() {
                this.setSelectedKeywords([]);
                this.setAllCheckboxesSelected(false);
            },
        },
        getSearchKeyword() {
            this.setSelectedKeywords([]);
            this.setAllCheckboxesSelected(false);
        },
        getAllWithoutIdsData: {
            handler(data) {
                if (this.getTotalKeywords === data.length) {
                    this.setAllCheckboxesSelected(false);
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.fetchData(true);
        window.addEventListener('resize', this.windowResizeHandler);
    },
    beforeDestroy() {
        this.clearGatheringTimer();
        window.removeEventListener('resize', this.windowResizeHandler);
    },
    methods: {
        ...mapActions([
            'fetchKeywords',
            'fetchMetrics',
            'setCurrentProject',
            'resetAllKeywords',
            'setAllCheckboxesSelected',
            'setSelectedKeywords',
            'resetAllWithoutIds',
            'toggleCommonKeywordsChartIsShow',
            'clearGatheringTimer',
            'setCurrentPage',
            'setTotalKeywords',
            'setTotalKeywordsPages',
            'setKeywords',
        ]),
        async fetchData(force = false) {
            const route = this.$route.name;

            if (!this.getCurrentProject) {
                this.setCurrentProject();
            }

            const id = this.getProjectProperty('id') || this.$route.params.id;

            if (
                !force &&
                [
                    'shareViewkey',
                    'shareViewkeyWithHash',
                    'shareKeywordsTable',
                    'shareKeywordsTableWithHash',
                    'pdfProject',
                ].includes(route)
            ) {
                return;
            }

            if (!id) {
                return;
            }

            try {
                this.setTotalKeywords();
                this.setTotalKeywordsPages();
                this.setKeywords();
                await this.fetchKeywords({ id });
                this.setCurrentPage(1);
            } catch (e) {
                return this.$toastr.e(e);
            }
        },
        handleSelectAll() {
            if (!this.getIsAllCheckboxesSelected) {
                this.setAllCheckboxesSelected(true);
            }
            if (this.getAllWithoutIdsData.length) {
                this.resetAllWithoutIds();
            }
        },

        handleUnSelectAll() {
            this.setAllCheckboxesSelected(false);
            this.setSelectedKeywords([]);
        },
        windowResizeHandler() {
            this.isMobile =
                window.screen.width <= window.MOBILE_WIDTH_RESOLUTION_PHONE;
        },
    },
};
</script>

<style lang="scss">
.keywords-container {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;

    &.keywords-container_share-view {
        .dashboard-action-bar {
            &.dashboard-action-bar-fixed {
                top: 65px !important;
            }
        }

        .keywords-table__header_fixed {
            top: 67px;
        }
    }

    @media (max-width: 659px) {
        margin: 2px 0 0 0;
    }
}

.keywords-container_pdf-view {
    margin-bottom: 0;

    .actions-header,
    .actions-class {
        display: none !important;
        width: 0 !important;
    }
}

.keywords-table__wrapper {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    height: 100%;
    position: relative;
    width: 100%;
}

@media (max-width: 959px) {
    .keyword-actions-is-open {
        padding-bottom: 316px;
    }
    .keywords-container_share-view {
        .keywords-table__header_fixed {
            top: 109px;
        }
    }
}

.keywords-tables {
    color: #333333;
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    margin-bottom: 10px;

    .loader {
        margin: calc((100vh - 360px) / 2) auto;
    }
}

.keywords-tables_empty {
    background-image: url(~img/cactus.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 200px;
    padding-bottom: 300px;
}

.keywords-table__header,
.keywords-table__body {
    display: flex;
    flex-flow: column wrap;
}

.keywords-table__header_fixed {
    @extend .hidden-scroll;
    overflow: scroll;
    position: fixed !important;
    top: 139px;
    z-index: 11;

    &.with_action_bar {
        top: 165px;
    }

    @media (max-width: 959px) {
        top: 115px;
        &.with_action_bar {
            top: 140px;
        }
    }
}

.header-row {
    display: flex;
    height: 33px;
    position: relative;
}

.column-header {
    align-items: center;
    background-color: #f8f9fa;
    border: 1px solid #e1e8ef;
    color: kw-color(kw-grey, 1);
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    justify-content: flex-start;
    letter-spacing: 0.2px;
    line-height: 14px;
    position: relative;
    user-select: none;
    white-space: nowrap;

    &:first-child {
        border-top-left-radius: 4px;
    }

    &:last-child {
        border-top-right-radius: 4px;
    }

    &:not(:first-child) {
        border-left: none;
    }

    &.column-header_safari {
        letter-spacing: -0.7px;
    }

    &:not(.column-header_checkbox) {
        padding: 0 15px;
    }

    .column-header__title {
        outline: transparent !important;
    }

    @media (max-width: 959px) {
        font-size: 9px;
    }
}

.column-header__sort-container {
    height: 16px;
    margin-left: 0;
    position: relative;
    display: flex;
    align-items: center;

    span {
        font-size: 8px;
        color: kw-color(kw-grey, 1);
        transform: scaleX(0.8) translateX(-1px);
    }

    &.asc {
        transform: scaleY(-1);
    }
}

.row-keyword-chart {
    background-color: kw-color(kw-white, 1);
    border-bottom: 1px solid #dee5ec;
    padding: 0 8px;
    position: absolute;
    top: 35px;
    z-index: 1;
    @media screen and (max-width: 959px) {
        z-index: 3;
    }
}

.table-row {
    position: relative;

    &:last-child {
        .column-data {
            border-bottom: 1px solid #e1e8ef;
        }
    }

    &:not(.table-row_fav) {
        span.kw-favorite {
            display: none;
        }

        .table-row__inner_even {
            .column-data {
                background-color: #f7f8fa;
            }
        }
    }

    &.table-row_fav {
        .table-row__inner {
            .column-data {
                background-color: #fff8ee;
            }
        }

        .table-row__inner_even {
            .column-data {
                background-color: #fdf2e0;
            }
        }
    }

    .table-row__inner {
        display: flex;
        height: 33px;
        position: relative;

        &.table-row__inner_hovered,
        &.table-row__inner_checked {
            .column-data {
                background-color: #e5ecf3;
            }
        }
    }

    .table-row__inner_hovered {
        .table-cell__actions {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .table-row__title {
        @import '~sass/cutting-name';
        background: kw-color(kw-white, 1);
        border-bottom: 1px solid #dee5ec;
        border-top: 1px solid #dee5ec;
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        height: 46px;
        margin-bottom: 0;
        margin-top: 0;
        outline: transparent;
        padding: 10px 0;
        position: absolute;
        top: -46px;

        .kw-corner-down-right {
            margin: 0 4px;
        }
    }

    .table-row__title_secondary {
        color: #909399;
        position: relative;

        &:after {
            color: kw-color(kw-black, 1);
            content: '\e96a';
            font-family: 'icon-font';
            padding: 4px 8px;
            position: relative;
        }
    }

    .row-wrap {
        font-size: 14px;
        display: grid;
        line-height: 16px;
        @media (max-width: 959px) {
            font-size: 12px;
        }
    }

    .column-data {
        align-items: center;
        background: kw-color(kw-white, 1);
        border-right: 1px solid #e1e8ef;
        display: flex;
        justify-content: center;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:not(.column-data_checkbox) {
            padding: 0 15px;

            .row-wrap {
                width: 100%;
            }
        }

        &.column-data_keyword {
            padding-right: 5px;
        }

        &.column-data_gpage,
        &.column-best_grank {
            span {
                text-align: right;
            }
        }

        &.column-data_url {
            padding: 0 15px 0 30px;
        }

        &.column-data_left {
            justify-content: flex-start;
        }

        &:first-child {
            border-left: 1px solid #e1e8ef;
        }
    }

    .table-cell {
        align-content: center;
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        position: relative;
        width: 100%;
    }

    .table-cell__text_ranks {
        display: flex;
        align-items: center;
    }

    .table-cell__text {
        @import '~sass/cutting-name';
        outline: transparent;
    }

    .table-cell__content_cut {
        display: grid;
        position: relative;
        width: 100%;
    }

    .table-cell__content-grank {
        margin-left: auto;

        &:after {
            word-break: break-word;
        }
    }
}

.search-results-disclaimer {
    text-align: center;

    p {
        color: #ccc;
    }
}

.select_all_block {
    background: #e5ecf3;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    margin-bottom: 10px;
    z-index: 3;

    span {
        display: inline-block;
        margin: 0 0 0 4px;
        color: rgba($color: #4180c6, $alpha: 1);
        cursor: pointer;
        transition: color 0.5s;

        &:hover {
            color: rgba($color: #4180c6, $alpha: 0.7);
        }
    }
    @media (max-width: 420px) {
        padding: 5px 10px;
        height: auto;
        font-size: 13px;
        line-height: 14px;
    }
}

@media (max-width: 959px) {
    .table-row {
        .table-row__inner {
            height: 40px;
        }

        .table-row__title {
            padding-left: 5px;
        }
    }
}
</style>
