<template functional>
    <div class="permission">
        <template v-if="props.name !== 'on_demand_updates'">
            <span class="kw kw-check" v-if="props.permission || props.isDefaultChecked" />
            <span class="kw kw-x" v-else />
           
            {{ parent.$t(`permissions.${props.name}`) }}
        </template>
    </div>
</template>

<script>
export default {
    name: 'Permission',
    props: {
        name: {
            type: String,
            required: true,
        },
        permission: {
            type: Boolean,
            required: true,
        },
        isDefaultChecked: {
            type: Boolean,
            default: false,
        }
    },
};
</script>

<style lang="scss" scoped>
.permission {
    margin: 0 0 8px;
    color: kw-color(kw-white, 1);
    padding-left: 30px;
    font-family: 'Aeonik';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    
    &:last-child {
        margin-bottom: 0;
    }

    .kw {
        font-size: 10px;
        font-weight: 700;
        margin-right: 15px;
        margin-left: -30px;

        &.kw-check {
            color: kw-color(kw-green, 5);
        }

        &.kw-x {
            color: kw-color(kw-red, 10);
        }
    }
}
</style>
