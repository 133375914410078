<template>
    <div class="config-wrapper">
        <div class="text-content">
            <p class="title">{{ title }}</p>
            <div class="desc">
                <slot />
            </div>
        </div>
        <div class="toggle">
            <toggler
                :toggler="checked"
                @toggler_click="$emit('onToggle', !checked)"
            />
       </div>
    </div>
</template>

<script>
export default {
    name: 'ConfigToggle',
    props: {
        title: {
            type: String
        },
        checked: {
            type: Boolean
        }
    }
};
</script>

<style lang="scss">
    .config-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 32px;

        .title {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            margin: 0 0 8px;
        }
        .desc {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
        }
        .toggle {
            position: relative;
            width: 40px;
            min-width: 40px;
            height: 30px;
        }
    }
</style>
