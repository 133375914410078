<template>
    <!-- <h4 class="category-row" v-html="params.node.data.category" /> -->

    <h4
        class="category-row"
        data-cy="table-row-title"
        :data-tooltip="params.node.data.category"
    >
        <router-link
            v-if="isSearch"
            :to="{
                name: 'keywordList',
                params: { id: params.node.data.category },
            }"
        >
            <span v-html="params.node.data.title" />
        </router-link>
        <span v-else v-html="params.node.data.title" />
    </h4>
</template>

<script>
export default {
    name: 'CategoryRowRenderer',
    computed: {
        isSearch() {
            return this.$route.name === 'searchResults';
        },
    },
};
</script>

<style lang="scss" scoped>
.category-row {
    height: 46px;
    padding: 10px;
    line-height: 26px;
    margin: 0;
    border-top: 1px solid #e1e8ef;
    border-bottom: 1px solid #e1e8ef;
    background: white;

    &.top-border {
        border-top: 1px solid #e1e8ef;
    }
}
</style>
