const enTopPages = {
    'top-pages': {
        'title': 'Top Pages',
        'table': {
            'columns': {
                'total_keyword_count': 'Keywords',
                'average_cost_per_click': 'Avg. Cost Per Click',
                'current_ranking_url': 'URL',
                'cpc': 'CPC',
                'traffic': 'Traffic',
                'keyword': 'Top Keywords',
                'current_rank': 'Top Keyword Rank',
                'average_current_rank': 'Avg. Rank',
                'search_volume': 'Search Vol.',
                'total_search_volume': 'Total Search Volume',
            },
        },
    },
};

export default enTopPages;
