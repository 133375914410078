<template>
    <div
        :style="datePickerStyles"
        :class="datePickerClasses"
    >
        <span class="kw kw-calendar" />
        <input
            v-show="!editDate"
            id="datepicker-input"
            v-model="val"
            data-cy="date-picker-field"
            :class="datepickerInputClasses"
            :name="name"
            :placeholder="placeholder"
            readonly
            :style="{ width: width }"
            type="text"
            @click="inputClick"
        >
        <span class="formatted-date">
            {{ val && getFormattedDate(val) }}
        </span>
        <kw-button
            v-if="clearButton&&val"
            class="close"
            prepend-icon-class="kw kw-x"
            button-type="text"
            @click.stop="clearDateHandler"
        />
        <div
            v-show="displayDayView"
            id="datepicker-popup"
            data-cy="datepicker-popup"
            :class="datepickerPopupClasses"
        >
            <div class="datepicker-inner datepicker-inner_custom">
                <div class="datepicker-body">
                    <div class="datepicker-ctrl datapicker-ctrl_custom">
                        <span
                            aria-hidden="true"
                            class="kw kw-chevron-left"
                            @click="preNextMonthClick(0)"
                        />
                        <p @click="switchMonthView">
                            {{ stringifyDayHeader(currDate) }}
                        </p>
                        <span
                            aria-hidden="true"
                            class="kw kw-chevron-right"
                            @click="preNextMonthClick(1)"
                        />
                    </div>
                    <div class="datepicker-weekRange datepicker-weekRange_custom">
                        <span
                            v-for="(w, i) in text.daysOfWeek"
                            :key="i"
                        >
                            {{ w }}
                        </span>
                    </div>
                    <div class="datepicker-dateRange datepicker-dateRange_custom">
                        <span
                            v-for="(d, i) in dateRange"
                            :key="i"
                            :class="dayClass(d)"
                            @click="daySelect(d)"
                        >
                            {{ d.text }}
                        </span>
                    </div>
                    <div class="datepicker-dateControl">
                        <kw-button
                            data-cy="today-button-filter-calendar"
                            :button-text="$t('datepicker-today')"
                            button-type="text"
                            class="button-transparent_datepicker"
                            @click="selectToday"
                        />
                        <kw-button
                            data-cy="cancel-button-filter-calendar"
                            :button-text="$t('datepicker-close')"
                            button-type="text"
                            class="button-transparent_datepicker"
                            @click="displayDayView = false"
                        />
                    </div>
                    <hr
                        v-if="editDate"
                        class="datapicker__hr"
                    >
                    <kw-button
                        v-if="editDate"
                        :button-disabled="isEditDateDisabled"
                        :button-text="$t('apply-new-date')"
                        button-type="primary"
                        class="button-primary_datepicker"
                        @click="editDatePick"
                    />
                </div>
            </div>
        </div>
        <div
            v-show="displayMonthView"
            :class="datepickerPopupClasses"
        >
            <div class="datepicker-inner datepicker-inner_custom">
                <div class="datepicker-body">
                    <div class="datepicker-ctrl datapicker-ctrl_custom">
                        <span
                            aria-hidden="true"
                            class="kw kw-chevron-left"
                            @click="preNextYearClick(0)"
                        />
                        <p @click="switchDecadeView">
                            {{ stringifyYearHeader(currDate) }}
                        </p>
                        <span
                            aria-hidden="true"
                            class="kw kw-chevron-right"
                            @click="preNextYearClick(1)"
                        />
                    </div>
                    <div class="datepicker-monthRange datepicker-monthRange_custom">
                        <template v-for="(m, index) in text.months">
                            <span
                                :key="index"
                                :class="{ 'datepicker-dateRange-item-active': (text.months[parse(val).getMonth()] === m) && currDate.getFullYear() === parse(val).getFullYear() }"
                                @click="monthSelect(index)"
                                v-text="m.substr(0,3)"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-show="displayYearView"
            :class="datepickerPopupClasses"
        >
            <div class="datepicker-inner datepicker-inner_custom">
                <div class="datepicker-body">
                    <div class="datepicker-ctrl datapicker-ctrl_custom">
                        <span
                            aria-hidden="true"
                            class="icon-chevron_2 icon-rotate-right"
                            @click="preNextDecadeClick(0)"
                        />
                        <p>{{ stringifyDecadeHeader(currDate) }}</p>
                        <span
                            aria-hidden="true"
                            class="icon-chevron_2 icon-rotate-left"
                            @click="preNextDecadeClick(1)"
                        />
                    </div>
                    <div class="datepicker-monthRange datepicker-monthRange_custom decadeRange">
                        <template v-for="(decade, i) in decadeRange">
                            <span
                                :key="i"
                                :class="{'datepicker-dateRange-item-active':parse(val).getFullYear() === decade.text}"
                                @click.stop="yearSelect(decade.text)"
                                v-text="decade.text"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Datepicker from 'vue-strap/src/Datepicker';
    export default {
        extends: Datepicker,
        props: {
            editDate: {
                type: Boolean,
                default: false,
            },
            selectedNote: {
                type: Object,
                default: null,
            },
            clearDate: {
                type: Function,
                required: false,
                default: () => {},
            },
        },
        data () {
            return {
                topPopup: false,
            };
        },
        computed: {
            ...mapGetters([
                'getFormattedDate',
            ]),
            formattedSelectedDate () {
                return this.editDate
                    ? this.getFormattedDate(this.selectedNote.attributes.created_at)
                    : null;
            },
            isEditDateDisabled () {
                return this.formattedSelectedDate === this.getFormattedDate(this.currDate);
            },
            datePickerStyles () {
                if (!this.selectedNote) return {};
                const top = this.selectedNote.top < 0 ? 0 : this.selectedNote.top;
                return {top: `-${top}px`};
            },
            datePickerClasses () {
                return {
                    'datepicker datepicker_custom': true,
                    'datepicker_active': this.displayDayView,
                    'datepicker_edit': this.editDate,
                };
            },
            datepickerInputClasses () {
                return {
                    'form-control': true,
                    'with-reset-button': this.clearButton,
                    'datepicker-input': true,
                };
            },
            datepickerPopupClasses () {
                return {
                    'datepicker-popup datepicker-popup_custom': true,
                    'datepicker-popup_top': this.topPopup,
                };
            },
        },
        watch: {
            selectedNote (val) {
                this.inputClick(val);
            },
        },
        created () {
            this.updatePopupPosition();

            if (this.editDate) {
                this.inputClick(this.selectedNote);
            }
        },
        methods: {
            clearDateHandler () {
                this.val = '';
                this.clearDate();
            },
            selectToday () {
                this.currDate = this.parse(new Date());
                this.val = this.stringify(this.currDate);
            },
            inputClick (note) {
                this.updatePopupPosition();
                this.currDate = this.parse(this.val) || this.parse(new Date())
                if (this.editDate && note === null) {
                    this.displayDayView = false;
                    return;
                }

                if (this.editDate && note) {
                    this.displayDayView = true;
                    return;
                }

                if (this.displayMonthView || this.displayYearView) {
                    this.displayDayView = false;
                } else {
                    this.displayDayView = !this.displayDayView;
                    this.$emit('new-note-datepicker-opened');
                }
            },
            updatePopupPosition () {
                setTimeout(() => {
                    const trigger = document.getElementById('datepicker-input');
                    const menu = document.getElementById('datepicker-popup');

                    if (!menu) {
                        return;
                    }

                    const freeSpace = document.body.offsetHeight - trigger.getBoundingClientRect().bottom;
                    this.topPopup = menu.offsetHeight + 6 > freeSpace;
                }, 0);
            },
            daySelect (day) {
                if (day.sclass === 'datepicker-item-disable') {
                    return false;
                }

                if (day.date > new Date()) {
                    return false;
                }

                this.currDate = day.date;
                this.val = this.stringify(this.currDate);
                if (!this.editDate) {
                    this.displayDayView = false;
                }
            },
            editDatePick () {
                this.$emit('update:value', this.val);
                this.$emit('edit-date');
            },
            dayClass (d) {
                return {
                    [d.sclass]: true,
                    'datepicker-dateRange-item-prev': this.getFormattedDate(d.date) === this.formattedSelectedDate,
                    'datepicker-dateRange-item-disabled': d.date > new Date(),
                };
            },
        },
    }
</script>

<style lang="scss" scoped>
  .datepicker_custom {
    position: relative;
    width: 100%;

    span.kw-calendar {
      background-color: kw-color(kw-white, 1);
      color: #828F9C;
      font-weight: 700;
      padding-left: 10px;
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    .datepicker-input {
      background-color: kw-color(kw-white, 1);
      border: 1px solid #AFBAC7;
      box-shadow: none;
      color: transparent !important;
      cursor: pointer;
      user-select: none;
    }

    .close {
      right: 24px;
      opacity: 1;

      /deep/.kw {
        font-weight: 700;
        font-size: 12px;
        color: #828F9C;
      }
    }
  }

  .formatted-date {
    @import '~sass/cutting-name';
    display: inline-block;
    left: 12px;
    pointer-events: none;
    position: absolute;
    top: 7px;
    width: calc(100% - 60px);
  }

  .datepicker_right-popup {
    .datepicker-popup_custom {
      right: 0;
    }
  }

  .datepicker_edit {
    width: calc(100% - 20px);
    margin: 0 auto;

    .formatted-date {
      display: none;
    }

    span.kw-calendar {
      display: none;
    }
  }

  .datepicker_active {
    .form-control {
      &.datepicker-input {
        border-color: #4F4BCB !important;
      }
    }
  }

  .datepicker-popup_custom {
    border: none;
    border-radius: 6px;
    margin-bottom: 6px;
    margin-top: 4px;
    width: 100%;
  }

  .datepicker-popup_top {
    bottom: 100%;
  }

  .datepicker-dateControl {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .datepicker-inner_custom {
    width: 100%;
  }

  .button-transparent_datepicker {
    color: kw-color(kw-grey, 1);

    &:hover {
      color: kw-color(kw-blue, 1);
    }

    /deep/ span {
      height: unset;
      width: unset;
      padding: 0 10px;
    }
  }

  .datapicker__hr {
    background: kw-color(kw-light-grey, 1);
    border: none;
    height: 1px;
    margin: 8px 0;
    width: 100%;
  }

  .button-primary_datepicker {
    width: 100%;

    &:disabled {
      background: #C5C9CD;
      box-shadow: 0 1px 3px rgba(31, 63, 97, .2);
      color: kw-color(kw-grey, 1);
      opacity: .35;
    }

    /deep/ span {
      height: unset;
      width: unset;
    }
  }

  .datapicker-ctrl_custom {
    color: #828F9C;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: unset;

    span {
      cursor: pointer;
      display: inline-block;
      font-size: 10px;
      position: relative;
      height: 34px;
      line-height: 34px;
      width: 34px;

      &:hover {
        background: transparent;
        color: kw-color(kw-blue, 1);
      }
    }

    p {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
      height: unset;
      line-height: 34px;
      margin: 0;
      width: unset;

      &:hover {
        background: transparent;
        color: kw-color(kw-blue, 1);
      }
    }
  }

  .datepicker-weekRange_custom {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    line-height: 14px;
    margin: 0 8px;
  }

  .datepicker-dateRange_custom {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 8px;

    span {
      color: kw-color(kw-grey, 1);
      height: 28px;
      line-height: 28px;
      width: 30px;

      &:hover {
        background: #F5F6F7;
        color: kw-color(kw-grey, 1);
      }

      &.datepicker-dateRange-item-prev {
        background: rgba(kw-color(kw-blue, 1), .1);
        color: kw-color(kw-blue, 1);
      }

      &.datepicker-dateRange-item-disabled {
        background-color: transparent !important;
        color: #B9BABD !important;
        pointer-events: none;
      }
    }
  }

  .datepicker-dateRange-item-active {
    background: kw-color(kw-blue, 1) !important;
  }

  .datepicker-monthRange_custom {
    margin: 8px auto;

    span {
      color: kw-color(kw-grey, 1);
      height: 38px;
      line-height: 38px;

      &:hover {
        background: rgba(kw-color(kw-blue, 1), .1);
        color: kw-color(kw-blue, 1);
      }
    }
  }
</style>
