const scrollToSideBarItem = (projectId = null, tagId = null) => {
    if(projectId) {
        const mainBlock = document.getElementById('virtual-projects');
        const idToGet = tagId ? `tag_${tagId}`: `project_${projectId}`;
        const sideBarItem = document.getElementById(idToGet);

        if(mainBlock && sideBarItem) {
            const mainBlockOffset  = mainBlock.offsetTop;
            const sideBarItemOffset = sideBarItem.offsetTop;
            const itemOffsetFromParent = sideBarItemOffset - mainBlockOffset;
            if(itemOffsetFromParent) {
                mainBlock.scrollTo(0, itemOffsetFromParent);
            }
        }
    } else {
        console.trace('func scrollToSideBarItem: please provide id');
    }
}

export {
    scrollToSideBarItem
}