import pdfView from 'pages/pdf-view/PdfView';

export default [
    {
        path: '/pdf-view/:viewkey/:auth',
        name: 'pdfView',
        components: {
            pdfView,
        },
        props: {
            pdfView: (route) => ({
                viewkey: route.params.viewkey,
                auth: route.params.auth
            })
        }
    },
    // old route for share viewkey - redirects to new one
    {
        path: '/share_viewkey',
        redirect: ({query}) => ({
            path: `/projects/${query.viewkey}/${query.auth}${'client' in query ? '/client' : ''}`,
            query: '',
        }),
    },
    // old route for share viewkey - redirects to new one
    {
        path: '/project/:viewkey/:auth/:client?',
        redirect: ({params}) => ({
            path: `/projects/${params.viewkey}/${params.auth}${params.client ? '/client' : ''}`,
            query: '',
        }),
    },
];
