const boldenSuggestion = (scope, refs) => {
    if (!scope) return scope;

    const {suggestion, query} = scope;
    let result = refs.suggestComponent.displayProperty(suggestion);

    if (!query) return result;
  
    const texts = query.split(/[\s-_/\\|\.]/gm).filter(t => !!t) || [''];
    return result.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)','gi'), '$1<b>$2</b>$3');
};

const autocompleteStyles = {
    vueSimpleSuggest: 'custom-simple-suggest',
    inputWrapper: 'custom-simple-suggest__input-wrapper',
    suggestions: 'custom-simple-suggest__suggestions',
    suggestItem: 'custom-simple-suggest__item',
};

export {autocompleteStyles, boldenSuggestion};