import i18n from '@/i18n';
import toastr from '@/helpers/init-toastr';
import {apiCodes} from '@/constants';

import accountErrors from './error/account';
import addonsErrors from './error/addons';
import billingErrors from './error/billing';
import globalErrors from './error/global';
import projectsErrors from './error/projects';
import reportsErrors from './error/reports';
import keywordsErrors from './error/keywords';
import invoicesErrors from './error/invoices';
import notesErrors from './error/notes';
import usersErrors from './error/users';
import userErrors from './error/user';
import metricsErrors from './error/metrics';
import tagsErrors from './error/tags';
import gaProfilesErrors from './error/gaProfiles';
import gscErrors from './error/gsc';
import alertsErrors from './error/alerts';
import whitelabelDomainsErrors from './error/whitelabelDomains';
import importDataErrors from './error/importData';

import accountSuccess from './success/account';
import billingSuccess from './success/billing';
import notesSuccess from './success/notes';
import usersSuccess from './success/users';
import userSuccess from './success/user';
import addonsSuccess from './success/addons';
import invoicesSuccess from './success/invoices';
import legacySuccess from './success/legacy';
import tagsSuccess from './success/tags';
import keywordsSuccess from './success/keywords';
import projectsSuccess from './success/projects';
import reportsSuccess from './success/reports';
import gaProfilesSuccess from './success/gaProfiles';
import gscSuccess from './success/gsc';
import alertsSuccess from './success/alerts';
import importDataSuccess from './success/importData';

const error = {
    accountErrors,
    addonsErrors,
    alertsErrors,
    billingErrors,
    eventTrackingErrors: () => {},
    currenciesErrors: () => {},
    countriesErrors: () => {},
    globalErrors,
    projectsErrors,
    reportsErrors,
    invoicesErrors,
    keywordsErrors,
    legacyErrors: () => {}, //@TODO
    notesErrors,
    usersErrors,
    metricsErrors,
    tagsErrors,
    gaProfilesErrors,
    gscErrors,
    whitelabelDomainsErrors,
    importDataErrors,
    userErrors,
};

const success = {
    accountSuccess,
    addonsSuccess,
    alertsSuccess,
    billingSuccess,
    eventTrackingSuccess: () => {},
    currenciesSuccess: () => {},
    countriesSuccess: () => {},
    globalSuccess: () => {},
    projectsSuccess,
    reportsSuccess,
    invoicesSuccess,
    keywordsSuccess,
    legacySuccess,
    notesSuccess,
    usersSuccess,
    metricsSuccess: () => {},
    tagsSuccess,
    gaProfilesSuccess,
    gscSuccess,
    whitelabelDomainsSuccess: () => {},
    importDataSuccess,
    userSuccess,
};

export default {
    get: name => ({
        e: error[`${name}Errors`](i18n, toastr, apiCodes),
        s: success[`${name}Success`](i18n, toastr, apiCodes),
    }),
};
