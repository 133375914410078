import isEllipsisActive from '@/helpers/isEllipsisActive';

export default {
    data () {
        return {
            tooltip: Vue.renderTooltip,
        }
    },

    computed: {
        isHeaderVisible () {
            const foundedOption = this.getCurrentProject && this.getCurrentProject.settings.columns.find((item) => item === 'hideverified');
            return !!foundedOption
        }
    },

    methods: {
        getTooltipForText (text, event, allowHtml, options = {}) {
            const {interactive, maxWidth} = options;

            if (isEllipsisActive(event)) {
                return this.tooltip({text, allowHtml, event, interactive, maxWidth});
            }

            this.tooltip({disabled: true});
        },
        mouseoverHandler (e) {
            const el = e.target;
            const maxWidth = el.dataset.importantMaxWidth ? parseInt(el.dataset.importantMaxWidth) : null;
            const importantText = el.dataset.importantTooltip;
            const interactiveText = el.dataset.interactiveTooltip;
            const text = el.dataset.tooltip;
            const allowHtml = el.dataset.tooltipAllowHtml != 'false';
            const theme = el.dataset.importantTooltipTheme ?? el.dataset.interactiveTooltipTheme;
            return importantText
                ? this.tooltip({text: importantText, allowHtml, event: e, maxWidth, theme})
                : interactiveText
                    ? this.tooltip({text: interactiveText, allowHtml, event: e, interactive: true, maxWidth, theme})
                    : text ? this.getTooltipForText(text, e, allowHtml, {maxWidth}) : null;
        },
    },
};
