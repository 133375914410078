export default (i18n, toastr) => {
    return res => {
        const {config} = res;
        if (config.method === 'post' && config.url.includes('/tags')) {
            toastr.s(i18n.t('create-tags-success'));
        }
        if (config.method === 'delete' && config.url.includes('/tags')) {
            toastr.s(i18n.t('delete-tag-success'));
        }
        if (config.method === 'patch' && config.url.includes('/tags')) {
            toastr.s(i18n.t('edit-tag-success'));
        }
        return res;
    };
};