<template>
    <div class="keywords-table__wrapper" data-cy="keywords-table-wrap">
        <div v-resize="tableResizeHandler" :class="keywordsTablesClasses">
            <template v-if="!getKeywordsIsFetching">
                <keywords-new-table v-if="showNewTable" :items="keywords" />
                <template v-else>
                    <keywords-table-static
                        :items="keywords"
                        :common-line-width="commonLineWidth"
                    />

                    <keywords-table-dynamic :items="keywords" />
                </template>
            </template>

            <keywords-table-placeholder v-else />
        </div>
        <div
            v-if="getLazyLoadFetching"
            v-resize="tableResizeHandler"
            :class="keywordsTablesClasses"
        >
            <keywords-table-placeholder />
        </div>

        <table-pagination
            v-if="!isSearch && keywords && keywords.length"
            :current-page="getCurrentPage"
            :total-pages="getTotalKeywordsPages"
            :per-page="getDisplayLength"
            :total-records="getTotalKeywords"
            :on-change-page="keywordsPageChanged"
            :only-total-count="!!getLazyLoadMode"
        />

        <div
            v-if="isSearch && keywords && keywords.length === 250"
            class="search-results-disclaimer"
        >
            <p>{{ $t('search-results-disclaimer') }}</p>
        </div>
        <div
            v-show="getShowAllTags"
            class="all-tags-class"
            ref="all-tags"
            v-click-outside="hideAllTags"
        >
            <span
                v-for="(tag, key) in longTagList"
                :key="key"
                :class="tagsClasses(tag)"
                @click.stop="setActiveTagsWithUpdate(tag)"
                @mouseover="
                    tooltip({
                        text: tag.name,
                        event: $event,
                    })
                "
                v-text="tag.name"
            />
        </div>
    </div>
</template>

<script>
import TagsManagingMixin from '@/mixins/TagsManagingMixin';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import { isSharedPage } from '@/helpers/keywordTablesHelper.js';
import KeywordsTableDynamic from '@/components/main-table/KeywordsTableDynamic';
import KeywordsTableStatic from '@/components/main-table/KeywordsTableStatic';
import KeywordsNewTable from '@/components/new-table/KeywordsNewTable';
import KeywordsTablePlaceholder from '@/components/main-block/components/KeywordsTablePlaceholder';
import { mapActions, mapGetters } from 'vuex';
import resize from 'vue-resize-directive';
export default {
    name: 'KeywordsTable',
    components: {
        'keywords-table-dynamic': KeywordsTableDynamic,
        'keywords-table-placeholder': KeywordsTablePlaceholder,
        'keywords-table-static': KeywordsTableStatic,
        'keywords-new-table': KeywordsNewTable,
    },
    mixins: [ToolTipRenderMixin, TagsManagingMixin],
    directives: { resize },
    props: {
        keywords: {
            type: Array | undefined | null,
            default: () => [],
        },
    },
    data() {
        return {
            commonLineWidth: '1080px',
        };
    },
    computed: {
        ...mapGetters([
            'getCurrentPage',
            'getOtherSetting',
            'getLazyLoadMode',
            'getTotalKeywords',
            'getDisplayLength',
            'getCurrentProject',
            'getProjectProperty',
            'getLazyLoadFetching',
            'getTotalKeywordsPages',
            'getKeywordsIsFetching',
        ]),
        keywordsTablesClasses() {
            return {
                'keywords-tables keyword-table': true,
                'keywords-tables_empty':
                    this.keywords &&
                    !this.keywords.length &&
                    !this.getKeywordsIsFetching,
            };
        },
        isSearch() {
            return this.$route.name === 'searchResults';
        },
        showNewTable() {
            return this.getOtherSetting('newKwTable') === 'show';
        },
        longTagList() {
            let keyword = this.keywords.find(k => k.id === this.getShowAllTags);

            if (keyword) {
                return [...keyword.tagsSplited].splice(1);
            }

            return [];
        },
    },
    watch: {
        getShowAllTags(to) {
            if (to) {
                let position = document.querySelector(`#tag-${to} .more-tags-button`)?.getBoundingClientRect();
                let relative = document.getElementsByClassName('keywords-table__wrapper')[0]?.getBoundingClientRect();
                this.$refs['all-tags'].style.top = (position.top - relative.top) + 'px';
                this.$refs['all-tags'].style.left = (position.right - relative.left + 10) + 'px';
            }
        },
    },
    methods: {
        ...mapActions([
            'setCurrentPage',
            'fetchKeywords',
            'fetchKeywordsForShareViewKeys',
        ]),
        async keywordsPageChanged(page) {
            const { getDisplayLength, getCurrentPage, getTotalKeywords } = this;

            let pageToGo = page;

            if (page === 'next') {
                pageToGo = Math.min(
                    getCurrentPage + 1,
                    Math.ceil(getTotalKeywords / getDisplayLength),
                );
            } else if (page === 'prev') {
                pageToGo = Math.max(getCurrentPage - 1, 1);
            }

            this.setCurrentPage(pageToGo);

            if (isSharedPage(this.$route)) {
                const data = {
                    shortkey: this.getProjectProperty('shortkey'),
                    id: this.getProjectProperty('auth'),
                    password: this.getProjectProperty('password'),
                    not_reset_page: true,
                };

                this.fetchKeywordsForShareViewKeys(data);
            } else {
                await this.fetchKeywords({
                    id: this.getCurrentProject.id,
                    not_reset_page: true,
                });
            }
        },
        tableResizeHandler(e) {
            this.commonLineWidth = getComputedStyle(e, null).getPropertyValue(
                'width',
            );
        },
        hideAllTags() {
            this.setShowAllTags();
        },
    },
};
</script>

<style lang="scss" scoped>
.keywords-table__wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    height: 100%;
    position: relative;
    width: 100%;

    .all-tags-class {
        @import '~sass/partials/tags';

        background-color: kw-color(kw-white, 1);
        border-radius: 4px;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1), 0 0 3px rgba(0, 0, 0, 0.05);
        max-width: 200px;
        max-height: 120px;
        display: flex;
        overflow: auto;
        padding: 4px 8px 1px 8px;
        position: absolute;
        flex-flow: row wrap;
        z-index: 3;

        .tag-name {
            margin-right: 3px;
            margin-bottom: 3px;
            padding: 0 5.2px;
            max-width: 100%;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
</style>
