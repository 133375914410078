<template>
     <div class="block-wrapper">
        <div class="header-wrapper">
            <chart-header
                :title="$t('available-chart-titles.organic_traffic_acquisition_full')"
                :toolTip="$t('organic_traffic_acquisition-tooltip')"
                isHidable
                @onHide="onHide"
                :isHideDisabled="isLoading"
            />
        </div>
        <p class="info"
            v-if="lastUpdateDate"
            v-html="
                $t('organic_traffic_acquisition-last-update', {
                    domain: this.domain,
                    date: this.lastUpdateDate,
                })
            ">
        </p>

        <div class="chart">
            <organic-traffic-acquaisition-chart
                v-if="getCurrentProject"
                :key="`organic_traffic_acquisition_${chartKey}`"
                :loading="isLoading"
                :project="getCurrentProject"
                :data="data"
            />
        </div>
    </div>
</template>
 
<script>
    import { mapGetters, mapActions } from "vuex";
    import OrganicTrafficAcquaisitionChart from "./OrganicTrafficAcquaisitionChart.vue";
    import ChartBlockHeader from "./ChartBlockHeader.vue";
    import { formatRelativeTime, switchDateFormat } from "@/helpers/format-date-service";

    export default {
        name: 'OrganicTrafficAcquaisitionBlock',
        components: {
            "organic-traffic-acquaisition-chart": OrganicTrafficAcquaisitionChart,
            "chart-header": ChartBlockHeader
        },
        data() {
            return {
                isLoading: false,
                isFetching: false,
                chart: null,
                domain: '',
                lastUpdateDate: '',
                loaded: false,
                colors: ['#0074d9', '#5fca7a', '#ed7e16'],
                data: null
            }
        },
        computed: {
            ...mapGetters([
                "getCurrentProject",
                "getProjectVisibility",
                "getActiveTag"
            ]),
            chartKey(){
                return this.getActiveTag?.id || this.getCurrentProject?.id;
            }
        },
        watch: {
            getActiveTag: function() {
                this.fetchData();
            },
        },
        mounted(){
            if(!this.data && this.getCurrentProject?.project_id){
                this.fetchData();
            }
        },
        methods: {
            ...mapActions([
                "setProjectOverviewOptionIsVisible",
                "fetchAnalyticsChartData"
            ]),
            onHide() {
                this.isLoading = true;
                this.setProjectOverviewOptionIsVisible({ 
                    projectId: this.getCurrentProject.id,
                    option: "organic_traffic_acquisition",
                    value: false 
                }).finally(()=>{
                    this.isLoading = false;
                });
            },
            async fetchData() {
                this.data = null;
                this.lastUpdateDate = '';
                this.domain = '';
                try {
                    this.isLoading = true;
                    this.loaded = false;
                    const chartData = await this.fetchAnalyticsChartData(
                        this.getCurrentProject,
                    );

                    if (!chartData) return;

                    let dataByDate = chartData?.attributes?.ga_data_by_date ?? {};
                    this.domain = chartData?.attributes?.title ?? '';
                    this.lastUpdateDate = chartData?.attributes?.last_update ?
                        this.formatDate(this.parseDate(chartData.attributes.last_update)) : 
                        '';

                    const entries = Object.entries(dataByDate);
                    const lastTwoEntries = entries.slice(-2);
                    const lastTwoObj = Object.fromEntries(lastTwoEntries);

                    this.data = lastTwoObj;
                    this.loaded = true;
                } catch (error) {
                    this.loaded = false;
                } finally {
                    this.isLoading = false;
                }
            },
            resizeChart() {
                if (this.chart) {
                    this.chart.reflow();
                }
            },
            formatDate(date) {
                return `${formatRelativeTime(date)} (${switchDateFormat(date, 'M dS, Y hh:mm')})`;
            },
            parseDate(dateStr) {
                // Remove the 'th' from the date
                const cleanDateStr = dateStr.replace('th', '');
    
                // Create a Date object by manually parsing the string
                const [month, day, year, time] = cleanDateStr.match(/(\w+)\s+(\d+),\s+(\d+)\s+at\s+(\d+:\d+)/).slice(1, 5);
                
                const date = new Date(`${month} ${day}, ${year} ${time}`);
                
                // EST is UTC-5, so add 5 hours to convert to UTC
                const utcDate = new Date(date.getTime() + 5 * 60 * 60 * 1000);

                return utcDate;
            }
        },
    };
</script>
 
<style lang="scss" scoped>
.block-wrapper {
    width: 100%;
   
    .info {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: kw-color(kw-light-grey, 8);
        margin: 10px 0 0;
    }

    .chart {
        min-width: 300px;
    }
}
</style>
