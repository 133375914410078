<template>
    <div :id="`tag-${params.data.id}`">
        <span
            v-if="params.data.tagsSplited.length"
            :class="tagsClasses(params.data.tagsSplited[0], params.data.tagsSplited.length)"
            @click.stop="setActiveTagsWithUpdate(params.data.tagsSplited[0])"
            :data-important-tooltip="params.data.tagsSplited[0].name"
            v-text="params.data.tagsSplited[0].name"
        />
        <span
            v-if="params.data.tagsSplited.length > 1"
            class="more-tags-button"
            @click="setShowAllTags(params.data.id)"
        >
            {{ `+${params.data.tagsSplited.length - 1}` }}
        </span>
    </div>
</template>

<script>
import TagsManagingMixin from '@/mixins/TagsManagingMixin';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
export default {
    name: 'TagRenderer',
    mixins: [ToolTipRenderMixin, TagsManagingMixin],
};
</script>

<style lang="scss" scoped>
@import '~sass/partials/tags';
.more-tags-button {
    color: kw-color(kw-grey, 1);
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    margin-left: 5px;
    max-height: 16px;

    & ~ .tag-name {
        max-width: calc(100% - 18.5px);
    }
}
</style>
