export default {
    'succ-project-msg-plural': 'Projects have been {action}',
    'succ-project-msg-single': '{type} has been {action}',
    'saved-changes-msg': 'Changes saved successfully!',
    'coupon-applied-msg': 'Coupon applied successfully!',
    'add-note-msg': 'Note successfully added',
    'note-delete-ok': 'Note was deleted successfully',
    'note-saved-ok': 'Note saved successfully',
    'success-password-refreshed': 'Please check your e-mail to get refresh password link',
    'new-password-sended': 'New password sended to your e-mail',
    'addon-user-added': 'User {user} added',
    'addon-deleted-user': 'User {user} deleted',
    'addon-updated-user': 'User {user} updated',
    'invoice-options-save-success-msg': 'Invoice info saved',
    'plan-update-message': 'You successfully updated your plan!',
    'plan-upgrade-message': 'You successfully upgraded {plan} plan!',
    'plan-downgrade-message': 'You successfully downgraded {plan} plan!',
    'billing-card-add-success': 'Card has been added',
    'report-successfully-added': 'Report Successfully Added',
    'report-successfully-edited': 'Report successfully updated.',
    'report-successfully-deleted': 'Report Successfully Deleted',
    'email-seccessfully-sent': 'Email was successfully sent!',
    'trial-message': 'You are now on the 14-day free trial',
    'payment-successful': 'Payment Successful',
    'refresh-limit-success': 'Your refresh limit has been reset.'
};
