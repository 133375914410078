import guards from '../guards';

import Auth from 'pages/auth/Auth';
import Users from 'pages/auth/Users';
import Login from 'pages/auth/components/forms/Login';
import Signup from 'pages/auth/components/forms/Signup';
import ThankYou from 'pages/auth/components/ThankYou';
import EmailVerification from 'pages/auth/components/EmailVerification';
import ForgotPassword from 'pages/auth/components/forms/ForgotPassword';
import SetNewPassword from 'pages/auth/components/forms/SetNewPassword';

export default [
    {
        path: '/users',
        name: 'Users',
        components: { Users },
        redirect: {
            name: 'login',
        },
        children: [
            {
                path: '',
                name: 'Auth',
                component: Auth ,
                children: [
                    {
                        path: 'sign_in',
                        name: 'login',
                        component: Login,
                    },
                    {
                        path: 'sign_up',
                        name: 'signup',
                        component: Signup,
                    },
                    {
                        path: 'forgot-password',
                        name: 'forgotPassword',
                        component: ForgotPassword,
                    },
                    {
                        path: 'set-new-password',
                        name: 'setNewPassword',
                        component: SetNewPassword,
                    },
                ],
            },
            {
                path: 'thank-you',
                name: 'thankYou',
                component: ThankYou,
            },
            {
                path: 'verification',
                name: 'emailVerification',
                component: EmailVerification,
            },
        ],
        beforeEnter: guards('logPage'),
    },
];
