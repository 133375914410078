<template>
    <div
        v-if="staticHeaders && items"
        :data-cy="`${cyAttribute}-table-static`"
        class="app-table app-table_static"
        :style="staticTableStyles"
    >
        <div
            class="app-table__header"
            :data-cy="`${cyAttribute}-static-table-header`"
        >
            <div
                class="header-row"
                :data-cy="`${cyAttribute}-static-table-header-row`"
            >
                <div
                    v-if="isCheckboxVisible"
                    :class="getClasses(tableParts.HEADER, checkboxOptions)"
                    class="column-header column-data_checkbox column-header_checkbox"
                    :style="checkboxStyles"
                    :data-cy="`${cyAttribute}-static-table-header-column`"
                    @click="emitEvent('header-checkbox-click', checkboxField)"
                    @click.stop="emitEvent('header-checkbox-click', 'stop', checkboxField)"
                >
                    <checkbox
                        id="hyphen-app"
                        :icon-class="checkBoxIconClass"
                        :checkbox="checkboxOptions.isHeaderCheckboxActive"
                        @checkbox-click="emitEvent('header-checkbox-click', checkboxOptions)"
                    />
                </div>
                <template
                    v-for="header in filteredStaticHeaders"
                >
                    <div
                        :key="header.name"
                        :data-cy="`${cyAttribute}-static-table-header-column`"
                        :class="getClasses(tableParts.HEADER, header)"
                        class="column-header"
                        :style="getStyles(tableParts.STATIC_BODY_COLUMN, header)"
                        @click="emitEvent('header-column-click', header)"
                    >
                        <div
                            class="column-header__title"
                            v-html="header.title"
                        />
                        <div
                            v-if="header.sorter"
                            class="column-header__sort-container"
                            :class="sortingOptions.direction"
                        >
                            <span
                                v-if="header.sorter === sortingOptions.field"
                                class="kw kw-sort"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="app-table__body">
            <virtual-list
                id="virtual-static"
                :data-cy="`${cyAttribute}-static-table-body`"
                style="width: 100%;"
                :debounce="50"
                :remain="50"
                :pagemode="true"
                :size="VIRTUAL_LIST_ITEM_HEIGHT"
                :wclass="getVirtualListWClass('left')"
            >
                <div
                    v-for="(item, index) of items"
                    :key="index.id || index"
                    :class="getClasses(tableParts.BODY_ROW, item)"
                    :data-cy="`${cyAttribute}-static-table-row`"
                >
                    <div
                        :id="`row-static-${item.id}`"
                        :class="getTableRowInnerClasses(index)"
                        data-cy="row-with-keyword-and-hover"
                        @mouseover="setHoverState(item.id)"
                        @mouseleave="removeHoverState(item.id)"
                    >
                        <div
                            v-if="isCheckboxVisible"
                            :key="`checkbox-${item.id}`"
                            :class="[checkboxField.bodyClass]"
                            :style="checkboxStyles"
                            :data-cy="`${cyAttribute}-static-table-body-column`"
                            @click.ctrl="emitEvent('body-checkbox-click', 'ctrl', $event, item, index)"
                            @click.exact="(e) => emitEvent('body-column-click', 'exact', e, item, index, checkboxField)"
                            @click.meta="emitEvent('body-checkbox-click', 'ctrl', $event, item, index)"
                            @click.shift="(e) => emitEvent('body-column-click', 'shift', $event, item, index, checkboxField)"
                        >
                            <div class="row-wrap">
                                <div
                                    class="checkbox-block"
                                    @click.stop="emitEvent('body-checkbox-click', 'stop', $event, item, index)"
                                >
                                    <checkbox
                                        :id="`${tableName}-${item.id}`"
                                        :checkbox="false"
                                        @checkbox-click="emitEvent('body-checkbox-click', $event, item, index)"
                                    />
                                </div>
                            </div>
                        </div>

                        <template
                            v-for="header in filteredStaticHeaders"
                        >
                            <div
                                :key="`row-item-${header.name}`"
                                :data-cy="`${cyAttribute}-static-table-body-column`"
                                :class="getClasses(tableParts.BODY_COLUMN, header)"
                                :style="getStyles(tableParts.STATIC_BODY_COLUMN, header)"
                                @click.ctrl="(e) => emitEvent('body-column-click', 'ctrl', e, item, index, header)"
                                @click.exact="(e) => emitEvent('body-column-click', 'exact', e, item, index, header)"
                                @click.meta="(e) => emitEvent('body-column-click', 'meta', e, item, index, header)"
                                @click.shift="(e) => emitEvent('body-column-click', 'shift', e, item, index, header)"
                            >
                                <div class="row-wrap">
                                    <div
                                        v-if="!header.component"
                                        class="table-cell"
                                        v-html="processField(item, header)"
                                    />
                                    <component
                                        :is="header.component"
                                        v-else
                                        :key="`row-item-${header.name}`"
                                        v-bind="header.props(item, header, $parent.$emit)"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </virtual-list>
        </div>
    </div>
</template>
<script>
    import CustomTableMixin from '@/mixins/customTableMixin';

    export default {
        name: 'StaticTable',

        mixins: [CustomTableMixin],

        props: {
            items: {
                type: Array,
                required: true,
            },
            tableName: {
                type: String,
                required: true,
            },
            staticHeaders: {
                type: Array,
                required: false,
                default: null
            },
            sortingOptions: {
                type: Object,
                required: true
            },
            isActiveHover: {
                type: Boolean,
                required: false,
                default: false,
            },
            checkboxOptions: {
                type: Object,
                required: true,
            },
            fieldFormatter: {
                type: Function,
                required: true,
            },
            cyAttribute: {
                type: String,
                required: true,
            }
        },

        computed: {
            //this computed property calculates and returns in the style object the width of the static part of the table in pixels and in percent
            staticTableStyles() {
                const { widthInPixels, widthInPercentage } = this.getStaticTableWidth()
                const formattedWidthInPixels = `${widthInPixels}px`;
                const formattedWidthInPercentage = `calc(${widthInPercentage}% + ${this.checkboxWidth}px)`;
                return this.generateColumnWidthStyles(formattedWidthInPixels, formattedWidthInPercentage)
            },

            filteredStaticHeaders() {
                return this.staticHeaders.filter(header => header.name !== 'checkbox');
            },

            checkboxField() {
                return this.staticHeaders.find(header => header.name === 'checkbox');
            },

            isCheckboxVisible() {
                return (this.checkboxOptions.showCheckbox && this.checkboxField);
            },

            checkboxWidth() {
                const checkboxWidth = this.isCheckboxVisible ? this.checkboxField.width : 0;
                return checkboxWidth;
            },

            checkboxStyles() {
                const checkboxFormattedWidth = `${this.checkboxWidth}px`;
                const checkboxStyles = this.generateColumnWidthStyles(checkboxFormattedWidth);
                return checkboxStyles;
            },

            checkBoxIconClass() {
                const iconClass = this.checkboxOptions.isAllChecked ? 'kw kw-check': 'kw kw-minus';
                return iconClass;
            }
        },

        methods: {
            //this method calculates the width of the static part of the table
            getStaticTableWidth() {
                return this.filteredStaticHeaders.reduce((acc, header) => {
                    acc.widthInPixels += header.width;
                    acc.widthInPercentage += header.percentWidth;
                    return acc;
                }, { widthInPixels: this.checkboxWidth, widthInPercentage: 0 });
            },

        }
    };
</script>

<style lang="scss">
// important class for overflow shadows of dynamic table
.header-row_with-shadow {
    &:before {
        background: linear-gradient(to right, rgba(31, 63, 97, 0.07) 0%, rgba(31, 63, 97, 0) 97.37%);
        content: '';
        height: 100%;
        left: 100%;
        position: absolute;
        width: 8px;
        z-index: 1;
    }
}

// important class for overflow shadows of dynamic table
.table-row__inner_with-shadow {
    &:after {
        background: linear-gradient(to right, rgba(31, 63, 97, 0.07) 0%, rgba(31, 63, 97, 0) 97.37%);
        content: '';
        height: 34px;
        left: 100%;
        position: absolute;
        width: 8px;
    }
}

.app-table_static {
    flex: 1 1 100%;
    max-width: 330px;
    min-width: 270px;

    .column-header_checkbox {
        justify-content: center;
        position: sticky;
        left: 0;
        position: relative;
        z-index: 1;

        .column-header__sort-container {
            margin-left: 0;
        }

        .checkbox {
            position: relative;
            top: auto;
        }
    }

    .column-data_checkbox {
        background-color: kw-color(kw-white, 1) !important;
        position: sticky;
        left: 0;
        z-index: 2;

        .row-wrap {
            padding: 0;
            width: 100%;
            height: 100%;
        }

        .checkbox-block {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .checkbox {
            position: relative;
            top: 0;
        }
    }

    .table-row__inner {
        // border-left: 1px solid #E1E8EF;
        &:after {

            background: linear-gradient(to right, rgba(31, 63, 97, 0.07) 0%, rgba(31, 63, 97, 0) 97.37%);
            content: '';
            height: 32px;
            left: 100%;
            position: absolute;
            transform: scale(0);
            width: 8px;
        }
    }

    // header
    .column-header {
        &.column-header_app {
            border-top-right-radius: 0;
        }
    }

    // body
    .app-table__body {
        position: relative;
        z-index: 1;
    }

    .table-row_fav {
        .table-row__inner {
            & > span.kw-favorite {
                color: #F4A732;
                font-size: 8px;
                left: 35px;
                line-height: 8px;
                position: absolute;
                width: 8px;
                top: 13px;
                z-index: 1;
                @media screen and (max-width: 959px) {
                    font-size: 7px;
                    top: 16px;
                }
            }
        }
    }

    .column-data {
        &:nth-child(2) {
            border-left: 1px solid #E1E8EF;
        }

        &.column-data_app {
            position: relative;

            .table-cell {
                position: static;
            }
        }
    }
}

// if table grouped by keyword and column kw removed from table
.app-table_shrinked {
    flex-grow: 0;
    min-width: unset;
}

//responsive design
@media (max-width: 959px) {
    .app-table_static {
        flex: 1 1 auto;
        min-width: 176px;

        &.app-table_shrinked {
            flex: 0 1 auto;
        }

        // header
        .column-header {
            &.column-header_app {
                width: 100%;
                flex: unset !important;
            }

            &:first-child {
                border-top-left-radius: 0;
            }

            &:not(.column-header_checkbox) {
                padding: 0 10px;
            }
        }

        .table-row {
            .column-header_checkbox {
                display: none;
            }

            .column-data {
                user-select: none;

                &:first-child {
                    border-left: none;
                }

                &.column-data_app {
                    flex: unset !important;
                    padding: 0 15px !important;
                    width: 100%;

                    .table-cell__actions {
                        display: none !important;
                    }
                }

                &:not(.column-data_checkbox) {
                    padding: 0 10px;
                }
            }
        }

        .table-row__inner {
            &:after {
                height: 40px;
            }
        }
    }
}
</style>
