import client from '../client';
import interceptors from '../interceptors';
const currenciesInterceptor = interceptors.get('currencies');

client.interceptors.response.use(
    currenciesInterceptor.s,
    currenciesInterceptor.e,
);

export default {
    fetchCurrencies: async () => await client.get('/currency/rates'),
};
