<template>
    <div
        class="pdf-keyword-chart"
    >
        <h4 class="pdf_title_block">
            {{ getPdfTitleText }}
        </h4>
        <div
            :id="`highcharts_${itemIndex}`"
            :ref="`highcharts_${itemIndex}`"
            :style="`height: ${getOtherSetting('chartheight')}px;`"
        />
        <div class="chart-periods">
            <span
                class="view-title"
                v-html="'view:'"
            />

            <span
                v-for="(period, index) in periodsConfig"
                :key="index"
                :class="periodClasses(period.value)"
            >
                {{ period.text }}
            </span>
        </div>
    </div>
</template>

<script>
    import {config, addSeriesToOptions, periodsConfig} from '../configs/chartConfig';
    import {mapGetters, mapActions} from 'vuex';
    import Exporting from 'highcharts/modules/exporting';
    import Highcharts from 'highcharts';
    import NoData from 'highcharts/modules/no-data-to-display';
    Exporting(Highcharts);
    NoData(Highcharts);
    export default {
        name: 'PdfKeywordChart',
        props: {
            series: {
                type: Array,
                required: true,
            },
            itemIndex: {
                type: Number,
                required: false,
                default: () => 0,
            }
        },
        data () {
            return {
                chart: null,
                periodsConfig,
            }
        },
        computed: {
            ...mapGetters([
                'getKeywordSetting',
                'getOtherSetting',
                'getOriginalItems',
            ]),
            // computed property that configurates charts
            optionsWithDataForShow () {
                const chartOptions = config(
                    // list of notes
                    {},
                    // list of project ids
                    [],
                    // chart point click handler (for note adding)
                );
                const options = {
                    ...chartOptions,
                    legend: {
                        align: 'center',
                    }
                }
                chartOptions.chart.plotBackgroundImage = null;
                options.plotOptions.series.animation = false;
                //chartOptions.chart.height = this.getOtherSetting('chartheight');
                const series = this.series;
                return addSeriesToOptions({getSeries: series, chartOptions: options, notes: []});
            },
            getPdfTitleText () {
                const currectKeyword = this.getOriginalItems.find((item) => item.id == this.series[0].kwId)
                return `${currectKeyword.url} - ${currectKeyword.kw} (${currectKeyword.region})`;
            }
        },
        async mounted () {
            this.renderChart();

        },
        methods: {
            ...mapActions([
                'fetchChartData',
            ]),
            renderChart () {
                // validation for case when user closes chart after opening immediately
                if (document.getElementById(`highcharts_${this.itemIndex}`)) {
                    this.chart = new Highcharts.chart(`highcharts_${this.itemIndex}`, this.optionsWithDataForShow);
                    const chartBG =document.getElementsByClassName('highcharts-container')[this.itemIndex].querySelector('image');
                    if (chartBG) {
                        // save aspect ratio of image tag of svg chart
                        chartBG.setAttribute('preserveAspectRatio', 'xMidYMid slice');
                    }
                }
            },
            periodClasses (periodValue) {
                return {
                    'period-item': true,
                    'selected-period': this.getKeywordSetting('chartPeriod') === periodValue,
                };
            },
        },
    };

</script>

<style lang="scss" scoped>

    .pdf-keyword-chart {
        border: solid 1px kw-color(kw-light-grey, 5);
        border-radius: 4px;
        padding: 40px 0 10px 0 ;
    }

    .chart-periods {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -20px;
        .selected-period {
            font-weight: bold;
        }
        .period-item {
            cursor: pointer;
            @media screen and (max-width: 959px) {
                margin-left: 2vw;
                text-align: center;
            }
        }
        .period-item:after {
            content: '-';
            @media screen and (max-width: 959px) {
                display: none;
            }
            margin: 0 3px;
            font-weight: normal;
        }
        .period-item:last-child:after {
            display: none;
        }
        .view-title {
            margin-right: 3px;
        }
        .show-ranking-urls {
            cursor: pointer;
            @media screen and (max-width: 959px) {
                margin-left: 2vw;
                text-align: center;
                white-space: normal;
                line-height: 12px;
            }
            &:disabled {
                color: #909399;
                cursor: not-allowed;
            }
        }
    }
</style>

