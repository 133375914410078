<template>
    <div class="display-settings__columns" data-cy="settings-table-columns">
        <span class="table_columns_icon table_setting_icon" />
        <h2 class="settings__subtitle settings__subtitle_columns">
            <span>{{ $t('project-table-columns') }}</span>
            <div class="settings__subtitle_column_show_in">
                <span class="show-in-viewkeys">
                    {{ $t('show-in-viewkeys') }}
                </span>
                <span class="show-in-pdf">
                    {{ $t('show-in-pdf') }}
                </span>
            </div>
        </h2>
        <table-columns-row
            v-for="column in getColumnsConfig.filter(
                el => !el.required && !isGSCColumns(el.name),
            )"
            :key="column.apiAlias"
            :column="column"
            :toggleColumn="toggleColumn"
            :toggleViewkeyColumn="toggleViewkeyColumn"
            :togglePdfColumn="togglePdfColumn"
        />
        <span class="settings__subtitle_gsc_connect">{{
            $t('connect-google-search-console-settings')
        }}</span>
        <table-columns-row
            v-for="column in getColumnsConfig.filter(el =>
                isGSCColumns(el.name),
            )"
            :key="column.apiAlias"
            :column="column"
            :disabled="!getUserDataProp('gscConnected')"
            :toggleColumn="toggleColumn"
            :toggleViewkeyColumn="toggleViewkeyColumn"
            :togglePdfColumn="togglePdfColumn"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { KW_TABLE_COLUMNS } from '@/constants';
import TableColumnsRow from './TableColumnsRow';
export default {
    name: 'ColumnsSettings',
    components: { TableColumnsRow },
    computed: {
        ...mapGetters([
            'getUserDataProp',
            'getColumnsConfig',
            'getPdfShowColumns',
        ]),
    },
    methods: {
        ...mapActions(['updatePdfSettings', 'updateUserTableSettings']),
        togglePdfColumn(alias) {
            const pdfShowColumns = _.cloneDeep(this.getPdfShowColumns);
            let index = pdfShowColumns.indexOf(alias);

            if (index !== -1) {
                pdfShowColumns.splice(index, 1);
            } else {
                pdfShowColumns.push(alias);
            }

            this.updatePdfSettings({ show_columns: pdfShowColumns });
        },
        toggleColumn(alias) {
            const i = this.getColumnsConfig.findIndex(
                el => el.apiAlias === alias,
            );
            this.updateUserTableSettings({
                setting: alias,
                value: !this.getColumnsConfig[i].enabled,
            });
        },
        toggleViewkeyColumn(reportAlias) {
            const i = this.getColumnsConfig.findIndex(
                el => el.apiAliasReports === reportAlias,
            );
            this.updateUserTableSettings({
                setting: reportAlias,
                value: !this.getColumnsConfig[i].disabledInReports,
            });
        },
        isGSCColumns(field) {
            return KW_TABLE_COLUMNS.GSC.includes(field);
        },
    },
};
</script>

<style lang="scss" scoped>
.display-settings__columns {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    padding: 43px 24px;

    .settings__subtitle_columns {
        margin: 0 0 10px;
    }
}
.settings__subtitle_column_show_in {
    width: 100px;
    display: flex;
    justify-content: space-between;
}
.show-in-viewkeys {
    text-align: right;
    width: 50px;
}
.show-in-pdf {
    text-align: right;
    width: 45px;
}
.settings__subtitle_gsc_connect {
    font-weight: 500;
    font-size: 13px;
    margin: 13px 0;
}
</style>
