import client from '../client';
import interceptors from '../interceptors';
const addonsInterceptor = interceptors.get('addons');

client.interceptors.response.use(
    addonsInterceptor.s,
    addonsInterceptor.e,
);

const resource = '/addon/users';

const prepareData = payload => ({
    data: {attributes: payload}
});

export default {
    fetch: async () => await client.get(resource),
    add: async payload => await client.post(resource, prepareData(payload)),
    delete: async id => await client.delete(`${resource}/${id}`),
    update: async (id, payload) => await client.patch(`${resource}/${id}`, prepareData(payload)),
};