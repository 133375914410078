<script>
    import { mapActions } from 'vuex';
    import { aside } from 'vue-strap';
    import $ from '../../../node_modules/vue-strap/src/utils/NodeList.js';

    export default {
        extends: aside,
        watch: {
            show (val) {
                this.addBackdrop(val);
            }
        },
        created () {
            if (!this._backdrop) {
                const createdDiv = document.createElement('div');
                createdDiv.setAttribute('data-cy', 'aside-backdrop');
                this._backdrop = createdDiv;
            }
        },
        mounted () {
            this.addBackdrop(this.show);
        },
        methods: {
            ...mapActions([
                'closeAllBars',
                'resetCheckedProjects',
            ]),
            closeAllAndUnselect () {
                this.resetCheckedProjects();
                this.closeAllBars();

                if (this.$route.name === 'archived') {
                    window.history.length > 2
                        ? this.$router.go(-1)
                        : this.$router.push({name: 'Overview'});
                }
            },
            addBackdrop (show) {
                this.$emit('input', show);
                this.$emit(this.show ? 'open' : 'close');
                const body = document.body;
                const bodyWidth = getComputedStyle(body).width;

                if (show) {
                    if (!this._backdrop) {
                        this._backdrop = document.createElement('div');
                    }

                    this._backdrop.className = 'aside-backdrop';
                    this._backdrop.style.width = bodyWidth;
                    this._backdrop.style.margin = '0 auto';
                    body.appendChild(this._backdrop);
                    body.classList.add('modal-open');
                    body.style.paddingRight = '0';

                    // request property that requires layout to force a layout
                    //let x = this._backdrop.clientHeight
                    this._backdrop.classList.add('in');
                    $(this._backdrop).on('click', () => this.closeAllAndUnselect());
                } else {
                    $(this._backdrop).on('transitionend', () => {
                        $(this._backdrop).off();

                        try {
                            body.classList.remove('modal-open');
                            body.style.paddingRight = '0';
                            body.removeChild(this._backdrop);
                            this._backdrop = null;
                        } catch (e) {
                            //
                        }
                    })

                    if (!this._backdrop) {
                        //added condition to check if this._backdrop is not empty
                        this._backdrop = document.createElement('div');
                    }
                    
                    this._backdrop.className = 'aside-backdrop';
                    this._backdrop.style.width = bodyWidth;
                    this._backdrop.style.margin = '0 auto';
                }
            },
        },
    }

</script>
