const objToQueryString = (obj) => {
    const str = [];
    for (let p in obj)
      if (obj[p]) {
        if(Array.isArray(obj[p])) {
          obj[p].forEach(element => {
            str.push(`${p}[]` + '=' + encodeURIComponent(element));
          });
        }else {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
       
      }
    return str.join('&');
}

export default objToQueryString;