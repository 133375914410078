<template>
    <div class="justify-content-center sidebar-category-wrap custom-scroll">
        <div class="categories active-categories">
            <div class="header">
                <span class="title">
                    {{ $t('projects') }}
                </span>
            </div>
            <projects
                component="tree-navigation"
                :projects="categories"
                :loaded="getActiveProjectsLoaded"
            />
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Projects from 'components/left-sidebar/components/Projects';
    export default {
        name: 'ActiveProjects',
        components: {
            'projects': Projects,
        },
        props: {
            categories: {
                type: Array,
                required: true,
            },
        },
        computed: {
            ...mapGetters([
                'getActiveProjectsLoaded',
                'getUserSetting',
            ]),
        },
    }
</script>

<style lang="scss">
  .sidebar-category-wrap {
    height: 100%;
    overflow: hidden;
    padding-top: 3px;
  }

  .active-categories {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
      flex: 0 0 auto;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & > div {
        display: flex;
      }

      .title {
        color: #909399;
        letter-spacing: 1px;
        font-size: 12px;
      }
    }

    .card {
      @extend .custom-scroll;
      flex: 1 1 auto;

    }
  }

  .open {
    button {
      color: #3F4248;
    }
  }
</style>
