<template>
    <span v-if="!params.data.crawled_at" />
    <span v-else-if="params.value === 0" class="empty-value">> 100</span>
    <span v-else-if="params.value === null" class="empty-value no-value"
        >&mdash;</span
    >
    <span v-else>{{ params.value }}</span>
</template>

<script>
import RotatingUpdateIcon from '@/components/ui-elements/RotatingUpdateIcon';
export default {
    name: 'StartRenderer',
    components: {RotatingUpdateIcon},
};
</script>

<style lang="scss" scoped></style>
