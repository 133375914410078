<template>
    <div
        class="date-range"
        data-cy="date-range"
    >
        <div class="date-range__start">
            <label class="date-range__label">
                {{ startLabel }}
            </label>
            <span
                class="kw kw-information"
                @mouseover="tooltip({text: startLabel, event: $event})"
            />
            <datepicker
                class="datepicker_right-popup"
                format="dd MMMM yyyy"
                :value.sync="startValue"
                @input="e => setDate('start', e)"
            />
        </div>
        <div class="date-range__end">
            <label class="date-range__label">
                {{ endLabel }}
            </label>
            <span
                class="kw kw-information"
                @mouseover="tooltip({text: endLabel, event: $event})"
            />
            <datepicker
                class="datepicker_right-popup"
                format="dd MMMM yyyy"
                :value.sync="endValue"
                @input="e => setDate('end', e)"
            />
        </div>
    </div>
</template>

<script>
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    import VueStrapDataPicker from 'components/extended/VueStrapDataPicker';
    export default {
        name: 'DateRange',
        components: {
            'datepicker': VueStrapDataPicker,
        },
        mixins: [ToolTipRenderMixin],
        props: {
            endLabel: {
                type: String,
                required: false,
                default: '',
            },
            endValue: {
                type: String,
                required: true,
            },
            setDate: {
                type: Function,
                required: true,
            },
            startLabel: {
                type: String,
                required: false,
                default: '',
            },
            startValue: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>
  .date-range {
    display: flex;
  }

  .date-range__start {
    margin-right: 12px;
  }

  .date-range__label {
    color: kw-color(kw-black, 1);
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  /deep/ .datepicker-popup_custom {
    min-width: 232px;
    width: 250px;
  }

  /deep/ .datepicker-dateRange_custom span {
    width: 28px;
  }

  .kw-information {
    color: #828F9C;
    font-size: 12px;
    font-weight: 700;
    margin: 0 4px;
  }
</style>
