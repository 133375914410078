import {PROTOCOL_REGEXP} from '@/constants';
import {SET_REPORTS_SETTINGS, SET_COMPANY_SETTINGS} from '@/store/mutations';
import i18n from '@/i18n';
import toastr from '@/helpers/init-toastr';
import { cookieGetters } from '@/helpers/cookieManager';
const reports = {
    state: {
        reportsSettings: {
            emailbody: '',
            emailmonitor: '',
            emailsubject: '',
            mailfrom: '',
            mailsendername: '',
            monitorenabled: false,
            reportname: 'timestamp',
        },
        companySettings: {
            company_name: '',
            company_url: '',
            company_logo: '',
            company_desc: '',
        },
    },
    mutations: {
        [SET_REPORTS_SETTINGS]: (state, payload) => state.reportsSettings = payload,
        [SET_COMPANY_SETTINGS]: (state, payload) => state.companySettings = payload,
    },
    getters: {
        getReportsSettings: state => state.reportsSettings,
        getCompanySettings: state => state.companySettings,
    },
    actions: {
        async updateReportsSettings ({getters, dispatch}, settings) {
            const fd = new FormData();
            const params = {action: 'updatewhitelabel', auth: cookieGetters.getApiKey()};
            let res;

            Object.keys(settings)
                .forEach(el => fd.append(el, settings[el]));

            Object.keys(getters.getCompanySettings)
                .forEach(el => fd.append(el, getters.getCompanySettings[el]));

            try {
                res = await axios.post('', fd, {params});

                if (res && res.data.RESPONSE === 'FAILURE') {
                    return toastr.e(res.data.MESSAGE);
                }

                dispatch('setReportsSettings', settings);
                toastr.s(i18n.t('saved-changes-msg'));
            } catch (error) {
                toastr.e(i18n.t('settings-update-error'));
            }
        },
        async updateCompanySettings ({getters, dispatch}, settings) {
            const fd = new FormData();
            const params = {action: 'updatewhitelabel', auth: cookieGetters.getApiKey()};
            let res;

            Object.keys(settings).forEach(el => {
                // if company url has no protocol - we should add it before request
                el === 'company_url'
                    ? !PROTOCOL_REGEXP.test(settings[el])
                        ? fd.append(el, `http://${settings[el]}`)
                        : fd.append(el, settings[el])
                    : fd.append(el, settings[el]);
            });

            Object.keys(getters.getReportsSettings)
                .forEach(el => fd.append(el, getters.getReportsSettings[el]));

            try {
                res = await axios.post('', fd, {params});

                if (res && res.data.RESPONSE === 'FAILURE') {
                    return toastr.e(res.data.MESSAGE);
                }

                dispatch('setCompanySettings', settings);
                toastr.s(i18n.t('saved-changes-msg'));
            } catch (error) {
                toastr.e(i18n.t('settings-update-error'));
            }
        },
        setReportsSettings({commit, getters}, payload = {}) {
            const reportsSettings = getters.getReportsSettings;
            const newSettings = {
                ...reportsSettings,
            }
            Object.keys(payload).forEach(key => newSettings[key] = payload[key]);
            commit(SET_REPORTS_SETTINGS, newSettings)
        },
        setCompanySettings({commit, getters}, payload = {}) {
            const companySettings = getters.getCompanySettings;
            const newSettings = {
                ...companySettings,
            }
            Object.keys(payload).forEach(key => newSettings[key] = payload[key] ?? '');
            commit(SET_COMPANY_SETTINGS, newSettings)
        },

    },
};

export default reports;
