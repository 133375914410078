<template>
    <div
        class="app-table__wrapper custom-table_container"
        :data-cy="`${cyAttribute}-table-wrap`"
    >
        <template v-if="!isItemsFetching && items.length">
            <div
                v-resize="tableResizeHandler"
                class="app-tables app-table"
            >
                <static-table
                    :key="`static-table-${items.length}`"
                    ref="static"
                    :items="items"
                    :static-headers="staticHeaders"
                    :table-name="tableName"
                    :sorting-options="sortingOptions"
                    :checkbox-options="checkboxOptions"
                    :field-formatter="fieldFormatter"
                    :active-hover="isActiveHover"
                    :cy-attribute="cyAttribute"
                />

                <dynamic-table
                    :key="`dynamic-table-${items.length}`"
                    ref="dynamic"
                    :items="items"
                    :dynamic-headers="dynamicHeaders"
                    :table-name="tableName"
                    :sorting-options="sortingOptions"
                    :field-formatter="fieldFormatter"
                    :active-hover="isActiveHover"
                    :cy-attribute="cyAttribute"
                />
            </div>
            <table-pagination
                v-if="paginationOptions.isActive"
                :current-page="paginationOptions.currentPage"
                :on-change-page="page => $emit('page-change', page)"
                :per-page="paginationOptions.perPage"
                :total-pages="totalPages"
                :total-records="paginationOptions.total"
            />
        </template>
        <app-table-placeholder v-else />
    </div>
</template>

<script>
    import StaticTable from './StaticTable';
    import DynamicTable from './DynamicTable';
    import resize from 'vue-resize-directive';
    import KeywordsTablePlaceholder from '@/components/main-block/components/KeywordsTablePlaceholder';

    export default {
        name: 'Table',

        directives: { resize },

        components: {
            'static-table': StaticTable,
            'dynamic-table': DynamicTable,
            'app-table-placeholder': KeywordsTablePlaceholder

        },

        props: {

            isItemsFetching: {
                type: Boolean,
                default: false,
            },
            items: {
                type: Array,
                required: true,
            },
            headers: {
                type: Array,
                required: true,
            },
            tableName: {
                type: String,
                required: true,
            },
            checkboxOptions: {
                type: Object,
                required: true,
            },
            sortingOptions: {
                type: Object,
                required: false,
                default: () => ({}),
            },
            paginationOptions: {
                type: Object,
                required: false,
                default: () => ({ isActive: false }),
            },
            fieldFormatter: {
                type: Function,
                required: false,
                default: (item, header) => item[header.name],
            },
            cyAttribute: {
                type: String,
                required: false,
                default: 'app'
            },

            isActiveHover: {
                type: Boolean,
                required: false,
                default: false,
            },

        },

        computed: {
            staticHeaders() {
                const staticHeaders = this.headers.filter(field => field.isStatic);
                return staticHeaders;
            },

            dynamicHeaders() {
                const dynamicHeaders = this.headers.filter(field => !field.isStatic);
                return dynamicHeaders;
            },

            totalPages() {
                return Math.ceil(this.paginationOptions.total / this.paginationOptions.perPage);
            }
        },

        updated() {
            this.$emit('updated');
        },

        methods: {
            tableResizeHandler (e) {
                this.commonLineWidth = getComputedStyle(e, null).getPropertyValue('width');
            },
        },
    };
</script>


<style lang="scss">
.app-container {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    @media (max-width: 659px) {
        margin: 2px 0 0 0;
    }
}

.app-container_share-view {
    .app-table__header_fixed {
        top: 63px;
    }
}

.app-table__wrapper {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    height: 100%;
    position: relative;
    width: 100%;
}

@media (max-width: 959px) {
    .app-actions-is-open {
        padding-bottom: 316px;
    }
    .app-container_share-view {
        .app-table__header_fixed {
            top: 109px;
        }
    }
}

.app-tables {
    color: #333333;
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    margin-bottom: 10px;

    .loader {
        margin: calc((100vh - 360px) / 2) auto;
    }
}

.app-tables_empty {
    background-image: url(~img/cactus.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 200px;
    padding-bottom: 300px;
}

.app-table__header,
.app-table__body {
    display: flex;
    flex-flow: column wrap;
}

.app-table__header_fixed {
    @extend .hidden-scroll;
    overflow: scroll;
    position: fixed !important;
    top: 139px;
    z-index: 11;

    &.with_action_bar {
        top: 165px;
    }

    @media (max-width: 959px) {
        top: 79px;
        &.with_action_bar {
            top: 141px;
        }
    }
}

.header-row {
    display: flex;
    height: 33px;
    position: relative;
}

.column-header {
    align-items: center;
    background-color: #F8F9FA;
    border: 1px solid #E1E8EF;
    color: kw-color(kw-grey, 1);
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    justify-content: flex-start;
    letter-spacing: .2px;
    line-height: 14px;
    position: relative;
    user-select: none;
    white-space: nowrap;

    &:first-child {
        border-top-left-radius: 4px;
    }

    &:last-child {
        border-top-right-radius: 4px;
    }

    &:not(:first-child) {
        border-left: none;
    }

    &.column-header_safari {
        letter-spacing: -0.7px;
    }

    &:not(.column-header_checkbox) {
        padding: 0 15px;
    }

    .column-header__title {
        outline: transparent !important;
    }

    @media (max-width: 959px) {
        font-size: 9px;
    }
}

.column-header__sort-container {
    height: 16px;
    margin-left: 5px;
    position: relative;
    display: flex;
    align-items: center;

    span {
        font-size: 8px;
        color: kw-color(kw-grey, 1);
        transform: scaleX(.8) translateX(-1px);
    }

    &.asc {
        transform: scaleY(-1);
    }
}

.row-app-chart {
    background-color: kw-color(kw-white, 1);
    border-bottom: 1px solid #DEE5EC;
    padding: 0 8px;
    position: absolute;
    top: 35px;
    z-index: 1;
    @media screen and (max-width: 959px) {
        z-index: 3;
    }
}

.table-row {
    position: relative;

    &:last-child {
        .column-data {
            border-bottom: 1px solid #E1E8EF;
        }
    }

    &:not(.table-row_fav) {
        span.kw-favorite {
            display: none;
        }

        .table-row__inner_even {
            .column-data {
                background-color: #F7F8FA;
            }
        }
    }

    &.table-row_fav {
        .table-row__inner {
            .column-data {
                background-color: #FFF8EE;
            }
        }

        .table-row__inner_even {
            .column-data {
                background-color: #FDF2E0;
            }
        }
    }

    .table-row__inner {
        display: flex;
        height: 33px;
        position: relative;

        &.table-row__inner_hovered,
        &.table-row__inner_checked {
            .column-data {
                background-color: #E5ECF3;
            }
        }
    }

    .table-row__inner_hovered {
        .table-cell__actions {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .table-row__title {
        @import '~sass/cutting-name';
        background: kw-color(kw-white, 1);
        border-bottom: 1px solid #DEE5EC;
        border-top: 1px solid #DEE5EC;
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        height: 46px;
        margin-bottom: 0;
        margin-top: 0;
        outline: transparent;
        padding: 10px 0;
        position: absolute;
        top: -46px;

        .kw-corner-down-right {
            margin: 0 4px;
        }
    }

    .table-row__title_secondary {
        color: #909399;
        position: relative;

        &:after {
            color: kw-color(kw-black, 1);
            content: '\e96a';
            font-family: 'icon-font';
            padding: 4px 8px;
            position: relative;
        }
    }

    .row-wrap {
        font-size: 14px;
        display: grid;
        line-height: 16px;
        @media (max-width: 959px) {
            font-size: 12px;
        }
    }

    .column-data {
        align-items: center;
        background: kw-color(kw-white, 1);
        border-right: 1px solid #E1E8EF;
        display: flex;
        justify-content: center;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:not(.column-data_checkbox) {
            padding: 0 15px;

            .row-wrap {
                width: 100%;
            }
        }

        &.column-data_app {
            padding-right: 5px;
        }

        &.column-data_gpage, &.column-best_grank {
            span {
                text-align: right;
            }
        }

        &.column-data_url {
            padding: 0 15px 0 30px;
        }

        &.column-data_left {
            justify-content: flex-start;
        }

        &:first-child {
            border-left: 1px solid #E1E8EF;
        }
    }

    .table-cell {
        align-content: center;
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        position: relative;
        width: 100%;
    }

    .table-cell__text_ranks {
        display: flex;
        align-items: center;
    }

    .table-cell__text {
        @import '~sass/cutting-name';
        outline: transparent;
    }

    .table-cell__content_cut {
        display: grid;
        position: relative;
        width: 100%;
    }

    .table-cell__content-grank {
        margin-left: auto;

        &:after {
            word-break: break-word;
        }
    }
}

.search-results-disclaimer {
    text-align: center;

    p {
        color: #ccc;
    }
}

@media (max-width: 959px) {
    .table-row {
        .table-row__inner {
            height: 40px;
        }

        .table-row__title {
            padding-left: 5px;
        }
    }
}
</style>
