import client from '../client';
import interceptors from '../interceptors';
const eventTrackingInterceptor = interceptors.get('eventTracking');

client.interceptors.response.use(
    eventTrackingInterceptor.s,
    eventTrackingInterceptor.e,
);

const resource = '/event-tracking';

export default {
    trackMixpanelEvent: async payload => await client.put(`${resource}/mixpanel`, payload),
};
