<template>
    <div class="keywords-exceeded-modal">
        <div class="body">
            <div class="title">{{ $t('keywords-exceeded.title') }}</div>
            <div class="desc">{{ $t('keywords-exceeded.desc-1') }}</div>
            <div class="desc">{{ $t('keywords-exceeded.desc-2') }}</div>
        </div>
        <div class="footer">
            <div class="button primary" @click="redirectToPackages">
                {{ $t('upgrade-now') }}
            </div>
            <div class="button secondary" @click="close">
                {{ $t('dismiss') }}
            </div>
        </div>

        <global-events @keyup.enter="redirectToPackages" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cookieSetters } from '@/helpers/cookieManager';

export default {
    name: 'KeywordsExceededModal',
    mounted() {
        cookieSetters.setKeywordExceededModalShown(
            this.getUserDataProp('email'),
        );
    },
    computed: {
        ...mapGetters(['getUserDataProp']),
    },
    methods: {
        close() {
            this.$emit('close');
        },
        redirectToPackages() {
            this.$router.push({ name: 'Pricing' });
            this.close();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/modal';

.keywords-exceeded-modal {
    padding: 16px;

    .title {
        color: #000;
        font-size: 18px;
        font-family: Roboto;
        font-weight: 500;
        line-height: 32px;
    }

    .desc {
        margin-top: 16px;
        color: #24242c;
        font-size: 12px;
        font-family: Roboto;
        line-height: 16px;
    }

    .footer {
        margin-top: 24px;
        display: flex;
        gap: 16px;

        .button {
            cursor: pointer;
            width: 135px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            font-family: Roboto;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }

        .primary {
            color: kw-color(kw-white, 1);
            background: kw-color(kw-blue, 1);
            box-shadow: 0px 0px 1px 0px rgba(50, 73, 98, 0.2),
                0px 2px 5px 0px rgba(50, 73, 98, 0.15);
        }

        .secondary {
            color: kw-color(kw-blue, 1);
            border: 1px solid kw-color(kw-blue, 1);
            box-shadow: 0px 0px 1px 0px rgba(50, 73, 98, 0.2),
                0px 2px 5px 0px rgba(50, 73, 98, 0.15);
        }
    }
}
</style>
