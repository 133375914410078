import i18n from '@/i18n';

export const SERPFeatureColors = {
    'adwords-bottom': ['#06750f', '#06750f66'],
    'adwords-top': ['#049110', '#04911066'],
    'ai': ['#049110', '#04911066'],
    'ads': ['#E8BB48', '#E8BB4866'],
    'carousel': ['#355c5b', '#355c5b66'],
    'coupon_codes': ['#823143', '#82314366'],
    'faq': ['#ba3f02', '#ba3f0266'],
    'featured_snippet': ['#7d9c30', '#7d9c3066'],
    'image_pack': ['#C2465A', '#C2465A66'],
    'indeed-url': ['#108294', '#10829466'],
    'knowledge_panel': ['#44611E', '#44611E66'],
    'local_pack': ['#052963', '#05296366'],
    'ppl_also_ask': ['#27AE60', '#27AE6066'],
    'reviews': ['#9B59B6', '#9B59B666'],
    'shopping_results': ['#3498DB', '#3498DB66'],
    'site_links': ['#A2E0D8', '#A2E0D866'],
    'top_stories': ['#FFA875', '#FFA87566'],
    'tweets': ['#33AAFF', '#33AAFF66'],
    'video': ['#8A8AD6', '#8A8AD666'],
    'recipes': ['#FFA133', '#FFA13366'],
    'top_sights': ['#FF33AA', '#FF33AA66'],
};

export const createDifferenceColHTMLValue = (cur, prev, withDot, trunkate) => {
    const featured = cur - prev;
    const dot = `
        <span class="empty-value" style="width: 9px;">
            <span class="dot-neutral" style="margin: auto;"></span>
        </span>
    `;
    
    if(!featured || (trunkate && featured.toFixed(2) === '0.00')) {
        return withDot ? dot : 0;
    }

    return `<span><span class="icon-arrow_4${featured < 0 ? ' icon-rotate-down' : '' }"></span> ${Math.abs(trunkate ? featured.toFixed(2) : featured)}</span>`;
};

export const createSERPFeaturesNameColHTMLValue = (feature) => {
    return `<span class="feature-wrapper"><span class="kw-sf-icon black kw-sf-icon-${feature}"></span><span>${i18n.t(`serp-feature-${feature}`)}</span></span>`;
};

export const createSERPFeaturesChartColValue = (feature) => {
    const { total, featured, name, subtotal } = feature;

    return  {
        tooltip: `<div style="font-size: 12px; padding: 8px; font-weight: 500">
                    <p style="color: grey; margin: 0">${i18n.t(`serp-feature-${name}`)}</p>
                    <p style="margin: 0">${i18n.t('total')}: ${total}</p>
                    <p style="margin: 0">${i18n.t('featured')}: ${featured}</p>
                </div>`,
        data: [
            {
                color: SERPFeatureColors[name][1],
                value: `${(total / subtotal) * 100}%`
            },
            {
                color: SERPFeatureColors[name][0],
                value: `${(featured / subtotal) * 100}%`
            }
        ]
    }
};

export const createFeaturedColHTMLValue = (cur, prev) => {
    const dif = cur - prev;
    return `<span>${dif ? `<span class="icon-arrow_4${dif < 0 ? ' icon-rotate-down' : '' }"></span>` : ''} ${cur}</span>`;
};
