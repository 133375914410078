<template>
    <div
        v-if="projects && projects.length !== 0"
        class="tree-navigation-archived"
    >
        <div
            v-for="categoryParent in projects"
            :key="categoryParent.id"
            class="archived-project"
            data-cy="archived-project"
        >
            <div
                class="item-parent-wrap project_parent"
                tabindex="0"
                @click.stop="updateChecked(categoryParent)"
            >
                <div
                    class="project__name-wrapper"
                    data-cy="archived-project-name-wrapper"
                    @click.stop="parentProjectClickHandler(categoryParent)"
                    @mouseover="getTooltipForText(categoryParent.name, $event)"
                >
                    <div>
                        <checkbox
                            v-if="getIsArchivedCategoriesOpen"
                            :checkbox="checkedProject(categoryParent.id)"
                            @checkbox-click="parentProjectClickHandler(categoryParent)"
                        />
                    </div>
                    <a
                        :id="'tree-' + categoryParent.name.replace(/ /g, '-')"
                        :class="itemParentNameClasses(categoryParent)"
                        @click="goToProject(categoryParent.id)"
                    >
                        <span>{{ categoryParent.name }}</span>
                    </a>
                </div>
                <archived-projects-right-block
                    v-if="getIsArchivedCategoriesOpen"
                    :category="categoryParent"
                />
            </div>
            <div v-if="categoryParent.children && categoryParent.children.length !== 0 && openedCategory.includes(categoryParent.id)">
                <div
                    v-for="categoryChild in categoryParent.children"
                    :key="categoryChild.id"
                    class="project_child"
                    tabindex="0"
                    @click="updateChecked(categoryChild)"
                    @keyup.space="goToProject(categoryChild.id)"
                >
                    <div
                        class="project__name-wrapper"
                        @click.stop="childProjectClickHandler(categoryParent, categoryChild)"
                    >
                        <checkbox
                            v-if="getIsArchivedCategoriesOpen"
                            :checkbox="checkedProject(categoryChild.id)"
                            @checkbox-click="childProjectClickHandler(categoryParent, categoryChild)"
                        />
                        <span :class="itemChildNameWrapperClasses(categoryChild.name)">
                            <a
                                :id="'tree-' + categoryParent.name.replace(/ /g, '-') + '-' + categoryChild.name.replace(/ /g, '-')"
                                :class="itemChildNameClasses(categoryChild)"
                                @click.stop="goToProject(categoryChild.id)"
                            >
                                <span>{{ categoryChild.name }}</span>
                            </a>
                        </span>
                    </div>
                    <archived-projects-right-block
                        v-if="getIsArchivedCategoriesOpen"
                        :category="categoryChild"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import ArchivedProjectsRightBlock from 'components/left-sidebar/components/ArchivedProjectsRightBlock';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
    export default {
        name: 'TreeNavigationArchived',
        components: {
            'archived-projects-right-block': ArchivedProjectsRightBlock,
        },
        mixins: [ToolTipRenderMixin],
        props: {
            projects: {
                type: Array,
                required: true,
            }
        },
        data () {
            return {
                openedCategoriesKeys: [],
            }
        },
        computed: {
            ...mapGetters([
                'getCheckedProjects',
                'getIsArchivedCategoriesOpen',
                'getCheckedArchivedCategories',
                'getProjectProperty',
                'getProjectByName',
            ]),
            openedCategory () {
                return this.openedCategoriesKeys;
            },
        },
        beforeMount () {
            if (this.getProjectProperty('parent')) {
                const openedCategory = this.getProjectByName({ name: this.getProjectProperty('parent') });
                this.openedCategoriesKeys = openedCategory ? [openedCategory.id] : [];
            }
        },
        methods: {
            ...mapActions([
                'updateCheckedArchivedProjects',
            ]),
            itemChildNameWrapperClasses (name) {
                return {
                    'project__name-wrapper_active': this.getProjectProperty('name') === name,
                };
            },
            checkedProject (id) {
                return this.getCheckedProjects.includes(id) || this.getCheckedArchivedCategories.includes(id);
            },
            updateChecked (category) {
                if (this.getIsArchivedCategoriesOpen) {
                    this.updateCheckedArchivedProjects(category.id);
                }
            },
            parentProjectClickHandler (parentProject) {
                if (this.getIsArchivedCategoriesOpen) {
                    this.updateChecked(parentProject);
                } else {
                    this.goToProject(parentProject.id);
                }
            },
            childProjectClickHandler (parentProject, childProject) {
                if (this.getIsArchivedCategoriesOpen) {
                    this.updateChecked(childProject);
                }
                else {
                    this.goToProject(childProject.id);
                }
            },
            goToProject (id) {
                if (this.getIsArchivedCategoriesOpen) return;
                this.$router.push({name: 'keywordList', params: {id}});
            },
            itemParentNameClasses () {
                return {
                    'project__name': true,
                    'project__name_parent': true,
                };
            },
            itemChildNameClasses () {
                return {
                    'project__name': true,
                    'project__name_child': true,
                    'project__name_child_item': true,
                };
            },
        },
    };
</script>

<style lang="scss">
  .tree-navigation-archived {
    width: 100%;

    .item-parent-wrap {
      align-items: center;
      display: flex;
      margin: 0;

      &.project_parent {
        padding: 0;
        position: relative;

        .project__name-wrapper {
          display: flex;
          padding-left: 20px;
          padding-right: 38px;
        }

        &:hover {
          background-color: rgba(23, 107, 250, 0.08);

          .project__name {
            color: kw-color(kw-blue, 1);
            font-weight: 500;
            text-decoration: none;
          }
        }

        &:active {
          background-color: rgba(23, 107, 250, 0.08);

          .project__name {
            color: #265596;
            font-weight: 500;
            text-decoration: none;
          }
        }

        &:focus {
          outline: none;
        }
      }
    }

    .project_child {
      overflow: hidden;
      position: relative;

      span {
        display: inline-block;
        vertical-align: bottom;
        width: 100%;
      }

      .project__name-wrapper {
        padding-left: 43px;
        padding-right: 13px;
      }

      &:last-child a:after {
        border: none;
      }

      &:hover {
        background-color: rgba(23, 107, 250, 0.08);

        .project__name {
          color: kw-color(kw-blue, 1);
          font-weight: 500;
          text-decoration: none;
        }
      }

      &:active {
        background-color: rgba(23, 107, 250, 0.08);

        .project__name {
          color: #265596;
          font-weight: 500;
          text-decoration: none;
        }
      }

      &:focus {
        outline: transparent;
      }
    }

    .project__name-wrapper {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 46px;
      justify-content: center;
      outline: transparent !important;

      span {
        outline: transparent !important;
      }
    }

    .project__name {
      color: kw-color(kw-black, 1);
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      margin-left: 7px;
      outline: transparent !important;
      position: relative;
      text-decoration: none;
      vertical-align: top;

      &:hover {
        color: inherit;
      }
    }

    .project__name_parent {
      display: flex;
      align-items: center;
      width: 100%;
      outline: transparent;

      span {
        display: inline-block;
        vertical-align: bottom;
        width: calc(100% - 20px);
        max-width: 149px;
        @import '~sass/cutting-name';
      }
    }

    .project__name_child {
      display: inline-block;
      width: 100%;

      span {
        @import '~sass/cutting-name';
      }

      &:hover {
        background: transparent;
        text-decoration: none;
      }
    }

    .project__name_active {
      color: kw-color(kw-blue, 1);
      font-weight: 500;
    }
  }
</style>
