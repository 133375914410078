<template>
    <form
        v-if="!isViewOnlyUser"
        class="add-project"
        data-cy="add-project-sidebar"
        name="add-project"
    >
        <div class="add-project__header">
            <h2 class="add-project__title">{{ $t('new-project') }}</h2>
        </div>

        <div class="add-project__body" data-cy="add-project-body">
            <div class="add-project__field">
                <kw-input-field
                    id="project-name"
                    :label="$t('project-name')"
                    class="text-input"
                    data-cy="add-project-name"
                    :name="$t('category-name')"
                    :placeholder="$t('name-placeholder')"
                    type="text"
                    :value="addedProject.categoryName"
                    :error-data="projectNameFieldError('categoryName')"
                    @input="handleInputChange"
                />
            </div>

            <div class="add-project__field">
                <label class="text-label" for="project-reporting-currency">
                    {{ $t('project-reporting-currency') }}
                </label>
                <custom-v-select
                    :class="visibilityClass"
                    id="project-reporting-currency"
                    data-cy="project-reporting-currency"
                    :options="getCurrencies"
                    search
                    search-key="label"
                    :value="addedProject.currencyCode"
                    @change="handleCurrencyChange"
                    :placeholder="getCurrency"
                />
            </div>

            <div class="add-project__field">
                <div class="add-project__info">
                    {{ $t('project-reporting-currency-info-1') }}
                </div>
                <div class="add-project__info">
                    {{ $t('project-reporting-currency-info-2') }}
                </div>
            </div>
        </div>

        <div class="add-project__actions">
            <kw-button
                :button-text="$t('cancel')"
                button-type="text"
                class="add-project__cancel"
                data-cy="add-project-cancel"
                @click="toggleAddProjectScreenOpened"
            />
            <kw-button
                :button-text="$t('add-project')"
                button-type="primary"
                class="add-project__add gtm-add-project-btn"
                data-cy="add-project-confirm"
                :loading="getIsProjectAdding"
                type="submit"
                @click.prevent="createCategory"
            />
        </div>
    </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ValidationMixin from '@/mixins/ValidationMixin';
export default {
    name: 'AddProjectScreen',
    mixins: [ValidationMixin],
    data() {
        return {
            addedProject: {
                categoryName: '',
                currencyCode: null,
            },
            addedProjectErr: {
                categoryName: {},
            },
            isDataOverride: false,
            isPasswordRequired: false,
            subcategoryToggler: false,
            visibilityClass: '',
        };
    },
    computed: {
        ...mapGetters([
            'getActiveProjects',
            'getIsProjectAdding',
            'getDisplaySetting',
            'getProjectByName',
            'isViewOnlyUser',
            'getCurrencyByCode',
            'getCurrencies',
        ]),
        isSubcategoryTogglerDisabled() {
            return (
                !this.addedProject.categoryName ||
                !this.getActiveProjects.length
            );
        },
        getCurrency() {
            let code = this.getDisplaySetting('currency_code');

            return this.getCurrencyByCode(code)?.label;
        },
    },
    methods: {
        ...mapActions(['addProject', 'toggleAddProjectScreenOpened']),
        handleInputChange(value) {
            this.addedProject.categoryName = value;
            this.inputHandler('categoryName');
        },
        handleCurrencyChange(value) {
            this.addedProject.currencyCode = value;
            this.visibilityClass = value ? 'selected' : '';
        },
        inputHandler(type) {
            const { categoryName } = this.addedProject;

            switch (type) {
                case 'categoryName':
                    this.addedProjectErr[type] = this.projectNameValidator(
                        categoryName.trim(),
                        '',
                        'new',
                    );
                    break;
                default:
                    return;
            }
        },
        async createCategory() {
            this.addedProject.categoryName = this.addedProject.categoryName.trim();
            Object.keys(this.addedProjectErr).forEach(key =>
                this.inputHandler(key),
            );

            const errors = Object.keys(this.addedProjectErr).filter(
                key =>
                    this.addedProjectErr[key] && this.addedProjectErr[key].text,
            );

            if (errors.length) {
                errors.forEach(el =>
                    this.$toastr.e(this.addedProjectErr[el].text),
                );
                return;
            }
            await this.addProject(this.addedProject);
        },
        projectNameFieldError(type) {
            return {
                error:
                    this.addedProjectErr[type] &&
                    this.addedProjectErr[type].text,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/partials/dropdowns';

.add-project {
    display: flex;
    flex-direction: column;
    width: 100%;
}

// title styles
.add-project__title {
    padding-left: 24px;
    margin: 36px 0;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: kw-color(kw-black, 1);
    font-weight: 500;
    font-size: 28px;
}

// fields styles
.add-project__body {
    flex-grow: 1;
}

.add-project__field {
    padding: 0 24px 16px 24px;

    .add-project__info {
        color: #818f9c;
        font-family: Roboto;
        font-size: 11px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    label {
        width: 280px;
    }

    /deep/ .open .form-control.dropdown-toggle {
        @extend .custom-dropdown_active;
    }

    /deep/ .form-control.dropdown-toggle {
        @extend .custom-dropdown;
        width: 280px;
    }

    .custom-dropdown_filled {
        /deep/ .form-control.dropdown-toggle {
            color: kw-color(kw-black, 1);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    /deep/ .dropdown-menu {
        @extend .custom-dropdown-menu;
        @extend .custom-scroll;
        width: 280px;

        .glyphicon.glyphicon-ok.check-mark:before {
            content: '';
        }
    }

    .selected {
        /deep/ &.btn-group {
            .form-control {
                &.dropdown-toggle {
                    @extend .custom-dropdown;
                    color: kw-color(kw-black, 1);
                }
            }
        }
    }
}

.add-project__field_toggler {
    label {
        position: relative;
        padding-left: 45px;

        /deep/ .toggler {
            position: absolute;
            left: 0;
        }
    }
}

.add-project__line {
    margin-top: 8px;
    margin-bottom: 8px;
    border-color: #e9eaeb;
}

.add-project__subtitle {
    padding-top: 8px;
    margin-bottom: 16px;
    margin-top: 0;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #909399;
    font-size: 12px;
    font-weight: 500;
}

// actions styles
.add-project__actions {
    display: flex;
    padding-left: 24px;
    padding-bottom: 15px;
}

.add-project__cancel {
    margin-right: 20px;
    width: 138px;
}

.add-project__add {
    width: 138px;
}
</style>
