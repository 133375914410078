<template>
    <div class="warning-plan">
        <div class="plan-content-message">
            <div class="message">{{ $t('billing-subscription-ending-soon') }}</div>
        </div>
        <div class="plan-content-date">
            <div class="date">{{ date }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PlanWarning',
        props: {
            date: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>
  .warning-plan {
    background: rgba(kw-color(kw-yellow, 1), .15);
    border: 1px solid kw-color(kw-yellow, 1);
    height: auto;
    padding: 20px 20px;
    width: calc(100% + 2px);
    bottom: -1px;
    left: -1px;
    position: relative;
    border-radius: 0 0 4px 4px;

    .plan-content-message {
      height: auto;
      margin: 0 auto;
      width: 207px;

      .message {
        color: kw-color(kw-yellow, 7);
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        margin-bottom: 5px;
      }
    }

    .plan-content-date {
      height: auto;
      margin: 0 auto;
      width: 167px;

      .date {
        color: #17181B;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 5px;
        text-align: center;
      }
    }

    .plan-action {
      height: auto;
      margin: 0 auto;
      width: 240px;

      .button-component_primary {
        margin-top: 15px;
      }
    }
  }
</style>