import client from '../client';
import interceptors from '../interceptors';
const invoicesInterceptor = interceptors.get('invoices');

client.interceptors.response.use(
    invoicesInterceptor.s,
    invoicesInterceptor.e,
);

const resource = '/user/invoices';

export default {
    fetch: async () => await client.get(resource),
    fetchOptions: async () => await client.get(`${resource}/options`),
    updateOptions: async payload => await client.patch(`${resource}/options`, payload),
};