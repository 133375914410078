const dashboardOverviewMetricsOptions = [
    {value: 'daily', label: '1d'},
    {value: 'weekly', label: '7d'},
    {value: 'monthly', label: '30d'},
    {value: 'life', label: 'Start'},
];

const projectMetricsOptions = [
    { value: 'daily', label: '1 day'},
    { value: 'weekly', label: '7 days' },
    { value: 'monthly', label: '30 days' },
    { value: 'semester', label: '6 months' },
    { value: 'yearly', label: '1 year' },
    { value: 'life', label: 'All time' },
];

const projectMetricsDateDropdownRanges = [
    { value: 1, label: '1 day' },
    { value: 7, label: '7 days' },
    { value: 30, label: '30 days' },
    { value: 180, label: '6 months' },
    { value: 365, label: '1 year' },
    { value: 666666, label: 'All time' }
];

const dashboardOverviewMetricsOptionsValues = {
    daily: 1,
    weekly: 7,
    monthly: 30,
    life: 'start'
}

export { 
    dashboardOverviewMetricsOptions, 
    projectMetricsOptions,
    projectMetricsDateDropdownRanges,
    dashboardOverviewMetricsOptionsValues
};
