var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-info-section"},[(_vm.currentPackage)?[_c('h2',{staticClass:"title"},[_vm._v(_vm._s((_vm.isFreePackage && !_vm.expirePostTrial)
                    ? _vm.$t('packages.free.title')
                    : (_vm.trialExists || _vm.expirePostTrial)
                    ? _vm.$t('signup-form.trial')
                    : _vm.$t('signup-form.title', {
                          type: _vm.$t(
                              ("common." + (_vm.isEnterprise ? 'enterprise' : 'business'))
                          ),
                }))+"\n            "),_c('span',{staticClass:"like-link",on:{"click":_vm.goToPackages}},[_vm._v(_vm._s(_vm.$tc('change', 1)))])]),_vm._v(" "),_c('div',{staticClass:"permissions"},[_c('permissions',{attrs:{"current-package":_vm.currentPackage,"expirePostTrial":_vm.expirePostTrial}})],1),_vm._v(" "),(!_vm.isFreePackage)?_c('div',[_c('pricing-info',{attrs:{"current-package":_vm.currentPackage,"billing-cycle":_vm.billingCycle,"trial-exists":_vm.trialExists,"showAdditionalInfo":true}}),_vm._v(" "),(!_vm.isFreePackage)?_c('div',[_c('div',{staticClass:"toggler-row"},[_c('div',{staticClass:"billing-label billing-monthly",domProps:{"textContent":_vm._s(_vm.$t('signup-form.billing-cycle.monthly'))},on:{"click":function($event){return _vm.changeBillingCycle(false)}}}),_vm._v(" "),_c('label',{staticClass:"billing-cycle-label",attrs:{"data-cy":"billing-cycle-label"}},[_c('toggler',{attrs:{"toggler":_vm.billingCycleToggle,"color":"grey"},on:{"toggler_click":_vm.changeBillingCycle}})],1),_vm._v(" "),_c('div',{staticClass:"billing-label billing-annually",domProps:{"textContent":_vm._s(_vm.$t('signup-form.billing-cycle.annually'))},on:{"click":function($event){return _vm.changeBillingCycle(true)}}})]),_vm._v(" "),_c('p',{staticClass:"hint"},[_c('span',{staticClass:"ast"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('signup-form.billing-cycle.hint')))])]):_vm._e()],1):_vm._e()]:_c('loader',{attrs:{"size":60,"width":4}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }