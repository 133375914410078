import {fastProject} from '@/store/utils';
import {partialKeywordAdd} from '@/store/utils/addKeywords';
import {parseUrl, buildUrl} from '@/helpers/url';
import apiFactory from '@/api';
import i18n from '@/i18n';
import router from '@/routes';
import toastr from '@/helpers/init-toastr';
import { KEYWORD_SOURCES } from "@/constants";
const keywordsApi = apiFactory.get('keywords');


import {
    RESET_KEYWORD_DRAFT,
    RESET_QUANTITY_DRAFT,
    SET_ACTIVE_GROUPS,
    SET_CURRENT_PAGE_FOR_SUGGESTIONS,
    SET_DRAFTS_QUANTITY,
    SET_KEYWORD_ADDING,
    SET_KEYWORD_DRAFT,
    SET_SELECTED_SUGGESTIONS,
    SET_SUGGESTIONS,

} from '@/store/mutations';

export const addKeywordsPersistedKeys = [
    'keywords.draftsQuantity',
    'keywords.keywordDraft',
    'keywords.keywordGoogleLocalMapsDraft',
    'keywords.perPageForSuggestions',
];

export default {
    state: {
        currentPageForSuggestions: 1,
        draftsQuantity: {web: 0, map: 0, yt: 0},
        keywordAdding: false,
        keywordDraft: {web: null, map: null, yt: null},
        keywordGoogleLocalMapsDraft: null,
        perPageForSuggestions: '20',
        selectedSuggestions: [],
        suggestions: [],
    },
    mutations: {
        [RESET_KEYWORD_DRAFT]: (state) => state.keywordDraft = {web: null, map: null, yt: null},
        [RESET_QUANTITY_DRAFT]: (state) => state.draftsQuantity = {web: 0, map: 0, yt: 0},
        [SET_CURRENT_PAGE_FOR_SUGGESTIONS]: (state, payload) => state.currentPageForSuggestions = payload,
        [SET_DRAFTS_QUANTITY]: (state, { type, payload }) => state.draftsQuantity[type] = payload,
        [SET_KEYWORD_ADDING]: (state, payload) => state.keywordAdding = payload,
        [SET_KEYWORD_DRAFT]: (state, { type, payload }) => state.keywordDraft[type] = payload,
        [SET_SELECTED_SUGGESTIONS]: (state, payload) => state.selectedSuggestions = Object.freeze(payload),
        [SET_SUGGESTIONS]: (state, suggestions) => state.suggestions = Object.freeze(suggestions),
    },
    getters: {
        getPerPageForSuggestions: state => state.perPageForSuggestions,
        getCurrentPageForSuggestions: state => state.currentPageForSuggestions,
        getKeywordAdding: state => state.keywordAdding,
        getSuggestions: (state, {getSelectedSuggestions}) => state.suggestions
            .filter(el => !getSelectedSuggestions.includes(el.attributes.suggestion)),
        getSelectedSuggestions: state => state.selectedSuggestions,
        getSuggestionsPaginated: (state, getters) => {
            const {getSuggestions, getPerPageForSuggestions, getCurrentPageForSuggestions} = getters;
            const items = _.cloneDeep(getSuggestions);
            const currentPage = getCurrentPageForSuggestions;
            const perPage = getPerPageForSuggestions;
            const paginatedItems = items.splice((currentPage - 1) * perPage, perPage);
            return paginatedItems;
        },
        getKeywordDraft: state => type => state.keywordDraft[type],
        getDraftsQuantity: state => state.draftsQuantity.web + state.draftsQuantity.map + state.draftsQuantity.yt,
    },
    actions: {
        async addKeyword ({getters, dispatch, commit}, {keyword, type, tagsAsPartOfKeyword, exact, leading_source}) {
            commit(SET_KEYWORD_ADDING, true);

            try {
                const {
                    geolocation,
                    isBulkImport,
                    includedInSov,
                    keywords,
                    language,
                    platform,
                    region,
                    tags,
                    url,
                    source,
                } = keyword;


                exact = typeof exact !== 'undefined' && exact;


                if (!getters.getCurrentProject) {
                    return false;
                }

                const {getCurrentProject} = getters;

                const fields = [];
                // multiple locations
                let tagsForCreate = [];
                if(tags && tags.length) {
                    tagsForCreate = tags.map((item) => item.text);
                }
                const preparedKeywords = geolocation.location.map(l => ({
                    category: getters.getFullProjectName(getCurrentProject),
                    included_in_sov: includedInSov,
                    ignore_local: typeof geolocation.ignoreLocal !== 'undefined' ? geolocation.ignoreLocal : true,
                    near: l,
                    source: source ?? KEYWORD_SOURCES.MANUAL.value,
                    gmb: geolocation.GMB,
                    notes: {
                        manual_gmb: geolocation.isManualGmb
                    },
                    url: typeof url !== 'undefined' && url
                        ? type === 'yt' ? url : buildUrl(parseUrl(url), exact)
                        : '',
                    region: type === 'yt' ? region.value.replace('youtube', 'google') : region.value,
                    language: language ? language.value : 'en',
                    tags: tagsForCreate,
                    type,
                    leading_source,
                }));

                // multiple platform (mobile and desktop) doubles requests
                if (platform) {
                    platform.split('-').forEach(p => {
                        preparedKeywords.forEach(kw => {
                            fields.push({...kw, type: p === 'mobile' ? 'sem' : 'se'});
                        });
                    });
                } else {
                    fields.push(...preparedKeywords);
                }

                const mapKeywords = fields => isBulkImport
                    ? item => {
                        const parts = item.split(':');

                        return {
                            type: 'keyword',
                            attributes: {
                                ...fields,
                                url: buildUrl(parseUrl(parts[0]), exact),
                                kw: !tagsAsPartOfKeyword ? parts[1] : item.slice(item.indexOf(':') + 1),
                                tags: !tagsAsPartOfKeyword && parts[2] ? parts[2].split(',') : fields.tags,
                                leading_source,
                            },
                        };
                    }
                    : el => ({type: 'keyword', attributes: {...fields, kw: el}});

                const preparedKeywordsLength = keywords.length * fields.length;
                let added = 0;
                const updateAdded = v => {
                    added += v;
                    return added;
                };

                const data = fields.reduce((acc, cur) => {
                    keywords
                        .filter(el => !/^\s+$/.test(el) && el.length)
                        .forEach(el => acc.push(mapKeywords(cur)(el)));

                    return acc;
                }, []);

                const res = await partialKeywordAdd(data, getters, dispatch, preparedKeywordsLength, 1, updateAdded);
                const duplicates = preparedKeywordsLength - added;

                if (duplicates) {
                    toastr.w(i18n.t('keyword-were-duplicate', {duplicates, total: preparedKeywordsLength}));
                }

                toastr.s(res.data.meta.title);

                if (getters.getTutorialMode) {
                    dispatch('setTutorialMode');
                }

                dispatch('fetchKeywords', {id: getCurrentProject.id});
                const projects = _.cloneDeep(getters.getOriginalActiveProjects);

                const combinedTags = []
                res.data.data.forEach((item) => {
                    item.attributes.tags.forEach((item1) => {
                        combinedTags.push(item1);
                    });
                });
                const initialMergedData = getCurrentProject.tags.map((item) => {
                    const foundedIndex = combinedTags.findIndex((item1) => item.id === item1.id);
                    if(foundedIndex >= 0) {
                        const splicedItem = combinedTags.splice(foundedIndex, 1);
                        return splicedItem[0];
                    }else {
                        return item;
                    }
                });
                const allTags = [...initialMergedData, ...combinedTags];
                const uniqueTags = _.uniqBy(allTags, 'name');
                projects
                    .find(p => p.id === getCurrentProject.id)
                    .attributes.tags = uniqueTags;

                dispatch('updateCurrentProjectTags', uniqueTags);
                commit(SET_ACTIVE_GROUPS, projects);
               //
            } catch(e) {
                throw e;
            } finally {
                commit(SET_KEYWORD_ADDING, false);
            }
        },
        async fetchSuggestions ({commit}, payload) {
            const keyword = payload.filter(item => /^\s+$/.test(item) || !item.length ? false : true);

            const queryParameters = {
                params: {
                    kw: _.takeRight(keyword, 10)
                }
            };

            if (!keyword.length) {
                return;
            }

            try {
                const res = await keywordsApi.suggestions(queryParameters);
                commit(SET_SUGGESTIONS, res.data.data);
            } catch (e) {
                throw new Error('Suggestions was not loaded');
            }
        },
        resetKeywordsDrafts ({commit}) {
            commit(RESET_KEYWORD_DRAFT);
            commit(RESET_QUANTITY_DRAFT);
        },
        setSelectedSuggestions({commit}, payload) {
            commit(SET_SELECTED_SUGGESTIONS, payload);
        },
        setKeywordDraft({commit}, payload) {
            commit(SET_KEYWORD_DRAFT, payload);
        },
        setDraftsQuantity({commit}, payload) {
            commit(SET_DRAFTS_QUANTITY, payload);
        },
        setCurrentPageForSuggestions({commit}, payload) {
            commit(SET_CURRENT_PAGE_FOR_SUGGESTIONS, payload);
        },
        setSuggestedKeywords({commit}, payload) {
            commit(SET_SUGGESTIONS, payload);
        }
    },
};
