export default {
    'gsc-impressions': 'Impressions',
    'gsc-position': 'Avg. Position',
    'gsc-clicks': 'Clicks',
    'gsc-ctr': 'CTR',
    'average-pos-min': 'Average position minimum',
    'average-pos-max': 'Average position maximum',
    'average-imp-min': 'Average impression minimum',
    'average-imp-max': 'Average impression maximum',
    'average-ctr-min': 'Average CTR minimum',
    'average-ctr-max': 'Average CTR maximum',
    'select-a-country': 'Select a Region',
    'select-a-device': 'Select a Device',
    'device': 'Device',
    'set-rules': 'Set Rules',
    'edit-rules': 'Edit Rules',
    'step-n': 'STEP {n}:',
    'keyword-suggestions': 'Keyword Suggestions {count}',
    'active-google-account': 'Active google {service} account',
    'connection-rules-modal-header': 'Set keyword suggestion rules',
    'active-google-search-console': 'ACTIVE GOOGLE SEARCH CONSOLE PROFILE',
    'set-rules-for-discovering': 'Set rules for discovering new keywords',
    'connect-google-search-console': 'Connect your Google Search Console account',
    'connect-google-search-console-settings': 'Connect your Google Search Console account to see these metrics',
    'i-keyword-suggestions': 'Integrate with Google Search Console to get Keyword suggestions',
    'all-suggested-keywords-information': 'All suggested keywords will be added to the current Project, Group or Tag',
    'customize-rules': 'Customize your rules so that Keyword.com can suggest keywords for this project',
    'connect-google-search-console-and': 'Connect your Google Search Console account and get new keyword suggestions here.',
    'average-ctr-label': 'The CTR of your website is between...',
    'average-impression-label': 'The number of Impressions are between...',
    'average-position-label': 'The average Position of your website is between...',
    'automatically-create-keyword-label': 'Automatically add suggested keywords to current project, group or tag',
    'add-selected-keywords': 'Add selected keyword(s)',
    'remove-selected-keywords': 'Remove selected keyword(s)',
    'keywords-not-selected': 'Select the keywords',
    'view-removed-keyword-suggestions-list': 'View Removed Keyword List',
    'removed-keyword-suggestions': 'Removed keyword suggestions',
    'restore-keyword-suggestions': 'Restore keyword suggestion(s)',
    'add-keyword-or-s': 'Add Keyword(s)',
    'tag-s': 'Tag(s)',
    'to-project': 'To Project: {project}',
    'profile-project-connection': 'Profile-Project Connection',
    'profile-project-connection-s': 'Profile-Project Connection(s)',
    'item-create-success': '{item} successfully created.',
    'item-update-success': '{item} successfully updated.',
    'item-delete-success': '{item} successfully deleted.',
    'keyword-suggestions': 'Keyword Suggestions',
    'keyword-suggestions-deleted': 'Keyword(s) have been removed from list.',
    'keyword-suggestions-restored': 'Keyword(s) have been added back to your suggestion list.',
    'gsc-needed': 'Connect your GSC account to see the data.',
};
