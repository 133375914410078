export const parseToMetricsTableData = (project, fromStart) => {
    const ranges = []

    for(let i in project.current_results_counts){
        let dif = project.current_results_counts[i] - project.previous_results_counts[i];
        let lifeDif = project.current_results_counts[i] - project.start_results_counts[i];
       
        ranges.push({
            daily: {
                count: project.previous_results_counts[i],
                change: dif,
                status: dif > 0 ? "up" : dif < 0 ? "down" : "tie"
            },
            weekly: {
                count: project.previous_results_counts[i],
                change: dif,
                status: dif > 0 ? "up" : dif < 0 ? "down" : "tie"
            },
            monthly: {
                count: project.previous_results_counts[i],
                change: dif,
                status: dif > 0 ? "up" : dif < 0 ? "down" : "tie"
            },
            semester: {
                count: project.previous_results_counts[i],
                change: dif,
                status: dif > 0 ? "up" : dif < 0 ? "down" : "tie"
            },
            yearly: {
                count: project.previous_results_counts[i],
                change: dif,
                status: dif > 0 ? "up" : dif < 0 ? "down" : "tie"
            },
            life: {
                count: project.start_results_counts[i],
                change: lifeDif,
                status: lifeDif > 0 ? "up" : lifeDif < 0 ? "down" : "tie"
            },
            count: project.current_results_counts[i],
            title: i
        })
    }

    return ranges
};

export const parseToDashboardChartData = (data) => {
    return data.map(item => {

        const resData = item.attributes.chart;
        const result = {};

        for(let i in resData){
            let topPosData = resData[i].top_positions;
            if(topPosData){
                for(let j in topPosData){
                    if(result[j]?.length){
                        result[j].push(topPosData[j]);
                    } else {
                        result[j] = [topPosData[j]];
                    }
                }
            }
        }
        const keywordsInTopPositions = Object.entries(result).map(([key, value]) => ([key,value]));

        return ({
            ...item,
            attributes: {
                ...item.attributes,
                chart: keywordsInTopPositions
            }
        })
    });
}
