import { render, staticRenderFns } from "./ShareOfVoiceBlock.vue?vue&type=template&id=175c2f1a&scoped=true&"
import script from "./ShareOfVoiceBlock.vue?vue&type=script&lang=js&"
export * from "./ShareOfVoiceBlock.vue?vue&type=script&lang=js&"
import style0 from "./ShareOfVoiceBlock.vue?vue&type=style&index=0&id=175c2f1a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "175c2f1a",
  null
  
)

export default component.exports