<template>
    <div data-cy="thank-you" class="thank-you info">
        <div class="title">{{ $t('thank-you.title') }}</div>
        <div class="body">
            <div class="info">
                <div class="msg">{{ $t('thank-you.info-msg') }}</div>
                <div class="like-link">{{ email }}</div>
            </div>
            <div class="buttons">
                <kw-button
                    v-if="showLogin"
                    :loading="loading"
                    button-type="action"
                    :button-text="$t('thank-you.open-login-page')"
                    @click="openLoginPage"
                />
                <template v-else>
                    <kw-button
                        :loading="loading"
                        button-type="action"
                        :button-text="$t('thank-you.open-my-inbox')"
                        @click="openMyInbox"
                    />

                    <div class="button open-login-page" @click="openLoginPage">
                        {{ $t('thank-you.open-login-page') }}
                    </div>
                </template>
            </div>
            <div class="resend-row">
                {{ $t('thank-you.do-not-receive-msg') }}
                <span
                    v-if="!resending"
                    class="like-link"
                    @click="resendEmail"
                    >{{ $t('thank-you.resend-btn') }}</span
                >
                <loader v-else :size="14" />
            </div>
        </div>
        <div class="logo"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SMTP_PROVIDERS_FOR_SNIPER_LINK } from '@/constants';
export default {
    name: 'ThankYou',
    data() {
        return {
            resending: false,
            loading: false,
            email: this.$route.query.email,
            smtpProvider: '',
            showLogin: false,
        };
    },
    computed: {
        getWebsiteUrl() {
            return process.env.KEYWORD_WEBSITE_URL;
        },
    },
    async mounted() {
        if (!this.$route.query.email) {
            this.openLoginPage();
        }

        await this.getSMTPProvider(this.email);
        this.showLogin = !Object.keys(SMTP_PROVIDERS_FOR_SNIPER_LINK).includes(
            this.smtpProvider,
        );
    },
    methods: {
        ...mapActions(['resendVerificationEmail', 'validateEmail']),
        openLoginPage() {
            this.$router.push({ name: 'login' });
        },
        openMyInbox() {
            window.open(this.getSniperLink(), '_blank');
        },
        async getSMTPProvider(email) {
            try {
                const res = await this.validateEmail(email);

                if (res.mx_record === 'mail.protonmail.ch') {
                    this.smtpProvider = 'proton';
                } else {
                    this.smtpProvider = res.smtp_provider;
                }
            } catch (error) {
                this.$toastr.e(this.$t('something-went-wrong'));
            }
        },
        getSniperLink() {
            if (this.smtpProvider) {
                return SMTP_PROVIDERS_FOR_SNIPER_LINK[
                    this.smtpProvider
                ].replace('email', encodeURIComponent(this.email));
            }
        },
        async resendEmail() {
            this.resending = true;
            await this.$recaptchaLoaded();
            let recaptcha = await this.$recaptcha('resend_validation_email');

            try {
                let data = {
                    email: this.email,
                    recaptcha: recaptcha,
                };

                const res = await this.resendVerificationEmail(data);

                if (res) {
                    this.$toastr.s(
                        this.$t('thank-you.resent-msg', {
                            email: this.email,
                        }),
                    );
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.resending = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.thank-you {
    .title {
        color: #15394f;
        font-family: Aeonik;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .body {
        .info {
            text-align: center;
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 27px;

            .msg {
                color: var(--black, #15394f);
            }
        }

        /deep/ .kw-button {
            height: 50px;
            width: 500px;
            border-radius: 6px;

            color: #fff;
            text-align: center;
            font-family: Aeonik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 12px;
        }
        .buttons {
            margin-bottom: 16px;

            .button {
                width: 500px;
                height: 50px;
                flex-shrink: 0;
                text-align: center;
                font-family: Aeonik;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 50px;
                cursor: pointer;

                &.open-login-page {
                    fill: #fff;
                    color: var(--black, #15394f);
                }
            }
        }

        .resend-row {
            color: #15394f;
            text-align: center;
            font-family: Aeonik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;

            /deep/ .button-loader {
                display: inline-block;
                margin: 0 41px;
            }
        }
    }

    .logo {
        background-image: url(~img/logo-full.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 153px;
        height: 30px;
    }

    .like-link {
        color: kw-color(kw-blue, 6);
        cursor: pointer;
    }
}
</style>
