<template>
    <div class="overview-actions">
        <div
            class="view-toggler"
            data-cy="overview-toggler"
        >
            <kw-button
                v-for="item in viewList"
                :key="item.value"
                :button-active="item.value === viewValue"
                button-type="secondary"
                :prepend-icon-class="item.icon"
                data-cy="overview-toggler-button"
                @click="setOverviewView(item.value)"
            />
        </div>

        <search-input
            v-if="type === 'table'"
            icon-position="left"
            :search-input-handler="searchProjects"
            :search-input-placeholder="$t('search-projects')"
            :search-input-value="getOverviewSearch"
        />

        <label
            v-if="type === 'table'"
            class="overview-actions__label"
            data-cy="overview-change-sorting"
        >
            <toggler
                :toggler="sortByChanges"
                @toggler_click="toggleSortByChanges"
            />

            <span
                @click="toggleSortByChanges"
                v-text="$t('sort-by-changes')"
            />
        </label>

        <div class="overview-actions__export-wrapper">
            <kw-button
                button-type="secondary"
                prepend-icon-class="kw kw-download"
                class="overview-actions__export-button"
                @click="OpenExportCSVModal"
            />
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import ConfirmExportCSV from 'components/modals/ConfirmExportCSV';

    export default {
        name: 'OverviewActions',
        props: {
            getOverviewSearch: {
                type: String,
                required: true,
            },
            searchProjects: {
                type: Function,
                required: true,
            },
            sortByChanges: {
                type: Boolean,
                required: false,
                default: false,
            },
            toggleSortByChanges: {
                type: Function,
                required: false,
                default: () => {},
            },
            type: {
                type: String,
                required: false,
                default: 'table',
            },
            viewValue: {
                type: String,
                required: true,
            },
            viewList: {
                type: Array,
                required: true,
            },
        },
        methods: {
            ...mapActions([
                'setOverviewView'
            ]),
            OpenExportCSVModal() {
                this.$modal.show(
                    ConfirmExportCSV,
                    {},
                    {
                        resizable: true,
                        adaptive: true,
                        maxWidth: 530,
                        height: 'auto',
                        classes: 'v--modal'
                    },
                );
            }
        },
    }
</script>

<style lang="scss">
  .overview-actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 12px auto;

    .v-context {
      width: 200px;
    }

    .search-input {
      max-width: 200px;
    }

    @media (max-width: 959px) {
      padding: 0 16px;
      margin: 8px auto;
    }
  }

  .overview-actions__export-wrapper {
    position: relative;
      .overview-actions__export-button {
          width: 36px;
          padding: 8px 10px;

          .kw-download {
              font-weight: 700;
              font-size: 18px;
          }
      }
  }

  .overview-actions__label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: normal;
    margin: 0 0 0 auto;
    user-select: none;

    .toggler {
      margin-right: 8px;
      position: relative !important;
    }
  }

  .overview-actions__export-button {
    background-color: kw-color(kw-white, 1) !important;
    margin-left: 10px;
  }

  .view-toggler {
    background-color: kw-color(kw-white, 1);
    border-radius: 4px;
    display: flex;
    margin-right: 8px;
    position: relative;

    button {
      &.kw-button {
        padding: 10px 10px !important;

        .kw {
          color: #828F9C;
          font-weight: 700;
        }

        &.btn-active {
          .kw {
            color: kw-color(kw-blue, 1);
          }
        }

        &:first-child {
          border-bottom-right-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }

        &:last-child {
          border-bottom-left-radius: 0 !important;
          border-top-left-radius: 0 !important;
          margin-left: -1px;
        }

        &:hover {
          border: 1px solid #EDEEF0 !important;

          .kw {
            color: kw-color(kw-blue, 1) !important;
          }
        }

        &:focus {
          border: 1px solid #EDEEF0 !important;
        }
      }
    }
  }
</style>
