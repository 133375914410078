export default (i18n, toastr) => {
    return res => {
        const {config, data} = res;

        if (config.method === 'post' && config.url.includes('action=sendreportnow')) {
            data.response.includes('success')
                ? toastr.s(i18n.t('report-sent-out'))
                : toastr.e(i18n.t('resend-failed'));
        }

        if (config.method === 'get' && config.url.includes('viewkey=resetviewkey')) {
            if (data.RESPONSE == 'SUCCESS') {
                toastr.s(i18n.t('key-reset'))
            } else {
                toastr.e(i18n.t('key-reset-failed'));
            }
        }

        return res;
    };
};
