export default {
    'available-charts': 'Available charts',
    'available-chart-titles': {
        'project_metrics': 'Keyword Position Tracking',
        'keywords_in_top': 'Keywords in Top Positions',
        'sov': 'Share of voice',
        'visibility': 'Visibility',
        'estimated_traffic': 'Estimated Traffic',
        'top_pages': 'Top 10 Pages',
        'organic_traffic_acquisition': 'Organic Traffic Acquisition',
        'organic_traffic_acquisition_full': 'Organic Traffic Acquisition by User Channel (Google Analytics)',
        'serp_feature_performance': 'SERP Feature Performance',
        'keywords_by_intent': 'Keywords by Intent',
        'average_rank': 'Average Rank',
        'distribution': 'Average Rank vs Distribution'
    },
    'suggested': 'Suggested',
    'visibility-tooltip': `<b>Measure your search visibility:</b><br />This chart evaluates your website's visibility in Google's organic search results by analyzing the rank of your keywords, their click-through rates (CTR), and search volumes. Use the date range filters—1 day, 7 days, 30 days, 6 months, 1 year, to all time—to track how your visibility evolves. A higher visibility score indicates a stronger online presence, making your site more likely to be found by potential visitors. Adjust your SEO strategies based on these insights to improve your rankings, enhance your site's visibility, and drive more organic traffic.`,
    'estimated_traffic-tooltip': `<b>Estimate your traffic potential:</b><br />This chart calculates the expected traffic to your website from specific keywords, factoring in your ranking position, the average click-through rate (CTR), and search volume. Adjust the date range filter—1 day, 7 days, 30 days, 6 months, 1 year, to all time—to examine how your traffic potential changes over time. This insight allows you to prioritize SEO efforts on high-performing keywords and optimize for better visibility and engagement. Use this data to forecast traffic trends and align your content strategy with your audience's search behavior for maximum impact.`,
    'sov-chart-tooltip': '<b>Master your market presence:</b><br />This chart and table combo offers a comprehensive view of your Share of Voice (SoV) for selected keywords, benchmarking your visibility against the top 10 competing URLs. The SoV metric reflects the percentage of exposure or influence your pages hold in search results, offering a clear picture of your competitive standing. Use the line chart to track how your SoV evolves over time, with customizable date ranges from 1 day to all time, to identify trends, gauge the effectiveness of your SEO strategies, and pinpoint opportunities for growth. Dive into the data to strategize how to capture more digital real estate and enhance your online authority',
    'distribution-tooltip': 'In this chart you can compare the Average Trends over a specified period of time broken down by ‘Top x’ SERP buckets. <br/><br/> You can compare by the percentage or number of Keywords over time, and see the Daily Average Rank for those Keywords during that time.',
    'project_metrics-tooltip': `<b>Explore your Keyword Movement:(Customizable Ranges)</b><br />This chart visualizes the distribution of your keywords across different search engine ranking positions. You can customize the position ranges to suit your needs by going to Settings > Table Settings > "Metric Ranges". Quickly see how many of your keywords rank within your preferred ranges, helping you identify trends, gauge your SEO success, and strategize for better visibility in search results.`,
    'keywords_by_intent-tooltip': `<b>Understand keyword intent dynamics:</b><br />This chart categorizes your keywords by search intent, showing not only the number of keywords per intent but also their percentage of your project's, group's, or tag's total keyword count, alongside the estimated traffic for each intent. This analysis enables you to align your content strategy more closely with your audience's needs, optimizing for the types of queries most likely to drive traffic to your site. Leverage this insight to tailor your SEO and content efforts for maximum impact.`,
    'organic_traffic_acquisition-tooltip': `<b>Unlock insights into organic discovery with Google Analytics:</b><br />This report, powered by data from Google Analytics, delves into how users find your site through organic search. It provides a comprehensive analysis of sessions categorized by primary organic channels, shedding light on user engagement and acquisition trends. Understand the effectiveness of your SEO strategies and the relevance of your content as you navigate through the organic pathways users take to reach your site. By leveraging Google Analytics data, you can optimize your content, keywords, and SEO tactics to enhance organic visibility and attract more targeted traffic. Utilize this report to refine your approach to organic acquisition and drive sustainable growth.`,
    'serp_feature_performance-tooltip': `<b>Track your SERP Feature success:</b><br />This chart provides insight into the SERP features (like featured snippets, local packs, or image carousels) appearing in organic search results for your keywords and highlights how often your content is captured in these features. Use the date range filters—1 day, 7 days, 30 days, 6 months, 1 year, to all time—to review your performance over time. Understanding your visibility in SERP features can guide you to optimize your content and SEO strategies, enhancing your chances of being featured. This data is crucial for boosting your site's visibility and drawing more traffic by leveraging these high-visibility opportunities.`,
    'top_pages-tooltip': `<b>Unlock insights on top-performing pages:</b><br />This chart spotlights your best-performing pages, showcasing their average rank, rank trend (up or down), visibility score, estimated traffic, and the count of ranking keywords. Utilize date range filters—1 day, 7 days, 30 days, 6 months, 1 year, to all time—to analyze performance over specific periods. Click on any page to delve deeper into the keywords driving its success. This detailed view helps you understand the factors contributing to page performance, enabling strategic decisions to enhance your SEO impact. Leverage this data to optimize your content and improve overall site visibility.`,
    'average_rank-tooltip': `<b>Discover your average rank trends:</b><br />This chart provides a snapshot of your SEO performance by showing the daily average rank and the 30-day average rank of keywords in your project, group, or tag. Filter through date ranges—1 day, 7 days, 30 days, 6 months, 1 year, to all time—to explore how your average rankings have evolved. This historical insight helps you pinpoint trends, assess the effectiveness of your SEO strategies over time, and make data-driven decisions to boost your search engine rankings. Dive into your data to uncover the story of your SEO journey and plan your next steps with confidence.`,
    'keywords_in_top-tooltip': `<b>Track your SEO journey:</b><br />This area chart illustrates the evolution of your keyword rankings over time, segmented by your customized position ranges. Filter by date to view your historical performance and observe how your SEO strategies impact your visibility in search results. Customize your metric ranges in Settings > Table Settings > 'Metric Ranges' to tailor the chart to your specific needs and goals. Use this visual guide to inform your future SEO decisions and optimize your online presence.`,
    '30_day_average': '30-Day Avg. Rank',
    'daily_average': 'Daily Avg. Rank',
};
