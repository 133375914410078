import { mapGetters } from 'vuex';
import { GTM_EVENTS, REPORT_TYPE_KEY } from '@/constants';
import ViewkeyLinkModal from 'components/modals/ViewkeyLinkModal';
import ExportProjectModal from 'components/modals/ExportProjectModal';
import ConfirmManageProjects from 'components/modals/ConfirmManageProjects';
import ExportModal from 'components/modals/ExportModal';
import { exportAsTXT } from '@/helpers/downloads-service';

export default {
    computed: {
        ...mapGetters([
            'getUserData',
            'getProjectById',
            'getViewkeyView',
            'getFetchFilters',
            'getSearchKeyword',
            'getDataForViewkey',
            'getCurrentProject',
            'getOriginalCheckedProjects',
        ]),
    },
    methods: {
        openGetViewkey(projects) {
            this.$modal.show(
                ViewkeyLinkModal,
                { inputProjects: projects },
                {
                    classes: 'v--modal center-modal-popup view-key-modal',
                    clickToClose: window.screen.width > window.MOBILE_WIDTH_RESOLUTION_PHONE,
                    height: 'auto',
                    name: 'GetViewkeyModal',
                    width: window.screen.width > window.MOBILE_WIDTH_RESOLUTION_PHONE ? 540 : '90%',
                }
            );
        },
        manageProjects(type, isSingle, project, fromDashboard, parentWithChildren = false) {
            this.$modal.show(
                ConfirmManageProjects,
                {
                    fromDashboard: typeof fromDashboard === 'undefined' ? false : fromDashboard,
                    parentWithChildren,
                    projectsForManage: isSingle ? [project] : this.getOriginalCheckedProjects,
                    type,
                },
                {
                    width: 370,
                    height: 'auto',
                    classes: 'v--modal',
                    'data-cy': 'test-cy'
                }
            );
        },
        /**
         * @description Trigger Export CSV modal
         * @param {Object} project
         */
        exportProjectAsCSV(project) {
            this.$modal.show(
                ExportProjectModal,
                { project, exportReport: this.exportReport },
                {
                    classes: 'v--modal',
                    draggable: '.custom-modal_draggable',
                    height: 'auto',
                    name: 'ExportProjectModal',
                    width: 370,
                },
            );
        },
        exportProjectAsTXT(...params) {
            this.emitGTMCustomEvent(2);
            exportAsTXT(...params)
        },
        exportReport(project, data) {
            this.emitGTMCustomEvent(data.report_type);
            this.$modal.show(
                ExportModal,
                {
                    project,
                    data,
                },
                {
                    classes: 'v--modal',
                    draggable: '.custom-modal_draggable',
                    height: 'auto',
                    name: 'ExportModal',
                    width: document.body.offsetWidth < 500 ? '90%' : 485,
                },
            );
        },
        emitGTMCustomEvent(type) {
            /**
             * GTM custom event d_export_project_data
             */
             window.dataLayer = window.dataLayer || [];
             window.dataLayer.push({
                 event: GTM_EVENTS.CLICK_EXPORT,
                 'export-type': REPORT_TYPE_KEY[type],
                 uid: this.getUserData.userId,
             });
        },
    },
};
