<template>
    <div class="secondary-header">
        <a
            v-if="getTutorialMode===false && !noLink"
            class="secondary-header__link"
            :data-cy="dataCy"
            nohref
            @click="closer"
        >
            <span class="kw kw-chevron-left" />
            <template v-if="getCurrentProject">
                {{ projectName }} <span v-if="count">{{ count }}</span>
            </template>
            <template v-else>
                {{ $t('project') }}
            </template>
        </a>
        <h2
            class="secondary-header__title"
            v-text="title"
        />
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        name: 'SecondaryHeader',
        props: {
            closer: {
                type: Function,
                required: true,
            },
            dataCy: {
                type: String,
                required: true,
            },
            noLink: {
                type: Boolean,
                required: false,
            },
            title: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapGetters([
                'getCurrentProject',
                'getTutorialMode',
            ]),
            count () {
                return this.getCurrentProject
                    ? `(${this.getCurrentProject.keywords_count.ACTIVE || 0} ${this.$t('keywords')})`
                    : '';
            },
            projectName () {
                return this.getCurrentProject
                    ? `${this.getCurrentProject.name}`
                    : decodeURIComponent(this.$route.params.id);
            },
        },
    }
</script>

<style lang="scss" scoped>
  .secondary-header {
    background-color: #fcfcfc;
    box-shadow: 0px 2px 4px rgba(42, 70, 116, 0.05);
    padding: 18px 20px 36px;
    width: 100%;
    z-index: 1;
  }

  .secondary-header__link {
    color: #909399;
    cursor: pointer;
    text-decoration: none;

    span.kw-chevron-left {
      font-size: 9px;
      margin-right: 8px;
    }
  }

  .secondary-header__title {
    color: kw-color(kw-black, 1);
    font-size: 21px;
    line-height: 36px;
    letter-spacing: .01em;
    margin: 0;
  }

  @media screen and (max-width: 959px) {
    .secondary-header {
      padding: 18px 10px 36px;
    }
  }
</style>
