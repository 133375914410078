import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters(['getActiveTag', 'getShowAllTags']),
    },
    methods: {
        ...mapActions(['addActiveTag', 'setShowAllTags']),
        setActiveTagsWithUpdate(tag) {
            if (
                this.$route.name === 'shareViewkeyWithHash' ||
                this.$route.name === 'shareKeywordsTableWithHash'
            ) {
                return;
            }

            this.addActiveTag(tag);
        },
        tagsClasses(tag, count = 0) {
            return {
                'tag-name': true,
                'alone': count == 1,
                'tag-field-disabled':
                    this.$route.name === 'shareViewkeyWithHash' ||
                    this.$route.name === 'shareKeywordsTableWithHash',
                'active-tag':
                    this.getActiveTag.id === tag.id,
            };
        },
    },
}
