const defaultConfig = {
    expires: '1d',
    path: '; path=/',
    domain: '',
    secure: '',
    sameSite: '; SameSite=Lax'
  };
const cookieManager = {
    get: key => {
      let value = decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;

      if (value && value.substring(0, 1) === '{' && value.substring(value.length - 1, value.length) === '}') {
        try {
          value = JSON.parse(value);
        } catch (e) {
          return value;
        }
      }
      return value;
    },
    remove:function (key, path, domain) {
        if (!key || !this.isKey(key)) {
          return false;
        }
        document.cookie = encodeURIComponent(key) +
            '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
            (domain ? '; domain=' + domain : '') +
            (path ? '; path=' + path :  '; path=/') +
            '; SameSite=Lax';

        return this;
    },
    isKey: (key) => {
        return (new RegExp('(?:^|;\\s*)' + encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=')).test(document.cookie);
    },
    set: (key, value, expireTimes, path, domain, secure, sameSite) => {
        if (!key) {
          throw new Error('Cookie name is not found in the first argument.');
        } else if (/^(?:expires|max\-age|path|domain|secure|SameSite)$/i.test(key)) {
          throw new Error('Cookie name illegality. Cannot be set to ["expires","max-age","path","domain","secure","SameSite"]\t current key name: ' + key);
        }
        // support json object
        if (value && value.constructor === Object) {
          value = JSON.stringify(value);
        }
        let _expires = '';
        expireTimes = expireTimes == undefined ? defaultConfig.expires : expireTimes;
        if (expireTimes && expireTimes != 0) {
          switch (expireTimes.constructor) {
            case Number:
              if (expireTimes === Infinity || expireTimes === -1) _expires = '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
              else _expires = '; max-age=' + expireTimes;
              break;
            case String:
              if (/^(?:\d+(y|m|d|h|min|s))$/i.test(expireTimes)) {
                // get capture number group
                const _expireTime = expireTimes.replace(/^(\d+)(?:y|m|d|h|min|s)$/i, '$1');
                // get capture type group , to lower case
                switch (expireTimes.replace(/^(?:\d+)(y|m|d|h|min|s)$/i, '$1').toLowerCase()) {
                    // Frequency sorting
                  case 'm':
                    _expires = '; max-age=' + +_expireTime * 2592000;
                    break; // 60 * 60 * 24 * 30
                  case 'd':
                    _expires = '; max-age=' + +_expireTime * 86400;
                    break; // 60 * 60 * 24
                  case 'h':
                    _expires = '; max-age=' + +_expireTime * 3600;
                    break; // 60 * 60
                  case 'min':
                    _expires = '; max-age=' + +_expireTime * 60;
                    break; // 60
                  case 's':
                    _expires = '; max-age=' + _expireTime;
                    break;
                  case 'y':
                    _expires = '; max-age=' + +_expireTime * 31104000;
                    break; // 60 * 60 * 24 * 30 * 12
                  default:
                    new Error('unknown exception of "set operation"');
                }
              } else {
                _expires = '; expires=' + expireTimes;
              }
              break;
            case Date:
              _expires = '; expires=' + expireTimes.toUTCString();
              break;
          }
        }
        document.cookie =
            encodeURIComponent(key) + '=' + encodeURIComponent(value) +
            _expires +
            (domain ? '; domain=' + domain : defaultConfig.domain) +
            (path ? '; path=' + path : defaultConfig.path) +
            (secure == undefined ? defaultConfig.secure : secure ? '; Secure' : '') +
            (sameSite == undefined ? defaultConfig.sameSite : (sameSite ? '; SameSite=' + sameSite : ''));
        return this;
    },
}
const cookieSetters = {
    setApiKey: key => cookieManager.set('api_key', key, '30d'),
    setExpireAt: key => cookieManager.set('expire_at', key, '30d'),
    setOnboarding: key => cookieManager.set('onboarding', key, '30d'),
    setExpireModalShown: data => cookieManager.set('expire_modal_shown_today', data, '1d'),
    setKeywordExceededModalShown: data => cookieManager.set('keyword_exceeded_modal_shown_today', data, '1d'),
    setShowAll: data => cookieManager.set('showall', data, '30d'),
    setUserProfile: profile => cookieManager.set('userProfile', profile, '30d'),
    setReportActionLogs: actionLogs => {
      if (actionLogs && actionLogs.length) {
        cookieManager.set('reportActionLogs', actionLogs)
      } else {
        cookieManager.remove('reportActionLogs')
      }
    },
    setImportActivityLogId: activityLogID => {
      if (activityLogID) {
        cookieManager.set('importActivityLogId', activityLogID);
      } else {
        cookieManager.remove('importActivityLogId');
      }
    },
    setIsRestricted: key => cookieManager.set('is_restricted_access', key, '30d'),
};

const cookieGetters = {
    getMixpanelId: () => {
        if (!cookieManager.isKey('mixpanel_id')) {
            cookieManager.set(
              'mixpanel_id',
              'mixpanel_kw_' + Math.random().toString(36).substr(2, 9),
              '1h'
            );
        }

        return cookieManager.get('mixpanel_id');
    },
    getApiKey: () => cookieManager.get('api_key'),
    getExpireAt: () => cookieManager.get('expire_at'),
    getOnboarding: () => cookieManager.get('onboarding'),
    getGaClientId: () => cookieManager.get('_ga'),
    getFirstVisitDate: () => cookieManager.get('first_visit_date'),
    getExpireModalShown: () => cookieManager.get('expire_modal_shown_today'),
    getKeywordExceededModalShown: () => cookieManager.get('keyword_exceeded_modal_shown_today'),
    getShowAll: () => cookieManager.get('showall'),
    getUserProfile: () => cookieManager.get('userProfile'),
    getReportActionLogs: () => cookieManager.get('reportActionLogs')?.split(','),
    getImportActivityLogId: () => cookieManager.get('importActivityLogId'),
    getUtmAndMarketingParameters: () => {
        let utmParams = {};
        const params = {
            'initial_traffic_source': 'initialTrafficSource',
            'initial_landing_page': '_uc_initial_landing_page',
            'utm_source': '_uc_utm_source',
            'utm_medium': '_uc_utm_medium',
            'utm_id': '_uc_utm_id',
            'utm_term': '_uc_utm_term',
            'utm_mt': '_uc_utm_mt',
            'utm_device': '_uc_utm_device',
            'utm_campaign': '_uc_utm_campaign',
            'gclid': '_uc_gclid',
            'fbclid': '_uc_fbclid',
            'msclkid': '_uc_msclkid',
            'twclid': '_uc_twclid',
            'rdtclid': '_uc_rdtclid',
        };

        Object.keys(params).forEach(function (i) {
            if (cookieManager.isKey(params[i])) {
                let val = cookieManager.get(params[i]);

                if (val !== '') {
                    utmParams[i] = val;
                }
            }
        });

        return utmParams;
    },
    getRewardfulReferral: () => cookieManager.get('rewardful.referral'),
    getIsRestricted: () => cookieManager.get('is_restricted_access'),
}

export {
    cookieSetters,
    cookieGetters,
    cookieManager,
}