const PageNotFound = () => import('pages/errors/PageNotFound');
const ServerError = () => import('pages/errors/ServerError');

export default [
    {
        components: {PageNotFound},
        name: 'PageNotFound',
        path: '*',
    },
    {
        components: {ServerError},
        name: 'ServerError',
        path: '/server-error-page',
    },
];