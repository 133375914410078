var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('div',{staticClass:"viewkey-item",attrs:{"data-cy":"viewkey-item-link"}},[_c('div',{staticClass:"viewkey-item__input-label"},[_c('span',{staticClass:"kw kw-link"}),_vm._v(" "),_c('label',{staticClass:"text-label",attrs:{"for":("viewkey-" + (_vm.project.id))}},[_vm._v("\n                "+_vm._s(_vm.$t('viewkey-link'))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"viewkey-item__input"},[_c('input',{ref:"viewkeyLink",staticClass:"text-input",attrs:{"id":("viewkey-" + (_vm.project.id)),"placeholder":_vm.$t('viewkey'),"readonly":"","type":"text","data-cy":"view-key-input"},domProps:{"value":_vm.viewkeyLink(_vm.project)},on:{"click":function($event){return $event.target.select()}}}),_vm._v(" "),_c('kw-button',{attrs:{"button-text":_vm.$t('copy'),"button-type":"primary","data-cy":"viewkey-copy-button"},on:{"click":function($event){_vm.copyViewkey(
                        ("viewkey-" + (_vm.project.id)),
                        _vm.viewkeyLink(_vm.project),
                        _vm.project
                    )}}})],1)]),_vm._v(" "),(!_vm.hideEmbedded)?_c('div',{staticClass:"viewkey-item viewkey-item_embedded-code",attrs:{"data-cy":"viewkey-item-embed"}},[_c('div',{staticClass:"viewkey-item__input-label"},[_c('span',{staticClass:"kw kw-table"}),_vm._v(" "),_c('label',{staticClass:"text-label",attrs:{"for":("embedded-code-" + (_vm.project.id))}},[_vm._v("\n                "+_vm._s(_vm.$t('embedded-code'))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"viewkey-item__input"},[_c('input',{staticClass:"text-input",attrs:{"id":("embedded-code-" + (_vm.project.id)),"placeholder":_vm.$t('viewkey'),"type":"text","readonly":""},domProps:{"value":_vm.embeddedCode(_vm.project)},on:{"click":function($event){return $event.target.select()}}}),_vm._v(" "),_c('kw-button',{attrs:{"button-text":_vm.$t('copy'),"button-type":"primary","data-cy":"embedded-copy-button"},on:{"click":function($event){_vm.copyViewkey(
                        ("embedded-code-" + (_vm.project.id)),
                        _vm.embeddedCode(_vm.project),
                        _vm.project,
                        true
                    )}}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }