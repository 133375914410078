export default {
    'broad-domain': 'Broad Domain',
    'exact-domain': 'Exact Url',
    'keywords-translations': {
        'create-edit-modal': {
            'example': 'example.com',
            'title': {
                'first-create': 'Let’s add your first keywords',
            },
            'description': {
                'first-create': 'Experience the power behind keyword.com by adding your first keywords below. 🧑‍💻',
            },
            'form': {
                'keywords': 'Keywords',
                'keywords-placeholder': 'Input one keyword per line',
            },
            'buttons': {
                'save': 'Save Keywords',
                'go-back': 'Go Back',
            },
            'keywords-textarea-placeholder': 'Input one keyword per line',
        },
    },
};
