import client from '../client';
import interceptors from '../interceptors';
const tagsInterceptor = interceptors.get('tags');

client.interceptors.response.use(
    tagsInterceptor.s,
    tagsInterceptor.e,
);

const resource = '/projects';

const encodedId = id => encodeURIComponent(id);

export default {
    createTags: async (payload, projectId) => await client.post(`${resource}/${encodedId(projectId)}/tags`, payload),
    deleteProjectTag: async (projectId, tagId) => await client.delete(`${resource}/${encodedId(projectId)}/tags/${encodedId(tagId)}`),
    editTag: async (payload, projectId, tagId) => await client.patch(`${resource}/${encodedId(projectId)}/tags/${encodedId(tagId)}`, payload),
    fetchManageTagsData: async (payload, projectId) => await client.get(`${resource}/${encodedId(projectId)}/tags/keywords-count-mapping`, payload),
};
