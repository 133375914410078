<template>
    <div class="manage_tags_container" data-cy="manage-tags-container">
        <div class="manage_tags_header">
            <div class="manage_tags_input_wrap" data-cy="tags-typing-field">
                <input
                    v-model="searchTagValue"
                    :disabled="fetchLoading"
                    :placeholder="$t('start-typing')"
                    type="text"
                />
                <span class="kw kw-search" />
            </div>
        </div>

        <div class="manage_tags_body">
            <template v-if="fetchLoading">
                <loader />
            </template>
            <ul
                v-if="getIsDataListShown && !fetchLoading"
                class="manage_tags_body_inner"
            >
                <template v-for="item in allList">
                    <li
                        v-if="
                            item.name
                                .toLowerCase()
                                .match(searchTagValue.toLowerCase())
                        "
                        :key="item.id"
                    >
                        <label
                            :class="getCheckboxClasses(item)"
                            @click="handleCheckboxClick(item)"
                        >
                            <checkbox
                                :id="`${item.id}-checkbox`"
                                :checkbox="checkboxCondition(item)"
                                :icon-class="getCheckBoxIcon(item)"
                                @checkbox-click="handleCheckboxClick(item)"
                            />
                            <div class="list_item_text">
                                {{ item.name }}
                            </div>
                        </label>
                    </li>
                </template>
            </ul>
            <div v-else-if="!fetchLoading" class="no_data_block">
                {{ $t("no-tags") }}
            </div>
        </div>
        <div class="manage_tags_footer">
            <div
                v-if="getShowCreateTag"
                class="dropdown_create_tag"
                @click="handleCreateTag"
            >
                <span class="create_icon" data-cy="create-tag-icon" />
                <div class="dropdown_create_tag_text">
                    {{ $t("create-new-tag") }} "{{ searchTagValue }}"
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import RefreshMixin from "@/mixins/RefreshMixin";
import DashboardMixin from "@/mixins/DashboardMixin";
import ToolTipRenderMixin from "@/mixins/ToolTipRenderMixin";

export default {
    name: "ManageTagsForGSCModalContainer",
    mixins: [DashboardMixin, RefreshMixin, ToolTipRenderMixin],
    props: {
        activeTag: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            allList: [],
            tagList: [],
            searchTagValue: "",
            createTagLoading: false,
            applyLodaing: false,
            fetchLoading: false,
            buttonKey: 1,
        };
    },
    computed: {
        ...mapGetters([
            "getSelectedAuth",
            "getCurrentProject",
            "getManageTagsData",
            "getAllWithoutIdsData",
            "getIsAllCheckboxesSelected",
        ]),
        checkboxCondition() {
            return (item) => item.checkedStatus !== "unChecked";
        },
        getCheckboxClasses() {
            return ({ checkedStatus }) => ({
                [checkedStatus]: true,
                "manage-tags-checkbox": true,
            });
        },
        getCheckBoxIcon() {
            return ({ checkedStatus }) =>
                checkedStatus === "partialChecked"
                    ? "kw kw-minus"
                    : "kw kw-check";
        },
        getIsButtonDisabled() {
            const dif = _.differenceWith(
                this.allList,
                this.getManageTagsData,
                _.isEqual
            );
            const disabled =
                _.isEmpty(dif) || this.applyLodaing || this.fetchLoading;
            return disabled;
        },
        getShowCreateTag() {
            return (
                (this.allList.length &&
                    !this.allList.find((item) =>
                        item.name
                            .toLowerCase()
                            .match(this.searchTagValue.toLowerCase())
                    )) ||
                (!this.getManageTagsData.length && this.searchTagValue)
            );
        },
        getIsDataListShown() {
            if (this.allList.length && !this.searchTagValue) return true;
            return (
                this.allList.length &&
                this.allList.find((item) =>
                    item.name
                        .toLowerCase()
                        .match(this.searchTagValue.toLowerCase())
                )
            );
        },
    },
    watch: {
        getManageTagsData: {
            handler() {
                this.createTagList();
            },
            deep: true,
        },
    },
    async created() {
        this.fetchLoading = true;
        const withoutIds = this.getAllWithoutIdsData;
        const params = {};
        if (!this.getIsAllCheckboxesSelected) {
            params.keywordIds = this.getSelectedAuth;
        }
        if (withoutIds.length) {
            params.withoutIds = withoutIds;
        }

        await this.fetchManageTagsData(params);

        this.fetchLoading = false;
    },
    methods: {
        ...mapActions(["createTags", "manageTags", "fetchManageTagsData"]),
        createTagList() {
            const sortedTagList = _.orderBy(
                this.getManageTagsData,
                ["statusCode", "id"],
                ["asc", "desc"]
            );
            this.allList = sortedTagList.map((el) => {
                el.checkedStatus =
                    this.activeTag.id === el.id ? "checked" : "unChecked";
                return el;
            });
            this.$emit(
                "change",
                this.allList.filter((el) => el.checkedStatus === "checked")
            );
        },

        handleCheckboxClick({ id }) {
            const allList = this.allList;
            const newList = allList.map((item) => {
                if (item.id === id) {
                    if (item.checkedStatus === "checked") {
                        return {
                            ...item,
                            checkedStatus: "unChecked",
                        };
                    } else {
                        return {
                            ...item,
                            checkedStatus: "checked",
                        };
                    }
                }
                return item;
            });
            this.allList = newList;

            this.$emit(
                "change",
                this.allList.filter((el) => el.checkedStatus === "checked")
            );
        },

        handleCreateTag() {
            this.createTagLoading = true;
            try {
                this.createTags({
                    tags: [this.searchTagValue],
                    projectId: this.getCurrentProject.project_id,
                    fromManageTags: true,
                });
            } catch (e) {
                console.log(e, "e");
            }
            this.createTagLoading = false;
        },
        async handleApplyClick() {
            this.applyLodaing = true;
            const dataForSend = {
                attachedTagIds: [],
                detachedTagIds: [],
            };
            const dif = _.differenceWith(
                this.allList,
                this.getManageTagsData,
                _.isEqual
            );
            const withoutIds = this.getAllWithoutIdsData;
            dif.forEach((item) => {
                if (item.checkedStatus === "checked") {
                    dataForSend.attachedTagIds.push(item.id);
                } else {
                    dataForSend.detachedTagIds.push(item.id);
                }
            });
            if (!dataForSend.attachedTagIds.length) {
                delete dataForSend.attachedTagIds;
            }
            if (!dataForSend.detachedTagIds.length) {
                delete dataForSend.detachedTagIds;
            }
            if (!this.getIsAllCheckboxesSelected) {
                const selectedKeywords = this.getSelectedAuth;
                dataForSend.keywordIds = selectedKeywords;
            }
            const projectId = this.getCurrentProject.project_id;
            if (withoutIds.length) {
                dataForSend.withoutIds = withoutIds;
            }
            try {
                await this.manageTags({ data: dataForSend, projectId });
                this.applyCb();
            } catch (e) {
                console.log(e, "e");
            }
            this.buttonKey = this.buttonKey + 1;
            this.applyLodaing = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.manage_tags_container {
    width: 100%;
}

.manage_tags_header {
    padding: 13px 0 5px;
}

.manage_tags_body {
    padding: 13px 0 5px 0;
    color: #333333;
    border-bottom: 1px solid #e9eaeb;
}

.manage_tags_footer {
    padding: 20px;
}

.manage_tags_body_inner {
    max-height: 200px;
    @extend .custom-scroll;
    overflow-y: auto;
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;

        label {
            cursor: pointer;
            position: relative;
            padding-left: 25px;
            display: block;
            transition: opacity 0.5s;

            &:hover {
                opacity: 0.7;
            }
        }

        .list_item_text {
            font-weight: 400;
            font-size: 14px;
            color: #333333;
        }
    }
}

.manage_tags_title {
    color: #333333;
    font-size: 14px;
    font-weight: 500;
}

.manage_tags_button {
    width: 100%;
}

.manage_tags_input_wrap {
    position: relative;

    input {
        border: none;
        border-bottom: 1px solid kw-color(kw-light-grey, 1);
        padding: 5px 25px 5px 0;
        width: 100%;
        color: #333333;
        font-size: 14px;
        font-weight: 400;
    }

    .kw-search {
        color: #828f9c;
        font-size: 13px;
        font-weight: 700;
        position: absolute;
        right: 0;
        top: 8px;
        z-index: 1;
    }
}

.no_data_block {
    font-size: 15px;
    text-align: center;
    padding: 10px 15px 15px;
    color: #333333;
}

.dropdown_create_tag {
    color: kw-color(kw-blue, 1);
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: opacity 0.5;
    align-items: center;
    opacity: 1;

    &:hover {
        opacity: 0.7;
    }

    .dropdown_create_tag_text {
        font-size: 12px;
        font-weight: 500;
        padding-left: 10px;
    }

    span {
        display: block;
        width: 14px;
        min-width: 14px;
        height: 14px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: 13px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("~img/rounded-plus.svg");
    }
}

/deep/ .partialChecked {
    .checkbox {
        background: #828f9c !important;
    }
}

button {
    &.kw-btn-primary {
        /deep/ .button-loader {
            left: -7px !important;
        }
    }
}
</style>
