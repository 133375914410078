<template>
    <div class="dashboard-overview__inner app-table__wrapper">
        <div class="app-container">
            <overview-action-bar
                v-if="
                    getCheckedProjects.length &&
                    !isEditProjectOpen &&
                    $route.name === 'Overview'
                "
                :action-bar-type="$route.name"
                :manage-projects="manageProjects"
                :open-get-viewkey="openGetViewkey"
                :selected="getCheckedProjects"
                :selected-original-projects="getOriginalCheckedProjects"
            />
            <custom-table
                :items="projects"
                :headers="headers"
                :table-name="tableName"
                cy-attribute="overview"
                :field-formatter="fieldFormatter"
                :sorting-options="getOverviewSort"
                :checkbox-options="checkboxOptions"
                :pagination-options="paginationOptions"
                :is-items-fetching="getOverviewDataLoading"
                @page-change="handlePageChange"
                @updated="
                    () => setCheckboxesOnUpdate(tableName, 'getCheckedProjects')
                "
                @dropdown-item-before-open="
                    (project, resolve) => showActions(project, resolve)
                "
                @handle-item-click="args => dropdownItemClick(args)"
                @header-checkbox-click="toggleAllDashboardCheckboxes(tableName)"
                @header-column-click="changeSorter"
                @body-column-click-shift="
                    ([e, project, index, header]) =>
                        handleColumnClick(header, project, index, e)
                "
                @body-column-click-ctrl="
                    ([e, project, index]) =>
                        handleDashboardCheckboxClick(
                            project.id,
                            tableName,
                            index,
                            e,
                            projects,
                        )
                "
                @body-column-click-meta="
                    ([e, project, index]) =>
                        handleDashboardCheckboxClick(
                            project.id,
                            tableName,
                            index,
                            e,
                            projects,
                        )
                "
                @body-checkbox-click="
                    ([e, project, index]) =>
                        handleDashboardCheckboxClick(
                            project.id,
                            tableName,
                            index,
                            e,
                            projects,
                        )
                "
                @body-checkbox-click-stop="
                    ([e, project, index]) =>
                        handleDashboardCheckboxClick(
                            project.id,
                            tableName,
                            index,
                            e,
                            projects,
                        )
                "
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DEFAULTS_METRICS, pdfExportParams, REPORT_TYPE } from '@/constants';
import getItems from '../configs/fields_v2';

import DashboardMixin from '@/mixins/DashboardMixin';
import ProjectsActionsMixin from '@/mixins/ProjectsActionsMixin';
import Table from '@/components/main-table/custom-table/Table';
import OverviewActionBar from '@/components/action-bar/OverviewActionBar';

export default {
    name: 'OverviewTableView',

    components: {
        'custom-table': Table,
        'overview-action-bar': OverviewActionBar,
    },

    mixins: [DashboardMixin, ProjectsActionsMixin],

    data() {
        return {
            tableName: 'ProjectsOverview_v2',
            dataForDropdown: null,
        };
    },

    computed: {
        ...mapGetters([
            'getOverviewSort',
            'isEditProjectOpen',
            'getDisplaySetting',
            'getCheckedProjects',
            'getCurrentOverviewPage',
            'getOverviewDataLoading',
            'getOverviewItemsPerPage',
            'getSortedAndFilteredProjectsWithMetrics',
            'getPaginatedProjectsWithMetrics',
            'getOverviewDataParams',
            'getOverviewPaginateWithAPI',
            'getIsAuthUser'
        ]),

        headers() {
            return getItems(this.getDisplaySetting);
        },

        checkboxOptions() {
            return {
                showCheckbox: true,
                isHeaderCheckboxActive:
                    this.getCheckedProjects && this.getCheckedProjects.length,
                isAllChecked:
                    this.getCheckedProjects.length ===
                    this.getSortedAndFilteredProjectsWithMetrics.length,
            };
        },

        paginationOptions() {
            if(this.getOverviewPaginateWithAPI){
                return {
                    perPage: this.getOverviewDataParams.per_page,
                    currentPage: this.getOverviewDataParams.current_page,
                    total: this.getOverviewDataParams.total,
                    isActive:
                        this.getOverviewDataParams.total >
                        this.getOverviewDataParams.per_page,
                }
            } else {
                return {
                    perPage: this.getOverviewItemsPerPage,
                    currentPage: this.getCurrentOverviewPage,
                    total: this.getSortedAndFilteredProjectsWithMetrics.length,
                    isActive:
                            this.getSortedAndFilteredProjectsWithMetrics.length >
                            this.getOverviewItemsPerPage,
                };
            }
        },

        projects() {
            const array = [];
            array.length = 14;
            array.fill({});
            return this.getOverviewDataLoading
                ? array
                : this.getOverviewPaginateWithAPI
                ? this.getSortedAndFilteredProjectsWithMetrics 
                : this.getPaginatedProjectsWithMetrics;
        },
    },
    watch: {
        getCheckedProjects(val) {
            if (val && !val.length) {
                const dashboardTableHeader = document.querySelector(
                    '.app-table__header',
                );
                dashboardTableHeader &&
                    dashboardTableHeader.classList.remove('with_action_bar');
            } else if (val && val.length === 1) {
                this.fixTableHeader('app');
            }
        },
    },

    created() {
        this.setOverviewDataParams({
            page: 1,
            per_page: 20
        })
        if(this.getIsAuthUser){
            this.fetchOverviewData();
        }
    },
    beforeDestroy() {
        this.overviewPageChanged({page: 1, withAPI: this.getOverviewPaginateWithAPI});
    },
    destroyed() {
        this.resetCheckedProjects();
    },

    methods: {
        ...mapActions([
            'selectEditProject',
            'fetchOverviewData',
            'overviewPageChanged',
            'resetCheckedProjects',
            'overviewSorterChanged',
            'setOverviewDataParams'
        ]),
        changeSorter([header]) {
            if (header.sorter) {
                this.overviewSorterChanged(header.sorter);
            }
        },

        handleColumnClick({ name }, { id }, index, e) {
            if (name === 'checkbox') {
                this.handleDashboardCheckboxClick(
                    id,
                    this.tableName,
                    index,
                    e,
                    this.projects,
                );
            } else {
                this.handleDashboardRowSelect(
                    id,
                    this.tableName,
                    index,
                    e,
                    this.projects,
                );
            }
        },

        handlePageChange(value) {
            this.overviewPageChanged({page: value, withAPI: this.getOverviewPaginateWithAPI});
            window.scrollTo(0, 0);
        },

        fieldFormatter(project, header) {
            if (header.name === 'id') {
                return project.id.replace(
                    '[sub]',
                    '<span class="kw kw-corner-down-right"></span>',
                );
            }
            if (header.name.includes('ranges')) {
                const item = _.get(project, header.name);
                const metricsPeriod =
                    this.getDisplaySetting('metrics') === 'hide'
                        ? DEFAULTS_METRICS.PROP
                        : this.getDisplaySetting('metrics');
                const { change } = item[metricsPeriod] || null;
                // we need to show current count and movement from necessary metrics period
                return `
                    <div class="text-right overview-table-body__cell">
                        <span class="icon-arrow_4 ${
                            change < 0 && 'icon-rotate-down'
                        }${change ? '' : ' transparent'}"></span>
                        <span class="change${
                            change < 0
                                ? ' negative'
                                : change > 0
                                ? ' positive'
                                : ''
                        }">${change ? Math.abs(change) : ''}</span>
                        <span class="count">${item.count}</span>
                    </div>`;
            }

            const text = _.get(project, header.name);
            return `<span></span><div class="text-right">${text || ''}</div>`;
        },

        showActions(project, resolve) {
            this.dataForDropdown = { project };
            resolve();
        },

        dropdownItemClick(e) {
            const { project } = this.dataForDropdown;
            switch (e) {
                case 'get-viewkey':
                    this.openGetViewkey([this.getProjectById(project.id)]);
                    break;

                case 'archive-project':
                    this.manageProjects(
                        'archive',
                        true,
                        this.getProjectById(project.id),
                    );
                    break;

                case 'edit-project':
                    this.selectEditProject(project.id);
                    break;

                case 'get-csv':
                    this.exportProjectAsCSV(this.getProjectById(project.id));
                    break;

                case 'get-pdf':
                    this.exportReport(this.getProjectById(project.id), {
                        report_type: REPORT_TYPE.PDF,
                        selector: 'pdfWatcher',
                        ...pdfExportParams,
                    });
                    break;
            }
        },
    },
};
</script>

<style lang="scss">
.dashboard-overview__inner {
    margin: 11px auto 0 auto;
}

.app-container {
    .action-bar-container {
        flex: 1;
        align-self: flex-start;
    }
}
.overview-table-body__cell {
    align-items: center;
    border-right: 1px solid #e1e8ef;
    color: #333;
    display: flex;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    min-width: 74px;
    overflow: hidden;
    padding: 0 5px;
    position: relative;

    &.text-right {
        justify-content: flex-end;
    }

    .checkbox {
        position: relative !important;
        top: 4% !important;
    }

    &:last-child {
        border-right: none;
        padding: 0 8px;
    }

    .button-component {
        margin: auto;
    }

    .count,
    .change {
        width: 42px;
    }

    .count {
        font-weight: 500;
    }

    .icon-arrow_4 {
        font-size: 10px;
        margin-right: 5px;

        &.transparent {
            opacity: 0;
        }
    }

    .icon-arrow_4.icon-rotate-down {
        color: kw-color(kw-red, 1);
    }

    .icon-arrow_4:not(.icon-rotate-down) {
        color: kw-color(kw-green, 1);
    }

    .change {
        margin-right: 5px;
        text-align: left;

        &.negative {
            color: kw-color(kw-red, 1);
        }

        &.positive {
            color: kw-color(kw-green, 1);
        }
    }

    span {
        white-space: nowrap;
    }
}
</style>
