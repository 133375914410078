<template functional>
    <div class="share-data">
        <a
            v-if="props.project.company_logo"
            class="share-data__logo-link"
            data-cy="branding-preview-logo_link"
            :href="$options.companyLink(props.project)"
            target="_blank"
        >
            <img
                alt="logo"
                class="share-data__logo-image"
                data-cy="branding-preview-logo"
                :src="props.project.company_logo"
            >
        </a>
        <div class="share-data__info">
            <h4
                class="share-data__company-name"
                data-cy="branding-preview-company_name"
                v-text="$options.companyName(props.project)"
            />
            <div
                class="share-data__description"
                data-cy="branding-preview-company_description"
                v-html="$options.companyDescription(props.project)"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ShareData',
        props: {
            project: {
                type: Object,
                required: false,
                default: () => {},
            },
        },
        companyLink: project => project.company_link || '',
        companyName: project => project.company_name || '',
        companyDescription: project => project.company_desc || '',
    }
</script>

<style lang="scss" scoped>
  .share-data {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: 0 16px;
  }

  .share-data__logo-link {
    display: flex;
    min-height: 80px;
  }
  .share-data__info {
    width: 280px;
  }

  .share-data__logo-image {
    height: auto;
    margin: auto 0;
    width: 250px;
  }

  .share-data__company-name {
    font-size: 16px;
    color: kw-color(kw-black, 1);
  }

  .share-data__description {
    color: #909399;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 10px;
    word-break: break-word;
  }

  @media (min-width: 960px) {
    .share-data {
      flex-flow: row nowrap;
      padding: 0;
    }
    .share-data__logo-link {
      margin-right: 24px;
      min-height: 150px;
    }
  }
</style>