import ShareOfVoice from 'pages/share/ShareOfVoice';
import ShareKeywordsTable from 'pages/share/ShareKeywordsTable';

export default [
    {
        path: '/project-share-of-voice/:viewkey/:auth/:client?',
        name: 'shareOfVoice',
        components: {
            shareOfVoice: ShareOfVoice,
        },
        props: {
            shareOfVoice: route => ({
                auth: route.params.auth,
                client: route.params.client === 'client',
                viewkey: route.params.viewkey,
            })
        }
    },
    {
        path: '/tag-share-of-voice/:viewkey/:hash/:client?',
        name: 'shareOfVoiceWithHash',
        components: {
            shareOfVoice: ShareOfVoice,
        },
        props: {
            shareOfVoice: route => ({
                hash: route.params.hash,
                client: route.params.client === 'client',
                viewkey: route.params.viewkey,
            })
        }
    },
    {
        path: '/project-share-table/:viewkey/:auth/:client?',
        name: 'shareKeywordsTable',
        components: {
            shareKeywordsTable: ShareKeywordsTable,
        },
        props: {
            shareKeywordsTable: route => ({
                auth: route.params.auth,
                client: route.params.client === 'client',
                viewkey: route.params.viewkey,
            })
        }
    },
    {
        path: '/tag-share-table/:viewkey/:hash/:client?',
        name: 'shareKeywordsTableWithHash',
        components: {
            shareKeywordsTable: ShareKeywordsTable,
        },
        props: {
            shareKeywordsTable: route => ({
                hash: route.params.hash,
                client: route.params.client === 'client',
                viewkey: route.params.viewkey,
            })
        }
    },
];
