export default (i18n, toastr) => {
	return res => {
		const {config, data} = res;

		if (config.method !== 'get' && config.url.includes('/analytics/profiles')) {
			toastr.s(data.message);
		}

		return res;
	};
};
