<template>
    <div :class="metricsContainerClasses">
        <div
            class="project-overview__metrics-title"
        >
            <template v-if="!loading">
                <project-link
                    v-if="!loading"
                    :project-raw="getProjectRaw"
                    :new-tab="false"
                />
                <div v-if="project.total" class="project-overview__total">
                    <span v-text="project.total" />
                    {{ $t('total-keywords') }}
                </div>
            </template>
            <template v-else>
                <placeholder-cell :width="200" :height="14" />
                <placeholder-cell :width="200" :height="14" />
            </template>
        </div>
        <metrics
            :data="project"
            :loading="loading"
            @onDateChange="val => $emit('onDateChange', val)"
        />
    </div>
</template>

<script>
import { actions } from '@/configs/overviewConfigs';
import { mapGetters, mapActions } from 'vuex';
import Metrics from 'components/metrics/Metrics';
import ProjectsActionsMixin from '@/mixins/ProjectsActionsMixin';
import {
    MOBILE_WIDTH_RESOLUTION_PHONE,
    pdfExportParams,
    REPORT_TYPE,
} from '@/constants';
import KeywordsInTopChart from './KeywordsInTopChart.vue';
import SharedKeywordsInTopBlock from '../main-block/components/Charts/SharedKeywordsInTopBlock.vue';

export default {
    name: 'SingleProjectMetrics',
    components: {
        metrics: Metrics,
        'keywords-in-top-chart': KeywordsInTopChart,
        'shared-keywords-in-top-block': SharedKeywordsInTopBlock,
    },
    mixins: [ProjectsActionsMixin],
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        project: {
            type: Object,
            required: true,
        },
        fullWidth: {
            type: Boolean,
            default: false, 
        }
    },
    data() {
        return {
            dropdownItems: actions,
        };
    },
    computed: {
        ...mapGetters([
            'getCurrentProject',
            'getKeywordFilters',
            'getViewkeyView',
        ]),
        getProjectRaw() {
            return this.getCurrentProject?.id || this.project?.id || '';
        },
        isMobileView() {
            return MOBILE_WIDTH_RESOLUTION_PHONE < window.screen.width;
        },
        metricsContainerClasses() {
            return {
                'project-overview__metrics-container': !this.fullWidth,
                'project-overview__metrics-container_full': this.fullWidth
            };
        },
    },
    methods: {
        ...mapActions(['selectEditProject']),
        dropdownItemClick(project) {
            return e => {
                switch (e) {
                    case 'get-viewkey':
                        this.openGetViewkey([this.getProjectById(project.id)]);
                        break;

                    case 'archive-project':
                        this.manageProjects(
                            'archive',
                            true,
                            this.getProjectById(project.id),
                        );
                        break;

                    case 'edit-project':
                        this.selectEditProject(project.id);
                        break;

                    case 'get-csv':
                        this.exportProjectAsCSV(
                            this.getProjectById(project.id),
                        );
                        break;

                    case 'get-pdf':
                        this.exportReport(this.getProjectById(project.id), {
                            report_type: REPORT_TYPE.PDF,
                            startDate: this.getKeywordFilters.keywordsDate,
                            ...pdfExportParams,
                        });
                        break;
                }
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.project-overview__metrics-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-right: 5px;

    a {
        margin-right: 10px;
    }
}

.project-overview__total {
    color: kw-color(kw-grey, 1);
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;

    span {
        font-weight: 500;
    }
}

.project-overview__metrics-container {
    width: 502px;
    padding-top: 8px;
    padding-right: 5px;

    &_full {
        width: 100%;
        padding: 8px 5px 8px 8px;
    }

    @media (max-width: 959px) {
        width: 100%;
        margin-bottom: 32px;

        /deep/ .dashboard {
            max-width: 100% !important;
        }
    }

    /deep/ .dashboard {
        display: flex;
        flex-flow: row wrap;
        max-width: 542px;
    }

    /deep/ .dashboard-block {
        flex: 1 1 30%;
        margin: 0 6px;

        &:first-child {
            margin-left: 0;
        }

        &:nth-child(4) {
            margin-left: 0;
            margin-top: 12px;
        }

        &:nth-child(5) {
            margin-top: 12px;
        }

        &:nth-child(6) {
            margin-top: 12px;
        }
    }
}

@media screen and (max-width: 959px) {
    .project-overview__metrics-container {
        padding: 0;

        /deep/ .dashboard-block {
            &:nth-child(3) {
                margin-right: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    .project-overview_viewkey {
        border: 0;
        box-shadow: none;
        padding: 0;
    }
}
</style>
