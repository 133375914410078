<template>
    <div class="reset-on-demand-modal">
        <div class="header">
            <h4 class="title">{{ $t('reset-on-demand-refresh-limit') }}</h4>
            <div class="close-btn" @click="close">
                <span
                    class="kw kw-x"
                />
            </div>
        </div>
        <div class="modal-body">
            <p class="description" v-html="$t('get-count-on-demand-refreshes', { count: this.limit })" />
            <div class="next-refresh-info">
                <span class="kw kw-info" />
                <p class="text">
                    {{ $t('next-automatic-on-demand-refresh') }}
                    <span class="date">{{ date }}</span>
                </p>
            </div>
        </div>
        <div class="modal-footer">
            <kw-button
                :button-text="$t('reset-your-limit-with-price', { price })"
                :loading="loading"
                @click="onSubmit"
            />
            <p class="hint">{{ $t('reset-on-demand-refresh-hint') }}</p>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getNextMonday, getDayFromNow, getHoursLater } from '@/helpers/format-date-service';

export default {
    name: 'ResetOnDemandRefreshLimitModal',
    props: {
        price: {
            type: Number,
            required: true
        },
        cycle: {
            type: String,
            required: true
        },
        limit: {
            type: Number,
            required: true
        }
    },
    data(){
        return {
            loading: false
        }
    },
    methods: {
        ...mapActions([
            'resetOnDemandRefreshLimit'
        ]),
        onSubmit(){
            this.loading = true;
            this.resetOnDemandRefreshLimit().then(()=>{
                this.close();
            }).finally(()=>{
                this.loading = false;
            })
        },
        close() {
            this.$emit('close');
        },
    },
    computed: {
        date(){
            if(this.cycle === 'hourly'){
                return getHoursLater('bi-hourly-timestamp', 2);
            } else if (this.cycle === 'daily'){
                return `${getDayFromNow('F jS', -1)} at 00:00 UTC`;
            }
            return `${getNextMonday('F jS')} at 00:00 UTC`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~sass/modal';
.reset-on-demand-modal {
    width: 100%;
    font-family: Roboto;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 21px 20px 17px;
        border-bottom: 1px solid kw-color(kw-light-grey, 1);

        h4.title {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            margin: 0;
        }

        .close-btn {
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            & > span {
                font-size: 10px;
                font-weight: 900;
            }
        }
    }
    
    .modal-body {
        padding: 20px;

        .description {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 20px;
            b {
                font-weight: 500;
            }
        }

        .next-refresh-info {
            display: flex;
            width: 100%;
            padding: 12px 16px;
            background-color: kw-color(kw-red, 2);
            gap: 12px;

            .kw-info {
                color: kw-color(kw-blue, 1);
                font-size: 24px;
                font-weight: 900;
            }

            .text {
                margin: 0;

                .date {
                    color: kw-color(kw-blue, 1);
                }
            }
        }
    }

    .modal-footer {
        border-color: kw-color(kw-light-grey, 1);
        padding: 20px 24px;
        .kw-button {
            width: 100%;
            height: 40px;
            margin-bottom: 8px;
        }
        .hint {
            max-width: 464px;
            margin: 0 auto;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
        }
    }
}
</style>
