var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pricing-info-wrapper"},[_c('div',{staticClass:"price-row"},[_c('div',{staticClass:"price-row-content light"},[_c('div',{staticClass:"title"},[_vm._v("\n                "+_vm._s(_vm.isFreePackage
                        ? _vm.currentPackage.title
                        : _vm.$t('signup-form.title', {
                              type: _vm.$t(
                                  ("common." + (_vm.isEnterprise
                                          ? 'enterprise'
                                          : 'business'))
                              ),
                          }))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"price"},[_vm._v("\n                $\n                "+_vm._s(_vm.packagePrice ? _vm.packagePrice.toFixed(2) : _vm.packagePrice)+_vm._s(_vm.billingCycleText)+"\n            ")])]),_vm._v(" "),(!_vm.isFreePackage && _vm.getCoupon)?_c('div',{staticClass:"price-row-content light"},[_c('div',{staticClass:"title"},[_vm._v("\n                "+_vm._s(_vm.$t('pricing-info.coupon', { coupon: _vm.showCouponCode ? _vm.getCoupon : '' }))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"price"},[_vm._v("\n                -$ "+_vm._s(_vm.couponPrice ? _vm.couponPrice.toFixed(2) : _vm.couponPrice)+_vm._s(_vm.billingCycleText)+"\n            ")])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"price-row"},[_c('div',{staticClass:"price-row-content"},[_c('div',{staticClass:"title"},[_vm._v("\n                "+_vm._s(_vm.$t('pricing-info.total-now'))+"\n                "),(!_vm.isFreePackage && _vm.showAdditionalInfo)?_c('span',{staticClass:"ast"},[_vm._v("*")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"price"},[_vm._v("\n                $\n                "+_vm._s(_vm.trialExists
                        ? 0
                        : _vm.totalPrice
                        ? _vm.totalPrice.toFixed(2)
                        : _vm.totalPrice)+_vm._s(_vm.billingCycleText)+"\n                \n            ")])])]),_vm._v(" "),(_vm.trialExists)?_c('div',{staticClass:"price-row"},[_c('div',{staticClass:"price-row-content"},[_c('div',{staticClass:"title"},[_vm._v("\n                "+_vm._s(_vm.$t('pricing-info.total-after'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"price"},[_vm._v("\n                $ "+_vm._s(_vm.totalPrice ? _vm.totalPrice.toFixed(2) : _vm.totalPrice)+_vm._s(_vm.billingCycleText)+"\n            ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }