import {MOBILE_WIDTH_RESOLUTION_PHONE, MINIMAL_LEFT_SIDEBAR_WIDTH} from '@/constants';

import {
    SET_CONTENT_LEFT_MARGIN,
    SET_EDITABLE_GROUP,
    SET_IS_SIDEBAR_RESIZING,
    SET_SIDEBAR_WIDTH,
    TOGGLE_EDIT_PROJECT,
    TOGGLE_PROJECT_ADD_TAGS,
    TOGGLE_ADD_SUB_PROJECT,
    TOGGLE_PROJECT_EDIT_TAG,
    SET_SIDEBAR_OPENED,
    SET_ARCHIVED_PROJECTS_OPENED,
    SET_ADD_PROJECT_SCREEN_OPENED,
} from '@/store/mutations';

export const sidebarsPersistedKeys = [
    'sidebars.isLeftSidebarOpen',
    'sidebars.sidebarWidth',
    'sidebars.contentLeftMargin',
];

const sidebars = {
    state: {
        // we need in it because of limitations of the vue-draggable-resizable
        contentLeftMargin: MINIMAL_LEFT_SIDEBAR_WIDTH,
        isAddProjectScreenOpened: false,
        isArchivedProjectsOpen: false,
        isEditProjectOpen: false,
        isLeftSidebarOpen: window.screen.width > MOBILE_WIDTH_RESOLUTION_PHONE,
        isSidebarResizing: false,
        sidebarWidth: MINIMAL_LEFT_SIDEBAR_WIDTH,
        isAddProjectTagsOpen: false,
        isAddSubProjectOpen: false,
        isEditProjectTagOpen: false,
        projectForChildAction: {}
    },
    mutations: {
        [SET_CONTENT_LEFT_MARGIN]: (state, payload) => state.contentLeftMargin = payload,
        [SET_IS_SIDEBAR_RESIZING]: (state, payload) => state.isSidebarResizing = payload,
        [SET_SIDEBAR_WIDTH]: (state, payload) => state.sidebarWidth = payload,
        [SET_ARCHIVED_PROJECTS_OPENED]: (state, payload) => state.isArchivedProjectsOpen = payload,
        [TOGGLE_EDIT_PROJECT]: state => state.isEditProjectOpen = !state.isEditProjectOpen,
        [SET_ADD_PROJECT_SCREEN_OPENED]: (state, payload) => state.isAddProjectScreenOpened = payload,
        [SET_SIDEBAR_OPENED]: (state, payload) => state.isLeftSidebarOpen = payload,
        [TOGGLE_PROJECT_ADD_TAGS]: (state, payload) => {
            state.isAddProjectTagsOpen = !state.isAddProjectTagsOpen;
            state.projectForChildAction = payload;
        },
        [TOGGLE_ADD_SUB_PROJECT]: (state, payload) => {
            state.isAddSubProjectOpen = !state.isAddSubProjectOpen;
            state.projectForChildAction = payload;
        },
        [TOGGLE_PROJECT_EDIT_TAG]: (state) => state.isEditProjectTagOpen = !state.isEditProjectTagOpen,
    },
    getters: {
        getContentLeftMargin: state => state.contentLeftMargin,
        getIsArchivedCategoriesOpen: state => state.isArchivedProjectsOpen,
        getIsSidebarResizing: state => state.isSidebarResizing,
        getSidebarWidth: state => state.sidebarWidth,
        isAddProjectOpen: state => state.isAddProjectScreenOpened,
        isEditProjectOpen: state => state.isEditProjectOpen,
        isLeftSidebarOpen: state => state.isLeftSidebarOpen,
        getIsAddProjectTagsOpen: state => state.isAddProjectTagsOpen,
        getIsAddSubProjectOpen: state => state.isAddSubProjectOpen,
        getIsEditProjectTagOpen: state => state.isEditProjectTagOpen,
        getProjectForChildAction: state => state.projectForChildAction,
    },
    actions: {
        selectEditProject: ({getters, commit}, projectId) => {
            commit(TOGGLE_EDIT_PROJECT);
            commit(
                SET_EDITABLE_GROUP,
                getters.getOriginalActiveProjects.find(project => project.id === projectId),
            );
        },
        closeAllBars: ({getters, commit, dispatch}) => {
            if (!getters.isLeftSidebarOpen) {
                dispatch('toggleSideBar')
            }
                
            if (getters.isAddProjectOpen) {
                dispatch('toggleAddProjectScreenOpened');
            }

            if (getters.isEditProjectOpen)
                commit(TOGGLE_EDIT_PROJECT);

            if (getters.getIsArchivedCategoriesOpen) {
                dispatch('toggleArchivedProjectsOpened');
            }

            if (getters.getIsAddProjectTagsOpen)
                commit(TOGGLE_PROJECT_ADD_TAGS);

            if (getters.getIsAddSubProjectOpen)
                commit(TOGGLE_ADD_SUB_PROJECT);

            if (getters.getIsEditProjectTagOpen)
                commit(TOGGLE_PROJECT_EDIT_TAG);
        },
        updateSidebarWidth: ({commit, dispatch}, width) => {
            commit(SET_IS_SIDEBAR_RESIZING, false);

            if (width < MINIMAL_LEFT_SIDEBAR_WIDTH) {
                dispatch('setSidebarOpened', false);
                setTimeout(() => {
                    commit(SET_SIDEBAR_WIDTH, MINIMAL_LEFT_SIDEBAR_WIDTH);
                    commit(SET_CONTENT_LEFT_MARGIN, MINIMAL_LEFT_SIDEBAR_WIDTH);
                }, 400);
                return;
            }

            commit(SET_SIDEBAR_WIDTH, width);
        },
        sidebarWidthResizing: ({commit}, width) => {
            commit(SET_IS_SIDEBAR_RESIZING, true);
            commit(SET_CONTENT_LEFT_MARGIN, width);
        },
        toggleProjectAddTags: ({commit}, project = {}) => {
            commit(TOGGLE_PROJECT_ADD_TAGS, project);
        },
        toggleAddSubProject: ({commit}, project = {}) => {
            commit(TOGGLE_ADD_SUB_PROJECT, project);
        },
        toggleProjectEditTag: ({commit}) => {
            commit(TOGGLE_PROJECT_EDIT_TAG);
        },
        toggleSideBar({dispatch, getters}) {
            const openedState = getters.isLeftSidebarOpen;
            dispatch('setSideBarOpened', !openedState);
        },
        setSideBarOpened({commit}, payload) {
            commit(SET_SIDEBAR_OPENED, payload);
        },
        toggleArchivedProjectsOpened({dispatch, getters}) {
            const storeData = getters.getIsArchivedCategoriesOpen;
            dispatch('setArchivedProjectsOpened', !storeData);
        },
        setArchivedProjectsOpened({commit}, payload) {
            commit(SET_ARCHIVED_PROJECTS_OPENED, payload);
        },
        setAddProjectScreenOpened({commit}, payload) {
            commit(SET_ADD_PROJECT_SCREEN_OPENED, payload);
        },
        toggleAddProjectScreenOpened({dispatch, getters}) {
            const storeData = getters.isAddProjectOpen;
            dispatch('setAddProjectScreenOpened', !storeData);
        }
    }
};

export default sidebars;
