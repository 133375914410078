<template>
    <div class="display-settings__item">
        <!-- table columns settings -->
        <checkbox
            :id="`${column.apiAlias}-checkbox`"
            :disabled="disabled || (column.hiddenForFree && getIsFreePlan)"
            :checkbox="
                !(disabled || (column.hiddenForFree && getIsFreePlan)) &&
                column.enabled
            "
            :data-cy="`${column.apiAlias}`"
            @checkbox-click="toggleColumn(column.apiAlias)"
        />
        <label
            :for="`${column.apiAlias}-checkbox`"
            :class="{ disabled: disabled }"
            class="settings__label settings__label_columns"
            @click="!disabled && toggleColumn(column.apiAlias)"
        >
            <span v-text="column.optionName" />
        </label>
        <div class="checkboxes">
            <!-- shared columns settings -->
            <checkbox
                :id="`${column.apiAliasReports}-checkbox`"
                :disabled="disabled"
                :checkbox="!disabled && !column.disabledInReports"
                :data-cy="`viewkey-${column.apiAlias}`"
                @checkbox-click="toggleViewkeyColumn(column.apiAliasReports)"
            />
            <!-- pdf columns settings -->
            <checkbox
                :id="`pdf-${column.apiAlias}-checkbox`"
                :disabled="disabled || column.disabledInPDF"
                :data-cy="`pdf-${column.apiAlias}`"
                :checkbox="
                    !(disabled || column.disabledInPDF) &&
                    getPdfShowColumn(column.apiAlias)
                "
                @checkbox-click="togglePdfColumn(column.apiAlias)"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'TableColumnsRow',
    props: {
        column: {
            type: Object,
            required: true,
        },
        toggleColumn: {
            type: Function,
            required: true,
        },
        toggleViewkeyColumn: {
            type: Function,
            required: true,
        },
        togglePdfColumn: {
            type: Function,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['getPdfShowColumn', 'getIsFreePlan']),
        showPdf() {
            return (
                !this.disabled &&
                this.getPdfShowColumn(this.column.apiAlias) &&
                !this.column?.disabledInPDF
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.display-settings__item {
    min-width: max-content;
    border-top: 1px solid #e1e8ef;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 10px 0;
    position: relative;

    /deep/ .checkbox {
        position: relative !important;
        top: unset;
    }

    .settings__label_columns {
        cursor: pointer;
        flex-grow: 1;
        margin-bottom: 0;
        padding-left: 8px;

        &.disabled {
            cursor: default;
        }

        @media screen and (max-width: 480px) {
            max-width: calc(100% - 32px);
        }
    }

    .checkboxes {
        width: 66px;
        display: flex;
        justify-content: space-between;
    }
}
</style>
