<template>
    <div class="col-lg-7 col-sm-7 col-xs-7 archived-categories-right-block">
        <div 
            class="col-lg-3 col-sm-3 col-xs-3 date"
            data-cy="archived-project-date"
        >
            {{ computeDate(category.time) }}
        </div>
        <div class="col-lg-9 col-sm-9 col-xs-9">
            <div class="row">
                <div
                    class="col-lg-8 col-sm-8 col-xs-8 keywords"
                    data-cy="archived-project-keywords"
                >
                    {{ category.count }}
                </div>
                <div
                    class="col-lg-4 col-sm-4 col-xs-4 actions"
                    @click.stop
                >
                    <kw-button
                        :id="'manage-' + category.name.replace(/ /g,'-')"
                        button-type="text"
                        :button-text="$t('restore')"
                        data-cy="project-restore-button"
                        @click="toggleRestore(category, $t('archived-project-restore-msg', { category: category.name}))"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    export default {
        name: 'ArchivedProjectsRightBlock',
        props: {
            category: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapGetters([
                'getCategoriesForUndo',
                'getDeletedCategoriesForUndo',
            ]),
        },
        methods: {
            ...mapActions([
                'restoreProjects',
                'fetchActiveProjects',
                'archiveCategoriesUndo',
                'deleteCategoriesUndo',
            ]),
            /**
             * @param date '2019-01-30 08:19:15'
             * @returns string '2019-01-30'
             */
            computeDate (date) {
                // format('YYYY-MM-DD');
                if (date) return date.slice(0,10);
            },

            async toggleRestore (category, msg) {
                try {
                    await this.restoreProjects([category.id]);
                    this.$toastr.createToastr({
                        msg,
                        type: 'success',
                        undoCallback: this.undoHandler,
                    });
                    await this.fetchActiveProjects(true);
                } catch (error) {
                    console.log(error, 'error')
                }
            },
            async undoHandler () {
                const promises = [];

                if (this.getCategoriesForUndo.length) {
                    promises.push(this.archiveCategoriesUndo(this.getCategoriesForUndo));
                }

                if (this.getDeletedCategoriesForUndo.length) {
                    promises.push(this.deleteCategoriesUndo(this.getDeletedCategoriesForUndo));
                }

                try {
                    await Promise.all(promises);
                    this.$toastr.s(this.$t('restore-ok-msg'));
                } catch (e) {
                    this.$toastr.e(e);
                }
            },
        },
    };
</script>

<style lang="scss">
  .archived-categories-right-block {
    align-items: center;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    border-top: 2px solid transparent;
    display: flex;
    height: 36px;
    padding: 0;

    .date, .keywords {
      line-height: 16px;
      font-size: 11px;
      color: #909399;
      padding: 0;
      text-align: center;
    }

    .keywords {
      width: 135px;
      line-height: 38px;
    }

    .actions {
      position: relative;
      padding: 0;

      button {
        box-shadow: none;

        &.kw-btn-text {
          color: kw-color(kw-blue, 1) !important;
        }
      }
    }
  }
</style>
