<template>
    <div class="circular-progress-bar-wrapper">
        <span class="progress-bar-name" v-if="message" v-text="message" />
        <!-- the starting angle is calculated using a radian: -1.5708 -->
        <div class="circle-wrapper">
            <vue-circle
                :ref="uniqueRef"
                :progress="halfProgress"
                :size="65"
                :reverse="false"
                line-cap="round"
                :fill="progressColor"
                empty-fill="#E4E7EC"
                :animation-start-value="0.0"
                insert-mode="append"
                :thickness="7"
                :show-percent="false"
            >
                <span
                    class="progress-bar-percent"
                    @mouseover="
                        tooltip({
                            text: `${used} / ${limit} (${progressTooltip}%)`,
                            event: $event,
                            disabled: false,
                        })
                    "
                    >{{ progress }}%</span
                >
            </vue-circle>
        </div>
    </div>
</template>

<script>
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';
import VueCircle from 'vue2-circle-progress/src/index.vue';

export default {
    name: 'CircularProgressBar',
    components: {
        VueCircle,
    },
    mixins: [ToolTipRenderMixin],
    props: {
        limit: {
            type: Number,
            required: true,
        },
        used: {
            type: Number,
            required: true,
        },
        color: {
            type: Object,
            required: true,
        },
        message: {
            type: String,
            default: null,
        },
        uniqueRef: {
            type: String,
            default: '',
        },
    },
    computed: {
        progress() {
            const progressPercent = Math.floor((this.used / this.limit) * 100);
            return this.limit == 0
                ? 0
                : progressPercent >= 100
                ? 100
                : progressPercent;
        },
        progressTooltip() {
            return this.limit == 0
                ? 0
                : Number(((this.used / this.limit) * 100).toFixed(1));
        },
        halfProgress() {
            return Math.floor(this.progress / 2);
        },
        progressColor(){
            return this.progress >= 90 ? { color: '#CB1A02' } : this.color;
        }
    },

    watch: {
        used() {
            // updateProgress(value) - It will update component progress value and animate the change. It doesn't redraw the widget.
            this.$refs[this.uniqueRef].updateProgress(this.halfProgress);
        },
        limit() {
            this.$refs[this.uniqueRef].updateProgress(this.halfProgress);
        },
    },
};
</script>

<style lang="scss" scoped>
.circular-progress-bar-wrapper {
    width: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    .circular-progress-bar .percent-text {
        font-weight: 400 !important;
    }

    .progress-bar-percent {
        position: absolute;
        width: 100%;
        height: 18px;
        line-height: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        font-size: 16px;
        top: 52%;
        transform: translateY(-100%);
    }

    .progress-bar-name {
        display: flex;
        padding-top: 4px;
        margin-bottom: 1.5px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 11.3px;
        line-height: 12px;
        text-align: center;
        color: #63697b;
    }

    .circle-wrapper {
        position: relative;
        width: 65px;
        height: calc(72px / 2);
        overflow: hidden;
        margin-bottom: 5px;
    }

    .circle {
        display: flex;
    }
}
</style>
