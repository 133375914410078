<template>
    <vue-scrollbar
        class="scroll-area"
        :settings="settings"
        @ps-scroll-x="dynamicTableScrollHandler"
    >
        <slot />
    </vue-scrollbar>
</template>

<script>
    import DashboardMixin from '@/mixins/DashboardMixin';
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import 'vue-custom-scrollbar/dist/vueScrollbar.css';
    import resize from 'vue-resize-directive';
    import {userAgent} from '@/helpers/device-detection';
    export default {
        name: 'VueCustomScrollbar',
        components: {
            'vue-scrollbar': vueCustomScrollbar,
        },
        directives: {
            resize,
        },
        mixins: [
            DashboardMixin,
        ],
        props: {
            wrapperQuerySelector: {
                type: String,
                required: false,
                default: '.keywords-table_dynamic'
            },

            headerQuerySelector: {
                type: String,
                required: false,
                default: '.keywords-table__header'
            }
        },
        data () {
            return {
                settings: {
                    suppressScrollY: true,
                },
            };
        },
        watch: {
            $route () {
                // if project small and has no keywords we should hide float scroll
                this.toggleFloatScroll();
            },
        },
        mounted () {
            this.$nextTick(() => {
                const el = document.querySelector(`${this.wrapperQuerySelector}`);
                this.calcScrollCoordinated(this.coordinates(el));
                this.toggleFloatScroll();
                window.addEventListener('resize', this.windowResizeHandler);
            });
        },
        beforeDestroy () {
            window.removeEventListener('resize', this.windowResizeHandler);
        },
        methods: {
            windowResizeHandler () {
                const el = document.querySelector(`${this.wrapperQuerySelector}`);
                this.calcScrollCoordinated(this.coordinates(el));
            },
            calcScrollCoordinated ({left, width}) {
                const childEl = document.querySelector(`${this.wrapperQuerySelector} ${this.headerQuerySelector}`)?.children[0];
                if (!childEl) return;
                if (!left || !width || !childEl) {
                    return;
                }

                const sliderWidth = userAgent() === 'safari'
                    ? Math.round(width / childEl.scrollWidth * width)
                    : Math.round(width / childEl.clientWidth * width);

                // compute dynamic styles to overflow inline styles of library
                const styles = `
                    .ps__rail-x {
                        width: ${width - 32}px !important;
                    }
                    .ps__rail-x_fixed {
                        left: ${left}px !important;
                    }
                    .ps__thumb-x {
                        width: ${sliderWidth - 32}px !important;
                    }
                    .ps__rail-x_fixed .ps__thumb-x {
                        width: ${sliderWidth - 40}px !important;
                    }
                `;

                // insert styles to style element because of library updates element style attribute
                const styleEl = document.createElement('style');
                styleEl.id = 'float-scroll-styles';
                styleEl.innerHTML = styles;
                document.getElementsByTagName('head')[0].appendChild(styleEl);
            },
            // get width and left coordinates from element
            coordinates (el) {
                return !el
                    ? {left: null, width: null}
                    : {
                        left: Math.round(el.getBoundingClientRect().left),
                        width: Math.round(el.getBoundingClientRect().width),
                    };
            },
            // sync header scroll at the dashboard table
            dynamicTableScrollHandler (e) {
                const dynamicTableHeader = document.querySelector(`${this.wrapperQuerySelector} ${this.headerQuerySelector}`);
                if (!dynamicTableHeader) return;
                dynamicTableHeader.scrollLeft = e.target.scrollLeft;
            }
        },
    }
</script>

<style lang="scss" scoped>
  /deep/ .ps__rail-x {
    background-color: transparent !important;
    border-radius: 10px;
    bottom: 0;
    height: 6px;
    z-index: 10;
  }

  /deep/ .ps__rail-x_fixed {
    background-color: kw-color(kw-white, 1) !important;
    border-left: 4px solid kw-color(kw-white, 1);
    border-radius: 100px;
    border-right: 4px solid kw-color(kw-white, 1);
    bottom: 6px !important;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, .2);
    height: 26px;
    position: fixed;

    .ps__thumb-x {
      border-radius: 100px;
      bottom: 4px;
      height: 18px !important;
    }
  }

  /deep/ .ps__thumb-x {
    border-radius: 10px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
</style>